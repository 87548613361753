import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

//custom-components
import GalleryLoadingCard from '../galleryLoadingCard/galleryLoadingCard';
import Galleries from '../../galleries/galleries';

//query
import { GET_GALLERIES, UPDATE_GALLERY_STATS } from '../../../queries/common_queries';

class GalleryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_galleries: [
                <GalleryLoadingCard />,
                <GalleryLoadingCard />,
                <GalleryLoadingCard />,
                <GalleryLoadingCard />,
                <GalleryLoadingCard />,
                <GalleryLoadingCard />,
                <GalleryLoadingCard />,
                <GalleryLoadingCard />

            ],
            pagination: {
                skip: 0,
                first: 10000,
                lang: localStorage.LANG
            },
            galleries: [],
            loading: false,
            nextPage: 1,
            previousPage: 0,
            currentPage: 0,
            activepage: '',
            ToDos: 16,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            isFirstBtnActive: 'disabled',
            isLastBtnActive: '',
        }
        console.log(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, index) {
        this.setState({
            currentPage: Number(index),
            activepage: index
        });
    }

    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            //Get Galleries
            this.get_galleries(this.state.pagination).then(result => {
                console.log(result)
                // var pinnedGallery = result.galleries;
                // var res = pinnedGallery.filter((it) => it.pinned === true);
                // console.log(res)

                var gallery_op = result.galleries.map(gallery => {
                    console.log(gallery)
                    return {
                        id: gallery.id,
                        galleryTitle: gallery.galleryTitle,
                        galleryBannerImage: gallery.galleryBannerImage,
                        galleryHelperText: gallery.galleryHelperText,
                        galleryDescription: gallery.galleryDescription,
                        isActive: gallery.isActive,
                        eventDate: gallery.eventDate,
                        pinned: gallery.pinned
                    }
                });
                console.log(this.state.pinned)
                // const myData = [].concat(gallery_op).sort((a, b) => a.pinned > b.pinned ? -1 : 1)
                this.setState({ galleries: gallery_op , count: result.count, loading: false })
                console.log(this.state.galleries)
            })
        }, 2000);

    }

    componentDidUpdate() { window.scrollTo(0, 0) }

    redirectToGallery = (id) => {
        this.updateGalleryStats(id).then(result => {
            this.props.history.push('/galleryImages?id=' + id);
        });
        
    }

    get_galleries = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_GALLERIES,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getGalleries;
    };

    updateGalleryStats = async (galleryId) => {
        const result = await this.props.client.mutate({
          mutation: UPDATE_GALLERY_STATS,
          variables: {galleryId:galleryId},
        });
        console.log(result.data);
        return result.data.updateGalleryStats;
      };

    handlePagination = async (pageNo) => {
        console.log(pageNo);
        this.setState({
            currentPage: pageNo
        })
      
        if (pageNo >= 0) {
            this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
            this.state.nextPage = pageNo + 1;
        }

        if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
            this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
            this.state.previousPage = pageNo - 1;
        }

        if (pageNo === 0) {
            this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
        }

        var paginations = {
            // galleryId:galleryId,
            skip: pageNo * this.state.ToDos,
            first: 16,
            lang: localStorage.LANG
        };

        if (pageNo > 0) {
            this.get_galleries(paginations).then(result => {
                console.log(result)
                this.setState({ galleries: result.galleries, count: result.count });
            })
        } else {
            this.get_galleries(this.state.pagination).then(result => {
                this.setState({ galleries: result.galleries, count: result.count });
            })
        }
    }

    render() {
        const { loading, galleries, loading_galleries, count, previousPage, nextPage, currentPage, ToDos, isPrevBtnActive, isNextBtnActive, isFirstBtnActive, isLastBtnActive } = this.state;
        const pages = Math.ceil(count / ToDos);
        console.log(this.state.pinned)
        if (pages === 0) return null;

        var items = [];
        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }

        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className="fas fa-angle-left"></i> </span></li>
        } else {
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
        }

        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
        }
        else {
            renderNextBtn = <li className={isNextBtnActive}><a onClick={() => this.handlePagination(nextPage)} id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
        }

        let renderfirstBtn = null;
        if (isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
        } else {
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
        }

        let renderlastBtn = null;
        if (isLastBtnActive === 'disabled') {
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
        } else {
            renderlastBtn = <li className={isLastBtnActive}><a onClick={() => this.handlePagination(items.length - 1)} id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
        }
        var MyData= [].concat(this.state.galleries)
        return (
            <div className="uk-container">
                {/* <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <p> Galleries Detail goes here ... </p>
                            </div>                          
                        </div> */}
                <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-margin uk-grid" uk-grid uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" >
                    { loading ? (
                        loading_galleries.map((gallery, i) => (
                            <GalleryLoadingCard key={i} />
                        ))
                    ) : (
                        
                        MyData.sort((a, b) => a.pinned > b.pinned ? -1 : 1)
                        .map((gallery, x) => 
                            <Galleries key={x} gallery={gallery} get_gallery={() => this.redirectToGallery(gallery.id)} />
                        )

                            // galleries.map((gallery, x) => (
                            //     <Galleries gallery_content={gallerySec} key={x} gallery={gallery} get_gallery={() => this.redirectToGallery(gallery.id)} />
                            // ))
                        )}
                </div>
                {/* {count >= 16 ? (
                    <div className="paginations uk-flex uk-flex-center">
                        <ul className="uk-pagination" uk-grid>
                            {renderfirstBtn}
                            {renderPrevBtn}

                            {
                                items.map((item, index) =>
                                    <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                                )
                            }

                            {renderNextBtn}
                            {renderlastBtn}
                        </ul>
                    </div>
                ) : (null)} */}
            </div>
        );
    }
}

export default withRouter(withApollo(GalleryContainer));
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';
import Navbar from '../../navbar/navbar';
import Header from '../../header/header';
import MainBanner from '../../home//mainBanner/mainBanner';
import Footer from '../../footer/footer';
import ReactTooltip from 'react-tooltip';

import { Table, Grid, Icon, Menu, Button } from "semantic-ui-react";
import { Document, Page } from 'react-pdf';
// import { Document } from 'react-pdf/dist/esm/entry.parcel';
// import PDFViewer from 'pdf-viewer-reactjs'

//custom-components
import Card_lesson from '../../../components/lesson/card_lesson/card_lesson';
import Lesson_container from '../../../components/lesson/lesson_container/lesson_container';
import QuizButtonComponent from '../../quizButtonComponent/quizButtonComponent';
import NextButton from '../../nextButton/nextButton';
import ContentNotFoundMessage from '../../../components/messages/contentNotFound/ContentNotFoundMessage'

import { GET_NEXT_MODULE, GET_SUB_MODULE } from '../../../queries/common_queries'
import result from '../../../screens/user/result/result';
import PropTypes from 'prop-types';
// import Refresh from '../../../images/refresh.png'
import PDF from "react-pdf-js";
// import Viewer from 'react-drag-n-zoom'
import squareIcon from '../../../images/square.png';
import { withNamespaces } from "react-i18next";

class Sub_module_container extends React.Component {
    constructor(props) {
        super(props);
        // console.log(this.props.moduleId)
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        this.state = {
            cards: [
                <Card_lesson />,
                <Card_lesson />,
                <Card_lesson />,
                <Card_lesson />,
                <Card_lesson />
            ],
            moduleId: this.props.moduleId,
            subModuleId: id,
            quizStatus: false,
            pagination: {
                moduleId: this.props.moduleId,
                first: 1,
                lang: localStorage.LANG
            },
            next_module: "",
            sub_modules: this.props.sub_modules,
            nextSubModuleId: this.props.nextSubModuleId,
            numPages: null,
            pageNumber: 1,
            scale: 1.0,
            pages: null,
            page: 1,
            collapsibleSections: this.props.sub_module.collapsibleSections ? this.props.sub_module.collapsibleSections.collapsibleSections.length > 0 ? this.props.sub_module.collapsibleSections.collapsibleSections : [] : [],

            pageCounts: new Array(15),
            currentPageArray: new Array(15).fill(1),
            scales: new Array(15).fill(1),
        }
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.onDocumentComplete = this.onDocumentComplete.bind(this);
        this.ZoomIn = this.ZoomIn.bind(this);
        console.log(localStorage.LANG)
    }

    redirectToLesson = (id, moduleId, submoduleId) => {
        this.props.history.push('/lesson?id=' + id + '&module=' + moduleId + '&submodule=' + submoduleId);
    }


    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    goToPrevPage = (index, type) => {
        const { pageNumber, pages, currentPageArray, pageCounts } = this.state;
        var temp = currentPageArray;
        var currentPage = temp[index];
        console.log('KKKKKKKKKK')
        console.log(temp[index])
        if (type === 'prev') {
            temp[index] = currentPage - 1;
            this.setState(state => ({ pageNumber: state.pageNumber - 1, currentPageArray: temp }));
        }
        if (type === 'last') {
            temp[index] = pageCounts[index];
            this.setState(state => ({ pageNumber: state.pageNumber - 1, currentPageArray: temp }));
        }
    }

    goToNextPage = (index, type) => {
        const { pageNumber, pages, currentPageArray, pageCounts } = this.state;
        var temp = currentPageArray;
        var currentPage = temp[index];

        if (type === 'next') {
            temp[index] = currentPage + 1;
            this.setState(state => ({ pageNumber: state.pageNumber + 1, currentPageArray: temp }));
        }
        if (type === 'reset') {
            temp[index] = 1;
            this.setState(state => ({ pageNumber: state.pageNumber + 1, currentPageArray: temp }));
        }
    }
    ZoomIn = (index) => {
        var temp = this.state.scales;
        const currentScale = temp[index];
        if (currentScale < 1.8) {
            temp[index] = currentScale + 0.1;
            this.setState(state => ({ scale: state.scale + 0.1, scales: temp }));
        }
    }

    ZoomOut = (index) => {
        var temp = this.state.scales;
        const currentScale = temp[index];
        if (currentScale > 1) {
            temp[index] = currentScale - 0.1;
            this.setState(state => ({ scale: state.scale + 0.1, scales: temp }));
        }

    }

    ZoomInit = (index) => {
        var temp = this.state.scales;
        if (temp[index] > 1) {
            temp[index] = 1;
            this.setState({
                scales: temp
            })
        }
    }

    onDocumentComplete = (pages, index) => {
        var temp = this.state.pageCounts;
        temp[index] = pages;
        console.log(index)
        console.log(pages)
        this.setState({ page: 1, pages, pageCounts: temp });
    }

    handlePrevious = () => {
        this.setState({ page: this.state.page - 1 });
    }

    handleNext = () => {
        this.setState({ page: this.state.page + 1 });
    }

    renderPagination = () => {
        return (
            <>
                <button onClick={this.handlePrevious}>&lt;</button>
                <button onClick={this.handleNext}>&gt;</button>
            </>
        );
    }

    breadcrumbredirect = (path) => {
        if (path == "HOME") {
            this.props.history.push('/home');
        } else if (path == "MODULE") {
            this.props.history.push('/module?id=' + this.state.moduleId);
        } else {
            return false;
        }

    }
    componentDidMount() {
        document.querySelectorAll('oembed[url]').forEach(element => {
            const URL = element.getAttribute('url');
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            var match = URL.match(regExp);
            if (match && match[7].length == 11) {
                console.log(match[7]);
                const videoId = "https://www.youtube.com/embed/" + match[7] + "?rel=0&modestbranding=1";
                element.innerHTML = '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;"><iframe src=' + videoId + ' style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>';
            }

            // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
            // to discover the media.
            // const anchor = document.createElement('a');

            // anchor.setAttribute('href', element.getAttribute('url'));
            // anchor.className = 'embedly-card';

            // 
        });



    }

    componentDidUpdate(prevProps) {
        if (prevProps.sub_module.id != this.props.sub_module.id) {
            this.get_submodule().then(result => {
                console.log(result);
                // this.setState({
                //     s
                // })
            })

        }


    }

    get_submodule = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_SUB_MODULE,
            variables: { subModuleId: this.state.subModuleId, lang: localStorage.LANG },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModule;
    };

    collapseSection = (status, index) => {
        var temp = this.state.collapsibleSections;
        if (status === true) {
            temp[index].display = false;
        }

        else {
            temp[index].display = true;
        }

        this.setState({ collapsibleSections: temp })
    };

    render() {
        const { id, from, last, lessons, sub_module, redirectKey, lastModule, sub_modules, nextSubModuleId, moduleId, module, learningSummary, presentations, t } = this.props;
        const content = this.props.contents.contents;
        const { cards, quizStatus, pageNumber, numPages, scale, pages, collapsibleSections, pageCounts, currentPageArray, scales } = this.state;
        const presentationFiles = presentations.presentations;

        let paginationOne = null;

        if (this.state.pages) {
            paginationOne = this.renderPagination();
        }
        var pptDoc = "https://firebasestorage.googleapis.com/v0/b/halad-bucket.appspot.com/o/Fundamentals_of_Web_Design_v2.ppt?alt=media&amp;token=98fb5259-32c3-40a0-a309-6e2f3e5417af"
        var iFrame = document.getElementById('pdfs');
        // console.log(iFrame.contentWindow.document.querySelectorAll('.page').length);

        return (
            <div>
                <br />
                <div>
                    <ul className="uk-breadcrumb">
                        <li><a onClick={() => this.breadcrumbredirect("HOME")}>{t('home')}</a></li>
                        <li><a onClick={() => this.breadcrumbredirect("MODULE")}>{t('module')}</a></li>
                        <li><a onClick={() => this.breadcrumbredirect("SUBMODULE")}>{t('single_module')}</a></li>
                    </ul>
                </div>



                <div className="uk-clearfix boundary-align">
                    {
                        presentationFiles ? (
                            presentationFiles.length > 0 ? (

                                ''
                                //   <div className="presentationStyles">
                                //     {
                                //         presentationFiles.map((res,index)=>(
                                //             <div className="DocStyle">
                                //                 <Table className="docTable">
                                //                     <Table.Row>
                                //                         <Document
                                //                         // file="https://firebasestorage.googleapis.com/v0/b/halad-bucket.appspot.com/o/Learning%20Google%20Guice.pdf?alt=media&amp;token=d0cac623-57fe-4c53-81bd-02e8bc5a4b23"
                                //                         // file="https://firebasestorage.googleapis.com/v0/b/halad-bucket.appspot.com/o/Fundamentals_of_Web_Design_v2.ppt?alt=media&amp;token=98fb5259-32c3-40a0-a309-6e2f3e5417af"
                                //                         file={res.presentationUrl}
                                //                         onLoadSuccess={this.onDocumentLoadSuccess}
                                //                         >

                                //                         <Page scale={scale} pageNumber={pageNumber} width={400} />
                                //                         </Document>
                                //                     </Table.Row>
                                //                     <Table.Row className="slideFoot">
                                //                     <nav className="slideFooterStyle">
                                //                         <button onClick={this.goToPrevPage} className="uk-button prev"> <i class="fa fa-caret-left fa-2x" aria-hidden="true"></i></button>
                                //                         <p> {pageNumber} of {numPages} </p>
                                //                         <button onClick={this.goToNextPage} className="uk-button next"> <i class="fa fa-caret-right fa-2x" aria-hidden="true"></i></button>

                                //                         <div className="ZoomStyle">
                                //                           <button onClick={this.ZoomIn} id="Zoomin"  name="Zoomin" class="uk-button btn zoom "><i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></button>
                                //                           <button  onClick={this.ZoomOut} id="Zoomout"  name="Zoomout"  class=" uk-button btn zoom-out" ><i class="fa fa-search-minus fa-2x" aria-hidden="true"></i></button>
                                //                           <button  onClick={this.ZoomInit} id="ZoomInit"  name="ZoomInit"  class=" uk-button btn zoom-init" >  <i class="fa fa-recycle fa-2x" aria-hidden="true"></i></button>
                                //                         </div>
                                //                     </nav>
                                //                     </Table.Row>
                                //                     <div style={{ paddingTop:"10px", }}>
                                //                         <a target="_blank" href={res.presentationUrl} className="uk-button-primary"  style={{backgroundColor:"#008CC9",float:"left",textDecoration:"none", borderRadius:"3px", padding:"4px 5px", fontSize:"14px" }}> Download </a> 
                                //                     </div>
                                //                 </Table>


                                //                  {/* {paginationOne}
                                //                 <PDFViewer
                                //                     document={{
                                //                         url: res.presentationUrl,
                                //                     }}
                                //                 /> */}

                                //              {/* <iframe src="https://firebasestorage.googleapis.com/v0/b/halad-bucket.appspot.com/o/Learning%20Google%20Guice.pdf?alt=media&amp;token=d0cac623-57fe-4c53-81bd-02e8bc5a4b23#view=Fit" style={{width:"600px", height:"500px", marginBottom:"20px"}} frameborder="0"></iframe> */}

                                //             </div>   
                                //         // <iframe src={`//docs.google.com/gview?url=${res.presentationUrl}&embedded=true`} style={{width:"600px", height:"500px", marginBottom:"20px"}} frameborder="0"></iframe>
                                //         ))
                                //     }
                                //    </div>
                            ) : (null)
                        ) : (null)
                    }


                    {/* section-heading */}
                    <div className="none-border ">
                        {sub_module.subContent != "" ? (
                            <div className="ck-style">
                                <div dangerouslySetInnerHTML={{ __html: sub_module.subContent }}></div>
                            </div>
                        ) : (
                                null
                            )}
                        <br />
                    </div>

                </div>

                {/* Test Start */}
                <div>
                    {
                        presentationFiles ?
                            presentationFiles.length > 0 ?
                                presentationFiles.map((file, index) => (
                                    <div className="pdfIframe1">
                                        <div className="slidePdf1">
                                            <div className={scales[index] === 1 ? "slidePdf2" : "slidePdf3"}>
                                                <PDF
                                                    file={file.presentationUrl}
                                                    page={currentPageArray[index]}
                                                    scale={scales[index]}
                                                    onDocumentComplete={(pages) => this.onDocumentComplete(pages, index)}
                                                // className="PDFStyle"
                                                />
                                            </div>
                                        </div>


                                        <div className="slideFoot1">
                                            <nav className="slideFooterStyle1">
                                                {currentPageArray[index] != 1 ? (
                                                    <button onClick={() => this.goToPrevPage(index, 'prev')} className="uk-button prev"> <i class="fa fa-caret-left fa-2x" aria-hidden="true"></i></button>
                                                ) : (
                                                        <button onClick={() => this.goToPrevPage(index, 'last')} className="uk-button prev"> <i class="fa fa-caret-left fa-2x" aria-hidden="true"></i></button>
                                                    )
                                                }
                                                <p> {currentPageArray[index]} of {pageCounts[index]} </p>

                                                {currentPageArray[index] != pageCounts[index] ? (
                                                    <button onClick={() => this.goToNextPage(index, 'next')} className="uk-button next"> <i class="fa fa-caret-right fa-2x" aria-hidden="true"></i></button>
                                                ) : (
                                                        // <button onClick={this.goToNextPage} disabled className="uk-button next"> <i class="fa fa-caret-right fa-2x" aria-hidden="true"></i></button>  
                                                        <button onClick={() => this.goToNextPage(index, 'reset')} className="uk-button next"> <i class="fa fa-caret-right fa-2x" aria-hidden="true"></i></button>
                                                    )
                                                }

                                                <div className="ZoomStyle">
                                                    <button onClick={() => this.ZoomIn(index)} style={{ color: scales[index] >= 1.8 ? 'gray' : 'white', cursor: scales[index] >= 1.8 ? 'default' : 'pointer' }} id="Zoomin" uk-tooltip={t('zoon_in')} name="Zoomin" class="uk-button btn zoom" ><i class="fa fa-search-plus fa-2x" aria-hidden="true"></i></button>
                                                    <button onClick={() => this.ZoomOut(index)} style={{ color: scales[index] <= 1 ? 'gray' : 'white', cursor: scales[index] <= 1 ? 'default' : 'pointer' }} id="Zoomout" uk-tooltip={t('zoom_out')} name="Zoomout" class="uk-button btn zoom-out" ><i class="fa fa-search-minus fa-2x" aria-hidden="true"></i></button>
                                                    {/* <button  onClick={this.ZoomInit} id="ZoomInit" uk-tooltip="Refresh"  name="ZoomInit"  class="uk-button btn zoom-init" ><img className="refreshIcon" style={{ width:"78%",paddingBottom:"13px"}} src={Refresh} /></button> */}

                                                    <button onClick={() => this.ZoomInit(index)} style={{ color: scales[index] <= 1 ? 'gray' : 'white', cursor: scales[index] <= 1 ? 'default' : 'pointer' }} id="ZoomInit" uk-tooltip={t('refresh')} name="ZoomInit" class="uk-button btn zoom-init" >   <i class="fa fa-recycle fa-2x" aria-hidden="true"></i></button>
                                                    <a href={file.presentationUrl} download target="_blank" id="ZoomInit" uk-tooltip={t('download')} name="ZoomInit" class="uk-button btn zoom-init"> <i class="fa fa-arrow-circle-down fa-2x" aria-hidden="true"></i></a>

                                                    {/* <a target="_blank" href={res.presentationUrl} className="uk-button-primary"  style={{textDecoration:"none", borderRadius:"3px", padding:"4px 5px", fontSize:"14px" }}> Download </a>  */}

                                                </div>
                                            </nav>
                                        </div>
                                    </div>

                                ))

                                : null : null
                    }

                </div>
                {/* Test End */}

                <div>
                    {
                        sub_module.videos ?
                            sub_module.videos.videos ?
                                sub_module.videos.videos.length > 0 ?
                                    sub_module.videos.videos.map(video => (
                                        <div style={{ width: '75%', marginBottom: '2.5rem', textAlign: 'justify' }}>
                                            <video key={'https://kmsvideo.skotie.com/' + video.url} className="video" style={{ width: '100%', marginBottom: '0rem' }} controls>
                                                <source src={'https://kmsvideo.skotie.com/' + video.url} />
                                            </video>
                                            <text className="videoCaptionUser">{video.description}</text>
                                        </div>

                                    ))
                                    : null
                                : null
                            : null
                    }
                    {/* ReatTooltip was used to have the tooltip option in ck editor content! */}
                    <ReactTooltip textColor='white' className="data-tip-custom-styles" />
                    <div className="ck-style">
                        {
                            collapsibleSections ?
                                collapsibleSections.length > 0 ?
                                    collapsibleSections.map((section, index) => (
                                        section.title != '' ?
                                            section.content != '' ?
                                                <React.Fragment>
                                                    <div className="customAccordion" style={{ backgroundColor: section.bgColor || '#e3e3e3' }} onClick={() => this.collapseSection(section.display, index)}>
                                                        <i class="fa fa-caret-down" aria-hidden="true" style={{ color: section.fontColor, marginRight: '0.5rem' }}></i>
                                                        <text style={{ color: section.fontColor }}>{section.title}</text>
                                                    </div>
                                                    {
                                                        section.display === true ?
                                                            <div className="customAccordionContent">
                                                                <ReactTooltip textColor='white' className="data-tip-custom-styles" />

                                                                <div dangerouslySetInnerHTML={{ __html: section.content }}></div>
                                                            </div>
                                                            : null
                                                    }
                                                </React.Fragment>
                                                : null
                                            : null
                                    ))
                                    : null
                                : null

                        }
                    </div>
                </div>
                <br />
                {
                    nextSubModuleId != 0 ? (
                        null
                    ) : (
                            <div>
                                {localStorage.LANG=="ar"? (
                                    <Table className="moduleTable muduleSummary ">
                                    <Table.Body>
                                        <Table.Row className="">
                                            <Table.Cell style={{ padding: "0px" }}>
                                                <Grid columns={3}>
                                                    <Grid.Column className="ModuleSummary_Ar">
                                                        {t('summary')}
                                                    </Grid.Column>
                                                </Grid>
                                            </Table.Cell>
                                        </Table.Row>
                                        <br />
                                        <div className="ck-style">
                                            {learningSummary ? (
                                                learningSummary.length > 0 ? (
                                                    learningSummary.map((singleSummary) => (
                                                        <Table.Row className="moduleLearningObjective_Ar learningSummary_Ar">
                                                            <Table.Cell style={{ paddingBottom: "0px" }}>
                                                                <ul style={{ listStyleType: "none" }}>
                                                                    <li><span>  <div className="ckPara" dangerouslySetInnerHTML={{ __html: singleSummary.learningSum }}></div>  </span></li>
                                                                    {singleSummary.summary_lists ? (
                                                                        singleSummary.summary_lists.map((list) => (
                                                                            <dd style={{ paddingRight: "25px", marginRight: "10px" }}><span><img className="squareIcon_Ar" src={squareIcon} />{list.list}</span>  </dd>
                                                                        ))
                                                                    ) : (null)}
                                                                </ul>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                ) : (
                                                        <ContentNotFoundMessage />
                                                    )
                                            ) : (
                                                    <ContentNotFoundMessage />
                                                )}
                                        </div>
                                    </Table.Body>
                                </Table>

                                ):(
                                    <Table className="moduleTable muduleSummary ">
                                    <Table.Body>
                                        <Table.Row className="">
                                            <Table.Cell style={{ padding: "0px" }}>
                                                <Grid columns={3}>
                                                    <Grid.Column className="ModuleSummary">
                                                        {t('summary')}
                                                    </Grid.Column>
                                                </Grid>
                                            </Table.Cell>
                                        </Table.Row>
                                        <br />
                                        <div className="ck-style">
                                            {learningSummary ? (
                                                learningSummary.length > 0 ? (
                                                    learningSummary.map((singleSummary) => (
                                                        <Table.Row className="moduleLearningObjective learningSummary">
                                                            <Table.Cell style={{ paddingBottom: "0px" }}>
                                                                <ul style={{ listStyleType: "none" }}>
                                                                    <li><span>  <div className="ckPara" dangerouslySetInnerHTML={{ __html: singleSummary.learningSum }}></div>  </span></li>
                                                                    {singleSummary.summary_lists ? (
                                                                        singleSummary.summary_lists.map((list) => (
                                                                            <dd style={{ paddingLeft: "25px", marginLeft: "10px" }}><span><img className="squareIcon" src={squareIcon} />{list.list}</span>  </dd>
                                                                        ))
                                                                    ) : (null)}
                                                                </ul>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                ) : (
                                                        <ContentNotFoundMessage />
                                                    )
                                            ) : (
                                                    <ContentNotFoundMessage />
                                                )}
                                        </div>
                                    </Table.Body>
                                </Table>

                                )}
                                
                                <br />
                                {/* <div>
                                    <QuizButtonComponent quiz_content={sub_moduleDetail} moduleId={moduleId} quizStatus={quizStatus} />
                                </div> */}
                            </div>

                        )
                }

                {
                    redirectKey == "LESSON" ? (
                        lessons.length != 0 ? (
                            /* sub_modules.subModules.map(( sub_module , index )=> ) */
                            lessons.lessons.map((lesson, i) => (
                                <Card_lesson num={i + 1} lesson={lesson} get_lesson={() => this.redirectToLesson(lesson.id, this.props.moduleId, this.props.id)} />
                            ))
                        ) : (null)
                    ) : (
                            redirectKey == "CONTENT" ? (
                                <Lesson_container id={id} subModuleId={this.props.id} moduleId={this.props.moduleId} from={from} content={content[0]} />
                            )() : (
                                    null
                                )
                        )
                }
            </div>
        );
    }

}

export default withRouter(withApollo(withNamespaces()(Sub_module_container))); 
export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    var re = /^([1-9]{1}[0-9]{8}$)/;
    return re.test(phone);
}

const initAuth = () => {
    return window.gapi.auth2.init({
      client_id: "788661828009-rl47rh3d5nrudhet6c281phipdcan8al.apps.googleusercontent.com", //paste your client ID here
      scope: "https://www.googleapis.com/auth/analytics.readonly",
    });
  };

export const checkSignedIn = () => {
return new Promise((resolve, reject) => {
    initAuth() //calls the previous function
    .then(() => {
        const auth = window.gapi.auth2.getAuthInstance(); //returns the GoogleAuth object
        resolve(auth.isSignedIn.get()); //returns whether the current user is currently signed in
    })
    .catch((error) => {
        reject(error);
    });
});
};

export const renderButton = () => {
    window.gapi.signin2.render("signin-button", {
      scope: "profile email",
      width: 240,
      height: 50,
      longtitle: true,
      theme: "dark",
      onsuccess: onSuccess,
      onfailure: onFailure,
    });
  };
  
  const onSuccess = (googleUser) => {
    console.log("Logged in as: " + googleUser.getBasicProfile().getName());
  };
  
  const onFailure = (error) => {
    console.error(error);
  };
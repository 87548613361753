import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import ContentNotFoundMessage from "../../../components/messages/contentNotFound/ContentNotFoundMessage";

//queries
import { GET_MODULE_QUIZ } from "../../../queries/common_queries";
import {
  GET_MODULES_FOR_ADMIN,
  UPDATE_MODULE_QUIZ,
} from "../../../queries/admin_queries";

import { Button } from "react-uikit";

import { Values } from "redux-form-website-template";

//redux-form
import { Field, FieldArray, reduxForm } from "redux-form";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class QuizEdit extends React.Component {
  constructor(props) {
    super(props);

    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    }

    this.state = {
      modules: [],
      moduleId: "",
      moduleQuizId: "",
      isActive: true,
      loading: false,
      pagination: {
        isActive: true,
        skip: 0,
        first: 100,
        lang: "en",
      },
      moduleQuiz: [],
      fullModuleQuiz: {},
      title: "",
      description: "",
      totalMarksArr: [],
      totalMarks: 0,
      allquestions: 0,
      passRate: 0,
      score: 0.0,
      certificateStatus: false,
      formLanguage: "en",
      lang: "en",

      quizAnswerIndexes: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
      
    };
    // console.log(this.props)

    this.handleChange = this.handleChange.bind(this);

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });
  }

  componentDidMount() {
    this.get_modules(this.state.pagination).then((result) => {
      var module_opt = [];
      result.modules.map((module) =>
        module_opt.push({
          key: module.id,
          value: module.id,
          id: "moduleId",
          label: module.moduleTitle,
        })
      );
      this.setState({ modules: module_opt });
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleModuleChange = (event) => {
    var moduleId = event.target.value;
    this.setState({ moduleId: moduleId });
    this.get_quiz(moduleId, this.state.formLanguage).then(result => {
        console.log(result)
        if (result.count > 0) {
            this.setState({
                loading: false,
                moduleId: result.moduleQuizzes[0].moduleId,
                moduleQuizId: result.moduleQuizzes[0].id,
                moduleQuiz: result.moduleQuizzes[0].moduleQuiz.questions,
                title: result.moduleQuizzes[0].moduleQuiz.title,
                description: result.moduleQuizzes[0].moduleQuiz.description,
                passRate: result.moduleQuizzes[0].moduleQuiz.passRate,
                allquestions: result.moduleQuizzes[0].moduleQuiz.questions.length,
            });
            console.log(result.moduleQuizzes[0].moduleQuiz);
            setTimeout(function () {
                this.props.initialize(result.moduleQuizzes[0].moduleQuiz);
            }.bind(this, 2000));
        } else {
            this.setState({ loading: false, moduleQuiz: [], title: "", passRate: ""});
        }
    })
  };

  renderField = ({ input, label, index, type, meta: { touched, error } }) => (
    <div>
      {index} ){" "}
      <input
        className="uk-input uk-width-xxlarge"
        {...input}
        type={type}
        placeholder={label}
        //value={value}
      />
    </div>
  );

  renderCorrectField = ({
    input,
    label,
    checked,
    type,
    meta: { touched, error },
  }) => (
    <div>
      <div>
        <label style={{color: '#42adff', marginLeft: '1.5rem'}}>
          {" "}
          <input
            {...input}
            type={type}
            className="uk-checkbox"
            id="correct"
          />{" "}
          Correct{" "}
        </label>
      </div>
    </div>
  );

  renderAnswers = ({ fields, questionPart, meta: { error } }) => (
    <div>
      {fields.map((answer, index) => (
        <div key={index}>
          <div className="" style={{color: 'rgb(83, 136, 83)'}}>
            <a className="uk-button" onClick={() => fields.remove(index)}>
              <i className="fas fa-minus-circle"></i> Answer
            </a>
          </div>
          <Field
            name={questionPart + "." + `${"answers[" + index + "]"}.answer`}
            type="text"
            props={{
              index: this.state.quizAnswerIndexes[index],
              value: answer.answer,
            }}
            component={this.renderField}
            label={`Answer`}
          />
          <Field
            name={questionPart + "." + `${"answers[" + index + "]"}.isCorrect`}
            type="checkbox"
            component={this.renderCorrectField}
            label={`Correct`}
          />
        </div>
      ))}

      <div>
        <Button
          className="uk-button uk-button-default uk-margin adminSectionOtherButtons2"
          type="button"
          onClick={() => fields.push()}
        >
          <i className="fas fa-plus-circle"></i> Answer
        </Button>
      </div>
    </div>
  );

  renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div>
      {console.log(fields)}

      {fields.length > 0
        ? fields.map((question, index) => (
            <div key={index}>
              <div className="" style={{color: '#9b2333'}}>
                <a className="uk-button" onClick={() => fields.remove(index)}>
                  <i className="fas fa-minus-circle"></i> Question
                </a>
              </div>
              <Field
                name={`${"questions[" + index + "]"}.question`}
                type="text"
                props={{
                  index: index + 1,
                }}
                component={this.renderField}
                label="Question"
              />
              <hr />
              <FieldArray
                name={`${question}.answers`}
                props={{
                  questionPart: `${"questions[" + index + "]"}`,
                }}
                component={this.renderAnswers}
              />
            </div>
          ))
        : null}

      <Button
        className="uk-button uk-button-default uk-margin adminSectionOtherButtons1"
        type="button"
        onClick={() => fields.push({})}
      >
        <i className="fas fa-plus-circle"></i> Question
      </Button>
    </div>
  );

  handleFormSubmit = (values) => {
    console.log(values);

    var err = false;
    const { moduleId, passRate } = this.state;

    this.setState({ loading: true });

    if (moduleId == "") {
      err = true;
      this.setState({ loading: false, validation_err: true });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please select a module.",
      });
    } else if (passRate < 0) {
      err = true;
      this.setState({ loading: false, validation_err: true });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Minimum valid input is zero!",
      });
    } else {
      this.handleValidateSubmit(values).then((result) => {
        if (result != true) {
          var quiz = values;
          console.log(quiz);
          this.props.setFormStatus({ status: false, title: "", message: "" });
          this.props.setSuccessStatus({
            status: false,
            title: "",
            message: "",
          });

          this.updateModuleQuiz(quiz)
            .then((result) => {
              console.log(result);
              console.log("Success");
              this.props.setSuccessStatus({
                status: true,
                title: "",
                message: "Module Quiz Updated Successfully!",
              });

              this.setState({
                loading: false,
                answers: [{ answer: "", isCorrect: false }],
                question_arr: [],
                answer_iscorrect: false,
                // moduleId: "",
                // title: "",
                // passRate: "",
                // description: "",
                // moduleQuiz: [],
              });
            })
            .catch((error) => {
              console.log(error);
              this.setState({ loading: false });
              this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                  <div>
                    An error occurred while trying to update the quiz! <br/>
                    Please try again.
                  </div>
              });
            });
        }
      });
    }
  };

  updateModuleQuiz = async (quiz) => {
    quiz["title"] = this.state.title;
    quiz["description"] = this.state.description;
    quiz["passRate"] = this.state.passRate;

    const { moduleQuizId, isActive, formLanguage } = this.state;
    console.log(formLanguage)
    const moduleQuiz = quiz;

    const result = await this.props.client.mutate({
      mutation: UPDATE_MODULE_QUIZ,
      variables: { moduleQuizId, moduleQuiz, isActive, lang: formLanguage },
    });

    return result.data.updateModuleQuiz;
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  get_modules = async (pagination) => {
    const result = await this.props.client.query({
      query: GET_MODULES_FOR_ADMIN,
      variables: pagination,
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getModulesForAdmin;
  };

  get_quiz = async (moduleId, language) => {
    var pagination = this.state.pagination;
    pagination["moduleId"] = moduleId;
    pagination.lang = language;
    const result = await this.props.client.query({
      query: GET_MODULE_QUIZ,
      variables: pagination,
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getModuleQuiz;
  };

  handleValidateSubmit = async (values) => {
    this.setState({ validation_err: false });
    //var quiz = JSON.stringify(values);
    var quiz = values;
    var err = false;
    //var quiz = '{"questions": [{"question": "Hungry?","answers": [{"answer": "Yes","isCorrect": true}, {"answer": "No"}]}]}';
    console.log(quiz);

    const { moduleId } = this.state;

    if (this.isEmpty(quiz)) {
      err = true;
      this.setState({ loading: false, validation_err: true });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please enter at least one question.",
      });
    } else {
      this.setState({ validation_err: true });
      quiz.questions.map((question) => {
        if (question == undefined || this.isEmpty(question)) {
          err = true;
          this.setState({ loading: false, validation_err: true });
          this.props.setFormStatus({
            status: true,
            title: "Oops!",
            message: "Please provide the question content.",
          });
        } else {
          if (question.answers == undefined) {
            err = true;
            this.setState({ loading: false, validation_err: true });
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message: "Please enter available answers for this question.",
            });
          } else {
            console.log(quiz.questions);

            var isCorrectCount = 0;
            var answer_count = question.answers.length;

            question.answers.map((answer) => {
              if (answer == undefined || answer == "") {
                err = true;
                this.setState({ loading: false, validation_err: true });
                this.props.setFormStatus({
                  status: true,
                  title: "Oops!",
                  message: "Please enter available answers for this question.",
                });
              } else {

                if (answer.isCorrect == undefined || answer.isCorrect == false) {

                 isCorrectCount= isCorrectCount + 1;
                                        console.log(isCorrectCount)
                                        // console.log(answer.length)
                                    if (isCorrectCount == answer_count) {
                                        err = true;
                                        this.setState({ loading: false, validation_err: true });
                                        this.props.setFormStatus({ status: true, title: "Oops!", message: "Please tick at least one correct answer for all questions before saving." });
                                    } 

                  // if (isCorrectCount > 1) {
                  //   err = true;
                  //   this.setState({ loading: false, validation_err: true });
                  //   this.props.setFormStatus({
                  //     status: true,
                  //     title: "Oops!",
                  //     message: "Please provide the single correct answer.",
                  //   });
                  // } else {
                  //   err = false;
                  //   this.setState({ loading: false, validation_err: false });
                  //   this.props.setFormStatus({
                  //     status: false,
                  //     title: "",
                  //     message: "",
                  //   });
                  // }

                  return false;
                }

                // if (answer.isCorrect == undefined || answer.isCorrect=='') {
                //   err = true;
                //   this.setState({ loading: false, validation_err: true });
                //   this.props.setFormStatus({ status: true, title: "Oops!", message: "Please tick atleast one correct answer for questions before saving." });
                // } 
                
                // else if (answer.isCorrect != undefined && answer.isCorrect == false) {
                //     err = true;
                //     this.setState({ loading: false, validation_err: true });
                //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Please mark correct answer for all questions." });
                // }
              }
            });
          }
        }
      });
    }

    return err;
  };

  changeFormLanguage = (language) => {
    const id = this.state.moduleId;
    var tempParameter = this.state.pagination;
    tempParameter.lang = language;
    // this.setState({ 
      
    // });
    console.log(this.state.moduleId)
    // this.setState({ getModuleParameter: { moduleId: id, lang: language } });
    this.get_modules(tempParameter).then((result) => {
      var module_opt = [];
      result.modules.map((module) =>
        module_opt.push({
          key: module.id,
          value: module.id,
          id: "moduleId",
          label: module.moduleTitle,
        })
      );
      this.setState({ 
        modules: module_opt, 
        formLanguage: language, 
        moduleId: "",
        title: "",
        passRate: "",
        description: "",
        moduleQuiz: [],
    });
    });
    if (this.state.moduleId != "") {
      this.get_quiz(this.state.moduleId, language).then((result) => {
        console.log(result);
        if (result.count > 0) {
          this.setState({
            loading: false,
            moduleId: result.moduleQuizzes[0].moduleId,
            moduleQuiz: result.moduleQuizzes[0].moduleQuiz.questions,
            title: result.moduleQuizzes[0].moduleQuiz.title,
            description: result.moduleQuizzes[0].moduleQuiz.description,
            passRate: result.moduleQuizzes[0].moduleQuiz.passRate,
            allquestions: result.moduleQuizzes[0].moduleQuiz.questions.length,
          });
          console.log(result.moduleQuizzes[0].moduleQuiz);
          setTimeout(
            function () {
              //this.props.initialize(result.moduleQuizzes[0].moduleQuiz.questions);
            }.bind(this, 2000)
          );
        } else {
          this.setState({ loading: false, moduleQuiz: [], title: "", passRate: ""});
        }
      });
    }
  };

  addNewQuiz() {
    this.props.history.push("/admin_create_quiz");
  }

  render() {
    const { loading, modules, moduleQuiz, formLanguage } = this.state;
    const { handleSubmit, formErrorStatus, formSuccessState, translatingLanguages } = this.props;

    console.log(moduleQuiz);

    return (
      <div className="admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-question-circle icon-large uk-margin-right"></i>
          <h4 className="uk-margin-remove adminSectionTitles"> Edit Quiz </h4>
        </div>
        <div
          className="uk-flex-inline uk-float-right"
          style={{ paddingRight: "10px" }}
        >
          <button
            className="uk-button uk-button-primary admin-btn"
            onClick={() => this.addNewQuiz()}
          >
            Create Quiz
          </button>
        </div>
        <div className="uk-margin-medium">
          <form
            className="ui form"
            onSubmit={handleSubmit((val) => this.handleFormSubmit(val))}
          >
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="fas fa-question-circle uk-margin-small-right"></i> Quiz Details
                    {
                      translatingLanguages.length > 0 ?
                      <div className="formLanguageSwitcherDiv">
                        {
                          translatingLanguages.map(languageTab => (
                            <span circular icon="download" className={ formLanguage === languageTab.key ? "formLanguageSwitcherActive"  : "formLanguageSwitcher" } onClick={() => this.changeFormLanguage(languageTab.key)}>
                              {languageTab.language}
                            </span>
                          ))
                        }
                      </div>
                        : null
                    }
                    {/* <div className="formLanguageSwitcherDiv">
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "en"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("en")}
                        >
                          English
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "fr"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("fr")}
                        >
                          French
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "ar"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("ar")}
                        >
                          Arabic
                        </span>
                      </div> */}
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">
                    <div className="uk-form-label"> Module </div>
                    <select
                      className="uk-select uk-width-xxlarge"
                      onChange={this.handleModuleChange}
                      value={this.state.moduleId || ""}
                    >
                      <option key="userType" value="" disabled>
                        Select Module
                      </option>
                      {modules.map((item) => (
                        <option key={item.key} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>

                    <div className="uk-margin">
                      <label className="uk-form-label">Title</label>
                      <br />
                      <input
                        required
                        value={this.state.title || ""}
                        className="uk-input uk-width-xxlarge"
                        id="title"
                        name="title"
                        placeholder="Title"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="uk-margin">
                      <label className="uk-form-label">Description</label>
                      <br />
                      <textarea
                        value={this.state.description || ""}
                        className="uk-textarea uk-width-xxlarge"
                        rows="2"
                        id="description"
                        name="description"
                        onChange={this.handleChange}
                      ></textarea>
                    </div>

                    <div className="uk-margin">
                      <label className="uk-form-label">Pass Rate</label>
                      <br />
                      <input
                        required
                        value={this.state.passRate || ""}
                        type="number"
                        className="uk-input uk-width-xxlarge"
                        id="passRate"
                        name="passRate"
                        placeholder="Pass Rate"
                        onChange={this.handleChange}
                      />
                    </div>

                    {/* {
                                            moduleQuiz.length > 0 ? (
                                                <div className="uk-card-small uk-card-default uk-width-xxlarge" >
                                                    <h3>Questions</h3>
                                                    <div className="uk-card-body  ">
                                                            {moduleQuiz.map((quiz, i) => (
                                                               [ <h5>{i+1+") "} {quiz.question} </h5>,
                                                                    quiz.answers.map((answer, j) => (
                                                                        <div style={{paddingLeft:"30px"}}>
                                                                            {answer.isCorrect == undefined ? (
                                                                                <h6>{j+1+") "}{answer.answer}</h6>
                                                                            ):(
                                                                                answer.isCorrect == true ? (
                                                                                   <h6>{j+1+") "}{answer.answer} &emsp; <i className="far fa-check-circle fa-1x" style={{color:"#06a7e3"}}></i></h6>
                                                                                ):(
                                                                                    <h6>{j+1+") "}{answer.answer}</h6>
                                                                                )
                                                                            )}
                                                                            
                                                                        </div>
                                                                    )),
                                                                console.log(quiz)
                                                            ]
                                                            ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                    null
                                                )
                                        } */}

                    {moduleQuiz.length > 0 ? (
                      <FieldArray
                        name="questions"
                        component={this.renderQuestions}
                      />
                    ) : this.state.moduleId != "" ? (
                        <ContentNotFoundMessage lang={formLanguage} />
                      ) : null}

                    {/* <Field
                                                    name={`${quiz.question}.question`}
                                                    type="text"
                                                    props={{
                                                        index: i + 1
                                                    }}
                                                    component={this.renderField}
                                                    label="Question"
                                                /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <Values form="fieldArrays" /> */}

            <Button
              className="uk-button uk-button-primary uk-margin"
              style={{ height: 40 }}
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? (
                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
              ) : (
                "Update"
              )}
            </Button>
            {formErrorStatus.status ? (
              <div className="danger_alert">{formErrorStatus.message}</div>
            ) : formSuccessState.status ? (
              <div className="success_alert">{formSuccessState.message} </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    );
  }
}

QuizEdit = connect(mapStateToProps, mapDispatchToProps)(QuizEdit);

export default withApollo(reduxForm({ form: "fieldArrays" })(QuizEdit));

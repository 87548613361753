import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Navbar from "../../navbar/navbar";

import ContentLoader from "react-content-loader";

import Completed from "../../../images/completed.png";
import Todo from "../../../images/todo.png";
import { withNamespaces } from "react-i18next";

class Card_sub_module extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { sub_module, completed, t, num } = this.props;
    console.log(sub_module.subModuleOverview)
    console.log(num)

    return (
      <a href="" className="uk-link-reset">
        <div
          onClick={() => this.props.get_sub_module()}
          class="uk-card uk-card-default uk-grid-collapse uk-margin"
          uk-grid="true"
        >
          <div class="uk-card-media-left uk-cover-container uk-width-1-3">
            <img src={sub_module.subBannerIconUrl} alt="" uk-cover="true" />
            <canvas style={{ width: "auto" }} width="100" height="250"></canvas>
          </div>
          <div class="uk-width-2-3">
            {
              localStorage.LANG == "ar" ? (
                <div class="uk-card-body">
                  <h4>{num}. {sub_module.subModuleTitle}</h4>
                  <span class=" submodule_min uk-label" style={{ top: "45px" }}>
                    <i class="fas fa-clock" style={{paddingLeft:"3px"}} aria-hidden="true"></i>
                    <span>{sub_module.subModuleOverview} </span> {t('minutes')}
                  </span>
                  <p>{sub_module.subModuleHelperText}</p>
                  {
                    completed === true ?
                      <span style={{ position: 'absolute', bottom: '0', left: '0', margin: '1rem 2rem' }}>
                        <img src={Completed} uk-tooltip="مكتمل" style={{ height: '2rem', width: '2rem' }} alt="" />
                      </span>
                      :
                      <span style={{ position: 'absolute', bottom: '0', left: '0', margin: '1rem 2rem' }}>
                        <img src={Todo} uk-tooltip="يحتاج إلى الاكتمال" style={{ height: '2rem', width: '2rem' }} alt="" />
                      </span>
                  }

                </div>
              ) : (
                <div class="uk-card-body">
                  <span class="uk-card-badge uk-label" style={{ top: "45px" }}>
                    <i class="fas fa-clock" aria-hidden="true"></i>
                    <span> {sub_module.subModuleOverview} </span> {t('minutes')}
                  </span>
                  <h4 style={{ width: "85%" }} >{num}. {sub_module.subModuleTitle}</h4>
                  <p>{sub_module.subModuleHelperText}</p>
                  {/* <span class="uk-card-badge uk-label" style={{ top: "290px" }}><i class="fas fa-clock" aria-hidden="true"></i><span> {sub_module.subModuleOverview} </span> min</span> */}
                  {
                    completed === true ?
                      <span style={{ position: 'absolute', bottom: '0', right: '0', margin: '1rem 2rem' }}>
                        <img src={Completed} uk-tooltip={localStorage.LANG == 'sp' ? "Completado" : localStorage.LANG == 'fr' ? "Complété" : "Completed"} style={{ height: '2rem', width: '2rem' }} alt="" />
                        {/* <text style={{marginLeft: '0.3rem', fontSize: '0.8rem'}}>Completed</text> */}
                      </span>
                      :
                      <span style={{ position: 'absolute', bottom: '0', right: '0', margin: '1rem 2rem' }}>
                        <img src={Todo} uk-tooltip={localStorage.LANG == 'sp' ? "Por completar" : localStorage.LANG == 'fr' ? "À terminer" : "To be Completed"} style={{ height: '2rem', width: '2rem' }} alt="" />
                      </span>
                  }
                </div>
              )
            }

          </div>
        </div>
      </a>
    );
  }
}

// <div onClick={() => this.props.get_sub_module()} className="uk-card uk-card-small uk-card-body uk-padding-remove-right uk-card-default uk-margin uk-padding-remove" style={{ cursor: "pointer" }}>
// <img src={sub_module.subBannerIconUrl} className="uk-align-left uk-width-1-3@m uk-margin-remove-bottom" />
// <div className="uk-padding-small">
//     {/* <p className="uk-text-right">Submoduless {this.props.num}</p> */}
//     <a href="" className="uk-link-reset">  <h4 className="uk-margin-small-bottom uk-margin-small-top">{sub_module.subModuleTitle}</h4>  <p>{sub_module.subModuleHelperText}</p> </a>
// </div>
// <br />
// </div>

export function Card_loading_sub_module() {
  return (
    <ContentLoader
      height={240}
      speed={1}
      primaryColor={"#ced1d2"}
      secondaryColor={"#fff"}
    >
      <rect x="0" y="0" rx="5" ry="5" width="100" height="70" />

      <rect x="110" y="17" rx="4" ry="4" width="300" height="5" />

      <rect x="110" y="40" rx="3" ry="3" width="250" height="5" />

      <rect x="0" y="75" rx="5" ry="5" width="100" height="70" />

      <rect x="110" y="92" rx="4" ry="4" width="300" height="5" />

      <rect x="110" y="115" rx="3" ry="3" width="250" height="5" />

      <rect x="0" y="150" rx="5" ry="5" width="100" height="70" />

      <rect x="110" y="167" rx="4" ry="4" width="300" height="5" />

      <rect x="110" y="190" rx="4" ry="4" width="250" height="5" />
    </ContentLoader>
  );
}

export function Card_loading_content() {
  return (
    <ContentLoader
      height={240}
      speed={1}
      primaryColor={"#ced1d2"}
      secondaryColor={"#fff"}
    >
      <rect x="0" y="35" rx="3" ry="3" width="190" height="10" />

      <rect x="0" y="60" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="65" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="70" rx="3" ry="3" width="250" height="2" />

      <rect x="0" y="80" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="85" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="90" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="95" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="100" rx="3" ry="3" width="250" height="2" />

      <rect x="0" y="110" rx="3" ry="3" width="250" height="2" />
      <rect x="0" y="115" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="120" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="125" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="130" rx="3" ry="3" width="390" height="2" />
      <rect x="0" y="135" rx="3" ry="3" width="390" height="2" />
    </ContentLoader>
  );
}

export default withNamespaces()(Card_sub_module);

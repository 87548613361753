import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

//generics
import { ippfauthtoken, USER_ID, EMAIL, FIRST_NAME, LAST_NAME, USER_TYPE } from '../../../constants/genarics';

class Admin_header extends React.Component {
    constructor(props) {
        super(props);
    }

    logout = () => {
        setTimeout(() => {
            localStorage.removeItem(ippfauthtoken);
            localStorage.removeItem(USER_ID);
            localStorage.removeItem(EMAIL);
            localStorage.removeItem(FIRST_NAME);
            localStorage.removeItem(LAST_NAME);
            localStorage.removeItem(USER_TYPE);
            this.props.history.push('/');
            // window.location.reload(); 
        }, 500)
    }

    editProfile = () =>{
        this.props.history.push('/admin_edit_profile');
    }

    render() {

        return (
            <nav className="uk-navbar">
                <div className="uk-navbar-left">
                    <ul className="uk-navbar-nav">
                        {/* <li className="uk-active">
                            <a href="">Dashboard</a>
                        </li>
                        <li>
                            <a href=""> Admin</a>
                        </li> */}
                    </ul>
                </div>

                <div className="uk-navbar-right">
                    <a className="clicked" href="#">
                        <i  className="fas fa-user icon-large uk-border-circle user-profile-tiny"></i>
                        {/* <img src="../../../assets/images/avatures/avature-2.png" alt="" class="uk-border-circle user-profile-tiny" />  */}
                    </a>
                    <div uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-bottom-small" className="uk-dropdown uk-padding-small angle-top-right uk-dropdown-bottom-right" >
                        <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold"> {localStorage.FIRST_NAME + " " + localStorage.LAST_NAME} </p>
                        <ul className="uk-nav uk-dropdown-nav">
                            <br/>
                            <li onClick={() => this.editProfile()}>
                                <a> <i className="fas fa-user uk-margin-small-right"></i> Change Password</a>
                            </li>
                            {/* <li>
                                <a href=""> <i className="fas fa-cog uk-margin-small-right"></i> Setting</a>                              
                            </li> */}
                            <li className="uk-nav-divider"></li>
                            <li onClick={() => this.logout()}>
                                <a> <i className="fas fa-sign-out-alt uk-margin-small-right"></i> Log out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}


export default withRouter(withApollo((Admin_header))); 

import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import moment from "moment";

//queries
import {
  CREATE_ANNOUNCEMENT,
  UPDATE_MODULE,
  UPDATE_ANNOUNCEMENT,
} from "../../../queries/admin_queries";
import { GET_SINGLE_ANNOUNCEMENT } from "../../../queries/common_queries";

//redux-form
import { Field, FieldArray, reduxForm } from "redux-form";

import { Button } from "react-uikit";

import { Input, Popup, Grid } from "semantic-ui-react";

import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import CKUploadAdapter from "../../../middleware/ck-file-upload-adapter";

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
    languageKeys: state.custom_reducer.languageKeys,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class AddAnnouncement extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");

    if (id === null) {
      this.state = {
        announcementId: "",
        formAction: "ADD",
        formLanguage: "en",
        description: "",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: "",
        isActive: "",
        loading: false,
        supportingLanguages: [],
        supportingLangErrorMsg: "",
        title: "",
        date: "",
        url: "",
        showExpDate: false,
      };
    } else {
      this.state = {
        announcementId: query.get("id"),
        formAction: "EDIT",
        formLanguage: "en",
        description: "",
        startDate: "",
        endDate: "",
        isActive: "",
        loading: false,
        supportingLanguages: [],
        supportingLangErrorMsg: "",
        title: "",
        date: "",
        url: "",
        showExpDate: false,
        languageEnabled: "",

      };
    }

    this.handleChange = this.handleChange.bind(this);

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.state.formAction == "EDIT") {
      this.getSingleAnnouncement(this.state.formLanguage).then((result) => {
        console.log(result);
        this.setState({
          description: result.description,
          title: result.title,
          startDate: result.startDate,
          endDate: result.endDate,
          url: result.url,
          showExpDate: result.showExpDate,
          date: result.date,
          languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true
        });
      });
    } else {
      this.setState({ loading: false });
    }
    //supporting languages array
    if (this.state.formAction === "ADD") {
      const supportingLanguages = this.props.translatingLanguages;
      this.setState({
        supportingLanguages: supportingLanguages,
      });
    }
    this.setState({ loading: false });
  }

  handleChange(event) {
    console.log(event);
    this.setState({ [event.target.id]: event.target.value });
    this.props.setFormStatus({
      status: false,
      title: "",
      message: "",
    });
    this.props.setSuccessStatus({
      status: false,
      title: "",
      message: "",
    });
  }

  handleCheckboxChange = (e) => {
    const {showExpDate} = this.state;
    var status = "";

    if (showExpDate === true) {
      status = false;
    }
    else {
      status = true;
    }
    this.setState({
      showExpDate: status,
    })
    console.log(status)
  }

  createAnnouncement = async () => {
    const { description, startDate, endDate, title, date, url, showExpDate } = this.state;
    const result = await this.props.client.mutate({
      mutation: CREATE_ANNOUNCEMENT,
      variables: {
        title: title,
        description: description,
        startDate: startDate,
        endDate: endDate,
        isActive: true,
        date: date,
        url: url,
        supportingLangs: { languages: [] },
        showExpDate: showExpDate,
      },
    });
    console.log(result.data);
    return result.data.createAnnouncement;
  };

  getSingleAnnouncement = async (language) => {
    const { announcementId, formLanguage } = this.state;
    const result = await this.props.client.mutate({
      mutation: GET_SINGLE_ANNOUNCEMENT,
      variables: {
        announcementId: announcementId,
        lang: language,
      },
    });
    console.log(result.data);
    return result.data.getSingleAnnouncement;
  };

  updateAnnouncement = async (language) => {
    const {
      announcementId,
      description,
      startDate,
      endDate,
      title,
      date,
      url,
      showExpDate,
      languageEnabled
    } = this.state;
    const result = await this.props.client.mutate({
      mutation: UPDATE_ANNOUNCEMENT,
      variables: {
        title: title,
        announcementId: announcementId,
        description: description,
        startDate: startDate,
        endDate: endDate,
        date: date,
        url: url,
        lang: language,
        showExpDate: showExpDate,
        languageEnabled
      },
    });
    console.log(result.data);
    return result.data.updateAnnouncement;
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/admin_home");
    } else if (path == "MANAGEANNOUNCEMENT") {
      this.props.history.push("/admin_announcements");
    } else {
      return false;
    }
  };

  changeFormLanguage = (language) => {
    const id = this.state.moduleId;
    this.getSingleAnnouncement(language).then((result) => {
      console.log(result);
      this.setState({
        description: result.description,
        title: result.title,
        startDate: result.startDate,
        endDate: result.endDate,
        url: result.url,
        showExpDate: result.showExpDate,
        date: result.date,
        formLanguage: language,
        languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true
      });
    });
  };

  handleFormSubmit = () => {
    console.log("CLICKED!");
    const { description, startDate, endDate } = this.state;
    this.setState({
      loading: true,
    });

    if (this.state.date === "") {
      const tempDate = this.state.startDate;
      this.setState({
        date: tempDate,
      });
    }

    if (description === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a description for the announcement.",
      });
      this.setState({ loading: false });
    } else if (startDate === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a start date for the announcement.",
      });
      this.setState({ loading: false });
    } else if (endDate === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide an end date for the announcement.",
      });
      this.setState({ loading: false });
    } else if (endDate < startDate) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a valid end date.",
      });
      this.setState({ loading: false });
    } else {
      this.createAnnouncement()
        .then((result) => {
          this.props.setSuccessStatus({
            status: true,
            title: "",
            message: "Announcement created successfully!",
          });
          this.setState({
            loading: false,
            description: "",
            startDate: moment().format("YYYY-MM-DD"),
            endDate: "",
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.setFormStatus({
            status: true,
            title: "Oops!",
            message:
            <div>
              An error occurred while creating the announcement! <br/>
              Please try again.
            </div>
          });
          this.setState({ loading: false });
        });
    }
  };

  handleFormUpdate = () => {
    console.log("CLICKED!");
    const { description, startDate, endDate } = this.state;
    this.setState({
      loading: true,
    });
    if (description === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a description for the announcement.",
      });
      this.setState({ loading: false });
    } else if (startDate === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a start date for the announcement.",
      });
      this.setState({ loading: false });
    } else if (endDate === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide an end date for the announcement.",
      });
      this.setState({ loading: false });
    } else if (endDate < startDate) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a valid end date.",
      });
      this.setState({ loading: false });
    } else {
      this.updateAnnouncement(this.state.formLanguage)
        .then((result) => {
          this.props.setSuccessStatus({
            status: true,
            title: "",
            message: "Announcement updated successfully!",
          });
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.props.setFormStatus({
            status: true,
            title: "Oops!",
            message:
              "An error occurred while updating the announcement. Please try again!",
          });
          this.setState({ loading: false });
        });
    }
  };

  handleCheckBoxChange = (status) => {
    this.setState({ languageEnabled: status });
  }

  render() {
    const {
      announcementId,
      formAction,
      formLanguage,
      description,
      startDate,
      endDate,
      isActive,
      loading,
      supportingLanguages,
      supportingLangErrorMsg,
    } = this.state;
    const {
      handleSubmit,
      formErrorStatus,
      formSuccessState,
      translatingLanguages,
    } = this.props;

    // console.log(endDate < startDate);
    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>Home</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("MANAGEANNOUNCEMENT")}>
                Manage Announcements
              </a>
            </li>
            {formAction == "ADD" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("ADDANNOUNCEMENT")}>
                  Add Announcement
                </a>
              </li>
            ) : formAction == "EDIT" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("EDITANNOUNCEMENT")}>
                  Edit Announcement
                </a>
              </li>
            ) : null}
          </ul>
        </div>

        <div className="uk-flex-inline uk-flex-middle">
        <i className="fas fa-bullhorn uk-margin-right" style={{fontSize: '2.3rem'}}></i>

          {formAction == "ADD" ? (
            <h4 className="uk-margin-remove"> Add New Announcement </h4>
          ) : formAction == "EDIT" ? (
            <h4 className="uk-margin-remove"> Edit Announcement </h4>
          ) : null}
        </div>

        <div className="uk-margin-medium">
          <form className="ui form">
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="fas fa-bullhorn uk-margin-small-right"></i>{" "}
                    Announcement Details
                    {
                      formAction == "EDIT" ?
                      translatingLanguages.length > 0 ?
                      <div className="formLanguageSwitcherDiv">
                        {
                        translatingLanguages.map(languageTab => (
                          <span circular icon="download" className={ formLanguage === languageTab.key ? "formLanguageSwitcherActive"  : "formLanguageSwitcher" } onClick={() => this.changeFormLanguage(languageTab.key)}>
                          {languageTab.language}
                          </span>
                        ))
                        }
                      </div>
                        : null
                      :null
                    }
                    {/* {formAction == "EDIT" ? (
                      <div className="formLanguageSwitcherDiv">
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "en"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("en")}
                        >
                          English
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "fr"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("fr")}
                        >
                          French
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "ar"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("ar")}
                        >
                          Arabic
                        </span>
                      </div>
                    ) : null} */}
                  </div>

                  <div className="uk-card-body uk-padding-remove-top">

                  {
                      this.state.formAction === "EDIT" ?
                          this.state.formLanguage != "en" ?
                              <div className="disableLanguageVersionDiv2">
                                  <input type="checkbox" id="languageEnabled" name="languageEnabled" checked={this.state.languageEnabled} onChange={() => this.handleCheckBoxChange(!this.state.languageEnabled)} />
                                  <label>{this.props.languageKeys[this.state.formLanguage]} version enabled</label>
                              </div>
                          : null
                      : null
                    }

                    <div className="uk-form-label"> Announcement Title</div>
                    <input
                      value={this.state.title || ""}
                      className="uk-textarea"
                      rows="1"
                      id="title"
                      name="title"
                      type="text"
                      maxLength={75}
                      onChange={this.handleChange}
                    ></input>                 
                    <div
                      style={{
                        fontSize: "0.7rem",
                        color: "gray",
                        width: "fit-content",
                        margin: "0rem 0rem 0rem auto",
                      }}
                    >
                      {this.state.title.length}/75
                    </div>
                    
                    <div className="uk-form-label"> Description </div>
                    <textarea
                      value={this.state.description || ""}
                      className="uk-textarea"
                      rows="3"
                      id="description"
                      name="description"
                      maxLength={310}
                      onChange={this.handleChange}
                    ></textarea>
                    <div
                      style={{
                        fontSize: "0.7rem",
                        color: "gray",
                        width: "fit-content",
                        margin: "0rem 0rem 0rem auto",
                      }}
                    >
                      {this.state.description.length}/310
                    </div>
                    <div className="uk-form-label"> Date of Publication </div>
                    <input
                      value={this.state.date || ""}
                      className="uk-textarea"
                      rows="2"
                      id="date"
                      name="date"
                      type="date"
                      onChange={this.handleChange}
                    ></input>
                    <div className="uk-form-label"> External Url</div>
                    <input
                      value={this.state.url || ""}
                      className="uk-textarea"
                      rows="1"
                      id="url"
                      name="url"
                      type="text"
                      onChange={this.handleChange}
                    ></input>
                    <br />
                    <br />
                    <hr />
                    <div className="uk-form-label">
                      {" "}
                      Announcement should be displayed from{" "}
                    </div>
                    <input
                      value={this.state.startDate || ""}
                      className="uk-textarea"
                      rows="2"
                      id="startDate"
                      name="startDate"
                      type="date"
                      onChange={this.handleChange}
                    ></input>

                    <div className="uk-form-label"> to </div>
                    <input
                      value={this.state.endDate || ""}
                      className="uk-textarea"
                      rows="2"
                      id="endDate"
                      name="endDate"
                      type="date"
                      onChange={this.handleChange}
                    ></input>
                    <input
                      type="checkbox"
                      id="showExpDate"
                      name="showExpDate"
                      value={true}
                      checked={this.state.showExpDate}
                      onChange={this.handleCheckboxChange}
                    />
                    <label  className="uk-form-label" for="showExpDate"> Show expiration date in the announcement</label>
                    <br></br>
                  </div>
                </div>
                {formAction === "ADD" ? (
                  <Button
                    className="uk-button uk-button-primary uk-margin"
                    style={{ height: 40 }}
                    type="button"
                    disabled={loading ? true : false}
                    onClick={() => this.handleFormSubmit()}
                  >
                    {loading ? (
                      <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                ) : (
                  <Button
                    className="uk-button uk-button-primary uk-margin"
                    style={{ height: 40 }}
                    type="button"
                    disabled={loading ? true : false}
                    onClick={() => this.handleFormUpdate()}
                  >
                    {loading ? (
                      <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                )}

                {formErrorStatus.status ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                ) : formSuccessState.status ? (
                  <div className="success_alert">
                    {formSuccessState.message}{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddAnnouncement = connect(mapStateToProps, mapDispatchToProps)(AddAnnouncement);

export default withApollo(reduxForm({ form: "fieldArrays" })(AddAnnouncement));

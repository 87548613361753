
import * as React from 'react'

class AboutUsBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="topic1 hero-bg shadow-small">
                <div className="uk-grid">        
                        <h3 className="uk-light uk-text uk-text-bold uk-text-white"> About Us ... </h3>           
                </div>                 
            </div>
        )
    }
}

export default AboutUsBanner; 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

//custom-components
import { Card_loading_module } from '../../module/card_module/card_module';


//queries
import { GET_TOOLS } from '../../../queries/admin_queries';
import result from '../../../screens/user/result/result';


class LearningContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tools: [],
            loading_modules: [
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />
            ],
            pagination: {
                skip: 0,
                first: 12,
                lang: localStorage.LANG,
            },
            loading: false,
            next_module: "",
            nextPage: 1,
            previousPage: 0,
            currentPage: 0,
            activePage: "",
            ToDos: 12,
            isPrevBtnActive: "disabled",
            isNextBtnActive: "",
            isFirstBtnActive: "disabled",
            isLastBtnActive: "",
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, index) {
        this.setState({
            currentPage: Number(index),
            activePage: index,
        });
    }

    componentDidMount() {
        this.setState({ loading: true });
        //Get Tools
        this.get_tools(this.state.pagination).then((result) => {
            console.log(result)
            var tool_opt = result.tools.map((tool) => {
                return {
                    id: tool.id,
                    title: tool.title,
                    image: tool.image,
                    url: tool.url
                };
            });
            this.setState({ count: result.count, tools: tool_opt, loading: false });
        });
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    get_tools = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_TOOLS,
            variables: pagination,
            fetchPolicy: "network-only",
        });
        return result.data.getTools;
    };

    handlePagination = async (pageNo) => {
        console.log(pageNo);
        this.setState({
            currentPage: pageNo
        });

        if (pageNo >= 0) {
            this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
            this.state.nextPage = pageNo + 1;
        }

        if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
            this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
            this.state.previousPage = pageNo - 1;
        }

        if (pageNo === 0) {
            this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
        }

        var paginations = {
            skip: pageNo * this.state.ToDos,
            first: 12,
            lang: "en"
        };

        if (pageNo > 0) {
            this.get_tools(paginations).then(result => {
                this.setState({ tools: result.tools, count: result.count });
            })
        } else {
            this.get_tools(this.state.pagination).then(result => {
                this.setState({ tools: result.tools, count: result.count });
            })
        }
    }

    render() {
        const { loading, loading_modules, tools, count, previousPage, nextPage, currentPage, ToDos, isPrevBtnActive, isNextBtnActive, isFirstBtnActive, isLastBtnActive } = this.state;
        const pages = Math.ceil(count / ToDos);
        console.log(pages)
        if (pages === 0) return null;
        var items = [];
        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }

        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className="fas fa-angle-left"></i> </span></li>
        } else {
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
        }

        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
        }
        else {
            renderNextBtn = <li className={isNextBtnActive}><a onClick={() => this.handlePagination(nextPage)} id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
        }

        let renderfirstBtn = null;
        if (isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
        } else {
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
        }

        let renderlastBtn = null;
        if (isLastBtnActive === 'disabled') {
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
        } else {
            renderlastBtn = <li className={isLastBtnActive}><a onClick={() => this.handlePagination(items.length - 1)} id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
        }

        return (
            <div className="uk-container">
                <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin uk-grid" uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" >
                    {loading ? loading_modules.map((module, i) => (
                        <Card_loading_module key={i} />
                    ))
                        : tools.map((tool, i) =>
                            <div >
                                <div className="uk-card uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
                                    <a href={tool.url} target="_blank" className="uk-link-reset" >
                                        <img src={tool.image} style={{ maxHeight: "190px", width: "100%", height: "100%", objectFit: "cover", minHeight: "190px" }} />
                                        <div className="uk-card-body">
                                            <hr />
                                            <h4>{tool.title}</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )}

                    {/* <div >
                        <div className="uk-card uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
                            <a href="https://bp.seippf.org/" target="_blank"  className="uk-link-reset" >
                                <img src={require("../../../assets/images/bptool.svg")} className="course-img" style={{ maxHeight: "260px", objectFit: "cover", width: "100%", height: "100%" }} />
                                <div className="uk-card-body">
                                <hr/>
                                    <h4>Business Plan Tool </h4>
                                </div>
                            </a>
                        </div>
                    </div> */}
                    <div >
                        {/* <div className="uk-card uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle " tabIndex="0">
                            <a href="https://planet361.com/" target="_blank"  className="uk-link-reset" >
                                <img src={require("../../../assets/images/e-commerce2.png")} className="course-img" style={{ maxHeight: "180px",minHeight:"180px", objectFit: "cover", width: "100%", height: "100%" }} />
                                <div className="uk-card-body" style={{position:"fixed", bottom:21}}>
                                    <hr/>
                                    <h4> E-Commerce/Online Store for IPPF MAs </h4>
                                </div>
                            </a>
                        </div> */}
                    </div>

                    {/* <div>
                        <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0" uk-height-match="target: .uk-card-body">
                            <a href="http://ra.seippf.org" target="_blank" className="uk-link-reset" >
                                <img src={require("../../../assets/images/businessimg.jpg")} className="course-img global-img-size"/>
                                <div className="uk-card-body">
                                    <h4>SE Readiness Assessment Tool</h4>                                  
                                </div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0" uk-height-match="target: .uk-card-body" >
                            <a href="https://bp.seippf.org/" target="_blank"  className="uk-link-reset" >
                                <img src={require("../../../assets/images/bptool.svg")}  />
                                <div className="uk-card-body" >
                                    <h4>Business Planning Tool </h4>
                                </div>
                            </a>
                        </div>
                    </div> */}
                </div>
                {count >= 12 ? (
                    <div className="paginations uk-flex uk-flex-center">
                        <ul className="uk-pagination" uk-grid>
                            {renderfirstBtn}
                            {renderPrevBtn}
                            {
                                items.map((item, index) =>
                                    <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                                )
                            }
                            {renderNextBtn}
                            {renderlastBtn}
                        </ul>
                    </div>
                ) : (null)}
            </div>
        );
    }
}

export default withRouter(withApollo(LearningContainer)); 
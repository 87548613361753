import React, { Component} from "react"
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {GET_NEXT_MODULE } from '../../queries/common_queries'

class NextButton extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            next_module: this.props.moduleId,
        };
    }

    redirectToNextModule = () => {
        this.get_next_module().then(result=>{
        this.props.history.push('/module?id='+ result[0].id );
          })
    }

    get_next_module =async ()=> {
        const result = await this.props.client.query({
            query: GET_NEXT_MODULE,
            variables:{
                moduleId: this.props.moduleId,
                first: 1
            },
            // fetchPolicy: 'cache-first'
            fetchPolicy:'network-only'
        });
        return result.data.getNextModule;
    };

    render(){
        return(
                 <button  className="uk-button uk-button-primary float" onClick={() => this.redirectToNextModule()} > Next </button>
              )
    }

}
export default withRouter(withApollo(NextButton));
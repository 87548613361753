import * as React from 'react';

class News_banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { module } = this.props;

        return (
            <div>

                <ul className="uk-switcher switcher-container">
                    <li>
                        <div className="topic1 hero-bg">
                            <div className="uk-grid">
                                <div className="uk-width-1-2@m">
                                    <h1 className="uk-animation-fade"> News    </h1>
                                    <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p>
                                    <div className="uk-visible@m uk-animation-slide-bottom-small uk-grid" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="topic2 hero-bg">
                            <div className="uk-grid">
                                <div className="uk-width-1-2@m">
                                    <h1 className="uk-animation-fade"> Welcome {localStorage.FIRST_NAME + " " + localStorage.LAST_NAME}    </h1>
                                    <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="topic3 hero-bg">
                            <h1 className="uk-animation-fade"> News </h1>
                            <p> News & Educational articles from the IPPF and our partners <br />from around the globe. Keep up to date and informed. </p>
                        </div>
                    </li>
                    <li>
                        <div className="topic4 hero-bg">
                            <h1 className="uk-animation-fade"> Welcome {localStorage.FIRST_NAME + " " + localStorage.LAST_NAME}    </h1>
                            <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p>
                        </div>
                    </li>
                </ul>

                <button className="uk-button uk-hidden@m  mobile-catagory-button" type="button" uk-toggle="target: #tabs-moible; cls: tabs-moible"> Open subcatagory </button>

                <ul className="uk-subnav uk-subnav-pill tabs-moible-hidden nav-menues" uk-switcher="connect: .switcher-container" id="tabs-moible">
                    <li>
                        <a href="#"> Learning </a>
                    </li>
                    <li>
                        <a href="#"> Tools </a>
                    </li>
                    <li className="uk-active">
                        <a href="#"> News </a>
                    </li>
                    <li>
                        <a href="#"> Galleries  </a>
                    </li>
                    <li>
                        <a href="#"> Resources  </a>
                    </li>
                </ul>
            </div>
        )
    }
}

export default News_banner; 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import ResultBanner from '../../../components/result/resultBanner/resultBanner';
import ResultContainer from '../../../components/result/resultContainer/resultContainer';
import Footer from '../../../components/footer/footer';

import { withNamespaces } from 'react-i18next';
//queries
import { GET_LESSONS } from '../../../queries/common_queries';

class Result extends React.Component {
    constructor(props) {
        super(props);
        if(localStorage.USER_ID==undefined){
            this.props.history.push('/');
        }
    }

    render() {
        const { t } = this.props;
        return ( 
            <div>
                <Navbar />
                <div className="app">
                    <Header />
                    <ResultBanner />
                    <ResultContainer />
                    <Footer language={localStorage.LANG} />
                </div>
            </div>
        );
    }
}

export default withRouter(withApollo(withNamespaces()(Result))); 
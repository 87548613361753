import { LOGGING_USER,FORM_STATUS,SUCCESS_STATUS, NOTIFICATION_STATUS, NEXT_SUB_MODULE_STATUS, VIEW_EXAM_SUBMISSIONS, RATINGS_MODAL_HANDLER, OPEN_BLURR_MODAL, STAT_DATES_HANDLER } from '../constants/index';

export function loginUser(user) {
    return {type: LOGGING_USER, user};
}

export const setFormStatus = (status) => {
    return {type: FORM_STATUS, status}
}

export const setSuccessStatus = (status) => {
    return {type: SUCCESS_STATUS, status}
}

export const setNotofications = (notifications) => {
    return {type: NOTIFICATION_STATUS, notifications}
}

export const setNextSubModuleStatus = (status) => {
    return {type: NEXT_SUB_MODULE_STATUS, status}
}

export const setViewingExam = (id) => {
    return {type: VIEW_EXAM_SUBMISSIONS, id}
}

export const setModalStatus = (status, category) => {
    return {type: RATINGS_MODAL_HANDLER, status, category}
}

export const setBlurrModalStatus = (status, category) => {
    return {type: OPEN_BLURR_MODAL, status, category}
}

export const setStatDates = (dates) => {
    return {type: STAT_DATES_HANDLER, dates}
}
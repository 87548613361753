import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import { Button } from 'react-uikit';

//components
import Admin_navbar from '../../../components/admin/admin_navbar/admin_navbar';
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import EditProfileBanner from '../../../components/edit_profile/edit_profile_banner/edit_profile_banner';
import EditProfileForm from '../../../components/edit_profile/edit_profile_form/edit_profile_form';
import { withNamespaces } from "react-i18next";

//read Api data
import { GET_USER_PROFILE } from '../../../queries/common_queries';

import { UPDATE_USER } from '../../../queries/user_queries';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class Edit_user_profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    render() {
        const { loading } = this.state;
        const { formErrorStatus, formSuccessState, t } = this.props;
        return (
            <div>
                <Navbar />
                <div>
                    <Header />
                    <div className="app">
                        {loading ? (
                            <div className="single-news-header" data-src='' uk-img="" class="single-news-header uk-height-large uk-background-norepeat  uk-background-center-center    uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover">
                                <div class="uk-width-1-1 uk-container uk-margin-large-top uk-padding-remove-bottom">
                                    <h1 class=" uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top">{t('wait')} </h1>
                                    <p class="  uk-margin-large-left  uk-text-white">
                                        {t('loading')}</p>
                                </div>
                            </div>
                        ) : (
                                [<EditProfileBanner />,
                                <EditProfileForm />]
                            )}
                    </div>
                    <Footer language={localStorage.LANG} />
                </div>
            </div>
        );
    }
}


export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Edit_user_profile))));

// export default Admin_edit_profile;
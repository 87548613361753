import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

//admin components
import Admin_header from '../../../components/admin/admin_header/admin_header'
import Admin_navbar from '../../../components/admin/admin_navbar/admin_navbar'

// header
import Header from '../../../components/admin/admin_header/admin_header';

//screens
import EditGallery from '../editGallery/editGallery'
import User_list from '../user_list/user_list';
import AddModule from '../addModule/addModule';
import AddSubModule from '../addSubModule/addSubModule';
import AddLesson from '../addLesson/addLesson';
import AddContent from '../addContent/addContent';
import CreateQuiz from '../createQuiz/createQuiz';
import QuizView from '../quizView/quizView';
import QuizEdit from '../quizEdit/quizEdit';
import Login from '../../user/login/login';
import AddNews from '../addNews/addNews';
import AddGallery from '../addGallery/addGallery';
import AddGalleryImage from '../addGalleryImage/addGalleryImage';
import Admin_edit_profile from '../admin_edit_profile/admin_edit_profile';
import NewsList from '../news_list/news_list';
import EditNews from '../editNews/editNews';
import Admin_register from '../admin_register/admin_register';
import DocumentUpload from '../documentUpload/documentUpload';
import DocumentList from '../documentUpload/documentList';
// import Admin_profile from '../admin_profile/admin_profile'
import AddAnnouncement from '../announcements/addAnnouncement';
import ManageAnnouncements from '../announcements/announcementList';

import ManageModules from '../manageModules/manageModules';
import ManageSubModules from '../manageSubModules/manageSubModules';
import ManageLessons from '../manageLessons/manageLessons';
import GalleryList from '../manageGallery/galleryList'
import Dashboard from '../Dashboard/dashboard'
import KnowledgeTestingCentre from '../knowledgeTestingCenter/manageKTC';
import AddExam from '../knowledgeTestingCenter/addExam';
import ExamSubmissions from '../knowledgeTestingCenter/examSubmissions';
import UserFeedbacks from '../userFeedbacks/manageUserFeedbacks';
import manageTools from '../manageTool/manageTools';
import AddTool from '../manageTool/addTool';

import { GET_ALL_USERS } from '../../../queries/common_queries';
//routes
import { Switch, Route, Redirect, withRouter, BrowserRouter as Router, Link } from "react-router-dom";
import manageLessons from '../manageLessons/manageLessons';
import SingleExamSubmission from '../knowledgeTestingCenter/singleExamSubmission';
//import indexRoutes from '../../../routes/indexRoutes';
import UploadVideos from '../../admin/addVideo/uploadVideos';
const indexRoutes = [
  {
    path: '/admin_dashboard',
    name: "Dashboard",
    icon: "fas fa-home fa-fw",
    isVisible:true,
    component: Dashboard
  },
  {
    path: '/admin_user_list',
    name: "User Profiles",
    icon: "fas fa-user fa-fw",
    isVisible:true,
    component: User_list
  },
  {
    path:'/admin_registration',
    name: "Register System User",
    icon: "far fa-user fa-fw",
    isVisible:true,
    component: Admin_register

  },
  {
    path: '/admin_manage_modules',
    name: "Manage Learning modules",
    icon: "fas fa-list fa-fw",
    isVisible:true,
    component: ManageModules
  },
  {
    path: '/admin_manage_submodules',
    name: "Manage Submodules",
    icon: "fas fa-th fa-fw",
    isVisible:true,
    component: ManageSubModules
  },
  {
    path: '/admin_create_quiz',
    name: "Create Quiz",
    icon: "fas fa-question-circle fa-fw",
    isVisible:false,
    component: CreateQuiz
  },
  {
    path:'/admin_quiz_view',
    name: "Manage Quiz",
    icon: "fas fa-question-circle fa-fw",
    isVisible:true,
    component: QuizView
  },
  {
    path:'/admin_knowledge_testing_center',
    name: "Manage SBL",
    icon: "far fa-newspaper fa-fw",
    isVisible:true,
    component: KnowledgeTestingCentre
  },
  {
    path: "/admin_manage_tools",
    name: "Manage Tools",
    icon: "fas fa-tools fa-fw",
    isVisible:true,
    component: manageTools,
  },
  {
    path: '/admin_add_tool',
    name: "Add Tool",
    icon: "fas fa-tools fa-fw",
    isVisible:false,
    component: AddTool
  },

  {
    path:'/admin_document_list',
    name: "Manage Resources",
    icon:"far fa-images fa-fw",
    isVisible:true,
    component: DocumentList
  },
  {
    path: '/admin_news_list',
    name: "Manage News",
    icon: "fa fa-newspaper fa-fw",
    isVisible:true,
    component: NewsList
  },
  {
    path:'/admin_gallery_list',
    name: "Manage Gallery",
    icon: "fas fa-image fa-fw",
    isVisible:true,
    component: GalleryList
  },
  {
    path: '/admin_announcements',
    name: "Manage Announcements",
    icon: "fas fa-bullhorn fa-fw",
    isVisible:true,
    component: ManageAnnouncements
  },
  {
    path:'/admin_quiz_edit',
    name: "Edit Quiz",
    icon:"fas fa-question fa-fw",
    isVisible:false,
    component: QuizEdit
  },
  {
    path:'/admin_add_gallery',
    name: "Add Gallery",
    icon: "fas fa-image fa-fw",
    isVisible:false,
    component: AddGallery
  },

  {
    path:'/admin_user_feedback',
    name: "User Feedback",
    icon: "far fa-star fa-fw",
    isVisible:true,
    component: UserFeedbacks
  },
  {
    path: '/admin_add_exam',
    name: "Add Exam",
    icon: "fas fa-list fa-fw",
    isVisible:false,
    component: AddExam
  },
  {
    path: '/admin_add_module',
    name: "Add Module",
    icon: "fas fa-list fa-fw",
    isVisible:false,
    component: AddModule
  },
  {
    path: '/admin_add_announcement',
    name: "Add Announcement",
    icon: "fas fa-list fa-fw",
    isVisible:false,
    component: AddAnnouncement
  },
  {
    path: '/admin_add_submodule',
    name: "Add Submodule",
    icon: "fas fa-th fa-fw",
    isVisible:false,
    component: AddSubModule
  },
  {
    path: '/admin_add_lesson',
    name: "Add Lesson",
    icon: "fas fa-book-open fa-fw",
    isVisible:false,
    component: AddLesson
  },
  {
    path: '/admin_add_content',
    name: "Add Content",
    icon: "fas fa-code fa-fw",
    isVisible:false,
    component: AddContent
  },
 {
    path: '/admin_add_news',
    name: "Add News",
    icon: "fa fa-edit fa-fw",
    isVisible:false,
    component: AddNews
  },
  {
    path: '/admin_edit_news',
    name: "Edit News",
    icon: "fa fa-edit fa-fw",
    isVisible:false,
    component: EditNews
  },
 
  {
    path:'/admin_edit_gallery',
    name: "Edit Gallery",
    icon: "fas fa-image fa-fw",
    isVisible:false,
    component: EditGallery
  },
  {
    path:'/admin_edit_profile',
    name: "Edit Profile",
    icon:"far fa-images fa-fw",
    isVisible:false,
    component: Admin_edit_profile

  },
  {
    path:'/admin_add_document',
    name: "Documents Upload",
    icon:"far fa-images fa-fw",
    isVisible:false,
    component: DocumentUpload

  },
  {
    path:'/admin_edit_document',
    name: "Documents Upload",
    icon:"far fa-images fa-fw",
    isVisible:false,
    component: DocumentUpload

  },
  {
    path:'/admin_exam_submissions',
    name: "Exam Submissions",
    icon: "fas fa-image fa-fw",
    isVisible:false,
    component: ExamSubmissions
  },
  {
    path:'/admin_exam_submission',
    name: "Single Exam Submission",
    icon: "fas fa-image fa-fw",
    isVisible:false,
    component: SingleExamSubmission
  },

// Temporary
{
  path:'/admin_add_video',
  name: "Upload File",
  icon: "fas fa-image fa-fw",
  isVisible:false,
  component: UploadVideos
},

]

class Admin_home extends React.Component {
  constructor(props) {
    super(props);

    var currentLocation = window.location.href;
    var url = currentLocation.split("?")
    url = url[0];
    url = url.split("/");
    var page = url[url.length - 1];

    this.state = {
      page: page
    }
  }

  render() {
    const { page } = this.state;
    
    return (
      // <Router>
        <div className="adminContant" style={{backgroundColor:"#edeff0"}}>
          <Admin_navbar routes={indexRoutes} />
          <div className="admin-content">
            <Admin_header />
            {indexRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                // exact={route.exact}
                component={route.component}
              />
            ))}

            {page == "admin_home" ? (
              /* <Redirect to="/admin_user_list" /> */
              <Redirect to="/admin_dashboard" />

            ) : (
                null
              )}

          </div>
        </div>
      // </Router>
    );
  }
}

export default withRouter(withApollo((Admin_home))); 
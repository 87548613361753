import * as React from 'react';
import Moment from 'react-moment';

export default function GalleryLoadingCard() {
    return <div>
        <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
            {/* <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                <a className="uk-button course-badge" href="#"> <i className="fas fa-heart icon-medium"></i> </a>
            </div>
            <div className="uk-transition-slide-right-medium uk-position-top-right uk-padding-small uk-margin-medium-right">
                <a className="uk-button uk-margin-small-right course-badge" href="#"> <i className="far fa-check-square icon-medium"></i> </a>
            </div> */}
            {/* <a href="" className="uk-link-reset" > */}
                {/* <img src="../../../assets/images/course/course-1.jpg" className="course-img" /> */}
                <div className="uk-card-body">
                <img src={require("../../../images/default.jpg")} className="course-img" />
                    <div className="loading_div"><h4 className="loading_text"></h4></div>
                    <hr className="uk-margin-remove-top" />
                    <div className="uk-clearfix">
                        <h4></h4>
                    </div>
                </div>
            {/* </a> */}
        </div>
    </div>;
}

import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';

const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyAT-ehZWASiI2J6tvHy3xEOgvMOr4XLsCI",
    authDomain: "ippf-7131f.firebaseapp.com",
    databaseURL: "https://ippf-7131f.firebaseio.com",
    projectId: "ippf-7131f",
    storageBucket: "gs://ippf-7131f.appspot.com",
    messagingSenderId: "393204276878",
    appId: "1:393204276878:web:38fd163398a79dd3bc33df",
    measurementId: "G-KYRS7W6S37"
};

firebase.initializeApp(config);

//firebase.firestore().settings(settings);

export default firebase;
export const LOGGING_USER = 'LOGGIN_USER';
export const FOUND_RESTRICTED = 'FOUND_RESTRICTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FORM_STATUS = 'FORM_STATUS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const UPLOAD_API_URL = 'https://ippfuploader.herokuapp.com'
export const NOTIFICATION_STATUS = 'NOTIFICATION_STATUS'
export const NEXT_SUB_MODULE_STATUS = 'NEXT_SUB_MODULE_STATUS'
export const VIEW_EXAM_SUBMISSIONS = 'VIEW_EXAM_SUBMISSIONS'
export const RATINGS_MODAL_HANDLER = 'RATINGS_MODAL_HANDLER'
export const RATING_LATER = 'RATING_LATER'
export const OPEN_BLURR_MODAL = 'OPEN_BLURR_MODAL'
export const STAT_DATES_HANDLER = 'STAT_DATES_HANDLER'
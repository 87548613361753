import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';


//queries
import { GET_MODULES_FOR_ADMIN, GET_SUB_MODULES_FOR_ADMIN } from '../../../queries/admin_queries';


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ManageSubModules extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }
        this.state = {
            loading: false,
            pagination: {
                // skip: 0,
                // first: 1000
            },
            submodule_pagination: {
                moduleId: "",
                // skip: 0,
                // first: 6
            },
            modules: [],
            subModules:[],
            previousPage:0,
            nextPage:1,
            currentPage:0,
            activePage:'',
            ToDos:6,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            isFirstBtnActive:'disabled',
            isLastBtnActive:'',
        }
        this.handleClick=this.handleClick.bind(this);
    }

    handleClick(event,index){
        this.setState({
            currentPage:Number(index),
            activePage:index
        })
    }

    componentDidMount() {
        this.loadModules();
    }

    componentDidUpdate() { window.scrollTo(0,0) }

    loadModules() {
        this.get_modules(this.state.pagination).then(result => {
            var module_opt = [];
            result.modules.map(module => (
                module_opt.push({ key: module.id, value: module.id, id: 'moduleId', label: module.moduleTitle })
            ));
            this.setState({ modules: module_opt });
        })
    };

    handleModuleChange = (event) => {
        var moduleId = event.target.value
        this.setState({ "moduleId": moduleId });

        var submodule_pagination = this.state.submodule_pagination;
        submodule_pagination['moduleId'] = moduleId;

        this.get_sub_modules(submodule_pagination).then(result => {
            this.setState({ subModules: result.subModules, count: result.count });
        });
    };

    handlePagination = async (pageNo) => {
        this.setState({
            currentPage:pageNo
        })

        var moduleId = this.state.moduleId;

        if(pageNo >=0){
            this.setState({ isNextBtnActive:'',isLastBtnActive:''})
            this.state.nextPage = pageNo + 1;
        }

        if(pageNo===Math.ceil(this.state.count / this.state.ToDos )-1){
            this.setState({ isNextBtnActive:'disabled',isLastBtnActive:'disabled'});
        }

        if (pageNo > 0) {
            this.setState({isPrevBtnActive:'',isFirstBtnActive:''})
            this.state.previousPage = pageNo - 1;
        }

        if(pageNo===0){
            this.setState({ isPrevBtnActive:'disabled',isFirstBtnActive:'disabled'})
        }

        var paginations = {
            moduleId: moduleId,
            skip: pageNo * this.state.ToDos,
            first: 6
        };

        this.get_sub_modules(paginations).then(result => {
            this.setState({ subModules: result.subModules, count: result.count });
        });
    }

    editSubModule(id) {
        this.props.history.push('/admin_add_submodule?id=' + id);
    }

    get_modules = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULES_FOR_ADMIN,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModulesForAdmin;
    };

    get_sub_modules = async (submodule_pagination) => {
        const result = await this.props.client.query({
            query: GET_SUB_MODULES_FOR_ADMIN,
            variables: submodule_pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModulesForAdmin;
    };

    addNewSubModule() {
        this.props.history.push('/admin_add_submodule');
    }

   
    render() {

        const { modules, subModules, loading, count, nextPage, previousPage ,currentPage,ToDos,isPrevBtnActive ,isNextBtnActive ,isFirstBtnActive,isLastBtnActive} = this.state;
        const pages = Math.ceil(count / ToDos);

        if (pages === 0) return null;

        var items = [];
        console.log(nextPage);

        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }

        let renderPrevBtn = null;
        if(isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className="fas fa-angle-left"></i> </span></li>
        } else{
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={()=> this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
        }

        let renderNextBtn = null;
        if(isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
        }
        else{
            renderNextBtn = <li className={isNextBtnActive}><a onClick={()=> this.handlePagination(nextPage) } id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
        }

        let renderfirstBtn = null;
        if(isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
        } else{
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={()=> this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
        }

        let renderlastBtn = null ;
        if(isLastBtnActive==='disabled'){
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
        }else{
            renderlastBtn = <li className={isLastBtnActive}><a onClick={()=>this.handlePagination(items.length -1)}  id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
        }

        console.log(modules);
        return (
            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-th icon-large uk-margin-right"></i>
                    <h4 className="uk-margin-remove adminSectionTitles">Submodules </h4>

                </div>
                <div className="uk-flex-inline uk-float-right">
                    <button className="uk-button uk-button-primary admin-btn" onClick={() => this.addNewSubModule()}>Add Submodule</button>
                </div>
                <br /> <br />
                
                <div>
                    <div className="uk-form-label"> Module </div>
                    <select className="uk-select uk-width-large" onChange={this.handleModuleChange} value={this.state.moduleId || ''}>
                        <option key="userType" value="" disabled>Select Module</option>
                        {modules.map(item => (
                            <option key={item.key} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                    <br /> <br />
                </div>
                <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                        <thead>
                            <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                                <th>Num</th>
                                <th style={{width: '30%'}}>Submodule Banner</th>
                                <th>Title</th>
                                <th>Helper Text</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                subModules.map((submodule, i) => (
                                    <tr key={i}>
                                        {/* {submodule.subModuleNumber}  */}
                                        <td> { i+1} </td>
                                        <td> <img src={submodule.subBannerIconUrl} />  </td>
                                        <td> {submodule.subModuleTitle}  </td>
                                        <td> {submodule.subModuleHelperText} </td>
                                        {/* <td> {submodule.isActive ? (
                                            <p style={{ color: "green" }}>ACTIVE</p>
                                        ) : (
                                                <p style={{ color: "red" }}>INACTIVE</p>
                                            )} </td> */}
                                        <td>
                                            <button disabled={loading ? (true) : (false)} className="uk-button uk-button-warning admin-table-btn" uk-tooltip="Edit" onClick={() => this.editSubModule(submodule.id)} ><i class='fas fa-pencil-alt'></i></button>


                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {/* <div className="paginations uk-flex uk-flex-center">
                    <ul class="uk-pagination" uk-margin>
                        {renderfirstBtn}
                        {renderPrevBtn}

                        {
                            items.map((item ,index) =>
                                <li key={index} id={index} active={this.state.activePage===index} onClick={(e)=>this.handleClick(e,item)} className={currentPage===item? 'active':''}  ><a onClick={() => this.handlePagination(item)}> {item + 1}</a></li>
                            )
                        }

                        {renderNextBtn}
                        {renderlastBtn}
                    </ul>
                </div> */}
            </div>
        );
    }

}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ManageSubModules))); 
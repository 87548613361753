import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Circle from "react-circle";
import InactiveUser from "../../images/inactiveUser.png";
import DownloadIcon from "../../images/download.png";
import InProgress from "../../images/inProgress.png";
import Ribbon from "../../images/ribbon.png";
import NoDataMessage from '../messages/contentNotFound/NoDataFoundSmall'
import CountUp from "react-countup";
import Bulb from '../../images/bulb.png';
import Complete from '../../images/complete.png';
import PassRate from '../../images/passRate.png';
import moment from "moment";
import { Doughnut, Pie } from "react-chartjs-2";
import { GET_MODULE_STATS } from "../../queries/admin_queries";

const mapStateToProps = (state, ownProps) => {
  return {
      statFromDate: state.custom_reducer.statFromDate,
      statToDate: state.custom_reducer.statToDate,
  }
}

class LearningStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // region: ['Africa', 'Arab World', 'European Network', 'East and Southeast Asia and Oceania', 'South Asia', 'Americas and the Caribbean'],
      regions: [
        { name: "Americas and the Caribbean", key: "ACR" },
        { name: "Africa", key: "Africa" },
        { name: "Arab World", key: "AWR" },
        { name: "European Network", key: "EN" },
        { name: "East and Southeast Asia and Oceania", key: "ESEAOR" },
        { name: "South Asia", key: "SAR" },
      ],
      modulesCount: "",
      subModulesCount: "",
      moduleAccessList: "",
      subModuleAccessList: "",
      modulePassList: "",
      subModulePassList: "",
      inactiveUsers: "",
      usersWithCertificates: "",
      overallModuleCompletionStatus: "",
      averageAttemptToPass: "",
      quizzesInProgress: "",
      quizSubmissions: "",
      fQSubmissions: "",
      totalUsers: '',

      chartData: "",
      mostAccessedModules: [],
      mostAccessedSubModules: [],
      leastAccessedModules: [],
      leastAccessedSubModules: [],
      mostPassedModules: [],
      mostPassedSubModules: [],
      // fontColors: ['#3bb3e3', '#ffcc00', '#2deb82', '#FF872B', '#0b246b', '#8a100e', '#0b246b', '#8a100e'],
      boxColors: ['#173F5F', '#3CAEA3', '#F6D55C', '#ff7361', '#3CAEA3', '#173F5F', '#ff7361', '#F6D55C'],
      box2Colors: ['#3e7a4e', '#3e7a4e', '#3e6c82', '#3e6c82', '#823e3e', '#823e3e',],
      fontColors: ['#FF7361', '#F6D55C', '#3CAEA3', '#173F5F', '#173F5F', '#3CAEA3'],
      fromDate: this.props.fromDate,
      toDate: this.props.toDate,
      chartLabels: [],
      chartCounts: [],
      chartColors: [
        "#F47373",
        "#FFBE5C",
        "#b97dd1",
        "#2CCCE4",
        "#912763",
        "#77C750",
      ],
      areaTotal: 0,
      options: {
        elements: {
          arc: {
            borderWidth: 0,
            borderColor: 'black'
          }
        },
        plugins: {
          labels: {
                render: 'percentage',
                // position: 'outside',
                fontColor: "white"
             }
        },
      },
      emptyChartData: {
        labels: ['Africa', 'AWR', 'EN', 'ESEAOR', 'SAR', 'ACR'],
        datasets: [
          {
            data: [1],
            backgroundColor: ['#e6e6e6', '#e6e6e6', '#e6e6e6', '#e6e6e6', '#e6e6e6', '#e6e6e6'],
            hoverBackgroundColor: ['#e6e6e6', '#e6e6e6', '#e6e6e6', '#e6e6e6', '#e6e6e6', '#e6e6e6']
          },
        ],
      },
      emptyChartOptions: {
        tooltips: {
          enabled: false
     },
        elements: {
          arc: {
            borderWidth: 0,
            borderColor: 'black'
          }
        },
        plugins: {
          labels: {
                render: 'percentage',
                // position: 'outside',
                fontColor: "#e6e6e6"
             }
        },
      },
      chartEmpty: false,
    };

  }

  componentDidMount() {
    this.getModuleStats(this.state.fromDate, this.state.toDate).then((result) => {
      console.log(result);
      this.prepareChartData(result.usersWithCertificates);
      this.prepareArrays(
        result.moduleAccessList,
        result.subModuleAccessList,
        result.modulePassList,
        result.subModulePassList
      );
      var moduleCompletion = result.usersWithCertificates.length/result.totalUsers * 100;
      console.log(moduleCompletion)
      this.setState({
        modulesCount: result.modulesCount,
        subModulesCount: result.subModulesCount,
        moduleAccessList: result.moduleAccessList,
        subModuleAccessList: result.subModuleAccessList,
        modulePassList: result.modulePassList,
        subModulePassList: result.subModulePassList,
        inactiveUsers: result.inactiveUsers,
        usersWithCertificates: result.usersWithCertificates,
        overallModuleCompletionStatus: moduleCompletion,
        averageAttemptToPass: result.averageAttemptToPass,
        quizzesInProgress: result.quizzesInProgress,
        quizSubmissions: result.quizSubmissions,
        fQSubmissions: result.fQSubmissions,
        totalUsers: result.totalUsers,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate) {
        this.getModuleStats(this.props.fromDate, this.props.toDate).then((result) => {
            console.log(result);
            this.prepareChartData(result.usersWithCertificates);
            this.prepareArrays(
              result.moduleAccessList,
              result.subModuleAccessList,
              result.modulePassList,
              result.subModulePassList
            );
            this.setState({
              modulesCount: result.modulesCount,
              subModulesCount: result.subModulesCount,
              moduleAccessList: result.moduleAccessList,
              subModuleAccessList: result.subModuleAccessList,
              modulePassList: result.modulePassList,
              subModulePassList: result.subModulePassList,
              inactiveUsers: result.inactiveUsers,
              usersWithCertificates: result.usersWithCertificates,
              overallModuleCompletionStatus: result.overallModuleCompletionStatus,
              averageAttemptToPass: result.averageAttemptToPass,
              quizzesInProgress: result.quizzesInProgress,
              quizSubmissions: result.quizSubmissions,
              fQSubmissions: result.fQSubmissions,
            });
          });
        }
  }

  prepareArrays = (
    moduleAccessList,
    subModuleAccessList,
    modulePassList,
    subModulePassList
  ) => {
    var mostAccessedModules = [];
    var mostAccessedSubModules = [];
    var leastAccessedModules = [];
    var leastAccessedSubModules = [];
    var mostPassedModules = [];
    var mostPassedSubModules = [];

    if (moduleAccessList.length > 6) {
      leastAccessedModules = [
        moduleAccessList[0],
        moduleAccessList[1],
        moduleAccessList[2],
      ];
      for (
        let i = moduleAccessList.length - 1;
        i >= moduleAccessList.length - 3;
        i -= 1
      ) {
        if (moduleAccessList[i].count > 0) {
          mostAccessedModules.push(moduleAccessList[i]);
        }
      }
    }

    if (subModuleAccessList.length > 6) {
      leastAccessedSubModules = [
        subModuleAccessList[0],
        subModuleAccessList[1],
        subModuleAccessList[2],
      ];
      for (
        let i = subModuleAccessList.length - 1;
        i >= subModuleAccessList.length - 3;
        i -= 1
      ) {
        if (subModuleAccessList[i].count > 0) {
          mostAccessedSubModules.push(subModuleAccessList[i]);
        }
      }
    }

    if (modulePassList.length >= 3 ) {
      for (
        let i = modulePassList.length - 1;
        i >= modulePassList.length - 3;
        i -= 1
      ) {
        if (modulePassList[i].count > 0) {
          mostPassedModules.push(modulePassList[i]);
        }
      }
    }

    if (subModulePassList.length >= 3 ) {
      for (
        let i = subModulePassList.length - 1;
        i >= subModulePassList.length - 3;
        i -= 1
      ) {
        if (subModulePassList[i].count > 0) {
          mostPassedSubModules.push(subModulePassList[i]);
        }
      }
    }

    this.setState({
      mostAccessedModules: mostAccessedModules,
      leastAccessedModules: leastAccessedModules,
      mostAccessedSubModules: mostAccessedSubModules,
      leastAccessedSubModules: leastAccessedSubModules,
      mostPassedModules: mostPassedModules,
      mostPassedSubModules: mostPassedSubModules
    });
  };

  prepareChartData = (users) => {
    const { regions } = this.state;
    var tempArray = [];
    var chartDataArray = [];
    var chartLabelArray = [];
    var chartCounts= [];
    var areaTotal = 0;
    var chartEmptyStatus = true;
    regions.map(
      (region) => (
        (tempArray = Object.values(users).filter((obj) => obj.region === region.name)),
        chartDataArray.push(tempArray.length),
        users.length > 0 ?
        chartLabelArray.push(region.key)
        : chartLabelArray.push(region.key + " " + '-' + " " + '0'),
        chartCounts.push({region: region.key, count: tempArray.length}),
        areaTotal = areaTotal + tempArray.length,

        chartEmptyStatus === true ?
          tempArray.length > 0 ?
            chartEmptyStatus = false
          : null
        : null
      )
    );
    console.log(chartDataArray)
    
    const data = {
      labels: chartLabelArray,
      datasets: [
        {
          data: chartDataArray,
          backgroundColor: this.state.chartColors
          // hoverBackgroundColor: [
          // '#FF6384',
          // '#36A2EB',
          // '#FFCE56'
          // ]
        },
      ],
    };
    
    this.setState({
      chartData: data,
      chartLabels: chartLabelArray,
      chartCounts: chartCounts,
      areaTotal: areaTotal,
      chartEmpty: chartEmptyStatus,
    });
  };

  getModuleStats = async (from, to) => {
    const result = await this.props.client.query({
      query: GET_MODULE_STATS,
      variables: {fromDate: from, toDate: to},
      fetchPolicy: "network-only",
    });
    return result.data.getModuleStats;
  };

  downloadCertificateUserList = (list) => {
    const arrayHeader = ['First name', 'Last name', 'User Category', 'Email Address', 'Organization name', 'Country', 'IPPF Region', 'Year Established', 'Account created', 'Certificate Obtained'];
    const arrayData = list;
    const delimiter = ',';
    const fileName = 'Certificate Obtained User List';
    this.export_csv_2(arrayHeader, arrayData, delimiter, fileName);
  }

  downloadInactiveUserList = (list) => {
    const arrayHeader = ['First name', 'Last name', 'User Category', 'Email Address', 'Organization name', 'Country', 'IPPF Region', 'Year Established', 'Account created'];
    const arrayData = list;
    const delimiter = ',';
    const fileName = 'Learning modules - Inactive User list';
    this.export_csv(arrayHeader, arrayData, delimiter, fileName);
  }

  export_csv = (arrayHeader, arrayData, delimiter, fileName) => {
    console.log(arrayData)
    let key = ['firstName', 'lastName', 'userType', 'email', 'fullName', 'country', 'region', 'year', 'createdAt'];
    let keys = ['firstName', 'lastName', 'userType', 'email', 'fullName', 'country', 'region', 'year', 'createdAt'];
    let header = arrayHeader.join(delimiter) + '\n';
    let csv = header;
    arrayData.forEach( obj => {
        let row = [];
        let date = '';
          keys.map(key => (
            obj.hasOwnProperty(key) ? 
            key === 'createdAt' ?
                row.push(moment(obj[key]).format('DD-MM-YYYY'))
                : key === 'isActive' ?
                      obj[key] === true ?
                          row.push('Active')
                          : row.push('Inactive')
                : key === 'userType' ?
                      obj['fullName'] === "International Planned Parenthood Federation" ?
                           row.push('CO')
                      : obj['fullName'] === "NA" ?
                           row.push("RO")
                      : row.push("MA")
                : key === 'newsletterSubscription' ?
                      obj[key] === true ?
                          row.push('Subscribed')
                          : row.push('Unsubscribed')
                : row.push(obj[key])
            : null
          ))
        csv += row.join(delimiter)+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName + '.csv';
    hiddenElement.click();
}

export_csv_2 = (arrayHeader, arrayData, delimiter, fileName) => {
  console.log(arrayData)
  let key = ['firstName', 'lastName', 'userType', 'email', 'fullName', 'country', 'region', 'year', 'createdAt', 'certificateObtainedAt'];
  let keys = ['firstName', 'lastName', 'userType', 'email', 'fullName', 'country', 'region', 'year', 'createdAt', 'certificateObtainedAt'];
  let header = arrayHeader.join(delimiter) + '\n';
  let csv = header;
  arrayData.forEach( obj => {
      let row = [];
      let date = '';
        keys.map(key => (
          obj.hasOwnProperty(key) ? 
            key === 'createdAt' || key === 'certificateObtainedAt' ?
                row.push(moment(obj[key]).format('DD-MM-YYYY'))
            : key === 'isActive' ?
                  obj[key] === true ?
                    row.push('Active')
                    : row.push('Inactive')
            : key === 'userType' ?
                  obj['fullName'] === "International Planned Parenthood Federation" ?
                      row.push('CO')
                  : obj['fullName'] === "NA" ?
                      row.push("RO")
                  : row.push("MA")
            : key === 'newsletterSubscription' ?
                  obj[key] === true ?
                      row.push('Subscribed')
                      : row.push('Unsubscribed')
            : row.push(obj[key])
          : null
        ))
      csv += row.join(delimiter)+"\n";
  });

  let csvData = new Blob([csv], { type: 'text/csv' });  
  let csvUrl = URL.createObjectURL(csvData);

  let hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName + '.csv';
  hiddenElement.click();
}

  render() {
    const { nextSubModuleDisabled, fromDate, toDate } = this.props;
    const {
      chartData,
      learningStats,
      modulesCount,
      subModulesCount,
      moduleAccessList,
      subModuleAccessList,
      modulePassList,
      subModulePassList,
      inactiveUsers,
      usersWithCertificates,
      overallModuleCompletionStatus,
      averageAttemptToPass,
      quizzesInProgress,
      quizSubmissions,
      fQSubmissions,
      mostAccessedModules,
      mostAccessedSubModules,
      leastAccessedModules,
      leastAccessedSubModules,
      mostPassedModules,
      mostPassedSubModules,
      fontColors,
      boxColors,
      box2Colors,
      chartCounts,
      chartColors,
      areaTotal,
      totalUsers,
    } = this.state;

    return (
      <div>
        <h4 className="statTitleMain">Learning</h4>
        <hr />
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[0]}}>
                  <text className="statCardLargeNumber" style={{ color: fontColors[0] }}>
                    {modulesCount != '' ?
                      <CountUp duration={4} end={modulesCount} />
                      : 0
                    }
                    
                  </text>
                  <div className="statCardLabel">
                    <i class="fas fa-list fa-fw"  />
                    Modules
                  </div>
                </div>
              </td>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[1]}}>
                  <text className="statCardLargeNumber" style={{ color: fontColors[1] }}>
                    {modulesCount != '' ?
                      <CountUp duration={4} end={subModulesCount} />
                      : 0
                    }
                  </text>
                  <div className="statCardLabel">
                    <i class="fas fa-th fa-fw" />
                    Submodules
                  </div>
                </div>
              </td>
              <td className="statTableTd2">
                <div className="statCard2" style={{backgroundColor: box2Colors[0]}}>
                  <div className="statCardLabel2" style={{color: '#F6D55C'}}>
                    Top 3 Most Passed Modules
                  </div>
                  <div className="statCardList">
                    <table style={{ width: "100%" }}>
                      <thead style={{color: '#aae349'}}>
                        <tr>
                          <th>#</th>
                          <th>Module Title</th>
                          <th style={{textAlign: 'right'}}>Passes</th>
                          <th style={{textAlign: 'right'}}>%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostPassedModules.length > 0
                          ? mostPassedModules.map((x, i) => (
                              <tr>
                                <td style={{ width: "1.5rem" }}>{i + 1}</td>
                                <td style={{ textAlign: "left" }}>
                                  {x.moduleTitle}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    width: '1.5rem'
                                  }}
                                >
                                  {x.count} 
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                    width: '2.5rem'
                                  }}
                                >
                                  {Math.round(x.count/totalUsers*100)+'%'}
                                </td>
                              </tr>
                            ))
                          :this.props.fromDate != '' || this.props.toDate != '' ?
                           <NoDataMessage message="No statistics for the selected duration" fontSize="0.8rem" />
                          : <NoDataMessage message="There are no modules with atleast 1 pass yet" fontSize="0.8rem" />
                          }
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[2]}}>
                  <text className="statCardLargeNumber2" style={{ color: fontColors[2] }}>
                    {quizSubmissions != '' ?
                      <CountUp duration={4} end={quizSubmissions} />
                      : 0
                    }
                  </text>
                  <div className="statCardLabel" style={{color: 'black'}}><i class="fas fa-info fa-fw" />Module Quiz Submissions</div>
                </div>
              </td>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[3]}}>
                  <text className="statCardLargeNumber2" style={{ color: fontColors[3] }}>
                    {fQSubmissions != '' ?
                      <CountUp duration={4} end={fQSubmissions} />
                      : 0
                    }
                  </text>
                  <div className="statCardLabel" style={{color: 'black'}}><img src={Bulb} style={{height: '1.3rem', width: 'auto'}} /> Flash Quiz Submissions</div>
                </div>
              </td>
              <td className="statTableTd2">
                <div className="statCard2" style={{backgroundColor: box2Colors[1]}}>
                  <div className="statCardLabel2" style={{color: '#F6D55C'}}>
                    Top 3 Most Passed Submodules
                  </div>
                  <div className="statCardList">
                    <table style={{ width: "100%" }}>
                    <thead style={{color: '#aae349'}}>
                        <tr>
                          <th>#</th>
                          <th>Submodule Title</th>
                          <th style={{textAlign: 'right'}}>Passes</th>
                          <th style={{textAlign: 'right'}}>%</th>
                        </tr>
                      </thead>
                      <tbody>
                      {mostPassedSubModules.length > 0
                          ? mostPassedSubModules.map((x, i) => (
                              <tr>
                                <td style={{ width: "1.5rem" }}>{i + 1}</td>
                                <td style={{ textAlign: "left" }}>
                                  {x.subModuleTitle}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    width: '1.5rem'
                                  }}
                                >
                                  {x.count}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                    width: '2.5rem'
                                  }}
                                >
                                  {Math.round(x.count/totalUsers*100)+'%'}
                                </td>
                              </tr>
                            ))
                            :this.props.fromDate != '' || this.props.toDate != '' ?
                            <NoDataMessage message="No statistics for the selected duration" fontSize="0.8rem" />
                           : <NoDataMessage message="There are no sub modules with atleast 1 pass yet" fontSize="0.8rem" />
                           }
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[4]}}>
                  <Circle
                    animate={true}
                    animationDuration="3s"
                    size={80}
                    lineWidth={30}
                    progress={Math.round(overallModuleCompletionStatus)}
                    // progress={30}
                    progressColor={"#ffb300"}
                    bgColor="#E0E0E0"
                    textColor="white"
                    textStyle={{
                      fontSize: "6.5rem",
                      fontWeight: "500",
                    }}
                  />
                  <div className="statCardLabel"><img src={Complete} style={{height: '1rem', width: 'auto'}} /> Overall Quiz Completion</div>
                </div>
              </td>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[5]}}>
                  <Circle
                    animate={true}
                    animationDuration="3s"
                    size={100}
                    lineWidth={30}
                    progress={Math.round(averageAttemptToPass)  || 0}
                    progressColor={"#ff425b"}
                    bgColor="#E0E0E0"
                    textColor="white"
                    textStyle={{
                      fontSize: "6.5rem",
                      fontWeight: "500",
                    }}
                  />
                  <div className="statCardLabel">
                  <img src={PassRate} style={{height: '1rem', width: 'auto'}} />
                  {" "}Quiz Pass Rate
                  </div>
                </div>
              </td>
              <td className="statTableTd2">
                <div className="statCard2" style={{backgroundColor: box2Colors[2]}}>
                  <div className="statCardLabel2"  style={{color: '#ed978c'}}>
                    Top 3 Most Accessed Modules
                  </div>
                  <div className="statCardList">
                    <table style={{ width: "100%" }}>
                    <thead style={{color: '#72c6ed'}}>
                        <tr>
                          <th>#</th>
                          <th>Module Title</th>
                          <th style={{textAlign: 'right'}}>Visits</th>
                          <th style={{textAlign: 'right'}}>%</th>
                        </tr>
                      </thead>
                      <tbody>
                      {mostAccessedModules.length > 0
                          ? mostAccessedModules.map((x, i) => (
                              <tr>
                                <td style={{ width: "1.5rem" }}>{i + 1}</td>
                                <td style={{ textAlign: "left" }}>
                                  {x.moduleTitle}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    width: '1.5rem'
                                  }}
                                >
                                  {x.count}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                    width: '2.5rem'
                                  }}
                                >
                                  {Math.round(x.count/totalUsers*100)+'%'}
                                </td>
                              </tr>
                            ))
                            :this.props.fromDate != '' || this.props.toDate != '' ?
                            <NoDataMessage message="No statistics for the selected duration" fontSize="0.8rem" />
                           : <NoDataMessage message="No statistics found" fontSize="0.8rem" />
                           }
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[6]}}>
                  <text className="statCardLargeNumber3" style={{ color: fontColors[4] }}>
                    {quizzesInProgress != '' ?
                      <CountUp duration={4} end={quizzesInProgress} />
                      : 0
                    }
                  </text>
                  {/* <img
                    src={InProgress}
                    style={{
                      height: "2rem",
                      width: "auto",
                      marginTop: "-1.3rem",
                      marginLeft: "0.3rem",
                    }}
                  /> */}

                  <div className="statCardLabel" style={{color: 'black'}}><img src={InProgress} style={{height: '1rem', width: 'auto'}} /> Quizzes in Progress</div>
                </div>
              </td>
              <td className="statTableTd1">
                <div className="statCard" style={{backgroundColor: boxColors[7]}}>
                  {
                    inactiveUsers.length > 0 ?
                      <img
                      src={DownloadIcon}
                      uk-tooltip="Download user list"
                      onClick = {() => this.downloadInactiveUserList(inactiveUsers)}
                      style={{
                        height: "1.3rem",
                        width: "auto",
                        position: "absolute",
                        marginLeft: "5.4rem",
                        cursor: "pointer",
                      }}
                    />
                  : null
                  }
                  
                  <text className="statCardLargeNumber3" style={{ color: fontColors[5] }}>
                    {inactiveUsers != '' ?
                      <CountUp duration={4} end={inactiveUsers.length} />
                      : 0
                    }
                  </text>
                  {/* <img
                    src={InactiveUser}
                    style={{
                      height: "2rem",
                      width: "auto",
                      marginTop: "-1.3rem",
                      marginLeft: "0.3rem",
                    }}
                  /> */}

                  <div className="statCardLabel" style={{color: 'black'}}><img src={InactiveUser} style={{height: '1rem', width: 'auto'}} /> Inactive Users - learning modules</div>
                </div>
              </td>
              <td className="statTableTd2">
                <div className="statCard2" style={{backgroundColor: box2Colors[3]}}>
                  <div className="statCardLabel2" style={{color: '#ed978c'}}>
                    Top 3 Most Accessed Submodules
                  </div>
                  <div className="statCardList">
                    <table style={{ width: "100%" }}>
                    <thead style={{color: '#72c6ed'}}>
                        <tr>
                          <th>#</th>
                          <th>Submodule Title</th>
                          <th style={{textAlign: 'right'}}>Visits</th>
                          <th style={{textAlign: 'right'}}>%</th>
                        </tr>
                      </thead>
                      <tbody>
                      {mostAccessedSubModules.length > 0
                          ? mostAccessedSubModules.map((x, i) => (
                              <tr>
                                <td style={{ width: "1.5rem" }}>{i + 1}</td>
                                <td style={{ textAlign: "left" }}>
                                  {x.subModuleTitle}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    width: '1.5rem'
                                  }}
                                >
                                  {x.count}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                    width: '2.5rem'
                                  }}
                                >
                                  {Math.round(x.count/totalUsers*100)+'%'}
                                </td>
                              </tr>
                            ))
                            :this.props.fromDate != '' || this.props.toDate != '' ?
                            <NoDataMessage message="No statistics for the selected duration" fontSize="0.8rem" />
                           : <NoDataMessage message="No statistics found" fontSize="0.8rem" />
                           }
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="statTableTd1" colSpan="2">
                <div className="statCard4" style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                  <img
                    src={Ribbon}
                    style={{
                      height: "2rem",
                      width: "auto",
                      marginTop: "-2rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <text
                    style={{
                      fontSize: "3rem",
                      color: "#109175",
                      fontWeight: "500",
                    }}
                  >
                    {usersWithCertificates != '' ?
                      <CountUp duration={4} end={usersWithCertificates.length} />
                      : 0
                    }
                  </text>
                  <div
                    className="statCardLabel"
                    style={{ textAlign: "center", marginTop: "-1.5rem", color: '#36a3cf' }}
                  >
                    Certificates Obtained
                  </div>
                  <br />
                  <div style={{ textAlign: "left" }}>
                    <text style={{color: '#51b098'}}>Region wise:</text>
                    {
                      chartData != '' ?
                     
                    <table style={{width: '100%'}}>
                            <tbody>
                              <tr>
                                <td style={{width: '25%', paddingLeft: '1rem'}}>
                                  <table style={{width: 'fit-content'}}>
                                    <tbody>
                                      {
                                        chartCounts.length > 0 ?
                                          chartCounts.map((label, index) => (
                                            <tr>
                                              <td style={{width: '50%'}}><div style={{width: '2.5rem', height: '1.3rem', backgroundColor: chartColors[index], color: 'white', fontSize: '0.8rem', textAlign: 'center'}}>{label.count}</div></td>
                                              <td style={{width: '50%', fontSize: '0.8rem', paddingLeft: '0.5rem'}}>{label.region}</td>
                                            </tr>
                                          ))
                                        : null
                                      }
                                      
                                    </tbody>
                                  </table>
                                </td>
                                <td style={{width: '75%'}}>
                                  {
                                    this.state.chartEmpty === false ?
                                   
                                    <Pie
                                  data={chartData}
                                  legend={{ position: "left", display: false, }}
                                  width={170}
                                  options={this.state.options}
                                />
                                :
                                <Doughnut
                                data={this.state.emptyChartData}
                                legend={{ position: "left", display: false, }}
                                width={170}
                              
                                options={this.state.emptyChartOptions}
                                />
                                
                                  }
                                  
                                
                                </td>
                              </tr>
                            </tbody>
                          </table>
                    : null
                    }
                    
                  </div>
                  {
                    usersWithCertificates.length > 0 ?
                    <img
                    src={DownloadIcon}
                    onClick = {() => this.downloadCertificateUserList(usersWithCertificates)}
                    uk-tooltip="Download user list"
                    style={{
                      height: "1.5rem",
                      width: "auto",
                      marginLeft: "21.5rem",
                      cursor: "pointer",
                    }}
                  />
                  : null
                  }
                  
                </div>
              </td>
              <td className="statTableTd2">
                <div className="statCard3" style={{backgroundColor: box2Colors[4]}}>
                  <div className="statCardLabel2" style={{color: '#97cfcd'}}>
                    Top 3 Least Accessed Modules
                  </div>
                  <div className="statCardList">
                    <table style={{ width: "100%" }}>
                    <thead style={{color: '#ffb3a6'}}>
                        <tr>
                          <th>#</th>
                          <th>Module Title</th>
                          <th style={{textAlign: 'right'}}>Visits</th>
                          <th style={{textAlign: 'right'}}>%</th>
                        </tr>
                      </thead>
                      <tbody>
                      {leastAccessedModules.length > 0
                          ? leastAccessedModules.map((x, i) => (
                              <tr>
                                <td style={{ width: "1.5rem" }}>{i + 1}</td>
                                <td style={{ textAlign: "left" }}>
                                  {x.moduleTitle}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    width: '1.5rem'
                                  }}
                                >
                                  {x.count}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                    width: '2.5rem'
                                  }}
                                >
                                  {Math.round(x.count/totalUsers*100)+'%'}
                                </td>
                              </tr>
                            ))
                            :this.props.fromDate != '' || this.props.toDate != '' ?
                            <NoDataMessage message="No statistics for the selected duration" fontSize="0.8rem" />
                           : <NoDataMessage message="No statistics found" fontSize="0.8rem" />
                           }
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />
                <div className="statCard3" style={{backgroundColor: box2Colors[5]}}>
                  <div className="statCardLabel2" style={{color: '#97cfcd'}}>
                    Top 3 Least Accessed Submodules
                  </div>
                  <div className="statCardList">
                    <table style={{ width: "100%" }}>
                    <thead style={{color: '#ffb3a6'}}>
                        <tr>
                          <th>#</th>
                          <th>Submodule Title</th>
                          <th style={{textAlign: 'right'}}>Visits</th>
                          <th style={{textAlign: 'right'}}>%</th>
                        </tr>
                      </thead>
                      <tbody>
                      {leastAccessedSubModules.length > 0
                          ? leastAccessedSubModules.map((x, i) => (
                              <tr>
                                <td style={{ width: "1.5rem" }}>{i + 1}</td>
                                <td style={{ textAlign: "left" }}>
                                  {x.subModuleTitle}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    width: '1.5rem'
                                  }}
                                >
                                  {x.count}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                    width: '2.5rem'
                                  }}
                                >
                                  {Math.round(x.count/totalUsers*100)+'%'}
                                </td>
                              </tr>
                            ))
                            :this.props.fromDate != '' || this.props.toDate != '' ?
                            <NoDataMessage message="No statistics for the selected duration" fontSize="0.8rem" />
                           : <NoDataMessage message="No statistics found" fontSize="0.8rem" />
                           }
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(LearningStats)));

import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import moment from 'moment';
//queries
import { GET_ANNOUNCEMENTS } from "../../../queries/common_queries";
import { async } from "q";
import { UPDATE_ANNOUNCEMENT } from "../../../queries/admin_queries";
import UploadVideos from '../../../components/uploadFiles/uploadVideos';

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class UploadFiles extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }
    this.state = {
      
    };
  }
  componentDidMount() {
    // this.get_all_announcements(this.state.pagination).then((result) => {
      
    // });
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      
    } = this.state;



    return (
      <div className="admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-list icon-large uk-margin-right"></i>
          <h4 className="uk-margin-remove"> File Upload </h4>
        </div>
        <br />
        <br />
        <br />
        <UploadVideos />
      </div>
    );
  }
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(UploadFiles))
);

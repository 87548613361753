
export const initialData = {
    isLoggedIn: false,
    userDetails: [],
}

export const isLoggedIn = () => {
    if ( localStorage.ippfauthtoken ) {
        return {
            token: localStorage.ippfauthtoken,
            userName: localStorage.FIRST_NAME,
            lastName: localStorage.LAST_NAME,
            userType: localStorage.USER_TYPE,
        };
    } else {
        return false;
    }
}

export const logoutUser = () => {
    localStorage.ippfauthtoken =  '';
    localStorage.FIRST_NAME =  '';
    localStorage.LAST_NAME = '';
    localStorage.USER_TYPE = '';
}

export const getUserFromCache = async (context) => {
    
}
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../navbar/navbar';

//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';




class Lesson_banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const {lesson} =this.props;

        return (
            <div className="topic2 hero-bg shadow-small">
                <div className="uk-grid">                
                        <h2 className="uk-light  uk-text-bold uk-text-white"> {lesson.lessonTitle} (Lesson)</h2>           
                </div>                 
            </div>
        )
    }
}

export default Lesson_banner; 
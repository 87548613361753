import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Navbar from "../../navbar/navbar";
import Header from "../../header/header";
import MainBanner from "../../home/mainBanner/mainBanner";
import Footer from "../../footer/footer";

//custom-components
import Card_sub_module from "../../../components/sub_module/card_sub_module/card_sub_module";
import Card_lesson from "../../../components/lesson/card_lesson/card_lesson";
import Lesson_container from "../../../components/lesson/lesson_container/lesson_container";

import ContentNotFoundMessage from "../../../components/messages/contentNotFound/ContentNotFoundMessage";
import { Table, Grid } from "semantic-ui-react";
import { withNamespaces } from "react-i18next";

class Module_container extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.module.id);
    this.state = {
      pagination: {
        skip: 0,
        first: 20,
        lang: localStorage.LANG,
      },
      moduleId: this.props.module.id,
      sub_modules: this.props.sub_modules,
      submissions: this.props.submissions,
      subModuleCompleted: '',
      module: this.props.module,
    };
  }

  redirectToSubModule(id, moduleId) {
    // const lastM = lastmodule ? true:false;
    // const flag= last ? true : false;
    this.props.history.push("/sub_module?id=" + id + "&module=" + moduleId);
  }

  redirectToLesson = (id, moduleId) => {
    this.props.history.push("/lesson?id=" + id + "&module=" + moduleId);
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/home");
    } else {
      return false;
    }
  };

  getSubModuleStatus = (sub_module, i) => {
      console.log(this.props.module.id)
      const tempSubmissions = this.state.submissions;
      console.log(tempSubmissions)
      // Fix to the error : .... .filter is not a function
      const res = Object.values(tempSubmissions).filter(
      (obj) => obj.subModuleId === sub_module.id
    );
    if (res.length > 0) {
        return <Card_sub_module
        num={i + 1}
        sub_module={sub_module}
        get_sub_module={() => this.redirectToSubModule(sub_module.id, this.props.module.id)}
        completed={true}
      />
    } else {
        return <Card_sub_module
        num={i + 1}
        sub_module={sub_module}
        get_sub_module={() => this.redirectToSubModule(sub_module.id, this.props.module.id)}
        completed={false}
      />    }
  };

  render() {
    const {
      id,
      from,
      redirectKey,
      module,
      sub_modules,
      lessons,
      contents,
      objectives,
      t
    } = this.props;
    const { moduleId } = this.state;
    return (
      <div>
        <br />{" "}
        <div>
          <ul className="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>{t('home')}</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("MODULE")}>{t('module')}</a>
            </li>
          </ul>
        </div>
        <div
          className="uk-card uk-card-default uk-grid-collapse uk-margin uk-grid moduleContainerTable"
          uk-grid="true"
        >
          {/* <img src={module.objectivesImageUrl} />
                    <br/> */}

        {localStorage.LANG=="ar" ? (
              <Table className="moduleTable">
              <Table.Header>
                <Table.Row className="moduleTitle_Ar">
                  <Table.Cell> {t('module_learning_obj')} {module.moduleTitle}</Table.Cell>
                </Table.Row>
              </Table.Header>
  
              <Table.Body>
                <Table.Row className="moduleLearning_Ar">
                  <Table.Cell textAlign="right"> { t('objectives')} </Table.Cell>
                </Table.Row>
  
                <Table.Row className="moduleLearningSum">
                  <Table.Cell>
                    <Grid columns={3}>
                      <Grid.Row>
                        <Grid.Column className="gridLearningSum_Ar">
                          {t('onCompletion')}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
                {/* <br/> */}
  
                {objectives ? (
                  objectives.length > 0 ? (
                    objectives.map((objective) => (
                      <Table.Row className="moduleLearningObjective_Ar">
                        <Table.Cell>
                          <ul class="experiences">
                            <li>{objective.learningObj}</li>
                          </ul>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <ContentNotFoundMessage />
                  )
                ) : (
                  <ContentNotFoundMessage />
                )}
              </Table.Body>
            </Table>
        ): (
              <Table className="moduleTable">
              <Table.Header>
                <Table.Row className="moduleTitle">
                  <Table.Cell> {t('module_learning_obj')} {module.moduleTitle}</Table.Cell>
                </Table.Row>
              </Table.Header>
  
              <Table.Body>
                <Table.Row className="moduleLearning">
                  <Table.Cell textAlign="right"> { t('objectives')} </Table.Cell>
                </Table.Row>
  
                <Table.Row className="moduleLearningSum">
                  <Table.Cell>
                    <Grid columns={3}>
                      <Grid.Row>
                        <Grid.Column className="gridLearningSum">
                          {t('onCompletion')}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
                {/* <br/> */}
  
                {objectives ? (
                  objectives.length > 0 ? (
                    objectives.map((objective) => (
                      <Table.Row className="moduleLearningObjective">
                        <Table.Cell>
                          <ul class="experiences">
                            <li>{objective.learningObj}</li>
                          </ul>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <ContentNotFoundMessage />
                  )
                ) : (
                  <ContentNotFoundMessage />
                )}
              </Table.Body>
            </Table>
        )}
      
          <br />
        </div>
        {/* <div className="uk-clearfix boundary-align">
                    <div className="uk-float-left section-heading none-border">
                        <h1>{module.moduleTitle}</h1>
                        <p>{module.moduleOverview}</p>
                        {module.content != "" ? (
                            <div dangerouslySetInnerHTML={{ __html: module.content }}></div>
                        ) : (
                                null
                            )}
                        {

                        }
                    </div>
                </div> */}
        {redirectKey == "SUBMODULE" ? (
          sub_modules ?
          sub_modules.subModules.length> 0 ?
          sub_modules.subModules.map((sub_module, i) => (
              this.getSubModuleStatus(sub_module, i)
            // <Card_sub_module
            //   num={i + 1}
            //   sub_module={sub_module}
            //   get_sub_module={() => this.redirectToSubModule(sub_module.id, this.props.module.id)}
            //   completed={this.state.subModuleCompleted}
            // />
          )) :null :null
        ) : redirectKey == "LESSON" ? (
          lessons.lessons.map((lesson, i) => (
            <Card_lesson
              num={i + 1}
              lesson={lesson.lesson}
              get_lesson={() => this.redirectToLesson(lesson.lesson.id, this.props.module.id)}
            />
          ))
        ) : redirectKey == "CONTENT" ? (
          <Lesson_container
            id={id}
            from={from}
            content={contents.contents[0]}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(withApollo(withNamespaces()(Module_container)));

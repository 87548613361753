import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//middleware
import {formatDate} from '../../../middleware/index';


//queries
import { GET_ALL_RESOURCES, GET_SINGLE_RESOURCE } from '../../../queries/common_queries';

import { Button } from 'react-uikit';

import PropTypes from 'prop-types';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class DocumentList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [],
            loading: false,
            pagination:{
                lang: "en",
                skip: 0,
                first: 100000,
            },
        };

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getResources(this.state.pagination).then(result => {
            console.log(result)
            var resource_op = result.resources.map(resource => {
                return {
                    id: resource.id,
                    resourceTitle: resource.resourceTitle,
                    resources: resource.resources,
                    createdAt: resource.createdAt,
                    description: resource.description,
                    url: resource.url,
                    resourceContent:resource.resourceContent,
                }
            });
            this.setState({ resources: resource_op, loading: false });
        });
    }

    getResources = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_ALL_RESOURCES,
            // fetchPolicy: 'cache-first'
            variables:pagination,
            fetchPolicy: 'network-only'
        });
        return result.data.getAllResources;
    };

    addNewResource() {
        this.props.history.push('/admin_add_document');
    }

    editResource(id) {
        this.props.history.push('/admin_edit_document?id=' + id);
    }

    render() {
        const { loading, resources } = this.state;

        return (
            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="far fa-images uk-margin-right" style={{fontSize: '2.3rem'}}></i>
                    <h4 className="uk-margin-remove adminSectionTitles"> Resources </h4>

                </div>
                <div className="uk-flex-inline uk-float-right">
                    <button className="uk-button uk-button-primary admin-btn" onClick={() => this.addNewResource()}>Add Resource</button>
                </div>
                <br /> <br />
                <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                        <thead>
                            <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                                {/* <th>No.</th> */}
                                <th style={{width: '38%'}}>Resource Title</th>
                                <th>Resource Description</th>
                                <th>Uploaded Date</th>
                                <th>Link</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resources.length > 0 ? (
                                resources.map((resource, i) => (
                                    <tr key={i}>
                                        {/* <td> {i + 1}  </td> */}
                                        <td> {resource.resourceTitle}  </td>
                                        <td> {resource.description}</td>
                                        <td> {formatDate(resource.createdAt)}  </td>
                                        <td>
                                            {
                                                resource.url ?
                                                resource.url != "" || resource.url != null || resource.url != undefined ? (
                                                    <a href="#" onClick={() => { window.open(resource.url); }}>View</a>
                                                ) : (null) : null
                                            }
                                        </td>
                                        <td>
                                            <button disabled={loading ? (true) : (false)} className="uk-button uk-button-warning admin-table-btn" uk-tooltip="Edit" onClick={() => this.editResource(resource.id)} ><i class='fas fa-pencil-alt'></i></button>
                                        </td>
                                    </tr>
                                ))
                            ) : (null)}

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DocumentList))); 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

class Single_resource_container extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.querySelectorAll('oembed[url]').forEach(element => {
            const URL = element.getAttribute('url');
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            var match = URL.match(regExp);
            if (match && match[7].length == 11) {
                console.log(match[7]);
                const videoId = "https://www.youtube.com/embed/" + match[7] + "?rel=0&modestbranding=1";
                element.innerHTML = '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;"><iframe src=' + videoId + ' style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>';
            }
        });
    }

    breadcrumbredirect = (path) => {
        console.log(this.props);
        if (path === "RESOURCE") {
            this.props.history.push('/resources');
        } else {
            return false;
        }
    }
    render() {
        const { resourceTitle, resourceContent, t, videos } = this.props;
        return (
            <div className="uk-container">
                <br />
                <div>
                    <ul className="uk-breadcrumb">
                        <li ><a onClick={() => this.breadcrumbredirect("RESOURCE")}> {t('resources_tab')} </a></li>
                        <li ><a onClick={() => this.breadcrumbredirect("SINGLE_RESOURCE")}> {t('single_resource')} </a></li>
                    </ul>
                </div>

                <div className="uk-container-small uk-margin-auto uk-margin-medium-top">
                    {/* <p className="uk-text-lead">{resourceTitle}</p> */}
                    <div className="ck-style singleResourceStyle" >
                            <div dangerouslySetInnerHTML={{ __html: resourceContent }} />
                    </div>
                    {/* <div
                        dangerouslySetInnerHTML={{
                            __html: content.replace(/href/g, "target='_blank' href")
                        }}>
                    </div> */}
                    {
                        videos ?
                            videos.length > 0 ?
                                videos.map(video => (
                                    <div style={{ width: '75%', marginBottom: '2.5rem', textAlign: 'justify' }}>
                                        <video key={'https://kmsvideo.skotie.com/' + video.url} className="video" style={{ width: '100%', marginBottom: '0rem' }} controls>
                                            <source src={'https://kmsvideo.skotie.com/' + video.url} />
                                        </video>
                                        <text className="videoCaptionUser">{video.description}</text>
                                    </div>
                                ))
                                : null
                            : null
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(withNamespaces()(Single_resource_container)));
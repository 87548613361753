import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';

//generics
import { ippfauthtoken, USER_ID, EMAIL, FIRST_NAME, LAST_NAME, USER_TYPE } from '../../../constants/genarics';
import { Image } from 'semantic-ui-react';

class Admin_navbar extends React.Component {
    constructor(props) {
        super(props);
    }

    logout = () => {
        setTimeout(() => {
            localStorage.removeItem(ippfauthtoken);
            localStorage.removeItem(USER_ID);
            localStorage.removeItem(EMAIL);
            localStorage.removeItem(FIRST_NAME);
            localStorage.removeItem(LAST_NAME);
            localStorage.removeItem(USER_TYPE);
            this.props.history.push('/');
            // window.location.reload();
        }, 500)
    }

    _onLinkClick = (path) => {
        this.props.history.push(path);
    };

    clickLogo = () => {
        this.props.history.push('/admin_home');
    }

    render() {
        const { routes } = this.props;       

        return (
            <div>
                <div className="admin-side navBarSize" id="admin-side">
                <div style={{backgroundColor:"#ffff", overflow: 'auto',  padding: "10px 5px"}} >
                {/* paddingTop:"10px",paddingBottom:"10px",  */}
                
                <Image  className="adm-logo adminlogooo" src={ require('../../../assets/images/admin_site_logo.png') } fluid />
                {/* <Image style={{width:"8rem"}} float="right" className="ippf-logo adm-logo adminlogooo" src={ require('../../../assets/images/2_SE_IPPF_logo_old_edited_color.png') } fluid /> */}

                 {/* <Image className="ui fluid image uk-display-inline-block ippf-logo" src={require('../../images/1_SE_IPPF_logo_1.png')} fluid />
                                    <Image float="right" className="ui fluid image uk-display-inline-block ippf-logo" src={require('../../images/2_SE_IPPF_logo_old_edited_color.png')} fluid />
                     */}
                </div>
                
                    {/* <a className="admin-logo" onClick={()=>this.clickLogo()}><i className="fas fa-certificate"></i> IPPF ADMIN</a> */}
                    <ul data-simplebar className="navBarSubDiv">

                        {routes.map((route, key) => (
                            route.isVisible ? (
                                <li key={key} onClick={() => this._onLinkClick(route.path)}>
                                    <a> <i className={route.icon}></i> {route.name} </a>
                                </li>
                            ) : (
                                    null
                                )
                        ))}

                        <li>
                            <a name="manual" href="http://seippf.org/adminguide/" target="_blank">
                                {" "}
                                <i className="fas fa-file fa-fw"></i> User Manual{" "}
                            </a>
                        </li>

                        <li onClick={() => this.logout()}>
                            <a> <i className="fas fa-sign-out-alt fa-fw"></i> Logout </a>
                        </li>


                    </ul>
                </div>
                <div className="admin-mobile-headder uk-hidden@m">
                    <span uk-toggle="target: #admin-side; cls: admin-side-active" className="uk-padding-small uk-text-white uk-float-right"><i className="fas fa-bars"></i></span>
                    <a className="uk-logo" href=""> <i className="fas fa-graduation-cap"> </i> IPPF </a>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo((Admin_navbar))); 
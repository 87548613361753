import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import InfoIcon from '../../../images/info_red.png'
import { withNamespaces } from "react-i18next";

class ContentNotFoundMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            langDict: {en: "English", fr: "Français", ar: "العربية", sp: "español"},
        };
    }

    render() {
        const {langDict} = this.state;
        const {lang, t} = this.props;
        return (
            <div className="contentUnavailableMessage">
                <div className="messageSubDiv">
                    <img src={InfoIcon} className="messageInfoIcon" />
                </div>
                <div className="messageSubDiv">
                    <h5 style={{
                    color: "#a30023",
                    fontWeight: "500",
                    // fontSize: "1rem",
                  }}>
                        {t('no_content')?(t('no_content')):("")}&nbsp;
                        {
                            lang ?
                            langDict[lang] :
                            localStorage.LANG ? langDict[localStorage.LANG] : " "
                        }
                    </h5>
                </div>
            </div>
        );
    }
}

export default withRouter(withApollo(withNamespaces()(ContentNotFoundMessage))); 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

// queries
import {CREATE_GALLERY_IMAGE} from '../../../queries/user_queries'

import { Button } from 'react-uikit';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formErrorStatus,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddGalleryImage extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            data: null,
            galleryID:'',
            imageTitle:"",
            image:'',
            imageHelperText:'',
            imageDescription:'',
            isActive: true,
            loading: false
        };
        this.handleChange = this.handleChange.bind(this);
        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }
    
    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit = (event) => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.setState({ loading: true });

        const { galleryID, imageTitle, image, imageHelperText, imageDescription } = this.state;

        if (galleryID ==='') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Required Gallery ID !" });
        } 
        // else if (imageTitle === '') {
        //     this.setState({ loading: false });
        //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Required Image Title!" });
        // } else if (image === '') {
        //     this.setState({ loading: false });
        //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Required Image URL!" });
        // } else if (imageHelperText ==='') {
        //     this.setState({ loading: false });
        //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Required Image Helper Text!" });
        // } else if (imageDescription ==='') {
        //     this.setState({ loading: false });
        //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Required Image Description!" });
        // }
         else {
            this.createGalleryImage().then(result => {

                this.setState({
                    data: null,
                    loading: false,
                    galleryID:'',
                    imageTitle: '',
                    image: '',
                    imageHelperText: '',
                    imageDescription: ''
                });

                this.props.setSuccessStatus({
                    status: true,
                    title: "",
                    message:
                        "Created Gallery Image Successfully!."
                });

            }).catch(error => {
                console.log(error);
                this.setState({ loading: false });
                this.props.setFormStatus({ status: true, title: "Oops!", message: "There was an error while trying to create Gallery Image." });
            });

        }
    }
    

    createGalleryImage = async () => {
        const { galleryID, imageTitle, image, imageHelperText, imageDescription, data,isActive } = this.state;
        const content = data;

        const result = await this.props.client.mutate({
            mutation: CREATE_GALLERY_IMAGE,
            variables: { galleryID, imageTitle, image, imageHelperText, imageDescription, content,isActive}
        });

        return result.data.createGalleryImage;
    };

    render(){
        const {loading} = this.state;
        const { formErrorStatus, formSuccessState } = this.props;
        return(
            <div className="admin-content-inner">
            <div className="uk-flex-inline uk-flex-middle">
                <i className="far fa-images icon-large uk-margin-right"></i>
                <h4 className="uk-margin-remove"> Add New Gallery Image </h4>
            </div>
            <div className="uk-margin-medium">
                <form className="ui form">
                    <div className="uk-grid" >
                        <div className="uk-width-1@m">
                            <div className="uk-card-small uk-card-default">
                                <div className="uk-card-header uk-text-bold">
                                    <i className="far fa-images uk-margin-small-right"></i> Gallery Images
                                </div>
                                <div className="uk-card-body uk-padding-remove-top">
                                    <div className="uk-form-label"> Image Title </div>
                                    <input required value={this.state.imageTitle || ''} className="uk-input uk-form-width-huge" id="imageTitle" name="imageTitle" placeholder="Title" type="text" onChange={this.handleChange} />
                                    <div className="uk-form-label"> Image URL </div>
                                    <input required value={this.state.image || ''} className="uk-input uk-form-width-huge" id="image" name="image" placeholder="URL" type="text" onChange={this.handleChange} />
                                    <div className="uk-form-label"> Image Helper Text </div>
                                    <textarea required value={this.state.imageHelperText || ''} className="uk-textarea" rows="4" id="imageHelperText" name="imageHelperText" onChange={this.handleChange}></textarea>
                                    <div className="uk-form-label"> Image Description </div>
                                    <textarea value={this.state.imageDescription || ''} className="uk-textarea" rows="4" id="imageDescription" name="imageDescription" onChange={this.handleChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                            className="uk-button uk-button-primary uk-margin"
                            style={{ height: 40 }}
                            type="button"
                            disabled={loading ? (true) : (false)}
                            onClick={() => this.handleSubmit(this.event)}
                        >
                            {loading ? (
                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                            ) : ("Save")}
                        </Button>
                        {(formErrorStatus.status) ? (
                            <div className="danger_alert">{formErrorStatus.message}</div>
                        ) : ((formSuccessState.status) ? (
                            <div className="success_alert">{formSuccessState.message} </div>
                        ) : (''))}
                    
                </form>
            </div>
        </div>
        );
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AddGalleryImage))); 

import * as React from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

import InfoIcon from "../../../images/info_red.png";

class NoDataFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { langDict } = this.state;
    const { message, color, mainDivStyle } = this.props;
    console.log(mainDivStyle);
    return (
      <div
        className=""
        style={mainDivStyle != undefined ? mainDivStyle : styles.messageMainDiv}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  src={InfoIcon}
                  className=""
                  style={{ height: "1.2rem", width: "auto" }}
                />
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <text
                  style={{
                    color: "#a30023",
                    fontWeight: "500",
                    fontSize: "1rem",
                  }}
                >
                  {message}
                </text>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div className="messageSubDiv">
            <img src={InfoIcon} className="" style={{height: '1.2rem', width: 'auto'}} />
          </div>
          <div className="messageSubDiv">
            <h5 style={{color: "#a30023"}}>{message}</h5>
          </div> */}
      </div>
    );
  }
}

const styles = {
    messageMainDiv: {
      margin: "0 auto",
      marginTop: '2rem',
      marginLeft: '6rem',
    //   width: 'fit-content'
    },
  
  };

export default withRouter(withApollo(NoDataFound));

import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
// import ReactStars from "react-rating-stars-component";
import Button from "react-uikit/lib/components/Button";
import { setModalStatus } from "../../actions/user-actions";
import {
  CREATE_USER_RATING,
  UPDATE_USER_RATING,
} from "../../queries/user_queries";
import { _generate_email } from "../../middleware/index";

//generics
import {
  ippfauthtoken,
  USER_ID,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  USER_TYPE,
} from "../../constants/genarics";
import { GET_USER_RATINGS } from "../../queries/common_queries";

const mapStateToProps = (state, ownProps) => {
  return {
    ratingModalOpen: state.custom_reducer.ratingModalOpen,
    ratingModalType: state.custom_reducer.ratingModalType,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setModalStatus: (status) => {
      dispatch(setModalStatus(status));
    },
  };
};

class RatingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };
  }

  componentDidMount() {}

  changeFile = (e) => {
    console.log(e.target.files);
    this.setState({
      files: e.target.files,
    });
  };

  handleFormSubmit = async (e) => {
    var fileinfo = document.forms.namedItem("fileinfo");
    console.log(fileinfo);
    e.preventDefault();

    // let response = await fetch("http://127.0.0.1:4555/upload", {
    //   method: "POST",
    //   body: new FormData(fileinfo),
    //   // "Content-Type": "multipart/form-data",
    //   // mode: "no-cors"
    //   // dataType: "json"
    // }).then((result) => {
    //   console.log(result);
    //   // const test = result.json().then(res => console.log(res)).catch(err => console.log(err));
    // })
    // console.log(JSON.stringify(response))
    // // let result = await response.json();
    // // console.log(result)

    fetch('https://kmsvideo.skotie.com/upload', {
      method: 'POST',
      body: new FormData(fileinfo)
    })
    .then(response => response.json())
    .then(result => {
      console.log(result);
    })
    .catch(error => {
      console.error(error);
    });


  };

  render() {
    const {} = this.props;
    const {} = this.state;
    return (
      <div>
        <h1>File Upload</h1>
        <br />
        <form
          id="fileinfo"
          name="fileinfo"
          onSubmit={(e) => this.handleFormSubmit(e)}
        >
          <input type="file" name="file" multiple onChange={this.changeFile} />
          <Button type="submit">Upload</Button>
        </form>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withApollo(RatingModal))
);

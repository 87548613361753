import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';
import { withNamespaces } from "react-i18next";
import { Button } from 'react-uikit';

//queries
import { GET_MODULE_QUIZ, GET_NEXT_MODULE } from '../../../queries/common_queries';
import { GET_QUIZ_SUBMISSION } from '../../../queries/user_queries';


import Checkbox from 'react-simple-checkbox';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class QuizReviewContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            moduleQuizSubmissionId: this.props.moduleQuizSubmissionId,
            isActive: true,
            loading: false,
            pagination: {
                moduleId: this.props.moduleId,
                isActive: true,
                skip: 0,
                first: 20,
                lang: localStorage.LANG,
            },
            moduleQuiz: [],
            title: "",
            description: "",
            totalMarksArr: [],
            totalMarks: 0,
            allquestions: 0,
            passRate: 80,
            userAverage: 0.0,
            score: 0,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getQuizSubmission().then(result => {
            // console.log(result);
            this.get_next_module(result.moduleId).then(result => {
                // console.log(result.length)
                if (result.length !== 0) {
                    this.setState({ nextModuleId: result[0].id });
                } else {
                    this.setState({ nextModuleId: 0 });
                }
                // console.log(this.state.nextModuleId)
            })
            this.setState({
                moduleId: result.moduleId,
                moduleQuiz: result.moduleAnswerQuiz.questions,
                title: result.moduleAnswerQuiz.title,
                description: result.moduleAnswerQuiz.description,
                score: result.score,
                loading: false,
            });
        })
    }

    get_next_module = async (moduleId) => {
        const result = await this.props.client.query({
            query: GET_NEXT_MODULE,
            variables: {
                moduleId: moduleId,
                first: 1,
                lang: localStorage.LANG,
            },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getNextModule;
    };

    //IssueCertificate
    // issueCertificate(moduleId){
    //     this.props.history.push('/certificate?id='+moduleId);
    // }

    userResult = () => {
        this.props.history.push('/result')

    }

    getQuizSubmission = async () => {

        const moduleQuizSubmissionId = this.state.moduleQuizSubmissionId;

        const result = await this.props.client.query({
            query: GET_QUIZ_SUBMISSION,
            variables: { moduleQuizSubmissionId },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getQuizSubmission;
    };
    redirectToQuiz = () => {
        const { moduleId } = this.state;
        this.props.history.push('/quiz?id=' + moduleId);
    }
    passToNextModule = (nextModuleId) => {
        window.scrollTo(-500, -200);
        this.props.history.push('/module?id=' + nextModuleId);
    }
    render() {
        const { moduleId, moduleQuiz, title, description, loading, score } = this.state;
        const { t } = this.props;
        return (
            <div class="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read" uk-scrollspy="target: > a; cls:uk-animation-slide-bottom-small; delay: 200">
                <a href="#" class="paper uk-link-reset">
                    <div class="s" >
                        {
                            loading ? (
                                <div class="uk-width-5-6">
                                    <h2 className="uk-light  uk-text-bold uk-text-white">{t('wait')} </h2>
                                    <p className="uk-light uk-text-bold"> {t('loading')}</p>
                                    {/* <h2 class="uk-margin-remove"> Loading... </h2> */}
                                </div>
                            ) : (
                                moduleQuiz.length > 0 && this.state.score >= this.state.passRate ? (
                                    <div >
                                        <div style={{ textAlign: "center" }}><i className="fas fa-medal fa-8x" style={{ color: "#008a00" }}></i></div> <br />
                                        <div className="success_alert" style={{ textAlign: "center" }}><h3> {t('your_score')} {score} % <br /> {t('congratulation')}</h3> </div><br />
                                        <h2 class="uk-margin-remove"> {title}</h2>
                                        <p class="uk-margin-remove"> {description} </p>
                                    </div>
                                ) : (
                                    <div>
                                        <div>
                                            <div className="danger_alert" style={{ textAlign: "center" }}><h4> {t('thank_you_msg')} <br />
                                                {t('insufficient_score')} ({score} %). <br />
                                                {/* However, you have an insufficient score ({score} %) for the module to be completed. <br/> */}
                                                {t('try_agin')}..</h4></div><br />
                                            <h2 class="uk-margin-remove"> {title}</h2>
                                            <p class="uk-margin-remove"> {description} </p>
                                        </div><br />
                                    </div>
                                )
                            )
                        }
                    </div>
                </a>
                {
                    moduleQuiz.length > 0 ? (
                        moduleQuiz.map((question, i) => (
                            <div class="uk-link-reset paper uk-margin-remove-bottom" key={i}>
                                <h4 class="uk-margin-remove">{i + 1})  {question.question} {question.success != undefined ? (
                                    <i className="far fa-check-circle fa-1x" style={{ color: "#06a7e3" }}></i>
                                ) : (
                                    null
                                )}</h4>
                                {
                                    question.answers.map((answer, j) => (
                                        [
                                            <label style={{ cursor: "pointer" }}>
                                                <Checkbox
                                                    id="answer"
                                                    color={answer.markedAnswer === undefined ? "" : (answer.markedAnswer === true && answer.isCorrect === undefined ? "#FF0000" : "#4cd964")}
                                                    // color={answer.markedAnswer===true && answer.isCorrect===true?"#4cd964":(answer.markedAnswer===undefined && answer.isCorrect == true ? "")}
                                                    size={3}
                                                    className="colourCheckbox checkBoxStyle"
                                                    name={"answer" + i}
                                                    checked={answer.markedAnswer === true ? true : false}
                                                />

                                                {/* {answer.markedAnswer != undefined && answer.markedAnswer == true && answer.isCorrect != undefined && answer.isCorrect == true ? (
                                                    <Checkbox
                                                        id="answer"
                                                        color="#4cd964"
                                                        size={3}
                                                        className="colourCheckbox"
                                                        name={"answer" + i}
                                                        checked
                                                    />
                                                ) : (
                                                        answer.markedAnswer != undefined && answer.markedAnswer == true && answer.isCorrect == undefined || answer.isCorrect == false ? (
                                                            <Checkbox
                                                                id="answer"
                                                                color="#FF0000"
                                                                size={3}
                                                                className="colourCheckbox"
                                                                name={"answer" + i}
                                                                checked
                                                            />
                                                        ) : (
                                                            answer.markedAnswer == undefined && answer.isCorrect != undefined || answer.isCorrect == true ? (
                                                                <Checkbox
                                                                    id="answer"
                                                                    color="#4cd964"
                                                                    size={3}
                                                                    className="colourCheckbox"
                                                                    name={"answer" + i}
                                                                    checked
                                                                />
                                                            ) : (
                                                                answer.markedAnswer == undefined && answer.isCorrect == undefined || answer.isCorrect == false ? (
                                                                    <Checkbox
                                                                        id="answer"
                                                                        size={3}
                                                                        className="colourCheckbox"
                                                                        name={"answer" + i}
                                                                        
                                                                    />
                                                                ) : (
                                                                        ""
                                                                    )
                                                                )
                                                            )
                                                    )} */}
                                                {answer.answer}
                                            </label>, <br />,
                                        ]
                                    ))
                                }
                            </div>
                        ))
                    ) : (
                        null
                    )
                }
                <br />
                <div class="uk-child-width-expand@s uk-child-width-1-2@s uk-grid uk-margin" uk-grid="true">
                    <div>
                        <Button
                            className={localStorage.LANG == 'ar' ? "uk-button uk-button-primary uk-margin uk-align-right" : "uk-button uk-button-primary uk-margin uk-align-left"}
                            type="button"
                            onClick={() => this.userResult()}
                            disabled={loading ? (true) : (false)}>
                            {loading ? (
                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                            ) : (t('view_dashboard'))}
                        </Button>
                    </div>
                    {
                        localStorage.LANG == 'ar' ?
                            (
                                <div className="uk-text-left">
                                    <Button
                                        className="uk-button uk-button-primary uk-margin"
                                        type="button"
                                        onClick={() => this.redirectToQuiz()}>{t('re_take_quiz')}
                                    </Button>
                                </div>
                            ) :
                            (
                                <div className="uk-text-right">
                                    <Button
                                        className="uk-button uk-button-primary uk-margin"
                                        type="button"
                                        onClick={() => this.redirectToQuiz()}>{t('re_take_quiz')}
                                    </Button>
                                </div>
                            )
                    }

                </div>

                <div class="uk-child-width-expand@s uk-child-width-1-1@s uk-grid" uk-grid="true">
                    {localStorage.LANG == 'ar' ?
                        (
                            <div className="uk-text-left">
                                {this.state.nextModuleId !== 0 ? (
                                    <Button
                                        className="uk-button uk-button-default"
                                        style={{ color: "#1e87f0", borderColor: "#1e87f0" }}
                                        type="button"
                                        onClick={() => this.passToNextModule(this.state.nextModuleId)}>
                                        {t('next_module')} <i className="fas fa-angle-left"></i>
                                    </Button>
                                ) : ("")}
                            </div>
                        ) :
                        (
                            <div className="uk-text-right">
                                {this.state.nextModuleId !== 0 ? (
                                    <Button
                                        className="uk-button uk-button-default"
                                        style={{ color: "#1e87f0", borderColor: "#1e87f0" }}
                                        type="button"
                                        onClick={() => this.passToNextModule(this.state.nextModuleId)}>
                                        {t('next_module')} <i className="fas fa-angle-right"></i>
                                    </Button>
                                ) : ("")}
                            </div>
                        )}

                </div>
                {/* <div class="uk-child-width-1-2" uk-grid="true">
                    <div>
                        <Button
                            className="uk-button uk-button-primary uk-margin uk-align-left"
                            type="button"
                            onClick={() => this.userResult()}
                            disabled={loading ? (true) : (false)}>
                            {loading ? (
                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                            ) : ("View My Learning Dashboard")}
                        </Button>
                    </div>
                    <div>
                        <div class="uk-child-width-1-2" uk-grid="true">
                            <div className="">
                                <Button
                                    className="uk-button uk-button-primary uk-margin"
                                    type="button"
                                    onClick={() => this.redirectToQuiz()}>Retake the Quiz
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className="uk-button uk-button-primary uk-margin float"
                                    type="button"
                                    onClick={() => this.passToNextModule()}>
                                    Next module <i className="fas fa-angle-right"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* 
            <Button
                className="uk-button uk-button-primary uk-margin"
                type="button"
                onClick={() => this.userResult()}
                disabled={loading ? (true) : (false)}
            >
                {loading ? (
                    <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                ) : ("View My Learning Dashboard")}
            </Button> */}
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(QuizReviewContainer))));
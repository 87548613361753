import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import Sub_module_banner from '../../../components/sub_module/sub_module_banner/sub_module_banner';
import Sub_module_container from '../../../components/sub_module/sub_module_container/sub_module_container';
import Footer from '../../../components/footer/footer';
import { Card_loading_content } from '../../../components/sub_module/card_sub_module/card_sub_module';
import { setNextSubModuleStatus } from "../../../actions/user-actions"
import QuizButtonComponent from '../../../components/quizButtonComponent/quizButtonComponent';

//queries
import { GET_SUB_MODULES, GET_LESSONS, GET_NEXT_SUBMODULE, GET_PREVIOUS_SUBMODULE, GET_NEXT_MODULE, GET_PREVIOUS_MODULE, GET_FLASH_QUIZ_SUBMISSIONS, GET_SUB_MODULE } from '../../../queries/common_queries';
import FlashQuiz from '../../../components/flashQuiz/flash_quiz';
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        nextSubModuleDisabled: state.custom_reducer.nextSubModuleDisabled,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setNextButtonStatus: (status) => {
            dispatch(setNextSubModuleStatus(status))
        }
    }
}

class Sub_module extends React.Component {
    constructor(props) {
        super(props);

        // window.scrollTo(-500, -200);

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let module = query.get('module');
        console.log(module)
        let last = query.get('last');
        console.log(id)

        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (id == undefined) {
                this.props.history.push('/home');
            } else if (module == undefined) {
                this.props.history.push('/home');
            }
        }

        this.state = {
            id: id,
            from: page,
            module: "",
            sub_modules: [],
            sub_module: "",
            lessons: [],
            contents: [],
            moduleId: module,
            pagination: {
                skip: 0,
                first: 20,
                subModuleId: id,
                isActive: true,
                moduleId: module,
                lang: localStorage.LANG
            },
            paginationSub: {
                isActive: true,
                moduleId: module,
                lang: localStorage.LANG
            },
            redirectKey: "",
            loading: false,
            last: last,
            nextSubModuleId: "",
            previousSubModuleId: "",
            nextModuleId: "",
            prevModuleId: "",
            count: '',
            subModuleCount: 1,
            disableNestSubModBtn: true,

            flashQuizSubmissionType: '',
            flashQuizStatus: '',
            fqSubmission: '',
            learningSummary: [],
            presentations: [],
            quizStatus: false,

        }
        // this.passToPreviousSubModule = this.passToPreviousSubModule.bind(this);
        // console.log(this.state.module)
        // console.log("content");
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
                    : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
                    : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
                    : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
    }

    componentDidMount() {
        this.initializeSubModuleScreen();
        this.initializeModuleScreen();

    }

    componentDidUpdate(prevState) {
        // if (prevState.id !== this.state.id) {
        //     this.initializeSubModuleScreen();
        //     this.initializeModuleScreen();
        // }


    }

    initializeSubModuleScreen() {
        this.setState({ loading: true });
        this.get_lessons(this.state.pagination).then(result => {
            console.log(result)
            // this.get_sub_modules(this.state.paginationSub).then(res=>{
            //     console.log(res)
            //     console.log(res.subModules.count)

            var redirectVal = "";
            if (result.lessons.count > 0) {
                redirectVal = "LESSON"
            }
            if (result.contents.count > 0) {
                redirectVal = "CONTENT"
            }
            console.log(result.subModule.presentations)
            this.setState({
                module: result.module, sub_modules: result.subModules,
                presentations: result.subModule.presentations != null ? result.subModule.presentations : [],
                sub_module: result.subModule,
                lessons: result.lessons,
                moduleId: result.subModule.moduleId,
                contents: result.contents, redirectKey: redirectVal,
                learningSummary:
                    result.module.learningSummary != null
                        ? result.module.learningSummary.summaries
                        : [],
                loading: false
            });
            // console.log(this.state.module.id)    
            // }

            //     )

            this.get_flash_quiz_submissions(this.state.sub_module).then(result => {
                if (result.length > 0) {
                    if (result[0].submissionStatus === "PASSED") {
                        this.props.setNextButtonStatus(false);
                        this.setState({ flashQuizSubmissionType: 'UPDATE', flashQuizStatus: 'PASSED', fqSubmission: result[0] })
                    }
                    else {
                        this.props.setNextButtonStatus(true);
                        this.setState({ flashQuizSubmissionType: 'UPDATE', flashQuizStatus: 'FAILED', fqSubmission: result[0] })
                    }
                } else {
                    this.setState({ flashQuizSubmissionType: 'CREATE' })
                    this.props.setNextButtonStatus(true);
                }
            });
        });

        this.get_next_submodule().then(Result => {
            // this.get_sub_modules(this.state.paginationSub).then(res=>{
            console.log(Result.length)
            if (Result.length !== 0) {
                console.log("console1")

                this.setState({ nextSubModuleId: Result[0].id, moduleId: Result[0].moduleId })
                // sub_modules: res.subModules,
                // moduleId: res[0].moduleId
            } else {
                console.log("console2")

                this.setState({ nextSubModuleId: 0 });
            }
            console.log(this.state.nextModuleId)
            // console.log(this.state.moduleId)
            // })
        });

        this.get_pervious_submodule().then(previousResult => {
            // this.get_sub_modules(this.state.paginationSub).then(res=>{
            console.log(previousResult.length)
            if (previousResult.length !== 0) {
                this.setState({ previousSubModuleId: previousResult[0].id, moduleId: previousResult[0].moduleId });
                // ,sub_modules: res.subModules
            } else {
                this.setState({ previousSubModuleId: 0 });
            }
            // })

            // if (previousResult.length > 0) {
            //     this.setState({ previousSubModuleId: previousResult[0].id, moduleId: previousResult[0].moduleId });
            // }
        })
        console.log(this.state.sub_module)
    }

    initializeModuleScreen() {
        this.setState({ loading: true });
        this.get_sub_modules(this.state.paginationSub).then(result => {
            var redirectVal = "";
            if (result.subModules.count > 0) {
                redirectVal = "SUBMODULE"
            }
            if (result.lessons.count > 0) {
                redirectVal = "LESSON"
            }
            if (result.contents.count > 0) {
                redirectVal = "CONTENT"
            }
            this.setState({
                module: result.module, sub_modules: result.subModules, lessons: result.lessons, count: result.subModules.count,
                contents: result.contents, redirectKey: redirectVal, loading: false
            });
            console.log(result.subModules.subModules)
        });

        this.get_next_module().then(result => {
            console.log(result.length)
            if (result.length !== 0) {
                this.setState({ nextModuleId: result[0].id });
            } else {
                this.setState({ nextModuleId: 0 });
            }

        })

        this.get_previous_module().then(result => {
            console.log(result.length)
            if (result.length !== 0) {
                this.setState({ prevModuleId: (result[0].id) });
            } else {
                this.setState({ prevModuleId: 0 })
            }
        })
    }

    get_previous_module = async () => {
        const result = await this.props.client.query({
            query: GET_PREVIOUS_MODULE,
            variables: {
                moduleId: this.state.moduleId,
                last: 1,
                lang: localStorage.LANG
            },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getBackModule;
    };


    get_next_module = async () => {
        const result = await this.props.client.query({
            query: GET_NEXT_MODULE,
            variables: {
                moduleId: this.state.moduleId,
                first: 1,
                lang: localStorage.LANG
            },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getNextModule;
    };

    get_sub_modules = async (paginationSub) => {
        console.log(paginationSub)
        const result = await this.props.client.query({
            query: GET_SUB_MODULES,
            variables: paginationSub,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        console.log(result.data.getSubModules)
        return result.data.getSubModules;
    };

    get_lessons = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_LESSONS,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getLessons;
    };

    get_next_submodule = async () => {
        console.log(this.state.moduleId, this.state.id)
        const result = await this.props.client.query({
            query: GET_NEXT_SUBMODULE,
            variables: {
                moduleId: this.state.moduleId,
                subModuleId: this.state.id,
                first: 1,
                lang: localStorage.LANG 
            },
            fetchPolicy: 'network-only'
        });
        return result.data.getNextSubModule;
    };

    get_pervious_submodule = async () => {
        const result = await this.props.client.query({
            query: GET_PREVIOUS_SUBMODULE,
            variables: {
                moduleId: this.state.moduleId,
                subModuleId: this.state.id,
                last: 1,
                lang: localStorage.LANG
            },
            fetchPolicy: 'network-only'
        });
        return result.data.getBackSubModule;
    };

    get_flash_quiz_submissions = async (submissionDetails) => {
        console.log(submissionDetails)
        const result = await this.props.client.query({
            query: GET_FLASH_QUIZ_SUBMISSIONS,
            variables: {
                subModuleId: submissionDetails.id
            },
            fetchPolicy: 'network-only'
        });
        console.log(result.data.getFlashQuizSubmissions)
        return result.data.getFlashQuizSubmissions;
    };

    passToNextSubModule = (nextSubModuleId, moduleId) => {
        console.log(moduleId)
        window.scrollTo(-500, -200);
        this.props.history.push('/sub_module?id=' + nextSubModuleId + '&module=' + moduleId)

        setTimeout(() => {
            var currentLocation = window.location.href;
            var url = currentLocation.split("?")
            url = url[0];
            url = url.split("/");
            var page = url[url.length - 1];

            let query = new URLSearchParams(this.props.location.search);
            let id = query.get('id');
            let module = query.get('module');
            let last = query.get('last');

            this.setState({ id: id, from: page, moduleId: module, subModuleId: id, pagination: { skip: 0, first: 20, isActive: true, subModuleId: id, moduleId: module, subModuleCount: this.state.subModuleCount++, lang: localStorage.LANG } });
            // subModuleCount:this.state.subModuleCount++
            // last: last,
            this.initializeSubModuleScreen();
            // console.log(this.state.subModuleCount)
            console.log(this.state.subModuleId)
        }, 1000);

        this.get_flash_quiz_submissions(this.state.sub_module).then(result => {
            if (result.length > 0) {
                if (result[0].submissionStatus === "PASSED") {
                    this.props.setNextButtonStatus(false);
                    this.setState({ flashQuizSubmissionType: 'UPDATE', flashQuizStatus: 'PASSED', fqSubmission: result[0] })
                }
                else {
                    this.props.setNextButtonStatus(true);
                    this.setState({ flashQuizSubmissionType: 'UPDATE', flashQuizStatus: 'FAILED', fqSubmission: result[0] })
                }
            } else {
                this.setState({ flashQuizSubmissionType: 'CREATE' })
                this.props.setNextButtonStatus(true);
            }
        });

    }

    passToPreviousSubModule = (previousSubModuleId, moduleId) => {
        window.scrollTo(-500, -200);
        this.props.history.push('/sub_module?id=' + previousSubModuleId + '&module=' + moduleId)

        setTimeout(() => {
            var currentLocation = window.location.href;
            var url = currentLocation.split("?")
            url = url[0];
            url = url.split("/");
            var page = url[url.length - 1];

            let query = new URLSearchParams(this.props.location.search);
            let id = query.get('id');
            let module = query.get('module');
            let last = query.get('last');

            this.setState({ id: id, from: page, moduleId: module, subModuleId: id, last: last, pagination: { skip: 0, last: 20, isActive: true, subModuleId: id, moduleId: module, subModuleCount: this.state.subModuleCount--, lang: localStorage.LANG } });
            this.initializeSubModuleScreen();
            console.log(this.state.subModuleId)
        }, 1000);
    }

    passToNextModule = (nextModuleId) => {
        window.scrollTo(-500, -200);
        this.props.history.push('/module?id=' + nextModuleId);

        setTimeout(() => {
            var currentLocation = window.location.href;
            var url = currentLocation.split("?")
            url = url[0];
            url = url.split("/");
            var page = url[url.length - 1];

            let query = new URLSearchParams(this.props.location.search);
            let id = query.get('id');

            this.setState({
                id: id, from: page, moduleId: id, paginationSub: {
                    // skip: 0, first: 3,
                    isActive: true, moduleId: module, lang: localStorage.LANG
                }
            });
            this.initializeModuleScreen();
        }, 1000)
    }

    passToPreviousModule = (prevModuleId) => {
        window.scrollTo(-500, -200);
        this.props.history.push('/module?id=' + prevModuleId);

        setTimeout(() => {
            var currentLocation = window.location.href;
            var url = currentLocation.split("?")
            url = url[0];
            url = url.split("/");
            var page = url[url.length - 1];

            let query = new URLSearchParams(this.props.location.search);
            let id = query.get('id');

            this.setState({
                id: id, from: page, moduleId: id, paginationSub: {
                    isActive: true, moduleId: module, lang: localStorage.LANG
                }
            });
            this.initializeModuleScreen();
        }, 1000)

        // this.props.history.goBack();
    }
    redirectToHome = () => {
        this.props.history.push("/home")
    }

    get_submodule = async (id, lang) => {
        const result = await this.props.client.query({
            query: GET_SUB_MODULE,
            variables: { subModuleId: id, lang: lang },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModule;
    };

    updateSubmoduleId = () => {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        console.log(id);
        console.log(this.state.id);
        if (id != this.state.id) {
            this.get_submodule(id, localStorage.LANG).then(result => {
                console.log(result);
                this.setState({
                    sub_module: result,
                    id: id,
                })
            })
        }
    }

    render() {
        this.updateSubmoduleId();
        const { id, moduleId, presentations, from, module, sub_modules, last, sub_module, lessons, contents, redirectKey, loading, count, subModuleCount, nextSubModuleId, fqSubmission, learningSummary } = this.state;
        const { nextSubModuleDisabled, t } = this.props;
        return (
            <div>
                <Navbar />
                <div className="app" style={{ paddingBottom: "20px", backgroundColor: "#ffffff" }}>
                    <Header />
                    {loading ? (
                        <div>
                            <div className="course-dhashboard" style={{ backgroundImage: "linear-gradient(to right, rgb(24, 41, 120) 50% ,rgb(24, 41, 120,0.9) )", minHeight: "140px", maxHeight: "140px" }}>
                                <div className="uk-grid">
                                   {localStorage.LANG=='ar'?(
                                        <div style={{paddingTop:"22px"}}>
                                            <h2 className="uk-light uk-text-bold uk-text-white"> {t('wait')} </h2>
                                            <p className="uk-light uk-text-bold"> {t('loading')} </p>
                                        </div>
                                   ):(
                                        <div>
                                        <h2 className="uk-light uk-text-bold uk-text-white"> {t('wait')} </h2>
                                        <p className="uk-light uk-text-bold"> {t('loading')} </p>
                                    </div>
                                   )}
                                </div>
                            </div>
                            <div className="uk-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400">
                                <div className="uk-clearfix boundary-align">
                                    <div className="section-heading none-border">
                                        <Card_loading_content />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                            [<Sub_module_banner sub_module={sub_module} />,
                            <div className="uk-container" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400">
                                <Sub_module_container presentations={presentations} nextSubModuleId={nextSubModuleId} id={id + 1} module={module} learningSummary={learningSummary} last={last} moduleId={this.state.moduleId} from={from} redirectKey={redirectKey} contents={contents} sub_module={sub_module} lessons={lessons} />
                                {console.log(sub_module.flashQuiz ? sub_module.flashQuiz.questions : "JO")}

                                {
                                    sub_module.flashQuiz ?
                                        sub_module.flashQuiz.questions.length > 0 ?
                                            <FlashQuiz questions={sub_module.flashQuiz.questions} subModule={sub_module} submission={fqSubmission} submissionType={this.state.flashQuizSubmissionType} submissionStatus={this.state.flashQuizStatus} />
                                            : null
                                        : null
                                }
                                {
                                    nextSubModuleId != 0 ?
                                        null
                                        :
                                        <div>
                                            <QuizButtonComponent moduleId={this.state.moduleId} quizStatus={this.state.quizStatus} />
                                        </div>
                                }

                                <br />
                                {this.state.nextSubModuleId !== 0 ? (
                                    localStorage.LANG=='ar' ?
                                    (
                                        <button style={{float:"left"}} className="uk-button uk-button-primary next-prev-btn" onClick={() => this.passToNextSubModule(this.state.nextSubModuleId, this.state.moduleId)}>
                                            {t('next_sub_module')} <i className="fas fa-angle-left"></i>
                                        </button>
                                    ):(
                                        <button className="uk-button uk-button-primary float next-prev-btn" onClick={() => this.passToNextSubModule(this.state.nextSubModuleId, this.state.moduleId)}
                                    // disabled={nextSubModuleDisabled} 
                                    >{t('next_sub_module')} <i className="fas fa-angle-right"></i></button>

                                    )
                                    
                                    /* subModuleCount < count ? (
                                        <button className="uk-button uk-button-primary float next-prev-btn" onClick={() => this.passToNextSubModule(this.state.nextSubModuleId, this.state.moduleId)} > Next Submodule <i className="fas fa-angle-right"></i></button>
                                    ): (
                                        this.state.nextModuleId!==0 ? (<button className="uk-button uk-button-primary float next-prev-btn"  onClick={() => this.passToNextModule(this.state.nextModuleId)} > Next Module <i className="fas fa-angle-right"></i></button>):("")
                                    ) */
                                ) :
                                    (
                                        this.state.nextModuleId == 0 ? ("") : (
                                            localStorage.LANG=='ar' ? (
<button style={{float:"left"}} className="uk-button uk-button-primary next-prev-btn" onClick={() => this.passToNextModule(this.state.nextModuleId)} >{t('next_module')} <i className="fas fa-angle-left"></i></button>
                                            ):(
<button className="uk-button uk-button-primary float next-prev-btn" onClick={() => this.passToNextModule(this.state.nextModuleId)} >{t('next_module')} <i className="fas fa-angle-right"></i></button>
                                            )
                                            
                                        )

                                    )}

                                {this.state.previousSubModuleId !== 0 ? (
                                        <button className={localStorage.LANG=='ar'? "uk-button uk-button-primary uk-align-right next-prev-btn": "uk-button uk-button-primary uk-align-left next-prev-btn"} onClick={() => this.passToPreviousSubModule(this.state.previousSubModuleId, this.state.moduleId)} ><i className={localStorage.LANG=='ar'? "fas fa-angle-right": "fas fa-angle-left"} ></i> {t('prev_sub_module')} </button>
                                    
                                   
                                ) : (
                                        this.state.prevModuleId !== 0 ? (
                                                <button className={localStorage.LANG=='ar'? "uk-button uk-button-primary uk-align-right next-prev-btn": "uk-button uk-button-primary uk-align-left next-prev-btn" }  onClick={() => this.passToPreviousModule(this.state.prevModuleId)} ><i className= {localStorage.LANG=='ar'? "fas fa-angle-right": "fas fa-angle-left"} ></i> {t('prev_module')} </button>
            
                                        ) : (
                                                <button className={localStorage.LANG=='ar'? "uk-button uk-button-primary uk-align-right": "uk-button uk-button-primary uk-align-left" }  onClick={() => this.redirectToHome()}> {t('back_home')} </button>
                                            )
                                    )
                                }
                            </div>]
                        )}
                </div>
                <Footer language={localStorage.LANG}/>
            </div>
        );
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Sub_module)))); 
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar'
import Header from '../../../components/header/header'
import Footer from  '../../../components/footer/footer'
import AboutUsBanner from '../../../components/aboutus/aboutusBanner/aboutusBanner'
import AboutUsContainer from '../../../components/aboutus/aboutusContainer/aboutusContainer'

class  AboutUs extends React.Component{
    constructor(props){
        super(props);
    }

    render() {
        return(
            <div>
              <Navbar />
              <div className="app">
                <Header />
                <AboutUsBanner/>
                <AboutUsContainer/>   
            </div>      
            <Footer />  
            </div>
        )
    }
}
 export default withRouter(withApollo(AboutUs));
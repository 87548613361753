import * as React from "react";
import { connect } from 'react-redux';
import { CREATE_FLASH_QUIZ_SUBMISSION, UPDATE_FLASH_QUIZ_SUBMISSION } from "../../queries/user_queries";
import { withApollo } from 'react-apollo';
import { setNextSubModuleStatus } from "../../actions/user-actions"
import { GET_FLASH_QUIZ_SUBMISSIONS } from "../../queries/common_queries";
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
  return {
    nextSubModuleDisabled: state.custom_reducer.nextSubModuleDisabled,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setNextButtonStatus: (status) => {
      dispatch(setNextSubModuleStatus(status))
    }
  }
}

class FlashQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      flashQuizAnswers: [],
      correctAnswers: [],
      formSuccessMessage: "",
      formErrorMessage: "",
      flashQuizAnswerIndexes: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
    };
  }

  componentDidMount() {
    const correctAnswers = [];

    const userAnswers = Array(this.props.questions.length).fill("");
    this.props.questions.map((question) =>
      correctAnswers.push(question.correctAnswer)
    );
    this.setState({ correctAnswers: correctAnswers, flashQuizAnswers: userAnswers });

    this.get_flash_quiz_submissions(this.props.subModule).then(result => {
      if (result.length > 0) {
        if (result[0].submissionStatus === "PASSED") {
          this.setState({ flashQuizStatus: 'PASSED' })
        }
        else {
          this.setState({ flashQuizStatus: 'FAILED' })
        }
      }
      else {
        this.setState({ flashQuizStatus: 'FAILED' })
      }
    })

  }

  createFlashQuizSubmission = async (quizStatus) => {
    const { questions, subModule } = this.props;
    const result = await this.props.client.mutate({
      mutation: CREATE_FLASH_QUIZ_SUBMISSION,
      variables: {
        subModuleId: subModule.id,
        moduleId: subModule.moduleId,
        submissionStatus: quizStatus,
        isActive: true,
      },
    });
    console.log(result.data)
    return result.data.createSubModule;
  };

  updateFlashQuizSubmission = async (submissionId, quizStatus) => {
    const { submission } = this.props;
    console.log(submissionId)
    const result = await this.props.client.mutate({
      mutation: UPDATE_FLASH_QUIZ_SUBMISSION,
      variables: {
        submissionId: submissionId,
        submissionStatus: quizStatus
      },
    });
    return result.data.updateFlashQuizSubmission;
  };

  get_flash_quiz_submissions = async (submissionDetails) => {
    console.log(submissionDetails)
    const result = await this.props.client.query({
      query: GET_FLASH_QUIZ_SUBMISSIONS,
      variables: {
        subModuleId: submissionDetails.id
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: 'network-only'
    });
    console.log(result.data.getFlashQuizSubmissions)
    return result.data.getFlashQuizSubmissions;
  };

  handleAnswerChange = (e, data, qIndex, aIndex) => {
    console.log(e.target.value);
    var tempArray = this.state.flashQuizAnswers;
    tempArray[qIndex] = e.target.value;
    this.setState({ flashQuizAnswers: tempArray });
  };

  submitAnswers = () => {
    const i = this.state.flashQuizAnswers.findIndex(obj => obj === '')
    if (i >= 0) {
      this.setState({ formErrorMessage: localStorage.LANG == "ar" ? (<div style={{ paddingRight: "20px" }}> {this.props.t('answer_all_ques')} </div>) : this.props.t('answer_all_ques') })
    }
    else {
      if (JSON.stringify(this.state.correctAnswers) === JSON.stringify(this.state.flashQuizAnswers)) {
        this.createFlashQuizSubmission('PASSED')
          .then((result) => {
            this.setState({ formSuccessMessage: localStorage.LANG=="ar"? (
              <div style={{paddingRight:"20px"}}> {this.props.t('proceed_next_module')} </div>
            ):(this.props.t('proceed_next_module')) , formErrorMessage: "" });
            this.props.setNextButtonStatus(false);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              formSuccessMessage: "", formErrorMessage: localStorage.LANG == "ar" ? (
                <div style={{ paddingRight: "20px" }}>
                  {this.props.t('error_submitting_flashQuiz_ans')} <br />
                  {this.props.t('try_agin')}
                </div>
              ) : (
                <div>
                  {this.props.t('error_submitting_flashQuiz_ans')} <br />
                  {this.props.t('try_agin')}
                </div>
              )
            });
          });
      }
      else {
        this.createFlashQuizSubmission('FAILED')
          .then((result) => {
            this.setState({
              formSuccessMessage: "", formErrorMessage: localStorage.LANG == "ar" ? (
                <div style={{ float: "right", paddingRight: "20px" }} > {this.props.t('Answer_all_for_next_module')} </div>
              ) : this.props.t('Answer_all_for_next_module')
            });
            this.props.setNextButtonStatus(true);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              formSuccessMessage: "", formErrorMessage: localStorage.LANG == "ar" ? (
                <div style={{ paddingRight: "20px" }}>
                  {this.props.t('error_submitting_flashQuiz_ans')} <br />
                  {this.props.t('try_agin')}
                </div>
              ) : (
                <div>
                  {this.props.t('error_submitting_flashQuiz_ans')} <br />
                  {this.props.t('try_agin')}
                </div>
              )
            });
          });
      }
    }
  };

  updateAnswers = (submissionId) => {
    const i = this.state.flashQuizAnswers.findIndex(obj => obj === '')
    if (i >= 0) {
      this.setState({ formErrorMessage: localStorage.LANG == "ar" ? (<div style={{ paddingRight: "20px" }}> {this.props.t('answer_all_ques')} </div>) : this.props.t('answer_all_ques') })
    }
    else {
      if (JSON.stringify(this.state.correctAnswers) === JSON.stringify(this.state.flashQuizAnswers)) {
        this.updateFlashQuizSubmission(submissionId, 'PASSED')
          .then((result) => {
            this.setState({ formSuccessMessage: localStorage.LANG=="ar"? (
              <div style={{paddingRight:"20px"}}>
                {this.props.t('proceed_next_module')}
              </div>
            ):(this.props.t('proceed_next_module')) , formErrorMessage: "" });
            this.props.setNextButtonStatus(false);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              formSuccessMessage: "", formErrorMessage: localStorage.LANG == "ar" ? (
                <div style={{paddingRight:"20px"}} >{this.props.t('error_updating_answers')}<br />
                  {this.props.t('try_agin')} </div>
              ) : (
                <div>{this.props.t('error_updating_answers')}<br />
                  {this.props.t('try_agin')} </div>
              )
            });
          });
      }
      else {
        this.updateFlashQuizSubmission(submissionId, 'FAILED')
          .then((result) => {
            this.setState({
              formSuccessMessage: "", formErrorMessage: localStorage.LANG == "ar" ? (
                <div style={{ float: "right", paddingRight: "20px" }} > {this.props.t('Answer_all_for_next_module')} </div>
              ) : this.props.t('Answer_all_for_next_module')
            });
            this.props.setNextButtonStatus(true);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              formSuccessMessage: "", formErrorMessage: localStorage.LANG=="ar"? (
              <div style={{paddingRight:"20px"}}> {this.props.t('error_updating_answers')} <br />
              {this.props.t('try_agin')} </div>
              ):(
              <div> {this.props.t('error_updating_answers')} <br />
              {this.props.t('try_agin')} </div>
              ) 
            });
          });
      }
    }
  };

  handleSubmit = () => {
    this.get_flash_quiz_submissions(this.props.subModule).then(result => {
      console.log(result)
      if (result.length > 0) {
        this.updateAnswers(result[0].id);

      } else {
        this.submitAnswers();
      }
    });
  };

  render() {
    const { questions, subModule, nextSubModuleDisabled, submissionType, submissionStatus, submission, t } = this.props;
    const { formErrorMessage, formSuccessMessage } = this.state;
    return (
      <div className="flashQuizMainDiv">
        <text className="flashQuizHeading"> {t('flash_quiz')}</text>
        <hr className="flashQuizHr" />
        {questions.map((question, index) => (
          this.state.flashQuizStatus === "FAILED" ?
            <div className="flashQuizQuestionDiv">
              <text className="flashQuizQuestion">
                {index + 1}) {question.question}
              </text>
              {question.answers.map((answer, i) => (
                <div className={localStorage.LANG == "ar" ? ("flashQuizAnswerDiv_Ar") : ("flashQuizAnswerDiv")}>
                  {/* <text>{this.state.flashQuizAnswerIndexes[i]})</text> */}
                  <input
                    type="radio"
                    name={index}
                    value={answer.answer}
                    onChange={(e, data) =>
                      this.handleAnswerChange(e, data, index, i)
                    }
                  ></input>
                  <text >{answer.answer}</text>
                </div>
              ))}
            </div>
            :
            <div className="flashQuizQuestionDivDisabled">
              <text className="flashQuizQuestion">
                {index + 1}) {question.question}
              </text>
              {question.answers.map((answer, i) => (
                <div className={localStorage.LANG == "ar" ? ("flashQuizAnswerDiv_after_submission_Ar") : ("flashQuizAnswerDiv_after_submission")} >
                  {/* <text style={{marginRight:'0.5rem'}}>{this.state.flashQuizAnswerIndexes[i]})</text> */}

                  {/* <input
                  type="radio"
                  disabled
                  name={index}
                  value={answer.answer}
                  onChange={(e, data) =>
                    this.handleAnswerChange(e, data, index, i)
                  }
                ></input> */}
                  <text style={{}}>{answer.answer}  {answer.correctAnswer === true ? <i class="fa fa-check-circle" style={{ fontSize: '0.8rem', color: 'green' }} aria-hidden="true"></i> : null}</text>
                </div>
              ))}
            </div>
        ))}
        <div>
          {formErrorMessage != '' ? (
            <div style={localStorage.LANG == "ar" ? (styles.messageDiv_Ar) : (styles.messageDiv)} className="danger_alert" >{formErrorMessage}</div>
          ) : formSuccessMessage != '' ? (
            <div style={localStorage.LANG == "ar" ? (styles.messageDiv_Ar) : (styles.messageDiv)} className="success_alert">{formSuccessMessage} </div>
          ) : (
            null
          )}
        </div>
        {
          submissionType === "CREATE" ?
            this.state.flashQuizStatus === 'PASSED' ?
              null
              :
              <button
                style={styles.button}
                className={localStorage.LANG == "ar" ? ("uk-button uk-align-left flashQuizSubmitButton_Ar") : ("uk-button uk-align-left flashQuizSubmitButton")}
                onClick={() => this.handleSubmit()}
              >
                {" "}
                {t('submit')}{" "}
              </button>
            : submissionType === "UPDATE" ?
              this.state.flashQuizStatus === 'PASSED' ?
                null
                :
                <button
                  style={styles.button}
                  className={localStorage.LANG == "ar" ? ("uk-button uk-align-left flashQuizSubmitButton_Ar") : ("uk-button uk-align-left flashQuizSubmitButton")}
                  onClick={() => this.handleSubmit()}
                >
                  {" "}
                  {t('submit')}{" "}
                </button>
              : null
        }

      </div>
    );
  }
}

// export default (withApollo(FlashQuiz));
export default (connect(mapStateToProps, mapDispatchToProps)(withApollo(withNamespaces()(FlashQuiz))));

const styles = {
  messageDiv: {
    width: '78%',
    float: 'left',
  },
  messageDiv_Ar: {
    width: '78%',
    float: 'right',
  },
  button: {
    width: '20%',
    float: 'right',
    marginTop: '0.2rem',
  }
}

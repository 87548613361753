import gql from "graphql-tag";

export const LOGIN_QUERY = gql`
  mutation LoginQuery($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        mobile
        tel
        ext
        email
        gender
        userType
        isActive
        loggedInAfterSignup
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String
    $email: String!
    $mobile: String
    $tel: String
    $ext: String
    $gender: Gender
    $userType: UserType!
    $isActive: Boolean
    $password: String!
    $fullName: String
    $country: String
    $region: String
    $year: Int
    $languagePreference: String
    # COMMENT - 22/04/2021
  ) {
    signup(
      firstName: $firstName
      lastName: $lastName
      email: $email
      mobile: $mobile
      tel: $tel
      ext: $ext
      gender: $gender
      userType: $userType
      isActive: $isActive
      password: $password
      fullName: $fullName
      country: $country
      region: $region
      year: $year
      languagePreference: $languagePreference
      # COMMENT - 22/04/2021
    ) {
      token
      user {
        id
        firstName
        lastName
        mobile
        tel
        ext
        email
        gender
        userType
        isActive
        fullName
        country
        region
        year
        languagePreference
        # COMMENT - 22/04/2021
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation EditBasicProfile(
    $userId: String!
    $firstName: String
    $lastName: String
    $mobile: String
    $tel: String
    $isActive: Boolean
  ) {
    editBasicProfile(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
      tel: $tel
      isActive: $isActive
    ) {
      id
      firstName
      lastName
      mobile
      tel
      ext
      email
      gender
      userType
      isActive
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userID: String!) {
    deleteUser(userID: $userID) {
      id
      firstName
      lastName
      mobile
      tel
      ext
      email
      gender
      userType
      isActive
      fullName
      country
      region
      year
      createdAt
      languagePreference
      # COMMENT - 22/04/2021
    }
  }
`;

export const CREATE_ANALYSTIC_RECORD = gql`
  mutation CreateAnalyticRecord(
    $moduleId: String
    $subModuleId: String
    $lessonId: String
    $contentId: String
  ) {
    createAnalyticRecord(
      moduleId: $moduleId
      subModuleId: $subModuleId
      lessonId: $lessonId
      contentId: $contentId
    ) {
      id
      moduleId
      subModuleId
      lessonId
      contentId
    }
  }
`;

export const EDIT_BASIC_PROFILE = gql`
  mutation EditBasicProfile(
    $userId: String!
    $firstName: String
    $lastName: String
    $mobile: String
    $tel: String
    $ext: String
    $password: String
    $gender: Gender
    $isActive: Boolean
    $fullName: String
    $country: String
    $region: String
    $year: Int
    $newsletterSubscription: Boolean
    $languagePreference: String
    # COMMENT - 22/04/2021
  ) {
    editBasicProfile(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
      tel: $tel
      ext: $ext
      password: $password
      gender: $gender
      isActive: $isActive
      fullName: $fullName
      country: $country
      region: $region
      year: $year
      newsletterSubscription: $newsletterSubscription
      languagePreference: $languagePreference
      # COMMENT - 22/04/2021
    ) {
      id
      firstName
      lastName
      mobile
      tel
      ext
      email
      gender
      userType
      isActive
      fullName
      country
      region
      year
      newsletterSubscription
      languagePreference
      # COMMENT - 22/04/2021
    }
  }
`;

export const CREATE_MODULE_QUIZ_SUBMISSION = gql`
  mutation CreateModuleQuizSubmission(
    $moduleId: String!
    $moduleAnswerQuiz: JSON!
    $score: Float!
    $certificateStatus: Boolean!
  ) {
    createModuleQuizSubmission(
      moduleId: $moduleId
      moduleAnswerQuiz: $moduleAnswerQuiz
      score: $score
      certificateStatus: $certificateStatus
    ) {
      id
      moduleId
      moduleAnswerQuiz
      score
      certificateStatus
      isActive
    }
  }
`;

export const UPDATE_MODULE_QUIZ_SUBMISSION = gql`
  mutation UpdateModuleQuizSubmission(
    $moduleId: String!
    $moduleAnswerQuiz: JSON!
    $score: Float!
  ) {
    updateModuleQuizSubmission(
      moduleId: $moduleId
      moduleAnswerQuiz: $moduleAnswerQuiz
      score: $score
    ) {
      id
      moduleId
      moduleAnswerQuiz
      score
      certificateStatus
      isActive
    }
  }
`;

export const GET_QUIZ_SUBMISSION = gql`
  query GetQuizSubmission($moduleQuizSubmissionId: String!) {
    getQuizSubmission(moduleQuizSubmissionId: $moduleQuizSubmissionId) {
      id
      moduleId
      moduleAnswerQuiz
      score
      certificateStatus
      isActive
    }
  }
`;

export const GET_QUIZ_SUBMISSION_STATUS = gql`
  query GetQuizSubmissionStatus($moduleId: String!) {
    getQuizSubmissionStatus(moduleId: $moduleId) {
      status
    }
  }
`;

export const CREATE_NEWS = gql`
  mutation CreateNews(
    $newsTitle: String!
    $newsBannerImage: String
    $newsHelperText: String!
    $newsContent: String!
    $isActive: Boolean
  ) {
    createNews(
      newsTitle: $newsTitle
      newsBannerImage: $newsBannerImage
      newsHelperText: $newsHelperText
      newsContent: $newsContent
      isActive: $isActive
    ) {
      id
      newsTitle
      newsBannerImage
      newsHelperText
      newsContent
      createdAt
      isActive
    }
  }
`;

export const UPDATE_NEWS = gql`
  mutation UpdateNews(
    $newsId: String!
    $newsTitle: String
    $newsBannerImage: String
    $newsHelperText: String
    $newsContent: String
    $isActive: Boolean
    $lang: Lang!
  ) {
    updateNews(
      newsId: $newsId
      newsTitle: $newsTitle
      newsBannerImage: $newsBannerImage
      newsHelperText: $newsHelperText
      newsContent: $newsContent
      isActive: $isActive,
      lang: $lang
    ) {
      id
      newsTitle
      newsBannerImage
      newsHelperText
      newsContent
      createdAt
      isActive
      # languageObj
    }
  }
`;

export const CREATE_GALLERY = gql`
  mutation CreateGallery(
    $galleryTitle: String!
    $galleryBannerImage: String
    $galleryHelperText: String
    $galleryDescription: String
    $isActive: Boolean
    $eventDate: String
    $supportingLangs: JSON
    $videos: JSON
    $pinned: Boolean
  ) {
    createGallery(
      galleryTitle: $galleryTitle
      galleryBannerImage: $galleryBannerImage
      galleryHelperText: $galleryHelperText
      galleryDescription: $galleryDescription
      isActive: $isActive
      eventDate: $eventDate
      supportingLangs: $supportingLangs
      videos: $videos
      pinned: $pinned
    ) {
      id
      galleryTitle
      galleryBannerImage
      galleryHelperText
      galleryDescription
      isActive
      createdAt
      eventDate
      videos
      pinned
    }
  }
`;

export const CREATE_GALLERY_IMAGE = gql`
  mutation CreateGalleryImage(
    $galleryID: String!
    $imageTitle: String
    $image: String
    $imageHelperText: String
    $imageDescription: String
    $isActive: Boolean
    $fileName: String
    $supportingLangs: JSON
  ) {
    createGalleryImage(
      galleryID: $galleryID
      imageTitle: $imageTitle
      image: $image
      imageHelperText: $imageHelperText
      imageDescription: $imageDescription
      isActive: $isActive
      fileName: $fileName
      supportingLangs: $supportingLangs
    ) {
      id
      galleryID
      imageTitle
      image
      imageHelperText
      imageDescription
      isActive
      fileName
    }
  }
`;

export const UPDATE_GALLERY = gql`
  mutation UpdateGallery(
    $galleryId: String!
    $galleryTitle: String
    $galleryBannerImage: String
    $galleryHelperText: String
    $galleryDescription: String
    $isActive: Boolean
    $eventDate: String
    $lang: Lang!
    $videos: JSON
    $pinned: Boolean
    $languageEnabled: Boolean
  ) {
    updateGallery(
      galleryId: $galleryId
      galleryTitle: $galleryTitle
      galleryBannerImage: $galleryBannerImage
      galleryHelperText: $galleryHelperText
      galleryDescription: $galleryDescription
      isActive: $isActive
      eventDate: $eventDate
      lang:$lang
      videos: $videos
      pinned: $pinned
      languageEnabled: $languageEnabled
    ) {
      id
      galleryTitle
      galleryBannerImage
      galleryHelperText
      galleryDescription
      isActive
      createdAt
      eventDate
      videos
      pinned
      languageEnabled
    }
  }
`;

export const UPDATE_GALLERY_IMAGE = gql`
  mutation UpdateGalleryImage(
    $galleryImageId: String!
    $imageTitle: String
    $image: String
    $imageHelperText: String
    $imageDescription: String
    $isActive: Boolean
    $fileName: String
    $lang: Lang!
  ) {
    updateGalleryImage(
      galleryImageId: $galleryImageId
      imageTitle: $imageTitle
      image: $image
      imageHelperText: $imageHelperText
      imageDescription: $imageDescription
      isActive: $isActive
      fileName: $fileName
      lang: $lang
    ) {
      id
      galleryID
      imageTitle
      image
      imageHelperText
      imageDescription
      isActive
      fileName
    }
  }
`;

export const DELETE_GALLERY_IMAGE = gql`
  mutation DeleteGalleryImage($galleryImageID: String!) {
    deleteGalleryImage(galleryImageID: $galleryImageID) {
      id
      galleryID
      imageTitle
      image
      imageHelperText
      imageDescription
      isActive
      fileName
      languageObj
    }
  }
`;

export const GET_USER_RESULT = gql`
  query GetUserResult($lang: Lang!) {
    getUserResult(lang: $lang) {
      modules {
        module {
          id
          moduleTitle
          moduleHelperText
          bannerColor
          bannerIconUrl
          moduleOverview
          content
          moduleNumber
          isActive
        }
        result {
          id
          moduleId
          moduleAnswerQuiz
          score
          certificateStatus
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordRequest($email: String!) {
    resetPasswordRequest(email: $email) {
      status
      reason
      userId
      userEmail
      userName
      psscode
    }
  }
`;

export const VERIFY_PASSCODE = gql`
  query VerifyPasscode($passcode: Int, $userId: String) {
    checkPasscode(passcode: $passcode, userId: $userId) {
      status
      reason
      userId
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($userId: String!, $password: String!) {
    updateUserPassword(userId: $userId, password: $password) {
      id
      firstName
      lastName
      mobile
      tel
      ext
      email
      gender
      userType
      isActive
      fullName
      country
      region
      year
      createdAt
    }
  }
`;

export const CREATE_FLASH_QUIZ_SUBMISSION = gql`
mutation createFlashQuizSubmission($subModuleId: String!, $moduleId: String, $submissionStatus: FlashQuizStatus!, $isActive: Boolean) {
  createFlashQuizSubmission(subModuleId: $subModuleId, moduleId: $moduleId, submissionStatus: $submissionStatus, isActive: $isActive)
    {
      id
      subModuleId
      moduleId
      submissionStatus
      isActive
      createdAt
      updatedAt
      createdBy
    }
  }
`;

export const UPDATE_FLASH_QUIZ_SUBMISSION = gql`
mutation updateFlashQuizSubmission($submissionId: String!, $submissionStatus: FlashQuizStatus!, $isActive: Boolean) {
  updateFlashQuizSubmission(submissionId: $submissionId, submissionStatus: $submissionStatus, isActive: $isActive)
    {
      id
      subModuleId
      moduleId
      submissionStatus
      isActive
      createdAt
      updatedAt
      createdBy
    }
  }
`;

export const CREATE_EXAM_SUBMISSION = gql`
mutation createExamSubmission($examId: String!, $submission: JSON, $isActive: Boolean) {
  createExamSubmission(examId: $examId, submission: $submission, isActive: $isActive)
    {
      id
      examId
      submission
      score
      markedBy
      comments
      isActive
      createdAt
      updatedAt
      createdBy
      grade
      user{
        id
        firstName
        lastName
        mobile
        tel
        ext
        email
        gender
        userType
        isActive
        fullName
        country
        region
        year
      }
    }
  }
`;

export const CREATE_USER_RATING = gql`
mutation createUserRating($rating: String, $review: String, $isActive: Boolean) {
  createUserRating(rating: $rating, review: $review, isActive: $isActive)
    {
      id
      rating
      review
      user{
        id
        firstName
        lastName
        mobile
        tel
        ext
        email
        gender
        userType
        isActive
        fullName
        country
        region
        year
      }
      isActive
      createdAt
      updatedAt
      createdBy
    }
  }
`;

export const UPDATE_USER_RATING = gql`
mutation updateUserRating($ratingId: String, $rating: String, $review: String, $isActive: Boolean) {
  updateUserRating(ratingId: $ratingId, rating: $rating, review: $review, isActive: $isActive)
    {
      id
      rating
      review
      user{
        id
        firstName
        lastName
        email
        userType
        country
        region
      }
      isActive
      createdAt
      updatedAt
      createdBy
    }
  }
`;

export const GET_SEARCH_RESULTS = gql`
  query getSearchResults($searchKey: String!, $lang: Lang!) {
    getSearchResults(searchKey: $searchKey, lang: $lang)
  }
`;

export const GET_CERTIFICATE_STATUS_OF_USER = gql`
  query getCertificateObtainedByUser {
    getCertificateObtainedByUser
  }
`;
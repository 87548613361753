import * as React from 'react';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router";
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer.js';
import HelpBanner from '../../../components/help/helpBanner/helpBanner';
import HelpContainer from '../../../components/help/helpContainer/helpContainer';
 
 class Help extends React.Component{
     constructor(props){
         super(props);
     }

     render(){
         return(
             <div> 
               <Navbar />
                <div className="app">
                    <Header />
                    <HelpBanner/>
                    <HelpContainer/>
                </div>
               <Footer /> 
             </div>
         )
     }

 }

export default  withRouter(withApollo(Help));
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../navbar/navbar';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import ContentNotFoundMessage from "../../../components/messages/contentNotFound/ContentNotFoundMessage";

//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';
import { withNamespaces } from 'react-i18next';
import { Button } from 'react-uikit';

//queries
import { GET_MODULE_QUIZ, GET_SUB_MODULES, GET_NEXT_MODULE } from '../../../queries/common_queries';
import { CREATE_MODULE_QUIZ_SUBMISSION, UPDATE_MODULE_QUIZ_SUBMISSION } from '../../../queries/user_queries';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class Quizz_Container extends React.Component {
    constructor(props) {
        super(props);

        console.log(this.props.moduleId);

        this.state = {
            isActive: true,
            loading: false,
            quizStatus: this.props.quizStatus,
            pagination: {
                moduleId: this.props.moduleId,
                isActive: true,
                skip: 0,
                first: 20,
                lang: localStorage.LANG,
            },
            moduleQuiz: [],
            fullModuleQuiz: {},
            title: "",
            description: "",
            totalMarksArr: [],
            totalMarks: 0,
            allquestions: 0,
            passRate: 0,
            score: 0.0,
            certificateStatus: false,
            moduleId: "",
            buttonVisible: false,
            passMark: 80,
            answerIndexes: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],

        };

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.get_quiz(this.state.pagination).then(result => {
            this.Submodule();
            if (result.count > 0) {
                const n = 10
                // result.moduleQuizzes[0].moduleQuiz.questions.length;
                const randomArray = result.moduleQuizzes[0].moduleQuiz.questions.map(x => ({ x, r: Math.random() }))
                    .sort((a, b) => a.r - b.r)
                    .map(a => a.x)
                    .slice(0, n);

                console.log(result)
                this.setState({
                    loading: false,
                    moduleId: result.moduleQuizzes[0].moduleId,
                    moduleQuiz: randomArray,
                    // moduleQuiz: result.moduleQuizzes[0].moduleQuiz.questions,
                    title: result.moduleQuizzes[0].moduleQuiz.title,
                    description: result.moduleQuizzes[0].moduleQuiz.description,
                    passRate: result.moduleQuizzes[0].moduleQuiz.passRate,
                    allquestions: result.moduleQuizzes[0].moduleQuiz.questions.length,
                });
            } else {
                this.setState({ loading: false });
            }
        })


    }


    onAnswerChanged = (event, questionKey, answerKey) => {

        var moduleQuiz = this.state.moduleQuiz;

        moduleQuiz[questionKey].answers.map((answer, j) => (
            j == answerKey ? (
                answer.markedAnswer == undefined ? (
                    [moduleQuiz[questionKey].answers[answerKey]['markedAnswer'] = true,
                    this.setState({ moduleQuiz: moduleQuiz })]
                ) : (
                    delete answer['markedAnswer']
                )
            ) : (
                delete answer['markedAnswer']
            )
        ))

        console.log(this.state.moduleQuiz);

    }

    submitQuiz = () => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.setState({ loading: true });
        this.submitAnswers().then(result => {
            this.setState({ buttonVisible: true });
            // this.setState({
            //     score:this.state.score
            // })
            console.log(result)
            console.log(this.state.score);
            console.log(this.state.passRate);

            if (this.state.score >= this.state.passRate) {
                this.setState({ certificateStatus: true, buttonVisible: true });
                console.log(this.state.quizStatus.status);
                this.state.quizStatus.status === false ? (

                    setTimeout(() => {
                        console.log("2")

                        this.submitModuleQuiz().then(result => {
                            console.log(result)
                            this.setState({ loading: false });
                            if (result.certificateStatus) {
                                this.props.history.push('/quizReview?id=' + result.id);
                            } else {
                                this.props.history.push('/quizReview?id=' + result.id);
                                // this.props.setSuccessStatus({
                                //     status: true,
                                //     title: "",
                                //     message:
                                //     <div>

                                //         Thank you for submitting your quiz responses. <br/>
                                //         However, you have an insufficient score for the module to be completed. <br/>
                                //         Please try again.
                                //     </div>
                                // });
                            }

                        }).catch(error => {
                            console.log(error);
                            this.setState({ loading: false });
                            this.props.setFormStatus({
                                status: true,
                                title: "Oops!",
                                message:
                                    <div>
                                        {this.props.t('error_submitting_quiz')}<br />
                                        {this.props.t('try_agin')}
                                    </div>

                            });
                        });
                    }, 1000)


                    // setTimeout(() => {
                    //     this.updateModuleQuiz().then(result => {
                    //         console.log(result)
                    //         this.setState({ loading: false });
                    //         if (result.certificateStatus) {
                    //             this.props.history.push('/quizReview?id=' + result.id);
                    //         } else {
                    //             this.props.setSuccessStatus({
                    //                 status: true,
                    //                 title: "",
                    //                 message:
                    //                     "Quiz submitted successfully. But your score is not enough to pass quiz. Try Again"
                    //             });
                    //         }

                    //     }).catch(error => {
                    //         console.log(error);
                    //         this.setState({ loading: false });
                    //         this.props.setFormStatus({
                    //             status: true,
                    //             title: "Oops!",
                    //             message:
                    //                 "There was an error while trying to submit quiz."

                    //         });
                    //     });
                    // }, 1000)



                ) : (


                    setTimeout(() => {
                        this.updateModuleQuiz().then(result => {
                            console.log(result)
                            this.setState({ loading: false });
                            if (result.certificateStatus) {
                                this.props.history.push('/quizReview?id=' + result.id);
                            } else {
                                this.props.setSuccessStatus({
                                    status: true,
                                    title: "",
                                    message:
                                        <div>
                                            {this.props.t('quiz_thanking_one')}<br />
                                            {this.props.t('quiz_thanking_two')} <br />
                                            {this.props.t('try_agin')}
                                        </div>
                                });
                            }

                        }).catch(error => {
                            console.log(error);
                            this.setState({ loading: false });
                            this.props.setFormStatus({
                                status: true,
                                title: "Oops!",
                                message:
                                    <div>
                                        {this.props.t('error_submitting_quiz')}<br />
                                        {this.props.t('try_agin')}
                                    </div>

                            });
                        });
                    }, 1000)

                    // setTimeout(() => {
                    //     console.log("2")

                    //     this.submitModuleQuiz().then(result => {
                    //         this.setState({ loading: false });
                    //         if (result.certificateStatus) {
                    //             this.props.history.push('/quizReview?id=' + result.id);
                    //         } else {
                    //             this.props.setSuccessStatus({
                    //                 status: true,
                    //                 title: "",
                    //                 message:
                    //                     "Quiz submitted successfully. But your score is " + this.state.score + ". It's not enough to pass quiz. Try Again."
                    //             });
                    //         }

                    //     }).catch(error => {
                    //         console.log(error);
                    //         this.setState({ loading: false });
                    //         this.props.setFormStatus({
                    //             status: true,
                    //             title: "Oops!",
                    //             message:
                    //                 "There was an error while trying to submit quiz."

                    //         });
                    //     });
                    // }, 1000)


                )

            } else {
                setTimeout(() => {
                    console.log("3")
                    this.submitModuleQuiz().then(result => {
                        this.setState({ loading: false });
                        if (result.certificateStatus) {
                            this.props.history.push('/quizReview?id=' + result.id);
                        } else {
                            this.props.history.push('/quizReview?id=' + result.id);
                            // this.props.setSuccessStatus({
                            //     status: true,
                            //     title: "",
                            //     message:
                            //     <div>

                            //         Thank you for submitting your quiz responses. <br/>
                            //         However, you have an insufficient score for the module to be completed. <br/>
                            //         Please try again.
                            //     </div>
                            // });
                        }

                    }).catch(error => {
                        console.log(error);
                        this.setState({ loading: false });
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                <div>
                                    {this.props.t('error_submitting_quiz')}<br />
                                    {this.props.t('try_agin')}
                                </div>

                        });
                    });
                }, 1000)
            }

        })
    }

    submitAnswers = async () => {
        var moduleQuiz = this.state.moduleQuiz;
        var totalMarks = 0;
        var question_count = 10;
        var allquestions = this.state.allquestions;
        console.log(moduleQuiz);

        moduleQuiz.map((question, i) => (
            question.answers.map((answer, j) => (
                answer.markedAnswer == undefined ? (
                    null
                ) : (
                    answer.isCorrect != undefined ? (
                        answer.isCorrect == true ? (
                           
                            [totalMarks++,
                                 allquestions < question_count ? 
                                (
                                    this.setState({
                                        totalMarks: totalMarks, score: Math.round(totalMarks * 100 / allquestions) }),
                                   question['success'] = true
                                ):(
                                    this.setState({
                                        totalMarks: totalMarks, score: Math.round(totalMarks * 100 / 10) }),
                                   question['success'] = true
                                )
                            // this.setState({
                            //      totalMarks: totalMarks, score: Math.round(totalMarks * 100 / 10) }),
                            //     //  allquestions
                            // question['success'] = true
                            ]
                        ) : (
                            null
                        )
                    ) : (
                        null
                    )
                )
            ))
        ))
        // console.log(totalMarks)
    }

    get_quiz = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULE_QUIZ,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModuleQuiz;
    };

    submitModuleQuiz = async () => {

        const { moduleId, score, certificateStatus } = this.state;

        var fullModuleQuiz = this.state.fullModuleQuiz;

        fullModuleQuiz['title'] = this.state.title;
        fullModuleQuiz['description'] = this.state.description;
        fullModuleQuiz['passRate'] = this.state.passRate;
        fullModuleQuiz['questions'] = this.state.moduleQuiz;

        const moduleAnswerQuiz = fullModuleQuiz;

        const result = await this.props.client.mutate({
            mutation: CREATE_MODULE_QUIZ_SUBMISSION,
            variables: { moduleId, moduleAnswerQuiz, score, certificateStatus }
        });

        return result.data.createModuleQuizSubmission;
    };

    updateModuleQuiz = async () => {

        const { moduleId, score } = this.state;

        var fullModuleQuiz = this.state.fullModuleQuiz;

        fullModuleQuiz['title'] = this.state.title;
        fullModuleQuiz['description'] = this.state.description;
        fullModuleQuiz['passRate'] = this.state.passRate;
        fullModuleQuiz['questions'] = this.state.moduleQuiz;

        const moduleAnswerQuiz = fullModuleQuiz;


        const result = await this.props.client.mutate({
            mutation: UPDATE_MODULE_QUIZ_SUBMISSION,
            variables: { moduleId, moduleAnswerQuiz, score }
        });


        return result.data.updateModuleQuizSubmission;
    };

    breadcrumbredirect = (path) => {
        if (path == "HOME") {
            this.props.history.push('/home');
        } else {
            return false;
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    Submodule() {
        this.get_sub_modules(this.state.pagination).then(result => {
            console.log(result.subModules)
            this.setState({
                module: result.module, sub_modules: result.subModules, lessons: result.lessons, count: result.subModules.count,
                contents: result.contents, loading: false
            });

            this.get_next_module().then(result => {
                console.log(result.length)
                if (result.length !== 0) {
                    this.setState({ nextModuleId: result[0].id });
                } else {
                    this.setState({ nextModuleId: 0 });
                }
                console.log(this.state.nextModuleId)
            })
        });
    }

    get_sub_modules = async (pagination) => {
        // console.log(pagination)
        const result = await this.props.client.query({
            query: GET_SUB_MODULES,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModules;
    };
    get_next_module = async () => {
        const result = await this.props.client.query({
            query: GET_NEXT_MODULE,
            variables: {
                moduleId: this.state.moduleId,
                first: 1,
                lang: localStorage.LANG,
            },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getNextModule;
    };
    passToNextModule = (nextModuleId) => {
        window.scrollTo(-500, -200);
        this.props.history.push('/module?id=' + nextModuleId);

        setTimeout(() => {
            var currentLocation = window.location.href;
            var url = currentLocation.split("?")
            url = url[0];
            url = url.split("/");
            var page = url[url.length - 1];

            let query = new URLSearchParams(this.props.location.search);
            let id = query.get('id');

            this.setState({
                id: id, from: page, moduleId: id, pagination: {
                    // skip: 0, first: 3,
                    isActive: true, moduleId: id
                }
            });
            this.Submodule();
        }, 1000)
    }


    render() {
        const { moduleQuiz, title, description, loading } = this.state;
        const { formErrorStatus, formSuccessState, t } = this.props;
        // const random = Math.floor(Math.random() * moduleQuiz.length);
        //     // console.log(random, moduleQuiz[random]);
        //     console.log( moduleQuiz[random])


        const n = 10;
        const randomArray = moduleQuiz.map(x => ({ x, r: Math.random() }))
            .sort((a, b) => a.r - b.r)
            .map(a => a.x)
            .slice(0, n);

        console.log(randomArray)
        return (
            <div class="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read" uk-scrollspy="target: > a; cls:uk-animation-slide-bottom-small; delay: 200">

                <a href="#" class="paper uk-link-reset">
                    <div>
                        <ul class="uk-breadcrumb">
                            <li><a onClick={() => this.breadcrumbredirect("HOME")}> {t('home')}</a></li>

                        </ul>
                    </div>
                    <div class="uk-flex-middle uk-grid" >

                        {

                            loading ? (
                                <div class="uk-width-5-6">
                                    {/* <h2 class="uk-margin-remove"> Loading... </h2> */}
                                </div>
                            ) : (
                                moduleQuiz.length > 0 ? (
                                    <div class="uk-width-1-1">
                                        <h3 class="uk-margin-remove"> {title}</h3>
                                        <p class="uk-margin-remove"> {description} </p>
                                    </div>
                                ) : (
                                    <ContentNotFoundMessage />
                                )
                            )

                        }


                    </div>
                </a>

                {

                    loading ? (
                        <div>
                            <span class="uk-margin-small-center" uk-spinner="ratio: 3"></span>
                        </div>



                    ) : (
                        moduleQuiz.length > 0 ? (
                            [moduleQuiz.map((question, i) => (
                                //console.log(question.answers)
                                <div class="uk-link-reset paper uk-margin-remove-bottom" key={i}>
                                    <h4 class="uk-margin-remove">{i + 1})  {question.question}</h4>
                                    {
                                        question.answers.map((answer, j) => (

                                            [
                                            // <label style={{ marginRight: '0.5rem', marginLeft: '0.3rem' }}>{this.state.answerIndexes[j]})</label>,
                                                 <label style={{ cursor: "pointer", display:"block" }}> <input className="uk-radio" type="radio" id="answer" name={"answer" + i} onChange={() => this.onAnswerChanged(this.event, i, j)} /> {answer.answer} </label>, ]
                                        ))
                                    }
                                </div>
                            )),
                            <br />,
                            (formErrorStatus.status) ? (
                                <div className="danger_alert">{formErrorStatus.message}</div>
                            ) : ((formSuccessState.status) ? (
                                <div className="success_alert">{formSuccessState.message} </div>
                            ) : ('')),

                            localStorage.LANG == "ar" ? (
                                <div class="uk-child-width-expand@s uk-child-width-1-2@s uk-grid uk-margin" uk-grid="true">
                                    <div>
                                        <Button
                                            className="uk-button uk-button-primary uk-align-left"
                                            style={{float: "right", marginRight: "1px"}}
                                            onClick={() => this.goBack()}>
                                            {t('back')}
                                        </Button>
                                    </div>
                                    <div className="uk-text-right">
                                        <Button
                                            className="uk-button uk-button-primary"
                                            style={{float:"left"}}
                                            type="button"
                                            onClick={() => this.submitQuiz()}
                                            disabled={loading ? (true) : (false)}>
                                            {loading ? (
                                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                            ) : (t('submit_answer'))}
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div class="uk-child-width-expand@s uk-child-width-1-2@s uk-grid uk-margin" uk-grid="true">
                                    <div>
                                        <Button
                                            className="uk-button uk-button-primary uk-align-left"
                                            onClick={() => this.goBack()}>
                                            {t('back')}
                                        </Button>
                                    </div>
                                    <div className="uk-text-right">
                                        <Button
                                            className="uk-button uk-button-primary"
                                            type="button"
                                            onClick={() => this.submitQuiz()}
                                            disabled={loading ? (true) : (false)}>
                                            {loading ? (
                                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                            ) : (t('submit_answer'))}
                                        </Button>
                                    </div>
                                </div>
                            ) ,
                            <div class="uk-child-width-expand@s uk-child-width-1-1@s uk-grid" uk-grid="true">
                                {localStorage.LANG=='ar'?(
                                     <div className="uk-text-left">
                                     {this.state.nextModuleId !== 0 ? (
                                         this.state.buttonVisible == true ? (
                                             <Button
                                                 className="uk-button uk-button-default"
                                                 style={{ color: "#1e87f0", borderColor: "#1e87f0" }}
                                                 type="button"
                                                 onClick={() => this.passToNextModule(this.state.nextModuleId)}>
                                                 {t('next_module')} <i className="fas fa-angle-left"></i>
                                             </Button>
                                         ) : (null)
                                     ) : ("")}
                                 </div>
                                ):(
                                <div className="uk-text-right">
                                    {this.state.nextModuleId !== 0 ? (
                                        this.state.buttonVisible == true ? (
                                            <Button
                                                className="uk-button uk-button-default"
                                                style={{ color: "#1e87f0", borderColor: "#1e87f0" }}
                                                type="button"
                                                onClick={() => this.passToNextModule(this.state.nextModuleId)}>
                                                {t('next_module')} <i className="fas fa-angle-right"></i>
                                            </Button>
                                        ) : (null)
                                    ) : ("")}
                                </div>
                                )}
                                
                            </div>

                            ]
                        ) : (
                            null
                        )

                    )

                }


                {/* <br/>
                        {this.state.nextModuleId!==0 ? (
                            this.state.buttonVisible == true ? (
                                <button className="uk-button uk-button-primary float next-prev-btn"  onClick={() => this.passToNextModule(this.state.nextModuleId)} > Next module <i className="fas fa-angle-right"></i></button>

                            ) : (null)
                            ):("")}  */}
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Quizz_Container))));
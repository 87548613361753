import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//ui-kit components

import { Button } from 'react-uikit';

//Spinner
import Loader from 'react-loader-spinner'

//queries
import { CREATE_USER } from '../../../queries/user_queries';

//utilities
import { validateEmail } from "../../../utilities/utils";

//react dropdown
import Dropdown from 'react-dropdown'

import { Form } from "semantic-ui-react";
import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class Admin_register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: {
                form_state: 'default',
                title: '',
                message: '',
            },
            required_fields: {
                firstName: '',
                lastName: '',
                email: '',
                // password: "",
                // gender: "MALE",
                confirm_password: "",
            },
            fields: {
                mobile: '',
                userType: 'ADMIN',
                gender:'MALE',
                isActive:true
            },
            user_types: [
                "ADMIN",
                // "EDITOR"         Removed beacause requested
            ],
            validated: false,
            inputError: '',
            loading: false,
            minStrength: 3,
            thresholdLength: 7,
            password: '',
            strength: 0,
        };

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    onGenderChanged = (event) => {
        let fields = this.state.fields;
        this.state.required_fields[event.target.id] = '';
        fields[event.target.id] = event.target.value;
        this.setState({ fields });
    }

    handleChange = (event) => {
        let fields = this.state.fields;
        this.state.required_fields[event.target.id] = '';
        fields[event.target.id] = event.target.value;
        this.setState({ fields });
    }

    handleUserTypeChange = (event) => {
        let fields = this.state.fields;
        this.state.required_fields["userType"] = '';
        fields["userType"] = event.target.value;
        this.setState({ fields });
        console.log(this.state.fields.userType)
    };

    handleSubmit(event) {
        event.preventDefault();

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });

        this.setState({ loading: true });

        const { fields, required_fields } = this.state;
        var stateCopy = Object.assign({}, this.state);
        var list = Object.keys(required_fields).map((required_field, i) => {
            stateCopy.required_fields[required_field] = fields[required_field] ? '' : 'Required';
            this.setState({ stateCopy });
            return fields[required_field] === undefined || fields[required_field] === '';
        });

        if (!list.filter((e, i, a) => e == true)[0]) {

            //validate email
            if (!validateEmail(this.state.fields.email)) {
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Please enter a valid email!"
                });
                this.setState({ loading: false });
                return;
            }

            //password match
            if (this.state.password !== this.state.fields.confirm_password) {
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Passwords do not match!"
                });
                this.setState({ loading: false });
                return;
            }

            if(this.state.password.length <= this.state.thresholdLength ){
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Password must have more than 7 characters!"
                });
                this.setState({ loading: false });
                return;
            }
// var password = 
            this.createUser(fields, this.state.password).then(result => {

                this.setState({
                    loading: false,
                    fields: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobile: '',
                        confirm_password: '',
                    },
                    password: '',
                });

                this.props.setSuccessStatus({
                    status: true,
                    title: "",
                    message:
                        "System user registered successfully! "
                });

            }).catch(error => {
                console.log(error);
                if (error) {
                    this.setState({ loading: false });
                    this.props.setFormStatus({
                        status: true,
                        title: "Oops!",
                        message:
                            "User already exists!"

                    });
                }
            });

        } else {
            this.setState({ loading: false });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    "Please provide inputs for all fields."
            });
        }
    }

    redirectToLogin = () => {
        this.props.history.push("/admin");
    }

    createUser = async (fields) => {
        const result = await this.props.client.mutate({
            mutation: CREATE_USER,
            variables: {
                userType:fields.userType,
                firstName: fields.firstName,
                lastName: fields.lastName,
                email: fields.email,
                password: this.state.password,
                confirm_password:fields.confirm_password,
                mobile: fields.mobile,
                gender:fields.gender
               
            }
        });

        return result.data.signup;
    };

    stateChanged = ( event )=> {
        // event.preventDefault();
        console.log(event.target.value)
        const {   validator = f => f,  } = this.props;
        var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
		this.setState({
			password: event.target.value,
            strength: zxcvbn(event.target.value).score,
        },) 
	};

    validatePasswordStrong = (password) => {
        console.log(password)
		if (password.length <= this.thresholdLength){
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Password is short!"
            });
            this.setState({ loading: false });
            return;
        }
        if (zxcvbn(password).score < this.minStrength) {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Password is weak!"
            });
            this.setState({ loading: false });
            return;
        }
        // throw new Error("Password is weak");
	};
    render() {

        const { loading, user_types , strength, password } = this.state;
        const { formErrorStatus, formSuccessState, onStateChanged, validator, children, ...restProps } = this.props;

        const passwordLength = password.length;
		const passwordStrong = strength >= this.minStrength;
		const passwordLong = passwordLength > this.thresholdLength;

        const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();

        const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
        
        return (

            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                <i className="fas fa-user icon-large uk-margin-right"></i>
                <h3 className="uk-margin-remove adminSectionTitles"> System User Registration </h3>
                </div>
                <br />
                <br />
                <div className="uk-card-small uk-card-default " >
                    {/* <div className="uk-card-header uk-text-bold">
                        <i className="fas fa-user uk-margin-small-right"></i> System User Registration
                    </div> */}
                    <div className="uk-card-body  ">
                        <div className="uk-grid">


                            <div className="uk-width">

                                <div className="uk-form-label">User Type </div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon"><i className="fas fa-admin icon-medium"></i></span>
                                    <select className="uk-select ll uk-form-width-large" onChange={this.handleUserTypeChange} value={this.state.fields.userType || ''}>
                                        {/* <option key="userType" value="" disabled>Select User Type</option> */}
                                        {user_types.map(item => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="uk-form-label">First Name</div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon"><i className="fas fa-user icon-medium"></i></span>
                                    <input value={this.state.fields.firstName || ''} className="uk-input ll uk-form-width-large" placeholder="First Name" id="firstName" id="firstName" type="text" onChange={this.handleChange} validateOnLoad={false} errorMessage={this.state.required_fields.firstName} />
                                </div>
                                <div className="uk-form-label">Last Name</div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon"><i className="fas fa-user icon-medium"></i></span>
                                    <input value={this.state.fields.lastName || ''} className="uk-input ll uk-form-width-large" placeholder="Last Name" id="lastName" id="lastName" type="text" onChange={this.handleChange} />
                                </div>
                                {/* <div className="uk-form-label">Gender</div>
                                <div className="uk-inline">
                                    <label> <input className="uk-radio" onChange={this.onGenderChanged} type="radio" id="gender" name="gender" value="MALE" /> Male </label>
                                    <label> <input className="uk-radio" onChange={this.onGenderChanged} type="radio" id="gender" name="gender" value="FEMALE" /> Female </label>
                                </div> */}
                                <div className="uk-form-label">Email</div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon"><i className="far fa-envelope icon-medium"></i></span>
                                    <input value={this.state.fields.email || ''} className="uk-input ll uk-form-width-large" placeholder="Email" id="email" id="email" type="email" onChange={this.handleChange} validateOnLoad={false} errorMessage={this.state.required_fields.firstName} />
                                </div>
                                <div className="uk-form-label">Mobile</div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon"><i className="fa fa-phone icon-medium"></i></span>
                                    <input value={this.state.fields.mobile || ''} className="uk-input ll uk-form-width-large" placeholder="Mobile" id="mobile" id="mobile" type="text" onChange={this.handleChange} />
                                </div>
                                <div className="uk-form-label" minStrength={3}>Password</div>
                                <Form.Field style={{width:"47%"}} {...restProps} validator={this.validatePasswordStrong}  >
                                <span style={{fontSize:"14px"}} className="d-block form-hint">To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.</span>
                                        {children}
                                        <div className={strengthClass}>
                                            <div className="strength-meter-fill" data-strength={strength}></div>
                                        </div>
                                    </Form.Field>
                                <div className="uk-inline ">
                                    <span className="uk-form-icon"><i className="fas fa-key icon-medium"></i></span>

                                    <input value={this.state.password || ''} className="uk-input ll uk-form-width-large" name="password" id="password" placeholder="Password" type="password" onChange={this.stateChanged} validateOnLoad={false} errorMessage={this.state.required_fields.firstName} />
                                    <div className="position-absolute password-count mx-3">
                                        <span className={counterClass}>{ passwordLength ? passwordLong ? `${this.thresholdLength}+` : passwordLength : '' }</span>
                                    </div>
                                </div>
                                <div className="uk-form-label">Confirm Password</div>
                                <div className="uk-inline uk-margin-bottom">
                                    <span className="uk-form-icon"><i className="fas fa-key icon-medium"></i></span>
                                    <input value={this.state.fields.confirm_password || ''} className="uk-input ll uk-form-width-large" name="confirm_password" id="confirm_password" placeholder="Confirm Password" type="password" onChange={this.handleChange} />
                                </div>
                                <br/>

                                <Button
                                    className="uk-button uk-button-primary uk-form-width-large uk-margin"
                                    style={{ height: 40 }}
                                    type="submit"
                                    disabled={loading ? (true) : (false)}
                                    onClick={this.handleSubmit}
                                >
                                    {loading ? (
                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                    ) : ("Register")}
                                </Button>
                                <br />

                                {(formErrorStatus.status) ? (
                                    <div className="danger_alert uk-form-width-large">{formErrorStatus.message}</div>
                                ) : ((formSuccessState.status) ? (
                                    <div className="success_alert uk-form-width-large">{formSuccessState.message}</div>
                                ) : (''))}

                                

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

Admin_register.propTypes = {
    children: PropTypes.node,
    onStateChanged: PropTypes.func,
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Admin_register))); 
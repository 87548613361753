import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import Header from '../../header/header';
import Navbar from '../../navbar/navbar';



class Banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { galleryTitle, bannerimage } = this.props;
        return (
            <div className="banner_wraper" >
                <div style={{ backgroundImage: "linear-gradient(to right, rgb(24, 41, 120) 50% ,rgb(24, 41, 120,0.9) )", minHeight: "140px", maxHeight: "140px", objectFit: "cover" }} className="uk-position-bottomsingle-news-header" uk-img="" className="single-news-header uk-height-large uk-background-norepeat  uk-background-center-center    uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover">
                    <div className="header_wrapper">
                        <div className="sigle-page-header">
                            <Header />
                        </div>
                        <div className="uk-width-1-1 uk-container uk-margin-large-top uk-padding-remove-bottom">
                            {localStorage.LANG=='ar'? (
                            <h1 style={{paddingTop:"12px"}} className="uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top"> {galleryTitle} </h1>
                            ):(
                            <h1 style={{paddingTop:"12px"}} className="uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top"> {galleryTitle} </h1>
                            )}
                        </div>
                    </div>
                    <div className="overlay"> </div>
                </div>
            </div>

            //     <div className="course-dhashboard" style={{backgroundImage:"linear-gradient(to right, rgb(24, 41, 120) 50% ,rgb(24, 41, 120,0.9) )",minHeight:"140px", maxHeight:"140px"}}>
            //     <div className="uk-grid">                
            //         <div>
            //             <h2 className="uk-light uk-text-bold uk-text-white" style={{paddingTop:"60px"}}> {galleryTitle}</h2>
            //             {/* <p className="uk-light uk-text-bold">{sub_module.subModuleHelperText}</p>                      */}
            //         </div>                     
            //     </div>                 
            // </div>
        )
    }
}

export default withRouter(withApollo(Banner));
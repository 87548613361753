import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Question from "../../images/question.png";
import Resource from "../../images/doc.png";
import Status from "../../images/status.png";
import Comments from "../../images/comments.png";
import Ribbon from "../../images/ribbon.png";
import RibbonDisabled from "../../images/ribbonDisabled.png";
import Circle from "react-circle";
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

const mapStateToProps = (state, ownProps) => {
  return {};
};

class ExamCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  redirectToExam = async (id) => {
    this.props.history.push("/sbl?id=" + id);
  };

  render() {
    console.log(this.props.exam)
    const { exam, category, t } = this.props;
    return (
      <div className= "examCard" >
        <div className= {localStorage.LANG=='ar'? "examCardCol1 float": "examCardCol1" }  >
          <text className= "examCardTitle" >{exam.exam.examName}</text>
          <div>
          <text className="examCardPublishedDate">{t('published_on')} <b>
            
            {
            // localStorage.LANG=="ar"?( moment(exam.exam.createdAt).format('YYYY-MM-DD')):(
               moment(exam.exam.createdAt).format('DD-MM-YYYY')
              //  )
               }</b></text>
          </div>
          <table className="examCardTable">
            <tbody>
              <tr>
                <td>
                  <img className={localStorage.LANG=='ar'? "examCardIcon_ar": "examCardIcon"}  src={Question} />
                  <text className="examCardDescription">
                    {t('questions')}: <b>{exam.exam.questions.questions.length}</b>
                  </text>
                </td>
                <td>
                  <img className={localStorage.LANG=='ar'? "examCardIcon_ar": "examCardIcon"} src={Resource} />
                  <text className="examCardDescription">
                    {t('resources_tab')}:{" "}
                    <b>
                      {exam.exam.resourceUrls
                        ? exam.exam.resourceUrls.length > 0
                          ? exam.exam.resourceUrls.resourceUrls.length
                          :  t('none')
                        : t('none')}
                    </b>
                  </text>
                </td>
                {/* <td>
                  <img className="examCardIcon" src={Status} />
                  <text className="examCardDescription">
                    {t('published_on')} <b>{moment(exam.exam.createdAt).format('DD-MM-YYYY')}</b>
                  </text>
                </td> */}
                <td>
                  <img className={localStorage.LANG=='ar'? "examCardIcon_ar": "examCardIcon"} src={Status} />
                  <text className="examCardDescription">
                    {t('status')}: <b>{exam.status === 'Completed' ? t('ongoing'): t('pending') }</b>
                  </text>
                </td>
                <td>
                  {category === "completed" ? (
                    <React.Fragment>
                      <img className={localStorage.LANG=='ar'? "examCardIcon_ar" : "examCardIcon" }  src={Comments} />
                      <text className="examCardDescription">
                      {/* {t('comments')}: <b>{ exam.comments}</b> */}
                        {t('comments')}: <b>{exam.comments=='None'? localStorage.LANG=='en'? "None": localStorage.LANG=='sp'? "Ninguno": "None"
                        : exam.comments}</b>
                      </text>
                    </React.Fragment>
                  ) : " "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={localStorage.LANG=='ar'? "examCardCol2_ar" : "examCardCol2"}>
          <span>
            {exam.status === "Completed" ? (
              <React.Fragment>
                 <div className="examCardImageDiv">
                   {
                     exam.grade != null ?
                     <Circle
                        animate={true}
                        animationDuration="3s"
                        size={52}
                        lineWidth={50}
                        progress={exam.grade}
                        progressColor={
                          exam.grade >= 70
                            ? "#28d15d"
                            : exam.grade >= 45
                            ? "#33c5ff"
                            : "#fc5858"
                        }
                        bgColor="#E0E0E0"
                        // textColor="white"
                        textStyle={{
                          fontSize: "6.5rem",
                          fontWeight: "bold",
                        }}
                      />
                      :null
                   }
                      <img src={Ribbon} className="examCardImage" />
                    </div>
                <div className="examCardImageDiv">
                  <button
                    onClick={() => this.redirectToExam(exam.exam.id)}
                    className="uk-button uk-button-primary admin-table-btn blueButton3"
                    uk-tooltip= {t('click_to_view_comments') }
                    delay= "500"
                    pos="top"
                    animation="uk-animation-scale-up"
                  >
                   {t('view_comments')}
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="examCardImageDiv">
                  <img src={RibbonDisabled} className="examCardImage" />
                </div>
                <div className="examCardImageDiv">
                  <button
                    onClick={() => this.redirectToExam(exam.exam.id)}
                    className="uk-button uk-button-primary admin-table-btn blueButton3"
                    // uk-tooltip="title: Click to attempt; delay: 500; pos: top; animation: uk-animation-scale-up"
                  >
                   {t('try')}
                  </button>
                </div>
              </React.Fragment>
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(withNamespaces()(ExamCard))));

import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//queries
import { CREATE_MODULE, CREATE_MODULE_QUIZ } from '../../../queries/admin_queries';
import { GET_MODULE_QUIZ } from '../../../queries/common_queries';

//ui-components
import { Button } from 'react-uikit';
import Modal from 'react-responsive-modal';
import Checkbox from 'react-simple-checkbox';

//redux-form
import { Field, FieldArray, reduxForm } from 'redux-form';

//queries
import { GET_MODULES_FOR_ADMIN } from '../../../queries/admin_queries';

import { Values } from 'redux-form-website-template';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}



class CreateQuiz extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }
        this.state = {
            title: "",
            description: "",
            modules: [],
            moduleId: '',
            answers: [{ answer: "", isCorrect: false }],
            question_arr: [],
            answer_iscorrect: false,
            visible: false,
            open: false,
            data: null,
            isActive: true,
            loading: false,
            validation_err: false,
            pagination: {
                isActive: true,
                lang:localStorage.LANG
            },
            passRate: 0,

            quizAnswerIndexes: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],

        };
        console.log(this.props)

        this.handleChange = this.handleChange.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {
        this.get_modules(this.state.pagination).then(result => {
            var module_opt = [];
            result.modules.map(module => (
                module_opt.push({ key: module.id, value: module.id, id: 'moduleId', label: module.moduleTitle })
            ));
            this.setState({ modules: module_opt });
        })
    }

    handleModuleChange = (event) => {
        this.setState({ "moduleId": event.target.value });
    };

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }


    renderField = ({ input, label, index, type, meta: { touched, error } }) => (
        <div>
            <label>{index} ) <input className="uk-input uk-width-xxlarge" {...input} type={type} placeholder={label} /></label>
        </div>
    );

    renderCorrectField = ({ input, label, type, meta: { touched, error } }) => (
        <div>
            <div>
                <label style={{color: '#42adff', marginLeft: '1.5rem'}}> <input {...input} type={type} className="uk-checkbox" id="correct" /> Correct </label>
            </div>
        </div>
    );

    renderAnswers = ({ fields, meta: { error } }) => (
        <div>
            {fields.map((answer, index) => (
                <div key={index}>
                    <div className="" style={{color: 'rgb(83, 136, 83)'}}>
                        <a className="uk-button" onClick={() => fields.remove(index)}><i className="fas fa-minus-circle"></i> Answer</a>
                    </div>
                    <Field
                        name={answer + "answer"}
                        type="text"
                        props={{
                            index: this.state.quizAnswerIndexes[index]
                        }}
                        component={this.renderField}
                        label={`Answer`}
                    />
                    <Field
                        name={answer + "isCorrect"}
                        type="checkbox"
                        component={this.renderCorrectField}
                        label={`Correct`}
                    />
                </div>
            ))}

            <div>
                <Button
                    className="uk-button uk-button-default uk-margin adminSectionOtherButtons2"
                    type="button"
                    onClick={() => fields.push()}
                ><i className="fas fa-plus-circle"></i> Answer
                                </Button>
            </div>
        </div>
    );

    renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
        <div>
            {console.log(fields)}

            {fields.map((question, index) => (
                <div key={index}>
                    <div className="" style={{color: '#9b2333'}}>
                        <a className="uk-button " onClick={() => fields.remove(index)}><i className="fas fa-minus-circle"></i> Question</a>
                    </div>
                    <Field
                        name={`${question}.question`}
                        type="text"
                        props={{
                            index: index + 1
                        }}
                        component={this.renderField}
                        label="Question"
                    />
                    <hr />
                    <FieldArray name={`${question}.answers`} component={this.renderAnswers} />
                </div>
            ))}

            <Button
                className="uk-button uk-button-default uk-margin adminSectionOtherButtons1"
                type="button"
                onClick={() => fields.push({})}
            ><i className="fas fa-plus-circle"></i> Question
                                </Button>

        </div>
    );

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    handleFormSubmit = (values) => {
        console.log(values)
        var err = false;
        const { moduleId, passRate } = this.state;

        this.setState({ loading: true });


        if (moduleId == "") {
            err = true;
            this.setState({ loading: false, validation_err: true });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please select a module." });
        } else if (passRate < 0) {
            err = true;
            this.setState({ loading: false, validation_err: true });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Minimum valid input is zero!" });
        } else {

            var parameter = {
                moduleId: moduleId,
                isActive: true,
                lang: "en",
            };

            this.get_quiz(parameter).then(result => {

                if (result.count > 0) {
                    err = true;
                    this.setState({ loading: false, validation_err: true });
                    this.props.setFormStatus({ status: true, title: "Oops!", message: "This module already has a quiz." });
                } else {
                    this.handleValidateSubmit(values).then(result => {
                        if (result != true) {
                            var quiz = values;
                            console.log(quiz)
                            this.props.setFormStatus({ status: false, title: '', message: '' });
                            this.props.setSuccessStatus({ status: false, title: '', message: '' });

                            this.createModuleQuiz(quiz).then(result => {
                                console.log(result)
                                console.log("Success")
                                this.props.setSuccessStatus({
                                    status: true,
                                    title: "",
                                    message:
                                        "Quiz created successfully for the module."
                                });

                                this.setState({
                                    loading: false,
                                    moduleId: '',
                                    answers: [{ answer: "", isCorrect: false }],
                                    question_arr: [],
                                    answer_iscorrect: false,
                                });

                            }).catch(error => {
                                console.log(error);
                                this.setState({ loading: false });
                                this.props.setFormStatus({ status: true, title: "Oops!", message: <div>
                                     An error occurred while trying to create the quiz for this module! <br/>
                                     Please try again.
                                </div> });
                            });
                        }
                    })
                }
            })

        }

    }

    handleValidateSubmit = async (values) => {

        this.setState({ validation_err: false });
        //var quiz = JSON.stringify(values);
        var quiz = values;
        var err = false;
        //var quiz = '{"questions": [{"question": "Hungry?","answers": [{"answer": "Yes","isCorrect": true}, {"answer": "No"}]}]}';
        console.log(quiz)

        const { moduleId } = this.state;

        if (this.isEmpty(quiz)) {
            err = true;
            this.setState({ loading: false, validation_err: true });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please enter at least one question." });
        } else {
            this.setState({ validation_err: true });
            quiz.questions.map(question => {
                if (question == undefined || this.isEmpty(question)) {
                    err = true;
                    this.setState({ loading: false, validation_err: true });
                    this.props.setFormStatus({ status: true, title: "Oops!", message: "Please provide the question content." });
                } else {
                    if (question.answers == undefined) {
                        err = true;
                        this.setState({ loading: false, validation_err: true });
                        this.props.setFormStatus({ status: true, title: "Oops!", message: "Please enter available answers for this question." });
                    } else {

                        console.log(quiz.questions)

                        var isCorrectCount = 0;
                        var answer_count = question.answers.length;
                        console.log(question.answers.length)
                        question.answers.map(answer => {
                            if (answer == undefined || answer == '') {
                                err = true;
                                this.setState({ loading: false, validation_err: true });
                                this.props.setFormStatus({ status: true, title: "Oops!", message: "Please enter available answers for this question." });
                            } else {

                                if (answer.isCorrect == undefined || answer.isCorrect == false) {

                                    isCorrectCount= isCorrectCount + 1;
                                        console.log(isCorrectCount)
                                        // console.log(answer.length)
                                    if (isCorrectCount == answer_count) {
                                        err = true;
                                        this.setState({ loading: false, validation_err: true });
                                        this.props.setFormStatus({ status: true, title: "Oops!", message: "Please tick at least one correct answer for all questions before saving." });
                                    } 

                                    // else {
                                    //     err = false;
                                    //     this.setState({ loading: false, validation_err: false });
                                    //     this.props.setFormStatus({ status: false, title: '', message: '' });

                                    // }

                                    return false;

                                }

                                //  if (answer.isCorrect == undefined || answer.isCorrect=='') {
                                //     err = true;
                                //     this.setState({ loading: false, validation_err: true });
                                //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Please tick atleast one correct answer for questions before saving." });
                                // } 

                                // Please mark atleast one correct answer for all questions.
                                
                                // else if (answer.isCorrect != undefined && answer.isCorrect == false) {
                                //     err = true;
                                //     this.setState({ loading: false, validation_err: true });
                                //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Please mark correct answer for all questions." });
                                // }
                            }
                        })
                    }

                }
            })
        }

        return err;
    }

    get_quiz = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULE_QUIZ,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModuleQuiz;
    };

    get_modules = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULES_FOR_ADMIN,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModulesForAdmin;
    };

    createModuleQuiz = async (quiz) => {
        quiz['title'] = this.state.title;
        quiz['description'] = this.state.description;
        quiz['passRate'] = this.state.passRate;

        const { moduleId, isActive } = this.state;
        const moduleQuiz = quiz;

        const result = await this.props.client.mutate({
            mutation: CREATE_MODULE_QUIZ,
            variables: { moduleId, moduleQuiz, isActive }
        });

        return result.data.createModuleQuiz;
    };

    onOpenModal = (e) => {
        e.preventDefault();
        this.setState({ open: true });
    };

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({ open: false });
    };

    editQuiz() {
        this.props.history.push("/admin_quiz_edit");
      }


    render() {
        const { loading, open, modules, question_arr } = this.state;
        const { handleSubmit, formErrorStatus, formSuccessState } = this.props;

        console.log(formSuccessState)

        return (

            <div className="admin-content-inner">


                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-question-circle icon-large uk-margin-right"></i>
                    <h4 className="uk-margin-remove adminSectionTitles"> Create Quiz </h4>
                </div>
                <div className="uk-flex-inline uk-float-right">
                <button
                    className="uk-button uk-button-primary admin-btn"
                    onClick={() => this.editQuiz()}
                >
                    Edit Quiz
                </button>
                </div>



                <div className="uk-margin-medium">
                    <form className="ui form" onSubmit={handleSubmit(val => this.handleFormSubmit(val))} >
                        <div className="uk-grid" >
                            <div className="uk-width-1@m">
                                <div className="uk-card-small uk-card-default">
                                    <div className="uk-card-header uk-text-bold">
                                        <i className="fas fa-question-circle  uk-margin-small-right"></i> Quiz Details
                                    </div>

                                    <div className="uk-card-body uk-padding-remove-top">
                                        <div className="uk-margin">
                                            <div className="uk-form-label">Module</div>
                                            <div className="uk-inline">
                                                <span className="uk-form-icon"><i className="fas fa-admin icon-medium"></i></span>
                                                <select className="uk-select uk-width-xxlarge" onChange={this.handleModuleChange} value={this.state.moduleId || ''}>
                                                    <option key="userType" value="" disabled>Select Module</option>
                                                    {modules.map(item => (
                                                        <option key={item.id} value={item.value}>
                                                            {item.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="uk-margin">
                                            <label className="uk-form-label">Title</label><br />
                                            <input required value={this.state.title || ''} className="uk-input uk-width-xxlarge" id="title" name="title" placeholder="Title" onChange={this.handleChange} />
                                        </div>

                                        <div className="uk-margin">
                                            <label className="uk-form-label">Description</label><br />
                                            <textarea value={this.state.description || ''} className="uk-textarea uk-width-xxlarge" rows="2" id="description" name="description" onChange={this.handleChange}></textarea>
                                        </div>

                                        <div className="uk-margin">
                                            <label className="uk-form-label">Pass Rate</label><br />
                                            <input required value={this.state.passRate || ''} type="number" className="uk-input uk-width-xxlarge" id="passRate" name="passRate" placeholder="Pass Rate" onChange={this.handleChange} />
                                        </div>

                                        <FieldArray name="questions" component={this.renderQuestions} />
                                        <div>
                                            
                                            
                                        </div>

                                        {/* <Values form="fieldArrays" /> */}
                                    </div>
                                    
                                </div>
                                <Button
                                                    className="uk-button uk-button-primary uk-margin "
                                                    type="submit"
                                                    disabled={loading ? (true) : (false)}
                                                >

                                                    {loading ? (
                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                    ) : ("Save")}
                                                </Button>
                                            {(formErrorStatus.status) ? (
                                                <div className="danger_alert">{formErrorStatus.message}</div>
                                            ) : ((formSuccessState.status) ? (
                                                <div className="success_alert">{formSuccessState.message} </div>
                                            ) : (''))}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

}

CreateQuiz = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateQuiz)

export default withApollo(reduxForm({ form: 'fieldArrays', })(CreateQuiz));
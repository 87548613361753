import * as React from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

import InfoIcon from "../../../images/info.png";

class NoDataFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { langDict } = this.state;
    const { message, fontSize } = this.props;

    return (
      <div className="contentUnavailableMessage" style={styles.messageMainDiv}>
            <i class="fa fa-info-circle"></i> <text style={{fontSize: fontSize}}>{message}</text>
      </div>
    );
  }
}

const styles = {
  messageMainDiv: {
    margin: "0 auto",
    marginTop: '50%'
  },

};

export default withRouter(withApollo(NoDataFound));

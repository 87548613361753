import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

//custom-components
import Card_module, {
  Card_loading_module,
} from "../../module/card_module/card_module";

//queries
import { GET_FLASH_QUIZ_PROGRESS, GET_MODULES } from "../../../queries/common_queries";
import { GET_USER_RESULT } from "../../../queries/user_queries";

class LearningContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      loading_modules: [
        <Card_loading_module />,
        <Card_loading_module />,
        <Card_loading_module />,
        <Card_loading_module />,
        <Card_loading_module />,
        <Card_loading_module />,
      ],
      pagination: {
        skip: 0,
        first: 20,
        lang: localStorage.LANG,
      },
      loading: false,
      next_module: "",
      nextPage: 1,
      previousPage: 0,
      currentPage: 0,
      activePage: "",
      ToDos: 20,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      isFirstBtnActive: "disabled",
      isLastBtnActive: "",
      moduleStatuses: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, index) {
    this.setState({
      //   currentPage: Number(event.target.id),
      currentPage: Number(index),
      activePage: index,
    });
  }

  componentDidMount() {
    this.setState({ loading: true });
    var statuses
    // get modules
    this.get_modules(this.state.pagination).then((result) => {
      console.log(result)
      var module_opt = result.modules.map((module) => {
        return {
          id: module.id,
          moduleTitle: module.moduleTitle,
          moduleHelperText: module.moduleHelperText,
          bannerColor: module.bannerColor,
          bannerIconUrl: module.bannerIconUrl,
          moduleOverview: module.moduleOverview,
          content: module.content,
          moduleNumber: module.moduleNumber,
          isActive: module.isActive,
          progress: '',
        };
      });
      this.setState({
        count: result.count,
        loading: false,
      });
      console.log(this.state.modules);

      //   this.getUserResult().then((result1) => {
      //     console.log(result1);
      //     const array = result1.modules;
      //     console.log(array)
      //     for (let i = 0; i < module_opt.length; i += 1) {
      //         var index = Object.values(array).findIndex(obj => obj.module.id === module_opt[i].id);
      //         console.log(index)
      //         module_opt[i].progress = index < 0 ? 2 : array[index].progress;
      //     }


      //     this.setState({
      //     //   moduleStatuses: result,
      //       modules: module_opt,

      //     });
      //   });


      const tempArray = module_opt;
      const moduleArray = module_opt;
      console.log(tempArray)
      if (tempArray.length > 0) {
        tempArray.map((mod, i) =>
          this.get_flash_quiz_progress(mod.id).then((result) => {
            moduleArray[i].progress = result.progress;

            this.setState({ modules: moduleArray, loading: false });
          })
        );
      }

    });
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  get_modules = async (pagination) => {
    console.log(pagination);
    const result = await this.props.client.query({
      query: GET_MODULES,
      variables: pagination,
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getModules;
  };

  getUserResult = async () => {
    const result = await this.props.client.query({
      query: GET_USER_RESULT,
      variables: {
        lang: localStorage.LANG,
      },
      fetchPolicy: "network-only",
    });
    return result.data.getUserResult;
  };

  get_flash_quiz_progress = async (moduleId) => {
    const result = await this.props.client.query({
      query: GET_FLASH_QUIZ_PROGRESS,
      variables: {
        moduleId: moduleId,
        createdBy: localStorage.USER_ID,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getFlashQuizProgress;
  };

  redirectToModule = (id) => {
    this.props.history.push("/module?id=" + id);
  };

  handlePagination = async (pageNo) => {
    console.log(pageNo);
    this.setState({
      currentPage: pageNo,
    });

    if (pageNo >= 0) {
      this.setState({ isNextBtnActive: "", isLastBtnActive: "" });
      this.state.nextPage = pageNo + 1;
    }

    if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
      this.setState({
        isNextBtnActive: "disabled",
        isLastBtnActive: "disabled",
      });
    }

    if (pageNo > 0) {
      this.setState({ isPrevBtnActive: "", isFirstBtnActive: "" });
      this.state.previousPage = pageNo - 1;
    }

    if (pageNo === 0) {
      this.setState({
        isPrevBtnActive: "disabled",
        isFirstBtnActive: "disabled",
      });
    }

    var paginations = {
      skip: pageNo * this.state.ToDos,
      first: 20,
      lang: localStorage.LANG,
    };

    if (pageNo > 0) {
      this.get_modules(paginations).then((result) => {
        this.setState({ modules: result.modules, count: result.count });
      });
    } else {
      this.get_modules(this.state.pagination).then((result) => {
        this.setState({ modules: result.modules, count: result.count });
      });
    }
  };

  isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  render() {
    const {
      modules,
      loading,
      loading_modules,
      count,
      nextPage,
      previousPage,
      currentPage,
      ToDos,
      isPrevBtnActive,
      isNextBtnActive,
      isFirstBtnActive,
      isLastBtnActive,
    } = this.state;
    const totalmodules = modules.length;
    console.log(modules);

    const pages = Math.ceil(count / ToDos);
    if (pages === 0) return null;

    var items = [];
    for (var index = 0; index < pages; index++) {
      items[index] = index;
    }

    let renderPrevBtn = null;
    if (isPrevBtnActive === "disabled") {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <span id="btnPrev" className="button disabled">
            <i className="fas fa-angle-left"></i>{" "}
          </span>
        </li>
      );
    } else {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <a onClick={() => this.handlePagination(previousPage)} id="btnPrev">
            <i className="fas fa-angle-left"></i>
          </a>
        </li>
      );
    }

    let renderNextBtn = null;
    if (isNextBtnActive === "disabled") {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <span id="btnNext" className="button disabled">
            {" "}
            <i className="fas fa-angle-right"></i>{" "}
          </span>
        </li>
      );
    } else {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <a onClick={() => this.handlePagination(nextPage)} id="btnNext">
            {" "}
            <i className="fas fa-angle-right"></i>{" "}
          </a>
        </li>
      );
    }

    let renderfirstBtn = null;
    if (isFirstBtnActive === "disabled") {
      renderfirstBtn = (
        <li className={isFirstBtnActive}>
          <span id="btnfirst" className="button disabled">
            <i className="fas fa-angle-double-left"></i>{" "}
          </span>
        </li>
      );
    } else {
      renderfirstBtn = (
        <li className={isFirstBtnActive}>
          <a onClick={() => this.handlePagination(0)} id="btnfirst">
            <i className="fas fa-angle-double-left"></i>
          </a>
        </li>
      );
    }

    let renderlastBtn = null;
    if (isLastBtnActive === "disabled") {
      renderlastBtn = (
        <li className={isLastBtnActive}>
          <span id="btnlast" className="button disabled">
            <i className="fas fa-angle-double-right"> </i>{" "}
          </span>{" "}
        </li>
      );
    } else {
      renderlastBtn = (
        <li className={isLastBtnActive}>
          <a
            onClick={() => this.handlePagination(items.length - 1)}
            id="btnlast"
          >
            {" "}
            <i className="fas fa-angle-double-right"></i>{" "}
          </a>
        </li>
      );
    }

    return (
      <ul className="uk-switcher switcher-container">
        <li>
          <div className="uk-container">
            {/* <div className="uk-clearfix boundary-align">
                    <div className="uk-float-left section-heading none-border">
                        <p> Modules are to be completed in numarical order. At the end of each module a quiz will be available</p>
                    </div>
                </div> */}
            <div
              className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin uk-grid"
              uk-grid
              uk-height-match="target: .uk-card-body"
              uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
            >
              {loading
                ? loading_modules.map((module, i) => (
                  <Card_loading_module key={i} />
                ))
                : modules.map((module, i) =>
                  // this.renderCardModule(module, i, module.id)
                  // this.isEmpty(this.state.modules) != true ? this.isEmpty(this.state.moduleStatuses) != true ? this.renderCardModule(module, i, module.id) : null : null
                  <Card_module key={i} moduleNo={i + 1} module={module} get_module={() => this.redirectToModule(module.id)} />
                )}
            </div>

            {/* <div className="paginations uk-flex uk-flex-center">
                    <ul className="uk-pagination" id="page-numbers" >
                        {renderfirstBtn}
                        {renderPrevBtn}
                             {
                                items.map((item,index) =>
                                  <li key={item}  id={item}  active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)}   className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                                  )
                              }
                        {renderNextBtn}
                        {renderlastBtn}
                    </ul>
                </div>    */}
          </div>
        </li>
      </ul>
    );
  }
}

export default withRouter(withApollo(LearningContainer));

import * as React from 'react';
import { withApollo } from 'react-apollo';
import {
    withRouter, BrowserRouter as Router, Switch,
    Route,
    Link, NavLink
} from 'react-router-dom';
import { Button } from 'semantic-ui-react'
//custom-components
import Card_news, { Card_loading_news } from '../card_news/card_news';
import Loader from 'react-loader-spinner'

//query
import { GET_ALL_NEWS, UPDATE_NEWS_STATS } from '../../../queries/common_queries';
import { withNamespaces } from "react-i18next";

class NewsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedButton: null,
            newses: [],
            our_news: [],
            global_news: [],
            loading_newses: [
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />
            ],
            pagination: {
                skip: 0,
                first: 16,
                lang: localStorage.LANG,
            },
            loading_all: false,
            loading_our: false,
            loading_global: false,
            pagination_one: {
                skip: 0,
                first: 16,
                newsType: 'IPPF_INTERNAL',
                lang: localStorage.LANG
            },
            pagination_two: {
                skip: 0,
                first: 16,
                newsType: 'GLOBAL',
                lang: localStorage.LANG
            },
            loading: false,
            nextPage: 1,
            previousPage: 0,
            currentPage: 0,
            activePage: '',
            ToDos: 16,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            isFirstBtnActive: 'disabled',
            isLastBtnActive: '',
            newsType: "ALL"
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, index) {
        this.setState({
            currentPage: Number(index),
            activePage: index
        })
    }


    changeCategory = (category) => {
        this.setState({
            newsType: category,
        });
    };

    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.get_news(this.state.pagination).then(result => {
                console.log(result)
                var news_opt = result.newses.map(newses => {
                    return {
                        id: newses.id,
                        newsTitle: newses.newsTitle,
                        newsBannerImage: newses.newsBannerImage,
                        newsHelperText: newses.newsHelperText,
                        newsContent: newses.newsContent,
                        createdAt: newses.createdAt,
                        url: newses.url,
                        newsType: newses.newsType
                    }
                });
                this.setState({ newses: news_opt, count: result.count, loading: false });
            });

            this.get_our_news(this.state.pagination_one).then(result => {
                console.log(result)
                var news_opt_our = result.newses.map(newses => {
                    return {
                        id: newses.id,
                        newsTitle: newses.newsTitle,
                        newsBannerImage: newses.newsBannerImage,
                        newsHelperText: newses.newsHelperText,
                        newsContent: newses.newsContent,
                        createdAt: newses.createdAt,
                        url: newses.url,
                        newsType: newses.newsType
                    }
                });
                this.setState({ our_news: news_opt_our, count_one: result.count, loading: false });
            });

            this.get_global_news(this.state.pagination_two).then(result => {
                console.log(result)
                var news_opt_global = result.newses.map(newses => {
                    return {
                        id: newses.id,
                        newsTitle: newses.newsTitle,
                        newsBannerImage: newses.newsBannerImage,
                        newsHelperText: newses.newsHelperText,
                        newsContent: newses.newsContent,
                        createdAt: newses.createdAt,
                        url: newses.url,
                        newsType: newses.newsType
                    }
                });
                this.setState({ global_news: news_opt_global, count_two: result.count, loading: false });
            });
        }, 2000);
    }

    componentDidUpdate() { window.scrollTo(0, 0) }

    get_news = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_ALL_NEWS,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        return result.data.getNewses;
    };

    get_our_news = async (pagination_one) => {
        const result = await this.props.client.query({
            query: GET_ALL_NEWS,
            variables: pagination_one,
            fetchPolicy: 'network-only'
        });
        return result.data.getNewses;
    };

    get_global_news = async (pagination_two) => {
        const result = await this.props.client.query({
            query: GET_ALL_NEWS,
            variables: pagination_two,
            fetchPolicy: 'network-only'
            // fetchPolicy: 'cache-first'
        });
        return result.data.getNewses;
    };

    updateNewsStats = async (newsId) => {
        const result = await this.props.client.mutate({
            mutation: UPDATE_NEWS_STATS,
            variables: { newsId: newsId },
        });
        console.log(result.data);
        return result.data.updateNewsStats;
    };

    redirectToNews = (id) => {
        this.updateNewsStats(id).then(result => {
            // this.props.history.push('/a_news?id=' + id);
            window.open('/a_news?id=' + id)

        });
    }

    open_news_url = (id, url) => {
        this.updateNewsStats(id).then(result => {
            window.open(url);
        });
    }

    handlePagination = async (pageNo) => {
        this.setState({
            currentPage: pageNo
        })
        this.setState({
            loading_all: true
        })
        var newsId = this.state.newses.id;

        if (pageNo >= 0) {
            this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
            this.state.nextPage = pageNo + 1;
        }

        if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
            this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
            this.state.previousPage = pageNo - 1;
        }
        if (pageNo === 0) {
            this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
        }

        var paginations = {
            // newsId:newsId,
            skip: pageNo * this.state.ToDos,
            first: 16,
            lang: localStorage.LANG
        };

        if (pageNo > 0) {
            this.get_news(paginations).then(result => {

                this.setState({ newses: result.newses, count: result.count, loading_all: false });
            })
        } else {
            this.get_news(this.state.pagination).then(result => {
                this.setState({ newses: result.newses, count: result.count, loading_all: false });
            })
        }
    }


    handlePagination_one = async (pageNo) => {
        this.setState({
            currentPage: pageNo
        })
        this.setState({
            loading_our: true
        })
        var newsId = this.state.our_news.id;
        if (pageNo >= 0) {
            this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
            this.state.nextPage = pageNo + 1;
        }

        if (pageNo === Math.ceil(this.state.count_one / this.state.ToDos) - 1) {
            this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
            this.state.previousPage = pageNo - 1;
        }
        if (pageNo === 0) {
            this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
        }

        var paginations = {
            // newsId:newsId,
            skip: pageNo * this.state.ToDos,
            first: 16,
            lang: localStorage.LANG,
            newsType: 'IPPF_INTERNAL',
        };

        if (pageNo > 0) {
            this.get_our_news(paginations).then(result => {
                console.log(result)
                this.setState({ our_news: result.newses, count_one: result.count, loading_our: false });
            })
        } else {
            this.get_our_news(this.state.pagination_one).then(result => {
                this.setState({ our_news: result.newses, count_one: result.count, loading_our: false });
            })
        }
    }


    handlePagination_two = async (pageNo) => {
        this.setState({
            currentPage: pageNo
        })
        this.setState({
            loading_global: true
        })
        var newsId = this.state.our_news.id;

        if (pageNo >= 0) {
            this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
            this.state.nextPage = pageNo + 1;
        }

        if (pageNo === Math.ceil(this.state.count_two / this.state.ToDos) - 1) {
            this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
            this.state.previousPage = pageNo - 1;
        }
        if (pageNo === 0) {
            this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
        }

        var paginations = {
            // newsId:newsId,
            skip: pageNo * this.state.ToDos,
            first: 16,
            lang: localStorage.LANG,
            newsType: 'GLOBAL',
        };

        if (pageNo > 0) {
            this.get_global_news(paginations).then(result => {
                console.log(result)
                this.setState({ global_news: result.newses, count_two: result.count, loading_global: false });
            })
        } else {
            this.get_global_news(this.state.pagination_two).then(result => {
                this.setState({ global_news: result.newses, count_two: result.count, loading_global: false });
            })
        }
    }

    render() {
        const { newses, newsType, our_news, global_news, loading, loading_newses, count, count_one, count_two, nextPage, previousPage, currentPage, ToDos, isPrevBtnActive, isNextBtnActive, isFirstBtnActive, isLastBtnActive } = this.state;
        const { t } = this.props;
        console.log(count)
        console.log(newses)
        const pages = Math.ceil(count / ToDos);
        if (pages === 0) return null;
        var items = [];
        console.log(pages)
        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }
        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled"><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"}></i> </span></li>
        } else {
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination(previousPage)} id="btnPrev" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"}></i></a></li>
        }

        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </span></li>
        }
        else {
            renderNextBtn = <li className={isNextBtnActive}><a onClick={() => this.handlePagination(nextPage)} id="btnNext" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </a></li>
        }

        let renderfirstBtn = null;
        if (isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i> </span></li>
        } else {
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination(0)} id="btnfirst" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i></a></li>
        }

        let renderlastBtn = null;
        if (isLastBtnActive === 'disabled') {
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}> </i> </span> </li>
        } else {
            renderlastBtn = <li className={isLastBtnActive}><a onClick={() => this.handlePagination(items.length - 1)} id="btnlast" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}></i>  </a></li>
        }


        ////////////////////// Pagination from Our News
        const pages_our = Math.ceil(count_one / ToDos);
        if (pages_our === 0) return null;
        var items_one = [];

        for (var index = 0; index < pages_our; index++) {
            items_one[index] = index;
        }

        let renderPrevBtn_one = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn_one = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled"><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"}></i> </span></li>
        } else {
            renderPrevBtn_one = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination_one(previousPage)} id="btnPrev" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"} ></i></a></li>
        }

        let renderNextBtn_one = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn_one = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </span></li>
        }
        else {
            renderNextBtn_one = <li className={isNextBtnActive}><a onClick={() => this.handlePagination_one(nextPage)} id="btnNext" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </a></li>
        }

        let renderfirstBtn_one = null;
        if (isFirstBtnActive === 'disabled') {
            renderfirstBtn_one = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i> </span></li>
        } else {
            renderfirstBtn_one = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination_one(0)} id="btnfirst" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i></a></li>
        }

        let renderlastBtn_one = null;
        if (isLastBtnActive === 'disabled') {
            renderlastBtn_one = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}> </i> </span> </li>
        } else {
            renderlastBtn_one = <li className={isLastBtnActive}><a onClick={() => this.handlePagination_one(items_one.length - 1)} id="btnlast" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}></i>  </a></li>
        }

        //////////////////////
        const pages_global = Math.ceil(count_two / ToDos);
        if (pages_global === 0) return null;
        var items_two = [];

        for (var index = 0; index < pages_global; index++) {
            items_two[index] = index;
        }


        let renderPrevBtn_two = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn_two = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled"><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"}></i> </span></li>
        } else {
            renderPrevBtn_two = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination_two(previousPage)} id="btnPrev" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"}></i></a></li>
        }

        let renderNextBtn_two = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn_two = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </span></li>
        }
        else {
            renderNextBtn_two = <li className={isNextBtnActive}><a onClick={() => this.handlePagination_two(nextPage)} id="btnNext" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </a></li>
        }

        let renderfirstBtn_two = null;
        if (isFirstBtnActive === 'disabled') {
            renderfirstBtn_two = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i> </span></li>
        } else {
            renderfirstBtn_two = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination_two(0)} id="btnfirst" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i></a></li>
        }

        let renderlastBtn_two = null;
        if (isLastBtnActive === 'disabled') {
            renderlastBtn_two = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}> </i> </span> </li>
        } else {
            renderlastBtn_two = <li className={isLastBtnActive}><a onClick={() => this.handlePagination_two(items_two.length - 1)} id="btnlast" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}></i>  </a></li>
        }

        return (
            <div className="uk-container">
                <ul className="ex2">
                    <button
                        onClick={() => this.changeCategory("ALL")}
                        className={
                            newsType != "ALL"
                                ? "uk-button newsCategoryButton"
                                : "uk-button newsCategoryButtonActive"
                        }
                    >
                        {t('all')}
                    </button>
                    <button
                        onClick={() => this.changeCategory("IPPF_INTERNAL")}
                        className={
                            newsType != "IPPF_INTERNAL"
                                ? "uk-button newsCategoryButton"
                                : "uk-button newsCategoryButtonActive"
                        }
                    >
                        {t('our_news')}
                    </button>
                    <button
                        onClick={() => this.changeCategory("GLOBAL")}
                        className={
                            newsType != "GLOBAL"
                                ? "uk-button newsCategoryButton"
                                : "uk-button newsCategoryButtonActive"
                        }
                    >
                        {t('global_news')}
                    </button>

                    <div style={{ float: "right" }}>
                        {
                            this.state.loading_all === true ?
                                <Loader
                                    type="Oval"
                                    color="#00BFFF"
                                    // color="#808080"
                                    height={80}
                                    width={80}
                                    timeout={3000}
                                />
                                : this.state.loading_our === true ? (
                                    <Loader
                                        type="Oval"
                                        color="#00BFFF"
                                        // color="#808080"
                                        height={80}
                                        width={80}
                                        timeout={3000}
                                    />
                                ) : (
                                    this.state.loading_global === true ? (
                                        <Loader
                                            type="Oval"
                                            color="#00BFFF"
                                            // color="#808080"
                                            height={80}
                                            width={80}
                                            timeout={3000}
                                            
                                        />
                                    ) : (
                                        null
                                    )
                                )


                        }
                    </div>
                </ul>
                <hr />

                {newsType === "ALL" ? (
                    <div>
                        <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-margin uk-grid" uk-grid uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200 " >
                            {loading ? (
                                loading_newses.map((newses, i) => (
                                    <Card_loading_news key={i} />
                                ))

                            ) : (
                                newses.map((newses, i) => (
                                    <Card_news key={i} newses={newses} get_news={() => this.redirectToNews(newses.id)} open_news={() => this.open_news_url(newses.id, newses.url)} />
                                ))
                            )
                            }

                        </div>
                        {count >= 16 ? (
                            <div className="paginations uk-flex uk-flex-center">
                                <ul className="uk-pagination" uk-grid>
                                    {renderfirstBtn}
                                    {renderPrevBtn}

                                    {
                                        items.map((item, index) =>
                                            <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                                        )
                                    }

                                    {renderNextBtn}
                                    {renderlastBtn}
                                </ul>
                            </div>
                        ) : (null)}
                    </div>
                ) : (
                    newsType == "IPPF_INTERNAL" ? (
                        <div>
                            <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-margin uk-grid" uk-grid uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200 " >
                                {loading ? (
                                    loading_newses.map((newses, i) => (
                                        <Card_loading_news key={i} />
                                    ))

                                ) : (
                                    our_news.map((newses, i) => (
                                        <Card_news key={i} newses={newses} get_news={() => this.redirectToNews(newses.id)} open_news={() => this.open_news_url(newses.id, newses.url)} />
                                    ))
                                )
                                }
                            </div>
                            {count_one >= 16 ? (
                                <div className="paginations uk-flex uk-flex-center">
                                    <ul className="uk-pagination" uk-grid>
                                        {renderfirstBtn_one}
                                        {renderPrevBtn_one}

                                        {
                                            items_one.map((item, index) =>
                                                <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination_one(item)} >{item + 1}</a></li>
                                            )
                                        }

                                        {renderNextBtn_one}
                                        {renderlastBtn_one}
                                    </ul>
                                </div>
                            ) : (null)}
                        </div>
                    ) : (
                        <div>
                            <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-margin uk-grid" uk-grid uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200 " >
                                {loading ? (
                                    loading_newses.map((newses, i) => (
                                        <Card_loading_news key={i} />
                                    ))

                                ) : (
                                    global_news.map((newses, i) => (
                                        <Card_news key={i} newses={newses} get_news={() => this.redirectToNews(newses.id)} open_news={() => this.open_news_url(newses.id, newses.url)} />
                                    ))
                                )
                                }
                            </div>
                            {count_two >= 16 ? (
                                <div className="paginations uk-flex uk-flex-center">
                                    <ul className="uk-pagination" uk-grid>
                                        {renderfirstBtn_two}
                                        {renderPrevBtn_two}

                                        {
                                            items_two.map((item, index) =>
                                                <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination_two(item)} >{item + 1}</a></li>
                                            )
                                        }

                                        {renderNextBtn_two}
                                        {renderlastBtn_two}
                                    </ul>
                                </div>
                            ) : (null)}
                        </div>
                    )
                )
                }

                <Router>
                    <div>
                        {/* <ul className="ex2">
                                        <NavLink classname="newsButton" exact activeClassName="main-nav-active" to="/news" >{newsSec.all}</NavLink>
                                        <NavLink exact activeClassName="main-nav-active" classname="newsButton" to="/ourNews">{newsSec.our_news}</NavLink>
                                        <NavLink exact activeClassName="main-nav-active" classname="newsButton" to="/globalNews">{newsSec.global_news}</NavLink>
                                </ul>
                             <hr /> */}

                        {/* <Switch>
                            <Route  path="/news">
                            <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-margin uk-grid" uk-grid uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200 " >
                                {loading ? (
                                    loading_newses.map((newses, i) => (
                                        <Card_loading_news key={i}/>
                                    ))

                                ) : (   
                                    newses.map((newses, i) => (
                                            <Card_news key={i} newses={newses} get_news={() => this.redirectToNews(newses.id)} open_news={() => this.open_news_url(newses.id, newses.url)} />
                                        ))
                                    )
                                }
                            </div>

                            {count >=16? (
                                    <div className="paginations uk-flex uk-flex-center">
                                        <ul className="uk-pagination"  uk-grid>
                                        {renderfirstBtn}
                                        {renderPrevBtn}

                                            {
                                                items.map((item,index) =>
                                                    <li key={index} id={index} active={this.state.activePage === index }  onClick={(e)=>this.handleClick(e,item)} className={currentPage===item? 'active': ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                                                )
                                            }

                                            {renderNextBtn}
                                            {renderlastBtn}
                                        </ul>
                                    </div>
                                ):(null)}
                            </Route>

                            <Route  path="/ourNews">
                                <OurNews />
                            </Route>
                            <Route  path="/globalNews">
                                <GlobalNews />
                            </Route>     
                    </Switch> */}
                    </div>
                </Router>
            </div>
        );
    }
}

export default withRouter(withApollo(withNamespaces()(NewsContainer)));
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../components/navbar/navbar';
import Header from '../components/header/header';
import MainBanner from '../components/home/mainBanner/mainBanner';
import HomeContainer from '../components/home/homeContainer/homeContainer';
import Footer from '../components/footer/footer';

// import CKEditor from 'ckeditor4-react';
import PropTypes from 'prop-types';

import CKEditor from '@ckeditor/ckeditor5-react';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';

class Editor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: '<p>Hello from CKEditor 5!</p>'
        };

        this.handleChange = this.handleChange.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
    }

    onEditorChange(event, editor, data) {
        this.setState({
            data: data
        });

        console.log(data)

    }

    handleChange(changeEvent) {
        this.setState({
            data: changeEvent.target.value
        });

        

    }
    

    render() {

        return (
            <div className="App">
                <h2>Using CKEditor 4 in React</h2>
                {/* <CKEditor
                    data={this.state.data}
                    onChange={this.onEditorChange} 
                    config={{
                        toolbar:[{
                            name: 'clipboard',
                            items: ['Undo', 'Redo']
                          },
                          {
                            name: 'styles',
                            items: ['Styles', 'Format']
                          },
                          {
                            name: 'basicstyles',
                            items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']
                          },
                          {
                            name: 'paragraph',
                            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                          },
                          {
                            name: 'links',
                            items: ['Link', 'Unlink']
                          },
                          {
                            name: 'insert',
                            items: ['Image', 'Table']
                          },
                          {
                            name: 'tools',
                            items: ['Maximize']
                          },
                          {
                            name: 'editing',
                            items: ['Scayt']
                          }
                        ]
                    }}

                 
                /> */}

                <CKEditor
                    onInit={editor => console.log('Editor is ready to use!', editor)}
                    onEditorChange
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.onEditorChange(event, editor, data)
                    }}
                    // config={ {
                    //     plugins: [ ListStyle, Paragraph, Bold, Italic, Heading ],
                    //     toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', ]
                    // } }
                    editor={DecoupledEditor}
                    data="<p>Hello from CKEditor 5!</p>"
                />

                <EditorPreview data={this.state.data} />
            </div>
        );
    }

}

class EditorPreview extends React.Component {
    render() {
        return (
            <div className="editor-preview">
                <h2>Rendered content</h2>
                <div dangerouslySetInnerHTML={{ __html: this.props.data }}></div>
            </div>
        );
    }
}

EditorPreview.defaultProps = {
    data: ''
};

EditorPreview.propTypes = {
    data: PropTypes.string
};

export default Editor; 
import firebase from './firebase';
import { Promise } from 'q';

// Check if there is a storage reference for the user
export const checkReference =  (userId) => {
    return new Promise(resolve => {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child('uploads/'+userId);
        console.log(imageRef);
    })
}

export const deleteFile = (path) => {
    return new Promise(resolve => {
        const storageRef = firebase.storage().ref();
        var fileRef = storageRef.child(path);
        // Delete the file
        fileRef.delete().then(function() {
        // File deleted successfully
        resolve({
          status: true,
        });
        }).catch(function(error) {
        // Uh-oh, an error occurred!
        resolve({
          status: false,
        })
        });
        
    });
    
}

export const uploadFile = (file, userId) => {
    return new Promise(resolve => {
      var metadata = {
        contentType: file.type
      };
      const storageRef = firebase.storage().ref();
      var uploadTask = storageRef
        .child("uploads/" + userId + "/" + file.name)
        .put(file, metadata);
  
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              resolve({
                  status: false,
                  url: null,
              })
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        function(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              resolve({
                status: false,
                url: null,
            })
              break;
  
            case "storage/canceled":
              // User canceled the upload
              resolve({
                status: false,
                url: null,
            })
              break;
  
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              resolve({
                status: false,
                url: null,
            })
              break;
          }
        },
        function() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log("File available at", downloadURL);
            resolve({
              status: true,
              url : downloadURL});
          });
        }
      );
    })
    
  };
import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_FLASH_QUIZ_PROGRESS } from "../../queries/common_queries";
import { Modal, ModalContent } from "semantic-ui-react";
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
  return {
    nextSubModuleDisabled: state.custom_reducer.nextSubModuleDisabled,
  };
};

class QuizButtonComponent extends React.Component {
  constructor(props) {
    super(props);

    console.log(this.props.moduleId);

    this.state = {
      moduleId: this.props.moduleId,
      quizStatus: this.props.quizStatus,
      loading: false,
      open: true,
      errorMessage: '',
    };
  }

  get_flash_quiz_progress = async () => {
    const result = await this.props.client.query({
      query: GET_FLASH_QUIZ_PROGRESS,
      variables: {
        moduleId: this.props.moduleId,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getFlashQuizProgress;
  };

  redirectToQuiz = (id) => {
    this.props.history.push("/quiz?id=" + id);
  };

  showSubModules = () => {
    this.props.history.push("/result");
  }

  checkOverallModuleProgress = (e) => {
    e.preventDefault();
    this.get_flash_quiz_progress().then((result) => {
      console.log();
      if (result.progress < 100) {
        this.setState({
          errorMessage: this.props.t('try_exam_with_unfilled_sub_modules'),
        });
      } else {
        this.setState({
          errorMessage: '',
        });
        this.redirectToQuiz(this.state.moduleId);
      }
    });
  };

  closeModal = (e) => {
    this.setState({ open: false });
  };

  render() {
    const { loading, quizStatus, open, errorMessage } = this.state;
    const { nextSubModuleDisabled, t } = this.props;
    return (
      <div className="take-quiz">
        <button
          className="take-quiz-btn uk-button uk-button-primary uk-button-large"
          // disabled={nextSubModuleDisabled}
          href="#browse-videos"
          onClick={(e) => this.checkOverallModuleProgress(e)}
        >
          {quizStatus ? t('re_take_quiz') : t('take_quiz')}
        </button>
        <div style={styles.messageDiv}>
          {errorMessage != '' ? (
            <React.Fragment>
              <div style={styles.messageTextDiv} className="danger_alert" >{errorMessage}</div>
              <text className="linkText" onClick={() => this.showSubModules()}>{t('in_complete')}</text>
              <br />
            </React.Fragment>
          ) : (
              null
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withApollo(withNamespaces()(QuizButtonComponent)))
);
const styles = {
  messageDiv: {
    width: 'fit-content',
    margin: '0 auto',
    marginTop: '1rem',
  },
  button: {
    width: '20%',
    float: 'right',
    marginTop: '0.2rem',
  },
  messageTextDiv: {
    paddingRight: '1.2rem',
  }
}

import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_REPEATED_USER_STATS, GET_SBL_STATS } from "../../queries/admin_queries";
import NoDataMessage from "../messages/contentNotFound/NoDataFoundSmall";
import Circle from "react-circle";

const mapStateToProps = (state, ownProps) => {
  return {};
};

class RepeatUserStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        totalUserCount: '',
        loggedInUserCount: '',
        percentage: '',
    };
  }

    componentDidMount() {
      this.getRepeatedUserStats().then((result) => {
          console.log(result)
        this.setState({
            totalUserCount: result.totalUserCount,
            loggedInUserCount: result.loggedInUserCount,
            percentage: result.percentage,
        });
      });
    }

  //   componentDidUpdate(prevProps) {
  //     if (prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate) {
  //         this.getSBLStats(this.props.fromDate, this.props.toDate).then((result) => {
  //             console.log(result);
  //             this.setState({
  //               totalExams: result.totalExams,
  //               totalExamSubmissions: result.totalExamSubmissions,
  //               mostPopularExam: result.mostPopularExam,
  //             });
  //           });
  //     }
  //   }

  getRepeatedUserStats = async (from, to) => {
      const result = await this.props.client.query({
        query: GET_REPEATED_USER_STATS,
        variables: {fromDate: from, toDate: to},
        fetchPolicy: "network-only",
      });
      return result.data.getRepeatedUserStats;
    };

  render() {
    const {} = this.props;
    const {
      percentage,
      loggedInUserCount,
    } = this.state;
    return (
      <div style={{width: '14rem',margin: '0 auto'}}>
        <table style={{ width: '100%'}}>
          <tbody>
            <tr>
              <td style={{width: '50%', textAlign: 'center'}}>
                <div>
                  <text style={styles.time}>{loggedInUserCount || 0}</text>
                  <text style={styles.text}> Users</text>

                </div>
                {/* <div style={{ marginTop: "0rem" }}>
                </div> */}
              </td>
              <td style={{width: '50%', textAlign: 'center', borderLeft: '1px solid #dedede'}}>
                {/* <Circle
                  animate={true}
                  animationDuration="3s"
                  size={90}
                  lineWidth={30}
                  progress={Math.round(percentage) || 0}
                  progressColor="#b9de23"
                  bgColor="white"
                  textColor="white"
                  textStyle={{
                    fontSize: "5.5rem",
                    fontWeight: "500",
                  }}
                /> */}
                <text style={styles.time}>{Math.round(percentage) || 0}</text>
                  <text style={styles.text}> %</text>
                
              </td>
            </tr>
            <tr>
                <td colSpan="2">
                  <text style={styles.text1}>Repeat Registered Users</text>

                </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withApollo(RepeatUserStats))
);

const styles = {
  time: {
    fontSize: "3.5rem",
    color: 'white'
  },
  text: {
    fontSize: "0.9rem",
    fontWeight: '300',

  },
  text1: {
    fontSize: "1rem",
    fontWeight: '300',
    color: 'black'
  },
};

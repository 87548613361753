import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';


//queries
import { GET_MODULES_FOR_ADMIN, GET_SUB_MODULES_FOR_ADMIN, GET_LESSONS_FOR_ADMIN } from '../../../queries/admin_queries';


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ManageLessons extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }
        this.state = {
            loading: false,
            pagination: {
                skip: 0,
                first: 1000
            },
            submodule_pagination: {
                moduleId: "",
                skip: 0,
                first: 1000
            },
            modules: [],
            submodules: [],
            lessons: [],
            pagination_submodules: {
                isActive: true,
                lang: localStorage.LANG
            },
            pagination_lessons: {
                isActive: true,
                lang: localStorage.LANG
            },
        }
    }

    componentDidMount() {
        this.loadModules();
    }

    loadModules() {
        this.get_modules(this.state.pagination).then(result => {
            var module_opt = [];
            result.modules.map(module => (
                module_opt.push({ key: module.id, value: module.id, id: 'moduleId', label: module.moduleTitle })
            ));
            this.setState({ modules: module_opt });
        })
    }

    handleModuleChange = (event) => {
        var moduleId = event.target.value;

        let pagination_lessons = this.state.pagination_lessons;
        delete pagination_lessons["subModuleId"];
        pagination_lessons["moduleId"] = moduleId;

        this.get_submodules(moduleId).then(result => {
            console.log(result)
            var submodule_opt = [];
            result.subModules.map(submodule => (
                submodule_opt.push({ key: submodule.id, value: submodule.id, id: 'subModuleId', label: submodule.subModuleTitle })
            ));
            this.setState({ submodules: submodule_opt, "moduleId": moduleId });
        })

        this.get_lessons(pagination_lessons).then(result => {
            console.log(result)
            this.setState({ lessons: result.lessons });
        })
    };

    handleSubModuleChange = (event) => {
        var subModuleId = event.target.value;

        let pagination_lessons = this.state.pagination_lessons;
        delete pagination_lessons["moduleId"];
        pagination_lessons["subModuleId"] = subModuleId;

        this.get_lessons(pagination_lessons).then(result => {
            console.log(result)
            this.setState({ lessons: result.lessons, "subModuleId": subModuleId });
        })
    };

    get_modules = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULES_FOR_ADMIN,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModulesForAdmin;
    };

    get_submodules = async (moduleId) => {
        let pagination_submodules = this.state.pagination_submodules;
        pagination_submodules["moduleId"] = moduleId;
        const result = await this.props.client.query({
            query: GET_SUB_MODULES_FOR_ADMIN,
            variables: pagination_submodules,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModulesForAdmin;
    };

    get_lessons = async (pagination_lessons) => {
        console.log(pagination_lessons)
        const result = await this.props.client.query({
            query: GET_LESSONS_FOR_ADMIN,
            variables: pagination_lessons,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getLessonsForAdmin;
    };

    addNewLesson() {
        this.props.history.push('/admin_add_lesson');
    }

    editLesson(id) {
        this.props.history.push('/admin_add_lesson?id=' + id);
    }

    render() {

        const { modules, submodules, lessons, loading, count } = this.state;
        const pages = Math.ceil(count / 3);

        if (pages === 0) return null;

        var items = [];

        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }
        return (
            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-th icon-large uk-margin-right"></i>
                    <h4 className="uk-margin-remove">Lessons </h4>

                </div>
                <div className="uk-flex-inline uk-float-right">
                    <button className="uk-button uk-button-primary admin-btn" onClick={() => this.addNewLesson()}>Add Lesson</button>
                </div>
                <div className="uk-background-default uk-margin-top uk-padding">
                    <div className="uk-grid">
                
                    </div>
                </div>
                <div>
                    <div className="uk-form-label"> Module </div>
                    <select className="uk-select uk-width-large" onChange={this.handleModuleChange} value={this.state.moduleId || ''}>
                        <option key="userType" value="" disabled>Select Module</option>
                        {modules.map(item => (
                            <option key={item.key} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                    <br />
                </div>
                <div>
                    <div className="uk-form-label"> Submodule </div>
                    <select className="uk-select uk-width-large" placeholder="Select Submodule" onChange={this.handleSubModuleChange} value={this.state.subModuleId || ''}>
                        <option key="userType" value="" >Select Submodule</option>
                        {submodules.map(item => (
                            <option key={item.key} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
                <br /> <br />
                <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                        <thead>
                            <tr className="uk-text-small uk-text-bold">
                                <th>Lesson Num</th>
                                <th>Lesson Banner</th>
                                <th>Lesson Title</th>
                                <th>Helper Text</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lessons.map((lesson, i) => (
                                    <tr key={i}>
                                        <td> {lesson.lessonNumber} </td>
                                        <td> <img src={lesson.lessonBannerIconUrl} />  </td>
                                        <td> {lesson.lessonTitle}  </td>
                                        <td> {lesson.lessonHelperText} </td>
                                        <td> {lesson.isActive ? (
                                            <p style={{ color: "green" }}>ACTIVE</p>
                                        ) : (
                                                <p style={{ color: "red" }}>INACTIVE</p>
                                            )} </td>
                                        <td>
                                            <button disabled={loading ? (true) : (false)} className="uk-button uk-button-warning admin-table-btn" uk-tooltip="Edit" onClick={() => this.editLesson(lesson.id)} ><i class='fas fa-pencil-alt'></i></button>

                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="paginations uk-flex uk-flex-center">
                    <ul class="uk-pagination" uk-margin>
                        <li><a onClick={() => this.handlePagination(0)}><i class="fas fa-angle-double-left"></i></a></li>
                        <li><a href="#"><i class="fas fa-angle-left"></i></a></li>

                        {
                            items.map((item) =>
                                <li><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                            )
                        }

                        <li><a href="#"><i class="fas fa-angle-right"></i></a></li>
                        <li><a onClick={() => this.handlePagination(items.length - 1)}><i class="fas fa-angle-double-right"></i></a></li>
                    </ul>
                </div>
            </div>
        );
    }

}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ManageLessons))); 
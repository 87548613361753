import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import Lesson_banner from '../../../components/lesson/lesson_banner/lesson_banner';
import Lesson_container from '../../../components/lesson/lesson_container/lesson_container';
import Footer from '../../../components/footer/footer';

//queries
import { GET_CONTENT } from '../../../queries/common_queries';

class Lesson extends React.Component {
    constructor(props) {
        super(props);

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');
        const module = query.get('module');
        const submodule = query.get('submodule');

        if(localStorage.USER_ID==undefined){
            this.props.history.push('/');
        }else{
            if(id==undefined){
                this.props.history.push('/home');
            }
        }

        this.state = {
            id: id,
            lesson: "",
            content: "",
            from: page,
            pagination: {
                skip: 0,
                first: 20,
                moduleId: "",
                subModuleId: "",
                lessonId: id,
                isActive: true,
                lang: localStorage.LANG
            },
            loading: false,
            moduleId: module,
            subModuleId: submodule
        }

    }

    componentDidMount() {
        this.setState({ loading: true });
        this.get_content(this.state.pagination).then(result => {
            console.log(result)
            this.setState({
                module: result.module, sub_module: result.subModule, lesson: result.lesson,
                content: result.contents.contents[0], loading: false
            });
        });
    }

    get_content = async (pagination) => {
        console.log(pagination)
        const result = await this.props.client.query({
            query: GET_CONTENT,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
            
        });
        return result.data.getContent;
    };



    render() {

        const { id, from, lesson, content, loading, moduleId,subModuleId } = this.state;
        console.log(content)

        return (
            <div>
                <Navbar />
                <div id="spinneroverlay">
                    <div className="spinner"></div>
                </div>
                <div className="app">
                    <Header />

                    {loading ? (
                        <div className="topic2 hero-bg shadow-small">
                            <div className="uk-grid">
                                        <h2 className="uk-light  uk-text-bold uk-text-white"> Just a moment. </h2>
                                        <p className="uk-light uk-text-bold">  We are loading the content… </p>
                            </div>
                        </div>
                    ) : (
                            [<Lesson_banner lesson={lesson} /> ,
                            <Lesson_container from={from} id={id} subModuleId={subModuleId} moduleId={moduleId} content={content} /> ]
                        )}
                    <Footer />
                </div>
            </div>
        );
    }

}

export default withRouter(withApollo(Lesson)); 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { Route, withRouter, Redirect } from 'react-router-dom';
import Navbar from '../../navbar/navbar';


//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';
import Course_catagory_hero from '../../../assets/images/home/Layer3.png';


//containers
import LearningContainer from '../../learning/learningContainer/learningContainer';
import homeContainer from '../../home/homeContainer/homeContainer';
import ToolsContainer from '../../tools/toolsContainer/toolsContainer';
import NewsContainer from '../../news/newsContainer/newsContainer';
import GalleryContainer from '../../gallery/galleryContainer/galleryContainer';
import ExamContainer from '../../../screens/user/exams/exams';
import Header from '../../header/header';
import Footer from '../../footer/footer';

//Menu
import HomeMenubar from '../homeMenubarItem';


const homeRoutes = [
    {
        path: '/home',
        name: "Learning",
        component: LearningContainer
    },
    {
        path: '/tools',
        name: "Tools",
        component: ToolsContainer
    },
    {
        path: '/news',
        name: "News",
        component: NewsContainer
    },
    {
        path: '/gallery',
        name: "Gallery",
        component: GalleryContainer
    },
    {
        path: '/sbls',
        name: "Exams",
        component: ExamContainer
    }
]


class MainBanner extends React.Component {
    constructor(props) {
        super(props);

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        this.state = {
            page: page
        }
    }

    _onLinkClick = (path) => {
        this.props.history.push(path);
    };

    render() {

        const { page } = this.state;
        console.log(page)

        return (
            <div>

                <Navbar />
                <div class="app">
                    <Header />


                    {/* <ul className="uk-switcher switcher-container"> */}
                    {page == "home" ? (

                        <div className="topic1 hero-bg">
                            <div className="uk-grid">
                                <div className="uk-width-1-2@m">
                                    <h1 className="uk-animation-fade"> Welcome {localStorage.FIRST_NAME + " " + localStorage.LAST_NAME}    </h1>
                                    <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p>
                                    <div className="uk-visible@m uk-animation-slide-bottom-small uk-grid" >
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : (
                            page == "tools" ? (

                                <div className="topic2 hero-bg">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2@m">
                                            <h1 className="uk-animation-fade"> Welcome {localStorage.FIRST_NAME + " " + localStorage.LAST_NAME}    </h1>
                                            <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p>
                                        </div>
                                    </div>
                                </div>

                            ) : (
                                    page == "news" ? (

                                        <div className="topic2 hero-bg">
                                            <div className="uk-grid">
                                                <div className="uk-width-1-2@m">
                                                    <h1 className="uk-animation-fade"> NEWS </h1>
                                                    <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p>
                                                </div>
                                            </div>
                                        </div>

                                    ) : (
                                            page == "gallery" ? (

                                                <div className="topic4 hero-bg">
                                                    <h1 className="uk-animation-fade"> Welcome {localStorage.FIRST_NAME + " " + localStorage.LAST_NAME}    </h1>
                                                    <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p>
                                                </div>

                                            ) : (
                                                    null
                                                )
                                        )
                                )

                        )}


                    <button className="uk-button uk-hidden@m  mobile-catagory-button" type="button" uk-toggle="target: #tabs-moible; cls: tabs-moible"> Open subcatagory </button>


                    <ul className="uk-subnav uk-subnav-pill tabs-moible-hidden nav-menues" uk-switcher="connect: .switcher-container" id="tabs-moible">
                        {homeRoutes.map((route, key) => (
                            <li className="uk-active" key={key} onClick={() => this._onLinkClick(route.path)}>
                                <a> {route.name} </a>
                            </li>
                        ))}
                    </ul>

                    {homeRoutes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            component={route.component}
                        />
                    ))}

                    {page == "home" ? (
                        <Redirect to="/home" />
                    ) : (
                            null
                        )}

                </div>
                <Footer />
            </div>
        );
    }

}

export default withRouter(withApollo((MainBanner))); 
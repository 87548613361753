import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Home from '../../assets/images/icons/home.png';
import Contact from '../../assets/images/icons/Contact-Us.png';
import Questions from '../../assets/images/icons/questions-circular-button.png';
import Aboutus from '../../assets/images/icons/About-us.png';
import Terms_Conditions from '../../assets/images/icons/Terms_Conditions.png';
import FAQ from '../../assets/images/icons/FAQ.png';
import Ratings from '../../assets/images/icons/rating.png';
import RatingModal from "../../components/userRatings/ratingModal"
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import {
  setModalStatus,
} from "../../actions/user-actions";

const mapStateToProps = (state, ownProps) => {
  return {
    ratingModalOpen: state.custom_reducer.ratingModalOpen,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setModalStatus: (status) => {
      dispatch(setModalStatus(status));
    },
  };
};

class Navbar extends React.Component {
    constructor(props) {
        super(props);
    }
    navigate = (path) => {
        console.log(path);
        this.props.history.push(path);
    }

    openRatingModal = (status) => {
      var status = false
      if (this.props.ratingModalOpen === true) {
        status = false;
      }
      else {
        status = true;
      }
      this.props.setModalStatus(status, "navbar");
    }

    render() {
      const { t } = this.props;
        return (
            <div>
          {this.props.ratingModalOpen === true ? <RatingModal category = "navbar" /> : null}
                <div className="side-nav uk-animation-slide-left-medium" id="side-nav">
                    <span className="uk-animation-fade tm-mobile-close-icon" uk-toggle="target: #side-nav; cls: side-nav-active"> <i className="fas fa-times icon-large"></i></span>
                    <div id="side-nav" >
                        <div className="align-center">
                          {
                            localStorage.LANG=="ar"? (
                              <ul className="side-nav_icon sideNav">
                            <li onClick={() => this.navigate("/home")}>
                                <a uk-tooltip={t('home')} pos="right"> <img src={Home} alt="home"  /></a>
                            </li>

                            <li onClick={() => this.navigate("/faq")} >
                                <a uk-tooltip={t('faq')} pos="right" > <img  src={ Questions } alt="faq"  /> </a>
                            </li>

                            <li onClick={() => this.navigate("/contact")}>
                                <a uk-tooltip={t('contact')} pos="right" > <img className="iconSize" src={ Contact } alt="contact" /> </a>
                            </li>
                            <li
                             onClick={() => this.openRatingModal(true)}
                             >
                                <a uk-tooltip={t('rate_us')} pos="right" > <img className="" src={ Ratings } alt="rating" style={{height: "35px", width: '35px'}} /> </a>
                            </li>
                            <li>{this.props.ratingModalOpen}</li>
                        </ul>
                            ):(
                              <ul className="side-nav_icon">
                            <li onClick={() => this.navigate("/home")}>
                                <a uk-tooltip={t('home')} pos="right"> <img src={Home} alt="home"  /></a>
                            </li>

                            <li onClick={() => this.navigate("/faq")}>
                                <a uk-tooltip={t('faq')} pos="right" > <img  src={ Questions } alt="faq"  /> </a>
                            </li>

                            <li onClick={() => this.navigate("/contact")}>
                                <a uk-tooltip={t('contact')} pos="right" > <img className="iconSize" src={ Contact } alt="contact" /> </a>
                            </li>
                            <li
                             onClick={() => this.openRatingModal(true)}
                             >
                                <a uk-tooltip={t('rate_us')} pos="right" > <img className="" src={ Ratings } alt="rating" style={{height: "35px", width: '35px'}} /> </a>
                            </li>
                            <li>{this.props.ratingModalOpen}</li>
                        </ul>
                            )
                          }
                        
                        </div>
                        <div className="front-bottom">
                            <img src={require("../../assets/images/seLayer.png")} className="front-bottom-img" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withApollo(withNamespaces()(Navbar)))
);
import * as React from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Navbar from "../../../components/navbar/navbar";
import Header from "../../../components/header/header";
import Module_banner from "../../../components/module/module_banner/module_banner";
import Module_container from "../../../components/module/module_container/module_container";
import Footer from "../../../components/footer/footer";
import Card_module, {
  Card_loading_sub_module,
} from "../../../components/sub_module/card_sub_module/card_sub_module";
import ContentLoader from "react-content-loader";
import { withNamespaces } from "react-i18next";

//queries
import {
  GET_SUB_MODULES,
  GET_NEXT_MODULE,
  GET_PREVIOUS_MODULE,
  GET_FLASH_QUIZ_PROGRESS,
  GET_MODULE,
} from "../../../queries/common_queries";


class Module extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(-500, -200);
    var currentLocation = window.location.href;
    var url = currentLocation.split("?");
    url = url[0];
    url = url.split("/");
    var page = url[url.length - 1];

    let query = new URLSearchParams(this.props.location.search);
    let id = query.get("id");

    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (id == undefined) {
        this.props.history.push("/home");
      }
    }

    this.state = {
      id: id,
      from: page,
      module: "",
      sub_modules: [],
      lessons: [],
      contents: [],
      moduleId: id,
      pagination: {
        // skip: 0,
        // first: 3,
        isActive: true,
        moduleId: id,
        lang: localStorage.LANG,
      },
      redirectKey: "",
      loading: false,
      nextModuleId: "",
      prevModuleId: "",
      nextPage: 1,
      previousPage: 0,
      currentPage: 0,
      activePage: "",
      ToDos: 3,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      isFirstBtnActive: "disabled",
      isLastBtnActive: "",
      // objectives:[{ question:'',}],
      objectives: [],
      fqSubmissions: "",
    };
    console.log(this.state.module);
    console.log(id);
    this.handleClick = this.handleClick.bind(this);
    this.passToPreviousModule = this.passToPreviousModule.bind(this);
    // console.log("submodule");
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
                    : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
                    : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
                    : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  handleClick(event, index) {
    // console.log(index)
    this.setState({
      currentPage: Number(index),
      activePage: index,
    });
  }

  componentDidMount() {
    this.initializeModuleScreen();
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  initializeModuleScreen() {
    this.setState({ loading: true });
    this.get_module().then((result) => {
        this.setState({
            module: result
        })
    })
    this.get_sub_modules(this.state.pagination).then((result) => {
      // console.log(result.subModules.count)
      console.log(result.module.objectives);

      var redirectVal = "";
      if (result.subModules.count > 0) {
        redirectVal = "SUBMODULE";
      }

      if (result.lessons.count > 0) {
        redirectVal = "LESSON";
      }
      if (result.contents.count > 0) {
        redirectVal = "CONTENT";
      }
      console.log(result.module);
      this.setState({
        module: result.module,
        sub_modules: result.subModules,
        lessons: result.lessons,
        count: result.subModules.count,
        objectives:
          result.module.objectives != null
            ? result.module.objectives.learnings
            : [],
        contents: result.contents,
        redirectKey: redirectVal,
        loading: false,
      });
    });

    this.get_next_module().then((result) => {
      console.log(result.length);
      if (result.length !== 0) {
        this.setState({ nextModuleId: result[0].id });
      } else {
        this.setState({ nextModuleId: 0 });
      }
      console.log(this.state.nextModuleId);
    });

    this.get_previous_module().then((result) => {
      console.log(result.length);
      if (result.length !== 0) {
        this.setState({ prevModuleId: result[0].id });
      } else {
        this.setState({ prevModuleId: 0 });
      }

      // if (result.length > 0) {
      //     this.setState({ prevModuleId: (result[0].id)
      //      });
      // }

      // if(result.length==0){
      //     this.setState({ prevModuleId: result[0].id });
      // }else{
      //     this.setState({ prevModuleId: 0 });
      // }
    });

    this.get_flash_quiz_progress().then((result) => {
      this.setState({ fqSubmissions: result.submissions });
    });
  }

  get_sub_modules = async (pagination) => {
    console.log(pagination);
    const result = await this.props.client.query({
      query: GET_SUB_MODULES,
      variables: pagination,
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    // console.log(result.data.getSubModules)
    return result.data.getSubModules;
  };

  handlePagination = async (pageNo) => {
    console.log(pageNo);
    this.setState({
      currentPage: pageNo,
    });

    if (pageNo >= 0) {
      this.setState({ isNextBtnActive: "", isLastBtnActive: "" });
      this.state.nextPage = pageNo + 1;
    }

    if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
      this.setState({
        isNextBtnActive: "disabled",
        isLastBtnActive: "disabled",
      });
    }

    if (pageNo > 0) {
      this.setState({ isPrevBtnActive: "", isFirstBtnActive: "" });
      this.state.previousPage = pageNo - 1;
    }
    if (pageNo === 0) {
      this.setState({
        isPrevBtnActive: "disabled",
        isFirstBtnActive: "disabled",
      });
    }

    var moduleId = this.state.module.id;
    console.log(moduleId);
    var paginations = {
      skip: pageNo * this.state.ToDos,
      first: 3,
      moduleId: moduleId,
      lang: localStorage.LANG,
    };
    this.get_sub_modules(paginations).then((result) => {});

    if (pageNo > 0) {
      this.get_sub_modules(paginations).then((result) => {
        this.setState({
          sub_modules: result.subModules,
          count: result.subModules.count,
        });
      });
    } else {
      this.get_sub_modules(this.state.pagination).then((result) => {
        this.setState({
          sub_modules: result.subModules,
          count: result.subModules.count,
        });
      });
    }
  };

  get_previous_module = async () => {
    const result = await this.props.client.query({
      query: GET_PREVIOUS_MODULE,
      variables: {
        moduleId: this.state.moduleId,
        last: 1,
        lang: localStorage.LANG,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getBackModule;
  };

  get_next_module = async () => {
    const result = await this.props.client.query({
      query: GET_NEXT_MODULE,
      variables: {
        moduleId: this.state.moduleId,
        first: 1,
        lang: localStorage.LANG,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getNextModule;
  };

  passToNextModule = (nextModuleId) => {
    window.scrollTo(-500, -200);
    this.props.history.push("/module?id=" + nextModuleId);

    setTimeout(() => {
      var currentLocation = window.location.href;
      var url = currentLocation.split("?");
      url = url[0];
      url = url.split("/");
      var page = url[url.length - 1];

      let query = new URLSearchParams(this.props.location.search);
      let id = query.get("id");

      this.setState({
        id: id,
        from: page,
        moduleId: id,
        pagination: {
          // skip: 0, first: 3,
          isActive: true,
          moduleId: id,
          lang: localStorage.LANG,
        },
      });
      this.initializeModuleScreen();
    }, 100);
  };

  passToPreviousModule = (prevModuleId) => {
    window.scrollTo(-500, -200);
    this.props.history.push("/module?id=" + prevModuleId);

    setTimeout(() => {
      var currentLocation = window.location.href;
      var url = currentLocation.split("?");
      url = url[0];
      url = url.split("/");
      var page = url[url.length - 1];

      let query = new URLSearchParams(this.props.location.search);
      let id = query.get("id");

      this.setState({
        id: id,
        from: page,
        moduleId: id,
        pagination: {
          // skip: 0, first: 3,
          isActive: true,
          moduleId: id,
          lang: localStorage.LANG,
        },
      });
      this.initializeModuleScreen();
    }, 100);

    // this.props.history.goBack();
  };
  redirectToHome = () => {
    this.props.history.push("/home");
  };

  get_module = async () => {
    const result = await this.props.client.query({
      query: GET_MODULE,
      variables: {
        moduleId: this.state.id,
        lang: localStorage.LANG,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result)
    return result.data.getModule;
  };

  get_flash_quiz_progress = async () => {
    const result = await this.props.client.query({
      query: GET_FLASH_QUIZ_PROGRESS,
      variables: {
        moduleId: this.state.id,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getFlashQuizProgress;
  };

  get_single_module = async (id) => {
    const result = await this.props.client.query({
      query: GET_MODULE,
      variables: {
        moduleId: id,
        lang: localStorage.LANG,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result)
    return result.data.getModule;
  };

  updateModuleId = () => {
    let query = new URLSearchParams(this.props.location.search);
    let id = query.get('id'); 
    let data = {isActive: true, moduleId: id, lang: localStorage.LANG}
    console.log(id);
    console.log(this.state.id);
    if(id != this.state.id) {
        this.get_single_module(id, localStorage.LANG).then(result1 => {
            console.log(result1);
            this.get_sub_modules(data).then((result) => {
              this.setState({
                module: result1,
                id: id,
                sub_modules: result.subModules,
                count: result.subModules.count,
              });
            });
        })
    }
}

  render() {
    this.updateModuleId();
    const {
      id,
      from,
      module,
      sub_modules,
      lessons,
      contents,
      redirectKey,
      loading,
      ToDos,
      count,
      nextPage,
      previousPage,
      isPrevBtnActive,
      isNextBtnActive,
      isFirstBtnActive,
      isLastBtnActive,
      objectives,
    } = this.state;
const { t } = this.props;
    return (
      <div>
        <Navbar />
        <div className="app" style={{ paddingBottom: "20px" }}>
          <Header />
          {loading ? (
            <div>
              <div
                className="course-dhashboard"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, rgb(1, 102, 202) 50% ,rgb(1, 102, 202,0.9) )",
                  minHeight: "140px",
                  maxHeight: "140px",
                }}
              >
                <div className="uk-grid">
                  <div>
                   {localStorage.LANG=='ar'?(
                      <h2 style={{paddingTop:"22px"}} className="uk-light  uk-text-bold uk-text-white">
                      {" "}
                      {t('wait')}{" "}
                    </h2>
                   ):(
                    <h2 style={{paddingTop:"10px"}} className="uk-light  uk-text-bold uk-text-white">
                    {" "}
                    {t('wait')}{" "}
                  </h2>
                   )}
                    <p className="uk-light uk-text-bold">
                      {" "}
                      {t('loading')}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="uk-container"
                uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
              >
                <div className="uk-clearfix boundary-align">
                  <div className="section-heading none-border">
                    <Card_loading_sub_module />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            [
              <Module_banner module={module} />,
              <div
                className="uk-container"
                uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
              >
                <Module_container
                  sub_modules={sub_modules}
                  from={from}
                  id={id}
                  redirectKey={redirectKey}
                  module={module}
                  objectives={objectives}
                  // sub_modules={sub_modules} comment on july 25th 2023 by Narthu
                  lessons={lessons}
                  contents={contents}
                  submissions={this.state.fqSubmissions}
                />
                {this.state.nextModuleId !== 0 ? (
                  localStorage.LANG=='ar' ? (
                    <button
                    style={{float:"left"}}
                    className="uk-button uk-button-primary next-prev-btn"
                    onClick={() =>
                      this.passToNextModule(this.state.nextModuleId)
                    }
                  >
                    {" "}
                   {t('next_module')} <i className="fas fa-angle-left"></i>
                  </button>
                  ):(
                  <button
                    className="uk-button uk-button-primary float next-prev-btn"
                    onClick={() =>
                      this.passToNextModule(this.state.nextModuleId)
                    }
                  >
                    {" "}
                   {t('next_module')} <i className="fas fa-angle-right"></i>
                  </button>
                  )
                ) : (
                  ""
                )}

                {this.state.prevModuleId !== 0 ? (
                  localStorage.LANG=='ar'? (
                    <button
                    className="uk-button uk-button-primary uk-align-right next-prev-btn"
                    onClick={() =>
                      this.passToPreviousModule(this.state.prevModuleId)
                    }
                  >
                    <i className="fas fa-angle-right"></i> {t('prev_module')}{" "}
                  </button>
                  ):(
                  <button
                    className="uk-button uk-button-primary uk-align-left next-prev-btn"
                    onClick={() =>
                      this.passToPreviousModule(this.state.prevModuleId)
                    }
                  >
                    <i className="fas fa-angle-left"></i> {t('prev_module')}{" "}
                  </button>
                  )
                ) : (
                  localStorage.LANG=='ar'?(
                    <button
                    className="uk-button uk-button-primary uk-align-right"
                    onClick={() => this.redirectToHome()}
                  >
                    {" "}
                    {t('back_home')}{" "}
                  </button>
                  ):(
                  <button
                    className="uk-button uk-button-primary uk-align-left"
                    onClick={() => this.redirectToHome()}
                  >
                    {" "}
                    {t('back_home')}{" "}
                  </button>
                  )
                )}
              </div>,
            ]
          )}
        </div>
        <Footer language={localStorage.LANG} />
      </div>
    );
  }
}

export default withRouter(withApollo(withNamespaces()(Module)));

import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../../actions/user-actions';

import { GET_ALL_NEWS, UPDATE_NEWS_STATS } from '../../../../queries/common_queries';
import Card_news, { Card_loading_news } from '../../../../components/news/card_news/card_news';
import english from '../../../../languages/en.json'


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class OurNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pagination: {
                skip: 0,
                first: 16,
                newsType: 'IPPF_INTERNAL',
                lang: localStorage.LANG
            },
            newses: [],
            loading_newses: [
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />,
                <Card_loading_news />
            ],
            nextPage: 1,
            previousPage: 0,
            currentPage: 0,
            activePage: '',
            ToDos: 16,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            isFirstBtnActive: 'disabled',
            isLastBtnActive: ''
        }
        // this.loadAllNews();
        this.handleClick = this.handleClick.bind(this);


    }


    handleClick(event, index) {
        this.setState({
            currentPage: Number(index),
            activePage: index
        })
    }

    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.get_news(this.state.pagination).then(result => {
                console.log(result)
                var news_opt = result.newses.map(newses => {
                    return {
                        id: newses.id,
                        newsTitle: newses.newsTitle,
                        newsBannerImage: newses.newsBannerImage,
                        newsHelperText: newses.newsHelperText,
                        newsContent: newses.newsContent,
                        createdAt: newses.createdAt,
                        url: newses.url,
                        newsType: newses.newsType
                    }
                });
                this.setState({ newses: news_opt, count: result.count, loading: false });
            });
        }, 2000);
    }

    componentDidUpdate() { window.scrollTo(0, 0) }


    get_news = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_ALL_NEWS,
            variables: pagination,
            fetchPolicy: 'network-only'
            // fetchPolicy: 'cache-first'
        });
        return result.data.getNewses;
    };

    updateNewsStats = async (newsId) => {
        const result = await this.props.client.mutate({
          mutation: UPDATE_NEWS_STATS,
          variables: {newsId:newsId},
        });
        console.log(result.data);
        return result.data.updateNewsStats;
      };

    // loadAllNews = async () => {
    //     const {  pagination } = this.state;
    //     const { newsType,isActive, skip, first } = pagination;
    //     await this.props.client.query({
    //         query: GET_ALL_NEWS,
    //         variables:  {  newsType, isActive, skip, first },
    //         fetchPolicy: 'network-only'
    //     }).then(result => {
    //         console.log(result.data.getNewses.count);
    //         this.setState({
    //             loading: false,
    //             newses: result.data.getNewses.newses,
    //             count: result.data.getNewses.count
    //         });
    //     }).catch(error => {
    //         this.setState({ loading: false });
    //     });
    // }

    redirectToNews = (id) => {
        this.updateNewsStats(id).then(result => {
        this.props.history.push('/a_news?id=' + id);
    });
    }

    open_news_url = (id, url) => {
        this.updateNewsStats(id).then(result => {
            window.open(url);
        });
    }

    handlePagination = async (pageNo) => {
        this.setState({
            currentPage: pageNo
        })
        var newsId = this.state.newses.id;


        if (pageNo >= 0) {
            this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
            this.state.nextPage = pageNo + 1;
        }

        if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
            this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
            this.state.previousPage = pageNo - 1;
        }
        if (pageNo === 0) {
            this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
        }

        var paginations = {
            // newsId: newsId,
            newsType: 'IPPF_INTERNAL',
            skip: pageNo * this.state.ToDos,
            first: 16,
            lang:"en"
        };

        if (pageNo > 0) {
            this.get_news(paginations).then(result => {

                this.setState({ newses: result.newses, count: result.count });
            })
        } else {
            this.get_news(this.state.pagination).then(result => {
                this.setState({ newses: result.newses, count: result.count });
            })
        }
    }

    render() {
        const { newses, loading, loading_newses, count, nextPage, previousPage, currentPage, ToDos, isPrevBtnActive, isNextBtnActive, isFirstBtnActive, isLastBtnActive } = this.state;
        console.log(count);
        const en = english
        const fr = [{ firstTitle:"bonju" }]
        const ar = [{ firstTitle: "ه لمواجهة الظروف الأليمة، وأكرر بأنه لم"}, { secondTitle: "ه لمواجهة الظروف الأليمة، وأكرر بأنه لم"},{ thirdTitle: "ه لمواجهة الظروف الأليمة، وأكرر بأنه لم"},{ fourthTitle: "ه لمواجهة الظروف الأليمة، وأكرر بأنه لم"} ];
        const newsSec = localStorage.LANG === "fr" ? fr : localStorage.LANG === "ar" ? ar : en;
        console.log(newsSec)

        const pages = Math.ceil(count / ToDos);
        if (pages === 0) return null;
        var items = [];

        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }


        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled"><i className="fas fa-angle-left"></i> </span></li>
        } else {
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
        }

        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
        }
        else {
            renderNextBtn = <li className={isNextBtnActive}><a onClick={() => this.handlePagination(nextPage)} id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
        }

        let renderfirstBtn = null;
        if (isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
        } else {
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
        }

        let renderlastBtn = null;
        if (isLastBtnActive === 'disabled') {
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
        } else {
            renderlastBtn = <li className={isLastBtnActive}><a onClick={() => this.handlePagination(items.length - 1)} id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
        }

        return (
            <div className="uk-container">
                <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-margin uk-grid" uk-grid uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200 " >
                    {loading ? (
                        loading_newses.map((newses, i) => (
                            <Card_loading_news key={i} />
                        ))

                    ) : (
                            newses.map((newses, i) => (
                                <Card_news key={i} news_detail={newsSec} newses={newses} get_news={() => this.redirectToNews(newses.id)} open_news={() => this.open_news_url(newses.id, newses.url)} />
                            ))
                        )
                    }
                </div>

                {count >= 16 ? (
                    <div className="paginations uk-flex uk-flex-center">
                        <ul className="uk-pagination" uk-grid>
                            {renderfirstBtn}
                            {renderPrevBtn}

                            {
                                items.map((item, index) =>
                                    <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                                )
                            }

                            {renderNextBtn}
                            {renderlastBtn}
                        </ul>
                    </div>
                ) : (null)}
            </div>
        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(OurNews))); 

import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import {formatDate} from '../../../middleware/index';


//queries
import { GET_ALL_NEWS } from '../../../queries/common_queries';
import { UPDATE_NEWS } from '../../../queries/common_queries';
import { async } from 'q';
import { Item } from 'semantic-ui-react';
import { tsIndexSignature } from '@babel/types';


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class NewsList extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }

        this.state = {
            loading: false,
            newses: [],
            pagination: {
                skip: 0,
                first: 20,
                lang:localStorage.LANG
            },
            previousPage:0,
            nextPage:1,
            currentPage:0,
            activePage:'',
            ToDos:20,
            isPrevBtnActive:'disabled',
            isNextBtnActive:'',
            isFirstBtnActive:'disabled',
            isLastBtnActive:''
        }
        this.handleClick=this.handleClick.bind(this)
    }

    handleClick(event,index){
        this.setState({
            currentPage:Number(index),
            activePage: index
        })
    }

    componentDidMount() {
        this.get_all_news(this.state.pagination).then(result => {
            this.setState({ newses: result.newses, count: result.count });
        });
    }
    componentDidUpdate(){
        window.scrollTo(0,0)
    }

    handlePagination = async (pageNo) => {
        console.log(pageNo);
        this.setState({
            currentPage:pageNo
        });

        if(pageNo >=0){
            this.setState({ isNextBtnActive:'',isLastBtnActive:''})
            this.state.nextPage = pageNo + 1;
        }

        if(pageNo===Math.ceil(this.state.count / this.state.ToDos )-1){
            this.setState({ isNextBtnActive:'disabled',isLastBtnActive:'disabled'});
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive:'',isFirstBtnActive:''});
            this.state.previousPage = pageNo-1;
        }

        if(pageNo===0){
            this.setState({ isPrevBtnActive:'disabled',isFirstBtnActive:'disabled'});
        }
        
        var paginations = {
            skip: pageNo * this.state.ToDos,
            first: 20,
            lang:"en"

        };

        if (pageNo > 0) {
            this.get_all_news(paginations).then(result => {
                this.setState({ newses: result.newses, count: result.count });
            });
        } else {
            this.get_all_news(this.state.pagination).then(result => {
                this.setState({ newses: result.newses, count: result.count });
            });
        }
    }


    changeUserStatus(id, status) {

        var data = {};

        if (status) {
            data = { newsId: id, isActive: false };
        } else {
            data = { newsId: id, isActive: true };
        }

        this.setState({ loading: true });

        this.saveNewsStatus(data).then(result => {
            this.setState({ loading: false });
            this.loadNewses();
        }).catch(error => {
            console.log(error);
        });
    }

    get_all_news = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_ALL_NEWS,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getNewses;
    };

    saveNewsStatus = async (data) => {
        const result = await this.props.client.mutate({
            mutation: UPDATE_NEWS,
            variables: data
        });
        return result.data.editBasicProfile;
    };

    addNewNews() {
        this.props.history.push('/admin_add_news');
    }

    redirectToEditNews = (id) => {
        this.props.history.push('/admin_edit_news?id=' + id);
    }

    redirectToViewNews = (id) => {
        this.props.history.push('/a_news?id=' + id);
    }


    render() {

        const { newses, count, nextPage, previousPage,currentPage,ToDos,isPrevBtnActive,isNextBtnActive, isFirstBtnActive,isLastBtnActive} = this.state;
        const pages = Math.ceil(count / ToDos);
        console.log(newses)

        if (pages === 0) return null;

        var items = [];

        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }

        let renderPrevBtn = null;
        if(isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className="fas fa-angle-left"></i> </span></li>
        } else{
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={()=> this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
        }

        let renderNextBtn = null;
        if(isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
        }
        else{
            renderNextBtn = <li className={isNextBtnActive}><a onClick={()=> this.handlePagination(nextPage) } id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
        }

        let renderfirstBtn = null;
        if(isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
        } else{
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={()=> this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
        }

        let renderlastBtn = null ;
        if(isLastBtnActive==='disabled'){
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
        }else{
            renderlastBtn = <li className={isLastBtnActive}><a onClick={()=>this.handlePagination(items.length -1)}  id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
        }


        return (
            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fa fa-newspaper fa-2x uk-margin-right"></i>
                    <h4 className="uk-margin-remove adminSectionTitles"> News List </h4>
                </div>
                <div className="uk-flex-inline uk-float-right">
                    <button className="uk-button uk-button-primary admin-btn" onClick={() => this.addNewNews()}>Add News</button>
                </div>
                             
                <br /> <br />

                <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                        <thead>
                            <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                                <th style={{width: '35%'}}>News Title</th>
                                <th>News Type</th>
                                <th>Uploaded Date</th>
                                <th style={{width: '15%'}}>Link</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                newses.map((newses, i) => (
                                    <tr key={i}>
                                        <td> {newses.newsTitle}  </td>
                                        <td> {newses.newsType === "GLOBAL" ? "Global" : "Our News"   }  </td>
                                        <td> {formatDate(newses.createdAt)}  </td>
                                        <td>{ newses.url ? <a onClick={() => window.open(newses.url) }>View</a> : null } </td>
                                        <td className="uk-flex-inline uk-flex-middle">
                                            <button className="uk-button uk-button-warning admin-table-btn" uk-tooltip="Edit News" onClick={() => this.redirectToEditNews(newses.id)} ><i class='fas fa-pencil-alt'></i></button>
                                            {/* {newses.isActive ? (
                                                <button disabled={loading ? (true) : (false)} className="uk-button uk-button-danger admin-table-btn" uk-tooltip="Delete" onClick={() => this.changeUserStatus(newses.id, newses.isActive)}>
                                                    {loading ? (
                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                    ) :  <i class='fa fa-trash'></i>}
                                                </button>
                                            ) : (
                                                    <button disabled={loading ? (true) : (false)} className="uk-button uk-button-success admin-table-btn" uk-tooltip="Delete" onClick={() => this.changeUserStatus(newses.id, newses.isActive)}>
                                                        {loading ? (
                                                            <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                        ) :  <i class='fa fa-trash'></i>}
                                                    </button>
                                                )} */}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="paginations uk-flex uk-flex-center">
                    <ul class="uk-pagination" uk-margin>
                       {renderfirstBtn}
                       {renderPrevBtn}

                        {
                            items.map((item,index) =>
                                <li key={index} id={index}  active={this.state.activePage===index} onClick= {(e)=>this.handleClick(e,item)} className={currentPage===item? 'active': ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                            )
                        }

                      {renderNextBtn}
                      {renderlastBtn}
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(NewsList))); 
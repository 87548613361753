import * as React from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Button } from "react-uikit";


class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.state;
    const { message, confirmDelete, closeModal } = this.props;

    return (
      <div style={styles.background}>
        <div style={styles.background1}>
          <h4>{message}</h4>
          {/* <br /> */}
          <hr />
          <Button
            className="uk-button modaleConfirmButton"
            type="button"
            style={styles.confirmButton}
            //   disabled={rating === ""}
            onClick={()=> confirmDelete()}
          >
            Yes
          </Button>
          <Button
            className="uk-button uk-button-primary modaleCancelButton"
            type="button"
            style={styles.cancelButton}
            // disabled={rating === ""}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(withApollo(ConfirmationModal));

const styles = {
  background: {
    backgroundColor: "#0000005e",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  background1: {
    backgroundColor: "white",
    position: "absolute",
    // top: '0',
    right: "0",
    width: "40%",
    padding: "1rem",
    zindex: "100",
    marginRight: "28%",
    marginTop: "15%",
    borderRadius: "5px",
    textAlign: "center",
  },

  cancelButton: {
    width: "7rem",
    float: "right",
    marginRight: "0.8rem",
  },

  confirmButton: {
    width: "7rem",
    float: "right",
  },
};

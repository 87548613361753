import { UPLOAD_API_URL } from '../constants/index'
//firebase
import { uploadFile, deleteFile } from "../firebase/FileServices";

class CKUploadAdapter {
    loader = null;
    constructor(loader) {
        this.loader = loader;
    }
    
    upload() {
        return this.loader.file.then(file => new Promise((resolve, reject) => {
            this._sendRequest(resolve, file);
        }));
    }

    _sendRequest(resolve, file) {
        // const formData = new FormData();        
        // this._startDocUpload(this.state.formData);
        // formData.append('upload', file);
        if (file != "") {
            this._startDocUpload(resolve, file);
            // console.log(result);
            // fetch(`${UPLOAD_API_URL}/image-upload`, {
            //     method: 'POST',
            //     body: formData
            // }).then(res => {
            //     if (!res.ok) {
            //         throw res
            //     }
            //     return res.json()
            // }).then(images => {
            //     resolve({
            //         default: images[0].secure_url
            //     });
            // }).catch(err => {
            //     console.log(err)
            // })
        }
    }

    _startDocUpload = async (resolve, file) => {
        const date = new Date();
        const date_time = date.getTime();
        const result = await uploadFile(file, date_time);
        resolve({
            default: result.url
        });
        // console.log(result);
        // if (result.status) {
        //     this.setState({
        //         isUploading: false,
        //         fileRef: 'uploads/' + localStorage.FIRST_NAME + Math.floor(Math.random() * 100) + 1 + '/' + file.name,
        //         image: result.url,
        //         bannerIconUrl: result.url,
        //         isUploading: false,
        //     });
        // }
    };
}

export default CKUploadAdapter;
import { createStore, applyMiddleware, compose ,combineReducers } from 'redux';
import rootReducer from '../reducers/index';
import { reducer as reduxFormReducer } from 'redux-form';


const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const reducer = combineReducers({
  custom_reducer:rootReducer,
  form: reduxFormReducer, 
});

const store = (window.devToolsExtension
  ? window.devToolsExtension()(createStore)
  : createStore)(reducer);

export default store;

// import { createStore, applyMiddleware, compose } from 'redux';
// import rootReducer from '../reducers/index';
// import {loginFormMiddleware } from '../middleware/index';


// const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//     rootReducer,
//     storeEnhancers(applyMiddleware(
//         loginFormMiddleware, 
//         )),
//     );

// export default store;






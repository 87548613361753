import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
// import ReactStars from "react-rating-stars-component";
import Button from "react-uikit/lib/components/Button";
import { setModalStatus } from "../../actions/user-actions";

import { DELETE_USER } from "../../queries/user_queries";
import { _generate_email } from "../../middleware/index";


const mapStateToProps = (state, ownProps) => {
    return {
        ratingModalOpen: state.custom_reducer.ratingModalOpen,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setModalStatus: (status) => {
            dispatch(setModalStatus(status));
          },
    }
}


class UserdelateModal extends React.Component{
    constructor(props){
        super(props);
    const query = new URLSearchParams(this.props.location.search);
// console.log(this.props.UserID)

        this.state = {
            userID: this.props.UserID,
        }
    this.onCloseModal = this.onCloseModal.bind(this);
    }

    onCloseModal = () => {
        this.props.setModalStatus(false);
      };

      deleteUserAccount = async (userId) => {
          console.log(userId)
            const result = await this.props.client.mutate({
            mutation: DELETE_USER,
            variables: { userID:userId },
        })
        return result.data.deleteUser;
    };

    deleteUser = async (userID) => {
        console.log(userID);
        console.log("hghg");

        this.deleteUserAccount(userID).then(result=>{
            const email = result.email;
            const userName = result.firstName;
            const name = result.firstName + " " + result.lastName;
              const personalizations = {
                personalizations: [
                  {
                    to: [
                      {
                        email: email,
                        name: name,
                      },
                    ],
                    bcc: [
                        {
                          "email": "narthana.se@gmail.com",
                          "name": "Narthana"
                        },
                        {
                          "email": "chameera.lakshitha212@gmail.com",
                          "name": "Lakshitha"
                        },
                        {
                          "email": "rishi@fpasrilanka.org",
                          "name": "Rishikeshan Thiyagaraja"
                        }
                      ],
                    dynamic_template_data: {
                      name: userName,
                    },
                    subject: "Social Enterprise Academy – Your Account has been deleted!",
                  },
                ],
                // template_id:  "d-91e891fa7a344cbdb2e940eb24389f9f"
                template_id: result.languagePreference === 'en' ? "d-91e891fa7a344cbdb2e940eb24389f9f" 
                                    : result.languagePreference === 'ar' ? "d-6976a5dfc33f441ab93a834da832a98b" 
                                        : result.languagePreference === 'fr' ? "d-df34b2e61ac24b38a295093b46fbc651" 
                                            : result.languagePreference === 'sp' ? "d-4393b99f2804444786689481108b157e" 
                                                : "d-91e891fa7a344cbdb2e940eb24389f9f"
                // CHANGE 23/04/2021 

              };
              try {
                const data = _generate_email(
                  " https://seippf.org/mail/mail.php ",
                  personalizations
                );
                console.log(data);
              } catch (error) {
                console.error(error);
              }

              // this.setState({ open: false });
        this.props.setModalStatus(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        
        return result;

        })

        
      };

    render(){
        const { userID } = this.state;
        console.log(userID)
        return(
            <div className="deleteModalDiv">
                <form>
                   
                    <div className="deleteModal">
                    <div>
                        <h4> Are you sure you want to reject/delete this user? </h4>
                    </div>
                    <hr/>
                    <div className="deleteUser_buttons">
                        <Button
                        className="uk-button uk-button-primary cancelDeleteUserButton"
                        type="button"
                        onClick={() => this.onCloseModal()}
                        >
                        Cancel
                        </Button>
                        <Button
                        className="uk-button uk-button-primary sumbitDeleteUserButton"
                        type="button"
                        onClick={() => this.deleteUser(userID)}
                        >
                        Confirm
                        </Button>
                    </div>
                    </div>
                </form>
            </div>
        )
    }

}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withApollo(UserdelateModal))
  );
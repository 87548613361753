import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import LocationIcon from "../../../assets/images/locationIcon.png"
import TelephoneIcon from '../../../assets/images/TPIcon.png';
import EmailIcon from "../../../assets/images/EmailIcon.png"

class ContactContainer extends React.Component{
    constructor(props){
        super(props);
    }


    render(){
        return(  
             <div> 
                <div className="tm-header uk-visible@m tm-header-transparent">
                    <div media="768" animation="uk-animation-slide-top" cls-active="uk-navbar-sticky uk-nav-dark" sel-target=".uk-navbar-container" top=".tm-header ~ [class*=&quot;uk-section&quot;], .tm-header ~ * > [class*=&quot;uk-section&quot;]" cls-inactive="uk-navbar-transparent   uk-dark" className="uk-sticky uk-navbar-container">
                        <div className="uk-container  uk-position-relative">
                            <nav className="uk-navbar uk-navbar-transparent">

                                <div className="uk-navbar-left">
                             {/* //added on 3rd Feb 2022 */}
                                <Image className="uk-display-inline-block ippf-logo adm-logo" src={ require('../../../images/1_SE_IPPF_logo_1.png') } fluid />
                                <Image className="uk-display-inline-block ippf-logo adm-logo" src={ require('../../../images/2_SE_IPPF_logo_old_edited_color.png') } fluid />
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav toolbar-nav">
                                        <li>
                                        <a href="/">Home</a>
                                        </li>
                                        <li>
                                            {/* login route changed */}
                                        <a href="/#aboutus_section" >About Us</a>
                                        </li>
                                        <li>
                                        <a href="/contact" target="_blank">Contact Us</a>
                                        </li>
                                    </ul>
                                    <a className="el-content uk-button uk-button-success uk-circle" href="#"> Register </a>
                                    {" "}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
             

            <div className="uk-container">
                <div className="uk-margin-medium-top uk-grid-large" uk-grid>
                    <h2 className="uk-heading-line uk-margin-remove-top uk-text-left"><span> Contact Us </span></h2>
                </div>
            <div className="Grid-Inner-Contents uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"> 
                <div>
                    <div> <img className="IconSize" src={LocationIcon}/> </div>
                </div>
                <div>
                     <div> <img className="IconSize" src={TelephoneIcon}/> </div>
                </div>
                <div>
                     <div> <img className="IconSize" src={EmailIcon}/> </div>
                </div>

                <div>
                        <div> 
                            <p className="uk-text-lead" > <strong>IPPF CO </strong> </p>
                            4,Newham's Row,
                            <br/>
                            Bermondsey, 
                            <br/>
                            London SE1 3UZ,
                            <br/>
                            United Kingdom
                        </div>                
                </div>
                <div>
                        <div>
                        <p className="uk-text-lead" > <strong>Telephone </strong> </p>
                        +442079398200
                        </div>                   
                </div>

                {/* <div>
                        <div>
                        <p className="uk-text-lead" > <strong>Email  </strong> </p>
                        fpa@fpasrilanka.org
                        <br/>
                        info@fpasrilanka.org
                        </div>
                                       
                </div> */}
            </div>

           
              
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.9351454458947!2d79.86897211477279!3d6.898360395015404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc6ae13ce8c9af0d7!2sThe%20Family%20Planning%20Association%20of%20Sri%20Lanka!5e0!3m2!1sen!2slk!4v1574920132550!5m2!1sen!2slk" width="100%" height="450" frameborder="0" allowfullscreen=""></iframe>
             {/* src="https://goo.gl/maps/HzhmsjfPNsn2ERcz9"  */}

            </div>
                 
            
            <div className="Grid-Inner-Contents uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"> 
                <div>
                    <div> <img className="IconSize" src={LocationIcon}/> </div>
                </div>
                <div>
                     <div> <img className="IconSize" src={TelephoneIcon}/> </div>
                </div>
                <div>
                     <div> <img className="IconSize" src={EmailIcon}/> </div>
                </div>

                <div>
                        <div> 
                            <p className="uk-text-lead" > <strong>SE Hub </strong> </p>
                            37/27 Bullers Lane, 
                            <br/>
                            Colombo 7, 
                            <br/>
                            Sri Lanka.
                        </div>                
                </div>
                <div>
                        <div>
                        <p className="uk-text-lead" > <strong>Telephone </strong> </p>
                        +9411 255 5455
                            <br/>
                        +9411 258 0915 / 255 6611
                        </div>                   
                </div>
                <div>
                        <div>
                        <p className="uk-text-lead" > <strong>Email  </strong> </p>
                        fpa@fpasrilanka.org
                        <br/>
                        info@fpasrilanka.org
                        </div>                   
                </div>
            </div>

           
              
            <div style={pStyle}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.9351454458947!2d79.86897211477279!3d6.898360395015404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc6ae13ce8c9af0d7!2sThe%20Family%20Planning%20Association%20of%20Sri%20Lanka!5e0!3m2!1sen!2slk!4v1574920132550!5m2!1sen!2slk" width="100%" height="450" frameborder="0" allowfullscreen=""></iframe>
            </div>
            </div>

            </div>
        )
    }
}
const pStyle = {
    paddingBottom: '10px',
    
  };

export default withRouter(withApollo(ContactContainer));
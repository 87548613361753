import * as React from "react";
import { connect } from "react-redux";
import {
  CREATE_FLASH_QUIZ_SUBMISSION,
  UPDATE_FLASH_QUIZ_SUBMISSION,
} from "../../queries/user_queries";
import { withApollo } from "react-apollo";
import { setNextSubModuleStatus } from "../../actions/user-actions";
import { GET_ANNOUNCEMENTS } from "../../queries/common_queries";
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
  return {
    nextSubModuleDisabled: state.custom_reducer.nextSubModuleDisabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setNextButtonStatus: (status) => {
      dispatch(setNextSubModuleStatus(status));
    },
  };
};

class AnnouncementLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      flashQuizAnswers: [],
      correctAnswers: [],
      formSuccessMessage: "",
      formErrorMessage: "",
    };
  }

  componentDidMount() {
    this.get_all_announcements().then((result) => {
      console.log(result);
      if (result.length > 0) {
        this.setState({
          announcements: result,
        });
      }
    });
  }

  get_all_announcements = async () => {
    const result = await this.props.client.query({
      query: GET_ANNOUNCEMENTS,
      variables: {
        lang: localStorage.LANG || "en",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        isActive: true,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getAnnouncements.announcements;
  };

  render() {
    const {
      questions,
      subModule,
      nextSubModuleDisabled,
      submissionType,
      submissionStatus,
      submission,
    } = this.props;
    const { formErrorMessage, announcements } = this.state;
    // const en = [{description: "Test News 1 Lorem Ipsum is simply dummy text of the printing and typesetting industry."}, {description: "Test News 2 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}, {description: "Test News 3 It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."} ];
    // const fr = [{description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."}, {description: "Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500."}, {description: "Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié."},]
    // const ar = [{description: "ها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لمن."}, {description: "نتيجة لظروف ما قد تكمن السعاده فيما نتحمله م"}, {description: "الألم الذي ربما تنجم عنه بعض"} ];
    // const announcements = localStorage.LANG === "fr" ? fr : localStorage.LANG === "ar" ? ar : en;
    console.log(announcements)
    console.log(localStorage.LANG)
    return (
      <div style={{ backgroundColor: "yellow" }}>
        {announcements ? (
          announcements.length > 0 ? (
            <p class={localStorage.LANG=="ar"? "marquee_ar": "marquee" } >     
              <span>
                {announcements.map((announcement) => (
                  <React.Fragment>
                    {/* fa-flip-horizontal */}
                    <i className={localStorage.LANG== "ar" ? "fa fa-bullhorn fa-flip-horizontal"  : "fa fa-bullhorn" }/> &nbsp; - &nbsp;
                    <text className={localStorage.LANG=="ar"? "marquee_span_text_ar": "marquee_span_text"} >
                      {announcement.description}
                    </text>
                  </React.Fragment>
                ))}
              </span>
            </p>
          ) : null
        ) : null}
      </div>
    );
  }
}

// export default (withApollo(FlashQuiz));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AnnouncementLine));

const styles = {};

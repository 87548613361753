import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setModalStatus } from "../../actions/user-actions";
import { Form, Message } from "semantic-ui-react";
//ui-kit components

import { Button } from "react-uikit";

//Spinner
import Loader from "react-loader-spinner";

//queries
import { LOGIN_QUERY } from "../../queries/user_queries";
import { Image } from "semantic-ui-react";
import {
  GET_ANNOUNCEMENTS,
  GET_USER_RATINGS,
  UPDATE_USER_LOGGED_IN_STATUS,
} from "../../queries/common_queries";
import { withNamespaces } from 'react-i18next';


const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    ratingModalOpen: state.custom_reducer.ratingModalOpen,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setModalStatus: (status, type) => {
      dispatch(setModalStatus(status, type));
    },
  };
};

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        form_state: "default",
        title: "",
        message: "",
      },
      email: "",
      password: "",
      loading: false,
    };
    props.setFormStatus({ status: false, title: "", message: "" });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.setFormStatus({ status: false, title: "", message: "" });
    const { email, password } = this.state;

    if (!email == "" || !password == "") {
      this.loginUser()
        .then((result) => {
          if (result.data.login.user.isActive) {
            localStorage.ippfauthtoken = result.data.login.token;
            localStorage.USER_ID = result.data.login.user.id;
            localStorage.EMAIL = result.data.login.user.email;
            localStorage.FIRST_NAME = result.data.login.user.firstName;
            localStorage.LAST_NAME = result.data.login.user.lastName;
            localStorage.USER_TYPE = result.data.login.user.userType;
            // localStorage.LANG = "en";
            localStorage.RATING_LATER = false;
            localStorage.RATING_POPPED = 'not_popped';
            this.setState({ loading: false });
            if (result.data.login.user.loggedInAfterSignup != true) {
              this.updateUserLoggedInStatus(result.data.login.user.id).then(
                (result) => {
                  console.log(result)
                  this.props.history.push("/home");
                }
              );
            }
            else {
              this.props.history.push("/home");
            }


            this.getUserRatings().then((result) => {
              console.log(result);
              if (!result.userRatings.length > 0) {
                if (localStorage.RATING_LATER != true) {
                  console.log(localStorage.RATING_POPPED)
                  if (localStorage.RATING_POPPED === 'not_popped') {
                    setTimeout(() => {
                      this.props.setModalStatus(true, "random");
                      localStorage.RATING_POPPED = 'popped';
                    }, 600000);
                  }
                }
              }
            });


          } else {
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message:<div style={{paddingRight:"12px"}}>{this.props.t('account_not_approved')}</div>
            });
            this.setState({ email: "", password: "", loading: false });
            return;
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message: (
                <div style={{paddingRight:"12px"}} className={localStorage.LANG=='fr'? "incorrect_cred_styles":localStorage.LANG=='sp'? "incorrect_cred_styles" : ""}>
                  {this.props.t('incorrect_credinals')}
                  <br />
                  {this.props.t('chk_details')}

                  {/* Your Email or Password appears to be wrong! <br /> Please
                  check your account details and try again. */}
                </div>
              ),
            });
          }
          this.setState({ loading: false });
        });
    } else {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: this.props.t('fill_required_fields'),
      });
      this.setState({ email: "", password: "", loading: false });
      return;
    }
  }

  getUserRatings = async (rating) => {
    const result = await this.props.client.mutate({
      mutation: GET_USER_RATINGS,
      variables: {
        createdBy: localStorage.USER_ID,
      },
    });
    console.log(result.data);
    return result.data.getUserRatings;
  };

  loginUser = async () => {
    const { email, password } = this.state;
    const result = this.props.client.mutate({
      mutation: LOGIN_QUERY,
      variables: { email, password },
    });
    return result;
  };

  updateUserLoggedInStatus = async (userId) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_USER_LOGGED_IN_STATUS,
      variables: { userId: userId },
    });
    console.log(result.data);
    return result.data.updateUserLoggedInStatus;
  };

  redirectToRegister = () => {
    this.props.history.push("/registration");
  };
  forget_password = () => {
    this.props.history.push("/forgetPassword");
  };

///// custom validation message 
//  validate = (input) =>{

//     var validityState_object = input.validity;
  
//     console.log(validityState_object)
  
//     if (validityState_object.typeMismatch) {
//       input.setCustomValidity('Thats not an email!');
//       input.reportValidity();
//     } else {
//       input.setCustomValidity('');
//       input.reportValidity();
//     }

//     if (!input.validity.valid) {
//       input.setCustomValidity("This field cannot be left blank");
//   } 
//   }
//   componentDidMount() {
//     document.querySelector('#email', '#password').addEventListener('blur', e =>
//     this.validate(e.target)
// )

//   }

  
  render() {
    const { loading } = this.state;
    const { formErrorStatus} = this.props;
    const { t } = this.props;

    // console.log(formErrorStatus)

    return (
      <div>
        <Form error onSubmit={this.handleSubmit}>
          <h2 className="uk-text-bold uk-text-center loginTitle">
           { t('login')}
          </h2>
          <div className="uk-margin">
            {/* <i class="fa fa-envelope icon"></i> */}
            <input
              id="email"
              name="email"
              placeholder={t('email')}
              className="bo ll uk-input uk-width-1-1"
              type="email"
              onChange={this.handleChange}
              required
              
            />
          </div>

          <div className="uk-margin">
            <input
              className="placholdercolor bo ll uk-input uk-width-1-1"
              id="password"
              name="password"
              placeholder={t('password')}
              type="password"
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="semantic-font  uk-margin uk-text-center">
            <Button
              className="uk-button loginYellowButton "
              type="submit"
              disabled={loading ? true : false}
            >
              {t('signInButton')}
            </Button>
          </div>
          <div>
            {formErrorStatus.status == true ? (
              <div className="danger_alert">{formErrorStatus.message}</div>
            ) : (
              ""
            )}
          </div>
      <p class={localStorage.LANG=='fr'? "uk-text-center Login_text dont_hv_ac_style_fr": "uk-text-center Login_text"}  style={{ marginTop: "0px", marginBottom: "0px" }} >{t('dont_have_account')} <button class="uk-button align uk-button-text Login_text"
       onClick={()=>this.redirectToRegister()}
       >
         {t('register_here')}
          </button>
      </p>
      <p class="uk-text-center" style={{marginTop: "0px", marginBottom: "0px" }}>
        <button class="uk-button uk-button-text Login_text"
         onClick={() => this.forget_password()}
        >   {t('forgot_password')}
       </button>
      </p>
          {/* <div className="loginBoxTextDiv">
            <text className="loginBoxText">{login.forgot_password} </text>
            <text
              className="loginBoxText forgotPwButton"
              onClick={() => this.forget_password()}
            >
              {login.forgot_password}
            </text>
          </div> */}
        </Form>
        {this.state.loading ? <div className="spinner"></div> : ""}
      </div>
    );
  }
}
const styles = {
  darkBack: {
    backgroundColor: "rgba(0, 0, 0, 0.52)",
    backgroundBlendMode: "color-burn",
  },
  poweredBy: {
    bottom: "8px",
    right: "60px",
  },
};
export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(LoginComponent)))
);

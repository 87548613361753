import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../navbar/navbar';

//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';

import { withNamespaces } from "react-i18next";



class Quiz_banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
    const { t } = this.props;
        return (
            <div className={localStorage.LANG=="ar"? "topic1_ar hero-bg shadow-small": "topic1 hero-bg shadow-small"}>
                <div className="uk-grid">                
                    {localStorage.LANG=='ar'? (
                        <h3 style={{lineHeight:"40px", paddingTop:"12px"}} className="uk-light uk-text-uppercase uk-text-bold uk-text-white"> {t('test_knowledge')}<br/>{t('take_below_quiz')} </h3>           
                    ):(
                        <h3 style={{lineHeight:"40px"}} className="uk-light uk-text-uppercase uk-text-bold uk-text-white"> {t('test_knowledge')}<br/>{t('take_below_quiz')} </h3>           
                    )}
                </div>                 
            </div>
        )
    }
}

export default withNamespaces()(Quiz_banner); 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';


class HomeMenubarItem extends React.Component {
    constructor(props) {
        super(props);
    }

    _onLinkClick = (path,key) => {
        this.props.getActiveState(key);
        this.props.history.push(path);
    };

    render() {
        const { routes } = this.props;

        return (
            <li onClick={() => this._onLinkClick(this.props.path,this.props.key)}>
                <a> {this.props.name} </a>
            </li>
        )
    }
}

export default withRouter(withApollo((HomeMenubarItem))); 
import * as React from 'react'

class ContactBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="topic1 hero-bg2 shadow-small">
                <div className="uk-text-center ">
                    <h1 className=" uk-animation-slide-top-small uk-text-black"> Contact Us </h1>
                            <p className="uk-text-black uk-text-large"> We look forward to hearing from You.<br /> </p>
                </div>
                        {/* <h3 className="uk-light uk-text uk-text-bold uk-text-white"> Contact Information... </h3>            */}
            </div>
        )
    }
}

export default ContactBanner; 
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { isLoggedIn } from "../../../services/CacheOperations";
import { Image, Dropdown } from 'semantic-ui-react';
import Footer from '../../../components/footer/footer'
import ContactContainer from '../../../components/contact/contactContainer/contactContainer'
import ContactBanner from '../../../components/contact/contactBanner/contactBanner'
import { ippfauthtoken, USER_ID, EMAIL, FIRST_NAME, LAST_NAME, USER_TYPE } from '../../../constants/genarics';

import { withNamespaces } from 'react-i18next';
import i18n from '../../../i18n';
import { EDIT_BASIC_PROFILE } from '../../../queries/user_queries';

const languageOptions = [
    { text: "English", value: "en", key: "en" },
    { text: "العربية", value: "ar", key: "ar" },
    { text: "Français", value: "fr", key: "fr" },
    { text: "Español", value: "sp", key: "sp" },
];

localStorage.LANG = localStorage.LANG || "en";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            // Tawk Chat - Language Widgets
            s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
                : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
                    : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
                        : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
    }

    logout = () => {
        setTimeout(() => {
            localStorage.removeItem(ippfauthtoken);
            localStorage.removeItem(USER_ID);
            localStorage.removeItem(EMAIL);
            localStorage.removeItem(FIRST_NAME);
            localStorage.removeItem(LAST_NAME);
            localStorage.removeItem(USER_TYPE);
            {/* login route changed */ }
            this.props.history.push("/");
        }, 500)
    }

    // changeLanguage = async (e, { value }, data) => {
    //     localStorage.LANG = value;
    //     i18n.changeLanguage(value);
    //     window.location.reload();
    // };


    changeLanguage = async (e, { value }, data) => {
        localStorage.LANG = value;
        if (localStorage.USER_ID != undefined) {
            this.update_user_language(value).then((result) => {
                i18n.changeLanguage(value);
                window.location.reload();
            });
        }
        else {
            i18n.changeLanguage(value);
            window.location.reload();
        }
    };

    update_user_language = async (lang) => {
        const result = await this.props.client.mutate({
            mutation: EDIT_BASIC_PROFILE,
            variables: { userId: localStorage.USER_ID, languagePreference: lang }
        });
        return;
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="tm-header uk-visible@m tm-header-transparent">
                    <div
                        media="768"
                        animation="uk-animation-slide-top"
                        cls-active="uk-navbar-sticky uk-nav-dark"
                        sel-target=".uk-navbar-container"
                        top='.tm-header ~ [class*="uk-section"], .tm-header ~ * > [class*="uk-section"]'
                        cls-inactive="uk-navbar-transparent   uk-dark"
                        className="uk-sticky uk-navbar-container"
                    >
                        <div className=" uk-container  uk-position-relative">
                            {
                                localStorage.LANG == "ar" ? (
                                    <nav className="paddingHeader uk-navbar uk-navbar-transparent">

                                        <div className="lineHeight uk-navbar-right">
                                            <ul className=" uk-navbar-nav toolbar-nav">
                                                {/* Uncomment to enable language switcher in user site */}

                                                <li className="uk-text-capitalize">
                                                    <div style={{ position: "relative" }}>
                                                        <Dropdown
                                                            className="languageSwitcherLogin"
                                                            options={languageOptions}
                                                            value={localStorage.LANG}
                                                            onChange={this.changeLanguage}
                                                        />
                                                        <i
                                                            class="fa fa-caret-down langDropdownIcon"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                </li>
                                                {
                                                    isLoggedIn() ? (
                                                        <li style={{ marginRight: "60px" }} className="uk-text-uppercase">
                                                            <a href="/home"> {t('home')} </a>
                                                        </li>
                                                    ) : (
                                                        <li style={{ marginRight: "60px" }} className="uk-text-uppercase">
                                                            <a href="/">{t('loginButton')} </a>
                                                        </li>
                                                    )
                                                }
                                                <li>
                                                    <a target="_blank" href="/faq"> {t('faq')}</a>
                                                </li>
                                                <li className="uk-text-uppercase">
                                                    {/* login route changed */}
                                                    <a href="/#aboutus_section" target="_blank">{t('about')}</a>
                                                </li>
                                                <li className="uk-text-uppercase">
                                                    <a href="/contact">{t('contact')} </a>
                                                </li>

                                                {isLoggedIn() ? (
                                                    <li className="uk-text-uppercase">
                                                        <a onClick={() => this.logout()}>{t('logout')}</a>
                                                    </li>
                                                ) : ("")
                                                }
                                            </ul>

                                            {isLoggedIn() ? ("") : (
                                                <a
                                                    className="fontstyle el-content uk-button uk-button-success uk-circle"
                                                    href="registration"

                                                >
                                                    {" "}
                                                    {t('register')}{" "}
                                                </a>
                                            )}
                                        </div>
                                        <div className="uk-navbar-left">
                                            <a style={{float:"left"}} href="#" className="uk-logo black-logo">
                                                {/* <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/SE_IPPF_logo.png')} fluid /> */}
                                                {/* //added on 3rd Feb 2022 */}
                                                <Image style={{marginTop:"14.5px"}} className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/1_SE_IPPF_logo_1.png')} fluid />
                                                <Image style={{float:"right", paddingBottom:"2px"}} className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/2_SE_IPPF_logo_old_edited_color.png')} fluid />

                                            </a>
                                        </div>
                                    </nav>
                                ) : (
                                    <nav className="paddingHeader uk-navbar uk-navbar-transparent">
                                        <div className="uk-navbar-left">
                                            <a href="#" className="uk-logo black-logo">
                                                {/* <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/SE_IPPF_logo.png')} fluid /> */}
                                                {/* //added on 3rd Feb 2022 */}
                                                <Image className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/1_SE_IPPF_logo_1.png')} fluid />
                                                <Image float="right" className="ui fluid image uk-display-inline-block ippf-logo adm-logo" src={require('../../../images/2_SE_IPPF_logo_old_edited_color.png')} fluid />

                                            </a>
                                        </div>
                                        <div className="lineHeight uk-navbar-right">
                                            <ul className=" uk-navbar-nav toolbar-nav">
                                                {/* Uncomment to enable language switcher in user site */}

                                                <li className="uk-text-capitalize">
                                                    <div style={{ position: "relative" }}>
                                                        <Dropdown
                                                            className="languageSwitcherLogin"
                                                            options={languageOptions}
                                                            value={localStorage.LANG}
                                                            onChange={this.changeLanguage}
                                                        />
                                                        <i
                                                            class="fa fa-caret-down langDropdownIcon"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                </li>
                                                {
                                                    isLoggedIn() ? (
                                                        <li className="uk-text-uppercase">
                                                            <a href="/home"> {t('home')} </a>
                                                        </li>
                                                    ) : (
                                                        <li className="uk-text-uppercase">
                                                            <a href="/">{t('loginButton')} </a>
                                                        </li>
                                                    )
                                                }
                                                <li>
                                                    <a target="_blank" href="/faq"> {t('faq')}</a>
                                                </li>
                                                <li className="uk-text-uppercase">
                                                    {/* login route changed */}
                                                    <a href="/#aboutus_section" target="_blank">{t('about')}</a>
                                                </li>
                                                <li className="uk-text-uppercase">
                                                    <a href="/contact">{t('contact')} </a>
                                                </li>

                                                {isLoggedIn() ? (
                                                    <li className="uk-text-uppercase">
                                                        <a onClick={() => this.logout()}>{t('logout')}</a>
                                                    </li>
                                                ) : ("")
                                                }
                                            </ul>

                                            {isLoggedIn() ? ("") : (
                                                <a
                                                    className="fontstyle el-content uk-button uk-button-success uk-circle"
                                                    href="registration"

                                                >
                                                    {" "}
                                                    {t('register')}{" "}
                                                </a>
                                            )}
                                        </div>
                                    </nav>
                                )
                            }

                        </div>
                    </div>
                </div>

                <div className="contactStyle">
                    <div style={{ paddingTop: "130px", paddingBottom: "30px" }} className="uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light back" data-src="../assets/images/backgrounds/about.jpg" uk-img>
                        <div className="uk-width-1-1@m  uk-text-center">
                            <h1 className="semantic-font uk-animation-slide-top-small uk-text-white">
                                {t('h1title')}
                            </h1>
                            <p className="uk-text-white uk-text-large">
                                {t('p_title')}<br />
                            </p>
                        </div>
                    </div>
                    <div className="uk-section-default uk-section ">
                        <div className="uk-margin-auto uk-container-small uk-width-1-2@m uk-text-center">
                            <p style={{ textAlign: "justify" }}> <a href="" style={{ color: "orange" }} ></a> {t('p1')} </p>
                            <p>{t('p2')}</p>
                            <p>{t('p3')} </p>
                            <p><h5>{t('h5')} </h5></p>
                            
                            <p className="uk-text-center ContactTittle"><strong> {t('p4')} </strong></p>
                            <div className="uk-child-width-1-2@s uk-grid-match uk-grid">
                                <div>
                                    <div className="linkEffectHover uk-card uk-card-hover uk-card-body uk-light" style={{ backgroundColor: "#fff", fontSize: "16px", padding: "20px 20px" }}>
                                        <h3 className="uk-card-title" style={{ color: "#000", minHeight: "45px" }} >{t('jobTitle_one')} </h3>
                                        <p  ><span className="far fa-user" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG == 'ar' ? "contact_text_Arabic" : "contact_text"}>Rishikeshan Thiyagaraja (Rishi)</span></p>
                                        <a href="mailto:rishi@fpasrilanka.org" target="_top" ><span className="far fa-envelope" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG == 'ar' ? "hoverlink contact_text_Arabic" : "hoverlink contact_text"}  >rishi@fpasrilanka.org</span></a>
                                        <p><span className="fab fa-skype" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG == 'ar' ? "contact_text_Arabic" : "contact_text"}>rishikeshant</span></p>
                                        <p><span className="fas fa-mobile-alt" style={{ color: "#000", paddingRight: "5px" }}></span>
                                            {localStorage.LANG == 'ar' ? (
                                                <span className="contact_text_Arabic">94773783865+</span>
                                            ) : (
                                                <span className="contact_text">+94773783865</span>
                                            )}
                                            {/* <span className={localStorage.LANG=='ar'? "contact_text_Arabic":"contact_text" }>94773783865+</span> */}
                                        </p>
                                        {localStorage.LANG == 'ar' ? (


                                            <p><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span>
                                                {/* {localStorage.RAT_LANG=='ar'?( */}
                                                <span className="contact_text_Arabic">94112555455+ (تحويلة 217)</span>
                                                {/* ):(
                                                <span className="contact_text">+94112555455 (Ext 217)</span>
                                            )
                                                    } */}
                                            </p>
                                        ) : (

                                            <p><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span>
                                                {/* {localStorage.RAT_LANG=='ar'?(
                                                 <span className="contact_text_Arabic">94112555455+ (تحويلة 217)</span>
                                            ):( */}
                                                <span className="contact_text">+94112555455 (Ext 217)</span>
                                                {/* )
                                                    } */}
                                            </p>
                                        )
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="linkEffectHover uk-card uk-card-hover uk-card-body uk-light" style={{ backgroundColor: "#fff", fontSize: "16px", padding: "20px 20px" }}>
                                        <h3 className="uk-card-title" style={{ color: "#000", paddingRight: "5px", minHeight: "45px" }}>{t('jobTitle_two')}</h3>
                                        <p><span className="far fa-user" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG == 'ar' ? "contact_text_Arabic" : "contact_text"}>Manjula Liyanage (Manjula)</span></p>
                                        <a href="mailto:manjula@fpasrilanka.org" target="_top" ><span className="far fa-envelope" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG == 'ar' ? "contact_text_Arabic hoverlink" : "contact_text hoverlink"}>manjula@fpasrilanka.org</span></a>
                                        <p><span className="fab fa-skype" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG == 'ar' ? "contact_text_Arabic" : "contact_text"}>manjula.chathuranga</span></p>
                                        <p><span className="fas fa-mobile-alt" style={{ color: "#000", paddingRight: "5px" }}></span>
                                            {localStorage.LANG == 'ar' ? (
                                                <span className="contact_text_Arabic">94714072631+</span>
                                            ) : (
                                                <span className="contact_text">+94714072631</span>
                                            )}

                                        </p>
                                        {localStorage.LANG == 'ar' ? (

                                            <p><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span>
                                                {/* {localStorage.RAT_LANG == 'ar' ? ( */}
                                                <span className="contact_text_Arabic">94112555455+ (تحويلة 217)</span>
                                                {/* ) : (
                                                    <span className="contact_text">+94112555455 (Ext 217)</span>
                                                )} */}

                                            </p>
                                            // <p style={{display:"flex"}} className={localStorage.LANG=="ar"? "Arabic_contact_para":""}><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG=='ar'? "contact_text_Arabic":"contact_text" }>

                                            //     {localStorage.LANG=="ar"? (
                                            //         <div>94112555455+ (تحويلة 217)</div>
                                            //     ):(
                                            //         <div>+94112555455 (Ext 217)</div>
                                            //     )}
                                            //    </span></p>
                                        ) : (
                                            // <p style={{ display: "flex", paddingLeft: "45px" }} className={localStorage.LANG == "ar" ? "Arabic_contact_para" : ""}><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.LANG == 'ar' ? "contact_text_Arabic" : "contact_text"}>
                                            //     {localStorage.LANG == "ar" ? (
                                            //         <div>94112555455+ (تحويلة 217)</div>
                                            //     ) : (
                                            //         <div>+94112555455 (Ext 217)</div>
                                            //     )}
                                            // </span></p>

                                            ///added 19 AUG
                                            <p><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span>
                                                {/* {localStorage.RAT_LANG == 'ar' ? (
                                                    <span className="contact_text_Arabic">94112555455+ (تحويلة 217)</span>
                                                ) : ( */}
                                                <span className="contact_text">+94112555455 (Ext 217)</span>
                                                {/* )
                                                } */}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-container uk-width-1-3@m ">
                            <div className="uk-margin-auto uk-container-small">
                                <div className="uk-column-1-2 uk-column-divider ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer language={localStorage.LANG} />
            </div>
        )
    }
}
export default withRouter(withApollo(withNamespaces()(Contact)));
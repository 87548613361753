import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

class HelpContainer extends React.Component{
    constructor(props){
        super(props);
    }

        render(){
            return(
                <div className="uk-container">
                <div className="uk-margin-medium-top uk-grid-large" uk-grid>
                                <div className="uk-card uk-card-default uk-card-small uk-card-body tm-ignore-container " >
                                    <h4> Quick Start using IPPF </h4>
                                    <p className="uk-margin-remove-top uk-margin-small-bottom">  Hello dear visitor, if you want to know what exactly is going on at <b>IPPF </b> , you're at the right place to get some concrete answers ! </p> 
                                </div>
                                <div className="uk-card uk-card-default uk-card-small uk-card-body tm-ignore-container" >
                                    <h4> Resources </h4>
                                    <p className="uk-margin-remove-top uk-margin-small-bottom"> Nulla nec eleifend ante. Nunc suscipit suscipit felis eget efficitur. Nulla eget ante vestibulum, accumsan erat et, pulvinar mauris. Maecenas commodo vestibulum vestibulum. Vestibulum eget odio fringilla, suscipit sem a, fringilla metus. Pellentesque tincidunt justo et tincidunt porttitor. Nunc enim turpis, vulputate quis augue sed, semper ornare est. Vestibulum augue dui, tincidunt in mollis quis, egestas vel diam.</p> 
                                </div>       
                                <div className="uk-card uk-card-default uk-card-small uk-card-body tm-ignore-container " >
                                    <h4> Licences</h4>
                                    <p className="uk-margin-remove-top uk-margin-small-bottom">  Donec feugiat nisi vel lorem elementum, tempus bibendum erat pharetra. Ut at mauris in dolor mattis congue non eget justo. Nulla non rutrum justo. In et turpis elit. Quisque pellentesque risus eu malesuada faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque in facilisis massa, nec placerat nisl. Donec nec porta leo !</p> 
                                </div>                         
                </div>
                </div>
            )
        }
  
}
export default withApollo(withRouter(HelpContainer)) ;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/ippf_styles.scss';

// import "semantic-ui-css/semantic.min.css";
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store/store";
import { Provider } from "react-redux";
//import { createHTTPLink, createHttpLink } from "apollo-link-http";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { persistCache } from "apollo-cache-persist";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter,Route } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-dropdown/style.css'
// import "semantic-ui-css/semantic.min.css";
import './i18n';

// import "./assets/css/main.css";
// import './assets/css/admin.css';
// import './assets/css/uikit.css';
// import "./assets/css/fontawesome.css";

import './custom.css';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
// const { google } = require('googleapis')

import Timer from './components/Timer';

const httpLink = createHttpLink({

    uri:"https://kmsback.skotie.com/" // Live

    // uri:"http://161.35.207.178:4000" // staging
    
});


// const wsLink = new WebSocketLink({
//     uri: `ws://ippf.herokuapp.com/`,
//     options: {
//       reconnect: true,
//     },
//   });

const cache = new InMemoryCache();
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("ippfauthtoken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// const link = split(
//     ({ query }) => {
//       const { kind, operation } = getMainDefinition(query)
//       return kind === 'OperationDefinition' && operation === 'subscription'
//     },
//     wsLink,
//     authLink.concat(httpLink)
//   );

const client = new ApolloClient({
    // link: link,
    link: authLink.concat(httpLink),
    cache
});

persistCache({
    cache:client.cache,
    storage: window.localStorage,
    debug:true,
});
// /production/ippf-stag
// ""
ReactDOM.render(
    <BrowserRouter basename="">
        <ApolloProvider client={client}>
            <Provider store={store}>
                <App/>
                {/* <Timer/> */}
            </Provider>
        </ApolloProvider>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



// A new module is now available 
// A new resource is now available
// News article released
// A new gallery is now available 
import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import Page from '../../../components/certificate/page.js';
import Moment from 'react-moment';
import BannerImg from "../../../assets/images/backgrounds/kms-image-ori.png";
import Arabic_image from "../../../images/Certificate_Design_-_Arabic_Final-01.png";
import French_image from "../../../images/French.jpeg";
import Spanish_image from '../../../images/Spanish.jpeg';
import CertificateDesign3 from '../../../images/CertificateDesign3.png'

// import 'moment/locale/fr';
// import 'moment/locale/es';

//components
import Certificate from "../../../components/certificate/certificate";
// import PrintButton from "../../../components/certificate/printButton";
// import PrintButton_ar from "../../../components/certificate/printButton_ar";
// import PrintButton_fr from "../../../components/certificate/printButton_fr";
// import PrintButton_es from "../../../components/certificate/printButton_es";

import CertificateBanner from "../../../components/certificate/certificate_banner";
import Navbar from "../../../components/navbar/navbar";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import moment from "moment";
//query
import { GET_USER_PROFILE } from "../../../queries/common_queries.js";
import { GET_MODULE } from "../../../queries/common_queries.js";
import { GET_USER_RESULT } from "../../../queries/user_queries";
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};
const thStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal"
};
class Quizz_Container extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(-500, -200);
    const query = new URLSearchParams(this.props.location.search);
    const moduleid = query.get("id");
    this.getUserResult().then((result) => {
      // console.log(result);
      const dates = result.modules.map((a) =>
        a.result[0] ? a.result[0].updatedAt : new Date()
      );
      console.log(dates);
      const sort_dates = dates.sort(function (a, b) {
        return new Date(b) - new Date(a);
      });
      console.log(sort_dates[0]);
      this.setState({ completed_date: sort_dates[0] });
    });

    this.state = {
      userId: localStorage.USER_ID,
      completed_date: "",
      pagination: {
        moduleId: moduleid,
        // isActive: true,
        // skip: 0,
        // first: 20,
        lang: localStorage.LANG
      },
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.get_user().then((result) => {
      console.log(result);
      this.setState({
        name: result.firstName + " " + result.lastName,
        organization:
          result.fullName === "NA"
            ? result.region + " Regional Office"
            : result.fullName,
      });
    });

    this.get_module(this.state.pagination).then((moduleDtailes) => {
      this.setState({
        moduleName: moduleDtailes.moduleTitle,
      });
    });
  }

  //get user name
  get_user = async () => {
    const result = await this.props.client.query({
      query: GET_USER_PROFILE,
      fetchPolicy: "network-only",
    });
    return result.data.getUserProfile;
  };

  //get module details
  get_module = async (pagination) => {
    const moduleDtailes = await this.props.client.query({
      query: GET_MODULE,
      variables: pagination,
      fetchPolicy: "network-only",
    });
    return moduleDtailes.data.getModule;
  };

  getUserResult = async () => {
    const result = await this.props.client.query({
      query: GET_USER_RESULT,
      variables: {
        lang: localStorage.LANG,
      },
      fetchPolicy: "network-only",
    });
    return result.data.getUserResult;
  };

  issueCertificate() {
    console.log("hello");
    var page =
      '<html><table border="1" style="border-spacing:0;width:100%">' +
      document.getElementById("singlePage").html +
      "</table></html>";
    var w = window.open();
    w.document.write(page);
    w.print();
    w.close();
  }

  render() {
    const { name, moduleName, completed_date, organization } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Navbar />
        <div id="spinneroverlay">
          <div className="spinner"></div>
        </div>
        <div className="app">
          <Header />
          <CertificateBanner />
          <div
            class="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read bg-black-80 w-100 pv5"
            uk-scrollspy="target: > a; cls:uk-animation-slide-bottom-small; delay: 200"
          >
            {/* <button onClick={() => this.issueCertificate()}>Download</button> */}
            {localStorage.LANG == "ar" ? (
              //   <PrintButton_ar
              //   id={"singlePage"}
              //   label={t('download_certificate')}
              //   userName={name}
              //   moduleName={moduleName}
              //   completed_date={completed_date}
              //   organization={organization}
              //   lang={localStorage.LANG}
              // />

              //Commented on 15th of December
              <div>
                <ReactToPrint
                  trigger={() => 
                  <button class="uk-button uk-button-primary" uk-tooltip={t('download_certificate')}>{t('download_certificate')} <i class="fa fa-certificate" aria-hidden="true"></i></button>}
                  content={() => this.componentRef}
                />
                <div style={{ display: "none" }} > 
                <PrintButton_ar ref={(el) => (this.componentRef = el)} userName={name} organization={organization} completed_date={completed_date} />
                </div>
              </div>

            ) : (
              localStorage.LANG == "fr" ? (
                // <PrintButton_fr
                //   id={"singlePage"}
                //   label={t('download_certificate')}
                //   userName={name}
                //   moduleName={moduleName}
                //   completed_date={completed_date}
                //   organization={organization}
                // />
                <div>
                <ReactToPrint
                  trigger={() => 
                  // <button className="uk-button uk-button-primary" uk-tooltip={t('download_certificate')}>تحميل الشهادة</button>}
                  <button class="uk-button uk-button-primary" uk-tooltip={t('download_certificate')}>{t('download_certificate')} <i class="fa fa-certificate" aria-hidden="true"></i></button>}
                  content={() => this.componentRef}
                />
                <div style={{ display: "none" }} > 
                <PrintButton_fr 
              
                ref={(el) => (this.componentRef = el)} userName={name} organization={organization} completed_date={completed_date} />
                </div>
              </div>

              ) : (
                localStorage.LANG == "sp" ? (
                  // <PrintButton_es
                  //   id={"singlePage"}
                  //   label={t('download_certificate')}
                  //   userName={name}
                  //   moduleName={moduleName}
                  //   completed_date={completed_date}
                  //   organization={organization}
                  // />
                  <div>
                <ReactToPrint
                  trigger={() => 
                  // <button className="uk-button uk-button-primary" uk-tooltip={t('download_certificate')}>تحميل الشهادة</button>}
                  <button class="uk-button uk-button-primary" uk-tooltip={t('download_certificate')}>{t('download_certificate')} <i class="fa fa-certificate" aria-hidden="true"></i></button>}
                  content={() => this.componentRef}
                />
                <div style={{ display: "none" }} > 
                <PrintButton_es 
              
                ref={(el) => (this.componentRef = el)} userName={name} organization={organization} completed_date={completed_date} />
                </div>
              </div>
                ) : (
                  //Commented on 17th December 2021
                  // <PrintButton
                  //   id={"singlePage"}
                  //   label={t('download_certificate')}
                  //   userName={name}
                  //   moduleName={moduleName}
                  //   completed_date={completed_date}
                  //   organization={organization}
                  // />

                  <div>
                  <ReactToPrint
                    trigger={() => 
                    // <button className="uk-button uk-button-primary" uk-tooltip={t('download_certificate')}>تحميل الشهادة</button>}
                    <button class="uk-button uk-button-primary" uk-tooltip={t('download_certificate')}>{t('download_certificate')} <i class="fa fa-certificate" aria-hidden="true"></i></button>}
                    content={() => this.componentRef}
                  />
                  <div style={{ display: "none" }} > 
                  <PrintButton
                
                  ref={(el) => (this.componentRef = el)} userName={name} organization={organization} completed_date={completed_date} />
                  </div>
                </div>
                )

              )


            )}

            <br />
            <Certificate
              id={"singlePage"}
              userName={name}
              moduleName={moduleName}
              completed_date={completed_date}
              organization={organization}
            />
          </div>
          <Footer language={localStorage.LANG} />
        </div>
      </div>
    );
  }
}

class PrintButton_ar extends React.Component {
  constructor(props) {
    super(props);

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = '-';

    this.state = {
      currentDate: `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }
  }

  componentDidMount() {

  }


  render() {

    const { id, userName, moduleName, completed_date, organization } = this.props;
    return (
      <div class="container_arabic_c" dir="rtl">
        <img src={Arabic_image} />
        <div class="top-right">{this.props.userName}</div>
        <div class="top-right_org">{this.props.organization}</div>
        <div class="top-right_date">  {moment(this.props.completed_date, "YYYY-MM-DD").format("YYYY-MM-DD")}
          {/* <Moment format="YYYY Do MMMM">{this.props.completed_date}</Moment> */}
          </div>

        {/* <div className="certificate_ar" style={{position:"relative"}} >
                          <div className="certifi-cantainer">
                              <div style={{paddingRight:"2rem"}}  className="certifi-user-name">
                                  <p>{name}</p>
                              </div>
                              <br />
                              <div style={{paddingRight:"2rem"}}  className="certifi-compelte-course_ar" >
                                  <p>{organization}</p>
                              </div>
                              <div className="certifi-compelte-Date_ar">
                                  <Moment format="Do MMMM YYYY">{completed_date}</Moment>
                              </div>
                          </div>
              </div> */}

      </div>

    )
  }
}



class PrintButton_fr extends React.Component {
  constructor(props) {
    super(props);

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = '-';

    this.state = {
      currentDate: `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }
  }

  componentDidMount() {

  }
  render() {
    const { id, userName, moduleName, completed_date, organization } = this.props;
    return (
      <div className="container_Others_c">
        <img src={French_image} />
        <div class="top-right_others">{this.props.userName}</div>
        <div class="top-left_org">{this.props.organization}</div>
        <Moment class="top-left_date" locale="Fr" format="Do MMMM YYYY">{completed_date}</Moment>

        {/* <div class="top-left_date">  {moment(this.props.completed_date, "DD-MM-YYYY").format("DD-MM-YYYY")}
          </div> */}
      </div>
    )
  }
}




class PrintButton_es extends React.Component {
  constructor(props) {
    super(props);

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = '-';

    this.state = {
      currentDate: `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }
  }

  componentDidMount() {

  }
  render() {
    const { id, userName, moduleName, completed_date, organization } = this.props;
    var localLocale = moment(this.state.completed_date);
moment.locale('es');
localLocale.locale(false);
// alert(localLocale.format('LL')); 

console.log(moment(this.state.completed_date).locale('es').format('LLLL'))

    return (
      <div className="container_Others_c">
        <img src={Spanish_image} />
        <div class="top-right_others">{this.props.userName}</div>
        <div class="top-left_org_sp">{this.props.organization}</div>
        {/* <div class="top-left_date_sp">  {moment(this.props.completed_date, "DD-MM-YYYY").format("DD-MM-YYYY")}
          </div> */}
          <div className="top-left_date_sp">{localLocale.format('LL')}</div>
          {/* <Moment class="top-left_date_sp" locale="es" format="Do MMMM YYYY" >{completed_date}</Moment> */}
          
      </div>
    )
  }
}




class PrintButton extends React.Component {
  constructor(props) {
    super(props);

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = '-';

    this.state = {
      currentDate: `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }
  }

  componentDidMount() {

  }
  render() {
    const { id, userName, moduleName, completed_date, organization } = this.props;
    return (
      <div className="container_Others_c">
        <img src={CertificateDesign3} />
        <div class="top-right_others">{this.props.userName}</div>
        <div class="top-left_org_sp">{this.props.organization}</div>
        {/* <div class="top-left_date_sp">  {moment(this.props.completed_date, "DD-MM-YYYY").format("DD-MM-YYYY")}
          </div> */}
          <Moment class="top-left_date_en" locale="en-gb" format="Do MMMM YYYY">{completed_date}</Moment>
      </div>
    )
  }
}


export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Quizz_Container)))
);

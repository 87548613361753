import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';


//queries
import { CREATE_LESSON, CREATE_CONTENT, GET_MODULES_FOR_ADMIN, GET_SUB_MODULES_FOR_ADMIN, UPDATE_LESSON, UPDATE_CONTENT } from '../../../queries/admin_queries';
import { GET_LESSON, GET_CONTENT } from '../../../queries/common_queries';


import { Button } from 'react-uikit';

import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import { uploadFile, deleteFile } from "../../../firebase/FileServices";
import { Input } from "semantic-ui-react";


//constants
import { UPLOAD_API_URL } from '../../../constants/index'

//Image Uploader
import Images from '../Image/Images'

import CKUploadAdapter from '../../../middleware/ck-file-upload-adapter'


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddLesson extends React.Component {
    constructor(props) {
        super(props);
        if(localStorage.USER_ID==undefined){
            this.props.history.push('/');
        }else{
            if(localStorage.USER_TYPE=="MAUSER"){
                this.props.history.push('/');
            }
        }
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        if (id == null) {
            this.state = {
                data: null,
                modules: [],
                submodules: [],
                moduleId: '',
                subModuleId: '',
                lessonId: "",
                lessonTitle: '',
                lessonBannerIconUrl: '',
                lessonBannerColor: '',
                lessonHelperText: '',
                lessonOverview: '',
                isActive: true,
                formAction: "ADD",
                loading: false,
                image: "",
                formData: "",
                file: '', imagePreviewUrl: '',
                pagination: {
                    isActive: true
                },
                pagination_submodules: {
                    isActive: true
                },
                pagination_content: {
                    isActive: true
                },
                isUploading: false
            };
        } else {
            this.state = {
                data: null,
                modules: [],
                submodules: [],
                moduleId: '',
                subModuleId: '',
                lessonId: id,
                contentId: "",
                lessonTitle: '',
                lessonBannerIconUrl: '',
                lessonBannerColor: '',
                lessonHelperText: '',
                lessonOverview: '',
                isActive: true,
                formAction: "EDIT",
                loading: false,
                getModuleParameter: { moduleId: id },
                image: "",
                image_file: "",
                formData: "",
                file: '',
                pagination: {
                    isActive: true
                },
                pagination_submodules: {
                    isActive: true
                },
                pagination_content: {
                    lessonId: id,
                    isActive: true
                },
                isUploading: false

            };
        }


        console.log(this.props)

        this.handleChange = this.handleChange.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {

        this.setState({ loading: true });
        if (this.state.formAction == "EDIT") {
            this.getSingleLesson();
        } else {
            this.setState({ loading: false });
        }

        this.get_modules(this.state.pagination).then(result => {
            var module_opt = [];
            result.modules.map(module => (
                module_opt.push({ key: module.id, value: module.id, id: 'moduleId', label: module.moduleTitle })
            ));
            this.setState({ modules: module_opt });
        })

    }

    documentUploadOnClick = event => {
        this.setState({
            isUploading: true,
        });
        console.log(this.state.formData);
        this._startDocUpload(this.state.formData);
    };

    documentUploadOnChange =event => {
        this.setState({
            formData: event.target.files[0],
        });
    }

    _startDocUpload = async file => {
        var intDate = Number(new Date());

        const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
        if (result.status) {
            this.setState({
                isUploading: false,
                fileRef: 'uploads/' + localStorage.FIRST_NAME + intDate + '/' + file.name,
                image: result.url,
                lessonBannerIconUrl: result.url,
                isUploading: false,
            });
        }
    };

    _deleteFile = async (path) => {
        console.log(path)
        const deleteRef = await deleteFile(path);
        this.setState({image:"",lessonBannerIconUrl:""});
        return deleteRef;
    }


    getSingleLesson() {
        this.get_lesson(this.state.lessonId).then(result => {
            this.get_content(this.state.pagination_content).then(res => {
                this.setState({
                    moduleId: result.moduleId,
                    subModuleId: result.subModuleId,
                    lessonTitle: result.lessonTitle,
                    lessonBannerIconUrl: result.lessonBannerIconUrl,
                    image: result.lessonBannerIconUrl,
                    lessonBannerColor: result.lessonBannerColor,
                    lessonHelperText: result.lessonHelperText,
                    lessonOverview: result.lessonOverview,
                    data: res.contents.contents[0].content,
                    contentId: res.contents.contents[0].id,
                    loading: false
                });
            })
        })
    }

    onEditorChange(event, editor, data) {
        this.setState({
            data: data
        });
        console.log(data)
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleModuleChange = (event) => {
        var moduleId = event.target.value;
        this.get_submodules(moduleId).then(result => {
            console.log(result)
            var submodule_opt = [];
            result.subModules.map(submodule => (
                submodule_opt.push({ key: submodule.id, value: submodule.id, id: 'subModuleId', label: submodule.subModuleTitle })
            ));
            this.setState({ submodules: submodule_opt, "moduleId": moduleId });
        })
    };

    handleSubModuleChange = (event) => {
        this.setState({ "subModuleId": event.target.value });
    };

    filter = id => {
        return this.state.images.filter(image => image.public_id !== id)
    }

    removeImage = id => {
        this.setState({ images: this.filter(id) })
    }

    onError = id => {
        this.setState({ images: this.filter(id) })
    }


    handleSubmit = (event) => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.setState({ loading: true });

        const { lessonTitle, lessonBannerIconUrl, lessonHelperText, lessonOverview, moduleId, data } = this.state;

        if (moduleId == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please select a module." });
        } else if (lessonTitle == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please add a lesson title." });
        } else if (lessonBannerIconUrl == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please upload a banner image." });
        } else if (lessonHelperText == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please add helper text." });
        } else if (data == null) {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please add the body content for the lesson." });
        } else {


            if (this.state.formAction == "ADD") {

                this.createLesson().then(result => {
                    this.createContent(result.id).then(result => {

                        this.setState({
                            data: null,
                            loading: false,
                            lessonTitle: '',
                            lessonBannerIconUrl: '',
                            lessonBannerColor: '',
                            lessonHelperText: '',
                            lessonOverview: '',
                        });

                        this.props.setSuccessStatus({
                            status: true,
                            title: "",
                            message:
                                "Lesson created successfully!"
                        });

                    }).catch(error => {
                        console.log(error);
                        this.setState({ loading: false });
                        this.props.setFormStatus({ status: true, title: "Oops!", message: "An error occurred while trying to create the content for the lesson. Please check the content and try again!" });
                    });

                }).catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                    this.props.setFormStatus({ status: true, title: "Oops!", message: "There was an error while trying to create the lesson." });
                });

            } else if (this.state.formAction == "EDIT") {


                this.updateLesson().then(result => {
                    this.updateContent(this.state.contentId).then(result => {

                        this.getSingleLesson();

                        this.props.setSuccessStatus({
                            status: true,
                            title: "",
                            message:
                                "Lesson updated successfully."
                        });

                    }).catch(error => {
                        console.log(error);
                        this.setState({ loading: false });
                        this.props.setFormStatus({ status: true, title: "Oops!", message: "An error occurred while trying to update the content for the lesson. Please check the content and try again!" });
                    });

                }).catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                    this.props.setFormStatus({ status: true, title: "Oops!", message: "An error occurred while trying to update the lesson!" });
                });

            }

        }
    }

    createLesson = async () => {
        const { lessonTitle, lessonBannerIconUrl, lessonBannerColor, lessonHelperText, lessonOverview, moduleId, subModuleId, isActive } = this.state;

        var data_arr = {};
        if (subModuleId != '') {
            data_arr = { lessonTitle, lessonBannerIconUrl, lessonBannerColor, lessonHelperText, lessonOverview, subModuleId, isActive };
        } else {
            data_arr = { lessonTitle, lessonBannerIconUrl, lessonBannerColor, lessonHelperText, lessonOverview, moduleId, isActive };
        }

        const result = await this.props.client.mutate({
            mutation: CREATE_LESSON,
            variables: data_arr
        });
        return result.data.createLesson;
    };

    updateLesson = async () => {
        const { lessonId, lessonTitle, lessonBannerIconUrl, lessonBannerColor, lessonHelperText, lessonOverview, moduleId, subModuleId, isActive } = this.state;

        var data_arr = { lessonId, lessonTitle, lessonBannerIconUrl, lessonBannerColor, lessonHelperText, lessonOverview, isActive };

        const result = await this.props.client.mutate({
            mutation: UPDATE_LESSON,
            variables: data_arr
        });
        return result.data.updateLesson;
    };

    createContent = async (lessonId) => {
        const content = this.state.data;
        const isActive = true;

        const result = await this.props.client.mutate({
            mutation: CREATE_CONTENT,
            variables: { content, lessonId, isActive }
        });
        return result.data.createContent;
    };

    updateContent = async (contentId) => {
        console.log(contentId)
        const content = this.state.data;
        const isActive = true;

        const result = await this.props.client.mutate({
            mutation: UPDATE_CONTENT,
            variables: { content, contentId, isActive }
        });
        return result.data.updateContent;
    };

    get_modules = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULES_FOR_ADMIN,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModulesForAdmin;
    };

    get_submodules = async (moduleId) => {
        let pagination_submodules = this.state.pagination_submodules;
        pagination_submodules["moduleId"] = moduleId;
        const result = await this.props.client.query({
            query: GET_SUB_MODULES_FOR_ADMIN,
            variables: pagination_submodules,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModulesForAdmin;
    };

    get_lesson = async (lessonId) => {
        const result = await this.props.client.query({
            query: GET_LESSON,
            variables: { lessonId },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getLesson;
    };

    get_content = async (pagination_content) => {
        const result = await this.props.client.query({
            query: GET_CONTENT,
            variables: pagination_content,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getContent;
    };

    breadcrumbredirect = (path) => {
        if (path == "HOME") {
            this.props.history.push('/admin_home');
        } else if (path == "MANAGELESSON"){
            this.props.history.push('/admin_manage_lessons');
        } else {
            return false;
        }
    }


    render() {
        const { loading, modules, submodules, image, formAction } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;


        return (
            <div className="admin-content-inner">
                <div>
                    <ul class="uk-breadcrumb">
                        <li ><a onClick={() => this.breadcrumbredirect("HOME")}>Home</a></li>
                        <li ><a onClick={() => this.breadcrumbredirect("MANAGELESSON")}>Manage Lessons</a></li>
                        {formAction == "ADD" ? (
                            <li ><a onClick={() => this.breadcrumbredirect("ADDLESSON")}>Add Lesson</a></li>
                        ) : (
                                formAction == "EDIT" ? (
                                    <li ><a onClick={() => this.breadcrumbredirect("EDITLESSON")}>Edit Lesson</a></li>
                                ) : (
                                        null
                                    )
                            )}

                    </ul>
                </div>
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-book-open icon-large uk-margin-right"></i>
                    {formAction == "ADD" ? (
                        <h4 className="uk-margin-remove"> Add New Lesson </h4>
                    ) : (
                            formAction == "EDIT" ? (
                                <h4 className="uk-margin-remove"> Edit Lesson </h4>
                            ) : (
                                    null
                                )
                        )}
                </div>
                <div className="uk-margin-medium">
                    <form className="ui form">
                        <div className="uk-grid" >
                            <div className="uk-width-1@m">
                                <div className="uk-card-small uk-card-default">
                                    <div className="uk-card-header uk-text-bold">
                                        <i className="fas fa-book-open uk-margin-small-right"></i> Lesson Details
                                    </div>
                                    <div className="uk-card-body uk-padding-remove-top">
                                        <div className="uk-form-label"> Module </div>
                                        <select className="uk-select uk-width-xxlarge" onChange={this.handleModuleChange} value={this.state.moduleId || ''}>
                                            <option key="userType" value="" disabled>Select Module</option>
                                            {modules.map(item => (
                                                <option key={item.key} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="uk-form-label"> Submodule </div>
                                        <select className="uk-select uk-width-xxlarge" placeholder="Select Submodule" onChange={this.handleSubModuleChange} value={this.state.subModuleId || ''}>
                                            <option key="userType" value="" >Select Submodule</option>
                                            {submodules.map(item => (
                                                <option key={item.key} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="uk-form-label"> Lesson Title </div>
                                        <input required value={this.state.lessonTitle || ''} className="uk-input uk-form-width-huge" id="lessonTitle" name="lessonTitle" placeholder="Title" type="text" onChange={this.handleChange} />
                                        <div className="uk-form-label"> Upload Lesson Image </div>
                                       
                                        <div>
                                            <div className="">

                                                {/* File Component */}
                                                {this.state.image !== null && this.state.image !== undefined && this.state.image !== "" ?
                                                    (
                                                        <div className="file-upload-group uk-card uk-card-default uk-card-body" >
                                                            <div className="uk-text-center uk-grid " >

                                                                <div className="uk-width-auto@m">
                                                                    <span circular icon='download' class="uk-button uk-button-primary uk-button-small" onClick={() => { window.open(this.state.image); }}>Download</span>
                                                                </div>
                                                                <div className="uk-width-auto@m">
                                                                    <span circular icon='trash alternate outline' class="uk-button uk-button-secondary uk-button-small" onClick={() => {
                                                                        this._deleteFile(this.state.image).then(result => {
                                                                            console.log(result);
                                                                            this.setState({
                                                                                image: null,
                                                                                fileRef: null,
                                                                            });
                                                                        });
                                                                    }}>Delete</span>
                                                                </div>

                                                            </div><br />
                                                            <Images
                                                                image={image}
                                                                // removeImage={this.removeImage}
                                                            />
                                                        </div>
                                                    ) : (

                                                        <div className="uk-text-center uk-grid" >
                                                            <div className="uk-width-auto@m">
                                                                <Input
                                                                    onChange={this.documentUploadOnChange}
                                                                    name="upload"
                                                                    id="upload"
                                                                    className="uk-input uk-form-width-huge"
                                                                    placeholder="Upload here"
                                                                    type="file"
                                                                    multiple
                                                                />
                                                            </div>
                                                            <div className="uk-width-auto@m">
                                                                <span class="uk-button uk-button-default" onClick={this.documentUploadOnClick}>
                                                                    {loading ? (
                                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                                    ) : ("Click here to Upload")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {/* File Component */}
                                            </div>
                                            
                                            <br />
                                            {this.state.isUploading ? (
                                                <div className="uk-text-center uk-grid" >
                                                    <div className="uk-width-expand@m">
                                                        <h5 className="uk-align-left">Just a moment… <br/>
                                                            We are uploading your content.
                                                            </h5>
                                                    </div>
                                                    <div className="uk-width-auto@m">
                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                    ""
                                                )}
                                        </div>

                                        <br />
                                        <div className="uk-form-label"> Lesson Helper Text </div>
                                        <textarea required value={this.state.lessonHelperText || ''} className="uk-textarea" rows="5" id="lessonHelperText" name="lessonHelperText" onChange={this.handleChange}></textarea>
                                        <div className="uk-form-label"> Lesson Overview </div>
                                        <textarea value={this.state.lessonOverview || ''} className="uk-textarea" rows="5" id="lessonOverview" name="lessonOverview" onChange={this.handleChange}></textarea>
                                        <div className="uk-form-label"> Content </div>
                                        <CKEditor
                    onInit={ editor => {
                        console.log( 'Editor is ready to use!', editor );

                        // Insert the toolbar before the editable area.
                        editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement(),
                            editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                                    return new CKUploadAdapter(loader);
                                                }
                        );
                    } }

                    onEditorChange
                    onChange={ ( event, editor ) =>
                    //  console.log( { event, editor } )
                    {
                                                const data = editor.getData();
                                                this.onEditorChange(event, editor, data)
                                            }
                     }
                    editor={ DecoupledEditor }
                    // data="<p>Hello from CKEditor 5's DecoupledEditor!</p>"
                    data={this.state.data}
                    config={ {
                                                heading: {
                                                    options: [
                                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                                        ]
                                                    },
                                                    highlight:{
                                                        options:[
                                                            {
                                                                model: 'greenMarker',
                                                                class: 'marker-green',
                                                                title: 'Green marker',
                                                                color: 'var(--ck-highlight-marker-green)',
                                                                type: 'marker'
                                                            },
                                                        ]
                                                    }
                                                } }
                />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            className="uk-button uk-button-primary uk-margin"
                            style={{ height: 40 }}
                            type="button"
                            disabled={loading ? (true) : (false)}
                            onClick={() => this.handleSubmit(this.event)}
                        >
                            {loading ? (
                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                            ) : ("Save")}
                        </Button>
                        {(formErrorStatus.status) ? (
                            <div className="danger_alert">{formErrorStatus.message}</div>
                        ) : ((formSuccessState.status) ? (
                            <div className="success_alert">{formSuccessState.message} </div>
                        ) : (''))}
                    </form>
                </div>
            </div>
        );
    }
}

class EditorPreview extends React.Component {
    render() {
        return (
            <div>
                <br /><h5>Content Preview</h5>
                <div className="editor-preview">
                    <div dangerouslySetInnerHTML={{ __html: this.props.data }}></div>
                </div>
            </div>
        );
    }
}

EditorPreview.defaultProps = {
    data: ''
};

EditorPreview.propTypes = {
    data: PropTypes.string
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AddLesson))); 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

//custom-components
import Card_module, { Card_loading_module } from '../../module/card_module/card_module';
import Galleries from '../../galleries/galleries';
import News from '../../news/news'


//queries
import { GET_MODULES, GET_NEWSES,GET_NEWS ,GET_GALLERIES, GET_GALLERY } from '../../../queries/common_queries';


class HomeContainer extends React.Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        this.state = {
            modules: [],
            loading_modules: [
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />,
                <Card_loading_module />
            ],
            pagination: {
                skip: 0,
                first: 20,
                lang: localStorage.LANG,
            },
            loading: false
        }

        // this.get_gallery(id).then(result=>{
        //     this.setState({
        //         gallery: result.data.getGallery.gallery,
        //         galleryImages: result.data.getGallery.galleryImages
        //     });
        // })
    }

    componentDidMount() {

        this.setState({ loading: true });

            //get modules
            this.get_modules(this.state.pagination).then(result => {
                var module_opt = result.modules.map(module => {
                    return {
                        id: module.id,
                        moduleTitle: module.moduleTitle,
                        moduleHelperText: module.moduleHelperText,
                        bannerColor: module.bannerColor,
                        bannerIconUrl: module.bannerIconUrl,
                        moduleOverview: module.moduleOverview,
                        content: module.content,
                        moduleNumber: module.moduleNumber,
                        isActive: module.isActive,
                    }
                });
                this.setState({ modules: module_opt, loading: false });
            });

            

    }

    get_modules = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULES,
            variables: { pagination },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModules;
    };


    redirectToModule = (id) => {
        this.props.history.push('/module?id='+id);
    }

    render() {

        const { modules, loading, loading_modules  } = this.state;

        return (
            <ul className="uk-switcher switcher-container">
                <li>

                    <div className="uk-container">
                        {/* <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <p>Modules are to be completed in numarical order. At the end of each module a quiz will be available</p>
                            </div>
                        </div> */}

                        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" >

                            {
                            //     loading ? (
                            //     loading_modules.map((module, i) => (
                            //         <Card_loading_module key={i}/>
                            //     ))

                            // ) : (
                                    // modules.map((module, i) => (
                                    //     <Card_module key={i} module={module} get_module={() => this.redirectToModule(module.id)} />
                                    // ))
                                //)
                            }

                        </div>
                        
                    </div>
                </li>
                
                
            </ul>

        );
    }

}

export default withRouter(withApollo(HomeContainer)); 
import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_SBL_STATS } from "../../queries/admin_queries";
import NoDataMessage from '../messages/contentNotFound/NoDataFoundSmall'

const mapStateToProps = (state, ownProps) => {
  return {};
};

class SblStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        totalExams: '',
        totalExamSubmissions: '',
        mostPopularExam: '',
        fromDate: this.props.fromDate,
        toDate: this.props.toDate, 
    };
  }

  
  componentDidMount() {
    this.getSBLStats(this.state.fromDate, this.state.toDate).then((result) => {
      console.log(result);
      this.setState({
        totalExams: result.totalExams,
        totalExamSubmissions: result.totalExamSubmissions,
        mostPopularExam: result.mostPopularExam,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate) {
        this.getSBLStats(this.props.fromDate, this.props.toDate).then((result) => {
            console.log(result);
            this.setState({
              totalExams: result.totalExams,
              totalExamSubmissions: result.totalExamSubmissions,
              mostPopularExam: result.mostPopularExam,
            });
          });
    }
  }

  getSBLStats = async (from, to) => {
    const result = await this.props.client.query({
      query: GET_SBL_STATS,
      variables: {fromDate: from, toDate: to},
      fetchPolicy: "network-only",
    });
    return result.data.getSBLStats;
  };

  render() {
    const {} = this.props;
    const {
        totalExams,
        totalExamSubmissions,
        mostPopularExam,
        fromDate,
        toDate,
    } = this.state;
    return (
      <div className="">
        <h4 className="statTitleMain">SBL</h4>
        <hr />
        <table style={{width: '100%', padding: '1rem', borderSpacing: '10px'}}>
            <tbody>
                <tr>
                    <td style={{width: '30%', backgroundColor: '#369c91'}}>
                        <div className="sblBox1">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{borderRight: '1px solid white', paddingRight: '1rem'}}>
                                            <text style={{fontSize: '3rem', fontWeight: 'bold', color: '#9fe0ac'}}>
                                                {totalExams || 0}
                                            </text>
                                        </td>
                                        <td style={{padding: '0rem 1rem'}}>
                                        <text style={{fontSize: '0.9rem', color: 'white'}}>
                                                Total SBLs
                                            </text>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                    <td style={{width: '30%', backgroundColor: '#F7B25C'}}>
                    <div className="sblBox2">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{borderRight: '1px solid white', paddingRight: '1rem'}}>
                                            <text style={{fontSize: '3rem', fontWeight: 'bold', color: '#d46948'}}>
                                                {totalExamSubmissions || 0}
                                            </text>
                                        </td>
                                        <td style={{padding: '0rem 1rem'}}>
                                        <text style={{fontSize: '0.9rem', color: 'white'}}>
                                                Total Submissions
                                            </text>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                    <td style={{width: '40%', backgroundColor: '#49b1e6'}}>
                    <div className="sblBox3">
                            {/* <table>
                                <tbody>
                                    <tr>
                                        <td style={{borderRight: '1px solid white', paddingRight: '1rem'}}>
                                            <text style={{fontSize: '3rem', fontWeight: 'bold', color: 'white'}}>
                                                89
                                            </text>
                                        </td>
                                        <td style={{padding: '0rem 1rem'}}>
                                        <text style={{fontSize: '0.9rem', color: 'white'}}>
                                                Total Submissions
                                            </text>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> */}
                            <div style={{width: '100%', fontSize: '0.9rem', borderBottom: '1px solid white', paddingBottom: '0.5rem'}}>
                                Most popular SBL
                            </div>
                            <div style={{width: '100%', fontSize: '1rem', fontWeight: 'bold', padding: '0.5rem 0rem', textOverflow: 'ellipsis', color: '#065994'}}>
                            {mostPopularExam.title || <NoDataMessage message="No statistics found" fontSize="0.8rem" />}
                            </div>
                            <div style={{width: '100%', fontSize: '0.9rem', paddingTop: '0.5rem'}}>
                                Submissions: <b>{mostPopularExam.count || 'N/A'}</b>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(SblStats)));

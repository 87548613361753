//user
import Login from "../../src/screens/user/login/login";
import Login2 from "../../src/screens/user/login/login2";
import Registration from "../../src/screens/user/registration/registration";
import Home from "../../src/screens/user/home/home";
import Module from "../../src/screens/user/module/module";
import Sub_module from "../../src/screens/user/sub_module/sub_module";
import Lesson from "../../src/screens/user/lesson/lesson";
import Quizz from "../../src/screens/user/quizz/quizz";
import Editor from "../../src/screens/editor";
import QuizReview from "../../src/screens/user/quizReview/quizReview";
import Newses from "../../src/screens/user/news/news";
import News from "../../src/screens/user/single_news/single_news";
import GalleryImages from "../screens/user/galleryImages/galleryImages";
import Result from "../../src/screens/user/result/result";
import IssueCertificate from "../screens/user/issueCertificate/issueCertificate.js";
import FAQ from "../../src/screens/user/faq/faq";
import EditProfile from "../../src/screens/user/editProfile/editprofile";
import Contact from "../../src/screens/user/contact/contact";
import Terms_Conditions from "../../src/screens/user/terms_condition/terms_conditions";
import AboutUs from "../../src/screens/user/aboutus/aboutus";
import Help from "../../src/screens/user/help/help";
import Welcome from "../screens/user/Welcome/Welcome";
import Faq from "../screens/user/faq/faq";
import Resource from "../screens/user/single_resource/single_resource";
//admin
import Admin_login from "../../src/screens/admin/admin_login/admin_login";
import Admin_home from "../../src/screens/admin/admin_home/admin_home";

import AddGalleryImage from "../screens/admin/addGalleryImage/addGalleryImage";
import ForgetPassword from "../screens/user/forgetPassword/forgetPassword";
import OurNews from "../screens/user/news/ourNews/ourNews";
import GlobalNews from "../screens/user/news/globalNews/globalNews";

import Add_Announcement from "../screens/admin/announcements/addAnnouncement";
import Exam from "../screens/user/exams/singleExam";
import ExamSubmissions from '../screens/admin/knowledgeTestingCenter/examSubmissions';

import SearchResults from '../screens/user/userSearch/searchResults';

const indexRoutes = [
  {
    path: "/admin_home",
    name: "Admin_login",
    icon: "fas fa-user",
    component: Admin_home,
  },
  {
    path: "/admin_dashboard",
    name: "Admin_dashboard",
    icon: "fas fa-user",
    component: Admin_home,
  },

  {
    path: "/admin_user_list",
    name: "Users List",
    exact: true,
    icon: "fas fa-user",
    component: Admin_home,
  },
  {
    path: "/admin_add_module",
    name: "Add Module",
    icon: "fas fa-list",
    component: Admin_home,
  },
  {
    path: "/admin_add_submodule",
    name: "Add Sub-module",
    icon: "fas fa-th",
    component: Admin_home,
  },
  {
    path: "/admin_add_lesson",
    name: "Add Lesson",
    icon: "fas fa-book-open",
    component: Admin_home,
  },
  {
    path: "/admin_add_content",
    name: "Add Content",
    icon: "fas fa-code",
    component: Admin_home,
  },
  {
    path: "/admin_create_quiz",
    name: "Create Quiz",
    icon: "fas fa-question-circle",
    component: Admin_home,
  },
  {
    path: "/admin_quiz_view",
    name: "Quiz View",
    icon: "fas fa-question",
    component: Admin_home,
  },
  {
    path: "/admin_quiz_edit",
    name: "Quiz Edit",
    icon: "fas fa-question",
    component: Admin_home,
  },
  {
    path: "/admin_add_news",
    name: "Add News",
    icon: "fas fa-question-circle",
    component: Admin_home,
  },
  {
    path: "/admin_edit_news",
    name: "Edit News",
    icon: "fa fa-edit",
    isVisible: true,
    component: Admin_home,
  },
  {
    path: "/admin_news_list",
    name: "News List",
    icon: "fa fa-newspaper",
    component: Admin_home,
  },
  {
    path: "/admin_document_list",
    name: "Documents Upload",
    icon: "fa fa-newspaper",
    component: Admin_home,
  },
  {
    path: "/admin_add_document",
    name: "Documents Upload",
    icon: "fa fa-newspaper",
    component: Admin_home,
  },
  {
    path: "/admin_edit_document",
    name: "Documents Upload",
    icon: "fa fa-newspaper",
    component: Admin_home,
  },
  {
    path: "/",
    name: "Login",
    icon: "fas fa-user",
    component: Login,
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "fas fa-user",
  //   component: Login,
  // },
  {
    path: "/login2",
    name: "Login2",
    icon: "fas fa-user",
    component: Login2,
  },
  {
    path: "/registration",
    name: "Registration",
    icon: "fas fa-user",
    component: Registration,
  },
  {
    path: "/edit_profile",
    name: "Profile Update",
    icon: "fas fa-user",
    component: EditProfile,
  },
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-user",
    component: Home,
  },
  {
    path: "/module",
    name: "Module",
    icon: "fas fa-user",
    component: Module,
  },
  {
    path: "/sub_module",
    name: "Sub_module",
    icon: "fas fa-user",
    component: Sub_module,
  },
  {
    path: "/lesson",
    name: "Lesson",
    icon: "fas fa-user",
    component: Lesson,
  },
  {
    path: "/quiz",
    name: "Quiz",
    icon: "fas fa-user",
    component: Quizz,
  },
  {
    path: "/editor",
    name: "Editor",
    icon: "fas fa-user",
    component: Editor,
  },
  {
    path: "/admin",
    name: "Admin_login",
    icon: "fas fa-user",
    component: Admin_login,
  },
  {
    path: "/quizReview",
    name: "QuizReview",
    icon: "fas fa-user",
    component: QuizReview,
  },
  {
    path: "/certificate",
    name: "cretificate",
    icon: "fas fa-user",
    component: IssueCertificate,
  },
  {
    path: "/admin_manage_modules",
    name: "Manage Modules",
    exact: true,
    icon: "fas fa-list",
    component: Admin_home,
  },
  {
    path: "/admin_manage_submodules",
    name: "Manage Submodules",
    exact: true,
    icon: "fas fa-th",
    component: Admin_home,
  },
  {
    path: "/admin_manage_lessons",
    name: "Manage Lessons",
    icon: "fas fa-book-open",
    component: Admin_home,
  },
  {
    path: "/admin_registration",
    name: "Register System User",
    icon: "far fa-user",
    component: Admin_home,
  },

  {
    path: "/newses",
    name: "Newses",
    icon: "fas fa-user",
    component: Newses,
  },
  {
    path: "/admin_edit_profile",
    name: "Edit Profile",
    icon: "far fa-images",
    component: Admin_home,
  },
  {
    path: "/learning",
    name: "Learning",
    component: Home,
  },
  {
    path: "/tools",
    name: "Tools",
    component: Home,
  },
  {
    path: "/news",
    name: "News",
    component: Home,
  },
  {
    path: "/a_news",
    name: "Single_News",
    component: News,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Home,
  },
  {
    path: "/result",
    name: "Result",
    component: Result,
  },
  {
    path: "/admin_add_gallery",
    name: "Add Gallery",
    icon: "fas fa-image",
    component: Admin_home,
  },
  {
    path: "/galleryImages",
    name: "GalleryImages",
    component: GalleryImages,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/terms&conditions",
    name: "terms&conditions",
    component: Terms_Conditions,
  },
  //   {
  //       path:'/aboutus',
  //       name:"AboutUs",
  //       component:AboutUs
  //   },
  {
    path: "/help",
    name: "help",
    component: Help,
  },
  {
    path: "/add_gallery",
    name: "single image",
    component: AddGalleryImage,
  },
  {
    path: "/resources",
    name: "resources",
    component: Home,
  },
  {
    path: "/single_resource",
    name: "Single Resource",
    component: Resource,
  },
  {
    path: "/forgetPassword",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/admin_gallery_list",
    name: "Manage Gallery",
    component: Admin_home,
  },
  {
    path: "/admin_edit_gallery",
    name: "Edit Gallery",
    icon: "fas fa-image",
    component: Admin_home,
  },
  {
    path: "/ourNews",
    name: "Our News",
    component: OurNews,
  },
  {
    path: "/globalNews",
    name: "Global News",
    component: GlobalNews,
  },
  {
    path: "/admin_add_announcement",
    name: "Add Announcement",
    component: Admin_home,
  },
{
    path: "/admin_announcements",
    name: "Manage Announcement",
    component: Admin_home,
  },
  {
    path: "/admin_knowledge_testing_center",
    name: "Manage Knowledge Testing Center",
    component: Admin_home,
  },
  {
    path: "/admin_add_exam",
    name: "Add Exam",
    component: Admin_home,
  },
  // {
  //   path: "/exams",
  //   name: "Exams",
  //   component: Home,
  // },
  {
    path: "/sbls",
    name: "SBLS",
    component: Home,
  },
  // {
  //   path: "/exam",
  //   name: "Exam",
  //   component: Exam,
  // },
  {
    path: "/sbl",
    name: "Exam",
    component: Exam,
  },
  {
    path: "/admin_exam_submissions",
    name: "Exam Submissions",
    component: Admin_home,
  },
  {
    path: "/admin_exam_submission",
    name: "Single Exam Submission",
    component: Admin_home,
  },
  {
    path: "/admin_user_feedback",
    name: "User Feedbacks",
    component: Admin_home,
  },
  {
    path: "/search_results",
    name: "Search Results",
    component: SearchResults,
  },
  {
    path: "/admin_manage_tools",
    name: "Manage Tools",
    component: Admin_home,
  },
  {
    path: "/admin_add_tool",
    name: "Add Tool",
    icon: "fas fa-tools",
    component: Admin_home,
  },

  // Temporary Route
  {
    path: "/admin_add_video",
    name: "Upload File",
    component: Admin_home,
  }

  
];

export default indexRoutes;

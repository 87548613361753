import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import NewsContainer from '../../../components/news/newsContainer/newsContainer';
import News_banner from '../../../components/news/news_banner/news_banner';
import Footer from '../../../components/footer/footer';


class Module extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({ loading: true });
       
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="app">
                    <Header />
                    <News_banner />
                        <NewsContainer/>
                    <Footer language={localStorage.LANG} />
                </div>
            </div>
        );
    }

}

export default withRouter(withApollo(Module)); 


import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";

//queries
import { GET_EXAMS, GET_USER_RATINGS } from "../../../queries/common_queries";
import { async } from "q";
import { UPDATE_EXAM } from "../../../queries/admin_queries";
import moment from "moment";
import Star from "../../../images/1-star.png";
import Star2 from "../../../images/2-star.png";
import Star3 from "../../../images/3-star.png";
import Star4 from "../../../images/4-star.png";
import Star5 from "../../../images/5-star.png";
const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class ExamList extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID === undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE === "MAUSER") {
        this.props.history.push("/");
      }
    }
    this.state = {
      loading: false,
      all: [],
      ratings: [],
      excellent: [],
      veryGood: [],
      good: [],
      fair: [],
      needsImprovement: [],
      options: [
        { text: "All", key: "All", value: "All" },
        { text: "Excellent", key: "Excellent", value: "Excellent" },
        { text: "Very good", key: "Very good", value: "Very good" },
        { text: "Good", key: "Good", value: "Good" },
        { text: "Fair", key: "Fair", value: "Fair" },
        {
          text: "Needs improvement",
          key: "Needs improvement",
          value: "Needs improvement",
        },
      ],
      selectedOption: "All",
    };
  }

  componentDidMount() {
    const excellent = [];
    const veryGood = [];
    const good = [];
    const fair = [];
    const needsImprovement = [];
    this.get_all_ratings().then((result) => {
      if (result.length > 0) {
        this.setState({
          all: result,
          ratings: result,
        });

        result.map((rating) =>
          rating.rating === "Excellent"
            ? excellent.push(rating)
            : rating.rating === "Very good"
            ? veryGood.push(rating)
            : rating.rating === "Good"
            ? good.push(rating)
            : rating.rating === "Fair"
            ? fair.push(rating)
            : rating.rating === "Needs improvement"
            ? needsImprovement.push(rating)
            : null
        );
        const average = Math.round(
          (excellent.length * 5 +
            veryGood.length * 4 +
            good.length * 3 +
            fair.length * 2 +
            needsImprovement.length * 1) /
            this.state.all.length
        );
        this.setState({
          excellent: excellent,
          veryGood: veryGood,
          good: good,
          fair: fair,
          needsImprovement: needsImprovement,
          averageRating: average,
        });
      }
    });
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  //   addNewExam() {
  //     this.props.history.push("/admin_add_exam");
  //   }

  //   editExam(id) {
  //     this.props.history.push("/admin_add_exam?id=" + id);
  //   }

  //   viewSubmissions(id) {
  //     this.props.history.push("/admin_exam_submissions?id=" + id);
  //   }

  get_all_ratings = async () => {
    const result = await this.props.client.query({
      query: GET_USER_RATINGS,
      variables: {},
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getUserRatings.userRatings;
  };

  //   updateExam = async (id, status) => {
  //     const result = await this.props.client.mutate({
  //       mutation: UPDATE_EXAM,
  //       variables: {
  //         examId: id,
  //         lang: "en",
  //         isActive: status,
  //       },
  //     });
  //     console.log(result.data);
  //     return result.data.updateExam;
  //   };

  //   changeStatus = (id, status) => {
  //     console.log(id, status);
  //     this.updateExam(id, status).then((result) => {
  //       console.log(result);
  //       this.get_all_exams().then((result) => {
  //         if (result.length > 0) {
  //           this.setState({
  //             exams: result,
  //           });
  //         }
  //       });
  //     });
  //   };

  handleOptionChange = (event) => {
    var category = event.target.value;
    console.log(category);
    this.setState({ selectedOption: category });
    var tempList = [];
    if (category === "Excellent") {
      tempList = this.state.excellent;
    } else if (category === "Very good") {
      tempList = this.state.veryGood;
    } else if (category === "Good") {
      tempList = this.state.good;
    } else if (category === "Fair") {
      tempList = this.state.fair;
    } else if (category === "Needs improvement") {
      tempList = this.state.needsImprovement;
    } else {
      tempList = this.state.all;
    }
    this.setState({
      ratings: tempList,
    });
  };

  render() {
    const { ratings, loading, averageRating } = this.state;

    return (
      <div className="admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
        <i className="far fa-star uk-margin-right" style={{fontSize: '2.1rem'}}></i>

          <h4 className="uk-margin-remove adminSectionTitles"> User Feedback </h4>
        </div>
        {/* <div className="uk-flex-inline uk-float-right">
          <button
            className="uk-button uk-button-primary admin-btn"
            onClick={() => this.addNewExam()}
          >
            Add Exam
          </button>
        </div> */}

        <br /> <br />

        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{width: '20%', padding: '0.38rem 0rem'}}>
                Excellent: <b>{this.state.excellent.length}</b>
              </td>
              <td style={{width: '20%'}}>
                Very good: <b>{this.state.veryGood.length}</b>
              </td>
              <td style={{width: '20%'}}>
                Good: <b>{this.state.good.length}</b>
              </td>
              <td style={{width: '20%'}}>
                Fair: <b>{this.state.fair.length}</b>
              </td>
              <td style={{width: '20%'}}>
                Needs improvement: <b>{this.state.needsImprovement.length}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td style={{padding: '0.38rem 0rem', width: '50%'}}>
                Total Feedback: <b>{this.state.all.length}</b>
              </td>
              <td style={{width: 'auto', visibility: 'hidden'}}>jhvjv</td>
              <td align="right" style={{padding: '0.38rem 0rem', width: '50%'}}>
                Average Rating:{" "}
                <b>
                  {averageRating === 1
                    ? "Needs Improvement"
                    : averageRating === 2
                    ? "Fair"
                    : averageRating === 3
                    ? "Good"
                    : averageRating === 4
                    ? "Very Good"
                    : averageRating === 5
                    ? "Excellent"
                    : null}
                </b>
                {/* <br /> */}
                <img
                  className="starFeedbackMan1"
                  style={{marginTop: '-0.5rem', marginLeft: '1rem',}}
                  src={
                    averageRating === 5
                      ? Star5
                      : averageRating === 4
                      ? Star4
                      : averageRating === 3
                      ? Star3
                      : averageRating === 2
                      ? Star2
                      : averageRating === 1
                      ? Star
                      : null
                  }
                />
              </td>
            </tr>
            <tr>
              
            </tr>
          </tbody>
        </table>
        <br />
        <div>
          <div className="uk-form-label"> Rating Category </div>
          <select
            className="uk-select"
            style={{width: '13rem'}}
            onChange={this.handleOptionChange}
            // value={this.state.moduleId || ""}
          >
            {/* <option key="userType" value="" disabled>
              All
            </option> */}
            {this.state.options.map((item) => (
              <option key={item.key} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
          <br /> <br />
        </div>

        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead>
              <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                <th style={{width: '12%'}}>User</th>
                <th style={{width: '15%'}}></th>
                <th style={{ textAlign: "center", width: '8%' }}>Rating</th>
                <th style={{ textAlign: "left", width: '50%' }}>Review</th>
                <th style={{ textAlign: "center", width: '15%' }}>Date</th>
                {/* <th style={{ textAlign: "center" }}>Status</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ratings
                ? ratings.length > 0
                  ? ratings.map((rating, i) => (
                      <tr key={i}>
                        <td>
                          {" "}
                          {
                            rating.user ? rating.user.firstName ? rating.user.firstName : "" : ""
                          }
                          {" "}
                          {
                            rating.user ? rating.user.lastName ? rating.user.lastName : "" : ""
                          }
                          {" "}
                        </td>
                        <td>
                          {" "}
                          <img
                            className="starFeedbackMan"
                            src={
                              rating.rating === "Excellent"
                                ? Star5
                                : rating.rating === "Very good"
                                ? Star4
                                : rating.rating === "Good"
                                ? Star3
                                : rating.rating === "Fair"
                                ? Star2
                                : rating.rating === "Needs improvement"
                                ? Star
                                : null
                            }
                          />{" "}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {rating.rating || ""}{" "}
                        </td>

                        <td style={{ textAlign: "justify" }}>
                          {" "}
                          {rating.review || ""}{" "}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {moment(rating.updatedAt).format("DD-MM-YYYY") ||
                            ""}{" "}
                        </td>

                        {/* <td style={{ textAlign: "center" }}>
                          {" "}
                          {rating.isActive ? (
                            <p style={{ color: "green" }}>ACTIVE</p>
                          ) : (
                            <p style={{ color: "red" }}>INACTIVE</p>
                          )}{" "}
                        </td> */}
                      </tr>
                    ))
                  : null
                : null}
            </tbody>
          </table>
        </div>
        <br />
        {/* <div className="paginations uk-flex uk-flex-center">
                    <ul class="uk-pagination" uk-margin>
                        {renderfirstBtn}
                        {renderPrevBtn}

                        {
                            items.map((item,index) =>
                                <li key ={index} id={index} active={this.state.activePage=== index } onClick={(e)=> this.handleClick(e,item)}  className={currentPage===item? 'active': ''}><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                            )
                        }
                        {renderNextBtn}
                        {renderlastBtn}
                    </ul>
                </div> */}
        <br />
        <br />
      </div>
    );
  }
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(ExamList))
);

import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import Card_module, {
  Card_loading_module,
} from "../../module/card_module/card_module";

// import '../../../styles/progress.scss';
//queries
import { GET_USER_RESULT } from "../../../queries/user_queries";

import { Button } from "react-uikit";
import TableLoader from "../../../components/loaders/tableLoader";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import { withNamespaces } from "react-i18next";

// import PercentageCircle from 'reactjs-percentage-circle';
import Circle from "react-circle";
import Ribbon from "../../../images/ribbon.png";
import RibbonDisabled from "../../../images/ribbonDisabled.png";
import { GET_FLASH_QUIZ_PROGRESS } from "../../../queries/common_queries";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class ResultContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      moduleResults: [],
      buttonVisible: false,
      resultingArray: [],
      progressArray: [],
      // test: 66.66,
    };
  }

  // componentDidMount() {
  //   this.setState({ loading: true });
  //   this.getUserResult().then((result) => {
  //     console.log(result.modules);
  //     // this.setState({
  //     //   moduleResults: result.modules,
  //     //   loading: false,
  //     // });

  //     const tempArray = result.modules;
  //     const progressArray = [];
  //     if (tempArray.length > 0) {
  //       tempArray.map((module, i) =>
  //         this.get_flash_quiz_progress(module.module.id).then((result) => {
  //           progressArray.push({
  //             progress: result.progress,
  //             subModules: result.subModuleCount,
  //           });
  //           if (tempArray.length > 0) {
  //             for (let i = 0; i < progressArray.length; i++) {
  //               tempArray[i].progress = progressArray[i].progress;
  //             }

  //             this.setState({ moduleResults: tempArray, loading: false });
  //           }
  //         })
  //       );
  //     }

  componentDidMount() {
    this.setState({ loading: true });
    this.getUserResult().then((result) => {
      // console.log(result);
      // console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL');

      const tempArray = result.modules;
      const moduleArray = result.modules;
      const progressArray = [];
      if (tempArray.length > 0) {
        tempArray.map((module, i) =>
          this.get_flash_quiz_progress(module.module.id).then((result) => {
            moduleArray[i].progress = result.progress;
            moduleArray[i].subModules = result.subModuleCount;
            this.setState({ moduleResults: tempArray, loading: false });
          })
        );
      }

      let scoreArray = [];
      result.modules.map((module, i) =>
        module.result.length > 0
          ? module.result[0].score != undefined && module.result[0].score >= 80
            ? scoreArray.push(module.result[0].score)
            : null
          : null
      );

      if (result.modules.length == scoreArray.length) {
        this.setState({ buttonVisible: true });
      } else {
        this.setState({ buttonVisible: false });
      }

      this.setState({ resultingArray: scoreArray });
    });
  }

  //IssueCertificate
  issueCertificate(moduleId) {
    this.props.history.push("/certificate");
    // ?id=' + moduleId
  }

  getUserResult = async () => {
    const result = await this.props.client.query({
      query: GET_USER_RESULT,
      variables: {
        lang: localStorage.LANG,
      },
      fetchPolicy: "network-only",
    });
    return result.data.getUserResult;
  };

  get_flash_quiz_progress = async (moduleId) => {
    const result = await this.props.client.query({
      query: GET_FLASH_QUIZ_PROGRESS,
      variables: {
        moduleId: moduleId,
        createdBy: localStorage.USER_ID,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getFlashQuizProgress;
  };

  // getProgressArray = () => {
  //   const tempArray = moduleResults;
  //   if (moduleResults.length > 0) {
  //     moduleResults.map((module, i)=> (
  //       this.get_flash_quiz_progress(module.module.id).then((result) => {
  //         tempArray[i].progress = result.progress === null ? 0 : result.progress;
  //         console.log(result.progress)
  //         tempArray[i].subModules = result.subModuleCount;
  //       })
  //     ))
  //   }
  //   this.setState({moduleResults: tempArray});

  // };

  goToModule = (id) => {
    window.open("/module?id=" + id);
  }

  render() {
    const { loading, moduleResults, progressArray } = this.state;
    const { t } = this.props;
    console.log(moduleResults)
    return (
      <div
        className="uk-container uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
        uk-scrollspy="target: > a; cls:uk-animation-slide-bottom-small; delay: 200"
      >
        <a href="#" className="paper uk-link-reset">
          <div className="">
            {loading ? (
              <div className="uk-width-5-6">
              </div>
            ) : (
              [
                <h3>{ t('my_dashboard')}</h3>,
                
                <div
                  style={{ marginRight: "40px" }}
                  className="uk-text-center uk-margin"
                >
                  {this.state.buttonVisible == true ? (
                    <Button
                      className="uk-button uk-button-primary uk-margin"
                      type="button"
                      onClick={() => this.issueCertificate()}
                      disabled={loading ? true : false}
                      uk-tooltip={t('download_certificate')}
                    >
                      {loading ? (
                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                      ) : (
                        <i class="fas fa-certificate"> {t('download_certificate')} </i>
                      )}
                    </Button>
                  ) : (
                    localStorage.LANG=="ar" ?(
                      <div
                      class="uk-alert-warning "
                      uk-alert="true"
                      style={{ textAlign: "right", marginRight:"-40px" }}
                    >
                     {t('result_title')}
                    </div>
                    ):(
                      <div
                      class="uk-alert-warning "
                      uk-alert="true"
                      style={{ textAlign: "left" }}
                    >
                     {t('result_title')}
                    </div>
                    )
                    
                  )}
                </div>,

                moduleResults.length > 0 ? (
                  <div className="uk-overflow-auto">
                    {moduleResults.map((module, index) => (
                      <div className={localStorage.LANG=="ar" ? ("dashboardCard_Arabic"):("dashboardCard")} onClick={() => this.goToModule(module.module.id)}>
                        <div className="dashboardCardImageDiv">
                          <img
                            className="dashboardCardImage"
                            src={module.module.bannerIconUrl}
                          />
                        </div>
                        <div className="dashboardCardTitleDiv">
                          <text className="dashboardCardTitle">
                            {module.module.moduleTitle}
                          </text>
                        </div>
                        <div className="dashboardCardContentDiv">
                          <table>
                            <tbody>
                              <tr>
                                <td uk-tooltip={t('module_progress')}>
                                  <Circle
                                    animate={true}
                                    animationDuration="3s"
                                    size={80}
                                    lineWidth={50}
                                    progress={Math.round(module.progress)}
                                    progressColor="#008C95"
                                    bgColor="#E0E0E0"
                                    // textColor="white"
                                    textStyle={{
                                      fontSize: "5rem",
                                      fontWeight: "bold",
                                    }}
                                  />
                                </td>
                                <td>
                                  {module.result.length > 0 ? (
                                    module.result[0].score >= 80 ? (
                                      <img
                                        uk-tooltip={t('got_badge')}
                                        src={Ribbon}
                                        className="dashboardCardRibbon"
                                      />
                                    ) : (
                                      <img
                                        uk-tooltip={t("score_more_than_eighty")}
                                        src={RibbonDisabled}
                                        className="dashboardCardRibbon"
                                      />
                                    )
                                  ) : (
                                    <img
                                      uk-tooltip={t('attempt_quiz_for_badge')}
                                      src={RibbonDisabled}
                                      className="dashboardCardRibbon"
                                    />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          {module.progress > 0 ? (
                            module.result.length > 0 ? (
                              module.result[0].score >= 80 ? (
                                <text style={{ fontSize: "0.8rem" }}>
                                 {t('module_status')} <b>{t('pass')}</b>
                                </text>
                              ) : (
                                <text style={{ fontSize: "0.8rem" }}>
                                 {t('module_status')} <b>{t('in_progress')}</b>
                                </text>
                              )
                            ) : (
                              <text style={{ fontSize: "0.8rem" }}>
                              {t('module_status')} <b>{t('in_progress')}</b>
                              </text>
                            )
                          ) : (
                            <text style={{ fontSize: "0.8rem" }}>
                             {t('module_status')} <b>{t('pending')}</b>
                            </text>
                          )}
                          <br />
                          {module.result.length > 0 ? (
                            module.result.score != "" ||
                            module.result.score != null ? (
                              <text className={localStorage.LANG=="fr"? "fr_quiz_score":"quiz_score"} >
                                {t('quiz_score')}{" "}
                                <b>{module.result[0].score}%</b>
                              </text>
                            ) : (
                              <text className={localStorage.LANG=="fr"? "fr_quiz_score":"quiz_score"}>
                                {t('quiz_score')} <b>{t('NA')}</b>
                              </text>
                            )
                          ) : (
                            <text className={localStorage.LANG=="fr"? "fr_quiz_score":"quiz_score"}>
                                {t('quiz_score')} <b>{t('NA')}</b>
                            </text>
                          )}
                        </div>
                        {localStorage.LANG=="ar" ? (
                          
                        <div class="dashboardCardBottomDiv">
                        <text class="dashboardCardBottomDivText">
                         {t('sub_module')} &nbsp;
                          {module.subModules}
                        </text>
                        <span
                          class="uk-card-badge uk-label timeButton"
                          style={{
                            position: "inherit",
                            float: "left",
                            marginTop: "-0.4rem",
                          }}
                        >
                          <i class="fas fa-clock" aria-hidden="true"></i>
                          <span> {module.module.moduleOverview} </span>{" "}
                          <span className="miniuteText">{t('minutes')} </span>{" "}
                        </span>
                      </div>
                        ): (
                          
                        <div class="dashboardCardBottomDiv">
                        <text class="dashboardCardBottomDivText">
                          {/* {
                            this.state.progressArray.length >= index + 1 ?
                            this.state.progressArray[index].subModules
                            : null
                          } */}
                         {t('sub_module')} &nbsp;
                          {module.subModules}
                        </text>
                        <span
                          class="uk-card-badge uk-label timeButton"
                          style={{
                            position: "inherit",
                            float: "right",
                            marginTop: "-0.4rem",
                          }}
                        >
                          <i class="fas fa-clock" aria-hidden="true"></i>
                          <span> {module.module.moduleOverview} </span>{" "}
                          <span className="miniuteText">{t('minutes')} </span>{" "}
                        </span>
                      </div>
                        )}

                      </div>
                    ))}
                  </div>
                ) : null,
              ]
            )}
          </div>
        </a>
      </div>
    );
  }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ResultContainer))));

import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import NewsBanner from '../../../components/news/single_news/single_news_banner/single_news_banner';
import NewsContainer from '../../../components/news/single_news/single_news_cantainer/single_news_cantainer';
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import { withNamespaces } from 'react-i18next';

//queries
import { GET_A_NEWS } from '../../../queries/common_queries';

class Anews extends React.Component {
    constructor(props) {
        super(props);
        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');
        this.state = {
            id: id,
            from: page,
            module: "",
            sub_modules: [],
            lessons: [],
            contents: [],
            newsId: id,
            pagination: {
                newsId: id,
                isActive: true,
                lang:localStorage.LANG
            },
            redirectKey: "",
            loading: false,
            videos: [],
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
         //Get a news
         this.get_a_news(this.state.pagination).then(result =>{
            const videoList = result.videos ? result.videos.videos ? result.videos.videos : [] : [];
            this.setState({
                newsId: result.id, 
                newsBannerImage: result.newsBannerImage,
                createdAt:result.createdAt,
                newsTitle:result.newsTitle,
                newsHelperText: result.newsHelperText,
                newsContent: result.newsContent,
                loading: false,
                videos: videoList,
            });
        });       
    }

    get_a_news = async (pagination) => {  
        const result = await this.props.client.query({
            query: GET_A_NEWS,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
    //    console.log(result);
        return result.data.getNews;
    };

    render() {
        const { newsId, newsBannerImage, createdAt, newsTitle, newsHelperText, newsContent, loading } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Navbar />
                <div className="app">
                    {loading ? (
                        <div data-src='' uk-img="" className=" single-news-header single-news-header uk-height-large uk-background-norepeat  uk-background-center-center    uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover">
                            <div className="uk-width-1-1 uk-container uk-margin-large-top uk-padding-remove-bottom">
                                <h1 className=" uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top">{t('wait')} </h1>
                                <p className="  uk-margin-large-left  uk-text-white">
                                {t('loading')} </p>
                            </div>                 
                        </div>
                    ) : (
                            [<NewsBanner newsbanner={newsBannerImage}  newsTitle={newsTitle} newsHelperText={newsHelperText} createdAt={createdAt}/>,
                              <NewsContainer videos={this.state.videos} newsHelperText={newsHelperText} newsContent={newsContent} />]
                        )}
                    <Footer language={localStorage.LANG}/>
                </div>
            </div>
        );
    }
}

export default withRouter(withApollo(withNamespaces()(Anews)));
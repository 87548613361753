import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Q from '../../../assets/images/Q.png'
import A from '../../../assets/images/A.png'
import answer from '../../../assets/images/answer.png';
import question_icon from '../../../assets/images/question_icon.png'






class FaqContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        console.log(localStorage.LANG)
        return (
            <div className="uk-section-default uk-section">
                <hr />
                <div className="ui text container uiContainer containerStyle">
                    <div className="HeaderBorder ui placeholder segment">
                        <h4 className="ui header"> {t("faq_title")} </h4>
                    </div>

                    <article class="uk-card uk-card-default uk-card-small uk-card-body uk-article tm-ignore-container">
                        <ul class="uk-list-divider uk-list-large uk-accordion faq-accordion" uk-accordion="true">
                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_one") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <p> <div dangerouslySetInnerHTML={{ __html: t("a_one") }} /> </p>
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_two") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_two") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_three") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_three") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_four") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_four") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_five") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_five") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_six") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_six") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_seven") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_seven") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_eight") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_eight") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_nine") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_nine") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_ten") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_ten") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_eleven") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_eleven") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twelve") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twelve") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_thirteen") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirteen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_fourteen") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_fourteen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_fifteen") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_fifteen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_sixteen") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_sixteen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_seventeen") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_seventeen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_eighteen") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_eighteen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_nineteen") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_nineteen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_one") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_one") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_two") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_two") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_twenty_three") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_three") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_twenty_four") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_four") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_twenty_five") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_five") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_twenty_six") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_six") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_twenty_seven") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />

                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_seven") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_eight") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_eight") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_twenty_nine") }} /></strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_nine") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_one") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_one") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_two") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_two") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_three") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_three") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_four") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_four") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_five") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_five") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_six") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_six") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_seven") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_seven") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_eight") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_eight") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_thirty_nine") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_thirty_nine") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_fourty") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_fourty") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_fourty_one") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_fourty_one") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.LANG=="ar" ? ("uk-accordion-title titleFaq_AR"):("uk-accordion-title titleFaq")} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong><div dangerouslySetInnerHTML={{ __html: t("q_fourty_two") }} /> </strong>
                                </a>
                                <div class={localStorage.LANG=="ar" ? ("uk-accordion-content Rowcommon_AR "): ("uk-accordion-content Rowcommon ")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_fourty_two") }} />
                                    </span>
                                </div>
                            </li>

                        </ul>
                    </article>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(withNamespaces()(FaqContainer)));
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';


//queries
import { CREATE_LESSON, CREATE_CONTENT, GET_MODULES_FOR_ADMIN, GET_SUB_MODULES_FOR_ADMIN, GET_LESSONS_FOR_ADMIN } from '../../../queries/admin_queries';
import { GET_CONTENT } from '../../../queries/common_queries';


import { Button } from 'react-uikit';

import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import CKUploadAdapter from '../../../middleware/ck-file-upload-adapter';


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formErrorStatus,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modules: [],
            submodules: [],
            lessons: [],
            moduleId: '',
            subModuleId: '',
            lessonId: '',
            isActive: true,
            loading: false,
            pagination: {
                isActive: true,
            },
            pagination_submodules: {
                isActive: true
            },
            pagination_lessons: {
                isActive: true
            },
            pagination_content: {
                isActive: true
            },

        };
        // console.log(this.props)

        this.handleChange = this.handleChange.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {
        this.get_modules(this.state.pagination).then(result => {
            var module_opt = [];
            result.modules.map(module => (
                module_opt.push({ key: module.id, value: module.id,  label: module.moduleTitle })
            ));
            this.setState({ modules: module_opt });
        })
    }

    onEditorChange(event, editor, data) {
        this.setState({
            data: data
        });
        // console.log(data)
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleModuleChange = (event) => {
        var moduleId = event.target.value;

        let pagination_lessons = this.state.pagination_lessons;
        delete pagination_lessons["subModuleId"];
        pagination_lessons["moduleId"] = moduleId;

        this.get_submodules(moduleId).then(result => {
            // console.log(result)
            var submodule_opt = [];
            result.subModules.map(submodule => (
                submodule_opt.push({ key: submodule.id, value: submodule.id, label: submodule.subModuleTitle })
            ));
            this.setState({ submodules: submodule_opt, "moduleId": moduleId });
        })

        this.get_lessons(pagination_lessons).then(result => {
            // console.log(result)
            var lesson_opt = [];
            result.lessons.map(lesson => (
                lesson_opt.push({ key: lesson.id, value: lesson.id, label: lesson.lessonTitle })
            ));
            this.setState({ lessons: lesson_opt });
        })
    };

    handleSubModuleChange = (event) => {
        var subModuleId = event.target.value;

        let pagination_lessons = this.state.pagination_lessons;
        delete pagination_lessons["moduleId"];
        pagination_lessons["subModuleId"] = subModuleId;

        this.get_lessons(pagination_lessons).then(result => {
            // console.log(result)
            var lesson_opt = [];
            result.lessons.map(lesson => (
                lesson_opt.push({ key: lesson.id, value: lesson.id, id: 'lessonId', label: lesson.lessonTitle })
            ));
            this.setState({ lessons: lesson_opt, "subModuleId": subModuleId });
        })
    };

    handleLessonChange = (event) => {
        this.setState({ "lessonId": event.target.value });
    };

    handleSubmit = (event) => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.setState({ loading: true });

        const { data, lessonId, subModuleId, moduleId } = this.state;

        if (data == null) {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Required Content!" });
        } else if (lessonId == '' && subModuleId == '' && moduleId == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Required Module Or Submodule Or Lesson!" });
        } else {
            this.get_content(this.state.pagination_content).then(result => {
                // console.log(result.contents.contents)
                this.setState({ loading: false })

                result.contents.contents.map(content => (
                    lessonId != '' && lessonId == content.lessonId ? (
                        this.props.setFormStatus({ status: true, title: "Oops!", message: "Lesson has already Content!" })
                    ) : (
                            subModuleId != '' && subModuleId == content.subModuleId ? (
                                this.props.setFormStatus({ status: true, title: "Oops!", message: "Submodule has already Content!" })
                            ) : (
                                    moduleId != '' && moduleId == content.moduleId ? (
                                        this.props.setFormStatus({ status: true, title: "Oops!", message: "Module has already Content!" })
                                    ) : (
                                            this.createContent().then(result => {

                                                this.setState({
                                                    data: null,
                                                    loading: false
                                                });

                                                this.props.setSuccessStatus({
                                                    status: true,
                                                    title: "",
                                                    message:
                                                        "Created Content Successfully!."
                                                });

                                            }).catch(error => {
                                                console.log(error);
                                                this.setState({ loading: false });
                                                this.props.setFormStatus({ status: true, title: "Oops!", message: "There was an error while trying to create content." });
                                            })
                                        )
                                )
                        )
                ))

                
            });


        }
    }

    createContent = async () => {
        const { data, moduleId, subModuleId, lessonId, isActive } = this.state;
        const content = data;

        var data_arr = {};
        if (lessonId != '') {
            data_arr = { content, lessonId, isActive };
        } else if (subModuleId != '') {
            data_arr = { content, subModuleId, isActive };
        } else if (moduleId != '') {
            data_arr = { content, moduleId, isActive };
        }

        const result = await this.props.client.mutate({
            mutation: CREATE_CONTENT,
            variables: data_arr
        });
        return result.data.createContent;
    };

    get_content = async (pagination_content) => {
        const result = await this.props.client.query({
            query: GET_CONTENT,
            variables: pagination_content,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getContent;
    };

    get_modules = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULES_FOR_ADMIN,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModulesForAdmin;
    };

    get_submodules = async (moduleId) => {
        let pagination_submodules = this.state.pagination_submodules;
        pagination_submodules["moduleId"] = moduleId;
        const result = await this.props.client.query({
            query: GET_SUB_MODULES_FOR_ADMIN,
            variables: pagination_submodules,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModulesForAdmin;
    };

    get_lessons = async (pagination_lessons) => {
        // console.log(pagination_lessons)
        const result = await this.props.client.query({
            query: GET_LESSONS_FOR_ADMIN,
            variables: pagination_lessons,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getLessonsForAdmin;
    };


    render() {
        const { loading, modules, submodules, lessons } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;

        return (
            <div className="admin-content-inner">

                <div className="uk-modal-header">
                    <h4> Create New Content   </h4>
                </div>
                <div className="uk-modal-body">
                    <div className="uk-grid" >
                        <div className="uk-width-2-3@m">
                            <form>
                                <div className="uk-margin">
                                    <div className="uk-form-label">Module</div>
                                    <div className="uk-inline">
                                        <span className="uk-form-icon"><i className="fas fa-admin icon-medium"></i></span>
                                        <select className="uk-select uk-width-xxlarge" onChange={this.handleModuleChange} value={this.state.moduleId || ''}>
                                            <option key="userType" value="" disabled>Select Module</option>
                                            {modules.map(item => (
                                                <option key={item.key} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="uk-margin">
                                    <div className="uk-form-label">Submodule</div>
                                    <div className="uk-inline">
                                        <span className="uk-form-icon"><i className="fas fa-admin icon-medium"></i></span>
                                        <select className="uk-select uk-width-xxlarge" placeholder="Select Submodule" onChange={this.handleSubModuleChange} value={this.state.subModuleId || ''}>
                                            <option key="userType" value="" >Select Submodule</option>
                                            {submodules.map(item => (
                                                <option key={item.key} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="uk-margin">
                                    <div className="uk-form-label">Lesson</div>
                                    <div className="uk-inline">
                                        <span className="uk-form-icon"><i className="fas fa-admin icon-medium"></i></span>
                                        <select className="uk-select uk-width-xxlarge" placeholder="Select Submodule" onChange={this.handleLessonChange} value={this.state.lessonId || ''}>
                                            <option key="userType" value="" >Select Lesson</option>
                                            {lessons.map(item => (
                                                <option key={item.key} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="uk-margin">
                                    <label> Content </label><br /><br />
                                    <CKEditor
                    onInit={ editor => {
                        console.log( 'Editor is ready to use!', editor );

                        // Insert the toolbar before the editable area.
                        editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement(),
                            editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                                    return new CKUploadAdapter(loader);
                                                }
                        );
                    } }

                    onEditorChange
                    onChange={ ( event, editor ) =>
                    //  console.log( { event, editor } )
                    {
                                                const data = editor.getData();
                                                this.onEditorChange(event, editor, data)
                                            }
                     }
                    editor={ DecoupledEditor }
                    // data="<p>Hello from CKEditor 5's DecoupledEditor!</p>"
                    data={this.state.data}
                    config={ {
                                                heading: {
                                                    options: [
                                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                                        ]
                                                    },
                                                    highlight:{
                                                        options:[
                                                            {
                                                                model: 'greenMarker',
                                                                class: 'marker-green',
                                                                title: 'Green marker',
                                                                color: 'var(--ck-highlight-marker-green)',
                                                                type: 'marker'
                                                            },
                                                        ]
                                                    }
                                                } }
                />

                                    {/* <EditorPreview data={this.state.data} /> */}
                                </div>

                                <div className="uk-modal-footer uk-text-right">
                                    <Button
                                        className="uk-button uk-button-primary uk-margin"
                                        style={{ height: 40 }}
                                        type="button"
                                        disabled={loading ? (true) : (false)}
                                        onClick={() => this.handleSubmit(this.event)}
                                    >
                                        {loading ? (
                                            <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                        ) : ("Save")}
                                    </Button>
                                    <br />
                                </div>

                                {(formErrorStatus.status) ? (
                                    <div className="danger_alert">{formErrorStatus.message}</div>
                                ) : ((formSuccessState.status) ? (
                                    <div className="success_alert">{formSuccessState.message} </div>
                                ) : (''))}

                            </form>
                        </div>
                    </div>
                </div>


            </div>


        );
    }

}

class EditorPreview extends React.Component {
    render() {
        return (
            <div>
                <br /><h5>Content Preview</h5>
                <div className="editor-preview">
                    <div dangerouslySetInnerHTML={{ __html: this.props.data }}></div>
                </div>
            </div>
        );
    }
}

EditorPreview.defaultProps = {
    data: ''
};

EditorPreview.propTypes = {
    data: PropTypes.string
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AddContent))); 
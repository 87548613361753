import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import moment from "moment";
import { _generate_email } from "../../../middleware/index";

//queries
import {
  CREATE_ANNOUNCEMENT,
  UPDATE_MODULE,
  UPDATE_ANNOUNCEMENT,
  CREATE_EXAM,
  UPDATE_EXAM,
} from "../../../queries/admin_queries";
import {
  GET_SINGLE_ANNOUNCEMENT,
  GET_SINGLE_EXAM,
} from "../../../queries/common_queries";

//redux-form
import { Field, FieldArray, reduxForm } from "redux-form";

import { Button } from "react-uikit";

import { Input, Popup, Grid } from "semantic-ui-react";

import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import CKUploadAdapter from "../../../middleware/ck-file-upload-adapter";

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

import DocumentIcon from "../../../images/documentIcon.png";
import RemoveIcon from "../../../images/remove.png";
import ConfirmationModal from '../../../components/adminModals/confirmationModal';

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
    languageKeys: state.custom_reducer.languageKeys,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class AddExam extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");
    console.log(id)
    if (id === null) {
      this.state = {
        formAction: "ADD",
        formLanguage: "en",
        examId: "",
        examName: "",
        description: "",
        questions: [],
        resourceUrls: [],
        isActive: "",
        loading: false,
        supportingLanguages: [],
        supportingLangErrorMsg: "",
        fileRef: [],
        newFiles: [],
      };
    } else {
      this.state = {
        formAction: "EDIT",
        formLanguage: "en",
        examId: query.get("id"),
        examName: "",
        description: "",
        questions: [],
        resourceUrls: [],
        isActive: "",
        loading: false,
        supportingLanguages: [],
        supportingLangErrorMsg: "",
        fileRef: [],
        newFiles: [],
      };
    }

    this.handleChange = this.handleChange.bind(this);

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.state.formAction == "EDIT") {
      this.getSingleExam(this.state.formLanguage).then((result) => {
        console.log(result);
        this.setState({
          examName: result.examName,
          description: result.description,
          questions: result.questions ? result.questions.questions : [],
          resourceUrls: result.resourceUrls
            ? result.resourceUrls.resourceUrls
            : [],
          fileRef: result.resourceUrls ? result.resourceUrls.resourceUrls : [],
          languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true,
        });
      });
    } else {
      this.setState({ loading: false });
    }
    //supporting languages array
    if (this.state.formAction === "ADD") {
      const supportingLanguages = this.props.translatingLanguages;
      this.setState({
        supportingLanguages: supportingLanguages,
      });
    }
    this.setState({ loading: false });
  }

  handleChange(event) {
    console.log(event);
    this.setState({ [event.target.id]: event.target.value });
    this.props.setFormStatus({
      status: false,
      title: "",
      message: "",
    });
    this.props.setSuccessStatus({
      status: false,
      title: "",
      message: "",
    });
  }

  handleQuestionChange = (e, data, qIndex) => {
    var tempArray = this.state.questions;
    tempArray[qIndex].question = e.target.value;
    this.setState({ questions: tempArray });
  };

  handleAnswerChange = (e, data, qIndex) => {
    var tempArray = this.state.questions;
    tempArray[qIndex].marks = e.target.value;
    this.setState({ questions: tempArray });
  };

  createExam = async (data) => {
    const result = await this.props.client.mutate({
      mutation: CREATE_EXAM,
      variables: data,
    });
    console.log(result.data);
    return result.data.createExam;
  };

  getSingleExam = async (language) => {
    const { examId, formLanguage } = this.state;
    console.log(examId);
    const result = await this.props.client.mutate({
      mutation: GET_SINGLE_EXAM,
      variables: {
        examId: examId,
        lang: language,
      },
    });
    console.log(result.data);
    return result.data.getSingleExam;
  };

  updateExam = async (data) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_EXAM,
      variables: data,
    });
    console.log(result.data);
    return result.data.updateExam;
  };

  _startDocUpload = async (files) => {
    console.log(files)
    var intDate = Number(new Date());
    var result;
    var tempArray = this.state.resourceUrls;
    var temp = [];
    for (let i = 0; i < files.length; i += 1) {
      result = await uploadFile(
        files[i].resource,
        localStorage.FIRST_NAME + intDate
      );
      if (result.status) {
        console.log(result);
        tempArray.push({ resourceUrl: result.url });
        temp.push({ resourceUrl: result.url });
        this.setState({
          fileRef: tempArray,
          newFiles: [],
          resourceUrls: tempArray
        });
      }
    }
    if (temp.length > 0) {
      if (temp.length === files.length) {
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message: "Files uploaded successfully.",
        });
      } else {
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message:
          <div>
            An error occurred while uploading your files! <br/>
            Please try again.
          </div>
        });
      }
    } else {
      this.props.setSuccessStatus({
        status: false,
        title: "",
        message: "",
      });
      this.props.setFormStatus({
        status: false,
        title: "Oops!",
        message: "Add files before uploading.",
      });
    }

    this.setState({ isUploading: false });
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/admin_home");
    } else if (path == "MANAGEEXAM") {
      this.props.history.push("/admin_knowledge_testing_center");
    } else {
      return false;
    }
  };

  changeFormLanguage = (language) => {
    this.getSingleExam(language).then((result) => {
      console.log(result);
      this.setState({
        examName: result.examName,
        description: result.description,
        questions: result.questions ? result.questions.questions : [],
        resourceUrls: result.resourceUrls
          ? result.resourceUrls.resourceUrls
          : [],
        fileRef: result.resourceUrls ? result.resourceUrls.resourceUrls : [],
        formLanguage: language,
        languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true,
      });
      this.props.setFormStatus({ status: false, title: "", message: "" });
      this.props.setSuccessStatus({ status: false, title: "", message: "" });
    });
  };

  openDocument = (url) => {
    console.log(url);
    if (url != "" && url != undefined) {
      window.open(url);
    }
  };

  addQuestion = () => {
    var tempArray = this.state.questions;
    tempArray.push({ question: "", marks: "" });
    this.setState({ questions: tempArray });
  };

  removeQuestion = (qIndex) => {
    var tempArray = this.state.questions;
    tempArray.splice(qIndex, 1);
    this.setState({ questions: tempArray });
  };

  handleLanguageClick = (lang, supported) => {
    if (lang != "English") {
      const tempArray = this.state.supportingLanguages;
      const index = this.state.supportingLanguages.findIndex(
        (obj) => obj.language === lang
      );
      tempArray[index].supporting = supported;
      this.setState({
        supportingLanguages: tempArray,
        supportingLangErrorMsg: "",
      });
    } else {
      this.setState({
        supportingLangErrorMsg: "*English Language support is mandatory",
      });
    }
  };

  documentUploadOnChange = (e) => {
    // const resources = this.state.resourceUrls;
    const newFiles = [];
    // const resourceType = e.target.files[0].type.split('/')[0];
    const files = e.target.files;
    console.log(files)
    if (files.length > 0) {
      // e.target.files.map(file => (
        for(let i = 0; i < files.length; i ++) {
            newFiles.push({ resource: e.target.files[i] })
        }
      // ))
      console.log(newFiles)
      this.setState({
        newFiles: newFiles,
        // resourceUrls: resources,
        fileRef: [],
      });
    }
    this.props.setSuccessStatus({
      status: false,
      title: "",
      message: "",
    });
    this.props.setFormStatus({
      status: false,
      title: "",
      message: "",
    });
  };

  removeResource = (index) => {
    console.log(tempArray)
    var tempArray = this.state.resourceUrls;
    tempArray.splice(index, 1);
    this.setState({ resourceUrls: tempArray, fileRef: tempArray, confirmationModal: false });
    console.log(tempArray)
  };

  documentUploadOnClick = (event) => {
    if(this.state.newFiles.length > 0) {
      this.setState({
        isUploading: true,
      });
      this._startDocUpload(this.state.newFiles);
    }
    
  };

  checkQuestionStatus = () => {
    var tempArray = this.state.questions;
    var errorStatus = "";
    var correctAnswer = [];
    var temp = [];

    tempArray.map((q, qIndex) =>
      q.question != ""
        ? (errorStatus = "")
        : (errorStatus = "There cannot be an empty question field!")
    );
    console.log(errorStatus);
    return errorStatus;
  };

  handleFormSubmit = () => {
    const {
      examName,
      description,
      questions,
      resourceUrls,
      supportingLanguages,
      fileRef,
      newFiles
    } = this.state;
    var data;
    var questionErrorMsg;
    if (questions.length > 0) {
      questionErrorMsg = this.checkQuestionStatus();
      console.log(newFiles);
    }

    if (examName === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a title.",
      });
    } else if (!questions.length > 0) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "A SBL must have at least one question",
      });
    } else if (questionErrorMsg != "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: questionErrorMsg,
      });
    } else if (newFiles.length > 0) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please upload the selected file(s).",
      });
    } else {
      this.props.setFormStatus({
        status: false,
        title: "",
        message: "",
      });
      const supportingLangs = { languages: [] };
      const tempArr = supportingLanguages.filter(
        (obj) => obj.supporting === true
      );
      if (tempArr.length > 0) {
        tempArr.map((lang) =>
          lang.key != "en" ? supportingLangs.languages.push(lang.key) : null
        );
      }
      data = {
        examName: examName,
        description: description,
        resourceUrls: { resourceUrls: fileRef },
        questions: { questions: questions },
        supportingLangs: supportingLangs,
      };
      this.createExam(data)
        .then((result) => {
          console.log(result);
          this.setState({
            examName: "",
            description: "",
            questions: [],
            resourceUrls: [],
            fileRef: [],
          });

          this.props.setSuccessStatus({
            status: true,
            title: "",
            message: "SBL created successfully!",
          });
    
      
        })
    }
  };

  handleFormUpdate = () => {
    console.log('oooookkkkkkOKOKOKOKOKOKOKO')
    const {
      examId,
      examName,
      description,
      questions,
      resourceUrls,
      supportingLanguages,
      fileRef,
      newFiles,
      languageEnabled
    } = this.state;
    var data;
    var questionErrorMsg;
    if (questions.length > 0) {
      questionErrorMsg = this.checkQuestionStatus();
      console.log(questionErrorMsg);
    }

    if (examName === "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a title.",
      });
    } else if (!questions.length > 0) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "This SBL must have at least one question.",
      });
    } else if (questionErrorMsg != "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: questionErrorMsg,
      });
    } else if (newFiles.length > 0) {
      console.log(resourceUrls, fileRef);
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please upload the selected file(s).",
      });
    } else {
      this.props.setFormStatus({
        status: false,
        title: "",
        message: "",
      });
      const supportingLangs = { languages: [] };
      const tempArr = supportingLanguages.filter(
        (obj) => obj.supporting === true
      );
      if (tempArr.length > 0) {
        tempArr.map((lang) =>
          lang.key != "en" ? supportingLangs.languages.push(lang.key) : null
        );
      }
      console.log('LLLLLLLLLLLLLLLLL')
      console.log(fileRef)
      console.log(this.state.formLanguage)
      data = {
        examId: examId,
        examName: examName,
        description: description,
        resourceUrls: { resourceUrls: fileRef },
        questions: { questions: questions },
        lang: this.state.formLanguage,
        languageEnabled
      };
      this.updateExam(data)
        .then((result) => {
          this.props.setSuccessStatus({
            status: true,
            title: "",
            message: "SBL uploaded successfully!",
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
          this.props.setFormStatus({
            status: true,
            title: "Oops!",
            message:
            <div>
              An error occurred while trying to update the SBL! <br/>
              Please try again!
            </div>
              
          });
        });
    }
  };

  handleCheckBoxChange = (status) => {
    this.setState({ languageEnabled: status });
  }

  render() {
    const {
      formAction,
      formLanguage,
      examId,
      examName,
      description,
      questions,
      resourceUrls,
      isActive,
      loading,
      supportingLanguages,
      supportingLangErrorMsg,
    } = this.state;
    const {
      formErrorStatus,
      formSuccessState,
      translatingLanguages,
    } = this.props;
    console.log(this.state.fileRef);
    console.log(this.state.resourceUrls);
    console.log(this.state.newFiles)
    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>Home</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("MANAGEEXAM")}>
                Manage SBL
              </a>
            </li>
            {formAction == "ADD" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("ADDEXAM")}>
                  Add SBL
                </a>
              </li>
            ) : formAction == "EDIT" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("EDITEXAM")}>
                  Edit SBL
                </a>
              </li>
            ) : null}
          </ul>
        </div>

        <div className="uk-flex-inline uk-flex-middle">
          <i className="far fa-newspaper" style={{fontSize: '2.5rem', marginRight: '1rem'}}></i>

          {formAction == "ADD" ? (
            <h4 className="uk-margin-remove"> Add New SBL </h4>
          ) : formAction == "EDIT" ? (
            <h4 className="uk-margin-remove"> Edit SBL </h4>
          ) : null}
        </div>

        <div className="uk-margin-medium">
          <form className="ui form">
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="far fa-newspaper uk-margin-small-right"></i>{" "}
                    SBL Details
                    {
                      formAction == "EDIT" ?
                      translatingLanguages.length > 0 ?
                      <div className="formLanguageSwitcherDiv">
                        {
                        translatingLanguages.map(languageTab => (
                          <span circular icon="download" className={ formLanguage === languageTab.key ? "formLanguageSwitcherActive"  : "formLanguageSwitcher" } onClick={() => this.changeFormLanguage(languageTab.key)}>
                          {languageTab.language}
                          </span>
                        ))
                        }
                      </div>
                        : null
                      :null
                    }
                    {/* {formAction == "EDIT" ? (
                      <div className="formLanguageSwitcherDiv">
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "en"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("en")}
                        >
                          English
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "fr"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("fr")}
                        >
                          French
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "ar"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("ar")}
                        >
                          Arabic
                        </span>
                      </div>
                    ) : null} */}
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">

                  {
                      this.state.formAction === "EDIT" ?
                          this.state.formLanguage != "en" ?
                              <div className="disableLanguageVersionDiv">
                                  <input type="checkbox" id="languageEnabled" name="languageEnabled" checked={this.state.languageEnabled} onChange={() => this.handleCheckBoxChange(!this.state.languageEnabled)} />
                                  <label>{this.props.languageKeys[this.state.formLanguage]} version enabled</label>
                              </div>
                          : null
                      : null
                    }

                    <div className="uk-form-label"> SBL Title </div>
                    <input
                      value={this.state.examName || ""}
                      className="uk-input"
                      rows="1"
                      id="examName"
                      name="examName"
                      type="text"
                      onChange={this.handleChange}
                    ></input>
                    
                    {formAction === "ADD" ? (
                      <div>
                        <div className="uk-form-label">
                          {" "}
                          <text>Supporting Languages</text>
                          <text className="langErrorMessage">
                            {this.state.supportingLangErrorMsg}
                          </text>{" "}
                        </div>
                        {supportingLanguages
                          ? supportingLanguages.length > 0
                            ? supportingLanguages.map((lang) => (
                                <span
                                  className={
                                    lang.supporting === true
                                      ? "supportingLanguageActive"
                                      : "supportingLanguage"
                                  }
                                  onClick={() =>
                                    this.handleLanguageClick(
                                      lang.language,
                                      !lang.supporting
                                    )
                                  }
                                >
                                  {lang.language}
                                </span>
                              ))
                            : null
                          : null}
                      </div>
                    ) : null}
                    <div className="uk-form-label"> Description </div>
                    <textarea
                      value={this.state.description || ""}
                      className="uk-textarea"
                      rows="3"
                      id="description"
                      name="description"
                      onChange={this.handleChange}
                    ></textarea>
                    <div style={{display:"flex"}} className="uk-form-label"> Resource(s)  <span> <p style={{color:"#b54141", fontSize: "small", marginBottom:"12px", marginTop:"3px", paddingLeft:"4px"}}> (Please upload PDF files only)</p>
                    </span> </div>
                    <div className="uk-width-auto@m documentUpladDivs">
                      <Input
                        onChange={this.documentUploadOnChange}
                        name="upload"
                        id="upload"
                        className="uk-input uk-form-width-large "
                        placeholder="Upload here"
                        type="file"
                        multiple
                      />
                      <span
                        class="uk-button uk-button-primary examResourceUploadBtn"
                        onClick={this.documentUploadOnClick}
                        disabled
                      >
                        {loading ? (
                          <span
                            data-uk-spinner=""
                            style={{ color: "#o6a7e3" }}
                          />
                        ) : (
                          "Upload Resource"
                        )}
                      </span>
                    </div>
                    {resourceUrls ? (
                      resourceUrls.length > 0 ? (
                        <div className="examImageDiv uk-width-huge ">
                          {resourceUrls.map((res, index) => (
                            <div className="examDocIconDiv">
                              <img
                                src={DocumentIcon}
                                className="examDocIcon"
                                uk-tooltip={
                                  res.resource ? res.resource.name : null
                                }
                                onClick={() =>
                                  this.openDocument(
                                    formAction === "ADD"
                                      ? this.state.fileRef[index]
                                      : res.resourceUrl
                                  )
                                }
                              />
                              <img
                                src={RemoveIcon}
                                className="examDocRemoveIcon"
                                onClick={() => 
                                  // this.removeResource(index)
                                  this.setState({confirmationModal: true, clickedResourceIndexRemove: index})
                                }
                                uk-tooltip="Remove File"
                              />
                            </div>
                          ))}
                        </div>
                      ) : null
                    ) : null}
                    {this.state.isUploading ? (
                      <div className="uk-text-center uk-grid">
                        <div className="uk-width-expand@m">
                          <h5 className="uk-align-left">
                          Just a moment… <br/>
                          We are uploading your content.
                          </h5>
                        </div>
                        <div className="uk-width-auto@m">
                          <span
                            data-uk-spinner=""
                            style={{ color: "#o6a7e3" }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    <div className="uk-form-label"> Question(s) </div>
                    {questions
                      ? questions.length > 0
                        ? questions.map((question, index) => (
                            <div className="examQuestionDiv">
                              <div className="uk-form-label examQuestion">
                                Question {index + 1}:
                              </div>
                              <textarea
                                value={question.question}
                                className="uk-textarea "
                                rows="2"
                                id="question"
                                name="question"
                                onChange={(e, data) =>
                                  this.handleQuestionChange(e, data, index)
                                }
                              ></textarea>
                              <i
                                className="fas fa-minus-circle qaRemoveIcon"
                                onClick={() => this.removeQuestion(index)}
                              ></i>
                              {/* <text className="examMarksText">
                                Marks Allocated:
                              </text>
                              <input
                                value={question.marks}
                                className="uk-input"
                                rows="1"
                                id="marks"
                                name="marks"
                                type="number"
                                onChange={(e, data) =>
                                  this.handleAnswerChange(e, data, index)
                                }
                                style={{
                                  width: "5rem",
                                  height: "2rem",
                                  textAlign: "right",
                                }}
                              ></input> */}
                            </div>
                          ))
                        : null
                      : null}
                    <Button
                      className="uk-button uk-button-default uk-margin adminExamQuestionButton adminSectionOtherButtons1"
                      type="button"
                      onClick={() => this.addQuestion()}
                    >
                      <i className="fas fa-plus-circle"></i> Question
                    </Button>
                  </div>
                </div>
                {formAction === "ADD" ? (
                  <Button
                    className="uk-button uk-button-primary uk-margin"
                    style={{ height: 40 }}
                    type="button"
                    disabled={loading ? true : false}
                    onClick={() => this.handleFormSubmit()}
                  >
                    {loading ? (
                      <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                ) : (
                  <Button
                    className="uk-button uk-button-primary uk-margin"
                    style={{ height: 40 }}
                    type="button"
                    disabled={loading ? true : false}
                    onClick={() => this.handleFormUpdate()}
                  >
                    {loading ? (
                      <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                )}

                {formErrorStatus.status ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                ) : formSuccessState.status ? (
                  <div className="success_alert">
                    {formSuccessState.message}{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
        {
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to delete this document?" confirmDelete={() => this.removeResource(this.state.clickedResourceIndexRemove)} closeModal={() => this.setState({confirmationModal: false})} />
          : null
        }
      </div>
    );
  }
}

AddExam = connect(mapStateToProps, mapDispatchToProps)(AddExam);

export default withApollo(reduxForm({ form: "fieldArrays" })(AddExam));

import * as React from 'react';

class HelpBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="topic1 hero-bg shadow-small">
                <div className="uk-grid">        
                        <h3 className="uk-light uk-text uk-text-bold uk-text-white"> Advice and Answers from IPPF Team  ... </h3>           
                </div>                 
            </div>
        )
    }
}

export default HelpBanner; 
import * as React from 'react';
import Moment from 'react-moment';
import Header from '../../../header/header';

class Single_news_banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { newsbanner, newsTitle, newsHelperText, createdAt } = this.props;
        const dateToFormat = createdAt;


        return (
            <div className="banner_wraper" >
                 {/* data-src={newsbanner} */}
                <div  style={{backgroundImage:"linear-gradient(to right, rgb(24, 41, 120) 50% ,rgb(24, 41, 120,0.9) )",minHeight:"140px",maxHeight:"140px", objectFit:"cover"}}  className="uk-position-bottomsingle-news-header" uk-img="" className="single-news-header uk-height-large uk-background-norepeat  uk-background-center-center    uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover">
                    <div className="header_wrapper">
                        <div className="sigle-page-header">
                            <Header />
                        </div>
                        <div className="newsBanner uk-width-1-1 uk-container uk-margin-large-top uk-padding-remove-bottom">
                            {localStorage.LANG=='ar'?(
                            <h1 style={{paddingTop:"12px"}} className="uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top"> {newsTitle} </h1>
                            ):(
                            <h1 className="uk-text-bold uk-text-white uk-margin-large-left uk-margin-large-top"> {newsTitle} </h1>

                            )}
                            {/* <p className="uk-margin-large-left  uk-text-white">
                                Written by Super User on <time dateTime="2019-02-08T14:18:59+00:00"><Moment format="D MMM YYYY">{dateToFormat}</Moment></time>. Posted in News </p> */}
                        </div>
                    </div>
                    <div className="overlay"> </div>
                </div>
            </div>
        )
    }
}

export default Single_news_banner;
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Footer from '../../../components/footer/footer';
import Gallery_folder from '../../../components/galleries/gallery_folder/gallery_folder'
import Banner from '../../../components/galleries/banner/banner'
// query
import { GET_GALLERY } from '../../../queries/common_queries'
import Header from '../../../components/header/header';
import { withNamespaces } from 'react-i18next';


class GalleryImages extends React.Component {
    constructor(props) {
        super(props);
        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');
        this.state = {
            id: id,
            galleryTitle: "",
            galleryImages: [],
            galleryId: id,
            loading: false,
            galleryBannerImage: "",
            pagination: {
                galleryId: id,
                isActive: true,
                lang: localStorage.LANG
            },
            nextPage: 1,
            previousPage: 0,
            videos: [],
            finalList: [],
        }
    }

    componentDidMount() {
        console.log("--------------------")
        this.setState({ loading: true });

        // Get Gallery


        //Get GalleryImages
        this.get_galleryImages(this.state.pagination).then(result => {
            const videoList = result.gallery.videos ? result.gallery.videos.videos ? result.gallery.videos.videos : [] : [];
            const imageList = result.galleryImages ? result.galleryImages.galleryImages ? result.galleryImages.galleryImages : [] : [];
            const temp = imageList;
            console.log(videoList, imageList)
            if (videoList.length > 0) {
                videoList.map(video => (
                    temp.push({ image: 'https://kmsvideo.skotie.com/' + video.url, description: video.description, id: 'video' })
                ))
            }
            this.setState({
                galleryid: result.gallery.id,
                galleryTitle: result.gallery.galleryTitle,
                galleryBannerImage: result.gallery.galleryBannerImage,
                galleryImages: result.galleryImages.galleryImages,
                id: result.galleryImages.galleryImages.id,
                videos: videoList,
                finalList: temp
            })
        })
    }

    get_galleryImages = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_GALLERY,
            variables: pagination,
            fetchPolicy: 'network-only'
            //  fetchPolicy: 'cache-first'
        });
        console.log(result);
        return result.data.getGallery;
    }

    handlePagination = async (pageNo) => {
        console.log(pageNo)
        if (pageNo >= 0) {
            this.state.nextPage = pageNo + 1;
        }
        if (pageNo > 0) {
            this.state.previousPage = pageNo - 1;
        }

        var galleryId = this.state.gallery.id;
        console.log(galleryId)
        var paginations = {
            skip: pageNo * 6,
            first: 6,
            moduleId: galleryId

        };
        this.get_sub_modules(paginations).then(result => {
        })

        if (pageNo > 0) {
            this.get_galleryImages(paginations).then(result => {
                this.setState({ sub_modules: result.galleryImages.galleryImages, count: result.galleryImages.count });
            })
        } else {
            this.get_galleryImages(this.state.pagination).then(result => {
                this.setState({ sub_modules: result.galleryImages.galleryImages, count: result.galleryImages.count });
            })
        }
    }

    breadcrumbredirect = (path) => {
        if (path == "GALLERY") {
            this.props.history.push('/gallery');
        } else {
            return false;
        }
    }

    render() {
        const { galleryImages, galleryTitle, id, galleryBannerImage, videos } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Navbar />
                <div className="app">
                    <Banner bannerimage={galleryBannerImage} galleryTitle={galleryTitle} />
                    <div className="uk-container">
                        <br />
                        <div>
                            <ul className="uk-breadcrumb">
                                <li ><a onClick={() => this.breadcrumbredirect("GALLERY")}>{t('gallery_tab')}</a></li>
                                <li ><a onClick={() => this.breadcrumbredirect("GALLERYIMAGE")}>{t('gallery_image')}</a></li>
                            </ul>
                        </div>
                        <br />
                        {/* {
                            galleryImages.length > 0 ?
                                <div className="galleryImagesTitles">Photographs/Images</div>
                            : null
                        }
                            <div uk-grid uk-lightbox="animation: slide" className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" >
                                {
                                    galleryImages.map((galleryImage, x) => (
                                        <Gallery_folder key={x} galleryImage={galleryImage} id={galleryImage.id} />
                                    ))
                                }
                                
                        </div>
                        {
                            videos.length > 0 ?
                                <React.Fragment>
                                    <br />
                                    <br />
                                    <div className="galleryImagesTitles2">Videos</div>
                                    <br />
                                    <br />
                                </React.Fragment>
                                : null
                        }
                        
                        <div uk-grid uk-lightbox="animation: slide" className="uk-child-width-1-2@s uk-child-width-1-3@m uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200">
                                {
                                    videos.length > 0 ?
                                        videos.map(x => (
                                            <div className="video-cover" style={{verticalAlign: 'top'}}>
                                            <video className="video" height="200" style={{height: '10rem'}} controls>
                                                <source src={x.url} />
                                            </video>
                                        <text className="videoCaptionUser" style={{fontSize: '0.8rem', color: 'black'}}>{x.description}</text>
                                        </div>
                                        ))
                                        : null
                                }
                                
                        </div> */}

                        <div uk-grid uk-lightbox="animation: slide" className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" >
                            {
                                this.state.finalList.length > 0 ?
                                    this.state.finalList.map((galleryImage, x) => (
                                        // galleryImage.id === 'video' ?
                                        // <div className="video-cover" style={{verticalAlign: 'top'}}>
                                        //     <video className="video" height="200" style={{height: '10rem'}} controls>
                                        //         <source src={galleryImage.image} />
                                        //     </video>
                                        // <text className="videoCaptionUser" style={{fontSize: '0.8rem', color: 'black'}}>{galleryImage.description}</text>
                                        // </div>
                                        // :
                                        // <Gallery_folder key={x} galleryImage={galleryImage} id={galleryImage.id} />

                                        galleryImage.id === 'video' ?
                                            <ul className=" switcher-container">
                                                <div className="uk-container">
                                                    <div className="uk-clearfix boundary-align">
                                                        <div className="uk-float-left section-heading none-border">
                                                        </div>
                                                    </div>
                                                    <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip  img-size" >
                                                        {/* <div uk-lightbox="animation:slide"> */}
                                                        <a className="uk-inline" href={galleryImage.image} data-caption={galleryImage.description} >
                                                            <div id="galleryVideoCardDiv" className="video-cover1">
                                                                <video preload="metadata" key={galleryImage.image} className="video1" controls>
                                                                    <source src={galleryImage.image} />
                                                                </video>  </div>                          </a>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </ul>
                                            :
                                            <Gallery_folder key={x} galleryImage={galleryImage} id={galleryImage.id} />
                                    ))
                                    : null
                            }
                        </div>
                        {/* <br />
                                {
                                    videos.length > 0 ?
                                        videos.map(x => (
                                            <div className="video-cover">
                                            <video className="video" height="200" style={{height: '10rem'}} controls>
                                                <source src={x.url} />
                                            </video>
                                        </div>
                                        ))
                                        : null
                                } */}
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
                <Footer language={localStorage.LANG} />
            </div>
        )
    }
};

export default withRouter(withApollo(withNamespaces()(GalleryImages)));
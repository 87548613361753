import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

class Terms_conditionsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state={
            showContent:false,
            item:1,
        }
    }
operation(){
    this.setState({
        showContent:!this.state.showContent,
        
    })
}

getImageName = () => this.state.showContent ? 'minus': 'plus'

    render(){
        const {item } = this.state;
        console.log(item);
        console.log(this.state.showContent)
        const isplus= this.state.showContent
        
        return(
            <div className="uk-container">
             <div class="uk-margin-medium-top uk-grid-large" uk-grid >
             <h2 class="uk-heading-line uk-margin-remove-top uk-text-left"> <span> Terms Conditions </span> </h2>
                <article class="uk-card uk-card-default uk-card-small uk-card-body uk-article tm-ignore-container">
                    <ul class="uk-list-divider uk-list-large uk-accordion" uk-accordion>
                        <li>
                            <a  onClick={()=> this.operation()} > { isplus?  <p class="fa fa-minus-circle" > Lorem ipsum dolor sit amet, consectetur adipiscing elit?  </p>  : <p class="fa fa-plus-circle" >  Lorem ipsum dolor sit amet, consectetur adipiscing elit?  </p> }</a>
                        </li>

                    {
                        isplus?
                            <div class="uk-accordion-content">
                                <p>Vivamus imperdiet venenatis est. Phasellus vitae mauris imperdiet, condimentum eros vel, ullamcorper turpis. Maecenas sed libero quis orci egestas vehicula fermentum id diam. In sodales quam quis mi mollis eleifend id sit amet velit. Sed ultricies condimentum magna, vel commodo dolor luctus in. Aliquam et orci nibh. Nunc purus metus, aliquam vitae venenatis sit amet, porta non est. Proin vehicula nisi eu molestie varius. Pellentesque semper ex diam, at tristique ipsum varius sed. Pellentesque non metus ullamcorper, iaculis nibh quis, facilisis lorem. Sed malesuada eu lacus sit amet feugiat. Aenean iaculis dui sed quam consectetur elementum.</p>
                            </div>
                            :
                            null
                    }
                    </ul>
                </article>
                </div>
        </div>
             
        )
    }
}

export default withRouter(withApollo(Terms_conditionsContainer));
import React from 'react'

export default props => 
    <div className='fadein'>
      <div 
        // onClick={() => props.removeImage(props.images.public_id)} 
        className='delete'
      >
      </div>
      <img 
        style={{ width: "200px" }}
        src={props.image}
        alt='' 
        // onError={() => props.onError(props.images.public_id)}
      />
    </div>
  
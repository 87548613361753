import * as React from 'react';
import { formatDate } from '../../../middleware/index'
import { withNamespaces } from "react-i18next";
import moment from "moment";

class Card_news extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { newses, t } = this.props;
        return (
            <div>
                { newses.url ? (
                    <div className="" >
                        <div onClick={() => this.props.open_news()} className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
                            <a target="_blank" className="uk-link-reset" >
                                <img src={newses.newsBannerImage} className="course-img global-img-size" />
                                <div className="uk-card-body">
                                    <h4>{newses.newsTitle}</h4>
                                </div>

                                <div className="uk-grid uk-card-footer">
                                    {
                                        // localStorage.LANG=="ar"? (
                                        //     <div className="uk-width-1-1@m">
                                        //     {t('uploaded_date')} {
                                        //         moment(newses.createdAt, "YYYY-MM-DD").format(
                                        //             "YYYY-MM-DD"
                                        //         )}
                                        // </div>
                                        // ):(
                                            <div className="uk-width-1-1@m">
                                            {t('uploaded_date')} {formatDate(newses.createdAt)}
                                        </div>
                                        // )
                                        
                                    }
                                </div>
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className="" onClick={() => this.props.get_news()}>
                        <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
                            <a target="_blank" className="uk-link-reset" >
                                <img src={newses.newsBannerImage} className="course-img global-img-size" />
                                <div className="uk-card-body">
                                    <h4>{newses.newsTitle}</h4>
                                </div>

                                <div className="uk-grid uk-card-footer">
                                    <div className="uk-width-1-1@m">
                                        {t('uploaded_date')} {formatDate(newses.createdAt)}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export function Card_loading_news() {
    return <div>
        <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
            {/* <a href="" className="uk-link-reset" > */}
            <div className="uk-card-body">
                <img src={require("../../../images/default.jpg")} className="course-img" />
                <div className="loading_div"><h4 className="loading_text"></h4></div>
                <hr className="uk-margin-remove-top" />
                <div className="uk-clearfix">
                </div>
            </div>
            {/* </a> */}
        </div>
    </div>;
}

export default withNamespaces()(Card_news);
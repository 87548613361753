import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { formatDate } from '../../middleware/index'
// import { UPDATE_GALLERY } from '../../queries/user_queries'

import '../../App.css';
import not_pinned from '../../images/Programming-Pin-icon.png';
import pinned from '../../images/pin_user.png';
import { withNamespaces } from 'react-i18next';
import moment from "moment";
import { result } from 'underscore';

class Galleries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryDetails: this.props.gallery,
            lang: localStorage.LANG,
        }
    }

    // saveStatus = async (galleryId, pinned) => {
    //     console.log(galleryId)
    //     console.log(pinned)
    //     // this.setState({ loading: true });
    //     const lang = this.state.lang;
    //     this.props.client
    //         .mutate({
    //             mutation: UPDATE_GALLERY,
    //             variables: {
    //                 galleryId: galleryId,
    //                 pinned: pinned,
    //                 lang: lang
    //             },
    //         })
    //         .then((result) => {
    //             console.log(result)
    //             window.location.reload()
    //         });
    // };

    // changeStatus = (id, status) => {
    //     console.log(status)
    //     console.log(id);
    //     var data = "";
    //     if (status) {
    //         data = false;
    //     } else {
    //         data = true;
    //     }
    //     this.saveStatus(id, data);
    // }


    // updateGallery = async () => {
    //     const { galleryId, pinned } = this.state;
    //     const lang = this.state.lang;
    //     const result = await this.props.client.mutate({
    //         mutation: UPDATE_GALLERY,
    //         variables: { galleryId, lang, pinned },
    //     });
    //     return result.data.updateGallery;
    // }


    render() {
        const { gallery, t } = this.props;
        const { galleryDetails } = this.state;
        return (
            <div  onClick={() => this.props.get_gallery()}>
                <div className="uk-card uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0" >
                    <a className="uk-link-reset">
                        <img src={galleryDetails.galleryBannerImage} className="course-img global-img-size" />
                        <div className="uk-card-body">
                            <h4> {galleryDetails.galleryTitle} </h4>
                        </div>
                    </a>
                    <div className="uk-grid uk-card-footer" >
                    {/* {localStorage.LANG=="fr" ? (
                         <div className="uk-width-1-1@m" style={{ display: "flex" }}>
                         {t('event_date')} <br/> {formatDate(galleryDetails.eventDate)}
                         {galleryDetails.pinned ? (
                             <div>
                             <img
                                 src={pinned}
                                 className="pinIcon_user"
                                 uk-tooltip={t('pinned_album')}
                                 delay= "500"
                                 pos= "top" 
                                 animation= "uk-animation-scale-up"
                                 />
                             </div>     
                         ) : ("")
                         }
                        </div>
                    ):(
                        localStorage.LANG=="sp" ? (
                            <div className="uk-width-1-1@m" style={{ display: "flex" }}>
                         {t('event_date')} <br/> {formatDate(galleryDetails.eventDate)}
                         {galleryDetails.pinned ? (
                             <div>
                             <img
                                 src={pinned}
                                 className="pinIcon_user"
                                 uk-tooltip={t('pinned_album')}
                                 delay= "500"
                                 pos= "top" 
                                 animation= "uk-animation-scale-up" 
                                 />
                             </div>       
                         ) : ("")
                         }
                     </div>
                        ):( */}
                            <div className="uk-width-1-1@m" style={{ display: "flex" }}>
                         
                               <div style={{fontSize:".85rem"}}>{t('event_date')} {formatDate(galleryDetails.eventDate)}</div>
                           
                            {galleryDetails.pinned ? (
                                <div>
                                <img
                                    src={pinned}
                                    className="pinIcon_user"
                                    uk-tooltip={t('pinned_album')}
                                    delay= "500"
                                    pos= "top" 
                                    animation= "uk-animation-scale-up" 
                                    />
                                </div>       
                            ) : ("")
                            }
                        </div>
                        {/* )
                    )} */}
                    </div>
                </div>
            </div>
        )
    }
}

export function Loading_gallery() {
    return <div>
        <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle">
            <a href="" className="uk-link-reset" >
                <div className="uk-card-body">
                    <img src="../../../assets/images/news/2.jpg" className="course-img" />
                    <div className="loading_div"><h4 className="loading_text"></h4></div>
                    <hr className="uk-margin-remove-top" />
                    <div className="uk-clearfix">
                        <h4></h4>
                    </div>
                </div>
            </a>
        </div>
    </div>;

}

export default withRouter(withApollo(withNamespaces()(Galleries)));
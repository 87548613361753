import React, { Component } from "react";
import About from "../../user/aboutus/aboutus";
import Contact from "../../user/contact/contact";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Image, Dropdown } from "semantic-ui-react";
import { isLoggedIn } from "../../../services/CacheOperations";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import BannerImg from "../../../assets/images/backgrounds/kms-image.png";
import LoginComponent from "../../../components/login/LoginComponent2";
import LogoEncyte from "../../../assets/images/logo.png";
import { Button } from "react-uikit";

import {
  ippfauthtoken,
  USER_ID,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  USER_TYPE,
} from "../../../constants/genarics";
import AnnouncementLine from "../../../components/announcementLine/announcementLine";
import ForgotPassword from "../../../components/forgotPassword/forgot_password";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { GET_ANNOUNCEMENTS } from "../../../queries/common_queries";
import Login2Image from "../../../images/login22.png";
import { EDIT_BASIC_PROFILE } from "../../../queries/user_queries";

const languageOptions = [
  { text: "English", value: "en", key: "en" },
  { text: "Arabic", value: "ar", key: "ar" },
  { text: "French", value: "fr", key: "fr" },
  { text: "Spanish", value: "sp", key: "sp" },
];

class Login extends Component {
  constructor(props) {
    super(props);
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
        : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
          : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
            : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
    this.state = {
      announcements: [],
    };
  }

  componentDidMount() {
    this.get_all_announcements().then((result) => {
      console.log(result);
      if (result.length > 0) {
        this.setState({
          announcements: result,
        });
      }
    });
  }

  logout = () => {
    setTimeout(() => {
      localStorage.removeItem(ippfauthtoken);
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(EMAIL);
      localStorage.removeItem(FIRST_NAME);
      localStorage.removeItem(LAST_NAME);
      localStorage.removeItem(USER_TYPE);
      this.props.history.push("/login");
    }, 500);
  };

  redirectToRegister = () => {
    this.props.history.push("/registration");
  };

  navigate = (path) => {
    console.log(path);
    this.props.history.push(path);
  };

  // changeLanguage = async (e, { value }, data) => {
  //   localStorage.LANG = value;
  //   window.location.reload();
  // };

  changeLanguage = async (e, { value }, data) => {
    localStorage.LANG = value;
    if (localStorage.USER_ID != undefined) {
      this.update_user_language(value).then((result) => {
        window.location.reload();
      });
    }
    else {
      window.location.reload();
    }
  };

  update_user_language = async (lang) => {
    const result = await this.props.client.mutate({
      mutation: EDIT_BASIC_PROFILE,
      variables: { userId: localStorage.USER_ID, languagePreference: lang }
    });
    return;
  };

  get_all_announcements = async () => {
    const result = await this.props.client.query({
      query: GET_ANNOUNCEMENTS,
      variables: {
        lang: localStorage.LANG,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        isActive: true,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getAnnouncements.announcements;
  };

  render() {
    const { announcements } = this.state;
    return (
      <div>
        <div></div>
        {/* Begin Main */}
        <div id="spinneroverlay">
          <div className="spinner"></div>
        </div>
        {/* End Main */}
        {/* Begin NavBar */}
        <div className="uk-container loginNavBar">
          <nav className="paddingHeader uk-navbar uk-navbar-transparent">
            <div className="uk-navbar-left">
              <a href="#" className="uk-logo black-logo">
                {/* <Image
                  className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                  src={require("../../../images/SE_IPPF_logo.png")}
                  fluid
                /> */}
                <Image
                  className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                  src={require("../../../images/1_SE_IPPF_logo_1.png")}
                  fluid
                />
                <Image
                  float="right"
                  className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                  src={require("../../../images/2_SE_IPPF_logo_old_edited_color.png")}
                  fluid
                />
              </a>
            </div>
            <div className="lineHeight uk-navbar-right">
              <ul className=" uk-navbar-nav toolbar-nav">
                <li>
                  <div style={{ position: "relative" }}>
                    <Dropdown
                      className="languageSwitcherLogin"
                      options={languageOptions}
                      value={localStorage.LANG}
                      onChange={this.changeLanguage}
                    />
                    <i
                      class="fa fa-caret-down langDropdownIcon"
                      aria-hidden="true"
                    ></i>
                  </div>
                </li>

                {isLoggedIn() ? (
                  <li>
                    <a href="/home">HOME</a>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <a href="/faq">FAQs</a>
                </li>
                <li>
                  <a href="/login#aboutus_section">ABOUT US</a>
                </li>

                <li>
                  <a href="/contact">CONTACT US</a>
                </li>

                {isLoggedIn() ? (
                  <li>
                    <a onClick={() => this.logout()}>LOGOUT</a>
                  </li>
                ) : (
                  ""
                )}
              </ul>

              {isLoggedIn() ? (
                ""
              ) : (
                <a
                  className="fontstyle el-content uk-button uk-button-success uk-circle"
                  href="/registration"
                >
                  {" "}
                  Register{" "}
                </a>
              )}
            </div>
          </nav>
        </div>
        {/* End NavBar */}
        {/* Begin Router */}
        <Router>
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
        </Router>
        {/* End Router */}
        {/* Begin Login Section */}
        <div className="loginSection">
          <br />
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "70%" }}>
                  <div className="loginBoxEmpty"></div>
                </td>
                <td style={{ width: "30%", padding: "20px" }}>
                  <div className="loginBox">
                    <LoginComponent />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* End Login Section */}
        {/* Begin Announcement Line */}
        <div className="loginAnnouncementLine">
          <AnnouncementLine />
        </div>
        {/* End Announcement Line */}
        {/* Begin Section 1 */}
        <div className="uk-container uk-overflow-auto loginSectionOne">
          <table style={{ width: "100%" }} class="uk-table">
            <tbody>
              <tr>
                <td style={{ width: "40%", textAlign: "align" }}>
                  <div className="">
                    <div
                      className="announcementSectionLogin"
                      style={{ padding: "2rem 0rem", textAlign: "center" }}
                    >
                      <h4 style={{ color: "#00A9E0", fontWeight: "bold" }}>
                        Announcements
                      </h4>
                      <div className="announcementBox">
                        <Carousel
                          className="announcementCarousel"
                          autoPlay
                          showThumbs={false}
                          showIndicators={false}
                          showStatus={false}
                          style={{ height: "100%" }}
                          infiniteLoop
                          stopOnHover
                          interval={10000}
                        >
                          {announcements
                            ? announcements.length > 0
                              ? announcements.map((an) => (
                                <div className="carouselDiv">
                                  <div className="annTitleDiv">
                                    <text>{an.title}</text>
                                    <br></br>
                                    <text
                                      style={{
                                        fontSize: "0.8rem",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {an.date}
                                    </text>
                                  </div>

                                  <div className="annDescDiv">
                                    <text>{an.description}</text>
                                  </div>
                                  {an.url ? (
                                    <div className="annBtnDiv">
                                      <div
                                        style={{
                                          width: "fit-content",
                                          margin: "0 auto",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <Button
                                          className="uk-button annYellowButton"
                                          type="submit"
                                          onClick={() =>
                                            this.openAnnouncement(an.url)
                                          }
                                        >
                                          Read More
                                        </Button>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ))
                              : null
                            : null}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ width: "60%" }}>
                  <div className="" style={{ paddingTop: "2rem" }}>
                    <div>
                      <h4
                        style={{
                          color: "#00A9E0",
                          fontWeight: "bold",
                          color: "#1A2674",
                          textAlign: "center",
                        }}
                      >
                        Social Enterprise Acceleration Programme (SEAP)
                      </h4>
                    </div>
                    <div>
                      <text style={styles.Align}>
                        In 2015, IPPF established the{" "}
                        <a
                          href="https://www.ippf.org/our-approach/programmes/social-enterprise-acceleration-programme"
                          target="_blank"
                        >
                          Social Enterprise Acceleration Programme
                        </a>{" "}
                        (SEAP) with the long term aim of strengthening the
                        capacity of its MAs to apply entrepreneurial best
                        practices in the health sector while delivering social
                        value and improving lives. The Programme supports the
                        establishment and growth of social enterprises to
                        increase and diversify the MAs funding base and provide
                        greater sustainability.
                      </text>
                    </div>

                    <br />
                    <br />

                    <div>
                      <h4
                        style={{
                          color: "#00A9E0",
                          fontWeight: "bold",
                          color: "#1A2674",
                          textAlign: "center",
                        }}
                      >
                        International Planned Parenthood Federation (IPPF)
                      </h4>
                    </div>
                    <div>
                      <text style={styles.Align}>
                        <a href="https://www.ippf.org" target="_blank">
                          IPPF
                        </a>{" "}
                        is a global federation working to promote Sexual and
                        Reproductive Health and Rights for all. It was first
                        formed in 1952 in Bombay (present-day Mumbai) at the 3rd
                        International Conference on Planned Parenthood. 67 years
                        later, the Federation has{" "}
                        <a
                          href="https://www.ippf.org/about-us/member-associations"
                          target="_blank"
                        >
                          130+ Member Associations
                        </a>{" "}
                        (MA) working in 145 countries, with another 26 Partners
                        working in 21 countries, totaling 166 countries. The
                        IPPF Secretariat comprises of the Central Office in
                        London and six Regional Offices, working out of seven
                        global locations.
                      </text>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* End Section 1 */}
        <br />
        <br />
        {/* Start Section 2 */}
        <div className="loginSectionTwo">
          <img src={Login2Image} />
          {/* <h2 className="image2Text">Spark a fulfilling <br/> Journey</h2> */}
        </div>
        {/* End Section 2 */}
        <br />
        <br />
        <br />
        {/* Start Section 3 */}
        <div className="uk-container" style={{}}>
          <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
            <div>
              <h4 className="semantic-font" style={{ color: "#00A9E0" }}>
                Social Enterprise in the IPPF Strategic Framework
              </h4>
            </div>
            <div>
              <h4 className="semantic-font" style={{ color: "#00A9E0" }}>
                IPPF Social Enterprise Hub
              </h4>
            </div>
          </div>
          <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
            <div>
              <text style={styles.Align}>
                IPPF recognises social enterprise as the use of entrepreneurial
                methods to generate a surplus income, which is used to finance
                social development, service delivery and humanitarian activities
                that enable the organisation to fulfil its social mission.
              </text>
              <text style={styles.Align}>
                The Federation’s commitment to social enterprise is aligned with
                Outcome 4 of IPPF’s{" "}
                <a
                  href="https://www.ippf.org/about-us/strategy"
                  target="_blank"
                >
                  Strategic Framework
                </a>{" "}
                and Priority Objective 7, which aims to “enhance operational
                effectiveness and double national and global income”. Demand for
                SRH services, including contraception, is growing. The role of
                social enterprise is increasingly recognised by donors and
                impact investors as an effective way to provide goods and
                services.{" "}
              </text>
            </div>

            <div>
              <text style={styles.Align}>
                Since January 2018,{" "}
                <a href="http://www.fpasrilanka.org/" target="_blank">
                  The Family Planning Association of Sri Lanka (FPASL){" "}
                </a>{" "}
                has functioned as IPPF’s Social Enterprise Hub (SE Hub). The
                appointment of FPASL as the SE Hub was done with a long term
                view and belief that SEAP's potential and impact will be
                maximised if delivered in the context of a well-established MA
                with significant expertise and a successful track record in
                social enterprise.{" "}
              </text>
              <text style={styles.Align}>
                {" "}
                The SE Hub is located within FPASL’s Head Office, with a
                dedicated two member team who are supported by the
                organization’s Social Marketing Unit, Communications Officer and
                Senior Management Team. The team works closely with IPPF CO and
                the six regional offices to ensure the successful implementation
                of SEAP.
              </text>
            </div>
          </div>
        </div>
        {/* End Section 3 */}
        <br />
        <br />
        {/* Begin Footer */}
        <div className="foot fontFamily uk-margin-medium-top">
          <hr className="uk-margin-remove" />
          <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative">
            <div className="uk-grid ">
              <div
                className="uk-width-2-3@m uk-width-1-2@s uk-first-column uk-margin-auto"
                uk-height-match="target: SELECTOR"
              >
                <p className="uk-link-heading uk-text-lead uk-text-bold">
                  {/* <Image
                    className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                    src={require("../../../images/SE_IPPF_logo.png")}
                    fluid
                  /> */}
                  <Image
                    className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                    src={require("../../../images/1_SE_IPPF_logo_1.png")}
                    fluid
                  />
                  <Image
                    float="right"
                    className="ui fluid image uk-display-inline-block ippf-logo adm-logo"
                    src={require("../../../images/2_SE_IPPF_logo_old_edited_color.png")}
                    fluid
                  />
                </p>
              </div>
              <div className="uk-width-expand@m uk-width-1-1@s uk-flex  uk-flex-right">
                <ul
                  style={{ fontSize: "14px" }}
                  className="uk-list tm-footer-list"
                >
                  <li>
                    <a
                      style={{ textDecoration: "none" }}
                      href="/login#aboutus_section"
                      data-uk-smooth-scroll="{offset: 190}"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      name="manual"
                      href="http://seippf.org/userguide/"
                      target="_blank"
                    >
                      {" "}
                      User Manual
                    </a>
                  </li>
                  <li>
                    <a href="/contact" target="_blank">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="https://www.ippf.org/privacy" target="_blank">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="fontFamily footerCopyright uk-section-small">
          <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative">
            <div
              style={{ fontSize: "14px" }}
              className="uk-grid uk-margin-small"
              uk-grid="true"
            >
              <div className="copyRightText uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                <p>
                  {" "}
                  Copyright (C) 2020 -{" "}
                  <span className="uk-text-bold">
                    {" "}
                    IPPF Social Enterprise Hub
                  </span>
                  . All rights reserved.{" "}
                </p>
              </div>
              <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                <p>Version 1 . 3 . 0</p>
              </div>
              <div className="image uk-width-expand@m uk-width-1-2@s">
                <p style={{ textAlign: "right" }}>
                  {" "}
                  Powered By{" "}
                  <a href="https://encyte.io" target="_blank">
                    {" "}
                    <img src={LogoEncyte} />{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer */}
      </div>
    );
  }
}

const styles = {
  darkBack: {
    backgroundImage: `url(${BannerImg})`,
    backgroundBlendMode: "color-burn",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "100%",
    // width: "100%",
  },
  poweredBy: {
    bottom: "8px",
    right: "60px",
  },
  Align: {
    // textAlign: "justify",
    color: "black",
    // fontWeight: '500',
    fontSize: "0.9rem",
  },
};

export default withRouter(withApollo(Login));

import * as React from "react";
import { withApollo } from "react-apollo";
import {
  withRouter,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import { Button } from "semantic-ui-react";
//custom-components
// import Card_news, { Card_loading_news } from '../card_news/card_news';
import Card_news, {
  Card_loading_news,
} from "../../../components/news/card_news/card_news";
//query
import {
  GET_ALL_NEWS,
  GET_EXAMS,
  GET_EXAM_SUBMISSIONS,
  GET_EXAMS_WITH_SUBMISSION_STATUSES,
} from "../../../queries/common_queries";
import NoDataMessage from "../../../components/messages/contentNotFound/NoDataFoundColored";
import LoadingCards from "../../../components/SBL_Loading/sblCardLoading";
import Loader from 'react-loader-spinner'

import { Tab } from "semantic-ui-react";
import OurNews from "../../../screens/user/news/ourNews/ourNews";
import GlobalNews from "../../../screens/user/news/globalNews/globalNews";
import Question from "../../../images/question.png";
import Resource from "../../../images/doc.png";
import Status from "../../../images/status.png";
import Comments from "../../../images/comments.png";
import Ribbon from "../../../images/ribbon.png";
import RibbonDisabled from "../../../images/ribbonDisabled.png";
import ExamCard from "../../../components/exams/examCard";
import Circle from "react-circle";
import { withNamespaces } from 'react-i18next';

class ExamContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completedExams: [],
      pendingExams: [],
      submissions: [],
      examCategory: "ALL",
    };
    // this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.get_exams_with_submission_statuses().then((result) => {
      this.setState({
        loadingSBLs: true
      })
      console.log(result);

      console.log(result.pendingExams);

      if (result.pendingExams.length > 0) {
        this.setState({
          pendingExams: result.pendingExams,
          loadingSBLs: false

        });
      }
      else {
        this.setState({
          loadingSBLs: false
        });
      }
      if (result.completedExams.length > 0) {
        this.setState({
          completedExams: result.completedExams,
          loadingSBLs: false

        });
      }
      else {
        this.setState({
          loadingSBLs: false
        });
      }
      
    });
   
  }

  get_exams_with_submission_statuses = async () => {
    const result = await this.props.client.query({
      query: GET_EXAMS_WITH_SUBMISSION_STATUSES,
      variables: {
        createdBy: localStorage.USER_ID,
        lang: localStorage.LANG
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getExamsWithSubmissionStaus;
  };

  get_user_exam_submissions = async () => {
    const result = await this.props.client.query({
      query: GET_EXAM_SUBMISSIONS,
      variables: {
        createdBy: localStorage.USER_ID,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getExamSubmissions;
  };

  redirectToExam = async (id) => {
    this.props.history.push("/sbl?id=" + id);
  };

  isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  changeCategory = (category) => {
    this.setState({
      examCategory: category,
    });
  };

  render() {
    const { pendingExams, completedExams, examCategory } = this.state;
    const { t } = this.props;
    return (
      <div className="uk-container" style={{ padding: "0rem 0rem 3rem 0rem" }}>
        <ul className="examCategoryButtonsDiv">
          <button
            onClick={() => this.changeCategory("ALL")}
            className={
              examCategory != "ALL"
                ? "uk-button examCategoryButton"
                : "uk-button examCategoryButtonActive"
            }
          >
            {t('all')}
          </button>
         
          <button
            onClick={() => this.changeCategory("PENDING")}
            className={
              examCategory != "PENDING"
                ? "uk-button examCategoryButton"
                : "uk-button examCategoryButtonActive"
            }
          >
           {t('pending')}
          </button>

          <button
            onClick={() => this.changeCategory("COMPLETED")}
            className={
              examCategory != "COMPLETED"
                ? "uk-button examCategoryButton"
                : "uk-button examCategoryButtonActive"
            }
          >
              {t('ongoing')}
          </button>
        </ul>
        {examCategory === "ALL" ? (
          <React.Fragment>
            <div className={localStorage.LANG=='ar'? "uk-form-label examQuestionText examCategory examCategory_Arabic examCatPending":"uk-form-label examQuestionText examCategory examCatPending"} >
             {t('pending')}:
            </div>
            {this.state.loadingSBLs === false ?
            pendingExams ? (
              pendingExams.length > 0 ? (
                <React.Fragment>
                  {pendingExams.slice(0).reverse().map((exam) =>
                    this.isEmpty(pendingExams) != true ? (
                      <ExamCard exam={exam} category="pending" />
                    ) : null
                  )}
                </React.Fragment>
              ) : <NoDataMessage message={t('no_pendingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:styles.messageMainDiv} />
            ) : <NoDataMessage message={t('no_pendingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:styles.messageMainDiv}  />
            :
            <Loader
                type="ThreeDots"
                color="#00A9E0"
                height={10}
                width={50}
                // timeout={3000} //3 secs
                className={localStorage.LANG=='ar'? "sblListSpinner_Arabic" : "sblListSpinner"} 
            />
            }
            <div className="examCategoryDivider"></div>

            <div className={localStorage.LANG=='ar' ? "examCategory_Arabic uk-form-label examQuestionText examCategory examCatOngoing":"uk-form-label examQuestionText examCategory examCatOngoing"} >
            {t('ongoing')}:
            </div>
            {this.state.loadingSBLs === false ?
            completedExams ? (
              completedExams.length > 0 ? (
                <React.Fragment>
                  {completedExams.slice(0).reverse().map((exam) =>
                    this.isEmpty(completedExams) != true ? (
                      <ExamCard exam={exam} category="completed" />
                    ) : null
                  )}
                  <br />
                  <hr />
                </React.Fragment>
              ) : <NoDataMessage message={t('no_ongoingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:styles.messageMainDiv} />
            ) : <NoDataMessage message={t('no_ongoingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:styles.messageMainDiv} />
          :
              <Loader
                  type="ThreeDots"
                  color="#00A9E0"
                  height={10}
                  width={50}
                  // timeout={3000} //3 secs
                  className= {localStorage.LANG=='ar'? "sblListSpinner_Arabic": "sblListSpinner"} 
              />
          }
            
          </React.Fragment>
        ) : examCategory === "COMPLETED" ? (
          <React.Fragment>
            {completedExams ? (
              completedExams.length > 0 ? (
                completedExams.slice(0).reverse().map((exam) =>
                  this.isEmpty(completedExams) != true ? (
                    <ExamCard exam={exam} category="completed" />
                  ) : null
                )
              ) : (
                <NoDataMessage message={t('no_ongoingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:null}  />
              )
            ) : (
              <NoDataMessage message={t('no_ongoingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:null} />
            )}
            <br />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {pendingExams ? (
              pendingExams.length > 0 ? (
                pendingExams.slice(0).reverse().map((exam) =>
                  this.isEmpty(pendingExams) != true ? (
                    <ExamCard exam={exam} category="pending" />
                  ) : null
                )
              ) : (
                <NoDataMessage message={t('no_pendingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:null}  />
              )
            ) : (
              <NoDataMessage message={t('no_pendingSbl')} mainDivStyle={localStorage.LANG=='ar'? styles.messageMainDiv_Arabic:null}  />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default withRouter(withApollo(withNamespaces()(ExamContainer)));

const styles = {
  messageMainDiv: {
    marginTop: '2rem',
    marginLeft: '6rem',
    color: "#750019",
  },
  messageMainDiv_Arabic:{
    marginTop: '2rem',
    marginLeft: '6rem',
    color: "#750019",
    marginRight: '4rem'
  }
};
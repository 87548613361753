import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import '../../App.css';


class News extends React.Component{
    constructor(props){
        super(props);

    }
   
    redirect_to_newssubmodule=()=>{
        this.props.history.push('/gallery')
    }
    render(){
        const { news } =this.props;
        return(
            <div>
                <div>
                    <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip" >
                        <a
                        onClick={()=> this.redirect_to_newssubmodule()} 
                         className="uk-link-reset">
                            <img  src={news.newsBannerImage} className="img-size" />
                            <div className="uk-card-body">
                                <h4> {news.newsTitle} </h4>
                                <p> {news.newsHelperText} </p>
                            </div>
                        </a>
                    </div>
                </div>
         </div>                      
        )
    }
}

export default withRouter(withApollo(News));
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import '../../../App.css';
import { GET_GALLERY } from '../../../queries/common_queries';
import { withNamespaces } from 'react-i18next';

class Gallery_folder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { galleryImage } = this.props;
        return (
            <ul className=" switcher-container">
                <div className="uk-container">
                    <div className="uk-clearfix boundary-align">
                        <div className="uk-float-left section-heading none-border">
                        </div>
                    </div>
                    <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip  img-size" >
                        {/* <div uk-lightbox="animation:slide"> */}
                            <a className="uk-inline" href={galleryImage.image} data-caption={galleryImage.imageDescription} >
                                <img className="" src={galleryImage.image} />
                            </a>
                        {/* </div> */}
                    </div>
                </div>
            </ul>
        )
    }
}
export default withRouter(withApollo(withNamespaces()(Gallery_folder))); 

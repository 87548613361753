import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../navbar/navbar';

//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';




class Sub_module_banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { sub_module } = this.props;
        return (
            <div className="course-dhashboard" style={{ backgroundImage: "linear-gradient(to right, rgb(24, 41, 120) 50% ,rgb(24, 41, 120,0.9) )", minHeight: "140px", maxHeight: "140px" }}>
                <div className="uk-grid">
                    <div>
                        {localStorage.LANG == 'ar' ? (
                            <h2 className="uk-light uk-text-bold uk-text-white" style={{ paddingTop: "22px" }}> {sub_module.subModuleTitle}</h2>
                        ) : (
                            <h2 className="uk-light uk-text-bold uk-text-white"> {sub_module.subModuleTitle}</h2>
                        )}

                        {/* <p className="uk-light uk-text-bold">{sub_module.subModuleHelperText}</p>                      */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Sub_module_banner;
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../navbar/navbar';
import QuizButtonComponent from '../../quizButtonComponent/quizButtonComponent';

//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';

//queries
import { CREATE_ANALYSTIC_RECORD, GET_QUIZ_SUBMISSION_STATUS } from '../../../queries/user_queries';
import { GET_MODULE } from '../../../queries/common_queries';


class Lesson_Container extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.id);
        console.log(this.props.from)

        if (this.props.from == "module") {
            this.state = {
                moduleId: this.props.id,
                quizStatus: false,
                pagination: { moduleId: this.props.id, lang: localStorage.LANG },
                getModuleParameter: {lang: localStorage.LANG},
                loading: false
            }
        }

        if (this.props.from == "sub_module") {
            this.state = {
                moduleId: this.props.moduleId,
                quizStatus: false,
                pagination: { subModuleId: this.props.id, lang: localStorage.LANG },
                getModuleParameter: { subModuleId: this.props.id, lang: localStorage.LANG },
                loading: true
            }

            this.get_module(this.state.getModuleParameter).then(result => {
                this.setState({ moduleId: result.id, loading: false });
            })
        }

        if (this.props.from == "lesson") {
            this.state = {
                moduleId: this.props.moduleId,
                quizStatus: false,
                pagination: { lessonId: this.props.id, lang: localStorage.LANG },
                getModuleParameter: { lessonId: this.props.id, lang: localStorage.LANG },
                loading: true
            }

            this.get_module(this.state.getModuleParameter).then(result => {
                this.getQuizSubmissionStatus(this.state.moduleId).then(res => {
                    this.setState({ moduleId: result.id, quizStatus: res, loading: false });
                })
            })
        }
    }

    componentDidMount() {
        var contentId = this.props.content.id;
        console.log(contentId)
        if (contentId != null) {
            this.createAnalyticRecord(contentId).then(result => {
                console.log(result)
            }).catch(error => {
                console.log(error);
            });
        }

    }

    createAnalyticRecord = async (contentId) => {
        let pagination = this.state.pagination;
        pagination["contentId"] = contentId;

        const result = await this.props.client.mutate({
            mutation: CREATE_ANALYSTIC_RECORD,
            variables: pagination
        });

        return result.data.createAnalyticRecord;
    };

    get_module = async (getModuleParameter) => {
        const result = await this.props.client.query({
            query: GET_MODULE,
            variables: getModuleParameter,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModule;
    };

    getQuizSubmissionStatus = async (moduleId) => {
        const result = await this.props.client.query({
            query: GET_QUIZ_SUBMISSION_STATUS,
            variables: { moduleId },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getQuizSubmissionStatus;
    };

    breadcrumbredirect = (path) => {
        if (path == "HOME") {
            this.props.history.push('/home');
        } else if (path == "MODULE") {
            this.props.history.push('/module?id=' + this.state.moduleId);
        } else if (path == "SUBMODULE") {
            this.props.history.push('/sub_module?id=' + this.props.subModuleId + '&module=' + this.state.moduleId);
        } else {
            return false;
        }

    }

    render() {
        const { moduleId, loading, quizStatus } = this.state;
        const { content, subModuleId } = this.props;
        console.log(this.props.from)

        return (
            <div class="uk-container-small uk-margin-auto uk-margin-medium-top">

                <div>

                    {subModuleId == undefined || subModuleId == '' || subModuleId == null ? (
                        this.props.from == "module" ? (
                            null
                        ) : (
                                <ul class="uk-breadcrumb">
                                    <li><a onClick={() => this.breadcrumbredirect("HOME")}>Home</a></li>
                                    <li><a onClick={() => this.breadcrumbredirect("MODULE")}>Module</a></li>
                                    <li><a onClick={() => this.breadcrumbredirect("LESSON")}>Lesson</a></li>
                                </ul>
                            )
                    ) : (
                            this.props.from == "module" ? (
                                null
                            ) : (
                                    <ul class="uk-breadcrumb">
                                        <li><a onClick={() => this.breadcrumbredirect("HOME")}>Home</a></li>
                                        <li><a onClick={() => this.breadcrumbredirect("MODULE")}>Module</a></li>
                                        <li><a onClick={() => this.breadcrumbredirect("SUBMODULE")}>Submodule</a></li>
                                        <li><a onClick={() => this.breadcrumbredirect("LESSON")}>Lesson</a></li>
                                    </ul>
                                )
                        )}
                </div>
                    {content ? (
                        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                    ) : (null)}
                    <br />
                    {loading ? (
                        null
                    ) : (<QuizButtonComponent moduleId={moduleId} quizStatus={quizStatus} />)}
                </div>
        )
    }
}

export default withRouter(withApollo(Lesson_Container));
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../navbar/navbar';

//images
import Video from '../../../assets/images/icons/Video.png';
import Students from '../../../assets/images/icons/students.png';
import Discussion from '../../../assets/images/icons/Discussion.png';


class Card_lesson extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const {lesson} =this.props;

        console.log(lesson)

        return (
            <div onClick={() => this.props.get_lesson()} className="uk-card uk-card-small uk-card-body uk-padding-remove-right uk-card-default uk-margin uk-padding-remove" style={{cursor:"pointer"}}>
                <img src={lesson.lessonBannerIconUrl} className="uk-align-left uk-width-1-3@m uk-margin-remove-bottom" />
                <div className="uk-padding-small">
                    <p className="uk-text-right">Lesson {this.props.num}</p>
                    <a href="" className="uk-link-reset">  <h4 className="uk-margin-small-bottom uk-margin-small-top">{lesson.lessonTitle}</h4>  <p>{lesson.lessonHelperText}</p> </a>
                </div>
            </div>
        )
    }
}

export default Card_lesson; 
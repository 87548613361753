import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//queries
import { GET_MODULES_FOR_ADMIN } from '../../../queries/admin_queries';
import { async } from 'q';


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ManageModules extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }
        this.state = {
            loading: false,
            pagination: {
                isActive: true,
                // skip: 0,
                // first: 6
            },
            modules: [],
            nextPage: 1,
            previousPage:0,
            currentPage:0,
            activePage:'',
            ToDos:6,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            isFirstBtnActive:'disabled',
            isLastBtnActive:'',
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event,index){
        this.setState({
            currentPage:Number(index),
            activePage:index
        })
    }

    componentDidMount() {
        this.get_all_modules(this.state.pagination).then(result=>{
            this.setState({modules:result.modules, count:result.count})
        })
    }
    componentDidUpdate(){ window.scrollTo(0,0)}

    handlePagination = async(pageNo)=> {
        console.log(pageNo);
        this.setState({
            currentPage:pageNo
        })

        if(pageNo>=0){
            this.setState({ isNextBtnActive:'',isLastBtnActive:''})
            this.state.nextPage = pageNo+1;
        }

        if(pageNo===Math.ceil(this.state.count / this.state.ToDos )-1){
            this.setState({ isNextBtnActive:'disabled',isLastBtnActive:'disabled'});
        }

        if(pageNo>0){
            this.setState({isPrevBtnActive:'',isFirstBtnActive:''})
            this.state.previousPage = pageNo-1;
        }

        if(pageNo===0){
            this.setState({ isPrevBtnActive:'disabled',isFirstBtnActive:'disabled'})
        }

        var paginations = {
            skip: pageNo * this.state.ToDos,
            first: 6
        };

        if (pageNo > 0) {
            this.get_all_modules(paginations).then(result => {
                this.setState({ modules: result.modules, count: result.count });
            });
        } else {
            this.get_all_modules(this.state.pagination).then(result => {
                this.setState({ modules: result.modules, count: result.count });
            });
        }
    }

    addNewModule() {
        this.props.history.push('/admin_add_module');
    }

    editModule(id) {
        this.props.history.push('/admin_add_module?id=' + id);
    }

    get_all_modules = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_MODULES_FOR_ADMIN,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModulesForAdmin;
    };



    render() {

        const { modules, loading, count,currentPage , nextPage, previousPage ,ToDos,isPrevBtnActive ,isNextBtnActive ,isFirstBtnActive,isLastBtnActive} = this.state;
        console.log(currentPage);

        const pages = Math.ceil(count / ToDos);

        if (pages === 0) return null;

        var items = [];

        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }

        let renderPrevBtn = null;
        if(isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className="fas fa-angle-left"></i> </span></li>
        } else{
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={()=> this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
        }

        let renderNextBtn = null;
        if(isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
        }
        else{
            renderNextBtn = <li className={isNextBtnActive}><a onClick={()=> this.handlePagination(nextPage) } id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
        }

        let renderfirstBtn = null;
        if(isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
        } else{
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={()=> this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
        }

        let renderlastBtn = null ;
        if(isLastBtnActive==='disabled'){
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
        }else{
            renderlastBtn = <li className={isLastBtnActive}><a onClick={()=>this.handlePagination(items.length -1)}  id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
        }


        return (
            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-list icon-large uk-margin-right"></i>
                    <h4 className="uk-margin-remove adminSectionTitles"> Modules </h4>

                </div>
                <div className="uk-flex-inline uk-float-right">
                    <button className="uk-button uk-button-primary admin-btn" onClick={() => this.addNewModule()}>Add Module</button>
                </div>
              <br />
              <br />

                <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                        <thead>
                            <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                                <th>Num</th>
                                <th style={{width: '9rem'}}>Module Banner</th>
                                <th>Title</th>
                                <th>Helper Text</th>
                                {/* <th>Status</th> */}
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modules.map((module, i) => (
                                    <tr key={i}>
                                         {/* {module.moduleNumber}   */}
                                        <td> { i+ 1 } </td>
                                        <td> <img src={module.bannerIconUrl} />  </td>
                                        <td> {module.moduleTitle}  </td>
                                        <td> {module.moduleHelperText} </td>
                                        {/* <td> {module.isActive ? (
                                            <p style={{ color: "green" }}>ACTIVE</p>
                                        ) : (
                                                <p style={{ color: "red" }}>INACTIVE</p>
                                            )} </td> */}
                                        <td >
                                            <button disabled={loading ? (true) : (false)} className="uk-button uk-button-warning admin-table-btn" uk-tooltip="Edit" onClick={() => this.editModule(module.id)} ><i class='fas fa-pencil-alt'></i></button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                {/* <div className="paginations uk-flex uk-flex-center">
                    <ul class="uk-pagination" uk-margin>
                        {renderfirstBtn}
                        {renderPrevBtn}

                        {
                            items.map((item,index) =>
                                <li key ={index} id={index} active={this.state.activePage=== index } onClick={(e)=> this.handleClick(e,item)}  className={currentPage===item? 'active': ''}><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                            )
                        }
                        {renderNextBtn}
                        {renderlastBtn}
                    </ul>
                </div> */}
            </div>

        );
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ManageModules))); 
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import QuizReviewBanner from '../../../components/quizReview/quizReviewBanner/quizReviewBanner';
import QuizReviewContainer from '../../../components/quizReview/quizReviewContainer/quizReviewContainer';
import Footer from '../../../components/footer/footer';
import { GET_CERTIFICATE_STATUS_OF_USER } from '../../../queries/user_queries';
import { withNamespaces } from "react-i18next";

class QuizReview extends React.Component {
    constructor(props) {
        super(props);
        window.scrollTo(-500, -200);
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        if(localStorage.USER_ID==undefined){
            this.props.history.push('/');
        }else{
            if(id==undefined){
                this.props.history.push('/home');
            }
        }

        this.state={
            moduleQuizSubmissionId:id,
        }

       
    }

    componentDidMount() {
        this.get_certificate_status().then(result => {
            console.log(result);
            if(result.status != "null"){
                console.log('Certificate Email Sent!')
            }
        })
    }

    // getModuleStats = async () => {
    //     const result = await this.props.client.query({
    //       query: GET_MODULE_STATS,
    //       fetchPolicy: "network-only",
    //     });
    //     return result.data.getModuleStats;
    //   };

    get_certificate_status = async () => {
        const result = await this.props.client.query({
            query: GET_CERTIFICATE_STATUS_OF_USER,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getCertificateObtainedByUser;
    };

    render() {
        const {moduleQuizSubmissionId} =this.state;
        const { t } = this.props;
        return (
            <div>
                <Navbar />
                <div id="spinneroverlay">
                    <div className="spinner"></div>
                </div>
                <div className="app">
                    <Header />
                    <QuizReviewBanner />
                    <QuizReviewContainer  moduleQuizSubmissionId={moduleQuizSubmissionId}/>
                </div>
                <Footer language={localStorage.LANG}/>
            </div>
        );
    }

}
export default withRouter(withApollo(withNamespaces()(QuizReview))); 

import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Header from '../../../components/header/header';
import Quizz_banner from '../../../components/quizz/quizz_banner/quizz_banner';
import Quizz_container from '../../../components/quizz/quizz_container/quizz_container';
import Footer from '../../../components/footer/footer';
import { withNamespaces } from "react-i18next";
//queries
import { GET_QUIZ_SUBMISSION_STATUS } from '../../../queries/user_queries';
import {GET_SUB_MODULES,GET_NEXT_MODULE } from '../../../queries/common_queries'

class Quizz extends React.Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        if(localStorage.USER_ID==undefined){
            this.props.history.push('/');
        }else{
            if(id==undefined){
                this.props.history.push('/home');
            }
        }

        this.state = {
            id: id,
            moduleId: id,
            sub_modules: [],
            lessons: [],
            contents: [],
            quizStatus: false,
            loading: true,
            pagination: {
                isActive: true,
                moduleId: id,
                lang:localStorage.LANG,
            },
            buttonVisible:false,
            nextModuleId: "",


        }


    }

    componentDidMount() {
        this.getQuizSubmissionStatus(this.state.moduleId).then(result => {
            this.setState({ quizStatus: result });
            this.Submodule();
        })
       
    }

    getQuizSubmissionStatus = async (moduleId) => {
        const result = await this.props.client.query({
            query: GET_QUIZ_SUBMISSION_STATUS,
            variables: { moduleId },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getQuizSubmissionStatus;
    };

    Submodule(){
        this.get_sub_modules(this.state.pagination).then(result => {
            console.log(result.subModules)
            this.setState({
                 module: result.module, sub_modules: result.subModules, lessons: result.lessons,count:result.subModules.count,
                 contents: result.contents, loading: false
            });

            this.get_next_module().then(result => {
                console.log(result.length)
                if(result.length!==0){
                    this.setState({ nextModuleId: result[0].id });
                }else{
                    this.setState({ nextModuleId: 0 });
                }
                console.log(this.state.nextModuleId)
            })
        }); 
    }

    get_sub_modules = async (pagination) => {
        // console.log(pagination)
        const result = await this.props.client.query({
            query: GET_SUB_MODULES,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModules;
    };
    get_next_module = async () => {
        const result = await this.props.client.query({
            query: GET_NEXT_MODULE,
            variables: {
                moduleId: this.state.moduleId,
                first: 1,
                lang:localStorage.LANG,
            },
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getNextModule;
    };

    passToNextModule = (nextModuleId) => {
        window.scrollTo(-500, -200);
        this.props.history.push('/module?id=' + nextModuleId);

        setTimeout(() => {
            var currentLocation = window.location.href;
            var url = currentLocation.split("?")
            url = url[0];
            url = url.split("/");
            var page = url[url.length - 1];

            let query = new URLSearchParams(this.props.location.search);
            let id = query.get('id');

            this.setState({ id: id, from: page, moduleId: id, pagination: { 
                // skip: 0, first: 3,
                 isActive: true, moduleId: id } });
            this.Submodule();
        }, 1000)
    }
    
    render() {
        const { moduleId, quizStatus, loading,sub_modules } = this.state;

        return (
            <div>
                <Navbar />
                <div id="spinneroverlay">
                    <div className="spinner"></div>
                </div>
                <div className="app">
                    <Header />
                    <Quizz_banner />
                   
                    {loading == false ? (
                        <div> 
                        <Quizz_container sub_modules = {sub_modules} moduleId={moduleId} quizStatus={quizStatus} />
                        {/* <br/>
                        {this.state.nextModuleId!==0 ? (
                            this.state.buttonVisible == true ? (
                                <button className="uk-button uk-button-primary float next-prev-btn"  onClick={() => this.passToNextModule(this.state.nextModuleId)} > Next module <i className="fas fa-angle-right"></i></button>

                            ) : (null)
                            ):("")}            */}
                        </div> 
                    ) : (
                            null
                        )}

                    <Footer language={localStorage.LANG} />
                </div>
            </div>
        );
    }

}

export default withRouter(withApollo(Quizz));

import React, { Component } from "react";
import { BrowserRouter,Route } from "react-router-dom";
// import { ippfauthtoken, USER_ID, EMAIL, FIRST_NAME, LAST_NAME, USER_TYPE } from '../../../constants/genarics';


class Timer extends Component{
    constructor(props){
        super(props);
        this.state={
            count:0,

            
        }
        
    }

    componentDidMount(){
        this.setState({
            count:this.state.count + 1
            
        })
    }

    getCurrentTime() {
        var tempDate = new Date();
        var date = tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
        // var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
        const currDate = date;
        return (
         currDate
        );
      }
      

    getPreviousNavTime(){

      }



    //  onUserNavigate() {
    //     let idleTime = this.getCurrentTime() - getPreviousNavTime();
    //     storeCurrentNavTime();
    //     if (idleTime > ALLOWED_IDLE_TIME)
    //     setTimeout(() => {
    //         localStorage.removeItem(ippfauthtoken);
    //         localStorage.removeItem(USER_ID);
    //         localStorage.removeItem(EMAIL);
    //         localStorage.removeItem(FIRST_NAME);
    //         localStorage.removeItem(LAST_NAME);
    //         localStorage.removeItem(USER_TYPE);
    //         this.props.history.push("/login");
    //     }, 500)
    //         // window.location.href = '#/security/logout';
    // }




    render(){
    const {count,currentHours}= this.state;

    var tempDate = new Date();
    var date = tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
    
        return(
            <div>
                {count}

                <Route path="/login" onEnter={this.onUserNavigate} onChange={this.onUserNavigate}>
            ...
            </Route>
                {/* Current count:{count} */}
            </div>
            
        )
    }

}
export default Timer;
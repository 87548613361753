import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import exams from "../../screens/user/exams/exams";
import moment from "moment";
// import Navbar from "../../navbar/navbar";
// import Header from "../../header/header";
// import MainBanner from "../../home/mainBanner/mainBanner";
// import Footer from "../../footer/footer";
import { Button } from "react-uikit";
import { setFormStatus, setSuccessStatus } from "../../actions/user-actions";
import { CREATE_EXAM_SUBMISSION } from "../../queries/user_queries";
import {
  GET_EXAM_SUBMISSIONS,
  UPDATE_EXAM_SUBMISSION,
  GET_SINGLE_EXAM_SUBMISSION,
} from "../../queries/common_queries";
import DocumentIcon from "../../images/doc2.png";
import ContentNotFound from "../../components/messages/contentNotFound/ContentNotFoundMessage";
import { _generate_email } from "../../middleware/index";
import Circle from "react-circle";
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class ExamContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      examSubmission: [],
      exam: this.props.exam,
      answersSubmitted: false,
      comments: [],
      commentText: "",
      changeCommentText: "",
      commentInEditing: -1,
      enableSubmitButton: false,
      confirmDelete: -1,
      grade: "",
    };
    this.questionsRef = React.createRef();

    // this.generateSubmissionArray = this.generateSubmissionArray.bind(this);
    console.log(this.props);

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.generateSubmissionArray(this.props.exam);
    this.get_user_exam_submissions().then((result) => {
      console.log(result);
      if (result.submissions.length > 0) {
        const userSubmission = result.submissions[0];
        this.setState({
          submissionId: userSubmission.id,
          grade: userSubmission.grade,
          examSubmission: userSubmission.submission.submission,
          answersSubmitted: true,
          comments: userSubmission.comments
            ? userSubmission.comments.comments
              ? userSubmission.comments.comments.length > 0
                ? userSubmission.comments.comments
                : []
              : []
            : [],
        });
      }

      // this.setState({
      //   answersSubmitted: true,
      // })
    });
  }

  generateSubmissionArray = (exam) => {
    // const { exam } = this.state;
    const tempArray = [];

    const temp = exam.questions
      ? exam.questions.questions.length > 0
        ? exam.questions.questions.map((q, index) =>
            tempArray.push({ question: q.question, answer: "", comment: "" })
          )
        : null
      : null;

    this.setState({
      examSubmission: tempArray,
      loading: false,
    });
  };

  create_exam_submission = async () => {
    const { examSubmission } = this.state;
    const result = await this.props.client.mutate({
      mutation: CREATE_EXAM_SUBMISSION,
      variables: {
        examId: this.props.exam.id,
        submission: { submission: examSubmission },
      },
    });
    console.log(result.data);
    return result.data.createExamSubmission;
  };

  get_user_exam_submissions = async () => {
    const result = await this.props.client.query({
      query: GET_EXAM_SUBMISSIONS,
      variables: {
        examId: this.props.exam.id,
        createdBy: localStorage.USER_ID,
      },
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    console.log(result.data);
    return result.data.getExamSubmissions;
  };

  updateExamSubmission = async (data) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_EXAM_SUBMISSION,
      variables: data,
    });
    console.log(result.data);
    return result.data.updateExamSubmission;
  };

  getSingleExamSubmission = async () => {
    const { submissionId } = this.state;
    console.log(submissionId);
    const result = await this.props.client.mutate({
      mutation: GET_SINGLE_EXAM_SUBMISSION,
      variables: {
        submissionId: submissionId,
      },
    });
    return result.data.getSingleExamSubmission;
  };

  openDocument = (url) => {
    window.open(url);
  };

  handleAnswerChange = (e, data, qIndex, question) => {
    const tempArray = this.state.examSubmission;
    tempArray[qIndex].answer = e.target.value;
    this.setState({
      examSubmission: tempArray,
    });
    this.props.setFormStatus({ status: false, title: "", message: "" });
    this.props.setSuccessStatus({ status: false, title: "", message: "" });
  };

  handleAnswerSubmit = () => {
    this.setState({ loading: true });
    const { examSubmission } = this.state;
    var errorStatus = "";
    if (examSubmission.length > 0) {
      var tempArray = this.state.examSubmission;
      const temp = tempArray.filter((question) => question.answer === "");
      if (temp.length > 0) {
        errorStatus = localStorage.LANG=="ar"? (<div style={{paddingRight:"20px"}} >{this.props.t('answer_all')} </div>):(<div>{this.props.t('answer_all')} </div>) ;
      } else {
        errorStatus = "";
      }
    }
    if (errorStatus != "") {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: errorStatus,
      });
    } else {
      this.create_exam_submission().then((result) => {
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message: localStorage.LANG=="ar" ? (
            <div style={{paddingRight:"20px"}}> {this.props.t('submitted_answers_for_review')} </div>
          ):(
            <div> {this.props.t('submitted_answers_for_review')} </div>
          )
          
        });
        this.setState({
          answersSubmitted: true,
        });
        const userName = result.user.firstName + " " + result.user.lastName;
        const examName = this.props.exam.examName;
        const submissionId = result.id;
        let url = "http://seippf.org/admin_exam_submission?id="+submissionId;

        const personalizations = {
          personalizations: [
            {
              to: [
                {
                  email: "rishi@fpasrilanka.org",
                  name: "Rishikeshan Thiyagaraja",
                },
                {
                  email: "manjula@fpasrilanka.org",
                  name: "Manjula Chathuranga Liyanage",
                },
                
              ],
              bcc: [
                // {
                //   email: "chameera.lakshitha212@gmail.com",
                //   name: "Lakshitha",
                // },
                {
                  email: "narthana.se@gmail.com",
                  name: "Narthana Selvachothy",
                },
              ],
              dynamic_template_data: {
                userName: userName,
                examName: examName,
                url: url
              },
              subject: "Social Enterprise Academy – New SBL submission",
            },
          ],
          template_id: "d-92356e4d88de40778b6780bdd6af84f4",
        };
        try {
          const data = _generate_email(
            " https://seippf.org/mail/mail.php ",
            personalizations
          );
          console.log(data);
        } catch (error) {
          console.error(error);
        }
      });
    }

    this.setState({ loading: false });
  };

  checkEmptyQuestions = () => {
    var tempArray = this.state.examSubmission;
    var errorStatus = "";
    var temp = [];

    tempArray.map((q, qIndex) =>
      q.answer != ""
        ? (errorStatus = "")
        : (errorStatus = localStorage.LANG=="ar"? (<div style={{paddingRight:"20px"}} >{this.props.t('answer_all')} </div>) : (<div> {this.props.t('answer_all')} </div>) )
    );
    return errorStatus;
  };

  handleCommentChange = (e) => {
    this.setState({
      commentText: e.target.value,
    });
  };

  addNewComment = () => {
    if (this.state.commentText != "") {
      const tempComments = this.state.comments;
      tempComments.push({
        text: this.state.commentText,
        addedBy: {
          name: localStorage.FIRST_NAME + " " + localStorage.LAST_NAME,
          userType: "User",
          date: moment().format(),
          userId: localStorage.USER_ID,
          updated: "",
        },
      });
      const data = {
        submissionId: this.state.submissionId,
        comments: { comments: tempComments },
      };
      this.updateExamSubmission(data).then((result) => {
        console.log(result);
        this.setState({
          commentText: "",
          comments: result.comments
            ? result.comments.comments
              ? result.comments.comments.length > 0
                ? result.comments.comments
                : []
              : []
            : [],
        });

        const userName = result.user.firstName + " " + result.user.lastName;
        const examName = this.props.exam.examName;
        const submissionId = result.id;
        let url = "http://seippf.org/admin_exam_submission?id="+submissionId;

        const personalizations = {
          personalizations: [
            {
              to: [
                {
                  email: "rishi@fpasrilanka.org",
                  name: "Rishikeshan Thiyagaraja",
                },
                {
                  email: "manjula@fpasrilanka.org",
                  name: "Manjula Chathuranga Liyanage",
                },
              ],
              bcc: [
                // {
                //   email: "chameera.lakshitha212@gmail.com",
                //   name: "Lakshitha",
                // },
                {
                  email: "narthana.se@gmail.com",
                  name: "Narthana Selvachothy",
                },
              ],
              dynamic_template_data: {
                userName: userName,
                examName: examName,
                url: url
              },
              subject: "Social Enterprise Academy – New comment added",
            },
          ],
          template_id: "d-f65cf63f39094fbf8129b1a7e577c24a",
        };
        try {
          const data = _generate_email(
            " https://seippf.org/mail/mail.php ",
            personalizations
          );
          console.log(data);
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  editComment = (cIndex, text) => {
    this.setState({
      commentInEditing: cIndex,
      changeCommentText: text,
    });
  };

  cancelEditComment = (cIndex) => {
    this.setState({
      commentInEditing: -1,
      changeCommentText: "",
    });
  };

  changeComment = (e, cIndex) => {
    this.setState({
      changeCommentText: e.target.value,
      enableSubmitButton: true,
    });
  };

  requestDeleteComment = (cIndex) => {
    this.setState({
      confirmDelete: cIndex,
    });
  };

  confirmDeleteComment = (cIndex) => {
    var tempArray = this.state.comments;
    tempArray.splice(cIndex, 1);
    const data = {
      submissionId: this.state.submissionId,
      comments: { comments: tempArray },
    };
    this.updateExamSubmission(data).then((result) => {
      this.setState({
        confirmDelete: -1,
      });
    });
  };

  cancelDeleteComment = (cIndex) => {
    this.setState({
      confirmDelete: -1,
    });
  };

  submitChangeComment = (cIndex) => {
    if (this.state.changeCommentText != "") {
      var tempArray = this.state.comments;
      tempArray[cIndex].text = this.state.changeCommentText;
      tempArray[cIndex].updated = moment().format();

      const data = {
        submissionId: this.state.submissionId,
        comments: { comments: tempArray },
      };
      this.updateExamSubmission(data).then((result) => {
        this.setState({
          commentInEditing: -1,
          enableSubmitButton: false,
        });
      });
    } else {
      this.setState({
        commentInEditing: -1,
        enableSubmitButton: false,
      });
    }
  };

  scrollToQuestion = () => {
    console.log("delete clicked!");
    window.scrollTo(0, this.questionsRef.current.offsetTop);
    // window.scrollTo(-500, -200);
  };

  render() {
    const { exam, formErrorStatus, formSuccessState, t } = this.props;
    const {
      loading,
      examSubmission,
      answersSubmitted,
      commentText,
      changeCommentText,
      comments,
      commentInEditing,
      enableSubmitButton,
      confirmDelete,
      grade,
    } = this.state;

    return (
      <div style={styles.mainDiv}>
        {exam ? (
          <React.Fragment>
            {/* <h4 className="examTitleEC">{exam.examName}</h4> */}

            <div
              ref={this.questionsRef}
              className="uk-form-label examQuestionText examDescriptionEC"
            >
              {exam.description != "" ? (
                <React.Fragment>
                  <div style={{marginBottom: '1rem', color: '#254470', fontSize: '1.2rem', textDecoration: 'underline'}} className="uk-form-label examQuestionText examSubTitleEC">
                   {t('description')}
                  </div>
                  {exam.description}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            {exam.resourceUrls ? (
              exam.resourceUrls.resourceUrls.length > 0 ? (
                <React.Fragment>
                  <div style={{marginBottom: '1rem', color: '#254470', fontSize: '1.2rem', textDecoration: 'underline'}} className="uk-form-label examQuestionText examSubTitleEC">
                    {t('resource')}
                  </div>
                  {exam.resourceUrls.resourceUrls.map((res) => (
                    <img
                      src={DocumentIcon}
                      className="examDocIconUser"
                      uk-tooltip={t('click_to_view')}
                      onClick={() => this.openDocument(res.resourceUrl)}
                    />
                  ))}
                </React.Fragment>
              ) : null
            ) : null}
          </React.Fragment>
        ) : null}

        {examSubmission ? (
          examSubmission.length > 0 ? (
            <React.Fragment>
              <div style={{marginBottom: '1rem', color: '#254470', fontSize: '1.2rem', textDecoration: 'underline'}} className="uk-form-label examQuestionText examSubTitleEC">
                {t('questions')} :
              </div>
              {examSubmission.map((question, index) => (
                <div>
                  <div className="uk-form-label examQuestionText">
                    {index + 1}. {question.question}
                  </div>
                  {!this.state.answersSubmitted ? (
                    <textarea
                      disabled={this.state.answersSubmitted}
                      value={question.answer}
                      className="uk-textarea examQuestionTextArea"
                      rows="6"
                      id="question"
                      name="question"
                      onChange={(e, data) =>
                        this.handleAnswerChange(
                          e,
                          data,
                          index,
                          question.question
                        )
                      }
                    ></textarea>
                  ) : (
                    <div style={{textAlign: 'justify', margin: '1rem 1rem', fontSize: '0.9rem', borderLeft: 'solid 2px #dedede', paddingLeft: '1rem', marginLeft: '2rem', whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>
                      {question.answer}
                    </div>
                  )}

                  {/* <br /> */}
                  {/* ANSWER COMMENTS START */}
                  {question.comment ? (
                    <div className="commentMainDivAnswerUser">
                      <div className="commentUserReviewer">
                        {question.comment.name}{" - "}{t('SE_Hub')}
                      </div>

                      <div className="commentDate">
                        {moment(question.comment.date).utc().format('llll')} {t('GMT')}
                      </div>
                      <div className="commentText">{question.comment.text}</div>
                      {question.comment.updated ? (
                        question.comment.updated != "" ? (
                          <div className="commentEditedText">{t('edited')}</div>
                        ) : null
                      ) : null}
                    </div>
                  ) : null}

                  {/* ANSWER COMMENTS END */}
<hr />

                </div>
              ))}
            </React.Fragment>
          ) : (
            <ContentNotFound />
          )
        ) : (
          <ContentNotFound />
        )}
        <br />

        {grade ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <text className="examMarksText">
                    <b>{t('score')} </b>
                  </text>
                </td>
                <td>
                  <Circle
                    animate={true}
                    animationDuration="3s"
                    size={80}
                    lineWidth={40}
                    progress={grade}
                    progressColor={
                      grade >= 70
                        ? "#28d15d"
                        : grade >= 45
                        ? "#33c5ff"
                        : "#fc5858"
                    }
                    bgColor="#E0E0E0"
                    // textColor="white"
                    textStyle={{
                      fontSize: "6.5rem",
                      fontWeight: "bold",
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}

        <br />
        {answersSubmitted != true ? (
          <Button
            className="uk-button uk-button-primary uk-margin"
            style={{ height: 40, float: "right" }}
            type="button"
            disabled={loading ? true : false}
            onClick={() => this.handleAnswerSubmit()}
          >
            {loading ? (
              <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
            ) : (
             t('submit')
            )}
          </Button>
        ) : null}

        <br />
        {answersSubmitted === true ? (
          <div className="commentsSectionUser">
            <div className="uk-card-header uk-text-bold commentTitleDivUser">
              <text className="commentTitleUser">
                {t('comments')} -{" "}
                {comments ? (comments.length > 0 ? comments.length : 0) : 0}
              </text>
            </div>
            <div className="newCommentBox">
              <text className={localStorage.LANG=='ar'? "commentBox_ar":""}>
                {/* {localStorage.FIRST_NAME} {localStorage.LAST_NAME} */}
               { t('add_new_comment')}
              </text>
              <textarea
                value={commentText || ""}
                className={ localStorage.LANG=='ar'? "commentBox_ar uk-textarea commentTextArea" : "uk-textarea commentTextArea"} 
                rows="2"
                id="commentText"
                name="commentText"
                onChange={(e) => this.handleCommentChange(e)}
                disabled={!comments.length > 0}
                placeholder={
                  comments
                    ? comments.length > 0
                      ? localStorage.FIRST_NAME +
                        " " +
                        localStorage.LAST_NAME +
                        " " +
                        "says..."
                      : t('comment_enabling')
                    : t('comment_enabling')
                }
              ></textarea>
              {/* <button
                                  // onClick={() => this.viewSubmission(sub.id)}
                                  className="uk-button admin-table-btn reviewButtonExamSub"
                                >
                                </button> */}
              <i
                class={localStorage.LANG=='ar'? "fa fa-paper-plane sendIcon_ar": "fa fa-paper-plane sendIcon"}
                aria-hidden="true"
                uk-tooltip= {t('add_comment')}
                onClick={() => this.addNewComment()}
              ></i>
            </div>

            {comments
              ? comments.length > 0
                ? comments.slice(0).reverse().map((comment, cIndex) => (
                    <div className="commentMainDiv">
                      {comment.addedBy.userType === "Reviewer" ? (
                        <div className="commentUserReviewer">
                          {comment.addedBy.name}{t('comment_SE_Hub')}
                        </div>
                      ) : (
                        <div className="commentUser">
                          {comment.addedBy.name}{" "}
                        </div>
                      )}

                      <div className="commentDate">
                        {moment(comment.addedBy.date).utc().format('llll')} {t('GMT')}
                        
                        {comment.addedBy.userId === localStorage.USER_ID ? (
                          commentInEditing != -1 ? (
                            commentInEditing === cIndex ? (
                              <span
                                className="commentButton"
                                onClick={() => this.cancelEditComment(cIndex)}
                              >
                                {t('cancel_edit')}
                              </span>
                            ) : (
                              <span
                                className="commentButton"
                                onClick={() =>
                                  this.editComment(cIndex, comment.text)
                                }
                              >
                               {t('edit')}
                              </span>
                            )
                          ) : (
                            <React.Fragment>
                              {confirmDelete != cIndex ? (
                                <React.Fragment>
                                  <span
                                    className="commentButton"
                                    onClick={() =>
                                      this.editComment(cIndex, comment.text)
                                    }
                                  >
                                   {t('edit')}
                                  </span>
                                  <span
                                    className="commentButton"
                                    // onClick={() =>
                                    //   this.requestDeleteComment(cIndex)
                                    // }
                                    onClick={() => this.scrollToQuestion()}
                                  >
                                    {t('delete')}
                                  </span>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <span
                                    className="commentButtonRed"
                                    onClick={() =>
                                      this.confirmDeleteComment(cIndex)
                                    }
                                  >
                                    {t('confirm_delete')}
                                  </span>
                                  <span
                                    className="commentButtonBlue"
                                    onClick={() =>
                                      this.cancelDeleteComment(cIndex)
                                    }
                                  >
                                   {t('cancel')}
                                  </span>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          )
                        ) : null}
                      </div>
                      {cIndex != commentInEditing ? (
                        <div className="commentText">{comment.text}</div>
                      ) : (
                        <React.Fragment>
                          <textarea
                            value={changeCommentText || ""}
                            className="uk-textarea commentTextArea"
                            rows="2"
                            id="changeCommentText"
                            name="changeCommentText"
                            onChange={(e) => this.changeComment(e)}
                          ></textarea>
                          {enableSubmitButton === true ? (
                            <i
                              class={localStorage.LANG=='ar'? "fa fa-paper-plane sendIcon_ar": "fa fa-paper-plane sendIcon"}
                              aria-hidden="true"
                              uk-tooltip= {t('add_comment')}
                              onClick={() => this.submitChangeComment(cIndex)}
                            ></i>
                          ) : null}
                        </React.Fragment>
                      )}
                      {comment.updated ? (
                        comment.updated != "" ? (
                          <div className="commentEditedText">{t('edited')}</div>
                        ) : null
                      ) : null}
                      <hr />
                    </div>
                  ))
                : null
              : null}
            <br />
          </div>
        ) : null}

        <br />
        <br />
        {formErrorStatus.status ? (
          <div className="danger_alert">{formErrorStatus.message}</div>
        ) : formSuccessState.status ? (
          <div className="success_alert">{formSuccessState.message} </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

// export default withRouter(withApollo());
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withApollo(withNamespaces()(ExamContainer)))
);

const styles = {
  mainDiv: {
    paddingBottom: "3rem",
  },
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

class SearchBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { exam } = this.props;
        return (
            <div className="course-dhashboard" style={{ backgroundImage: `linear-gradient(to right, #00A9E0, #00A9E0)`, backgroundPosition: "right top", backgroundRepeat: "no-repeat", maxHeight: "40px", backgroundSize: '100%', }}>   
            </div>
        )
    }
}

export default SearchBanner; 
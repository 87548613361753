import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import { Button } from 'react-uikit';
import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner'

import { Form, Dropdown, input, Message } from "semantic-ui-react";

//read Api data
import { CREATE_USER_NOTIFICATION, GET_USER_PROFILE } from '../../../queries/common_queries';

import { EDIT_BASIC_PROFILE, UPDATE_PASSWORD } from '../../../queries/user_queries';
import { withNamespaces } from "react-i18next";

var countryOptions = [];
const countryList = {
    rg1: [
        { "key": "ctr1", "value": "Benin", "text": "Benin" },
        { "key": "ctr2", "value": "Botswana", "text": "Botswana" },
        { "key": "ctr3", "value": "Burkina Faso", "text": "Burkina Faso" },
        { "key": "ctr4", "value": "Burundi", "text": "Burundi" },
        { "key": "ctr5", "value": "Cameroon", "text": "Cameroon" },
        { "key": "ctr6", "value": "Cape Verde", "text": "Cape Verde" },
        { "key": "ctr7", "value": "Central African Republic", "text": "Central African Republic" },
        { "key": "ctr8", "value": "Chad", "text": "Chad" },
        { "key": "ctr9", "value": "Comoros", "text": "Comoros" },
        { "key": "ctr10", "value": "Republic of the Congo", "text": "Republic of the Congo" },
        { "key": "ctr11", "value": "Democratic Republic of the Congo", "text": "Democratic Republic of the Congo" },
        { "key": "ctr12", "value": "Cote d’Ivoire", "text": "Cote d’Ivoire" },
        { "key": "ctr13", "value": "Eswatini", "text": "Eswatini" },
        { "key": "ctr14", "value": "Ethiopia", "text": "Ethiopia" },
        { "key": "ctr15", "value": "Ghana", "text": "Ghana" },
        { "key": "ctr16", "value": "Guinea-Bissau", "text": "Guinea-Bissau" },
        { "key": "ctr17", "value": "Guinea-Conakry", "text": "Guinea-Conakry" },
        { "key": "ctr18", "value": "Lesotho", "text": "Lesotho" },
        { "key": "ctr19", "value": "Madagascar", "text": "Madagascar" },
        { "key": "ctr20", "value": "Malawi", "text": "Malawi" },
        { "key": "ctr21", "value": "Mali", "text": "Mali" },
        { "key": "ctr22", "value": "Mozambique", "text": "Mozambique" },
        { "key": "ctr23", "value": "Namibia", "text": "Namibia" },
        { "key": "ctr24", "value": "Niger", "text": "Niger" },
        { "key": "ctr25", "value": "Nigeria", "text": "Nigeria" },
        { "key": "ctr26", "value": "Sierra Leone", "text": "Sierra Leone" },
        { "key": "ctr27", "value": "Tanzania", "text": "Tanzania" },
        { "key": "ctr28", "value": "Togo", "text": "Togo" },
        { "key": "ctr29", "value": "Uganda", "text": "Uganda" },
        { "key": "ctr30", "value": "Zambia", "text": "Zambia" },
        { "key": "ctr31", "value": "Mauritius", "text": "Mauritius" },
        { "key": "ctr32", "value": "Sao Tome & Principe", "text": "Sao Tome & Principe" },
        { "key": "ctr33", "value": "Kenya", "text": "Kenya" },
        { "key": "ctr34", "value": "Liberia", "text": "Liberia" },
        { "key": "ctr35", "value": "Republic of South Africa", "text": "Republic of South Africa" },
        { "key": "ctr36", "value": "Rwanda", "text": "Rwanda" },
        { "key": "ctr37", "value": "Senegal", "text": "Senegal" },
        { "key": "ctr38", "value": "Seychelles", "text": "Seychelles" },
        { "key": "ctr39", "value": "South Sudan", "text": "South Sudan" },
        { "key": "ctr40", "value": "Zimbabwe", "text": "Zimbabwe" },


    ],
    rg2: [
        { "key": "ctr41", "value": "Algeria", "text": "Algeria" },
        { "key": "ctr42", "value": "Bahrain", "text": "Bahrain" },
        { "key": "ctr43", "value": "Egypt", "text": "Egypt" },
        { "key": "ctr44", "value": "Lebanon", "text": "Lebanon" },
        { "key": "ctr45", "value": "Mauritania", "text": "Mauritania" },
        { "key": "ctr46", "value": "Morocco", "text": "Morocco" },
        { "key": "ctr47", "value": "Pakistan", "text": "Pakistan" },
        { "key": "ctr48", "value": "Palestine", "text": "Palestine" },
        { "key": "ctr49", "value": "Somaliland", "text": "Somaliland" },
        { "key": "ctr50", "value": "Sudan", "text": "Sudan" },
        { "key": "ctr51", "value": "Syria", "text": "Syria" },
        { "key": "ctr52", "value": "Tunisia", "text": "Tunisia" },
        { "key": "ctr53", "value": "Yemen", "text": "Yemen" },
        { "key": "ctr54", "value": "Libya", "text": "Libya" },
        { "key": "ctr55", "value": "Jordan", "text": "Jordan" },


    ],
    rg3: [
        { "key": "ctr56", "value": "Cambodia", "text": "Cambodia" },
        { "key": "ctr57", "value": "China", "text": "China" },
        { "key": "ctr58", "value": "Cook Islands", "text": "Cook Islands" },
        { "key": "ctr59", "value": "Fiji", "text": "Fiji" },
        { "key": "ctr60", "value": "Hong Kong", "text": "Hong Kong" },
        { "key": "ctr61", "value": "Indonesia", "text": "Indonesia" },
        { "key": "ctr62", "value": "Kiribati", "text": "Kiribati" },
        { "key": "ctr63", "value": "Democratic People's Republic of Korea", "text": "Democratic People's Republic of Korea" },
        { "key": "ctr64", "value": "Republic of Korea", "text": "Republic of Korea" },
        { "key": "ctr65", "value": "Malaysia", "text": "Malaysia" },
        { "key": "ctr66", "value": "Mongolia", "text": "Mongolia" },
        { "key": "ctr67", "value": "New Zealand", "text": "New Zealand" },
        { "key": "ctr68", "value": "Philippines", "text": "Philippines" },
        { "key": "ctr69", "value": "Samoa", "text": "Samoa" },
        { "key": "ctr70", "value": "Solomon Islands", "text": "Solomon Islands" },
        { "key": "ctr71", "value": "Thailand", "text": "Thailand" },
        { "key": "ctr72", "value": "Tonga", "text": "Tonga" },
        { "key": "ctr73", "value": "Tuvalu", "text": "Tuvalu" },
        { "key": "ctr74", "value": "Vanuatu", "text": "Vanuatu" },
        { "key": "ctr75", "value": "Vietnam", "text": "Vietnam" },
        { "key": "ctr76", "value": "Australia", "text": "Australia" },
        { "key": "ctr77", "value": "Papua New Guinea", "text": "Papua New Guinea" },
        { "key": "ctr78", "value": "Laos", "text": "Laos" },
        { "key": "ctr79", "value": "Myanmar", "text": "Myanmar" },
        { "key": "ctr80", "value": "Japan", "text": "Japan" },

    ],
    rg4: [
        { "key": "ctr81", "value": "Albania", "text": "Albania" },
        { "key": "ctr82", "value": "Austria", "text": "Austria" },
        { "key": "ctr83", "value": "Belgium", "text": "Belgium" },
        { "key": "ctr84", "value": "Bosnia and Herzegovina", "text": "Bosnia and Herzegovina" },
        { "key": "ctr85", "value": "Bulgaria", "text": "Bulgaria" },
        { "key": "ctr86", "value": "Cyprus", "text": "Cyprus" },
        { "key": "ctr87", "value": "Denmark", "text": "Denmark" },
        { "key": "ctr88", "value": "Estonia", "text": "Estonia" },
        { "key": "ctr89", "value": "Finland", "text": "Finland" },
        { "key": "ctr90", "value": "France", "text": "France" },
        { "key": "ctr91", "value": "Georgia", "text": "Georgia" },
        { "key": "ctr92", "value": "Germany", "text": "Germany" },
        { "key": "ctr93", "value": "Ireland", "text": "Ireland" },
        { "key": "ctr94", "value": "Israel", "text": "Israel" },
        { "key": "ctr95", "value": "Kazakhstan", "text": "Kazakhstan" },
        { "key": "ctr96", "value": "Kyrgyzstan", "text": "Kyrgyzstan" },
        { "key": "ctr97", "value": "Latvia", "text": "Latvia" },
        { "key": "ctr98", "value": "Netherlands", "text": "Netherlands" },
        { "key": "ctr99", "value": "Norway", "text": "Norway" },
        { "key": "ctr100", "value": "Portugal", "text": "Portugal" },
        { "key": "ctr101", "value": "Republic of Macedonia", "text": "Republic of Macedonia" },
        { "key": "ctr102", "value": "Republic of Serbia", "text": "Republic of Serbia" },
        { "key": "ctr103", "value": "Romania", "text": "Romania" },
        { "key": "ctr104", "value": "Slovak Republic", "text": "Slovak Republic" },
        { "key": "ctr105", "value": "Spain", "text": "Spain" },
        { "key": "ctr106", "value": "Sweden", "text": "Sweden" },
        { "key": "ctr107", "value": "Switzerland", "text": "Switzerland" },
        { "key": "ctr108", "value": "Tajikistan", "text": "Tajikistan" },
        { "key": "ctr109", "value": "Ukraine", "text": "Ukraine" },
        { "key": "ctr110", "value": "Croatia", "text": "Croatia" },
        { "key": "ctr111", "value": "Hungary", "text": "Hungary" },
        { "key": "ctr112", "value": "Italy", "text": "Italy" },
        { "key": "ctr113", "value": "Malta", "text": "Malta" },
        { "key": "ctr114", "value": "Moldova", "text": "Moldova" },
        { "key": "ctr115", "value": "Poland", "text": "Poland" },
        { "key": "ctr116", "value": "Slovenia", "text": "Slovenia" },
        { "key": "ctr117", "value": "Uzbekistan", "text": "Uzbekistan" },

    ],
    rg5: [
        { "key": "ctr118", "value": "Afghanistan", "text": "Afghanistan" },
        { "key": "ctr119", "value": "Bangladesh", "text": "Bangladesh" },
        { "key": "ctr120", "value": "India", "text": "India" },
        { "key": "ctr121", "value": "Maldives", "text": "Maldives" },
        { "key": "ctr122", "value": "Nepal", "text": "Nepal" },
        // { "key": "ctr123", "value": "Pakistan", "text": "Pakistan" },
        { "key": "ctr123", "value": "Sri Lanka", "text": "Sri Lanka" },
        { "key": "ctr124", "value": "Bhutan", "text": "Bhutan" },
    ],
    rg6: [
        { "key": "ctr125", "value": "Barbados", "text": "Barbados" },
        { "key": "ctr126", "value": "Colombia", "text": "Colombia" },
        { "key": "ctr127", "value": "Jamaica", "text": "Jamaica" },
        { "key": "ctr128", "value": "Mexico", "text": "Mexico" },
        { "key": "ctr129", "value": "Peru", "text": "Peru" },
        { "key": "ctr130", "value": "Suriname", "text": "Suriname" },
        { "key": "ctr131", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago" },
        { "key": "ctr132", "value": "United States of America", "text": "United States of America" },
        { "key": "ctr133", "value": "Antigua", "text": "Antigua" },
        { "key": "ctr134", "value": "Aruba", "text": "Aruba" },
        { "key": "ctr135", "value": "Bolivia", "text": "Bolivia" },
        { "key": "ctr136", "value": "Chile", "text": "Chile" },
        { "key": "ctr137", "value": "Dominica", "text": "Dominica" },
        { "key": "ctr138", "value": "Guadeloupe", "text": "Guadeloupe" },
        { "key": "ctr139", "value": "Ecuador", "text": "Ecuador" },
        { "key": "ctr140", "value": "Guyana", "text": "Guyana" },
        { "key": "ctr141", "value": "Curacao", "text": "Curacao" },
        { "key": "ctr142", "value": "Grenada", "text": "Grenada" },
        { "key": "ctr143", "value": "Martinique", "text": "Martinique" },
        { "key": "ctr144", "value": "St. Lucia", "text": "St. Lucia" },
        { "key": "ctr145", "value": "St. Vincent", "text": "St. Vincent" },
        { "key": "ctr146", "value": "Guatemala", "text": "Guatemala" },
        { "key": "ctr147", "value": "Venezuela", "text": "Venezuela" },
        { "key": "ctr148", "value": "Argentina", "text": "Argentina" },
        { "key": "ctr149", "value": "Brazil", "text": "Brazil" },
        { "key": "ctr150", "value": "Haiti", "text": "Haiti" },
        { "key": "ctr151", "value": "Cuba", "text": "Cuba" },
        { "key": "ctr152", "value": "Honduras", "text": "Honduras" },
    
    ]
}
const regionOption = [
    { key: "rg1", value: "Africa", text: "Africa" },
    { key: "rg6", value: "Americas and the Caribbean", text: "Americas and the Caribbean" },
    { key: "rg2", value: "Arab World", text: "Arab World" },
    { key: "rg3", value: "East and Southeast Asia and Oceania", text: "East and Southeast Asia and Oceania" },
    { key: "rg4", value: "European Network", text: "European Network" },
    { key: "rg5", value: "South Asia", text: "South Asia" },
];

const yearOptions = [
    { key: "1940", value: 1940, text: "1940" },
    { key: "1941", value: 1941, text: "1941" },
    { key: "1942", value: 1942, text: "1942" },
    { key: "1943", value: 1943, text: "1943" },
    { key: "1944", value: 1944, text: "1944" },
    { key: "1945", value: 1945, text: "1945" },
    { key: "1946", value: 1946, text: "1946" },
    { key: "1947", value: 1947, text: "1947" },
    { key: "1948", value: 1948, text: "1948" },
    { key: "1949", value: 1949, text: "1949" },
    { key: "1950", value: 1950, text: "1950" },
    { key: "1951", value: 1951, text: "1951" },
    { key: "1952", value: 1952, text: "1952" },
    { key: "1953", value: 1953, text: "1953" },
    { key: "1954", value: 1954, text: "1954" },
    { key: "1955", value: 1955, text: "1955" },
    { key: "1956", value: 1956, text: "1956" },
    { key: "1957", value: 1957, text: "1957" },
    { key: "1958", value: 1958, text: "1958" },
    { key: "1959", value: 1959, text: "1959" },
    { key: "1960", value: 1960, text: "1960" },
    { key: "1961", value: 1961, text: "1961" },
    { key: "1962", value: 1962, text: "1962" },
    { key: "1963", value: 1963, text: "1963" },
    { key: "1964", value: 1964, text: "1964" },
    { key: "1965", value: 1965, text: "1965" },
    { key: "1966", value: 1966, text: "1966" },
    { key: "1967", value: 1967, text: "1967" },
    { key: "1968", value: 1968, text: "1968" },
    { key: "1969", value: 1969, text: "1969" },
    { key: "1970", value: 1970, text: "1970" },
    { key: "1971", value: 1971, text: "1971" },
    { key: "1972", value: 1972, text: "1972" },
    { key: "1973", value: 1973, text: "1973" },
    { key: "1974", value: 1974, text: "1974" },
    { key: "1975", value: 1975, text: "1975" },
    { key: "1976", value: 1976, text: "1976" },
    { key: "1977", value: 1977, text: "1977" },
    { key: "1978", value: 1978, text: "1978" },
    { key: "1979", value: 1979, text: "1979" },
    { key: "1980", value: 1980, text: "1980" },
    { key: "1981", value: 1981, text: "1981" },
    { key: "1982", value: 1982, text: "1982" },
    { key: "1983", value: 1983, text: "1983" },
    { key: "1984", value: 1984, text: "1984" },
    { key: "1985", value: 1985, text: "1985" },
    { key: "1986", value: 1986, text: "1986" },
    { key: "1987", value: 1987, text: "1987" },
    { key: "1988", value: 1988, text: "1988" },
    { key: "1989", value: 1989, text: "1989" },
    { key: "1990", value: 1990, text: "1990" },
    { key: "1991", value: 1991, text: "1991" },
    { key: "1992", value: 1992, text: "1992" },
    { key: "1993", value: 1993, text: "1993" },
    { key: "1994", value: 1994, text: "1994" },
    { key: "1995", value: 1995, text: "1995" },
    { key: "1996", value: 1996, text: "1996" },
    { key: "1997", value: 1997, text: "1997" },
    { key: "1998", value: 1998, text: "1998" },
    { key: "1999", value: 1999, text: "1999" },
    { key: "2000", value: 2000, text: "2000" },
    { key: "2001", value: 2001, text: "2001" },
    { key: "2002", value: 2002, text: "2002" },
    { key: "2003", value: 2003, text: "2003" },
    { key: "2004", value: 2004, text: "2004" },
    { key: "2005", value: 2005, text: "2005" },
    { key: "2006", value: 2006, text: "2006" },
    { key: "2007", value: 2007, text: "2007" },
    { key: "2008", value: 2008, text: "2008" },
    { key: "2009", value: 2009, text: "2009" },
    { key: "2010", value: 2010, text: "2010" },
    { key: "2011", value: 2011, text: "2011" },
    { key: "2012", value: 2012, text: "2012" },
    { key: "2013", value: 2013, text: "2013" },
    { key: "2014", value: 2014, text: "2014" },
    { key: "2015", value: 2015, text: "2015" },
    { key: "2016", value: 2016, text: "2016" },
    { key: "2017", value: 2017, text: "2017" },
    { key: "2018", value: 2018, text: "2018" },
    { key: "2019", value: 2019, text: "2019" },
    { key: "2020", value: 2020, text: "2020" },
    { key: "2021", value: 2021, text: "2021" },
    { key: "2022", value: 2022, text: "2022" },
    { key: "2023", value: 2023, text: "2023" },
    { key: "2024", value: 2023, text: "2024" }
];
const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class Edit_user_profile extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        }
        // else {
        //     if (localStorage.USER_TYPE == "MAUSER") {
        //         this.props.history.push('/');
        //     }
        // }

        const { minStrength = 3, thresholdLength = 7 } = props;

        this.minStrength = typeof minStrength === 'number'
            ? Math.max(Math.min(minStrength, 4), 0)
            : 3;

        this.thresholdLength = typeof thresholdLength === 'number'
            ? Math.max(thresholdLength, 7)
            : 7;
        this.state = {
            user: [],
            password: "",
            confPassword: "",
            userId: localStorage.USER_ID,
            firstName: "",
            lastName: "",
            mobile: "",
            email: "",
            tel: "",
            gender: "MALE",
            loading: false,
            fullName: "",
            country: "",
            region: "",
            year: 0,
            minStrength: 3,
            thresholdLength: 7,
            strength: 0,
            newsletterSubscription: false,
            subscriptionLoader: false,
            blurrModalOpen: false,
        };
        this.handleChange = this.handleChange.bind(this);
        // this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {
        // this.getUserProfileDetails();
        this.setState({ loading: true });
        this.user_profile();
    }

    // getUserProfileDetails() {
    //     this.user_profile().then(result => {
    //         console.log(result);
    //         this.setState({
    //             firstName: result.data.getUserProfile.firstName,
    //             lastName: result.data.getUserProfile.lastName,
    //             mobile: result.data.getUserProfile.mobile,
    //             tel: result.data.getUserProfile.tel,
    //             isActive: result.data.getUserProfile.isActive,
    //             fullName:result.data.getUserProfile.fullName,
    //             country: result.data.getUserProfile.country === "NA" ? "" : result.data.getUserProfile.country,
    //             region: result.data.getUserProfile.region === "NA" ? "" : result.data.getUserProfile.region,

    //             // country:result.data.getUserProfile.country,
    //             region:result.data.getUserProfile.region,
    //             year:result.data.getUserProfile.year,
    //             email:result.data.getUserProfile.email,
    //             loading: false

    //         });

    //     })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }



    user_profile = async () => {
        // const result = await 
        this.props.client.query({
            query: GET_USER_PROFILE,
            // fetchPolicy: 'network-only'
        }).then(result => {
            console.log(result)
            console.log(result.data.getUserProfile.fullName)
            // if (result.data.getUserProfile.region !== "NA") {
            //     const selectedOption = regionOption.filter(function (item) {
            //       return item.value == result.data.getUserProfile.region;
            //     });
            //     const key = selectedOption[0].key;
            //     countryOptions = countryList[key];
            //   }

            this.setState({
                loading: false,
                firstName: result.data.getUserProfile.firstName,
                lastName: result.data.getUserProfile.lastName,
                email: result.data.getUserProfile.email,
                country: result.data.getUserProfile.country === "NA" ? "" : result.data.getUserProfile.country,
                fullName: result.data.getUserProfile.fullName,
                region: result.data.getUserProfile.region === "NA" ? "" : result.data.getUserProfile.region,
                year: result.data.getUserProfile.year,
                newsletterSubscription: result.data.getUserProfile.newsletterSubscription
            })
        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
            })
        });
        // return result;
    };

    // handlePasswordChange = (event) => {
    //     this.setState({ password: event.target.value });
    // };


    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    _handleYearChange = (e, { value }) => {
        console.log(value)
        this.setState({
            year: value
        })
    }

    _handleRegionChange = (e, { value }) => {
        countryOptions = [];
        const selectedOption = regionOption.filter(function (item) {
            return item.value == value;
        });
        this.setState({ region: value });
        const key = selectedOption[0].key;
        countryOptions = countryList[key];
    }

    _handleCountryChange = (e, { value }) => {
        console.log(value)
        this.setState({ country: value });
    }

    handleSubmit = (e) => {
        // e.preventDefault();
        this.setState({ loading: true });
        const { password, confPassword, userId } = this.state;
        console.log(userId)

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.setState({ loading: true });

        if (password !== confPassword) {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: this.props.t('password_not_match')
            });
            this.setState({ loading: false });
            return;
        }


        if (this.state.password.length <= this.state.thresholdLength) {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: this.props.t('password_validity')
            });
            this.setState({ loading: false });
            return;
        }
        

        //   this.update_user_profile().then(result => {
        //     this.getUserProfileDetails();

        //     this.props.setSuccessStatus({
        //         status: true,
        //         title: "Oops!",
        //         message:
        //             "Updated Successfully!"
        //     });

        //     this.setState({ hideDialog: true });

        // }

        this.reset_password(userId, password).then(result => {
            this.setState({
                loading: false,
                password: "",
                confPassword: ""

            });
            setTimeout(() => {
                this.props.setFormStatus({ status: false, title: '', message: '' });
                this.props.setSuccessStatus({ status: false, title: '', message: '' });
                //   this.props.history.push('/');
            }, 5000);
            this.props.setSuccessStatus({
                status: true,
                title: "",
                message:
                    this.props.t('password_updated')
            });

            const data = {
                notificationType: "PASSWORD_CHANGED",
                notification: "You have changed your password successfully.",
                userToNotify: result.id,
                languageObj: {
                    ar: "لقد قمت بتغيير كلمة المرور الخاصة بك بنجاح.",
                    fr: "Vous avez changé votre mot de passe avec succès.",
                    sp: "Su contraseña se ha cambiado con éxito."
                }
            };

            this.notifyUser(data).then((result1) => {
                console.log(result1)
            })

        }).catch(error => {

            console.log(error);
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    <div>
                        {this.props.t('error_updating_pw')} <br />
                        {this.props.t('try_agin')}
                    </div>
            });

            this.setState({ hideDialog: true });
        });
    };

    reset_password = async (userId, password) => {
        const result = await this.props.client.mutate({
            mutation: UPDATE_PASSWORD,
            variables: { userId, password }
        });
        return result.data.updateUserPassword
    }

    notifyUser = async (data) => {
        const result = await this.props.client.mutate({
            mutation: CREATE_USER_NOTIFICATION,
            variables: data,
        });

        console.log(result.data);
        return result.data.createUserNotification;
    };


    update_user_profile = async () => {
        const { userId, firstName, lastName, mobile, tel, gender, password, isActive } = this.state;
        const result = await this.props.client.mutate({
            mutation: EDIT_BASIC_PROFILE,
            variables: { userId, firstName, lastName, mobile, tel, gender, password, isActive }
        });
        return;
    };

    update_newsletter_subscription = async (status) => {
        const { newsletterSubscription, userId } = this.state;
        const result = await this.props.client.mutate({
            mutation: EDIT_BASIC_PROFILE,
            variables: { userId: userId, newsletterSubscription: status }
        });
        return;
    };


    stateChanged = (event) => {
        console.log(event.target.value)
        const { validator = f => f, } = this.props;
        var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

        this.setState({
            password: event.target.value,
            strength: zxcvbn(event.target.value).score,
        })
    };

    handleCheckboxChange = (e) => {
        this.setState({
            subscriptionLoader: true
        })
        console.log(e.target.value)
        const value = this.state.newsletterSubscription;
        var temp = '';
        if (value === false) {
            this.setState({
                newsletterSubscription: true
            })
            temp = true;

        }
        else {
            this.setState({
                newsletterSubscription: false
            })
            temp = false;
        }

        this.update_newsletter_subscription(temp).then(result => {
            console.log(result);
            this.setState({
                subscriptionLoader: false
            })
        })

    }

    openModal = (e) => {
        if (this.state.newsletterSubscription === true) {
            this.setState({
                blurrModalOpen: true,

            })
        }
        else {
            this.handleCheckboxChange(e)
        }

    }


    closeModal = () => {
        this.setState({
            blurrModalOpen: false,
        })
    }

    unsubscribe = () => {
        this.setState({
            subscriptionLoader: true
        })
        const value = this.state.newsletterSubscription;
        var temp = '';
        this.setState({
            newsletterSubscription: false
        })
        temp = false;

        this.update_newsletter_subscription(temp).then(result => {
            console.log(result);
            this.setState({
                subscriptionLoader: false
            })
        })
        this.setState({
            blurrModalOpen: false,
        })
    }

    render() {
        const { loading, password, strength, subscriptionLoader, blurrModalOpen } = this.state;
        const { formErrorStatus, formSuccessState, onStateChanged, validator, children, t, ...restProps } = this.props;
        const passwordLength = password.length;
        const passwordStrong = strength >= this.minStrength;
        const passwordLong = passwordLength > this.thresholdLength;
        const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();
        const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
        return (
            <div
                // style={{backgroundColor:"white"}}
                uk-height-viewport="offset-top: true; offset-bottom: true"
                className="uk-flex uk-flex-middle"
                class="uk-margin"
                uk-scrollspy="target: > .reg-holder; cls:uk-animation-slide-bottom-small; delay: 200"
            >
                <div className="uk-width-1-2@m uk-width-1-2@s uk-margin-auto reg-holder">
                    <div className=" uk-padding ">
                        <Form className="formsegment" success error onSubmit={this.handleSubmit}>
                            <h2 className="semantic-font uk-text-bold">{t('profile')}   </h2>
                            <div className="uk-form-label">{t('user_Category')}<i style={{ color: "#db2828" }} className="icon_asterisk">*</i></div>
                            <div>
                                <input
                                    name="firstName"
                                    id="firstName"
                                    value={(this.state.fullName === "International Planned Parenthood Federation") ? (
                                        t('central_office')
                                        // "Central Office"

                                    ) : (this.state.fullName === "NA") ? 
                                    t('regional_office')
                                    // "Regional Office" 
                                    : (
                                        t('member_association')
                                        // "Member Association"
                                        )}
                                    className="uk-input uk-width-1-1 ll"
                                    type="text"
                                    required
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="uk-form-label"> {t('fName')}<i style={{ color: "#db2828" }} className="icon_asterisk">*</i> </div>
                            <div>
                                <input id='firstName' className="uk-input uk-width-1-1 ll" value={this.state.firstName} onChange={this.handleChange} disabled type="text" placeholder="Your Name" />
                            </div>

                            <div className="uk-form-label">{t('lName')}<i style={{ color: "#db2828" }} className="icon_asterisk">*</i> </div>
                            <div>
                                <input id='lastName' className="uk-input uk-width-1-1 ll" type="text" placeholder="Your Last name" onChange={this.handleChange} disabled value={this.state.lastName} />
                            </div>

                            <div className="uk-form-label">
                                {(this.state.fullName === "International Planned Parenthood Federation") ? (
                                    t('organization')
                                ) : (this.state.fullName === "NA") ? (t('regional_office_name')) : (t('full_name'))}<i style={{ color: "#db2828" }} className="icon_asterisk" >*</i>
                            </div>
                            <div>
                                <input
                                    name="fullName"
                                    id="fullName"
                                    value={this.state.fullName}
                                    className="uk-input uk-width-1-1 ll"
                                    placeholder=""
                                    type="text"
                                    onChange={this.handleChange}
                                    required
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="uk-form-label">{t('email')}<i style={{ color: "#db2828" }} className="icon_asterisk" >*</i></div>
                            <div>
                                <input
                                    name="email"
                                    id="email"
                                    value={this.state.email}
                                    className="uk-input uk-width-1-1 ll"
                                    placeholder=""
                                    type="email"
                                    onChange={this.handleChange}
                                    required
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="uk-form-label">{t('region')}<i style={{ color: "#db2828" }} className="icon_asterisk" >*</i></div>
                            <div>
                                <input
                                    className="uk-input uk-width-1-1 ll"
                                    id="region"
                                    name="region"
                                    value={this.state.region === "" ? "NA" : this.state.region}
                                    // value={this.state.region}
                                    required
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="uk-form-label">{t('country')}<i style={{ color: "#db2828" }} className="icon_asterisk">*</i></div>
                            <div className="uk-margin-bottom">
                                <input
                                    // onChange={this._handleCountryChange}
                                    className="uk-input uk-width-1-1 ll "
                                    name="country"
                                    id="country"
                                    selection
                                    value={this.state.country === "" ? "NA" : this.state.country}
                                    // options={countryOptions}
                                    required
                                    readOnly
                                    disabled
                                />
                            </div>
                            {/* rgba(191,191,191,.87); */}
                            <div className="uk-form-label">{t('year')}</div>
                            <div className="uk-margin-bottom">
                                <Dropdown
                                    className="uk-input uk-width-1-1 ll"
                                    selection
                                    value={this.state.year === 0 || this.state.year === "" ? "NA" : this.state.year}
                                    readOnly
                                    disabled
                                />
                            </div>
                            {/* <p style={{color:"rgba(0,0,0,.87)"}} className="fontFamily">Please <a href="/contact" target="_blank">contact the Social Enterprise Hub</a>  to make any changes to your profile information.</p> */}
                            <p style={{ color: "rgba(0,0,0,.87)" }} className="fontFamily"><div dangerouslySetInnerHTML={{ __html: t("profile_instruction") }} /></p>
                            <hr />
                            <div className="uk-form-label"><b>{t('change_newsletter_subscription')}</b></div>

                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                id="showExpDate"
                                                name="showExpDate"
                                                value={true}
                                                checked={this.state.newsletterSubscription === true}
                                                onChange={(e) => this.openModal(e)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </td>
                                        <td>
                                            <label className="uk-form-label" for="showExpDate"> {t('newsletter_subscription_chk_box')}</label></td>
                                        <td>
                                            <div style={{ marginLeft: '1rem' }}>
                                                {
                                                    subscriptionLoader === true ?
                                                        <Loader
                                                            type="Oval"
                                                            color="#808080"
                                                            height={22}
                                                            width={22}
                                                        />
                                                        : null
                                                }

                                            </div>

                                        </td>
                                    </tr>
                                </thead>
                            </table>



                            <hr />
                            <div className="uk-form-label"><b>{t('change_pw')}</b></div>

                            <div className="uk-form-label" minStrength={3}> {t('new_pw')}<i style={{ color: "#db2828" }} className="icon_asterisk">*</i> </div>
                            <Form.Field {...restProps} validator={this.validatePasswordStrong}  >
                                <span style={{ fontSize: "14px" }} className="d-block form-hint">{t('pw_strength_indicator')}</span>
                                {children}
                                <div className={strengthClass}>
                                    <div className="strength-meter-fill" data-strength={strength}></div>
                                </div>
                            </Form.Field>
                            <div>
                                <input id='password' name="password" className="uk-input uk-width-1-1 ll" type="Password" value={this.state.password} placeholder={t('new_pw')} onChange={this.stateChanged} />
                            </div>

                            <div className="uk-form-label"> {t('c_password')}<i style={{ color: "#db2828" }} className="icon_asterisk">*</i>  </div>
                            <div>
                                <input id='confPassword' name="confPassword" className="uk-input uk-width-1-1 ll" type="Password" value={this.state.confPassword} placeholder={t('c_password')} onChange={this.handleChange} />
                            </div>


                            <Button
                                className="uk-button uk-button-success uk-width-1-1  uk-margin"
                                style={{ height: 40 }}
                                type="button"
                                disabled={loading ? (true) : (false)}
                                onClick={this.handleSubmit}
                            >
                                {loading ? (
                                    <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                ) : (<div>{t('update_pw_button')}</div>)}
                            </Button>
                            <br />

                            {(formErrorStatus.status) ? (
                                <div className={localStorage.LANG=="ar"?("danger_alert_Arabic"):("danger_alert") }>{formErrorStatus.message}</div>
                            ) : ((formSuccessState.status) ? (
                                <div className={localStorage.LANG=="ar"? ("success_alert_Arabic"):("success_alert") } >{formSuccessState.message} </div>
                            ) : (''))}


                        </Form>
                    </div>
                </div>

                {blurrModalOpen === true ? (
                    <div style={styles.background}>
                        <div style={localStorage.LANG=='ar'? styles.arabic_back : styles.background1}>
                            {t('confirm_un_subscription')}
                            <br />
                            <br />
                            <Button
                                className="uk-button submitFeedbackButton1"
                                type="button"
                                style={localStorage.LANG=='ar'? styles.confirmButton_ar : styles.confirmButton}
                                //   disabled={rating === ""}
                                onClick={() =>
                                    this.unsubscribe()
                                }
                            >
                                {t('confirm')}
                            </Button>
                            <Button
                                className="uk-button uk-button-primary submitFeedbackButton"
                                type="button"
                                style={localStorage.LANG=='ar'? styles.cancelButton_ar : styles.cancelButton}
                                // disabled={rating === ""}
                                onClick={() => this.closeModal()}
                            >
                                {t('cancel')}
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>

        );
    }
}

Edit_user_profile.propTypes = {
    children: PropTypes.node,
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Edit_user_profile))));

// export default Admin_edit_profile;

const styles = {
    background: {
        backgroundColor: "#0000005e",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
    },
    background1: {
        backgroundColor: "white",
        position: "absolute",
        // top: '0',
        right: "0",
        width: "40%",
        padding: "1rem",
        zindex: "100",
        marginRight: "28%",
        marginTop: "15%",
        borderRadius: "5px",
        textAlign: "left",
    },
    arabic_back:{
        backgroundColor: "white",
        position: "absolute",
        right: "0",
        width: "40%",
        padding: "1rem",
        zindex: "100",
        marginRight: "28%",
        marginTop: "15%",
        borderRadius: "5px",
        textAlign: "right",
    },

    cancelButton: {
        width: "7rem",
        float: "right",
        marginRight: "0.8rem",
    },
    cancelButton_ar: {
        width: "7rem",
        float: "left",
        marginRight: "0.8rem",
    },

    confirmButton: {
        width: "7rem",
        float: "right",
    },
    confirmButton_ar: {
        width: "7rem",
        float: "left",
    },
};

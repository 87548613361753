import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';


class AboutUsContainer extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return( 
            <div className="uk-container">
                <div className="uk-margin-medium-top uk-grid-large" uk-grid>
                    <h2 className="uk-heading-line uk-margin-remove-top uk-text-left"><span>About us </span></h2>
                       <article className="uk-card uk-card-default uk-card-small uk-card-body uk-article tm-ignore-container">
                          <h3> Vision </h3>
                            <p className="uk-text-lead" > "Nullam scelerisque est commodo mauris tempus, ut accumsan augue posuere" </p>
                          <h3> Mision </h3>
                            <p className="uk-text-lead" > Sed quis tellus iaculis, semper urna eget, tincidunt lectus. Etiam eget cursus purus. Cras eget mattis metus, et posuere est. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nunc vitae enim sollicitudin, pellentesque mauris pulvinar, ultrices purus.</p>
                            <p> We are a full- service digital agency that believes being a favourite brand is better than being a famous one and our digital ecosystems can help your business grow and build an enduring relationship between your brand and the consumer</p>
                            <strong><em>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</em> </strong>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          <h2>Maecenas sit amet  </h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          <h2>Etiam blandit   lacus</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                     </article>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(AboutUsContainer));
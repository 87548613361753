import {
  LOGGING_USER,
  FOUND_RESTRICTED,
  LOGIN_SUCCESS,
  FORM_STATUS,
  SUCCESS_STATUS,
  NOTIFICATION_STATUS,
  NEXT_SUB_MODULE_STATUS,
  VIEW_EXAM_SUBMISSIONS,
  RATINGS_MODAL_HANDLER,
  OPEN_BLURR_MODAL,
  STAT_DATES_HANDLER
} from "../constants/index";

const initialState = {
  loginFormState: {
    form_state: "default",
    title: "",
    message: ""
  },
  formErrorStatus: {
    status: false,
    message: '',
    'title': '',
  },
  formSuccessState: {
    status: false,
    message: '',
    'title': '',
  },
  formNotificationsState: {
    status: false,
    notifications: []
  },
  nextSubModuleDisabled: true,
  translatingLanguages: [{key: 'en', language: "English", supporting: true}, {key: 'ar', language: "Arabic", supporting: false}, {key: 'fr', language: "French", supporting: false}, {key: 'sp', language: "Spanish", supporting: false},],
  languageKeys: { en: "English", fr: "French", ar: "Arabic", sp: "Spanish" },
  viewingExam: "",
  ratingModalOpen: false,
  ratingModalType: "",
  blurrModalOpen: false,
  blurrModalOpenId: '',
  statFromDate: 'd',
  statToDate: 'l',
};

export const rootReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case LOGGING_USER:
      return loginUserReducer(state, action);
    case LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case SUCCESS_STATUS:
      return requestSuccess(state, action);
    case FORM_STATUS:
      return updateFormStatus(state, action);
    case NOTIFICATION_STATUS:
      return {
        ...state,
        formNotificationsState: {
          status: action.status.status,
          notifications: action.status.notifications
        }
      }
    default:
      return state;
    case NEXT_SUB_MODULE_STATUS:
      return nextSubModuleStatusReducer(state, action);
      case VIEW_EXAM_SUBMISSIONS:
      return viewingExamReducer(state, action);
    case RATINGS_MODAL_HANDLER:
      console.log(action.status)
      return ratingModalReducer(state, action);
    case OPEN_BLURR_MODAL:
      return blurrModalReducer(state, action);
    case STAT_DATES_HANDLER:
      return statDateReducer(state, action);
  }
}


function updateFormStatus(state, action) {
  return {
    ...state,
    formErrorStatus: {
      status: action.status.status,
      message: action.status.message,
      'title': action.status.title,
    }
  }
}

function requestSuccess(state, action) {
  return {
    ...state,
    formSuccessState: {
      status: action.status.status,
      message: action.status.message,
      'title': action.status.title,
    }
  };
}

function loginSuccess(state, action) {
  return {
    ...state,
    loginFormState: {
      form_state: "success",
      title: "",
      message: ""
    }
  };
}

function loginUserReducer(state, action) {
  return {
    ...state,
    loginFormState: {
      form_state: "error",
      title: "",
      message: ""
    }
  };
}

function nextSubModuleStatusReducer(state, action) {
  return {
    ...state,
    nextSubModuleDisabled: action.status
  };
}

function viewingExamReducer(state, action) {
  return {
    ...state,
    viewingExam: action.id
  };
}

function ratingModalReducer(state, action) {
  return {
    ...state,
    ratingModalOpen: action.status,
    ratingModalType: action.category,
  };
}

function blurrModalReducer(state, action) {
  console.log(action.status)
  return {
    ...state,
    blurrModalOpen: action.status,
    blurrModalOpenId: action.category,
  };
}

function statDateReducer(state, action) {
  console.log(action.dates.fromDate, action.dates.toDate)
  return {
    ...state,
    fromDate: action.dates.fromDate,
    toDate: action.dates.toDate,
  };
}

export default rootReducer;

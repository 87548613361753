import * as React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

//query
import { GET_ALL_RESOURCES, UPDATE_RESOURCE_STATS } from '../../../queries/common_queries';
import Card_resource, { Card_loading_resource } from '../card_resource/card_resource';
import Loader from 'react-loader-spinner'

//middleware
import { formatDate } from '../../../middleware/index';

class ResourceContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resources: [],
            loading_resources: [
                <Card_loading_resource />,
                <Card_loading_resource />,
                <Card_loading_resource />,
                <Card_loading_resource />,
                <Card_loading_resource />,
                <Card_loading_resource />
            ],
            loading: false,
            loading_all: false,
            loading_our: false,
            loading_global: false,
            pagination: {
                skip: 0,
                first: 16,
                lang: localStorage.LANG
            },
            nextPage: 1,
            previousPage: 0,
            currentPage: 0,
            activepage: '',
            ToDos: 16,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            isFirstBtnActive: 'disabled',
            isLastBtnActive: '',
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, index) {
        this.setState({
            currentPage: Number(index),
            activepage: index
        });
    }

    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            //Get resources
            this.getResources(this.state.pagination).then(result => {
                console.log(result.count)
                var resource_op = result.resources.map(resource => {
                    return {
                        id: resource.id,
                        resourceTitle: resource.resourceTitle,
                        resources: resource.resources,
                        createdAt: resource.createdAt,
                        description: resource.description,
                        url: resource.url,
                        resourceContent: resource.resourceContent,
                    }
                });
                this.setState({ resources: resource_op, count: result.count, loading: false })
            })
        }, 2000);
    }

    componentDidUpdate() { window.scrollTo(0, 0) }


    getResources = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_ALL_RESOURCES,
            // fetchPolicy: 'cache-first'
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        return result.data.getAllResources;
    };

    updateResourceStats = async (resourceId) => {
        console.log(resourceId)
        const result = await this.props.client.mutate({
            mutation: UPDATE_RESOURCE_STATS,
            variables: { resourceId: resourceId },
        });
        console.log(result.data);
        return result.data.updateResourceStats;
    };

    redirectToResource = (id) => {
        // Executed if there is no resource url
        this.updateResourceStats(id).then(result => {
            // this.props.history.push('/single_resource?id=' + id);
            window.open('/single_resource?id=' + id);
        });
    }

    open_resource_url = (id, url) => {
        // Executed if there is a resource url
        this.updateResourceStats(id).then(result => {
            window.open(url);
        });
    }

    handlePagination = async (pageNo) => {
        console.log(pageNo);
        this.setState({
            currentPage: pageNo
        });
        this.setState({
            loading_all: true
        });
        if (pageNo >= 0) {
            this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
            this.state.nextPage = pageNo + 1;
        }

        if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
            this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
        }

        if (pageNo > 0) {
            this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
            this.state.previousPage = pageNo - 1;
        }

        if (pageNo === 0) {
            this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
        }

        var paginations = {
            skip: pageNo * this.state.ToDos,
            first: 16,
            lang: localStorage.LANG
        };

        if (pageNo > 0) {
            this.getResources(paginations).then(result => {
                this.setState({ resources: result.resources, count: result.count, loading_all: false });
            })
        } else {
            this.getResources(this.state.pagination).then(result => {
                this.setState({ resources: result.resources, count: result.count, loading_all: false });
            })
        }
    }

    render() {
        const { resources, loading, loading_resources, count, previousPage, nextPage, currentPage, ToDos, isPrevBtnActive, isNextBtnActive, isFirstBtnActive, isLastBtnActive } = this.state;
        const pages = Math.ceil(count / ToDos);
        console.log(pages)
        if (pages === 0) return null;
        var items = [];
        for (var index = 0; index < pages; index++) {
            items[index] = index;
        }

        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"}></i> </span></li>
        } else {
            renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination(previousPage)} id="btnPrev" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-right" : "fas fa-angle-left"}></i></a></li>
        }

        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </span></li>
        }
        else {
            renderNextBtn = <li className={isNextBtnActive}><a onClick={() => this.handlePagination(nextPage)} id="btnNext" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-left" : "fas fa-angle-right"}></i> </a></li>
        }

        let renderfirstBtn = null;
        if (isFirstBtnActive === 'disabled') {
            renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i> </span></li>
        } else {
            renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination(0)} id="btnfirst" ><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i></a></li>
        }

        let renderlastBtn = null;
        if (isLastBtnActive === 'disabled') {
            renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}> </i> </span> </li>
        } else {
            renderlastBtn = <li className={isLastBtnActive}><a onClick={() => this.handlePagination(items.length - 1)} id="btnlast" > <i className={localStorage.LANG == 'ar' ? "fas fa-angle-double-left" : "fas fa-angle-double-right"}></i>  </a></li>
        }

        return (
            <ul style={{ "list-style": "none" }}>
                <li>
                    <div className="uk-container">
                        {/* <div className="uk-clearfix boundary-align">
                                <div className="uk-float-left section-heading none-border">
                                    <p> Uploaded Resources </p>
                                </div>
                            </div> */}
                        <div style={{ float: "right", marginBottom:"10px"}}>
                            {
                                this.state.loading_all === true ?
                                    <Loader
                                        type="Oval"
                                        color="#00BFFF"
                                        // color="#808080"
                                        height={80}
                                        width={80}
                                    />
                                    : (null)
                            }
                        </div>

                        <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-margin uk-grid" uk-grid uk-height-match="target: .uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" >

                            {
                                loading ? (
                                    loading_resources.map((resource, i) => (
                                        <Card_loading_resource key={i} />
                                    ))

                                ) : (
                                    // resources.length > 0 ? (
                                    resources.map((resource, i) => (
                                        <Card_resource key={i} resource={resource} get_resource={() => this.redirectToResource(resource.id)} open_resource={() => this.open_resource_url(resource.id, resource.url)} />
                                    ))
                                    // ):(null)

                                )
                            }
                        </div>
                        {count >= 16 ? (
                            <div className="paginations uk-flex uk-flex-center">
                                <ul className="uk-pagination" uk-grid>
                                    {renderfirstBtn}
                                    {renderPrevBtn}

                                    {
                                        items.map((item, index) =>
                                            <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
                                        )
                                    }

                                    {renderNextBtn}
                                    {renderlastBtn}
                                </ul>
                            </div>
                        ) : (null)}
                    </div>
                </li>
            </ul>
        );
    }
}

export default withRouter(withApollo(ResourceContainer));
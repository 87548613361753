import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions'
import { Button } from 'react-uikit';

import Admin_navbar from '../../../components/admin/admin_navbar/admin_navbar';

import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';
import { Form } from "semantic-ui-react";

//read Api data
import { GET_USER_PROFILE } from '../../../queries/common_queries';
import { UPDATE_USER,UPDATE_PASSWORD } from '../../../queries/user_queries'

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class Admin_edit_profile extends React.Component {
    constructor(props) {
        super(props);
        if(localStorage.USER_ID==undefined){
            this.props.history.push('/');
        }else{
            if(localStorage.USER_TYPE=="MAUSER"){
                this.props.history.push('/');
            }
        }

        const { minStrength = 3, thresholdLength = 7 } = props;

		this.minStrength = typeof minStrength === 'number'
			? Math.max( Math.min(minStrength, 4), 0 )
			: 3;

		this.thresholdLength = typeof thresholdLength === 'number'
			? Math.max(thresholdLength, 7)
            : 7;
            
        this.state = {
            user: [],
            userId: localStorage.USER_ID,
            firstName: "",
            lastName: "",
            mobile: "",
            tel: "",
            gender: "",
            password:"",
            confPassword:'',
            loading: true,
            minStrength: 3,
            thresholdLength: 7,
            strength: 0,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

    }

    componentDidMount() {
        this.getUserProfileDetails();
    }

    getUserProfileDetails(){
        this.user_profile().then(result => {
            this.setState({
                firstName: result.data.getUserProfile.firstName,
                lastName: result.data.getUserProfile.lastName,
                email:result.data.getUserProfile.email,
                mobile: result.data.getUserProfile.mobile,
                tel: result.data.getUserProfile.tel,
                isActive: result.data.getUserProfile.isActive,
                loading: false
            });

        })
            .catch(error => {
                console.log(error);
            });
    }

    handleTypeChange = (event, { name, value }) => {
        this.setState({ gender: value });
    };


    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit = (e) => {
        // e.preventDefault();
        const {  password, confPassword, userId } = this.state;
        console.log(userId)

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });

        this.setState({loading:true});

        if (password !== confPassword) {
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message: "Passwords do not match!"
            });
            this.setState({ loading: false });
            return;
          }

          if(this.state.password.length <= this.state.thresholdLength ){
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Password must have more than 7 characters!"
            });
            this.setState({ loading: false });
            return;
        }

          this.reset_password(userId,password).then(result => {
            // this.getUserProfileDetails();
            // this.reset_password(,password)
            console.log(result);
      
            this.setState({
              loading: false,
              password: "",
              confPassword: ""
      
            });
            setTimeout(() => {
              this.props.setFormStatus({ status: false, title: '', message: '' });
              this.props.setSuccessStatus({ status: false, title: '', message: '' });
              //   this.props.history.push('/');
            }, 5000);
            this.props.setSuccessStatus({
              status: true,
              title: "",
              message:
                "Password updated successfully!"
            });
      
          }).catch(error => {

            console.log(error);
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    <div>
                        An error occurred while trying to update the password!<br/>
                        Please try again.
                    </div>
            });
            this.setState({ hideDialog: true });
        });
    };

     // this.update_user_profile().then(result => {
        //     this.getUserProfileDetails();

        //     this.props.setSuccessStatus({
        //         status: true,
        //         title: "Oops!",
        //         message:
        //             "Updated Successfully!"
        //     });

        //     this.setState({ hideDialog: true });

        // })

    user_profile = async () => {
        const result = await this.props.client.query({
            query: GET_USER_PROFILE,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });

        return result;

    };

    update_user_profile = async () => {
        const { userId, firstName, lastName, mobile, tel } = this.state;
        const result = await this.props.client.mutate({
            mutation: UPDATE_USER,
            variables: { userId, firstName, lastName, mobile, tel }
        });
        return result.data.editBasicProfile;
    };

    reset_password= async (userId,password)=>{
    const result= await this.props.client.mutate({
        mutation: UPDATE_PASSWORD,
        variables: { userId,password }
    });
    return result.data.updateUserPassword;
    }

    stateChanged = ( event )=> {
        const {   validator = f => f,  } = this.props;
		this.setState({
			password: event.target.value,
            strength: zxcvbn(event.target.value).score,
        },) 
    };
    
    render() {
        const { loading ,  password, strength } = this.state;
        const { formErrorStatus, formSuccessState, validator, children, ...restProps  } = this.props;
        const passwordLength = password.length;
		const passwordStrong = strength >= this.minStrength;
		const passwordLong = passwordLength > this.thresholdLength;

        const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();

        const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
        
        return (
            <div >
                {/* <Admin_navbar /> */}
                <div className="admin-content-inner">
                    <div className="uk-card-small uk-card-default " >
                        <div className="uk-card-header uk-text-bold">
                            <i className="fas fa-user uk-margin-small-right"></i> Profile details
                    </div>
                        <div className="uk-card-body  ">
                            <div className="uk-grid">
                                <div className="uk-width">
                                    <div className="uk-width-1-2">
                                        <div className="uk-form-label"> First Name:  </div>
                                        <input id='firstName' className="uk-input ll" value={this.state.firstName} onChange={this.handleChange} type="text" placeholder="Your Name" disabled />
                                    </div>

                                    <div className="uk-width-1-2">
                                        <div className="uk-form-label"> Last Name: </div>
                                        <input id='lastName' className="uk-input ll" type="text" placeholder="Your Last name" onChange={this.handleChange} value={this.state.lastName}  disabled/>
                                    </div>

                                    
                                    <div className="uk-width-1-2">
                                    <div className="uk-form-label"> Official Email Address</div>
                                        <input
                                        name="email"
                                        id="email"
                                        value={this.state.email}
                                        placeholder="Your email"
                                        className="uk-input ll"
                                        type="email"
                                        onChange={this.handleChange}
                                        required
                                        readOnly
                                        disabled
                                        />
                                    </div>

                                    <div className="uk-width-1-2">
                                        <div className="uk-form-label"> Mobile: </div>
                                        <input id='mobile' className="uk-input ll" type="text" placeholder="Your Last name" onChange={this.handleChange} value={this.state.mobile} disabled />
                                    </div>

                                    {/* <div className="uk-width-1-2">
                                        <div className="uk-form-label"> Telephone:  </div>
                                        <input id='tel' className="uk-input ll" type="text" placeholder="Your Telephone" value={this.state.tel} onChange={this.handleChange} disabled  />
                                    </div> */}

                                    <br />
                                    <div className="uk-form-label"><b>Change Password</b></div>

                                    <div className="uk-width-1-2">
                                        <div className="uk-form-label"  minStrength={3}> New Password<i  style={{color:"#db2828"}} className="icon_asterisk">*</i>   </div>
                                            <Form.Field {...restProps} validator={this.validatePasswordStrong}  >
                                                <span style={{fontSize:"14px"}} className="d-block form-hint">To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.</span>
                                                {children}
                                                <div className={strengthClass}>
                                                    <div className="strength-meter-fill" data-strength={strength}></div>
                                                </div>
                                            </Form.Field>
                                        <input id='password' name="password" className="uk-input ll"    required  type="password" value={this.state.password}  placeholder="Password" onChange={this.stateChanged} />
                                    </div>
                                   
                                    <div className="uk-width-1-2">
                                        <div className="uk-form-label">Confirm Password<i  style={{color:"#db2828"}} className="icon_asterisk">*</i>  </div>
                                        <input
                                        name="confPassword"
                                        id="confPassword"
                                        value={this.state.confPassword}
                                        className="uk-input ll"
                                        placeholder="Confirm Password"
                                        type="password"
                                        onChange={this.handleChange}
                                        required
                                        />
                                    </div>

                                    <Button
                                        className="uk-button uk-button-success uk-width-1-2  uk-margin"
                                        style={{ height: 40 }}
                                        type="button"
                                        disabled={loading ? (true) : (false)}
                                        onClick={this.handleSubmit}
                                    >
                                        {loading ? (
                                            <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                        ) : ("Update Password")}
                                    </Button>
                                    <br/>

                                    {(formErrorStatus.status) ? (
                                        <div className="danger_alert">{formErrorStatus.message}</div>
                                    ) : ((formSuccessState.status) ? (
                                        <div className="success_alert">{formSuccessState.message} </div>
                                    ) : (''))}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const styles = {
    icon: {
      width: "0px"
    }
  };

  Admin_edit_profile.propTypes = {
    children: PropTypes.node,
  }
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Admin_edit_profile)));

// export default Admin_edit_profile;
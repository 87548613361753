import gql from 'graphql-tag';
const language = localStorage.LANG;
export const GET_MODULES = gql`
query GetModules($isActive:Boolean,$skip: Int,$first: Int, $lang: Lang!) {
    getModules(isActive:$isActive,skip:$skip,first:$first,lang:$lang) {
      modules{
        id
        moduleTitle
        moduleHelperText
        bannerColor
        bannerIconUrl
        moduleOverview
        content
        moduleNumber
        isActive
        languageObj
        objectives
        learningSummary
      }
      count
    }
  }
`;

export const GET_USER_PROFILE = gql`
query GetUserProfile{
  getUserProfile{
    id
    firstName
    lastName
    mobile
    tel
    ext
    email
    gender
    userType
    isActive
    fullName
    country
    region
    year
    createdAt
    newsletterSubscription
  }
}
`;


export const GET_NEWSES = gql`
query GetNewses($isActive: Boolean,$skip: Int,$first: Int, $newsType: NewsType, $lang: Lang!)
  {
    getNewses(isActive:$isActive,skip:$skip,first:$first, newsType:$newsType, lang:$lang ){
        newses{
          id
          newsTitle
          newsBannerImage
          newsHelperText
          newsContent
          createdAt
          isActive
          url
          newsType
          languageObj
          videos
        }
        count
    }
  }
  `;


export const GET_NEWS = gql`
 query GetNews($newsId: String!, $lang: Lang!)
 {
  getNews(newsId:$newsId, lang:$lang){
      id
      newsTitle
      newsBannerImage
      newsHelperText
      newsContent
      createdAt
      isActive
      # languageObj
    }
 }
 `;

export const GET_GALLERIES = gql`
 query GetGalleries($isActive: Boolean,$skip: Int,$first: Int, $lang: Lang!)
 {
  getGalleries(isActive:$isActive,skip:$skip,first:$first, lang:$lang)
  {
    galleries{
      id
      galleryTitle
      galleryBannerImage
      galleryHelperText
      galleryDescription
      isActive
      createdAt
      eventDate
      languageObj
      videos
      pinned
    }
    count
  }
 }
`;

export const GET_GALLERY = gql`
query GetGallery($galleryId: String!,$isActive: Boolean,$skip: Int,$first: Int, $lang: Lang!)
{
  getGallery(galleryId:$galleryId,isActive:$isActive,skip:$skip,first:$first, lang:$lang){
    gallery
    {
      id
      galleryTitle
      galleryBannerImage
      galleryHelperText
      galleryDescription
      isActive
      createdAt
      eventDate
      languageObj
      videos
      pinned
      languageEnabled
    }
    galleryImages
    {
      galleryImages{
        id
        galleryID
        imageTitle
        image
        imageHelperText
        imageDescription
        isActive
        fileName
        languageObj
      }
      count
    }
  }
}`;


export const GET_SUB_MODULES = gql`
query GetSubModules($moduleId: String!,$isActive: Boolean,$skip: Int,$first: Int, $lang: Lang!) {
    getSubModules(skip:$skip,first:$first,moduleId:$moduleId, isActive:$isActive, lang: $lang) {
      module{
        id
        moduleTitle
        moduleHelperText
        bannerColor
        bannerIconUrl
        moduleOverview
        content
        moduleNumber
        isActive
        objectivesImageUrl
        bannerImage
        objectives
        languageObj
        learningSummary
      }
      subModules{
        count
        subModules{
          id
          subModuleTitle
          subModuleHelperText
          subBannerColor
          subBannerIconUrl
          subModuleOverview
          subContent
          subModuleNumber
          moduleId
          isActive
          flashQuiz
          languageObj
          presentations
          collapsibleSections
          videos
        }
      }
      lessons{
        count
        lessons{
          lesson{
            id
            lessonTitle
            lessonHelperText
            lessonBannerColor
            lessonBannerIconUrl
            lessonOverview
            lessonContent
            lessonNumber
            moduleId
            subModuleId
            isActive
            languageObj
          }
          count
        }
      }
      contents{
        count
        contents{
          id
          content
          moduleId
          subModuleId
          lessonId
          isActive
        }
      }
    }
  }
`;

export const GET_LESSONS = gql`
query GetLessons($skip: Int,$first: Int,$moduleId: String,$subModuleId: String,$isActive:Boolean!,$lang: Lang!) {
  getLessons(skip:$skip,first:$first,moduleId:$moduleId,subModuleId:$subModuleId,isActive:$isActive,lang:$lang) {
      module{
        id
        moduleTitle
        moduleHelperText
        bannerColor
        bannerIconUrl
        moduleOverview
        content
        moduleNumber
        isActive
        objectivesImageUrl
        bannerImage
        objectives
        languageObj
        learningSummary
      }
      subModule{
        id
        subModuleTitle
        subModuleHelperText
        subBannerColor
        subBannerIconUrl
        subModuleOverview
        subContent
        subModuleNumber
        moduleId
        isActive
        flashQuiz
        languageObj
        presentations
        collapsibleSections
        videos
      }
      lessons{
        count
        lessons{
          id
          lessonTitle
          lessonHelperText
          lessonBannerColor
          lessonBannerIconUrl
          lessonOverview
          lessonContent
          lessonNumber
          moduleId
          subModuleId
          isActive
          languageObj
        }
      }
      contents{
        count
        contents{
          id
          content
          moduleId
          subModuleId
          lessonId
          isActive
        }
      }
    }
  }
`;

export const GET_CONTENT = gql`
query GetContent($skip: Int,$first: Int,$moduleId: String,$subModuleId: String,$lessonId:String,$isActive:Boolean!,$lang: Lang!) {
  getContent(skip:$skip,first:$first,moduleId:$moduleId,subModuleId:$subModuleId,lessonId:$lessonId,isActive:$isActive,lang:$lang) {
      module{
        id
        moduleTitle
        moduleHelperText
        bannerColor
        bannerIconUrl
        moduleOverview
        content
        moduleNumber
        isActive
      }
      subModule{
        id
        subModuleTitle
        subModuleHelperText
        subBannerColor
        subBannerIconUrl
        subModuleOverview
        subContent
        subModuleNumber
        moduleId
        isActive
        flashQuiz
      }
      lesson{
          id
          lessonTitle
          lessonHelperText
          lessonBannerColor
          lessonBannerIconUrl
          lessonOverview
          lessonContent
          lessonNumber
          moduleId
          subModuleId
          isActive
      }
      contents{
        count
        contents{
          id
          content
          moduleId
          subModuleId
          lessonId
          isActive
        }
      }
    }
  }
`;


export const GET_MODULE_QUIZ = gql`
query GetModuleQuiz($moduleId: String,$isActive: Boolean!,$skip: Int,$first: Int, $lang: Lang!) {
  getModuleQuiz(moduleId:$moduleId,isActive:$isActive,skip:$skip,first:$first,lang:$lang) {
      count
      moduleQuizzes{
        id
        moduleId
        moduleQuiz
        isActive
        languageObj
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
query GetAllUsers($userType: UserType!,$isActive: Boolean,$skip: Int,$first: Int, $fromDate: String, $toDate: String) {
  getAllUsers(userType:$userType,isActive:$isActive,skip:$skip,first:$first, fromDate: $fromDate, toDate: $toDate) {
      count
      users{
        id
        firstName
        lastName
        mobile
        tel
        ext
        email
        gender
        userType
        isActive
        fullName
        country
        region
        year
        createdAt
        newsletterSubscription
        languagePreference
        # COMMENT - 22/04/2021
      }
    }
  }
`;

export const GET_MODULE = gql`
query GetModule($moduleId:String ,$subModuleId: String,$lessonId: String, $lang: Lang!){
    getModule(moduleId:$moduleId,subModuleId: $subModuleId,lessonId: $lessonId,lang:$lang){
        id
        moduleTitle
        moduleHelperText
        bannerColor
        bannerIconUrl
        moduleOverview
        content
        moduleNumber
        isActive
        objectivesImageUrl
        bannerImage
        objectives
        languageObj
        learningSummary
        languageEnabled
    }
  }
`;

export const GET_TOOL = gql`
query GetTool($toolId: String!,$lang: Lang!){
  getTool(toolId:$toolId,lang:$lang){
    id
    title
    image
    url
    languageObj
    isActive
    createdAt
    updatedAt
    createdBy
    languageEnabled
  }
}
`

export const GET_ALL_NEWS = gql`
  query getNewses($isActive: Boolean,$skip: Int,$first: Int, $newsType: NewsType, $lang: Lang!){
    getNewses(isActive: $isActive,skip: $skip,first: $first, newsType:$newsType, lang: $lang){
      count
      newses{
        id
        newsTitle
        newsBannerImage
        newsHelperText
        newsContent
        createdAt
        isActive
        url
        newsType
        languageObj
        videos
      }
    }
  }
`;

export const GET_A_NEWS = gql`
query GetANews($newsId:String!, $lang: Lang!){
  getNews(newsId:$newsId, lang:$lang){
    id
    newsTitle
    newsBannerImage
    newsHelperText
    newsContent
    createdAt
    isActive
    url
    newsType
    videos
    languageEnabled
  }
}
`;

export const UPDATE_NEWS = gql`
mutation updateNews($newsId:String! ,$newsTitle:String!,$newsBannerImage:String!,$newsHelperText:String!,$newsContent:String,$url:String, $newsType: NewsType, $lang: Lang!, $videos: JSON, $languageEnabled: Boolean){
  updateNews(newsId:$newsId,newsTitle:$newsTitle,newsHelperText:$newsHelperText,newsBannerImage:$newsBannerImage,newsContent:$newsContent,url:$url, newsType:$newsType, lang:$lang, videos: $videos, languageEnabled: $languageEnabled ){
    id
    newsTitle
    newsBannerImage
    newsHelperText
    newsContent
    isActive
    url
    newsType
    videos
    languageEnabled
  }
}
`;

export const GET_SUB_MODULE = gql`
query GetSubModule($subModuleId:String!, $lang:Lang!){
  getSubModule(subModuleId:$subModuleId, lang:$lang){
        id
        subModuleTitle
        subModuleHelperText
        subBannerColor
        subBannerIconUrl
        subModuleOverview
        subContent
        subModuleNumber
        moduleId
        isActive
        flashQuiz
        presentations
        collapsibleSections
        videos,
        languageEnabled
    }
  }
`;

export const GET_LESSON = gql`
query GetLesson($lessonId:String!,$lang:Lang!){
  getLesson(lessonId:$lessonId,lang:$lang){
        id
        lessonTitle
        lessonHelperText
        lessonBannerColor
        lessonBannerIconUrl
        lessonOverview
        lessonContent
        lessonNumber
        moduleId
        subModuleId
        isActive
    }
  }
`;

export const GET_NEXT_MODULE = gql`
query GetNextModule( $first: Int!, $moduleId: String!, $lang: Lang!){
  getNextModule(first:$first, moduleId:$moduleId,lang:$lang){
    id
    moduleTitle
    moduleHelperText
    bannerColor
    bannerIconUrl
    moduleOverview
    content
    moduleNumber
    isActive
  }
}
`;

export const GET_NEXT_SUBMODULE = gql`
query GetNextSubModule( $first: Int!, $subModuleId: String!,$moduleId: String!,$lang: Lang!){
  getNextSubModule(first:$first, subModuleId:$subModuleId, moduleId:$moduleId, lang:$lang){
    id
    subModuleTitle
    subModuleHelperText
    subBannerColor
    subBannerIconUrl
    subModuleOverview
    subContent
    subModuleNumber
    moduleId
    isActive
    flashQuiz
    collapsibleSections
  }
}
`;

export const GET_PREVIOUS_MODULE = gql`
query GetPreviousModule( $last: Int!, $moduleId: String!, $lang: Lang!){
  getBackModule( last: $last, moduleId: $moduleId,lang:$lang){
    id
    moduleTitle
    moduleHelperText
    bannerColor
    bannerIconUrl
    moduleOverview
    content
    moduleNumber
    isActive
  }
}
`

export const GET_PREVIOUS_SUBMODULE = gql`
query GetBackSubModule( $last: Int!, $subModuleId: String!,$moduleId: String!,$lang: Lang!){
  getBackSubModule(last:$last, subModuleId:$subModuleId,moduleId:$moduleId,lang:$lang){
    id
    subModuleTitle
    subModuleHelperText
    subBannerColor
    subBannerIconUrl
    subModuleOverview
    subContent
    subModuleNumber
    moduleId
    isActive
    flashQuiz
    collapsibleSections
  }
}
`;


export const GET_ALL_RESOURCES = gql`
 query GetAllResources($lang: Lang!, $skip: Int, $first: Int) {
   getAllResources(lang:$lang, skip:$skip, first:$first ){
    resources{
      id
      resourceTitle
      resources
      createdBy
      createdAt
      description
      url
      resourceContent
      languageObj
      videos
    }
    count
  }
 }
`;

export const GET_SINGLE_RESOURCE = gql`
query GetSingleResource( $resourceId: String!, $lang: Lang!){
  getSingleResource( resourceId:$resourceId, lang:$lang){
      id
      resourceTitle
      resources
      createdBy
      createdAt
      description
      url
      resourceContent
      # languageObj
      videos
      languageEnabled
  }
}
`;

export const NEW_NOTIFICATION_SUBSCRIPTION = gql`
  subscription{
    newNotification {
      id
      notification
      readBy
      createdAt
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
 query getNotifications( $lang: Lang!){
  getNotifications(lang:$lang){
      id
      readBy
      notification
      createdAt
      createdBy
      userToNotify
      languageObj
      
  }
 }
`;

export const GET_LATEST_NOTIFICATIONS = gql`
 query getAllNotifications( $lang: Lang!){
  getAllNotifications(lang:$lang){
      id
      readBy
      notification
      createdAt
      createdBy
      userToNotify
      languageObj
  }
 }
`;

export const UPDATE_NOTIFICATION = gql`
mutation updateNotification($notificationId:String!){
  updateNotification(notificationId:$notificationId){
    id
    notification
    readBy
  }
}
`;

export const GET_SINGLE_FLASH_QUIZ_SUBMISSION = gql`
query getSingleFlashQuizSubmission( $subModuleId: String!, $moduleId: String, $submissionStatus: FlashQuizStatus, $isActive: Boolean){
  getSingleFlashQuizSubmission(subModuleId:$subModuleId, moduleId:$moduleId, submissionStatus:$submissionStatus, isActive:$isActive){
    id
    subModuleId
    moduleId
    submissionStatus
    createdAt
    isActive
    createdBy
  }
}
`;

export const GET_FLASH_QUIZ_SUBMISSIONS = gql`
query getFlashQuizSubmissions( $submissionId: String, $subModuleId: String!, $submissionStatus: FlashQuizStatus, $moduleId: String, $isActive: Boolean){
  getFlashQuizSubmissions(submissionId:$submissionId, subModuleId:$subModuleId, submissionStatus:$submissionStatus, moduleId:$moduleId, isActive:$isActive){
    id
    subModuleId
    moduleId
    submissionStatus
    isActive
    createdAt
    createdBy
  }
}
`;

export const GET_FLASH_QUIZ_PROGRESS = gql`
query getFlashQuizProgress( $createdBy: String, $moduleId: String){
  getFlashQuizProgress(createdBy:$createdBy, moduleId:$moduleId){
    subModuleCount
    progress
    submissions{
      subModuleId
    }
  }
}
`;

export const GET_ANNOUNCEMENTS = gql`
query getAnnouncements($isActive:Boolean,$skip: Int,$first: Int, $lang: Lang!, $startDate: String, $endDate: String){
  getAnnouncements(isActive:$isActive, skip:$skip, first:$first, lang:$lang, startDate:$startDate, endDate:$endDate){
    announcements{
      id
      title
      description
      startDate
      endDate
      showExpDate
      date
      url
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
    }
    count
  }
}
`;

export const GET_SINGLE_ANNOUNCEMENT = gql`
query getSingleAnnouncement($announcementId:String! $lang: Lang!){
  getSingleAnnouncement(announcementId:$announcementId, lang:$lang){
      id
      title
      description
      startDate
      endDate
      showExpDate
      date
      url
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
      languageEnabled
  }
}
`;

export const GET_EXAMS = gql`
query getExams($isActive:Boolean,$skip: Int,$first: Int, $lang: Lang!){
  getExams(isActive:$isActive, skip:$skip, first:$first, lang:$lang){
    exams{
      id
      examName
      description
      questions
      resourceUrls
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
      createdByUserName
    }
    count
  }
}
`;

export const GET_SINGLE_EXAM = gql`
query getSingleExam($examId:String! $lang: Lang!){
  getSingleExam(examId:$examId, lang:$lang){
    id
    examName
    description
    questions
    resourceUrls
    languageObj
    isActive
    createdAt
    updatedAt
    createdBy
    createdByUserName
    languageEnabled
  }
}
`;

export const GET_EXAM_SUBMISSIONS = gql`
query getExamSubmissions($examId:String,$markedBy:String,$createdBy:String,$isActive:Boolean,$skip: Int,$first: Int){
  getExamSubmissions(examId:$examId, markedBy:$markedBy, createdBy:$createdBy, isActive:$isActive, skip:$skip, first:$first){
    exam{
    id
    examName
    description
    questions
    resourceUrls
    languageObj
    isActive
    createdAt
    updatedAt
    createdBy
  }
  submissions{
    id
    examId
    submission
    score
    markedBy
    comments
    isActive
    createdAt
    updatedAt
    createdBy
    grade
    user{
        id
        firstName
        lastName
        mobile
        tel
        ext
        email
        gender
        userType
        isActive
        fullName
        country
        region
        year
    }
  }
  count
  }
}
`;

export const GET_SINGLE_EXAM_SUBMISSION = gql`
query getSingleExamSubmission($submissionId:String!){
  getSingleExamSubmission(submissionId:$submissionId){
  	exam{
      id
      examName
      description
      questions
      resourceUrls
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
      createdByUserName
    }
   submission{
    id
    examId
    submission
    score
    markedBy
    comments
    isActive
    createdAt
    updatedAt
    createdBy
    grade
    user{
      id
      firstName
      lastName
      mobile
      tel
      ext
      email
      gender
      userType
      isActive
      fullName
      country
      region
      year
      languagePreference
      # COMMENT - 22/04/2021
    }
  }
  }
}
`;

export const UPDATE_EXAM_SUBMISSION = gql`
mutation updateExamSubmission($submissionId:String!,$submission: JSON,$score: Int,$markedBy:String,$comments:JSON,$grade:Int,$isActive:Boolean) {
  updateExamSubmission(submissionId:$submissionId,submission:$submission,score:$score,markedBy:$markedBy,comments:$comments,grade:$grade,isActive:$isActive)
    {
      id
    examId
    submission
    score
    markedBy
    comments
    isActive
    createdAt
    updatedAt
    createdBy
    grade
    user{
      id
      firstName
      lastName
      mobile
      email
      ext
      tel
      gender
      userType
      isActive
      fullName
      country
      region
      year
    }
    }
  }
`

export const GET_EXAMS_WITH_SUBMISSION_STATUSES= gql`
query getExamsWithSubmissionStaus($createdBy:String, $lang:Lang!){
  getExamsWithSubmissionStaus(createdBy:$createdBy, lang: $lang){
    pendingExams
    completedExams
  }
}
`;

export const GET_USER_RATINGS = gql`
query getUserRatings($rating:String,$createdBy:String,$isActive:Boolean,$skip: Int,$first: Int){
  getUserRatings(rating:$rating,createdBy:$createdBy,isActive:$isActive,skip:$skip,first:$first){
    userRatings{
      id
      rating
      review
      user{
        id
        firstName
        lastName
        email
        userType
        isActive
        country
        region
        year
      }
      isActive
      createdAt
      updatedAt
      createdBy
    }
  }
}
`;

export const GET_RECOMMENDATIONS = gql`
query getRecommendations{
  getRecommendations{
    modules{
      id
      moduleTitle
      moduleHelperText
      bannerIconUrl
      createdAt
    }
    subModules{
      id
      subModuleTitle
      subBannerIconUrl
      createdAt
    }
    galleries{
      id
      galleryTitle
      eventDate
      galleryBannerImage
      createdAt
    }
    news{
      id
      newsTitle
      newsBannerImage
    }
    resources{
      id
      resourceTitle
      description
      createdAt
    }
    announcements{
      id
      title
      date
    }
    exams{
      id
      createdAt
    }
  }
}
`;

export const UPDATE_RESOURCE_STATS = gql`
mutation updateResourceStats($resourceId:String!) {
  updateResourceStats(resourceId: $resourceId)
}
`

export const UPDATE_GALLERY_STATS = gql`
mutation updateGalleryStats($galleryId:String!) {
  updateGalleryStats(galleryId: $galleryId)
}
`

export const UPDATE_NEWS_STATS = gql`
mutation updateNewsStats($newsId:String!) {
  updateNewsStats(newsId: $newsId)
}
`

export const UPDATE_USER_LOGGED_IN_STATUS = gql`
mutation updateUserLoggedInStatus($userId:String!) {
  updateUserLoggedInStatus(userId: $userId)
}
`

export const UPDATE_USER_NOTIFICATION = gql`
mutation updateUserNotification($notificationId:String!){
  updateUserNotification(notificationId:$notificationId){
    id
    notification
    readBy
  }
}
`;

export const CREATE_USER_NOTIFICATION = gql`
mutation createUserNotification($notificationType:NotificationType, $notification:String, $url:String, $userToNotify:String, $languageObj: JSON){
  createUserNotification(notificationType:$notificationType, notification:$notification, url:$url, userToNotify:$userToNotify, languageObj:$languageObj){
    id
    notification
    userToNotify
    languageObj
  }
}
`;
import * as React from "react";
// import React, { useState } from 'react';
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Navbar from "../navbar/navbar";
import $ from 'jquery';
import { useSubscription, useQuery } from "@apollo/react-hooks";
import {
  NEW_NOTIFICATION_SUBSCRIPTION,
  GET_ALL_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  GET_LATEST_NOTIFICATIONS,
  GET_USER_RATINGS,
  UPDATE_USER_NOTIFICATION
} from "../../queries/common_queries";
import EN_Icon from "../../images/en.png";
import FR_Icon from "../../images/fr.png";
import AR_Icon from "../../images/ar.png";
//generics
import {
  ippfauthtoken,
  USER_ID,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  USER_TYPE,
} from "../../constants/genarics";
import { _getnotifications } from "../../middleware/index";
import { setNotofications, setModalStatus } from "../../actions/user-actions";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { EDIT_BASIC_PROFILE } from "../../queries/user_queries";

const languageOptions = [
  { text: "English", value: "en", key: "en" },
  { text: "العربية", value: "ar", key: "ar" },
  { text: "Français", value: "fr", key: "fr" },
  { text: "Español", value: "sp", key: "sp" },
];


const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  return {
    formNotificationsState: state.custom_reducer.formNotificationsState,
    ratingModalOpen: state.custom_reducer.ratingModalOpen,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setNotofications: (notifications) => {
      dispatch(setNotofications(notifications));
    },
    setModalStatus: (status, type) => {
      dispatch(setModalStatus(status, type));
    },
  };
};

function NewNotificationListener(client) {
  const {
    data,
    loading,
    error,
  } = useSubscription(NEW_NOTIFICATION_SUBSCRIPTION, { client: client.client });
  console.log(data);
  if (loading) {
    return false;
  }
  return true;

  // if (error) {
  //     return "";
  // }
  // console.log(notifications_arr);
  // return "Loading";
  //     return <li>

  //     <a href="#"><i style={{ paddingTop: "12%" }} className="fas fa-bell fa-2x"></i></a>
  //     <div uk-dropdown="pos: top-right ;mode : hover; animation: uk-animation-slide-bottom-small" class="uk-dropdown uk-dropdown-top-right  tm-dropdown-small border-radius-6 uk-padding-remove uk-box-shadow-large angle-top-right">
  //         <h5 class="uk-padding-small uk-margin-remove uk-text-bold  uk-text-left"> Notivications </h5>
  //         <a href="#" class="uk-position-top-right uk-link-reset"> <i class="fas fa-trash uk-align-right   uk-text-small uk-padding-small"> Clear all</i></a>
  //         <hr class=" uk-margin-remove" />
  //         <div class="uk-padding-smaluk-text-left uk-height-medium">
  //             <div data-simplebar>
  //                 <div class="uk-padding-small" uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 100 ; repeat: true">
  //                     <div class="uk-flex-middle uk-grid-small" uk-grid>
  //                         <div class="uk-width-3-4">
  //                             <p class="uk-margin-remove">Lorem ipsum dolor   ame ..</p>
  //                             <p class="uk-margin-remove-top uk-text-small uk-text-muted">25 min</p>
  //                         </div>
  //                     </div>
  //                     <div class="uk-flex-middle uk-grid-small" uk-grid>
  //                         <div class="uk-width-3-4">
  //                             <p class="uk-margin-remove">Lorem ipsum dolor   ame ..</p>
  //                             <p class="uk-margin-remove-top uk-text-small uk-text-muted">25 min</p>
  //                         </div>
  //                     </div>
  //                     <div class="uk-flex-middle uk-grid-small" uk-grid>
  //                         <div class="uk-width-3-4">
  //                             <p class="uk-margin-remove">Lorem ipsum dolor   ame ..</p>
  //                             <p class="uk-margin-remove-top uk-text-small uk-text-muted">25 min</p>
  //                         </div>
  //                     </div>
  //                     <div class="uk-flex-middle uk-grid-small" uk-grid>
  //                         <div class="uk-width-3-4">
  //                             <p class="uk-margin-remove">Lorem ipsum dolor   ame ..</p>
  //                             <p class="uk-margin-remove-top uk-text-small uk-text-muted">25 min</p>
  //                         </div>
  //                     </div>
  //                     <div class="uk-flex-middle uk-grid-small" uk-grid>
  //                         <div class="uk-width-3-4">
  //                             <p class="uk-margin-remove">Lorem ipsum dolor   ame ..</p>
  //                             <p class="uk-margin-remove-top uk-text-small uk-text-muted">25 min</p>
  //                         </div>
  //                     </div>
  //                     <div class="uk-flex-middle uk-grid-small" uk-grid>
  //                         <div class="uk-width-3-4">
  //                             <p class="uk-margin-remove">Lorem ipsum dolor   ame ..</p>
  //                             <p class="uk-margin-remove-top uk-text-small uk-text-muted">25 min</p>
  //                         </div>
  //                     </div>
  //                     <div class="uk-flex-middle uk-grid-small" uk-grid>
  //                         <div class="uk-width-3-4">
  //                             <p class="uk-margin-remove">Lorem ipsum dolor   ame ..</p>
  //                             <p class="uk-margin-remove-top uk-text-small uk-text-muted">25 min</p>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //     </div>
  // </li>;
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    var currentLocation = window.location.href;
    var url = currentLocation.split("?");
    url = url[0];
    url = url.split("/");
    var page = url[url.length - 1];
console.log(this.props.ratingModalOpen)
    this.state = {
      userId: localStorage.USER_ID,
      notifications: [],
      notifications_count: 0,
      click_count: 0,
      language: localStorage.LANG,
      flag: FR_Icon,
      langDict: { en: EN_Icon, fr: FR_Icon, ar: AR_Icon },

      showSearch: false,
      searchString: '',
      currentRoute: page,

    };
    this.get_notifications().then((result) => {
      console.log(result)
      this.setState({
        // notifications: result,
        notifications_count: result.length,
      });
    });
    this.get_latest_notifications().then((result) => {
      console.log(result)
      this.setState({
        notifications: result,
        // notifications_count: result.length
      });
    });
  }

  componentDidMount() {

    // if(localStorage.LANG === "ar"){
    //   $("p").attr("style", "direction: rtl !important");
    // }
    
    // localStorage.LANG = "en";
    // this.getUserRatings().then((result) => {
    //   console.log(result);
    //   if (!result.userRatings.length > 0) {
    //     if (localStorage.RATING_LATER != true) {
    //       console.log(localStorage.RATING_POPPED)
    //       if (localStorage.RATING_POPPED === 'not_popped') {
    //         setTimeout(() => {
    //           this.props.setModalStatus(true, "random");
    //           localStorage.RATING_POPPED = 'popped';
    //         }, 10000);
    //       }
    //     }
    //   }
    // });
  }

  getUserRatings = async (rating) => {
    const result = await this.props.client.mutate({
      mutation: GET_USER_RATINGS,
      variables: {
        createdBy: localStorage.USER_ID,
      },
    });
    console.log(result.data);
    return result.data.getUserRatings;
  };

  get_notifications = async () => {
    const result = await this.props.client.query({
      query: GET_ALL_NOTIFICATIONS,
      variables: {
        lang: localStorage.LANG,
      },
      fetchPolicy: "network-only",
    });
    return result.data.getNotifications;
  };

  get_latest_notifications = async () => {
    const result = await this.props.client.query({
      query: GET_LATEST_NOTIFICATIONS,
      variables: {
        lang: localStorage.LANG,
      },
      fetchPolicy: "network-only",
    });
    return result.data.getAllNotifications;
  };

  userResult = () => {
    this.props.history.push("/result");
  };

  updateProfile = () => {
    console.log(this.state.userId);
    this.props.history.push("/edit_profile?id=" + this.state.userId);
  };

  _notificationList = () => {
    console.log(this.state.notifications);
    return "hello";
  };

  logout = () => {
    this.getUserRatings().then((result) => {
      if (!result.userRatings.length > 0) {
        this.props.setModalStatus(true, "logout");
      } else {
        setTimeout(() => {
          localStorage.removeItem(ippfauthtoken);
          localStorage.removeItem(USER_ID);
          localStorage.removeItem(EMAIL);
          localStorage.removeItem(FIRST_NAME);
          localStorage.removeItem(LAST_NAME);
          localStorage.removeItem(USER_TYPE);
          {/* login route changed */}
          this.props.history.push("/");
        }, 500);
      }
    });
  };
  read_notification = () => {
    const { click_count, notifications } = this.state;
    // const temp_click_count = click_count === 0 ? click_count + 1 : 0;
    // this.setState({ notifications_count: 0, click_count: temp_click_count, notifications: click_count === notifications ? [] : notifications });
    this.setState({ notifications_count: 0 });
    notifications.map((notification) => {
      // console.log(notification.id);
      // console.log(notifications)
      // console.log("GENERAL:", notification.userToNotify ? notification.userToNotify : "no user")
      if(notification.userToNotify === null || notification.userToNotify === undefined) {
        this.update_notification(notification.id).then((result) => {
          // console.log(result);
        });
      }
      else {
        this.update_user_notification(notification.id).then((result) => {
          // console.log(result);
        });
      }
      
    });
  };

  update_notification = async (notificationId) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_NOTIFICATION,
      variables: { notificationId },
    });
    return result.data.updateNotification;
  };

  update_user_notification = async (notificationId) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_USER_NOTIFICATION,
      variables: { notificationId },
    });
    return result.data.updateUserNotification;
  };

  changeLanguage = async (e, { value }, data) => {
    localStorage.LANG = value;
    if(localStorage.USER_ID != undefined) {
      this.update_user_language(value).then((result) => {
        i18n.changeLanguage(value);
        this.setState({
          language: value,
        });
        window.location.reload();
      });
    }
    else {
      i18n.changeLanguage(value);
        this.setState({
          language: value,
        });
        window.location.reload();
    }
  };

  update_user_language = async (lang) => {
    const result = await this.props.client.mutate({
        mutation: EDIT_BASIC_PROFILE,
        variables: { userId: localStorage.USER_ID, languagePreference: lang }
    });
    return;
  };

  openSearch = async () => {
    console.log(this.state.showSearch)
    if(this.state.showSearch === true){
      this.setState({
        showSearch: false
      })
    }
    else{
      this.setState({
        showSearch: true
      })
    }
    
  }

  handleSearchChange = (e) => {
    this.setState({
      searchString: e.target.value
    })
  }

  openSearchResultsPage = () => {
    if(this.state.searchString != ''){
      this.props.history.push("/search_results?id=" + this.state.searchString);
    }
  }

  openSearchResultsPage1 = (e) => {
    if(e.key === 'Enter'){
      if(this.state.searchString != ''){
        this.props.history.push("/search_results?id=" + this.state.searchString);
      } 
    }
  }

  render() {
    const { notifications, notifications_count } = this.state;
    const { t } = this.props;
    return (
      <nav className="uk-navbar-transparent tm-mobile-header uk-animation-slide-top uk-position-z-index uk-navbar">
        <span
          className="uk-hidden@m tm-mobile-menu-icon"
          uk-toggle="target: #side-nav; cls: side-nav-active"
        >
          <i className="fas fa-bars icon-large"></i>
        </span>

        <span
          className="uk-hidden@m tm-mobile-user-icon uk-align-right"
          uk-toggle="target: #tm-show-on-mobile; cls: tm-show-on-mobile-active"
        >
          <i className="far fa-user icon-large"></i>
        </span>

        <a className="uk-hidden@m uk-logo" href="#">
          {" "}
          IPPF{" "}
        </a>
        <div className="uk-navbar-left uk-visible@m">
          {/* <a className="uk-navbar-item back-to-dashboard uk-button-text " href="#" uk-tooltip="title: back-to-dashboard ; delay: 700 ; pos: bottom-left ;animation:	uk-animation-scale-up">My Dashboard</a>
                    <a className="uk-navbar-item back-to-dashboard uk-button-text " href="#">Browse All Courses</a> */}
        </div>

        <div
          className=" uk-navbar-right tm-show-on-mobile uk-flex-right"
          id="tm-show-on-mobile"
        >
          {/* uk-nav-padding */}
          <span
            className="uk-hidden@m tm-mobile-user-close-icon uk-align-right"
            uk-toggle="target: #tm-show-on-mobile; cls: tm-show-on-mobile-active"
          >
            <i className="fas fa-times icon-large"></i>
          </span>
          {/* uk-text-uppercase */}
           {/* {(<NewNotificationListener client={this.props.client})? "ss":"ss" /> } */}

            {/* <NewNotificationListener client={this.props.client}/>    */}
            {/* <li onClick={() => this.openSearch()}>
                <i
                  style={{
                    marginTop: "-2rem",
                    color: "#ffffff",
                    textShadow: "3px 3px 16px #272634",
                    marginRight: '1rem',
                    cursor: 'pointer'
                  }}
                  className="fa fa-search"
                ></i>              
            </li>      */}
          {
            localStorage.LANG=="ar" ? (
              <ul className=" uk-navbar-nav uk-flex-middle">
                          <li>
                            <a href="#" uk-tooltip={t('my_profile')} pos="bottom">
                              <i
                                className="fas fa-user-circle fa-2x"
                                style={{
                                  paddingTop: "12%",
                                  color: "#ffffff",
                                  textShadow: "3px 3px 16px #272634",
                                }}
                              ></i>
                              
                            </a>
                            <div
                              uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-right"
                              className="uk-dropdown  uk-dropdown-top-right  tm-dropdown-small border-radius-6 angle-top-right"
                              style={{right:"27px"}}
                            >
                              <div style={{marginRight:"10px"}} className="uk-grid-small uk-flex-middle uk-margin-small-bottom uk-grid">
                                <div
                                  style={{ width: "10%" }}
                                  className="uk-width-1-4  uk-first-column"
                                >
                                  <i className="fas fa-user uk-align-center uk-border-circle"></i>
                                  {/* <img src="../assets/images/avatures/avature-2.png" alt="Image" className="uk-align-center uk-border-circle" /> */}
                                </div>
                                <div className="uk-width-3-4" style={{ width: "90%" }}>
                                  <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">
                                    {" "}
                                    {localStorage.FIRST_NAME +
                                      " " +
                                      localStorage.LAST_NAME}{" "}
                                  </p>
                                  <p className="uk-margin-remove-top uk-text-small uk-margin-small-bottom">
                                    {" "}
                                  </p>
                                </div>
                              </div>
                              <ul className="uk-nav uk-dropdown-nav">
                                <li onClick={() => this.userResult()}>
                                  <a href="#">
                                    {" "}
                                    <i style={{marginLeft:"10px"}} className="fas fa-poll-h uk-margin-small-right"></i> 
                                    {t('learning_dashboard')}
                                  </a>
                                </li>
                                <li onClick={() => this.updateProfile()}>
                                  <a href="#">
                                    {" "}
                                    <i style={{marginLeft:"10px"}}  className="fas fa-cog uk-margin-small-right"></i> 
                                   {t('my_account')}
                                  </a>
                                </li>
                                <li className="uk-nav-divider"></li>
                                <li onClick={() => this.logout()}>
                                  <a>
                                    {" "}
                                    <i style={{marginLeft:"10px"}} className="fas fa-sign-out-alt uk-margin-small-right"></i>{" "}
                                   {t('logout')}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>

                          <li onClick={() => this.read_notification()}>
                            <a uk-tooltip={t('notification')} pos="bottom">
                                  <i
                                  style={{
                                    paddingTop: "12%",
                                    color: "#ffffff",
                                    textShadow: "3px 3px 16px #272634",
                                  }}
                                  // className="fas fa-bell fa-2x"
                                  className={notifications_count<=9?"smallerCount fas fa-bell fa-2x":"greaterCount fas fa-bell fa-2x"}
                                  data-count={notifications_count}
                                  ></i>
                            </a>
                            <div
                              uk-dropdown="pos: top-right ;mode : click; animation: uk-animation-slide-bottom-small"
                              style={{right:"85px"}}
                              class="uk-dropdown uk-dropdown-top-right  tm-dropdown-small border-radius-6 uk-padding-remove uk-box-shadow-large angle-top-right"
                            >
                              <h5 class="uk-padding-small uk-margin-remove uk-text-bold  uk-text-right">
                                {" "}
                               {t('notification')}{" "}
                              </h5>
                              <p
                                style={{ padding: "10px" }}
                                className="uk-margin-remove uk-text-small uk-text-muted"
                              >
                                <strong>
                                  {t('notification_title')}
                                  {/* Please visit the relevant page to view <br />
                                  the new addition. ------------> changed to --------->  For any new additions, please visit the relevant section to view the update.*/}
                                </strong>
                              </p>
                              <hr class=" uk-margin-remove" />
                              <div class="uk-padding-smaluk-text-left uk-height-medium">
                                <div data-simplebar="true">
                                  <div
                                    class="uk-padding-small"
                                    uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 100 ; repeat: true"
                                  >
                                    {notifications.length > 0 ? (
                                      notifications.map((notification, i) => {
                                        const uploadedAt = moment(
                                          notification.createdAt
                                        ).format("DD-MM-YYYY");
                                        return (
                                          <div className="uk-flex-middle uk-grid-small uk-grid records_visibility">
                                            <div>
                                              <p className="uk-margin-remove">
                                                {notification.notification}
                                              </p>
                                              {
                                                notification.userToNotify != null ?
                                                  <p className="uk-margin-remove-top uk-text-small uk-text-muted">
                                                  {t('notification_date')}: {uploadedAt}
                                                </p>
                                                :
                                                <p className="uk-margin-remove-top uk-text-small uk-text-muted">
                                                {t('uploaded_on')} {uploadedAt}
                                              </p>
                                              }
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="uk-flex-middle uk-grid-small uk-grid mine">
                                        <div className="uk-width-3-4">
                                          <p className="uk-margin-remove-top uk-text-small uk-text-muted">
                                           {t('uploaded_on')}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          {
                            this.state.currentRoute != 'search_results' ?
                            <div className="homeSearchDiv2">
                            <input className="homeSearchInput2" value={this.state.searchString} onChange={(e) => this.handleSearchChange(e)} onKeyPress={(e) => this.openSearchResultsPage1(e)} />
                            <i
                              style={{
                                // marginTop: '0.8rem',
                                color: "#3E93CC",
                                marginRight: '1rem',
                                cursor: 'pointer',
                                fontSize: '0.9rem'
                              }}
                              className="fa fa-search"
                              onClick={() => this.openSearchResultsPage()}
                            ></i>
                            
                          </div>
                          : null
                          }
                            {/* Uncomment to enable language switcher in user site */}
              
                            <li style={{paddingRight:"10px"}}>
                            <Dropdown
                              className="languageSwitcher languageClass"
                              inline
                              options={languageOptions}
                              value={this.state.language}
                              onChange={this.changeLanguage}
                            />
                          </li>
                        </ul>
              
            ):(
              <ul className=" uk-navbar-nav uk-flex-middle">
           


              {/* Uncomment to enable language switcher in user site */}
              
                          <li>
                            <Dropdown
                              className="languageSwitcher languageClass"
                              inline
                              options={languageOptions}
                              value={this.state.language}
                              onChange={this.changeLanguage}
                            />
                          </li>
              
                          {
                            this.state.currentRoute != 'search_results' ?
                            <div className="homeSearchDiv2">
                            <input className="homeSearchInput2" value={this.state.searchString} onChange={(e) => this.handleSearchChange(e)} onKeyPress={(e) => this.openSearchResultsPage1(e)} />
                            <i
                              style={{
                                // marginTop: '0.8rem',
                                color: "#3E93CC",
                                marginRight: '1rem',
                                cursor: 'pointer',
                                fontSize: '0.9rem'
                              }}
                              className="fa fa-search"
                              onClick={() => this.openSearchResultsPage()}
                            ></i>
                            
                          </div>
                          : null
                          }
                          
                          <li onClick={() => this.read_notification()}>
                            <a uk-tooltip={t('notification')} pos="bottom">
                                  <i
                                  style={{
                                    paddingTop: "12%",
                                    color: "#ffffff",
                                    textShadow: "3px 3px 16px #272634",
                                  }}
                                  // className="fas fa-bell fa-2x"
                                  className={ notifications_count<=9 ? "smallerCount fas fa-bell fa-2x":"greaterCount fas fa-bell fa-2x"}
                                  data-count={notifications_count}
                                  ></i>
                            </a>
                            <div
                              uk-dropdown="pos: top-right ;mode : click; animation: uk-animation-slide-bottom-small"
                              class="uk-dropdown uk-dropdown-top-right  tm-dropdown-small border-radius-6 uk-padding-remove uk-box-shadow-large angle-top-right"
                            >
                              <h5 class="uk-padding-small uk-margin-remove uk-text-bold  uk-text-left">
                                {" "}
                               {t('notification')}{" "}
                              </h5>
                              <p
                                style={{ padding: "10px" }}
                                className="uk-margin-remove uk-text-small uk-text-muted"
                              >
                                <strong >
                                  {t('notification_title')}
                                  {/* Please visit the relevant page to view <br />
                                  the new addition. ------------> changed to --------->  For any new additions, please visit the relevant section to view the update.*/}
                                </strong>
                              </p>
                              <hr class=" uk-margin-remove" />
                              <div class="uk-padding-smaluk-text-left uk-height-medium">
                                <div data-simplebar="true">
                                  <div
                                    class="uk-padding-small"
                                    uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 100 ; repeat: true"
                                  >
                                    {notifications.length > 0 ? (
                                      notifications.map((notification, i) => {
                                        const uploadedAt = moment(
                                          notification.createdAt
                                        ).format("DD-MM-YYYY");
                                        return (
                                          <div className="uk-flex-middle uk-grid-small uk-grid records_visibility">
                                            <div>
                                              <p className="uk-margin-remove">
                                                {notification.notification}
                                              </p>
                                              {
                                                notification.userToNotify != null ?
                                                  <p className="uk-margin-remove-top uk-text-small uk-text-muted">
                                                  {t('notification_date')}: {uploadedAt}
                                                </p>
                                                :
                                                <p className="uk-margin-remove-top uk-text-small uk-text-muted">
                                                {t('uploaded_on')} {uploadedAt}
                                              </p>
                                              }
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="uk-flex-middle uk-grid-small uk-grid mine">
                                        <div className="uk-width-3-4">
                                          <p className="uk-margin-remove-top uk-text-small uk-text-muted">
                                           {t('uploaded_on')}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            {/* style={{paddingTop:"12%"}} */}
                            <a href="#" uk-tooltip={t('my_profile')} pos="bottom">
                              <i
                                className="fas fa-user-circle fa-2x"
                                style={{
                                  paddingTop: "12%",
                                  color: "#ffffff",
                                  textShadow: "3px 3px 16px #272634",
                                }}
                              ></i>
                              {/* icon-large uk-border-circle user-profile-tiny */}
                              {/* <img src="../assets/images/avatures/avature-2.png" alt="" className="uk-border-circle user-profile-tiny" /> */}
                            </a>
                            <div
                              uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-right"
                              className="uk-dropdown  uk-dropdown-top-right  tm-dropdown-small border-radius-6 angle-top-right"
                            >
                              <div className="uk-grid-small uk-flex-middle uk-margin-small-bottom uk-grid">
                                <div
                                  style={{ width: "10%"}}
                                  className="uk-width-1-4  uk-first-column"
                                >
                                  <i className="fas fa-user uk-align-center uk-border-circle"></i>
                                  {/* <img src="../assets/images/avatures/avature-2.png" alt="Image" className="uk-align-center uk-border-circle" /> */}
                                </div>
                                <div className="uk-width-3-4" style={{ width: "90%" }}>
                                  <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">
                                    {" "}
                                    {localStorage.FIRST_NAME +
                                      " " +
                                      localStorage.LAST_NAME}{" "}
                                  </p>
                                  <p className="uk-margin-remove-top uk-text-small uk-margin-small-bottom">
                                    {" "}
                                  </p>
                                </div>
                              </div>
                              <ul className="uk-nav uk-dropdown-nav">
                                {/* <li>
                                                      <a href="#"> <i className="fas fa-user uk-margin-small-right"></i> Profile</a>
                                                  </li> */}
                                <li onClick={() => this.userResult()}>
                                  <a href="#">
                                    {" "}
                                    <i className="fas fa-poll-h uk-margin-small-right"></i> 
                                    {t('learning_dashboard')}
                                  </a>
                                </li>
                                <li onClick={() => this.updateProfile()}>
                                  <a href="#">
                                    {" "}
                                    <i className="fas fa-cog uk-margin-small-right"></i> 
                                   {t('my_account')}
                                  </a>
                                </li>
                                <li className="uk-nav-divider"></li>
                                <li onClick={() => this.logout()}>
                                  <a>
                                    {" "}
                                    <i className="fas fa-sign-out-alt uk-margin-small-right"></i>{" "}
                                   {t('logout')}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
              
            )
          }
                 </div>
      </nav>
    );
  }
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Header)))
);

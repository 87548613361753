import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
// import moment from "moment";
import Moment from 'react-moment';
//image upload file
import Images from "../Image/Images";

//constants
import { UPLOAD_API_URL } from "../../../constants/index";

// queries
import {
  CREATE_GALLERY,
  CREATE_GALLERY_IMAGE,
  UPDATE_GALLERY,
  UPDATE_GALLERY_IMAGE,
  DELETE_GALLERY_IMAGE,
} from "../../../queries/user_queries";

import { GET_GALLERY } from "../../../queries/common_queries";

import { Button } from "react-uikit";
import { isModuleDeclaration } from "@babel/types";
import { from } from "zen-observable";
import { uploadFile, deleteFile } from "../../../firebase/FileServices";
import { Input } from "semantic-ui-react";
import ConfirmationModal from '../../../components/adminModals/confirmationModal';


const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class AddGallery extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");
    if (id == null) {
      this.state = {
        data: null,
        // id: "",
        galleryTitle: "",
        galleryHelperText: "",
        galleryDescription: "",
        // _id: "",
        // galleryID: "",
        eventDate: "",
        isActive: true,
        loading: false,

        isUploading: false,
        isImageUploading: false,
        fileRef: "",
        image: "",
        galleryBannerImage: "",
        formData: "",
        selectedImages: [],
        uploadedImage: [],
        // galleryImages:[],
        buttonVisible: false,
        images: [
          {
            imageFileRef: "",
            imageTitle: "",
            image: "",
            imageUrl: "",
            imageHelperText: "",
            imageDescription: "",
          },
        ],
        url: "",
        formAction: "ADD",
        supportingLanguages: this.props.translatingLanguages,
        supportingLangErrorMsg: "",

        videos: [],
        videoFiles: [],
        videoError: "",
        isVideoUploading: false,

        blurrModalOpen: false,
        modalOpen: false,
        pinned: false
      };
    } else {
      this.state = {
        data: null,
        // id: id,
        pagination: {
          galleryId: id,
          lang: "en",
        },
        lang: "en",
        galleryTitle: "",
        galleryHelperText: "",
        galleryDescription: "",
        // _id: "",
        // galleryId: id,
        // galleryID: "",
        GetGalleryParameter: {
          galleryId: id,
          skip: 0,
          first: 6,
        },
        eventDate: "",
        isActive: true,
        loading: false,

        isUploading: false,
        isImageUploading: false,
        fileRef: "",
        image: "",
        galleryBannerImage: "",
        formData: "",
        selectedImages: [],
        uploadedImage: [],
        // galleryImages:[],
        buttonVisible: false,
        images: [
          {
            imageFileRef: "",
            imageTitle: "",
            image: "",
            imageUrl: "",
            imageHelperText: "",
            imageDescription: "",
          },
        ],
        url: "",
        fileName: "",
        formAction: "EDIT",
        formLanguage: "en",
        supportingLanguages: [],
        supportingLangErrorMsg: "",

      };
    }

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });

    this.handleChange = this.handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
  }

  componentDidMount() {
    // this._removeImage(0);
    this.setState({ loading: true });
    if (this.state.formAction == "EDIT") {
      this._getSingleGallery();
    } else {
      this.setState({ loading: false });
    }
    //supporting languages array
    // if (this.state.formAction === "ADD") {
    //   const supportingLanguages = this.props.translatingLanguages;
    //   console.log(supportingLanguages)
    //   this.setState({
    //     supportingLanguages: supportingLanguages,
    //   });
    // }
  }

  _getSingleGallery() {
    this._getGallery(this.state.pagination).then((result) => {
      console.log(result);
      this.setState({
        galleryId: result.gallery.id,
        galleryTitle: result.gallery.galleryTitle,
        galleryBannerImage: result.gallery.galleryBannerImage,
        galleryHelperText: result.gallery.galleryHelperText,
        galleryDescription: result.gallery.galleryDescription,
        eventDate: result.gallery.eventDate,
        image: result.gallery.galleryBannerImage,
        gallery: result.gallery,
        galleryImages: result.galleryImages.galleryImages,
        // uploadedImage: result.galleryImages.galleryImages,
        loading: false,
      });
      //   this.setState({
      //     galleryTitle: result.galleryTitle,
      //     galleryBannerImage: result.galleryBannerImage,
      //     galleryHelperText: result.galleryHelperText,
      //     galleryDescription: result.galleryDescription,
      //     data: result.content,
      //     loading: false,
      // });
    });
  }

  _getGallery = async (pagination) => {
    // console.log(galleryId)
    const result = await this.props.client.query({
      query: GET_GALLERY,
      variables: pagination,
      fetchPolicy: "network-only",
    });
    console.log(result);
    return result.data.getGallery;
  };

  handleChange(event) {
    console.log(event.target.value);
    this.setState({ [event.target.id]: event.target.value });
  }

  imageUploadOnClick = (event) => {
    this.setState({
      images: {
        isImageUploading: true,
      },
    });
    console.log(this.state.selectedFiles);
    this._startImageUpload(this.state.selectedFile);
  };

  _startImageUpload = async (file) => {
    var intDate = Number(new Date());
    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    console.log(result);
    if (result.status === true) {
      this.setState({
        images: {
          isImageUploading: false,
          imageFileRef:
            "uploads/" + localStorage.FIRST_NAME + intDate + "/" + file.name,
          image: result.url,
          imageUrl: result.url,
        },
      });
      console.log(this.state.images);
    }
  };

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    console.log(event.target.files[0]);
  };

  // _deleteImageFile = async path => {
  //   const deleteRef = await deleteFile(path);
  //   this.setState({
  //     images: {
  //       image: "",
  //       imageUrl: ""
  //     }
  //   });
  //   return deleteRef;
  // };

  deleteImage = async (path) => {
    console.log(path);
    const deleteRef = await deleteFile(path);
    this.setState({
      url: "",
      fileName: "",
    });
    return deleteRef;
  };

  documentUploadOnClick = (event) => {
    this.setState({
      isUploading: true,
    });
    console.log(this.state.formData);
    this._startDocUpload(this.state.formData);
  };

  documentUploadOnChange = (event) => {
    this.setState({
      formData: event.target.files[0],
    });
  };

  _startDocUpload = async (file) => {
    var intDate = Number(new Date());
    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    console.log(result);
    if (result.status) {
      this.setState({
        isUploading: false,
        fileRef:
          "uploads/" + localStorage.FIRST_NAME + intDate + "/" + file.name,
        image: result.url,
        galleryBannerImage: result.url,
      });
    }
  };

  _startSelectedFilesUpload = async (file) => {
    var intDate = Number(new Date());
    // console.log("hello")
    console.log(file);
    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    console.log(result);

    if (result.status) {
      const galleryImg = {
        status: result.status,
        url: result.url,
        fileName: file.name,
      };
      // console.log(galleryImg)
      this.setState({
        isImageUploading: false,
        uploadedImage: [...this.state.uploadedImage, galleryImg],
        // galleryImages: [...this.state.uploadedImage, galleryImg],
      });
    }
    // console.log(this.state);
  };

  _deleteFile = async (path) => {
    console.log(path);
    const deleteRef = await deleteFile(path);
    this.setState({ image: "", galleryBannerImage: "" });
    return deleteRef;
  };

  _deleteImages = async (path) => {
    const deleteRef = await deleteFile(this.state.uploadedImage[path].url);
    console.log(this.state.uploadedImage);
    console.log(this.state.uploadedImage[path].url);
    // delete this.state.uploadedImage[path]
    this.state.uploadedImage.splice(path, 1);
    this.setState({
      uploadedImage: this.state.uploadedImage,
      // galleryImages: this.state.uploadedImage
    });
    return deleteRef;
  };

  // _deleteImageFile = async path => {
  //   console.log(path);
  //   const deleteRef = await deleteFile(path);
  //   this.setState({ url: "",
  //   fileName: "" });
  //   return deleteRef;
  // };

  _handleSubmit(event) {
    this.props.setFormStatus({ status: false, title: "", message: "" });
    this.props.setSuccessStatus({ status: false, title: "", message: "" });

    // this.setState({ loading: true });
    const { galleryTitle, uploadedImage, eventDate, galleryBannerImage } = this.state;
    console.log(galleryBannerImage);
    if (galleryTitle === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a title for the album.",
      });
    } else if (galleryBannerImage === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a cover image for this album.",
      });
    } else if (eventDate === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide an event date for this album.",
      });
    }

    else {
      this.props.setFormStatus({ status: false, title: "", message: "" });
      this.props.setSuccessStatus({ status: false, title: "", message: "" });

      if (this.state.formAction == "ADD") {
        const supportingLangs = { languages: [] };
        const tempArr = this.state.supportingLanguages.filter(
          (obj) => obj.supporting === true
        );
        if (tempArr.length > 0) {
          tempArr.map((lang) =>
            lang.key != "en" ? supportingLangs.languages.push(lang.key) : null
          );
        }
        this.createGallery()
          .then((result) => {
            console.log(result);
            uploadedImage.map((gallery) => {
              const data = {
                galleryID: result.id,
                image: gallery.url,
                fileName: gallery.fileName,
                supportingLangs: supportingLangs,
              };
              this.createGalleryImage(data).then((res) => { });

              
              this.setState({
                data: "",
                loading: false,
                galleryTitle: "",
                galleryBannerImage: "",
                galleryDescription: "",
                galleryHelperText: "",
                uploadedImage: [],
                selectedFiles:[],
                // pinned:"",
                image: "",
                eventDate: "",
                videos: "",
            
              })

              this.props.setSuccessStatus({
                status: true,
                title: "",
                message: "Album created successfully!",
              });

            });

            // window.location.reload();
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message:
              <div>
                An error occurred while trying to create this album!
                <br/>
                Please try again.
              </div>
            });
          });

      } else if (this.state.formAction == "EDIT") {
        this._updateGallery()
          .then((result) => {
            console.log(result);
            uploadedImage.map((gallery) => {
              const data = {
                galleryID: result.id,
                image: gallery.url,
                fileName: gallery.fileName,
              };
              this.updateGalleryImage(data).then((re) => {
                this._getSingleGallery();

                this.props.setSuccessStatus({
                  status: true,
                  title: "",
                  message: "Gallery updated successfully.",
                });
              });
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message: "An error occurred while trying to update the gallery!",
            });
          });
      }
    }
  }

  createGallery = async () => {
    const {
      galleryTitle,
      galleryBannerImage,
      galleryHelperText,
      galleryDescription,
      data,
      isActive,
      eventDate,
      id,
      videos,
      pinned
    } = this.state;
    const content = data;
    const supportingLangs = { languages: [] };
    const tempArr = this.state.supportingLanguages.filter(
      (obj) => obj.supporting === true
    );
    if (tempArr.length > 0) {
      tempArr.map((lang) =>
        lang.key != "en" ? supportingLangs.languages.push(lang.key) : null
      );
    }
    console.log(supportingLangs);

    var videoList = { videos: videos }

    const result = await this.props.client.mutate({
      mutation: CREATE_GALLERY,
      variables: {
        galleryTitle,
        galleryBannerImage,
        galleryHelperText,
        galleryDescription,
        content,
        isActive,
        eventDate,
        id,
        supportingLangs,
        videos: videoList,
        pinned
      },
    });
    // console.log(result);
    return result.data.createGallery;
  };

  createGalleryImage = async (data) => {
    const result = await this.props.client.mutate({
      mutation: CREATE_GALLERY_IMAGE,
      variables: data,
    });
    return result.data.createGalleryImage;
  };

  _updateGallery = async () => {
    const {
      galleryId,
      galleryTitle,
      galleryBannerImage,
      galleryHelperText,
      galleryDescription,
      data,
      isActive,
      eventDate,
      pinned,
      lang,
    } = this.state;
    const content = data;

    const result = await this.props.client.mutate({
      mutation: UPDATE_GALLERY,
      variables: {
        galleryId,
        galleryTitle,
        galleryBannerImage,
        galleryHelperText,
        galleryDescription,
        content,
        isActive,
        eventDate,
        pinned,
        lang,
      },
    });
    return result.data.updateGallery;
  };

  updateGalleryImage = async (data) => {
    const {
      galleryImageId,
      imageTitle,
      image,
      imageHelperText,
      imageDescription,
      isActive,
      fileName,
    } = this.state;

    const result = await this.props.client.mutate({
      mutation: UPDATE_GALLERY_IMAGE,
      variables: data,
      // { galleryImageId,imageTitle,image,imageHelperText,imageDescription,isActive,fileName },
    });
    return result.data.updateGalleryImage;
  };

  _addMoreImages() {
    this.setState((prevState) => ({
      images: [
        ...prevState.images,
        {
          imageTitle: "",
          image: "",
          imageHelperText: "",
          imageDescription: "",
        },
      ],
    }));
  }

  _removeImage(i) {
    let images = [...this.state.images];
    images.splice(i, 1);
    this.setState({ images });
  }

  componentDidMount() {
    this._removeImage(0);
  }

  _handleImageChange(i, e) {
    const { name, value } = e.target;
    let images = [...this.state.images];
    images[i] = { ...images[i], [name]: value };
    this.setState({ images });
  }

  fileSelectedHandler = (e) => {
    console.log(e.target.files);
    this.setState({
      selectedImages: e.target.files,
    });
  };

  _uploadSelectedImages = () => {
    console.log(this.state.selectedImages);
    const { galleryTitle } = this.state;

    if (this.state.selectedImages.length !== 0) {
      Array.from(this.state.selectedImages).forEach((file) => {
        console.log(file);

        this.setState({
          isImageUploading: true,
        });
        this._startSelectedFilesUpload(file).then((result) => {
          // galleryTitle!='' ? (

          this.setState({ buttonVisible: true });

          // ):(
          //   this.setState({ loading:false ,buttonVisible:false})
          // )
        });
      });
    }
  };

  _createGalleryImageUI() {
    console.log(this.state.images);

    return this.state.images.map((el, i) => (
      <div className="admin-content-inner" key={i}>
        <div className="uk-margin-medium">
          <form className="ui form">
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="far fa-images uk-margin-small-right"></i>{" "}
                    Gallery Image #{i + 1}
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">
                    <div className="uk-form-label"> Image Title </div>
                    <input
                      value={el.imageTitle || ""}
                      className="uk-input uk-form-width-huge"
                      id="imageTitle"
                      name={"imageTitle"}
                      placeholder="Title"
                      type="text"
                      onChange={this._handleImageChange.bind(this, i)}
                    />
                    <div className="uk-form-label"> Image </div>
                    <div>
                      <div className="">
                        {/* File Component */}
                        {el.image !== null &&
                          el.image !== undefined &&
                          el.image !== "" ? (
                            <div className="file-upload-group uk-card uk-card-default uk-card-body">
                              <div className="uk-text-center uk-grid ">
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="download"
                                    class="uk-button uk-button-primary uk-button-small"
                                    onClick={() => {
                                      window.open(el.image);
                                    }}
                                  >
                                    Download
                                </span>
                                </div>
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="trash alternate outline"
                                    class="uk-button uk-button-secondary uk-button-small"
                                    onClick={() => {
                                      this._deleteImageFile(el.image).then(
                                        (result) => {
                                          console.log(result);
                                          this.setState({
                                            images: {
                                              image: null,
                                              imageFileRef: null,
                                            },
                                          });
                                        }
                                      );
                                    }}
                                  >
                                    Delete
                                </span>
                                </div>
                              </div>
                              <br />
                              <Images
                                image={el.imageUrl}
                              // removeImage={this.removeImage}
                              />
                            </div>
                          ) : (
                            <div className="uk-text-center uk-grid">
                              <div className="uk-width-auto@m">
                                <Input
                                  type="file"
                                  name="file"
                                  onChange={this.onChangeHandler}
                                  // onChange={this._handleImageChange.bind(this, i)}
                                  // name="upload"
                                  id="upload"
                                  className="uk-input uk-form-width-huge"
                                  placeholder="Upload here"
                                  // type="file"
                                  multiple
                                />
                              </div>
                              <div className="uk-width-auto@m">
                                <span
                                  class="uk-button uk-button-default"
                                  onClick={this.imageUploadOnClick}
                                >
                                  {this.state.loading ? (
                                    <span
                                      data-uk-spinner=""
                                      style={{ color: "#o6a7e3" }}
                                    />
                                  ) : (
                                      "Click here to Upload Image"
                                    )}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <br />
                      {el.isImageUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                            Just a moment…
                            <br/>
                            We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>

                    {/* <div className="uk-text-center uk-grid" >
                                                            <div className="uk-width-auto@m">
                                                                <Input
                                                                    onChange={this._handleImageChange.bind(this, i)}
                                                                    name="upload"
                                                                    id="upload"
                                                                    className="uk-input uk-form-width-huge"
                                                                    placeholder="Upload here"
                                                                    type="file"
                                                                    multiple
                                                                />
                                                            </div>
                                                            <div className="uk-width-auto@m">
                                                                <span class="uk-button uk-button-default" onClick={this.imageUploadOnClick}>
                                                                    {this.state.loading ? (
                                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                                    ) : ("Click here to Upload image")}
                                                                </span>
                                                            </div>
                                                        </div> */}

                    {/* <input
                      required
                      value={el.image || ''}
                      className="uk-input uk-form-width-huge"
                      id="image"
                      name="image"
                      placeholder="URL"
                      type="text"
                      onChange={this._handleImageChange.bind(this, i)}
                    /> */}
                    <div className="uk-form-label"> Image Helper Text </div>
                    <textarea
                      value={el.imageHelperText || ""}
                      className="uk-textarea"
                      rows="4"
                      id="imageHelperText"
                      name="imageHelperText"
                      onChange={this._handleImageChange.bind(this, i)}
                    ></textarea>
                    <div className="uk-form-label"> Image Description </div>
                    <textarea
                      value={el.imageDescription || ""}
                      className="uk-textarea"
                      rows="4"
                      id="imageDescription"
                      name="imageDescription"
                      onChange={this._handleImageChange.bind(this, i)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="uk-button uk-button-primary uk-margin"
              style={{ height: 40 }}
              type="button"
              onClick={this._removeImage.bind(this, i)}
            >
              Remove Image
            </Button>
          </form>
        </div>
        <hr />
      </div>
    ));
  }

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/admin_home");
    } else if (path == "MANAGEGALLERY") {
      this.props.history.push("/admin_gallery_list");
    } else {
      return false;
    }
  };

  handleLanguageClick = (lang, supported) => {
    if (lang != "English") {
      const tempArray = this.state.supportingLanguages;
      const index = this.state.supportingLanguages.findIndex(
        (obj) => obj.language === lang
      );
      tempArray[index].supporting = supported;
      this.setState({
        supportingLanguages: tempArray,
        supportingLangErrorMsg: "",
      });
    } else {
      this.setState({
        supportingLangErrorMsg: "*Module must support English Language",
      });
    }
  };




  videoUploadOnChange = (e) => {
    console.log(e.target.files);
    console.log(e.target.files[0].type);
    var files = [];
    var type = [];
    var splitArray = [];
    files = e.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        type = files[i].type;
        if (type != '') {
          splitArray = type.split('/');
          if (splitArray[0] != 'video') {
            this.setState({
              videoError: '* Unsupported file format',
            });
          }
          else {
            this.setState({
              videoFiles: e.target.files,
              videoError: '',
            });
          }
        }

      }
    }


  }

  handleChangeVideoCaption = (e, index) => {
    console.log(e.target.value)
    const tempArray = this.state.videos;
    tempArray[index].description = e.target.value;
    this.setState({
      videos: tempArray,
    })
  }

  removeVideo = (index) => {
    console.log("index: ", index)
    const tempArray = this.state.videos;
    tempArray.splice(index, 1);
    this.setState({
      videos: tempArray,
      blurrModalOpen: false,
    })
  }

  videoUploadOnClick = async (e) => {
    this.setState({ isVideoUploading: true })
    var videoList = this.state.videos;
    var fileinfo = document.forms.namedItem("fileinfo");
    console.log(fileinfo);
    e.preventDefault();
    fetch('https://kmsvideo.skotie.com/upload', {
      method: 'POST',
      body: new FormData(fileinfo)
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.locations) {
          if (result.locations.length > 0) {
            result.locations.map(video => (
              videoList.push({ url: video.location, description: "" })
            ))
          }
        }
        this.setState({
          isVideoUploading: false,
          videos: videoList
        })
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isVideoUploading: false
        })
      });
  }

  openVideoModal = (index) => {
    this.setState({
      blurrModalOpen: true,
      deleteVideoIndex: index,
    })


  }

  closeVideoModal = () => {
    this.setState({
      blurrModalOpen: false,
    })
  }


  openModal = (index) => {
    this.setState({
      modalOpen: true,
      deleteID: index,
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
    })
  }

  removeGalleryImage = (index) => {
    console.log("index: ", index)
    const tempArray = this.state.uploadedImage;
    tempArray.splice(index, 1);
    this.setState({
      uploadedImage: tempArray,
      modalOpen: false,
    })
  }

  render() {
    const {
      loading,
      image,
      formAction,
      url,
      galleryImages,
      formLanguage,
      supportingLanguages,
      supportingLangErrorMsg,
      blurrModalOpen,
      modalOpen
    } = this.state;
    const {
      formErrorStatus,
      formSuccessState,
      translatingLanguages,
    } = this.props;
    console.log(image);
    console.log(this.state.uploadedImage);
    console.log(translatingLanguages);

    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>Home</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("MANAGEGALLERY")}>
                Manage Gallery
              </a>
            </li>
            {formAction == "ADD" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("ADDGALLERY")}>
                  Add New Album 
                </a>
              </li>
            ) : formAction == "EDIT" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("EDITGALLERY")}>
                  Edit Gallery
                </a>
              </li>
            ) : null}
          </ul>
        </div>

        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-image icon-large uk-margin-right"></i>
          {formAction == "ADD" ? (
            <h4 className="uk-margin-remove"> Add New Album  </h4>
          ) : formAction == "EDIT" ? (
            <h4 className="uk-margin-remove"> Edit Album </h4>
          ) : null}
        </div>
        <div className="uk-margin-medium">
          <form
            className="ui form"
          // onSubmit={this._handleSubmit}
          >
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="fas fa-image uk-margin-small-right"></i>{" "}
                    Album Details
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">
                    <div className="uk-form-label"> Album Title </div>
                    <input
                      // required
                      value={this.state.galleryTitle || ""}
                      className="uk-input uk-form-width-huge"
                      id="galleryTitle"
                      name="galleryTitle"
                      placeholder="Title"
                      type="text"
                      onChange={this.handleChange}
                    />

                    {formAction === "ADD" ? (
                      <div>
                        <div className="uk-form-label">
                          {" "}
                          <text>Supporting Languages</text>
                          <text className="langErrorMessage">
                            {this.state.supportingLangErrorMsg}
                          </text>{" "}
                        </div>
                        {supportingLanguages
                          ? supportingLanguages.length > 0
                            ? supportingLanguages.map((lang) => (
                              <span
                                className={
                                  lang.supporting === true
                                    ? "supportingLanguageActive"
                                    : "supportingLanguage"
                                }
                                onClick={() =>
                                  this.handleLanguageClick(
                                    lang.language,
                                    !lang.supporting
                                  )
                                }
                              >
                                {lang.language}
                              </span>
                            ))
                            : null
                          : null}
                      </div>
                    ) : null}

                    <div className="uk-form-label">Upload Banner Image </div>

                    <div>
                      <div className="">
                        {/* File Component */}
                        {this.state.image !== null &&
                          this.state.image !== undefined &&
                          this.state.image !== "" ? (
                            <div className="file-upload-group uk-card uk-card-default uk-card-body">
                              <div className="uk-text-center uk-grid ">
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="download"
                                    class="uk-button uk-button-primary uk-button-small"
                                    onClick={() => {
                                      window.open(this.state.image);
                                    }}
                                  >
                                    Download
                                </span>
                                </div>
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="trash alternate outline"
                                    class="uk-button uk-button-secondary uk-button-small"
                                    onClick={() => 
                                    //   {
                                    //   this._deleteFile(this.state.image).then(
                                    //     (result) => {
                                    //       console.log(result);
                                    //       this.setState({
                                    //         image: null,
                                    //         fileRef: null,
                                    //       });
                                    //     }
                                    //   );
                                    // }
                                    this.setState({confirmationModal: true})
                                  }
                                  >
                                    Delete
                                </span>
                                </div>
                              </div>
                              <br />
                              <Images
                                image={image}
                              // removeImage={this.removeImage}
                              />
                            </div>
                          ) : (
                            <div className="uk-text-center uk-grid">
                              <div className="uk-width-auto@m">
                                <Input
                                  onChange={this.documentUploadOnChange}
                                  name="upload"
                                  id="upload"
                                  className="uk-input uk-form-width-huge"
                                  placeholder="Upload here"
                                  type="file"
                                  multiple
                                />
                              </div>
                              <div className="uk-width-auto@m">
                                <span
                                  class="uk-button uk-button-primary"
                                  onClick={this.documentUploadOnClick}
                                >
                                  {loading ? (
                                    <span
                                      data-uk-spinner=""
                                      style={{ color: "#o6a7e3" }}
                                    />
                                  ) : (
                                      "Upload Image"
                                    )}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* File Component */}
                      </div>

                      <br />
                      {this.state.isUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                            Just a moment…
                            <br/> We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    <div className="uk-form-label"> Album Helper Text </div>
                    <textarea
                      required
                      value={this.state.galleryHelperText || ""}
                      className="uk-textarea"
                      rows="4"
                      id="galleryHelperText"
                      name="galleryHelperText"
                      onChange={this.handleChange}
                    ></textarea>
                    <div className="uk-form-label"> Album  Description </div>
                    <textarea
                      value={this.state.galleryDescription || ""}
                      className="uk-textarea"
                      rows="4"
                      id="galleryDescription"
                      name="galleryDescription"
                      onChange={this.handleChange}
                    ></textarea>

                    <div className="uk-form-label"> Event Date </div>
                    <input
                      value={this.state.eventDate || ""}
                      className="uk-textarea"
                      rows="2"
                      id="eventDate"
                      name="eventDate"
                      type="date"
                      onChange={this.handleChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* {this._createGalleryImageUI()} */}

            <div className="uk-form-label">
              {" "}
              <h3> Images Upload </h3>
            </div>
            <div>
              <div className="">
                {/* 
                        {this.state.image !== null &&
                        this.state.image !== undefined &&
                        this.state.image !== "" ? (
                          <div className="file-upload-group uk-card uk-card-default uk-card-body">
                            <div className="uk-text-center uk-grid ">
                              <div className="uk-width-auto@m">
                                <span
                                  circular
                                  icon="download"
                                  class="uk-button uk-button-primary uk-button-small"
                                  onClick={() => {
                                    window.open(this.state.image);
                                  }}
                                >
                                  Download
                                </span>
                              </div>
                              <div className="uk-width-auto@m">
                                <span
                                  circular
                                  icon="trash alternate outline"
                                  class="uk-button uk-button-secondary uk-button-small"
                                  onClick={() => {
                                    this._deleteFile(this.state.image).then(
                                      result => {
                                        console.log(result);
                                        this.setState({
                                          image: null,
                                          fileRef: null
                                        });
                                      }
                                    );
                                  }}
                                >
                                  Delete
                                </span>
                              </div>
                            </div>
                            <br />
                           <div>
                              {this.state.uploadedImage.length !== 0
                                  ? this.state.uploadedImage.map(url => (
                                <div>
                                  <Images
                                    style={{
                                      position: "relative",
                                      float: "left"
                                    }}
                                    key={url.url}
                                    image={url.url}
                                  />
                          </div>
                    ))
                : ""}
                           </div>
                          </div>
                        ) : ( */}

                <div className="uk-text-center uk-grid">
                  <div className="uk-width-auto@m">
                    <Input
                      onChange={this.fileSelectedHandler}
                      name="upload"
                      id="upload"
                      className="uk-input uk-form-width-huge"
                      placeholder="Upload here"
                      type="file"
                      multiple
                    />
                  </div>
                  <div className="uk-width-auto@m">
                    <Button
                      className="uk-button uk-button-primary uk-margin"
                      style={{ height: 40 }}
                      type="button"
                      onClick={() => this._uploadSelectedImages(this.event)}
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                      ) : (
                          "Upload Images"
                        )}
                    </Button>
                  </div>
                </div>
                <div className="file-upload-group uk-card uk-card-body">
                  {this.state.uploadedImage.length !== 0
                    ? this.state.uploadedImage.map((url, index) => (
                      <div>
                        <br />
                        <Images
                          style={{
                            position: "relative",
                            float: "left",
                          }}
                          key={url.url}
                          image={url.url}
                        // removeImage={this.removeImage}
                        />
                        <br />
                        <div className="uk-text-center uk-grid ">
                          <div className="uk-width-auto@m">
                            <span
                              circular
                              icon="download"
                              class="uk-button uk-button-primary uk-button-small"
                              onClick={() => {
                                window.open(url.url);
                              }}
                            >
                              Download
                              </span>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              circular
                              icon="trash alternate outline"
                              class="uk-button uk-button-secondary uk-button-small"
                              onClick={() => this.openModal(index)}
                            //   onClick={() => {
                            //     this._deleteImages(index).then((result) => {
                            //       // console.log(result);
                            //       this.setState({
                            //         url: null,

                            //       });
                            //     });
                            //   }
                            // }
                            >
                              Delete
                              </span>
                          </div>
                        </div>
                        <br />
                      </div>
                    ))
                    : ""}
                </div>
              </div>

              {this.state.isImageUploading ? (
                <div className="uk-text-center uk-grid">
                  <div className="uk-width-expand@m">
                    <h5 className="uk-align-left">
                      Just a moment…
                      <br/>
                      We are uploading your content.
                    </h5>
                  </div>
                  <div className="uk-width-auto@m">
                    <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                  </div>
                </div>
              ) : (
                  ""
                )}
            </div>
            <hr />
            {/* Upload videos start */}

            <h3 style={{marginBottom: '0rem', width: 'fit-content'}}> Video Upload </h3>
             <text style={{color:"#b54141", fontSize: "small", marginBottom:"1rem", marginTop:"", paddingLeft:"4px"}}> (Recommend you to upload 'MP4 videos' for a smoother user experience)</text>
             <br />
             <br />
            <form id="fileinfo" name="fileinfo" onSubmit={(e) => this.videoUploadOnClick(e)}>
              <div className="uk-grid">
                <div className="uk-width-auto@m">
                  <Input className="uk-input uk-form-width-huge" type="file" name="file" multiple onChange={this.videoUploadOnChange} />
                </div>
                <div className="uk-width-auto@m">
                  <Button className="uk-button-primary" type="submit" disabled={this.state.isVideoUploading === true} >Upload Videos</Button>
                </div>
                <br />
                {
                  this.state.videoError != "" ?
                    <div style={{ color: 'red', width: '100%', fontSize: '0.8rem' }}>
                      <text >{this.state.videoError}</text>
                    </div>
                    : null
                }
              </div>
            </form>
            <br />
            {this.state.isVideoUploading ? (
              <div className="uk-text-center uk-grid">
                <div className="uk-width-expand@m">
                  <h5 className="uk-align-left">
                  {/* Just one second , We are uploading your video/s. */}

                  Just a moment…
                  <br/>
                  We are uploading your content.
                  </h5>
                </div>
                <div className="uk-width-auto@m">
                  <span
                    data-uk-spinner=""
                    style={{ color: "#o6a7e3" }}
                  />
                </div>
              </div>
            ) : (
                ""
              )}
            {
              this.state.videos.length > 0 ?
                this.state.videos.map((x, index) => (
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '20rem', height: '10rem', padding: '0rem 0rem', backgroundColor: '' }}>
                          <div className="video-cover">
                            <video key={'https://kmsvideo.skotie.com/' + x.url} className="video" height="200" style={{ height: '10rem' }} controls>
                              <source src={'https://kmsvideo.skotie.com/' + x.url} />
                            </video>
                          </div>

                        </td>
                        <td style={{ verticalAlign: 'top' }}>
                          <textarea
                            value={x.description}
                            className="uk-textarea"
                            rows="3"
                            id="description"
                            name="description"
                            placeholder="Description"
                            style={{ fontSize: '0.8rem', marginBottom: '1rem', marginLeft: '1rem' }}
                            onChange={(e) => this.handleChangeVideoCaption(e, index)}></textarea>
                          <div className="fitCenterDiv">
                            <Button className="uk-button-secondary" style={{ margin: '0 auto', fontSize: '0.8rem' }} disabled={this.state.isVideoUploading === true} onClick={() => this.openVideoModal(index)}>Delete Video</Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))
                : null
            }
            {/* Upload videos end */}
          </form>

          {/* Video Delete Modal Start*/}

          {blurrModalOpen === true ? (
            <div style={styles.background}>
              <div style={styles.background1}>
                <h4>Are you sure you want to delete this video?</h4>
                <hr />
                {/* <br /> */}
                {/* <br /> */}
                <Button
                  className="uk-button modaleConfirmButton"
                  type="button"
                  style={styles.confirmButton}
                  //   disabled={rating === ""}
                  onClick={() =>
                    this.removeVideo(this.state.deleteVideoIndex)
                  }
                >
                  Yes
                                  </Button>
                <Button
                  className="uk-button uk-button-primary modaleCancelButton"
                  type="button"
                  style={styles.cancelButton}
                  // disabled={rating === ""}
                  onClick={() => this.closeVideoModal()}
                >
                  Cancel
                                  </Button>
              </div>
            </div>
          ) : null}

          {/* Video Delete Modal end*/}

          {/* Delete Gallery Image start */}
          {this.state.modalOpen == true ? (
            <div className="deleteModalDiv">
              <form>
                <div className="deleteModal">
                  <div>
                    <h4> Are you sure you want to delete this image? </h4>
                  </div>
                  <hr />
                  <div className="deleteUser_buttons">
                    <Button
                      className="uk-button uk-button-primary cancelDeleteUserButton"
                      type="button"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="uk-button uk-button-primary sumbitDeleteUserButton"
                      type="button"
                      onClick={() => this.removeGalleryImage(this.state.deleteID)}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          ) : (null)}

          {/* ////// */}
          {this.state.buttonVisible == true ? (
            <Button
              className="uk-button uk-button-primary uk-margin"
              style={{ height: 40 }}
              type="button"
              disabled={loading ? true : false}
              onClick={() => this._handleSubmit(this.event)}
            >
              {loading ? (
                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
              ) : (
                  "Save"
                )}
            </Button>
          ) : null}

          {formErrorStatus.status ? (
            <div className="danger_alert">{formErrorStatus.message}</div>
          ) : formSuccessState.status ? (
            <div className="success_alert">{formSuccessState.message} </div>
          ) : (
                ""
              )}
        </div>


        

{
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => {
              this._deleteFile(this.state.image).then(
                (result) => {
                  console.log(result);
                  this.setState({
                    image: null,
                    fileRef: null,
                    confirmationModal: false,
                  });
                }
              );
            }} closeModal={() => this.setState({confirmationModal: false})} />
            : null
        }


      </div>
    );
  }
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(AddGallery))
);

const styles = {
  background: {
    backgroundColor: "#0000005e",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  background1: {
    backgroundColor: "white",
    position: "absolute",
    // top: '0',
    right: "0",
    width: "40%",
    padding: "1rem",
    zindex: "100",
    marginRight: "28%",
    marginTop: "15%",
    borderRadius: "5px",
    textAlign: "center",
  },

  cancelButton: {
    width: "7rem",
    float: "right",
    marginRight: "0.8rem",
  },

  confirmButton: {
    width: "7rem",
    float: "right",
  },
};

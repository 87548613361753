import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './styles/userRatingStyles.css';

//routes
import { createBrowserHistory } from "history";
import { Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import indexRoutes from "./routes/indexRoutes.js";
import { ippfauthtoken, USER_ID, EMAIL, FIRST_NAME, LAST_NAME, USER_TYPE } from './constants/genarics';
import { Item } from 'semantic-ui-react';
import { withNamespaces } from 'react-i18next';
import i18n from './i18n';

// production/ippf/
const hist = createBrowserHistory({basename: '/'});
// const hist = createBrowserHistory({basename: 'production/ippf-stag/'});


hist.listen(_ => {
  window.scrollTo(0, 0)  
})

class App extends Component {

  constructor(props){
    super(props);
    // this.state = { logginStatus: true };
    // this.events = [
    //   "load",
    //   "mousemove",
    //   "mousedown",
    //   "click",
    //   "scroll",
    //   "keypress"
    // ];

    // this.logout = this.logout.bind(this);
    // this.resetTimeout = this.resetTimeout.bind(this);

    // for (var i in this.events) {
    //   window.addEventListener(this.events[i], this.resetTimeout);
    // }

    // this.setTimeout();
    
    // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    // (function () {
    //   var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    //   s1.async = true;
    //   s1.src = 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
    //   s1.charset = 'UTF-8';
    //   s1.setAttribute('crossorigin', '*');
    //   s0.parentNode.insertBefore(s1, s0);
    // })();

  }


  // clearTimeout() {
  //   if (this.warnTimeout) clearTimeout(this.warnTimeout);

  //   if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  // }

  // setTimeout() {
  //   // this.warnTimeout = setTimeout(this.warn, 16 * 1000);

  //   this.logoutTimeout = setTimeout(this.logout, 60* 60 * 1000);
  // }

  // resetTimeout() {
  //   this.clearTimeout();
  //   this.setTimeout();
  // }

  // logout() {
  //   this.setState({ logginStatus: false });
  //   setTimeout(() => {
  //     localStorage.removeItem(ippfauthtoken);
  //     localStorage.removeItem(USER_ID);
  //     localStorage.removeItem(EMAIL);
  //     localStorage.removeItem(FIRST_NAME);
  //     localStorage.removeItem(LAST_NAME);
  //     localStorage.removeItem(USER_TYPE);
  //     this.props.history.push("/login");
  // }, 500)
  // }

  // destroy() {
  //   this.clearTimeout();

  //   for (var i in this.events) {
  //     window.removeEventListener(this.events[i], this.resetTimeout);
  //   }
  // }

  render() {
    const { t, i18n } = this.props;
    document.body.dir = i18n.dir();
    // console.log(document.body)
    // document.body.className = ( document.body.dir == "ltr" ? ("other_language"):("arabic"))
    let routes = (
      <Switch>
        {indexRoutes.map((prop, key) => {
          return (
            <Route exact path={prop.path} component={prop.component} key={key}  />
          );
        })}
        <Redirect to="/" from="/login" />
        <Redirect to="/sbls" from="/exams" />
        <Redirect to="/sbl" from="/exam" />
        <Redirect to="/" />
        {/* <Redirect to="/login" /> */}
      </Switch>
    );

    return (
      <Router history={hist}>
        {routes}
      </Router>
      
    );

  }
}

export default withRouter(withNamespaces()(App));

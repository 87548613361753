import * as React from 'react';
import { formatDate } from '../../../middleware/index';
import { withNamespaces } from 'react-i18next';
import moment from "moment";

class Card_resource extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { resource, t } = this.props;
        console.log(localStorage.LANG)
        return (
            <div>
                {resource.url ? (
                    <div onClick={() => this.props.open_resource()} className="hoverEffect uk-card uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
                        <a target="_blank" className=" uk-link-reset" >
                            <img src={resource.resources.url} className="course-img global-img-size" />
                            <div className="uk-card-body">
                                <h4>{resource.resourceTitle}</h4>
                            </div>
                            <div className="uk-grid uk-card-footer">
                                {/* {
                                    localStorage.LANG == "ar" ? (
                                        <div className="uk-width-2-2@m">
                                            {t('uploaded_date')}  {
                                                moment(resource.createdAt, "YYYY-MM-DD").format(
                                                    "YYYY-MM-DD"
                                                )}
                                        </div>
                                    ) : ( */}
                                        <div className="uk-width-2-2@m">
                                            {t('uploaded_date')}  {formatDate(resource.createdAt)}
                                        </div>
                                    {/* )


                                } */}

                            </div>
                        </a>
                    </div>
                ) : (
                    <div onClick={() => this.props.get_resource()} className="hoverEffect uk-card uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
                        <a className=" uk-link-reset" >
                            <img src={resource.resources.url} className="course-img global-img-size" />
                            <div className="uk-card-body">
                                <h4>{resource.resourceTitle}</h4>
                            </div>
                            <div className="uk-grid uk-card-footer">
                                {
                                    // localStorage.LANG == "ar" ? (
                                    //     <div className="uk-width-2-2@m">
                                    //         {t('uploaded_date')}  {
                                    //             moment(resource.createdAt, "YYYY-MM-DD").format(
                                    //                 "YYYY-MM-DD"
                                    //             )}
                                    //     </div>
                                    // ) : (
                                        <div className="uk-width-2-2@m">
                                            {t('uploaded_date')}  {formatDate(resource.createdAt)}
                                        </div>
                                    // )
                                }
                            </div>
                        </a>
                    </div>
                )}
            </div>
        )
    }
}

export function Card_loading_resource() {
    return <div >
        <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabIndex="0">
            {/* <a href="" className="uk-link-reset" > */}
            <div className="uk-card-body">
                <img src={require("../../../images/default.jpg")} className="course-img" />
                <div className="loading_div"><h4 className="loading_text"></h4></div>
                <hr className="uk-margin-remove-top" />
                <div className="uk-clearfix">
                    <h4></h4>
                </div>
            </div>
            {/* </a> */}
        </div>
    </div>;
}

export default withNamespaces()(Card_resource);
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus, setModalStatus } from '../../../actions/user-actions'
import { Input } from "semantic-ui-react";
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

import { Button } from 'react-uikit';
import Images from "../Image/Images";
import moment from "moment";
import { formatDate } from "../../../middleware/index"
import { GET_GALLERY } from '../../../queries/common_queries'
import { CREATE_GALLERY, CREATE_GALLERY_IMAGE, UPDATE_GALLERY, UPDATE_GALLERY_IMAGE, DELETE_GALLERY_IMAGE } from '../../../queries/user_queries'
import result from '../../user/result/result';

import Delete_gallery_image from '../../../components/delete_gallery_images/delete_gallery_images'
import ConfirmationModal from '../../../components/adminModals/confirmationModal';


const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    ratingModalOpen: state.custom_reducer.ratingModalOpen,
    translatingLanguages: state.custom_reducer.translatingLanguages,
    languageKeys: state.custom_reducer.languageKeys,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    },
    setModalStatus: (status) => {
      dispatch(setModalStatus(status))
    }
  }
}

class EditGallery extends React.Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id');
    this.state = {
      gallery: [],
      galleryImages: [],
      image: "",
      isUploading: false,
      isImageUploading: false,
      fileRef: "",
      galleryBannerImage: '',
      selectedImages: [],
      uploadedImage: [],
      id: id,
      galleryId: id,
      pagination: {
        galleryId: id,
        isActive: true,
        lang: "en"
      },
      lang: "en",
      loading: true,
      buttonVisible: false,
      formData: "",
      formLanguage: "en",

      videos: [],
      videoFiles: [],
      videoError: "",
      isVideoUploading: false,
      open: false,
      blurrModalOpen: false,

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    props.setFormStatus({ status: false, title: '', message: '' });
    props.setSuccessStatus({ status: false, title: '', message: '' });
  }

  handleChange = (event) => {
    console.log(event.target.value)
    this.setState({ [event.target.id]: event.target.value });
  }

  // deleteImage = (id, imageIndex) =>{
  //   console.log(id)
  //   console.log(imageIndex)
  //   if(id==undefined){
  //     let galleryImages = [...this.state.galleryImages]
  //     galleryImages.splice(imageIndex, 1);
  //     this.setState({galleryImages: galleryImages})
  //   } else{
  //     this.deleteGalleryImage(id)
  //   }
  // }

  openModal = (status, id, value) => {
    // if (id != undefined) {
      const galleryImageID = id;
      var status = false
      if (this.props.ratingModalOpen === true) {
        status = false;
      }
      else {
        status = true;
      }
      this.props.setModalStatus(status);
      this.setState({ galleryImageID })
    // }
    // else {
    //   const galleryImageIndex = value;
    //   var status = false
    //   if (this.props.ratingModalOpen === true) {
    //     status = false;
    //   }
    //   else {
    //     status = true;
    //   }
    //   this.props.setModalStatus(status);
    //   this.setState({ galleryImageIndex })
    // }

  }

  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({ open: false, });
  };

  componentDidMount() {
    this.geSingleGallery();
    // this.deleteGalleryImage(this.state.galleryImageID);
  }

  geSingleGallery() {
    this.getGallery(this.state.pagination).then(result => {

      const videoList = result.gallery.videos ? result.gallery.videos.videos ? result.gallery.videos.videos : [] : [];

      console.log(result);
      this.setState({
        galleryId: result.gallery.id,
        galleryTitle: result.gallery.galleryTitle,
        galleryBannerImage: result.gallery.galleryBannerImage,
        galleryHelperText: result.gallery.galleryHelperText,
        galleryDescription: result.gallery.galleryDescription,
        eventDate: result.gallery.eventDate,
        image: result.gallery.galleryBannerImage,
        gallery: result.gallery,
        galleryImages: result.galleryImages.galleryImages,
        loading: false,
        videos: videoList
      })
    })
  }


  createGalleryImage = async data => {
    const result = await this.props.client.mutate({
      mutation: CREATE_GALLERY_IMAGE,
      variables: data
    });
    return result.data.createGalleryImage;
  };

  createGallery = async () => {
    const {
      galleryTitle,
      galleryBannerImage,
      galleryHelperText,
      galleryDescription,
      data,
      isActive,
      eventDate,
      id
    } = this.state;
    const content = data;

    const result = await this.props.client.mutate({
      mutation: CREATE_GALLERY,
      variables: {
        galleryTitle,
        galleryBannerImage,
        galleryHelperText,
        galleryDescription,
        content,
        isActive,
        eventDate,
        id
      }
    });
    return result.data.createGallery;
  };

  getGallery = async (pagination) => {
    const result = await this.props.client.query({
      query: GET_GALLERY,
      variables: pagination,
      fetchPolicy: 'network-only'
    });
    console.log(result.data.getGallery);
    return result.data.getGallery;
  }

  updateGallery = async () => {
    const { galleryId, galleryTitle, galleryBannerImage, galleryHelperText, galleryDescription, isActive, eventDate, formLanguage, videos, languageEnabled } = this.state;
    const galleryContent = "";
    const lang = formLanguage;
    var videoList = { videos: videos }

    const result = await this.props.client.mutate({
      mutation: UPDATE_GALLERY,
      variables: { galleryId, galleryTitle, galleryBannerImage, galleryHelperText, galleryContent, galleryDescription, isActive, eventDate, lang, videos: videoList, languageEnabled },
    });
    return result.data.updateGallery;
  }

  updateGalleryImage = async data => {
    const { galleryImageId, imageTitle, image, imageHelperText, imageDescription, isActive, fileName, formLanguage } = this.state;
    const result = await this.props.client.mutate({
      mutation: UPDATE_GALLERY_IMAGE,
      variables: data
    });
    return result.data.updateGalleryImage;
  }

  deleteGalleryImage = async (galleryImageID) => {
    console.log(galleryImageID)
    window.alert("Image Gallery id " + '"' + galleryImageID + '"' + " will be deleted");
    const result = await this.props.client.mutate({
      mutation: DELETE_GALLERY_IMAGE,
      variables: { galleryImageID }
    });
    this.setState({ galleryImages: this.state.galleryImages, });
    // window.location.reload();
    return result.data.deleteGalleryImage;
  }

  _deleteImages = async path => {
    console.log(path);
    const deleteRef = await deleteFile(this.state.uploadedImage[path].image);
    console.log(this.state.uploadedImage)
    console.log(this.state.uploadedImage[path].image)
    this.state.uploadedImage.splice(path, 1);
    this.setState({
      uploadedImage: this.state.uploadedImage,
    });
    return deleteRef;
  };

  handleSubmit = (e) => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.setState({ loading: true });
    const { galleryTitle, galleryImages, uploadedImage, galleryId, galleryBannerImage, eventDate } = this.state;
    console.log(uploadedImage)
    if (galleryTitle === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a title for the album."
      });
    } else if  (galleryBannerImage === "" || galleryBannerImage === null || galleryBannerImage === undefined) {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a cover image for this album.",
      });
    } else if (eventDate === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide an event date for this album.",
      });
    }

    else {
      uploadedImage.map((gallery) => {
        console.log(gallery)
        const data = {
          galleryID: galleryId,
          image: gallery.image,
          fileName: gallery.fileName
        }
        this.createGalleryImage(data).then(res => {
          console.log(res);
          // this.setState({ 
            
          //   image:"",
          //   fileName:""
          //  })
          this.geSingleGallery();
        })
      })
      this.updateGallery().then(resul => {
        console.log(resul)
        galleryImages.map((gall) => {
          console.log(gall);
          const data = {
            galleryID: resul.galleryID,
            galleryImageId: gall.id,
            image: gall.image,
            fileName: gall.fileName,
            lang: this.state.formLanguage,
          }
          this.updateGalleryImage(data).then(resu => {
            console.log(resu);
            this.setState({
              // data:"",
              galleryID:"",
              galleryImageId:"",
              fileName:null,
              image:null,
            })

          })
        })
        this.geSingleGallery();
        this.props.setSuccessStatus({
          status: true,
          title: "Oops!",
          message:
            "Content updated successfully."
        });
        window.location.reload();
        this.setState({ hideDialog: true });
      }).catch(error => {
        console.log(error);
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message:
            <div>
              An error occurred while trying to update the content!<br/>
              Please try again.
            </div>
        });
        this.setState({ hideDialog: true });
      });


      // this.updateGallery().then(result => {
      // console.log(result)
      // console.log(galleryImages)

      // galleryImages.map((gallery)=>{
      //   console.log(gallery)
      //     const data = {
      //         galleryID : result.id,
      //         galleryImageId: gallery.id,
      //         image: gallery.image,
      //         fileName: gallery.fileName
      //       }

      //       // this.updateGalleryImage(data).then(res=>{
      //       //   console.log(res)
      //       //   this.geSingleGallery();
      //       // })
      // })
      //   this.geSingleGallery();


      // this.props.setSuccessStatus({
      //     status: true,
      //     title: "Oops!",
      //     message:
      //         "Content updated successfully!"
      // });

      // this.setState({ hideDialog: true });

      // }).catch(error => {

      //     console.log(error);
      //     this.props.setFormStatus({
      //         status: true,
      //         title: "Oops!",
      //         message:
      //             "An error occurred while trying to update the content. Please try again!"
      //     });

      //     this.setState({ hideDialog: true });
      // });

    }
  };

  documentUploadOnClick = event => {
    this.setState({
      isUploading: true
    });
    console.log(this.state.formData);
    this._startDocUpload(this.state.formData);
  };

  documentUploadOnChange = event => {
    this.setState({
      formData: event.target.files[0]
    });
  };

  _startDocUpload = async file => {
    var intDate = Number(new Date());
    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    console.log(result);
    if (result.status) {
      this.setState({
        isUploading: false,
        fileRef:
          "uploads/" + localStorage.FIRST_NAME + intDate + "/" + file.name,
        image: result.url,
        galleryBannerImage: result.url
      });
    }
  };

  _deleteFile = async path => {
    console.log(path);
    const deleteRef = await deleteFile(path);
    this.setState({ image: "", galleryBannerImage: "" });
    return deleteRef;
  };

  fileSelectedHandler = e => {
    console.log(this.state.galleryImages)
    console.log(e.target.files);
    this.setState({
      selectedImages: e.target.files
    });
  };

  _uploadSelectedImages = () => {
    console.log(this.state.selectedImages);
    if (this.state.selectedImages.length !== 0) {
      Array.from(this.state.selectedImages).forEach(file => {
        console.log(file);
        this.setState({
          isImageUploading: true,
        });
        this._startSelectedFilesUpload(file).then(result => {
          this.setState({ buttonVisible: true })
        });
      });
    }
  };

  _startSelectedFilesUpload = async file => {
    var intDate = Number(new Date());
    console.log(file)
    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    console.log(result);

    if (result.status) {
      const galleryImg = {
        status: result.status,
        image: result.url,
        fileName: file.name
      }
      this.setState({
        isImageUploading: false,
        uploadedImage: [...this.state.uploadedImage, galleryImg],
        galleryImages: [...this.state.galleryImages, galleryImg],
      });
    }
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push('/admin_home');
    } else if (path == "MANAGEGALLERY") {
      this.props.history.push('/admin_gallery_list');
    } else {
      return false;
    }
  }

  changeFormLanguage = (language) => {
    const id = this.state.moduleId;
    var tempParameter = this.state.pagination;
    tempParameter.lang = language;
    // this.setState({ getModuleParameter: { moduleId: id, lang: language } });
    this.getGallery(tempParameter).then(result => {
      const videoList = result.gallery.videos ? result.gallery.videos.videos ? result.gallery.videos.videos : [] : [];
      console.log(result);
      this.setState({
        galleryId: result.gallery.id,
        galleryTitle: result.gallery.galleryTitle,
        galleryBannerImage: result.gallery.galleryBannerImage,
        galleryHelperText: result.gallery.galleryHelperText,
        galleryDescription: result.gallery.galleryDescription,
        eventDate: result.gallery.eventDate,
        image: result.gallery.galleryBannerImage,
        gallery: result.gallery,
        galleryImages: result.galleryImages.galleryImages,
        loading: false,
        videos: videoList,
        formLanguage: language,
        languageEnabled: result.gallery.languageEnabled != undefined ? result.gallery.languageEnabled === false ? false : true : true
      })
    })
  };





  videoUploadOnChange = (e) => {
    console.log(e.target.files);
    console.log(e.target.files[0].type);
    var files = [];
    var type = [];
    var splitArray = [];
    files = e.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        type = files[i].type;
        if (type != '') {
          splitArray = type.split('/');
          if (splitArray[0] != 'video') {
            this.setState({
              videoError: '* Unsupported file format',
            });
          }
          else {
            this.setState({
              videoFiles: e.target.files,
              videoError: '',
            });
          }
        }

      }
    }


  }

  handleChangeVideoCaption = (e, index) => {
    console.log(e.target.value)
    const tempArray = this.state.videos;
    tempArray[index].description = e.target.value;
    this.setState({
      videos: tempArray,
    })
  }

  removeVideo = (index) => {
    console.log("index: ", index)
    const tempArray = this.state.videos;
    console.log(this.state.videos)
    tempArray.splice(index, 1);
    this.setState({
      videos: tempArray,
      blurrModalOpen: false
    })
  }

  videoUploadOnClick = async (e) => {
    this.setState({ isVideoUploading: true })
    var videoList = this.state.videos;
    var fileinfo = document.forms.namedItem("fileinfo");
    console.log(fileinfo);
    e.preventDefault();
    fetch('https://kmsvideo.skotie.com/upload', {
      method: 'POST',
      body: new FormData(fileinfo)
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.locations) {
          if (result.locations.length > 0) {
            result.locations.map(video => (
              videoList.push({ url: video.location, description: "" })
            ))
          }
        }
        this.setState({
          isVideoUploading: false,
          videos: videoList
        })
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isVideoUploading: false
        })
      });
  }


  openVideoModal = (index) => {
    this.setState({
      blurrModalOpen: true,
      deleteVideoIndex: index,
    })


  }

  closeVideoModal = () => {
    this.setState({
      blurrModalOpen: false,
    })
  }

  handleCheckBoxChange = (status) => {
    console.log(status)
    this.setState({ languageEnabled: status });
  }



  render() {
    const { loading, image, galleryImages, gallery, uploadedImage, galleryImgs, galleryId, galleryImageId, formLanguage, open, galleryImageID,galleryImageIndex, blurrModalOpen } = this.state;
    const { formErrorStatus, formSuccessState, translatingLanguages } = this.props;
    console.log(this.state.galleryImageID)
    console.log(galleryId);
    console.log(galleryImages)
    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li ><a onClick={() => this.breadcrumbredirect("HOME")}>Home</a></li>
            <li ><a onClick={() => this.breadcrumbredirect("MANAGEGALLERY")}>Manage Gallery</a></li>
            <li ><a onClick={() => this.breadcrumbredirect("EDITGALLERY")}>Edit Album</a></li>
          </ul>
        </div>
        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-image icon-large uk-margin-right"></i>
          <h4 className="uk-margin-remove"> Edit Album </h4>
        </div>
        {this.props.ratingModalOpen === true ? <Delete_gallery_image galleryImages={galleryImages} image={image} galleryImageID={galleryImageID} galleryImageIndex={galleryImageIndex}/> : null}

        <div className="uk-margin-medium">
          <form
            className="ui form"
          >
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="fas fa-image uk-margin-small-right"></i>{" "}
                    Album Details
                    {
                      translatingLanguages.length > 0 ?
                      <div className="formLanguageSwitcherDiv">
                        {
                        translatingLanguages.map(languageTab => (
                          <span circular icon="download" className={ formLanguage === languageTab.key ? "formLanguageSwitcherActive"  : "formLanguageSwitcher" } onClick={() => this.changeFormLanguage(languageTab.key)}>
                          {languageTab.language}
                          </span>
                        ))
                        }
                      </div>
                        : null
                    }
                      {/* <div className="formLanguageSwitcherDiv">
                      <span
                        circular
                        icon="download"
                        className={
                          formLanguage === "en"
                            ? "formLanguageSwitcherActive"
                            : "formLanguageSwitcher"
                        }
                        onClick={() => this.changeFormLanguage("en")}
                      >
                        English
                        </span>
                      <span
                        circular
                        icon="download"
                        className={
                          formLanguage === "fr"
                            ? "formLanguageSwitcherActive"
                            : "formLanguageSwitcher"
                        }
                        onClick={() => this.changeFormLanguage("fr")}
                      >
                        French
                        </span>
                      <span
                        circular
                        icon="download"
                        className={
                          formLanguage === "ar"
                            ? "formLanguageSwitcherActive"
                            : "formLanguageSwitcher"
                        }
                        onClick={() => this.changeFormLanguage("ar")}
                      >
                        Arabic
                        </span>
                    </div> */}
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">

                  {
                        this.state.formLanguage != "en" ?
                            <div className="disableLanguageVersionDiv">
                                <input type="checkbox" id="languageEnabled" name="languageEnabled" checked={this.state.languageEnabled} onChange={() => this.handleCheckBoxChange(!this.state.languageEnabled)} />
                                <label>{this.props.languageKeys[this.state.formLanguage]} version enabled</label>
                            </div>
                        : null
                    }

                    <div className="uk-form-label"> Album Title </div>
                    <input
                      // required
                      value={this.state.galleryTitle || ''}
                      className="uk-input uk-form-width-huge"
                      id="galleryTitle"
                      name="galleryTitle"
                      placeholder="Title"
                      type="text"
                      onChange={this.handleChange}
                    />

                    
                    

                    <div className="uk-form-label">Upload Image </div>
                    <div>
                      <div className="">
                        {/* File Component */}
                        {this.state.image !== null &&
                          this.state.image !== undefined &&
                          this.state.image !== "" ? (
                            <div className="file-upload-group uk-card uk-card-default uk-card-body">
                              <div className="uk-text-center uk-grid ">
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="download"
                                    class="uk-button uk-button-primary uk-button-small"
                                    onClick={() => {
                                      window.open(this.state.image);
                                    }}
                                  >
                                    Download
                                </span>
                                </div>
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="trash alternate outline"
                                    class="uk-button uk-button-secondary uk-button-small"
                                    onClick={() => 
                                    //   {
                                    //   this._deleteFile(this.state.image).then(
                                    //     result => {
                                    //       console.log(result);
                                    //       this.setState({
                                    //         image: null,
                                    //         fileRef: null
                                    //       });
                                    //     }
                                    //   );
                                    // }
                                    this.setState({confirmationModal: true})
                                  }
                                  >
                                    Delete
                                </span>
                                </div>
                              </div>
                              <br />
                              <Images
                                image={image}
                              />
                            </div>
                          ) : (
                            <div className="uk-text-center uk-grid">
                              <div className="uk-width-auto@m">
                                <Input
                                  onChange={this.documentUploadOnChange}
                                  name="upload"
                                  id="upload"
                                  className="uk-input uk-form-width-huge"
                                  placeholder="Upload here"
                                  type="file"
                                  multiple
                                />
                              </div>
                              <div className="uk-width-auto@m">
                                <span
                                  class="uk-button uk-button-default"
                                  onClick={this.documentUploadOnClick}
                                >
                                  {loading ? (
                                    <span
                                      data-uk-spinner=""
                                      style={{ color: "#o6a7e3" }}
                                    />
                                  ) : (
                                      "Click here to Upload"
                                    )}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* File Component */}
                      </div>
                      <br />
                      {this.state.isUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                            Just a moment… <br/>
                            We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    <div className="uk-form-label"> Album Helper Text </div>
                    <textarea
                      required
                      value={this.state.galleryHelperText || ""}
                      className="uk-textarea"
                      rows="4"
                      id="galleryHelperText"
                      name="galleryHelperText"
                      onChange={this.handleChange}
                    ></textarea>
                    <div className="uk-form-label"> Album Description </div>
                    <textarea
                      value={this.state.galleryDescription || ""}
                      className="uk-textarea"
                      rows="4"
                      id="galleryDescription"
                      name="galleryDescription"
                      onChange={this.handleChange}
                    ></textarea>

                    <div className="uk-form-label"> Event Date </div>
                    <input
                      // value={moment(this.state.eventDate).format('DD/MM/YYYY') || ""}
                      value={this.state.eventDate || ""}

                      className="uk-textarea"
                      rows="2"
                      id="eventDate"
                      name="eventDate"
                      type="date"
                      onChange={this.handleChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="uk-form-label"> <h3> Images Upload </h3></div>
            <div>
              <div className="">
                <div className="uk-text-center uk-grid">
                  <div className="uk-width-auto@m">
                    <Input
                      onChange={this.fileSelectedHandler}
                      name="upload"
                      id="upload"
                      className="uk-input uk-form-width-huge"
                      placeholder="Upload here"
                      type="file"
                      multiple
                    />
                  </div>
                  <div className="uk-width-auto@m">
                    <Button
                      className="uk-button uk-button-primary uk-margin"
                      style={{ height: 40 }}
                      type="button"
                      onClick={() => this._uploadSelectedImages(this.event)}
                    >
                      {this.state.isImageUploading ? (
                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                      ) : (
                          "Upload Images"
                        )}
                    </Button>
                  </div>
                </div>
                <div className="file-upload-group uk-card uk-card-body">
                  {this.state.galleryImages.length !== 0
                    ? this.state.galleryImages.map((url, index) => (
                      <div>
                        <br />
                        <Images
                          style={{
                            position: "relative",
                            float: "left"
                          }}
                          key={url.image}
                          image={url.image}
                        />
                        <br />
                        <div className="uk-text-center uk-grid ">
                          <div className="uk-width-auto@m">
                            <span
                              circular
                              icon="download"
                              class="uk-button uk-button-primary uk-button-small"
                              onClick={() => {
                                window.open(url.image);
                              }}
                            >
                              Download
                            </span>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              circular
                              icon="trash alternate outline"
                              class="uk-button uk-button-secondary uk-button-small"
                              // onClick={() => this.deleteGalleryImage(url.id).then(
                              //   result => {
                              //     console.log(result)
                              //     this.setState({
                              //       url: null,
                              //       image: null
                              //     })

                              //   }
                              // )}
                              onClick={() => this.openModal(true, url.id, index)}
                              // onClick={()=> this.openModal(url.id, index)}

                            >
                              Delete
                                </span>
                          </div>
                        </div>
                        <br />
                        <Images
                          image={url}
                        />
                      </div>
                    ))
                    : ""}
                </div>
              </div>
              {this.state.isImageUploading ? (
                <div className="uk-text-center uk-grid">
                  <div className="uk-width-expand@m">
                    <h5 className="uk-align-left">
                    Just a moment… <br/>
                    We are uploading your content.
                                    </h5>
                  </div>
                  <div className="uk-width-auto@m">
                    <span
                      data-uk-spinner=""
                      style={{ color: "#o6a7e3" }}
                    />
                  </div>
                </div>
              ) : (
                  ""
                )}
            </div>

          </form>




          {/* Upload videos start */}

          <h3 style={{marginBottom: '0rem', width: 'fit-content'}}> Video Upload </h3>
             <text style={{color:"#b54141", fontSize: "small", marginBottom:"1rem", marginTop:"", paddingLeft:"4px"}}> (Recommend you to upload 'MP4 videos' for a smoother user experience)</text>
             <br />
             <br />
          <form id="fileinfo" name="fileinfo" onSubmit={(e) => this.videoUploadOnClick(e)}>
            <div className="uk-grid">
              <div className="uk-width-auto@m">
                <Input className="uk-input uk-form-width-huge" type="file" name="file" multiple onChange={this.videoUploadOnChange} />
              </div>
              <div className="uk-width-auto@m">
                <Button className="uk-button-primary" type="submit" disabled={this.state.isVideoUploading === true} >Upload Videos</Button>
              </div>
              <br />
              {
                this.state.videoError != "" ?
                  <div style={{ color: 'red', width: '100%', fontSize: '0.8rem' }}>
                    <text >{this.state.videoError}</text>
                  </div>
                  : null
              }
            </div>
          </form>
          <br />
          {this.state.isVideoUploading ? (
            <div className="uk-text-center uk-grid">
              <div className="uk-width-expand@m">
                <h5 className="uk-align-left">
                  {/* Just one second , We are uploading your video/s. */}
                  Just a moment…
                            <br/>
                            We are uploading your content.
                            </h5>
              </div>
              <div className="uk-width-auto@m">
                <span
                  data-uk-spinner=""
                  style={{ color: "#o6a7e3" }}
                />
              </div>
            </div>
          ) : (
              ""
            )}
          {
            this.state.videos.length > 0 ?
              this.state.videos.map((x, index) => (
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '20rem', height: '10rem', padding: '0rem 0rem', backgroundColor: '' }}>
                        <div className="video-cover">
                          <video key={'https://kmsvideo.skotie.com/' + x.url} className="video" height="200" style={{ height: '10rem' }} controls>
                            <source src={'https://kmsvideo.skotie.com/' + x.url} />
                          </video>
                        </div>

                      </td>
                      <td style={{ verticalAlign: 'top' }}>
                        <textarea
                          value={x.description}
                          className="uk-textarea"
                          rows="3"
                          id="description"
                          name="description"
                          placeholder="Description"
                          style={{ fontSize: '0.8rem', marginBottom: '1rem', marginLeft: '1rem' }}
                          onChange={(e) => this.handleChangeVideoCaption(e, index)}></textarea>
                        <div className="fitCenterDiv">
                          <Button className="uk-button-secondary" style={{ margin: '0 auto', fontSize: '0.8rem' }} disabled={this.state.isVideoUploading === true} onClick={() => this.openVideoModal(index)}>Delete Video</Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))
              : null
          }
          {/* Upload videos end */}


          {/* Video Delete Modal Start*/}

          {blurrModalOpen === true ? (
            <div style={styles.background}>
              <div style={styles.background1}>
                <h4>Are you sure you want to delete this video?</h4>
                <hr />
                {/* <br /> */}
                {/* <br /> */}
                <Button
                  className="uk-button modaleConfirmButton"
                  type="button"
                  style={styles.confirmButton}
                  //   disabled={rating === ""}
                  onClick={() =>
                    this.removeVideo(this.state.deleteVideoIndex)
                  }
                >
                  Yes
                                  </Button>
                <Button
                  className="uk-button uk-button-primary modaleCancelButton"
                  type="button"
                  style={styles.cancelButton}
                  // disabled={rating === ""}
                  onClick={() => this.closeVideoModal()}
                >
                  Cancel
                                  </Button>
              </div>
            </div>
          ) : null}

          {/* Video Delete Modal Start*/}

          {/* {this.state.buttonVisible == true ? ( */}
          <Button
            className="uk-button uk-button-primary uk-margin"
            style={{ height: 40 }}
            type="button"
            disabled={loading ? (true) : (false)}
            onClick={() => this.handleSubmit(this.event)}
          >
            {loading ? (
              <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
            ) : (
                "Update"
              )}
          </Button>
          {/* ):(null)} */}

          {formErrorStatus.status ? (
            <div className="danger_alert">{formErrorStatus.message}</div>
          ) : formSuccessState.status ? (
            <div className="success_alert">{formSuccessState.message} </div>
          ) : (
                ""
              )}
        </div>

        

  {
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => {
              this._deleteFile(this.state.image).then(
                result => {
                  console.log(result);
                  this.setState({
                    image: null,
                    fileRef: null,
                    confirmationModal: false
                  });
                }
              );
            }} closeModal={() => this.setState({confirmationModal: false})} />
            : null
        }                          



      </div>
    )
  }

}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(EditGallery)));

const styles = {
  background: {
    backgroundColor: "#0000005e",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  background1: {
    backgroundColor: "white",
    position: "absolute",
    // top: '0',
    right: "0",
    width: "40%",
    padding: "1rem",
    zindex: "100",
    marginRight: "28%",
    marginTop: "15%",
    borderRadius: "5px",
    textAlign: "center",
  },

  cancelButton: {
    width: "7rem",
    float: "right",
    marginRight: "0.8rem",
  },

  confirmButton: {
    width: "7rem",
    float: "right",
  },
};

import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_OTHER_STATS } from "../../queries/admin_queries";
import NoDataMessage from '../messages/contentNotFound/NoDataFoundSmall';
import Circle from "react-circle";

var _ = require('underscore');
const mapStateToProps = (state, ownProps) => {
  return {};
};

class GalleryStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        galleryStatList: [],
        galleries: [],

        galleryUserList: "",
        galleryUserCount: "",
        totalViewsGalleries: 0,

        fontColors: ['#1b702f', '#4d1b82'],

        fromDate: this.props.fromDate,
        toDate: this.props.toDate, 
    };
  }

  
  componentDidMount() {
    this.getOtherStats(this.state.fromDate, this.state.toDate).then((result) => {
      var temp = [];
      var galleryStats = [];
      var userListGal = "";
      var galleryUserCount = 0;
      var totalViewsGalleries = 0;
      var checkedList = [];

      if (result.galleries.length > 0) {
        if (result.galleryStatList.length > 0) {
            result.galleryStatList.slice(0).reverse().map(r => (
                temp = Object.values(result.galleries).filter(obj => obj.id === r.galleryId),
                temp.length > 0 ?
                checkedList.includes(r.galleryId) != true  ?
                  galleryStats.push({galleryTitle: temp[0].galleryTitle, clickCount: r.clickCount, createdBy: r.createdBy})
                  :null
                  : null,
                totalViewsGalleries = totalViewsGalleries + r.clickCount,
                checkedList.push(r.galleryId)
            ))
            userListGal = (_.countBy(galleryStats, function(galleryStats) { return galleryStats.createdBy; }));
            galleryUserCount = Object.keys(userListGal).length  
      }
    }

      this.setState({
        galleryStatList: galleryStats,
        galleryUserList: userListGal,
        totalViewsGalleries: totalViewsGalleries,
        galleryUserCount: galleryUserCount,
        totalUsers: result.totalUsers,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate) {
      this.getOtherStats(this.props.fromDate, this.props.toDate).then((result) => {
        var temp = [];
        var galleryStats = [];
        var userListGal = "";
        var galleryUserCount = 0;
        var totalViewsGalleries = 0;

        if (result.galleries.length > 0) {
          if (result.galleryStatList.length > 0) {
              result.galleryStatList.map(r => (
                  temp = Object.values(result.galleries).filter(obj => obj.id === r.galleryId),
                  temp.length > 0 ?
                    galleryStats.push({galleryTitle: temp[0].galleryTitle, clickCount: r.clickCount, createdBy: r.createdBy})
                  : null,
                  totalViewsGalleries = totalViewsGalleries + r.clickCount
              ))
              userListGal = (_.countBy(galleryStats, function(galleryStats) { return galleryStats.createdBy; }));
              galleryUserCount = Object.keys(userListGal).length  
        }
      }

        this.setState({
          galleryStatList: galleryStats,
          galleryUserList: userListGal,
          totalViewsGalleries: totalViewsGalleries,
          galleryUserCount: galleryUserCount,
        });
      });
    }
  }

  getOtherStats = async (from, to) => {
    const result = await this.props.client.query({
      query: GET_OTHER_STATS,
      variables: {fromDate: from, toDate: to},
      fetchPolicy: "network-only",
    });
    return result.data.getOtherStats;
  };

  render() {
    const {} = this.props;
    const {
        galleryStatList,
        galleries,
        fontColors,
        totalUsers,
        galleryUserList,
        galleryUserCount,
        totalViewsGalleries,

        fromDate,
        toDate,

    } = this.state;
    return (
      <div className="">
        <h4 className="statTitleMain">Gallery</h4>
        <hr />
        <table style={{width: '100%', backgroundColor: '#EEEFC7', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <tbody>
                <tr>
                    <td style={{width: '60%', padding: '0.8rem', verticalAlign:'top'}}>
                        <div style={{width: '100%', backgroundColor: '#EEEFC7', textAlign: 'left'}}>
                            <h5 className="resStatTitle">Individual Gallery Visits</h5>
                            <div>
                                <table style={{width: '100%'}}>
                                    <thead style={{color: '#6e230e', fontSize: '0.9rem'}}>
                                      <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th style={{textAlign: 'right'}}>Visits</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            galleryStatList.length > 0 ?
                                            galleryStatList.map((stat, index) => (
                                              index + 1 <=3 ?
                                              galleryStatList.length > 3 ?
                                                <tr>
                                                    <td style={{width: '2rem', fontSize: '0.9rem', fontWeight: 'bold'}}>{index + 1}.</td>
                                                    <td style={{width: '', textAlign: 'left', fontSize: '0.9rem', fontWeight: 'bold'}}>{stat.galleryTitle}</td>
                                                    <td style={{width: '2rem', textAlign: 'right', fontSize: '1rem', fontWeight: 'bold', color: '#2d9e00'}}>{stat.clickCount}</td>
                                                </tr>
                                              :
                                              <tr>
                                                <td style={{width: '2.5rem'}}>{index + 1}.</td>
                                                <td style={{width: '', textAlign: 'left', fontSize: '0.8rem'}}>{stat.galleryTitle}</td>
                                                <td style={{width: '2rem', textAlign: 'right',}}>{stat.clickCount}</td>
                                              </tr>
                                              :
                                              <tr>
                                                <td style={{width: '2.5rem'}}>{index + 1}.</td>
                                                <td style={{width: '', textAlign: 'left', fontSize: '0.8rem'}}>{stat.galleryTitle}</td>
                                                <td style={{width: '2rem', textAlign: 'right',}}>{stat.clickCount}</td>
                                              </tr>
                                            ))
                                        : 
                                        <tr>
                                            <td colSpan='3' style={{width: '2rem', fontSize: '0.9rem', fontWeight: 'bold'}}>
                                              <NoDataMessage message="No statistics found for the selected duration" fontSize="0.8rem" />
                                            </td>
                                        </tr>
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                    <td style={{width: '40%', padding: '0.8rem'}}>
                      <div style={{textAlign: 'center', width: '50%', float: 'left'}}>
                          <text style={{fontSize: '4rem', color: fontColors[0]}}>{totalViewsGalleries}</text>
                          <br />
                          <text style={{fontSize: '1rem', fontWeight: '300'}}>Total Visits</text> 
                      </div>
                      <div style={{textAlign: 'center', width: '50%', float: 'left'}}>
                          <text style={{fontSize: '4rem', color: fontColors[1]}}>{Math.round(totalViewsGalleries/galleryUserCount) || 0}</text>
                          <text style={{fontSize: '1rem'}}>/ per user</text> 

                          <br />
                          
                          <text style={{fontSize: '1rem', fontWeight: '300'}}>Average Visits</text>                           
                      </div>
                      <br /><br /><br /><br /><br />
                      <div style={{width: '100%'}}>
                      <div className="fitCenterDiv" style={{paddingTop: '1rem'}}>
                          <Circle
                            animate={true}
                            animationDuration="3s"
                            size={120}
                            lineWidth={50}
                            progress={Math.round(galleryUserCount/totalUsers*100) || 0}
                            progressColor="#db6558"
                            bgColor="white"
                            // textColor="white"
                            textStyle={{
                              fontSize: "6.5rem",
                              fontWeight: "500",
                            }}
                          />
                          <br />

                      </div>
                      <div className="fitCenterDiv">
                        <text style={{fontSize: '1.1rem', fontWeight: '300', textAlign: 'center'}}>Average User Views</text> 
                      </div>
                      </div>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withApollo(GalleryStats)));

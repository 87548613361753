import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions'

import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';


import Admin_navbar from '../../../components/admin/admin_navbar/admin_navbar'
import { Input, Form } from "semantic-ui-react";

import { Button } from 'react-uikit';

//read Api data
import { GET_A_NEWS } from '../../../queries/common_queries';

import { UPDATE_NEWS } from '../../../queries/common_queries';

//constants
import { UPLOAD_API_URL } from '../../../constants/index'

//middleware
import { imageUploader } from '../../../middleware/index'

import CKUploadAdapter from '../../../middleware/ck-file-upload-adapter';


//Image Uploader
import Images from '../Image/Images'
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

import ConfirmationModal from '../../../components/adminModals/confirmationModal';


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
        translatingLanguages: state.custom_reducer.translatingLanguages,
        languageKeys: state.custom_reducer.languageKeys,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}
class EditNews extends React.Component {
    constructor(props) {
        super(props);

        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        this.state = {
            news: [],
            newstitle: "",
            newshelpertext: "",
            newscontent: "",
            newsbanner: "",
            url: "",
            id: id,
            newsId: id,
            // lang:localStorage.LANG,
            pagination: {
                newsId: id,
                isActive: true,
                lang:"en",
            },
            image: "",
            loading: true,
            formData: "",
            file: '', imagePreviewUrl: '',
            data: null,
            selectedOption:'',
            news_types:[
                "IPPF_INTERNAL",
                "GLOBAL"
            ],
            formLanguage: "en",

            videos: [],
            videoFiles: [],
            videoError: "",
            isVideoUploading: false,
            blurrModalOpen: false


        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onValueChange = this.onValueChange.bind(this);


        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

    }

    componentDidMount() {
        this.getSingleNews();
    }

    getSingleNews(language) {
        this.get_a_news(this.state.pagination).then(result => {
            const videoList = result.videos ? result.videos.videos ? result.videos.videos : [] : [];

            console.log(result.newsType);
            this.setState({
                id: result.id,
                newsTitle: result.newsTitle,
                newsBannerImage: result.newsBannerImage,
                image: result.newsBannerImage,
                newsHelperText: result.newsHelperText,
                newsContent: result.newsContent,
                url: result.url,
                newsType: result.newsType,
                data: result.newsContent != null ? result.newsContent != undefined ? result.newsContent : "" : "",
                loading: false,
                videos: videoList,
            });

        }).catch(error => {
            console.log(error);
        });
    }

    handleTypeChange = (event, { name, value }) => {
        this.setState({ gender: value });
    };


    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    onEditorChange(event, editor, data) {
        this.setState({
            data: data
        });

    }
    onValueChange(event,data) {
        console.log(data.value)
        if(data.value==='link'){
            this.setState({
                selectedOption: event.target.value
              });
        }
        else if(data.value==='Content'){
            this.setState({
                selectedOption: event.target.value
              });
        }
      }

    handleSubmit = (e) => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });

        this.setState({ loading: true });

        const { newsType, newsTitle, newsBannerImage, newsHelperText, data } = this.state;

     
        if (newsType == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please select the ‘News type’." });
        } else if (newsTitle == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please add a title." });
        } else if (newsBannerImage == '' || newsBannerImage === null || newsBannerImage === undefined) {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please upload a banner image." });
        } else if (newsHelperText == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please add helper text." });
        // } else if (data == null || data == "") {
        //     this.setState({ loading: false });
        //     this.props.setFormStatus({ status: true, title: "Oops!", message: "Required News Content!" });
        } else {

            this.update_news().then(result => {
                console.log(result.newsContent)
                this.getSingleNews();
                this.props.setSuccessStatus({
                    status: true,
                    title: "Oops!",
                    message:
                        "Content updated successfully."
                });

                this.setState({ hideDialog: true });

            }).catch(error => {

                console.log(error);
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message:
                       <div>
                           An error occurred while trying to update the content!
                           <br/>
                           Please try again.
                       </div>
                });

                this.setState({ loading: false, hideDialog: true });
            });

        }
    };

    onImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file_pre = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                image_file: file_pre,
                image: reader.result
            });
        }
        reader.readAsDataURL(file_pre)

        this.props.setFormStatus({ status: false, title: '', message: '' });
        const errs = []
        const files = Array.from(e.target.files)

        if (files.length > 3) {
            const msg = 'Only 3 images can be uploaded at a time'
            this.props.setFormStatus({ status: true, title: "Oops!", message: msg });
        }

        const formData = new FormData()
        const types = ['image/png', 'image/jpeg', 'image/gif']

        files.forEach((file, i) => {

            if (types.every(type => file.type !== type)) {
                errs.push(`'${file.type}' is not a supported format`)
            }

            if (file.size > 1048576) {
                errs.push(`'${file.name}' is too large, please pick a smaller file`)
            }

            formData.append(i, file)
        })

        if (errs.length) {
            return errs.forEach(err => this.props.setFormStatus({ status: true, title: "Oops!", message: err }));
        }

        this.setState({ uploading: true, formData: formData })

    }

    sumbitUploader = (e) => {
        e.preventDefault();
        var formData = this.state.formData;

        if (formData != "") {
            this.setState({ loading: true });
            fetch(`${UPLOAD_API_URL}/image-upload`, {
                method: 'POST',
                body: formData
            })
                .then(res => {
                    if (!res.ok) {
                        throw res
                    }
                    return res.json()
                })
                .then(images => {
                    console.log(images[0].secure_url);
                    this.setState({
                        uploading: false,
                        newsBannerImage: images[0].secure_url,
                        loading: false
                        //images,
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    get_a_news = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_A_NEWS,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        console.log(result);
        return result.data.getNews;
    };



    update_news = async () => {
        const { newsId, newsHelperText, data, newsTitle, newsBannerImage, url, newsType, formLanguage, videos, languageEnabled } = this.state;
        const newsContent = data;
        const lang = formLanguage;
        var videoList = {videos: videos}
        const result = await this.props.client.mutate({
            mutation: UPDATE_NEWS,
            variables: { newsId, newsTitle, newsBannerImage, newsHelperText, newsContent, url , newsType, lang, videos: videoList, languageEnabled},
        });        
        return result.data.updateNews;
    };

    breadcrumbredirect = (path) => {
        if (path == "HOME") {
            this.props.history.push('/admin_home');
        } else if (path == "MANAGENEWS") {
            this.props.history.push('/admin_news_list');
        } else {
            return false;
        }
    }

    // updateprofile= async()=> {        
    //     const {userId,isActive} = this.state;       
    //      const result = await this.props.client.mutate({    
    //                 mutation: EDIT_USER,      
    //               variables: {userId,isActive}   
    //          });        
    //        return result.data.editBasicProfile;         
    //              }


    documentUploadOnClick = event => {
        this.setState({
            isUploading: true,
        });
        console.log(this.state.formData);
        this._startDocUpload(this.state.formData);
    };

    documentUploadOnChange =event => {
        this.setState({
            formData: event.target.files[0],
        });
    }

    _startDocUpload = async file => {
        var intDate = Number(new Date());
        const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
        console.log(result.url)
        if (result.status) {
            this.setState({
                isUploading: false,
                fileRef: 'uploads/' + localStorage.FIRST_NAME + intDate + '/' + file.name,
                image: result.url,
                newsBannerImage: result.url,
            });
        }
    };

    _deleteFile = async (path) => {
        console.log(path)
        const deleteRef = await deleteFile(path);
        this.setState({image:"",newsBannerImage:""});
        return deleteRef;
    }

    changeFormLanguage = (language) => {
        var tempModuleParameter = this.state.pagination;
        tempModuleParameter.lang = language;
        this.get_a_news(tempModuleParameter).then(result => {
            const videoList = result.videos ? result.videos.videos ? result.videos.videos : [] : [];
            console.log(result);
            this.setState({
                id: result.id,
                newsTitle: result.newsTitle,
                newsBannerImage: result.newsBannerImage,
                image: result.newsBannerImage,
                newsHelperText: result.newsHelperText,
                newsContent: result.newsContent,
                url: result.url,
                newsType: result.newsType,
                data: result.newsContent != null ? result.newsContent != undefined ? result.newsContent : "" : "",
                loading: false,
                videos: videoList,
                formLanguage: language,
                languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true
            });

        }).catch(error => {
            console.log(error);
        });
      };



      
  videoUploadOnChange = (e) => {
    console.log(e.target.files);
    console.log(e.target.files[0].type);
    var files = [];
    var type = [];
    var splitArray = [];
    files = e.target.files;
    if(files.length > 0) {
      for(let i = 0; i < files.length; i++) {
        type = files[i].type;
        if(type != '') {
          splitArray = type.split('/');
          if(splitArray[0] != 'video'){
            this.setState({
              videoError: '* Unsupported file format',
            });
          }
          else{
            this.setState({
              videoFiles: e.target.files,
              videoError: '',
            });
          }
        }
        
      }
    }
    

  }

  handleChangeVideoCaption = (e, index) => {
    console.log(e.target.value)
    const tempArray = this.state.videos;
    tempArray[index].description = e.target.value;
    this.setState({
      videos: tempArray,
    })
  }

  removeVideo = (index) => {
    console.log("index: ",index)
    const tempArray = this.state.videos;
    tempArray.splice(index, 1);
    this.setState({
      videos: tempArray,
      blurrModalOpen: false
    })
  }

  videoUploadOnClick = async (e) => {
    this.setState({isVideoUploading: true})
    var videoList = this.state.videos;
    var fileinfo = document.forms.namedItem("fileinfo");
    console.log(fileinfo);
    e.preventDefault();
    fetch('https://kmsvideo.skotie.com/upload', {
      method: 'POST',
      body: new FormData(fileinfo)
    })
    .then(response => response.json())
    .then(result => {
      console.log(result);
      if(result.locations){
        if(result.locations.length > 0) {
          result.locations.map(video => (
            videoList.push({url: video.location, description: "" })
          ))
        }
      }
      this.setState({
        isVideoUploading: false,
        videos: videoList
      })       
    })
    .catch(error => {
      console.error(error);
      this.setState({
        isVideoUploading: false
      })
    });
  }


  openVideoModal = (index) => {
    this.setState({
        blurrModalOpen: true,
        deleteVideoIndex: index,
    })


  }

  closeVideoModal = () => {
    this.setState({
        blurrModalOpen: false,
    })
}

handleCheckBoxChange = (status) => {
    this.setState({ languageEnabled: status });
  }



    render() {
        const { loading, image, news_types, formLanguage, blurrModalOpen } = this.state;
        const { formErrorStatus, formSuccessState, translatingLanguages } = this.props;
        return (
            <div>
                {/* <Admin_navbar /> */}

                <div className="admin-content-inner">
                    <div>
                        <ul class="uk-breadcrumb">
                            <li ><a onClick={() => this.breadcrumbredirect("HOME")}>Home</a></li>
                            <li ><a onClick={() => this.breadcrumbredirect("MANAGENEWS")}>Manage News</a></li>
                            <li ><a onClick={() => this.breadcrumbredirect("EDITNEWS")}>Edit News</a></li>
                        </ul>
                    </div>
                    <div className="uk-flex-inline uk-flex-middle">
                    <i className="fa fa-newspaper uk-margin-right" style={{fontSize: '2.3rem'}}></i>
                        <h4 className="uk-margin-remove"> Edit News  </h4>
                    </div>
                    <div className="uk-margin-medium">
                        <div className="uk-width-1@m">
                            <div className="uk-card-small uk-card-default">
                                <div className="uk-card-header uk-text-bold">
                                    <i className="fa fa-newspaper uk-margin-small-right"></i> News Details
                                    {
                                        translatingLanguages.length > 0 ?
                                        <div className="formLanguageSwitcherDiv">
                                          {
                                            translatingLanguages.map(languageTab => (
                                              <span circular icon="download" className={ formLanguage === languageTab.key ? "formLanguageSwitcherActive"  : "formLanguageSwitcher" } onClick={() => this.changeFormLanguage(languageTab.key)}>
                                                {languageTab.language}
                                              </span>
                                            ))
                                          }
                                        </div>
                                          : null
                                    }
                      {/* <div className="formLanguageSwitcherDiv">
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "en"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("en")}
                        >
                          English
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "fr"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("fr")}
                        >
                          French
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "ar"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("ar")}
                        >
                          Arabic
                        </span>
                      </div> */}
                                                    </div>
                                <div className="uk-card-body uk-padding-remove-top">

                                {
                                        this.state.formLanguage != "en" ?
                                            <div className="disableLanguageVersionDiv">
                                                <input type="checkbox" id="languageEnabled" name="languageEnabled" checked={this.state.languageEnabled} onChange={() => this.handleCheckBoxChange(!this.state.languageEnabled)} />
                                                <label>{this.props.languageKeys[this.state.formLanguage]} version enabled</label>
                                            </div>
                                        : null
                                    }

                                    <div className="uk-form-label">News Type</div>
                                        <div className="uk-inline newsDropdown">
                                            <select  style={styles.dropdownStyle} className="uk-select uk-form-width-large"
                                                id="newsType"
                                                name="newsType"
                                                onChange={this.handleChange}
                                                value={this.state.newsType || ''}
                                              >
                                                <option  key="newsType" value="" disabled>Select News Type</option>
                                                {news_types.map(item => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                    </div>

                                    <div className="uk-form-label"> News Title </div>
                                    <input required value={this.state.newsTitle || ''} className="uk-input uk-form-width-huge" id="newsTitle" name="newsTitle" placeholder="Title" type="text" onChange={this.handleChange} />
                                   

                                    



                                    <div className="uk-form-label"> News Banner Image </div>



<div>
        <div className="">
            {/* File Component */}
            {this.state.image !== null && this.state.image !== undefined && this.state.image !== "" ?
                (
                    <div className="file-upload-group uk-card uk-card-default uk-card-body" >
                        <div className="uk-text-center uk-grid " >

                            <div className="uk-width-auto@m">
                                <span circular icon='download' class="uk-button uk-button-primary uk-button-small" onClick={() => { window.open(this.state.image); }}>Download</span>
                            </div>
                            <div className="uk-width-auto@m">
                                <span circular icon='trash alternate outline' class="uk-button uk-button-secondary uk-button-small" onClick={() => 
                                //   {  
                                //      this._deleteFile(this.state.image).then(result => {
                                //         console.log(result);
                                //         this.setState({
                                //             image: null,
                                //             fileRef: null,
                                //         });
                                //     });
                                // }
                                this.setState({confirmationModal: true})
                                }>Delete</span>
                            </div>

                        </div><br />
                        <Images
                            image={image}
                            // removeImage={this.removeImage}
                        />
                    </div>
                ) : (

                    <div className="uk-text-center uk-grid" >
                        <div className="uk-width-auto@m">
                            <Input
                                onChange={this.documentUploadOnChange}
                                name="upload"
                                id="upload"
                                className="uk-input uk-form-width-huge"
                                placeholder="Upload here"
                                type="file"
                                multiple
                            />
                        </div>
                        <div className="uk-width-auto@m">
                            <span class="uk-button uk-button-primary" onClick={this.documentUploadOnClick}>
                                {loading ? (
                                    <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                ) : ("Upload Image")}
                            </span>
                        </div>
                    </div>
                )
            }

            {/* File Component */}
        </div>
        
        <br />
        {this.state.isUploading ? (
            <div className="uk-text-center uk-grid" >
                <div className="uk-width-expand@m">
                    <h5 className="uk-align-left">Just a moment… <br/>
                        We are uploading your content.
                        </h5>
                </div>
                <div className="uk-width-auto@m">
                    <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                </div>
            </div>
        ) : (
                ""
            )}
    </div>



                                    <Form.Field
                                                style={{display:"flex"}}
                                        >
                                                <Input 
                                                value='link'
                                                control='input'
                                                type='radio'
                                                name='htmlRadios'
                                                checked={this.state.selectedOption === "link"}
                                                onChange={this.onValueChange}
                                                
                                                />
                                            <div style={{marginTop:"3px"}} className="uk-form-label" > URL </div>
                                        </Form.Field>       
                                    <input required  disabled={this.state.selectedOption==="Content"}  value={this.state.url || ''} className="uk-input uk-form-width-huge" id="url" name="url" placeholder="URL" type="text" onChange={this.handleChange} />
                                    
                                    <Form.Field
                                            style={{display:"flex"}}
                                        >
                                            <Input
                                              value="Content"
                                              control='input'
                                              type='radio'
                                              name='htmlRadios'
                                              checked={this.state.selectedOption === "Content"}
                                              onChange={this.onValueChange}
                                              />
                                       
                                        <div style={{marginTop:"3px"}} className="uk-form-label"> Content </div>
                                        </Form.Field>
                   
                                        <CKEditor
                                            disabled={this.state.selectedOption === "link"} 
                                            onInit={editor => {
                                                console.log('Editor is ready to use!', editor);
                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement(),
                                                    editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                                        return new CKUploadAdapter(loader);
                                                    }
                                                );
                                            }}

                                            onEditorChange
                                            onChange={(event, editor) =>
                                            //  console.log( { event, editor } )
                                            {
                                                const data = editor.getData();
                                                this.onEditorChange(event, editor, data);
                                               
                                            }
                                            }
                                            editor={DecoupledEditor}
                                            // data="<p>Hello from CKEditor 5's DecoupledEditor!</p>"
                                            data={this.state.data}
                                            config={{
                                                //    plugins:  Table, TableToolbar, TableProperties, TableCellProperties,
                                                // numberedList: {
                                                //     options: [
                                                //         'decimal', 'decimal-leading-zero', 'lower-roman', 'upper-roman', 'lower-latin', 'upper-latin'
                                                //     ]
                                                // },
                                                
                                                heading: {
                                                    options: [
                                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                                    ]
                                                },
                                                fontSize: {
                                                    options: [
                                                        'default',
                                                        8,
                                                        9,
                                                        10,
                                                        11,
                                                        12,
                                                        14,

                                                        16,
                                                        18,
                                                        20,
                                                        22,
                                                        24,
                                                        26,
                                                        28,
                                                        36,
                                                        48,
                                                        72
                                                    ]
                                                },
                                                fontFamily: {
                                                    options: [
                                                        'default',
                                                        'Arial',
                                                        'courier New',
                                                        'Georgia',
                                                        'Lucida Sans Unicode',
                                                        'Tahoma',
                                                        'Times New Roman',
                                                        'Trebuchet MS',
                                                        'Verdana',
                                                        'Calibri Light',
                                                        'Calibri (Body)',
                                                        'Bahnschrift Light',
                                                        'Agency FB',
                                                        'Algerian',
                                                        'Arial Black',
                                                        'Arial Narrow',
                                                        'Arial Rounded MT Bold',
                                                        'Bahnschrift',
                                                        'Bahnschrift Condensed',
                                                        'Bahnschrift Light Condensed',
                                                        'Bahnschrift SemiBold SemiConden',
                                                        'Bahnschrift SemiCondensed',
                                                        'Sylfaen', 'Symbol', "Tahoma", 'Tempus Sans ITC', 'Times New Roman', 'Trebuchet MS', 'Tw Cen MT', 'Tw Cen MT Condensed', 'Tw cen MT Condensed Extra Bold', 'Verdana', ' Viner Hand ITC', 'Webdings ', 'Wide Latin', 'Wingdings', 'Wingdings 2', 'Wingdings 3', 'Yu Gothic ', 'Yu Gothic Light', 'Yu Gothic Medium', 'Yu Gothic UI',
                                                        'Yu Gothic UI Light', 'Yu Gothic UI Semibold', 'Yu Gothic UI Semilight', 'Rockwell Extra Bold', 'Sript MT Bold', ' Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Black', 'Segoe UI Emoji', 'Segoe UI Historic', 'Segoe UI Light', ' Segoe UI Semibold', 'Segoe UI Semilight', 'Segoe UI Symbol', 'SHOWCARD GOTHIC', ' SimSun', ' SimSun-ExtB', 'Sitka Banner', ' Sitka Display', 'Sitka Heading', 'Sitka Small', 'Sitka Subheading', 'Sitka Text', 'Snap ITC', 'STENCIL ',
                                                        'MV Boli', 'Myanmar Text', 'Nagara Engraved', 'Nagara Solid', 'Nirmala UI', 'Nirmala UI Semilight ', 'NSimSun', 'OCR A Extended', ' Old English text', 'Onyx', 'Palatino Linotype', 'papyrus', 'Perpetua',
                                                        'PERPETUA  TITLING MT', 'Playbill', 'PMingLiU-ExtB', 'Poor Richard', 'pristina', 'Ravie', 'Rockwell', 'Rockwell Condensed ',
                                                        'Microsoft JhengHei UI', 'Microsoft JhengHei UI Light', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le',
                                                        'Microsoft Uighur', 'Microsoft YaHei', 'Microsoft YaHei Light', 'Microsoft YaHei UI ', ' Microsoft YaHei UI Light', 'Microsoft Yi Baiti', 'MingLiU_HKSCS-ExtB', 'MingLiU-ExtB', 'Modern No. 20', 'Mongolian Baiti', 'Monotype Corsiva', ' MS Gothic',
                                                        'MS Outlook', 'MS PGothic', 'MS Reference Sans Serif ', 'MS Reference Specialty', ' MS UI Gothic', ' MT Extra',
                                                        'Imprint MT Shadow', 'Informal Roman', 'Ink Free', 'Javanese Text', 'Jokerman', 'Kristen ITC', ' Leelawadee ', 'Leelawadee UI', 'Leelawadee UI Semilight', ' Lucida Bright', 'Lucida Calligraphy ', 'Lucida Console', 'Lucida Fax', 'Lucida Handwriting', 'Lucida Sans', ' Lucida Sans Typewriter', 'Lucida Sans Unicode', 'magneto', 'Maiandra GD', 'Malgun Gothic', 'Malgun Gothic Semilight', ' Marlett', 'matura MT Script Capitats ',
                                                        'Cooper Black', 'COPPERPLATE GOTHIC BOLD', 'COPPERPLATE GOTHIC LIGHT', 'Corbel', 'Corbel Light', 'Courier New ', 'Curlz MT ',
                                                        'Dubai', ' Dubai Light', 'Dubai Medium', 'Ebrima', 'Elephant', 'ENGRAVERS MT', 'Eras Bold ITC', ' Eras Demi ITC', 'Eras Light ITC', 'Eras Medium ITC', ' FELIX TITLING', 'Footlight MT Light ', 'Forte', 'Franklin Gothic Book', 'Franklin Gothic Demi', 'Franklin Gothic Demi Cond', ' Franklin Gothic Heavy',
                                                        'Book Antigua', 'Bookman Old Style', ' Bookshelf Symbol 7', 'Bradley Hand ITC', 'Britannic Bold', 'Broadway', 'Brush script MT', 'Calibri', 'Calibri Light', 'Californian FB', 'Calisto MT', 'Cambria', 'Cambria Math', 'Candara', 'Candara Light', 'CASTELLAR', 'Centaur', 'Century', 'Century Gothic', 'Century Schoolbook', 'Chiller', 'Colonna MT', 'Comic Sans MS', 'Consolas', 'Constantia',
                                                        'Franklin Gothic Heavy', 'Franklin Gothic Medium', 'Franklin Gothic Medium Cond', 'Freestyle Script', ' French Script MT', 'Gadugi', 'Garamond', 'Georgia', 'Gill Sans MT', 'Gill Sans MT Condensed',
                                                        'Gloucester MT Extra Condensed ', 'Coudy Old Style', 'GOVUDY STOUT', 'Haettenschweiler', 'Harrington', 'High Tower Text', 'HoloLens MDL2 Assets', 'Impact', 'Imprint MT Shadow'

                                                    ]
                                                },
                                                fontColor: {
                                                    colors: [
                                                        //Green
                                                        {
                                                            color: 'hsl(210, 9%, 92%)',

                                                        },
                                                        {
                                                            color: 'hsl(210, 9%, 100%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 95%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 96%)',

                                                        },

                                                        {
                                                            color: 'hsl(145, 61%, 95%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 9%, 85%)',

                                                        },

                                                        {
                                                            color: 'hsl(204, 8%, 98%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 90%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 91%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 90%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 9%, 70%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 95%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 81%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 83%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 80%)',

                                                        },



                                                        {
                                                            color: 'hsl(210, 9%, 55%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 90%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 72%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 74%)',

                                                        },

                                                        {
                                                            color: 'hsl(145, 61%, 69%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 14%, 39%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 86%)',

                                                        }, {
                                                            color: 'hsl(204, 70%, 63%)',

                                                        }, {
                                                            color: 'hsl(6, 78%, 66%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 59%)',
                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 24%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 81%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 53%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 57%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 49%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 18%)',

                                                        }, {
                                                            color: 'hsl(204, 8%, 76%)',

                                                        }, {
                                                            color: 'hsl(204, 62%, 47%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 50%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 43%)',

                                                        },

                                                        {
                                                            color: 'hsl(210, 29%, 18%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 5%, 67%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 40%)',

                                                        }, {
                                                            color: 'hsl(6, 59%, 43%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 37%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 16%)',

                                                        },

                                                        {
                                                            color: 'hsl(204, 4%, 58%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 34%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 59%, 37%)',

                                                        }, {
                                                            color: 'hsl(145, 63%, 31%)',

                                                        },

                                                        {
                                                            color: 'hsl(210, 29%, 13%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 3%, 49%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 28%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 59%, 30%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 25%)',

                                                        },
                                                    ]
                                                },
                                                mediaEmbed: {
                                                    semanticDataOutput: true
                                                },
                                                link: {
                                                    addTargetToExternalLinks: true
                                                  }
                                            }}
                                        />

                {/* Upload videos start */}

<div className="uk-form-label"> Upload Videos </div>
                        <form id="fileinfo" name="fileinfo" onSubmit={(e) => this.videoUploadOnClick(e)}>
                            <div className="uk-grid">
                                  <div className="uk-width-auto@m">
                                      <Input disabled={this.state.selectedOption==="link"} className="uk-input uk-form-width-huge" type="file" name="file" multiple onChange={this.videoUploadOnChange} />
                                  </div>
                                  <div className="uk-width-auto@m">
                                      <Button className="uk-button-primary" type="submit" disabled={this.state.isVideoUploading === true} >Upload Video(s)</Button>   
                                  </div>
                                  <br />
                                  {
                                    this.state.videoError != "" ?
                                      <div style={{color: 'red', width: '100%', fontSize: '0.8rem'}}>
                                        <text >{this.state.videoError}</text>
                                      </div>
                                    : null  
                                  }
                            </div>
                        </form>
                        <br />
                        {this.state.isVideoUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                              {/* Just one second , We are uploading your video/s. */}
                              Just a moment…
                            <br/>
                            We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                        {
                          this.state.videos.length > 0 ?
                            this.state.videos.map((x, index) => (
                              <table style={{width: '100%'}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '20rem', height: '10rem', padding: '0rem 0rem', backgroundColor: ''}}>
                                        <div className="video-cover">
                                            <video key={'https://kmsvideo.skotie.com/' + x.url} className="video" height="200" style={{height: '10rem'}} controls>
                                                <source src={'https://kmsvideo.skotie.com/' + x.url} />
                                            </video>
                                        </div>
                                  
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                    <textarea
                                      value={x.description}
                                      className="uk-textarea"
                                      rows="3"
                                      id="description"
                                      name="description"
                                      placeholder="Description"
                                      style={{fontSize: '0.8rem', marginBottom: '1rem', marginLeft: '1rem'}}
                                      onChange={(e) => this.handleChangeVideoCaption(e, index)}></textarea>
                                      <div className="fitCenterDiv">
                                      <Button className="uk-button-secondary" style={{margin: '0 auto', fontSize: '0.8rem'}} disabled={this.state.isVideoUploading === true} onClick={() => this.openVideoModal(index)}>Delete Video</Button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            ))
                            : null
                        }
{/* Upload videos end */}

                                    

                               

                                    {/* <input type='file' id='single' onChange={this.onImageChange} />

                                    <button className="uk-button uk-button-warning admin-btn" onClick={this.sumbitUploader} >
                                        {loading ? (
                                            <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                        ) : ("Click here to Upload")}</button>

                                    <Images
                                        image={image}
                                        removeImage={this.removeImage}
                                    /> */}

                                    <br />
                                    <div className="uk-form-label"> News Helper Text </div>
                                    <textarea required value={this.state.newsHelperText || ''} className="uk-textarea" rows="5" id="newsHelperText" name="newsHelperText" onChange={this.handleChange}></textarea>
                                </div>
                            </div>
                        </div>

                        {/* Video Delete Modal Start*/}

                            {blurrModalOpen === true ? (
                              <div style={styles.background}>
                                <div style={styles.background1}>
                                  <h4>Are you sure you want to delete this video?</h4>
                                  <hr />
                                  {/* <br /> */}
                                  {/* <br /> */}
                                  <Button
                                    className="uk-button modaleConfirmButton"
                                    type="button"
                                    style={styles.confirmButton}
                                    //   disabled={rating === ""}
                                    onClick={() =>
                                      this.removeVideo(this.state.deleteVideoIndex)
                                    }
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    className="uk-button uk-button-primary modaleCancelButton"
                                    type="button"
                                    style={styles.cancelButton}
                                    // disabled={rating === ""}
                                    onClick={() => this.closeVideoModal()}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                             ) : null}

{/* Video Delete Modal Start*/}

                        <Button
                            className="uk-button uk-button-primary uk-margin"
                            style={{ height: 40 }}
                            type="button"
                            disabled={loading ? (true) : (false)}
                            onClick={() => this.handleSubmit(this.event)}
                        >
                            {loading ? (
                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                            ) : ("Update")}
                        </Button>
                        {(formErrorStatus.status) ? (
                            <div className="danger_alert">{formErrorStatus.message}</div>
                        ) : ((formSuccessState.status) ? (
                            <div className="success_alert">{formSuccessState.message} </div>
                        ) : (''))}
                    </div>
                </div>
                      
                {
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => {
                this._deleteFile(this.state.image).then(result => {
                    console.log(result);
                    this.setState({
                        image: null,
                        fileRef: null,
                        confirmationModal: false,
                    });
                });
            }} closeModal={() => this.setState({confirmationModal: false})} />
            : null
        }

            </div>
        );
    }
}


export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(EditNews)));

 const styles = {
 dropdownStyle :{
    border:"2px solid #e2e2e2",
    borderRadius:"4px",
    backgroundColor:"#f9f9f9"

 },
 background: {
    backgroundColor: "#0000005e",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  background1: {
    backgroundColor: "white",
    position: "absolute",
    // top: '0',
    right: "0",
    width: "40%",
    padding: "1rem",
    zindex: "100",
    marginRight: "28%",
    marginTop: "15%",
    borderRadius: "5px",
    textAlign: "center",
  },

  cancelButton: {
    width: "7rem",
    float: "right",
    marginRight: "0.8rem",
  },

  confirmButton: {
    width: "7rem",
    float: "right",
  },
}
import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../../navbar/navbar';
import { withNamespaces } from "react-i18next";
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';



const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class QuizReviewBanner extends React.Component {
    constructor(props) {
        super(props);
       
    }


    
    render() {
   const { t } = this.props;
        return (
            <div className={localStorage.LANG=="ar"? "topic1_ar hero-bg shadow-small": "topic1 hero-bg shadow-small"}>
                <div className="uk-grid"> 
                {
                    localStorage.LANG=='ar'? (
                        <h3 className="uk-light uk-text-uppercase uk-text-bold uk-text-white" style={{paddingTop:"12px"}}> {t('quiz_result')} </h3>           

                    ):(
                        <h3 className="uk-light uk-text-uppercase uk-text-bold uk-text-white"> {t('quiz_result')} </h3>           

                    )
                }               
                </div>                 
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(QuizReviewBanner))));
import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { Route, withRouter, Redirect } from "react-router-dom";
import Navbar from "../../../components/navbar/navbar";
import { withNamespaces } from 'react-i18next';

//images
import NewsContainer from "../../../components/news/newsContainer/newsContainer";
import GalleryContainer from "../../../components/gallery/galleryContainer/galleryContainer";

//containers
import LearningContainer from "../../../components/learning/learningContainer/learningContainer";
import ToolsContainer from "../../../components/tools/toolsContainer/toolsContainer";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";

import ResourceContainer from "../../../components/resource/resourceContainer/resourceContainer";
import ExamContainer from "../../user/exams/exams";

//Menu
const homeRoutes = [
  {
    path: "/home",
    name: "Learning",
    component: LearningContainer,
  },
  {
    path: "/tools",
    name: "Tools",
    component: ToolsContainer,
  },
  {
    path: "/sbls",
    name: "SBL",
    component: ExamContainer,
  },
  {
    path: "/news",
    name: "News",
    component: NewsContainer,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: GalleryContainer,
  },
  {
    path: "/resources",
    name: "Resources",
    component: ResourceContainer,
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    }

    var currentLocation = window.location.href;
    var url = currentLocation.split("?");
    url = url[0];
    url = url.split("/");
    var page = url[url.length - 1];

    this.state = {
      page: page,
      statusId: "",
    };
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
                    : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
                    : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
                    : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  _onLinkClick = (path) => {
    this.props.history.push(path);
  };

  setActive = (statusId) => {
    this.setState({ statusId: statusId });
  };

  render() {
    const { t } = this.props;
    const { page } = this.state;
    console.log(localStorage.LANG)
    return (
      <div>
        <Navbar />
        <div className="app">
          <Header/>
          {/* <ul className="uk-switcher switcher-container"> */}
          {page == "home" ? (
            <div className={localStorage.LANG=="ar"? "topic1_ar hero-bg": "topic1 hero-bg"} >
               {localStorage.LANG=='ar'? (
                  <h2 style={{ lineHeight: "0.3", paddingTop:"22px" }} className="uk-animation-fade">
              
                  {t('welcome')}، {localStorage.FIRST_NAME}
                </h2>
               ):(
                  <h2 style={{ lineHeight: "0.3", paddingTop:"10px" }} className="uk-animation-fade">
              
                  {t('welcome')}, {localStorage.FIRST_NAME}
                </h2>
               )
               }
             
              <h4 style={{ width: "85%" }}>
                {" "}
                {t('welcome_msg')}{" "}
              </h4>
            </div>
          ) : page == "tools" ? (
            <div className={localStorage.LANG=="ar"? "topic2_ar hero-bg" : "topic2 hero-bg"} >
              {/* topic2 */}
              {localStorage.LANG=='ar'?(
                <h4 style={{ width: "85%",paddingTop:"12px" }} className="uk-animation-fade">
                {t('tools_tab_title')}
              </h4>
              ):(
                <h4 style={{ width: "85%" }} className="uk-animation-fade">
                {t('tools_tab_title')}
              </h4>
              )}
              {/* <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p> */}
            </div>
          ) : page == "news" ? (
            <div className={localStorage.LANG=="ar"? "topic4_ar hero-bg": "topic4 hero-bg"} >
             {localStorage.LANG=='ar'?(
                <h4 style={{ width: "85%", paddingTop:"12px" }} className="uk-animation-fade">
                {t('news_tab_title')}
               </h4>
             ):(
              <h4 style={{ width: "85%" }} className="uk-animation-fade">
              {t('news_tab_title')}
             </h4>
             )}
              <p>
              <div dangerouslySetInnerHTML={{__html: t('tab_sub_title_news')}}/>
              </p>

              {/* <h2 className="uk-animation-fade"> News    </h2>
                                            <p> News & Educational articles from the IPPF and our partners <br />from around the globe. Keep up to date and informed.</p> */}
            </div>
          ) : page == "gallery" ? (
            <div className={localStorage.LANG=="ar"? "topic5_ar hero-bg": "topic5 hero-bg"} >
             {localStorage.LANG=='ar'?(
                <h4 style={{paddingTop:"12px"}} className="uk-animation-fade">
                {t('gallery_tab_title')}
                <br /> {t('gallery_tab_title_two')}
              </h4>
             ):(
              <h4 className="uk-animation-fade">
              {t('gallery_tab_title')}
              <br /> {t('gallery_tab_title_two')}
            </h4>
             )}
              <p>
                <div dangerouslySetInnerHTML={{__html: t('tab_sub_title_gallery')}}/>
              </p>

              {/* <h2 className="uk-animation-fade"> Welcome {localStorage.FIRST_NAME + " " + localStorage.LAST_NAME}    </h2>
                                                    <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p> */}
            </div>
          ) : page == "resources" ? (
            <div className={localStorage.LANG== "ar"? "topic3_ar hero-bg": "topic3 hero-bg" } >
              {/* topic5 */}
             {localStorage.LANG=='ar'?(
                <h4 style={{ width: "85%", paddingTop:"12px" }} className="uk-animation-fade">
                {t('resources_tab_title')}
               </h4>
             ):(
              <h4 style={{ width: "85%" }} className="uk-animation-fade">
              {t('resources_tab_title')}
             </h4>
             )}
              <p>
                <div dangerouslySetInnerHTML={{__html:t('tab_sub_title_resource')}}/>
              </p>
              {/* <p> The SEE Platform enables members to get informed <br /> across a range of subjects spanning the industry. </p> */}
            </div>
          ) : page == "sbls" ? (
            <div className={localStorage.LANG=="sp"?("topic11_spanish hero-bg"): localStorage.LANG=="fr"?("topic11_spanish hero-bg "): localStorage.LANG=="ar"? "topic11_ar hero-bg" : ("topic11 hero-bg") }>
              {/* topic5 */}
              <h3 style={{ width: "85%" }} className="uk-animation-fade">
                {localStorage.LANG=='ar'? (
                <div style={{paddingTop:"12px"}} dangerouslySetInnerHTML={{__html:t('sbl_tab_title')}} />
                ):(
                <div dangerouslySetInnerHTML={{__html:t('sbl_tab_title')}} />
                )}
              </h3>
              <h4
                style={{ width: "88%", marginTop: "-0.8rem" }}
                className="uk-animation-fade"
              >

            <p className={localStorage.LANG=="sp"? ("sbl_para_sp_fr"):(localStorage.LANG=="fr"? ("sbl_para_sp_fr"):("sbl_para"))}>
                <div dangerouslySetInnerHTML={{__html:t('sbl_tab_title_two')}} />
                </p>
                
                {/* Share your views, stimulate your social enterprise thinking,
                test your knowledge and interact <br/> virtually 1 on 1 with the SE Hub in a
                series of scenarios, trivia and on current focus areas. */}
              </h4>
              <p style={{ width: "85%", marginTop: "-0.4rem" }}>
               <div dangerouslySetInnerHTML={{__html: t('tab_sub_title_sbl')}} />
              </p>
            </div>
          ) : null}

          <button
            className="uk-button uk-hidden@m  mobile-catagory-button"
            type="button"
            uk-toggle="target: #tabs-moible; cls: tabs-moible"
          >
            {" "}
            Open subcatagory{" "}
          </button>

          <ul
            className="uk-subnav uk-subnav-pill tabs-moible-hidden nav-menues"
            uk-switcher="connect: .switcher-container"
            id="tabs-moible"
          >
            {page == "home"
              ? [
                  <li
                    className="uk-active"
                    onClick={() => this._onLinkClick("/home")}
                  >
                    <a>{t('learning_tab')}</a>
                  </li>,
                  <li  style={{paddingTop:"0px", fontSize:"15px"}} onClick={() => this._onLinkClick("/sbls")}>
                    <a  className={localStorage.LANG=="ar"? "SBL_tab_style": "" } >{t('SBL_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/tools")}>
                    <a>{t('tools_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/resources")}>
                    <a>{t('resources_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/news")}>
                    <a>{t('news_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/gallery")}>
                    <a>{t('gallery_tab')}</a>
                  </li>,
                ]
              : page == "tools"
              ? [
                  <li onClick={() => this._onLinkClick("/home")}>
                    <a>{t('learning_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/sbls")}>
                    <a className={localStorage.LANG=="ar"? "SBL_tab_style": "" }>{t('SBL_tab')}</a>
                  </li>,
                  <li
                    className="uk-active"
                    onClick={() => this._onLinkClick("/tools")}
                  >
                    <a>{t('tools_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/resources")}>
                    <a>{t('resources_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/news")}>
                    <a>{t('news_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/gallery")}>
                    <a>{t('gallery_tab')}</a>
                  </li>,
                ]
              : page == "news"
              ? [
                  <li onClick={() => this._onLinkClick("/home")}>
                    <a>{t('learning_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/sbls")}>
                    <a className={localStorage.LANG=="ar"? "SBL_tab_style": "" }>{t('SBL_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/tools")}>
                    <a>{t('tools_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/resources")}>
                    <a>{t('resources_tab')}</a>
                  </li>,
                  <li
                    className="uk-active"
                    onClick={() => this._onLinkClick("/news")}
                  >
                    <a>{t('news_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/gallery")}>
                    <a>{t('gallery_tab')}</a>
                  </li>,
                ]
              : page == "gallery"
              ? [
                  <li onClick={() => this._onLinkClick("/home")}>
                    <a>{t('learning_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/sbls")}>
                    <a className={localStorage.LANG=="ar"? "SBL_tab_style": "" }>{t('SBL_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/tools")}>
                    <a>{t('tools_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/resources")}>
                    <a>{t('resources_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/news")}>
                    <a>{t('news_tab')}</a>
                  </li>,
                  <li
                    className="uk-active"
                    onClick={() => this._onLinkClick("/gallery")}
                  >
                    <a>{t('gallery_tab')}</a>
                  </li>,
                ]
              : page == "resources"
              ? [
                  <li onClick={() => this._onLinkClick("/home")}>
                    <a>{t('learning_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/sbls")}>
                    <a className={localStorage.LANG=="ar"? "SBL_tab_style": "" }>{t('SBL_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/tools")}>
                    <a>{t('tools_tab')}</a>
                  </li>,
                  <li
                    className="uk-active"
                    onClick={() => this._onLinkClick("/resources")}
                  >
                    <a>{t('resources_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/news")}>
                    <a>{t('news_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/gallery")}>
                    <a>{t('gallery_tab')}</a>
                  </li>,
                ]
              : page == "sbls"
              ? [
                  <li onClick={() => this._onLinkClick("/home")}>
                    <a>{t('learning_tab')}</a>
                  </li>,
                  <li
                    onClick={() => this._onLinkClick("/sbls")}
                    className="uk-active"
                  >
                    <a className={localStorage.LANG=="ar"? "SBL_tab_style": "" }>{t('SBL_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/tools")}>
                    <a>{t('tools_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/resources")}>
                    <a>{t('resources_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/news")}>
                    <a>{t('news_tab')}</a>
                  </li>,
                  <li onClick={() => this._onLinkClick("/gallery")}>
                    <a>{t('gallery_tab')}</a>
                  </li>,
                ]
              : null}
          </ul>

          {homeRoutes.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}

          {page == "home" ? <Redirect to="/home" /> : null}
        </div>
        <Footer language={localStorage.LANG} />
      </div>
    );
  }
}

export default withRouter(withApollo(withNamespaces()(Home)));

import * as React from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Navbar from "../../../components/navbar/navbar";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import ContentLoader from "react-content-loader";
import ExamContainer from "../../../components/exams/examContainer";
import Loader from 'react-loader-spinner'

//queries
import {
    GET_SINGLE_EXAM,
} from "../../../queries/common_queries";
import NoDataMessage from '../../../components/messages/contentNotFound/NoDataFound'

import SearchBanner from "../../../components/search/searchBanner";
import { GET_SEARCH_RESULTS } from "../../../queries/user_queries";
import { withNamespaces } from 'react-i18next';

class Exam extends React.Component {
    constructor(props) {
        super(props);
        window.scrollTo(-500, -200);
        var currentLocation = window.location.href;
        var url = currentLocation.split("?");
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        let query = new URLSearchParams(this.props.location.search);
        let id = query.get("id");

        if (localStorage.USER_ID == undefined) {
            this.props.history.push("/");
        } else {
            if (id == undefined) {
                this.props.history.push("/home");
            }
        }

        this.state = {
            searchString: query.get("id"),
            loading: false,
            modules: [],
            subModules: [],
            exams: [],
            resources: [],
            news: [],
            galleries: [],
            resultCount: 0,
            mCollapse: true,
            sCollapse: true,
            eCollapse: true,
            rCollapse: true,
            nCollapse: true,
            gCollapse: true,
        };

        // this.handleClick = this.handleClick.bind(this);
        var Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = localStorage.LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
                    : localStorage.LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
                    : localStorage.LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
                    : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
        })();
    }

    componentDidMount() {
        if (this.state.searchString != '') {
            this.setState({
                loading: true
            })
            setTimeout(() => {
                this.getSearchResults().then((result) => {
                    console.log(result);
                    const count = result.modules.length + result.subModules.length + result.exams.length + result.resources.length + result.news.length + result.galleries.length;
                    this.setState({
                        modules: result.modules,
                        subModules: result.subModules,
                        exams: result.exams,
                        resources: result.resources,
                        news: result.news,
                        galleries: result.galleries,
                        resultCount: count,
                        loading: false
                    });
                });
            }, 1000);

        }
    }

    breadcrumbredirect = (path) => {
        if (path == "HOME") {
            this.props.history.push("/home");
        } else {
            return false;
        }
    };

    redirectToHome = () => {
        this.props.history.push("/home");
    };

    getSearchResults = async () => {
        const { searchString, } = this.state;
        const result = await this.props.client.mutate({
            mutation: GET_SEARCH_RESULTS,
            variables: {
                searchKey: searchString,
                lang: localStorage.LANG,
            },
        });
        return result.data.getSearchResults;
    };

    search = () => {
        this.setState({
            loading: true,
            // modules: [],
            // subModules: [],
            // exams: [],
            // resources: [],
            // news: [],
            // galleries: [],
            // resultCount: [],
            // loading: [],
        })
        if (this.state.searchString != '') {
            setTimeout(() => {
                this.getSearchResults().then((result) => {
                    console.log(result);
                    const count = result.modules.length + result.subModules.length + result.exams.length + result.resources.length + result.news.length + result.galleries.length;
                    this.setState({
                        modules: result.modules,
                        subModules: result.subModules,
                        exams: result.exams,
                        resources: result.resources,
                        news: result.news,
                        galleries: result.galleries,
                        resultCount: count,
                        loading: false,
                    });
                })
            }, 1000);
        }
    };

    searchOnEnter = (e) => {
        if(e.key === 'Enter'){
            this.setState({
                loading: true,
            })
            if (this.state.searchString != '') {
                setTimeout(() => {
                    this.getSearchResults().then((result) => {
                        console.log(result);
                        const count = result.modules.length + result.subModules.length + result.exams.length + result.resources.length + result.news.length + result.galleries.length;
                        this.setState({
                            modules: result.modules,
                            subModules: result.subModules,
                            exams: result.exams,
                            resources: result.resources,
                            news: result.news,
                            galleries: result.galleries,
                            resultCount: count,
                            loading: false,
                        });
                    })
                }, 1000);
            }
        }
    };

    handleSearchChange = (e) => {
        this.setState({
            searchString: e.target.value
        })
    }

    redirectToResult = (path) => {
        this.props.history.push(path);
    }

    redirectToExternalSite = (url) => {
        window.open(url);
    }

    openUrl = (url) => {
        window.open(url);
    }

    expandSection = (section, status) => {
        this.setState({
            [section]: status,
        })
    }

    render() {
        const { loading, modules, subModules, exams, resources, news, galleries, resultCount, mCollapse, sCollapse, eCollapse, rCollapse, nCollapse, gCollapse, } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Navbar />
                <div className="app" style={{ paddingBottom: "20px" }}>
                    <Header />
                    <SearchBanner />
                    {
                        loading === true ?
                            <div className="searchLoadingOverlay">
                                <Loader
                                    type="ThreeDots"
                                    color="#00A9E0"
                                    height={50}
                                    width={50}
                                    // timeout={3000} //3 secs
                                    className="searchResultSpinner"
                                />
                                <text style={{ float: 'left' }}></text>
                            </div>
                            : null
                    }

                    {
                        loading === false ?

                            <div
                                className="uk-container"
                                uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 400"
                            >
                                <br />{" "}
                                <div>
                                    <ul className="uk-breadcrumb">
                                        <li>
                                            <a onClick={() => this.breadcrumbredirect("HOME")}>
                                               {t('home')}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                {t('search_results')}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                {this.state.searchString}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <br />

                                <div style={{ margin: '0 auto', width: 'fit-content' }}>
                                    <input placeholder="Search..." className="searchResultsInput" value={this.state.searchString} onChange={(e) => this.handleSearchChange(e)} onKeyPress={(e) => this.searchOnEnter(e)} />
                                    <i
                                        style={{
                                            marginTop: '0.8rem',
                                            color: "#3E93CC",
                                            marginRight: '1rem',
                                            cursor: 'pointer',
                                            fontSize: '0.9rem'
                                        }}
                                        className="fa fa-search"
                                        onClick={() => this.search()}
                                    ></i>
                                </div>
                                <br />
                                <br />
                                <div>
                                    <text>{t('search_result_for')} <b>{this.state.searchString}</b></text>
                                    <br />
                                    {this.state.loading != true ? <text><b>{resultCount}</b> {resultCount === 1 ? "result" : "results"} {t('found')}</text> : null}
                                </div>
                                <br />
                                <br />
                                {
                                    modules.length > 0 ?
                                        <div>
                                            <h6 className="searchResultCategory" onClick={() => this.expandSection('mCollapse', this.state.mCollapse === true ? false : true)}>
                                                <i className= { localStorage.LANG=='ar'? "fa fa-caret-down iconHover2_ar" : "fa fa-caret-down iconHover2" } ></i>
                                {t('modules')}
                                </h6>
                                            {
                                                mCollapse === true ?
                                                    modules.map(result => (
                                                        <div className="searchResultsMainDiv" onClick={() => this.redirectToResult('/module?id=' + result.id)}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '95%' }}>
                                                                            <text className="searchResultsTitles">{result.moduleTitle}</text>
                                                                            <br />
                                                                            <text className="searchResultsContents">{result.moduleHelperText}</text>
                                                                        </td>
                                                                        <td style={{ width: '5%', textAlign: 'center' }}>
                                                                            <i
                                                                                style={{
                                                                                    marginTop: '0.8rem',
                                                                                    color: "#2e2e2e",
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '0.9rem'
                                                                                }}
                                                                                className={localStorage.LANG=='ar'? "fa fa-chevron-left iconHover" : "fa fa-chevron-right iconHover"}
                                                                                uk-tooltip={t('go_to_result')}
                                                                                onClick={() => this.redirectToResult('/module?id=' + result.id)}
                                                                            ></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                        </div>
                                        : null
                                }

                                {
                                    subModules.length > 0 ?
                                        <div style={{ marginTop: '2rem' }}>
                                            <h6 className="searchResultCategory" onClick={() => this.expandSection('sCollapse', this.state.sCollapse === true ? false : true)}>
                                                <i className= { localStorage.LANG=='ar'? "fa fa-caret-down iconHover2_ar" : "fa fa-caret-down iconHover2" } ></i>
                                {t('sub_modules')}
                                </h6>
                                            {
                                                sCollapse === true ?
                                                    subModules.map(result => (
                                                        <div className="searchResultsMainDiv" onClick={() => this.redirectToResult('/sub_module?id=' + result.id + '&module=' + result.moduleId)}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '95%' }}>
                                                                            <text className="searchResultsTitles">{result.subModuleTitle}</text>
                                                                            <br />
                                                                            <text className="searchResultsContents">{result.subModuleHelperText}</text>
                                                                        </td>
                                                                        <td style={{ width: '5%', textAlign: 'center' }}>
                                                                            <i
                                                                                style={{
                                                                                    marginTop: '0.8rem',
                                                                                    color: "#2e2e2e",
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '0.9rem'
                                                                                }}
                                                                                className={localStorage.LANG=='ar'? "fa fa-chevron-left iconHover" : "fa fa-chevron-right iconHover"}
                                                                                uk-tooltip={t('go_to_result')}
                                                                                onClick={() => this.redirectToResult('/sub_module?id=' + result.id + '&module=' + result.moduleId)}
                                                                            ></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    ))
                                                    : null
                                            }
                                        </div>
                                        : null
                                }

                                {
                                    exams.length > 0 ?
                                        <div style={{ marginTop: '2rem' }}>
                                            <h6 className="searchResultCategory" onClick={() => this.expandSection('eCollapse', this.state.eCollapse === true ? false : true)}>
                                                <i className={ localStorage.LANG=='ar'? "fa fa-caret-down iconHover2_ar" : "fa fa-caret-down iconHover2" } ></i>
                                {t('SBL')}
                                </h6>
                                            {
                                                eCollapse === true ?
                                                    exams.map(result => (
                                                        <div className="searchResultsMainDiv" onClick={() => this.redirectToResult('/sbl?id=' + result.id)}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '95%' }}>
                                                                            <text className="searchResultsTitles">{result.examName}</text>
                                                                            <br />
                                                                            <text className="searchResultsContents">{result.description}</text>
                                                                        </td>
                                                                        <td style={{ width: '5%', textAlign: 'center' }}>
                                                                            <i
                                                                                style={{
                                                                                    marginTop: '0.8rem',
                                                                                    color: "#2e2e2e",
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '0.9rem'
                                                                                }}
                                                                                className={localStorage.LANG=='ar'? "fa fa-chevron-left iconHover" : "fa fa-chevron-right iconHover"}
                                                                                uk-tooltip={t('go_to_result')}
                                                                                onClick={() => this.redirectToResult('/sbl?id=' + result.id)}
                                                                            ></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                        </div>
                                        : null
                                }


                                {
                                    resources.length > 0 ?
                                        <div style={{ marginTop: '2rem' }}>
                                            <h6 className="searchResultCategory" onClick={() => this.expandSection('rCollapse', this.state.rCollapse === true ? false : true)}>
                                                <i className={ localStorage.LANG=='ar'? "fa fa-caret-down iconHover2_ar" : "fa fa-caret-down iconHover2" } ></i>
                                {t('resources_tab')}
                                </h6>
                                            {
                                                rCollapse === true ?
                                                    resources.map(result => (
                                                        <div className="searchResultsMainDiv" onClick={() => result.url === "" ? this.redirectToResult('/single_resource?id=' + result.id) : this.redirectToExternalSite(result.url)}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '95%' }}>
                                                                            <text className="searchResultsTitles">{result.resourceTitle}</text>
                                                                            <br />
                                                                            <text className="searchResultsContents">{result.description}</text>
                                                                        </td>
                                                                        <td style={{ width: '5%', textAlign: 'center' }}>
                                                                            <i
                                                                                style={{
                                                                                    marginTop: '0.8rem',
                                                                                    color: "#2e2e2e",
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '0.9rem'
                                                                                }}
                                                                                className={localStorage.LANG=='ar'? "fa fa-chevron-left iconHover" : "fa fa-chevron-right iconHover"}
                                                                                uk-tooltip={t('go_to_result')}
                                                                                onClick={() => result.url === "" ? this.redirectToResult('/single_resource?id=' + result.id) : this.redirectToExternalSite(result.url)}
                                                                            ></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                        </div>
                                        : null
                                }

                                {
                                    news.length > 0 ?
                                        <div style={{ marginTop: '2rem' }}>
                                            <h6 className="searchResultCategory" onClick={() => this.expandSection('nCollapse', this.state.nCollapse === true ? false : true)}>
                                                <i className={ localStorage.LANG=='ar'? "fa fa-caret-down iconHover2_ar" : "fa fa-caret-down iconHover2" } ></i>
                                {t('news_tab')}
                                </h6>
                                            {
                                                nCollapse === true ?
                                                    news.map(result => (
                                                        <div className="searchResultsMainDiv" onClick={() => result.url === "" ? this.redirectToResult('/a_news?id=' + result.id) : this.redirectToExternalSite(result.url)}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '95%' }}>
                                                                            <text className="searchResultsTitles">{result.newsTitle}</text>
                                                                            {/* <br />
                                                                    <text className="searchResultsContents">{result.newsHelperText}</text> */}
                                                                        </td>
                                                                        <td style={{ width: '5%', textAlign: 'center' }}>
                                                                            {
                                                                                result.url != "" ?
                                                                                    <i
                                                                                        style={{
                                                                                            marginTop: '0.8rem',
                                                                                            color: "#2e2e2e",
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '0.9rem'
                                                                                        }}
                                                                                        className={localStorage.LANG=='ar'? "fa fa-chevron-left iconHover" : "fa fa-chevron-right iconHover"}
                                                                                        uk-tooltip={t('go_to_result')}
                                                                                        onClick={() => this.openUrl(result.url)}
                                                                                    ></i>
                                                                                    :
                                                                                    <i
                                                                                        style={{
                                                                                            marginTop: '0.8rem',
                                                                                            color: "#2e2e2e",
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '0.9rem'
                                                                                        }}
                                                                                        className={localStorage.LANG=='ar'? "fa fa-chevron-left iconHover" : "fa fa-chevron-right iconHover"}
                                                                                        uk-tooltip={t('go_to_result')}
                                                                                        onClick={() => result.url === "" ? this.redirectToResult('/a_news?id=' + result.id) : this.redirectToExternalSite(result.url)}
                                                                                    ></i>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    ))
                                                    : null
                                            }
                                        </div>
                                        : null
                                }

                                {
                                    galleries.length > 0 ?
                                        <div style={{ marginTop: '2rem' }}>
                                            <h6 className="searchResultCategory" onClick={() => this.expandSection('gCollapse', this.state.gCollapse === true ? false : true)}>
                                                <i className={ localStorage.LANG=='ar'? "fa fa-caret-down iconHover2_ar" : "fa fa-caret-down iconHover2" } ></i>
                                {t('gallery_tab')}
                                </h6>
                                            {
                                                gCollapse === true ?
                                                    galleries.map(result => (
                                                        <div className="searchResultsMainDiv" onClick={() => this.redirectToResult('/galleryImages?id=' + result.id)}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '95%' }}>
                                                                            <text className="searchResultsTitles">{result.galleryTitle}</text>
                                                                            <br />
                                                                            <text className="searchResultsContents">{result.galleryHelperText}</text>
                                                                        </td>
                                                                        <td style={{ width: '5%', textAlign: 'center' }}>
                                                                            <i
                                                                                style={{
                                                                                    marginTop: '0.8rem',
                                                                                    color: "#2e2e2e",
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '0.9rem'
                                                                                }}
                                                                                className={localStorage.LANG=='ar'? "fa fa-chevron-left iconHover" : "fa fa-chevron-right iconHover"}
                                                                                uk-tooltip={t('go_to_result')}
                                                                                onClick={() => this.redirectToResult('/galleryImages?id=' + result.id)}
                                                                            ></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                        </div>
                                        : null
                                }
                                {
                                    !resultCount > 0 ?
                                        <NoDataMessage message={t('no_found_result')} fontSize="0.9rem" />
                                        : null
                                }
                            </div>
                            : null
                    }
                </div>
                <Footer language={localStorage.LANG}/>
            </div>
        );
    }
}

export default withRouter(withApollo(withNamespaces()(Exam)));

import React from 'react';
import Page from './page.js';
import props from './../../screens/admin/Image/Images';
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import Moment from 'react-moment';

class Certificate extends React.Component {
    constructor(props) {
        super(props);

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = '-';

        this.state = {
            currentDate: `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
        }
    }

    componentDidMount() {

    }


    render() {

        const { id, userName, moduleName, organization, completed_date } = this.props;
        // const { currentDate,completed_date  } = this.state;
        // console.log(moment(completed_date).format("YYYY-MM-DD HH:mm:ss A"));

        var localLocale = moment(this.state.completed_date);
        moment.locale('es');
        localLocale.locale(false);

        return (
            <Page singleMode={true} id={id}>
                {/* className="certificate" */}
                <div className={localStorage.LANG=="ar"? "certificate_ar" :localStorage.LANG=="fr"? "certificate_fr": localStorage.LANG=="sp"? "certificate_sp": "certificate"} >
                    {
                        localStorage.LANG == 'ar' ? (
                            <div className="certifi-cantainer_ar">
                                <div style={{paddingRight:"6.9rem"}}  className="certifi-user-name_ar">
                                    <p>{userName}</p>
                                </div>
                                <br />
                                <div style={{paddingRight:"6.9rem"}}  className="certifi-compelte-course_ar" >
                                    <p>{organization}</p>
                                </div>
                                <div className="certifi-compelte-Date_ar">
                                    {/* {moment(completed_date).format("Do MMMM YYYY")} */}
                                    {/* {moment(completed_date).format("DD-MM-YYYY")} */}
                                    {/* <Moment format="Do MMMM YYYY">{completed_date}</Moment> */}
                                    {moment(completed_date, "YYYY-MM-DD").format("YYYY-MM-DD")}
                                </div>
                            </div>
                        ) : (
                            <div className={localStorage.LANG=='sp'?"certifi-cantainer_sp": "certifi-cantainer"} >
                                <div className="certifi-user-name">
                                    <p>{userName}</p>
                                </div>
                                <div className="certifi-compelte-course" >
                                    <p>{organization}</p>
                                </div>
                                <div className="certifi-compelte-Date">
                                    {/* {moment(completed_date).format("DD-MM-YYYY")} */}
                                    {localStorage.LANG=="sp"?(
                                          <div class="">{localLocale.format('LL')}</div>
                                    ):(
                                        <Moment locale={localStorage.LANG=="fr" ? "Fr" : "en-gb"}  format="Do MMMM YYYY">{completed_date}</Moment>
                                    )
                                        
                                    }
                                  

                                    {/* <Moment format="DD-MM-YYYY" withTitle>
                            {completed_date}
                        </Moment> */}
                                </div>
                            </div>
                        )
                    }
                </div>
            </Page>
            /* <div></div> */
        )
    }
}


export default Certificate;
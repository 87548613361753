import {
  LOGGING_USER,
  FOUND_RESTRICTED,
  LOGIN_SUCCESS,
  UPLOAD_API_URL
} from "../constants/index";
import { NEW_NOTIFICATION_SUBSCRIPTION, GET_ALL_NOTIFICATIONS } from "../queries/common_queries";

export function loginFormMiddleware({ dispatch }) {
  return function (next) {
    return function (action) {
      if (action.type === LOGGING_USER) {
        // console.log(action);
        // console.log(action.user.email);
        switch (action.user.email) {
          case "loading":
            return dispatch({ type: FOUND_RESTRICTED, payload: { form_state: 'loading', title: '', message: '' } });
          case "error":
            return dispatch({ type: FOUND_RESTRICTED, payload: { form_state: 'error', title: 'Error', message: 'Please include a valid username and a password' } });
          case "success":
            return dispatch({ type: FOUND_RESTRICTED, payload: { form_state: 'success', title: '', message: '' } });
          default:
            return next(action);
        }
      }
      return next(action);
    };
  };
}

export function formatDate(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [  day, mnth, date.getFullYear(),].join("-");
}

export const imageUploader = async (formData) => {
  fetch(`${UPLOAD_API_URL}/image-upload`, {
    method: 'POST',
    body: formData
  }).then(res => {
    console.log(res)
      return res;
    })
    .catch(err => {
      return err;
    })
}

export async function  _generate_email(url='',personalizations={}) {
  const newURL = 'https://seippf.org/mail/sendgrid.php'  //Added this url since the previous one stopped working
  const response = await fetch(newURL, {
    method: 'POST', 
    mode: 'cors', 
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'       
    },
    redirect: 'follow',
    referrer: 'no-referrer', 
    body: JSON.stringify({"personalizations": personalizations})
  });
  return await response.json();
}


export async function  _getnotifications(client) {
  // console.log(client);

  const result = await client.query({
    query: GET_ALL_NOTIFICATIONS,
    variables: {
      lang: localStorage.LANG,
    },
    fetchPolicy: 'network-only'
});
// console.log(result.data.getNotifications);
return result.data.getNotifications;
  // const response = await fetch(url, {
  //   method: 'POST', 
  //   mode: 'cors', 
  //   cache: 'no-cache',
  //   credentials: 'same-origin',
  //   headers: {
  //     'Content-Type': 'application/json'       
  //   },
  //   redirect: 'follow',
  //   referrer: 'no-referrer', 
  //   body: JSON.stringify({"personalizations": personalizations})
  // });
  // return await response.json();
}
import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from "../../../actions/user-actions";
import ConfirmationModal from "../../../components/adminModals/confirmationModal";
//queries
import { CREATE_MODULE, UPDATE_MODULE } from "../../../queries/admin_queries";
import { GET_MODULE } from "../../../queries/common_queries";

//constants
import { UPLOAD_API_URL } from "../../../constants/index";

//redux-form
import { Field, FieldArray, reduxForm } from "redux-form";

//middleware
import { imageUploader } from "../../../middleware/index";

//Image Uploader
import Images from "../Image/Images";
import fullSizeIcon from "@ckeditor/ckeditor5-core/theme/icons/object-center.svg";
import sideIcon from "@ckeditor/ckeditor5-core/theme/icons/object-right.svg";

import { Button } from "react-uikit";

import { Input, Popup, Grid } from "semantic-ui-react";

import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";

import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import CKUploadAdapter from "../../../middleware/ck-file-upload-adapter";

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    translatingLanguages: state.custom_reducer.translatingLanguages,
    languageKeys: state.custom_reducer.languageKeys,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
  };
};

class AddModule extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");

    if (id == null) {
      this.state = {
        data: null,
        moduleId: "",
        moduleTitle: "",
        bannerIconUrl: "",
        moduleHelperText: "",
        moduleOverview: "",
        isActive: true,
        formAction: "ADD",
        loading: false,
        image: "",
        formData: "",
        file: "",
        imagePreviewUrl: "",
        uploads: [],
        fileUrl: null,
        fileRef: null,
        tempFile: "",
        isUploading: false,

        formObjectivesData: "",
        objectiveFileRef: null,
        isObjectivesUploading: false,
        objectiveImage: "",
        objectivesImageUrl: "",

        formBannerImageData: "",
        bannerImage: "",
        bannerImageFileRef: null,
        isBannerImageUploading: false,
        validation_err: false,
        // objectives: [],

        supportingLanguages: [],
        supportingLangErrorMsg: "",
        summaries: [],
        learnings: [],

        confirmationModal: false,

      };
    } else {
      this.state = {
        data: null,
        moduleId: id,
        moduleTitle: "",
        bannerIconUrl: "",
        moduleHelperText: "",
        moduleOverview: "",
        isActive: true,
        formAction: "EDIT",
        loading: false,
        getModuleParameter: { moduleId: id, lang: "en" },
        image: "",
        image_file: "",
        formData: "",
        file: "",
        uploads: [],
        fileUrl: null,
        fileRef: null,
        tempFile: "",
        isUploading: false,

        formObjectivesData: "",
        objectiveFileRef: null,
        isObjectivesUploading: false,
        objectiveImage: "",
        objectivesImageUrl: "",

        formBannerImageData: "",
        bannerImage: "",
        bannerImageFileRef: null,
        isBannerImageUploading: false,
        formLanguage: "en",
        validation_err: false,
        // objectives: [],
        supportingLanguages: [],
        supportingLangErrorMsg: "",
        summaries: [],
        learnings: [],

        confirmationModal: false,


      };
    }

    this.handleChange = this.handleChange.bind(this);

    props.setFormStatus({ status: false, title: "", message: "" });
    props.setSuccessStatus({ status: false, title: "", message: "" });
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.state.formAction == "EDIT") {
      this.getSingleModule();
    } else {
      this.setState({ loading: false });
    }
    //supporting languages array
    if (this.state.formAction === "ADD") {
      const supportingLanguages = this.props.translatingLanguages;
      this.setState({
        supportingLanguages: supportingLanguages,
      });
    }
  }

  documentUploadOnClick = (event) => {
    this.setState({
      isUploading: true,
    });
    console.log(this.state.formData);
    this._startDocUpload(this.state.formData);
  };

  documentObjectivesUploadOnClick = (event) => {
    this.setState({
      isObjectivesUploading: true,
    });
    this._startObjectiveDocUpload(this.state.formObjectivesData);
  };

  documentBannerUploadOnClick = (event) => {
    this.setState({
      isBannerImageUploading: true,
    });
    this._startBannerImageUpload(this.state.formBannerImageData);
  };

  documentUploadOnChange = (event) => {
    this.setState({
      formData: event.target.files[0],
    });
  };

  documentObjectivesUploadOnChange = (event) => {
    this.setState({
      formObjectivesData: event.target.files[0],
    });
  };

  documentBannerUploadOnChange = (event) => {
    this.setState({
      formBannerImageData: event.target.files[0],
    });
  };

  _startDocUpload = async (file) => {
    var intDate = Number(new Date());

    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    if (result.status) {
      this.setState({
        isUploading: false,
        fileRef:
          "uploads/" + localStorage.FIRST_NAME + intDate + "/" + file.name,
        image: result.url,
        bannerIconUrl: result.url,
        isUploading: false,
      });
    }
  };

  _startObjectiveDocUpload = async (file) => {
    var intDate = Number(new Date());

    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    if (result.status) {
      this.setState({
        isObjectivesUploading: false,
        objectiveFileRef:
          "uploads/" + localStorage.FIRST_NAME + intDate + "/" + file.name,
        objectivesImageUrl: result.url,
        objectiveImage: result.url,
      });
    }
  };

  _startBannerImageUpload = async (file) => {
    var intDate = Number(new Date());

    const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
    if (result.status) {
      this.setState({
        isBannerImageUploading: false,
        bannerImageFileRef:
          "uploads/" + localStorage.FIRST_NAME + intDate + "/" + file.name,
        bannerImage: result.url,
      });
    }
  };

  _deleteFile = async (path) => {
    console.log(path);
    const deleteRef = await deleteFile(path);
    this.setState({ image: "", bannerIconUrl: "" });
    return deleteRef;
  };

  _deleteObjectivesFile = async (path) => {
    const deleteRef = await deleteFile(path);
    this.setState({ objectiveImage: "", objectivesImageUrl: "" });
    return deleteRef;
  };

  _deleteBannerFile = async (path) => {
    const deleteRef = await deleteFile(path);
    this.setState({ bannerImage: "" });
    return deleteRef;
  };

  getSingleModule() {
    this.get_module(this.state.getModuleParameter).then((result) => {
      console.log(result.learningSummary)
      const summaries = result.learningSummary != null ? result.learningSummary.summaries : [];
      const learnings = result.objectives != null ? result.objectives.learnings : [];

      console.log(result);
      this.setState({
        moduleId: result.id,
        moduleTitle: result.moduleTitle,
        moduleHelperText: result.moduleHelperText,
        bannerIconUrl: result.bannerIconUrl,
        image: result.bannerIconUrl,
        moduleOverview: result.moduleOverview,
        data: result.content,
        loading: false,
        objectiveImage: result.objectivesImageUrl,
        objectivesImageUrl: result.objectivesImageUrl,
        bannerImage: result.bannerImage,
        learnings: learnings,
        summaries: summaries,
      });
    });
  }

  onEditorChange(event, editor, summaries) {
    console.log(summaries);
    this.setState({
      summaries: summaries,
    });
    // console.log(data)
  }

  handleChange(event) {
    console.log(event);
    this.setState({ [event.target.id]: event.target.value });
  }

  // handleValidateSubmit = async (event) => {
  //   console.log(event);
  //   this.setState({ validation_err: false });
  //   var quiz = event;
  //   var err = false;

  //   if (this.isEmpty(quiz)) {
  //     err = true;
  //     this.setState({ loading: false, validation_err: true });
  //     this.props.setFormStatus({
  //       status: true,
  //       title: "Oops!",
  //       message: "Please enter at least one objective.",
  //     });
  //   } else {
  //     this.setState({ validation_err: true });
  //     quiz.learnings.map((learningObj) => {
  //       if (learningObj == undefined || this.isEmpty(learningObj)) {
  //         err = true;
  //         this.setState({ loading: false, validation_err: true });
  //         this.props.setFormStatus({
  //           status: true,
  //           title: "Oops!",
  //           message: "Please provide the objective content.",
  //         });
  //       } else {
  //         err = false;
  //         this.setState({ loading: false, validation_err: false });
  //         this.props.setFormStatus({ status: false, title: "", message: "" });
  //       }
  //     });
  //   }
  // };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleFormSubmit = (event) => {
    console.log(event);
    var err = false;
    this.props.setFormStatus({ status: false, title: "", message: "" });
    this.props.setSuccessStatus({ status: false, title: "", message: "" });
    this.setState({ loading: true });

    const {
      moduleTitle,
      bannerIconUrl,
      moduleHelperText,
      moduleOverview,
      moduleId,
      objectives,
      summaries
    } = this.state;
    if (moduleTitle == "") {
      err = true;
      this.setState({ loading: false, validation_err: true });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please provide a title for the module.",
      });
    } else if (bannerIconUrl == "") {
      err = true;
      this.setState({ loading: false, validation_err: true });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please upload a banner image for the module.",
      });
    } else if (moduleHelperText == "") {
      err = true;
      this.setState({ loading: false, validation_err: true });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: "Please add helper text.",
      });
    }
    else {
      if (this.state.formAction == "ADD") {
        this.createModule().then((result) => {
          this.props.setSuccessStatus({
            status: true,
            title: "",
            message: "Module created successfully!",
          });

          this.setState({
            data: null,
            loading: false,
            moduleTitle: "",
            bannerIconUrl: "",
            moduleHelperText: "",
            moduleOverview: "",
            learnings: [],
            summaries: [],
            supportingLanguages: this.props.translatingLanguages,
          });
        })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message:
                <div>
                  An error occurred while trying to create the module!
                <br />
                Please try again.
              </div>
            });
          });
      } else if (this.state.formAction == "EDIT") {
        this.get_module(this.state.getModuleParameter).then((result) => {
          this.updateModule().then((result) => {
            console.log(result
            )
            this.getSingleModule();
            this.props.setSuccessStatus({
              status: true,
              title: "",
              message: "Module updated successfully!",
            });
          })
            .catch((error) => {
              console.log(error);
              this.setState({ loading: false });
              this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                  <div>
                    An error occurred while trying to update the module!
                  <br />
                  Please try again.
                </div>
              });
            });
        });
      }
    }
  };

  renderField = ({ input, label, index, type, meta: { touched, error } }) => (
    <div>
      <label>
        {index} ){" "}
        <input
          className="uk-input uk-width-xxlarge"
          {...input}
          type={type}
          placeholder={label}
        />
      </label>
    </div>
  );

  renderLearnings = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div>
      {console.log(fields)}

      {fields
        ? fields.length > 0
          ? fields.map((learningObj, index) => (
            <div key={index}>
              <div className="">
                <a className="uk-button" onClick={() => fields.remove(index)}>
                  <i className="fas fa-minus-circle"></i> Objective{" "}
                </a>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Field
                  name={`${"learnings[" + index + "]"}.learningObj`}
                  type="text"
                  props={{
                    index: index + 1,
                    value: learningObj.learningObj,
                  }}
                  component={this.renderField}
                  label=" Objective"
                />
              </div>
              <hr />
            </div>
          ))
          : null
        : null}

      <div>
        <Button
          className="uk-button uk-button-default uk-margin"
          type="button"
          style={{ marginLeft: "22px" }}
          onClick={() => fields.push({})}
        >
          <i className="fas fa-plus-circle"></i>
          <span style={{ paddingLeft: "5px" }}>Learning objective(s)</span>
        </Button>
      </div>
    </div>
  );

  createModule = async () => {
    const { moduleTitle, bannerIconUrl, moduleHelperText, moduleOverview, data, isActive, objectivesImageUrl, bannerImage, summaries, learnings, } = this.state;
    const content = data;
    const objectives = { learnings: learnings };
    var learningSummary = { summaries: summaries };
    const supportingLangs = { languages: [] };
    const tempArr = this.state.supportingLanguages.filter(
      (obj) => obj.supporting === true
    );
    if (tempArr.length > 0) {
      tempArr.map((lang) =>
        lang.key != "en" ? supportingLangs.languages.push(lang.key) : null
      );
    }
    console.log(supportingLangs);
    const result = await this.props.client.mutate({
      mutation: CREATE_MODULE,
      variables: { moduleTitle, moduleHelperText, bannerIconUrl, moduleOverview, content, isActive, objectivesImageUrl, bannerImage, objectives, supportingLangs, learningSummary },
    });

    return result.data.createModule;
  };

  updateModule = async () => {
    // console.log("UPDATE")
    const { moduleId, moduleTitle, bannerIconUrl, moduleHelperText, moduleOverview, data, isActive, objectivesImageUrl, bannerImage, formLanguage, summaries, learnings, languageEnabled } = this.state;
    const content = data;
    var learningSummary = { summaries: summaries };
    const objectives = { learnings: learnings }

    const result = await this.props.client.mutate({
      mutation: UPDATE_MODULE,
      variables: { moduleId, moduleTitle, moduleHelperText, bannerIconUrl, moduleOverview, content, isActive, objectivesImageUrl, bannerImage, formLanguage, objectives, learningSummary, languageEnabled },
    });
    return result.data.updateModule;
  };

  get_module = async (getModuleParameter) => {
    console.log(getModuleParameter);
    const result = await this.props.client.query({
      query: GET_MODULE,
      variables: getModuleParameter,
      // fetchPolicy: 'cache-first'
      fetchPolicy: "network-only",
    });
    return result.data.getModule;
  };

  breadcrumbredirect = (path) => {
    if (path == "HOME") {
      this.props.history.push("/admin_home");
    } else if (path == "MANAGEMODULE") {
      this.props.history.push("/admin_manage_modules");
    } else {
      return false;
    }
  };

  changeFormLanguage = (language) => {
    const id = this.state.moduleId;
    var tempModuleParameter = this.state.getModuleParameter;
    tempModuleParameter.lang = language;
    // this.setState({ getModuleParameter: { moduleId: id, lang: language } });

    

    this.get_module(tempModuleParameter).then((result) => {
      // const summaries = result.learningSummary != null ? result.learningSummary.summaries : [];
      // const learnings = result.objectives != null ? result.objectives.learnings : [];

      const summaries = result.learningSummary ? result.learningSummary.summaries ? result.learningSummary.summaries.length > 0 ? result.learningSummary.summaries : [] : [] : [];
      const learnings = result.objectives ? result.objectives.learnings ? result.objectives.learnings.length > 0 ? result.objectives.learnings : [] : [] : [];

      this.setState({
        moduleTitle: result.moduleTitle,
        moduleHelperText: result.moduleHelperText,
        bannerIconUrl: result.bannerIconUrl,
        image: result.bannerIconUrl,
        moduleOverview: result.moduleOverview,
        data: result.content,
        loading: false,
        objectiveImage: result.objectivesImageUrl,
        objectivesImageUrl: result.objectivesImageUrl,
        bannerImage: result.bannerImage,
        getModuleParameter: { moduleId: id, lang: language },
        formLanguage: language,
        summaries: summaries,
        learnings: learnings,
        languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true
      });
    });
  };

  handleLanguageClick = (lang, supported) => {
    if (lang != "English") {
      const tempArray = this.state.supportingLanguages;
      const index = this.state.supportingLanguages.findIndex(
        (obj) => obj.language === lang
      );
      tempArray[index].supporting = supported;
      this.setState({
        supportingLanguages: tempArray,
        supportingLangErrorMsg: "",
      });
    } else {
      this.setState({
        supportingLangErrorMsg: "*Module must support English Language",
      });
    }
  };

  addSummary = () => {
    var summaryArray = this.state.summaries;
    summaryArray.push({ learningSum: "", summary_lists: [] });
    this.setState({ summaries: summaryArray });
  }

  addSummary_list = (index) => {
    var summaryArray = this.state.summaries;
    summaryArray[index].summary_lists.push({ list: "" });
    this.setState({ summaries: summaryArray });
  }

  removeSummary = (index) => {
    var summaryArray = this.state.summaries;
    summaryArray.splice(index, 1);
    this.setState({ summaries: summaryArray });
  }

  removeSummary_list = (qIndex, aIndex) => {
    var tempArray = this.state.summaries;
    tempArray[qIndex].summary_lists.splice(aIndex, 1);
    this.setState({ summaries: tempArray });
  }

  handleSummary_listChange = (e, data, qIndex, aIndex) => {
    var summaryArray = this.state.summaries;
    summaryArray[qIndex].summary_lists[aIndex].list = e.target.value;
    this.setState({ summaries: summaryArray });
  }

  handleSummaryChange = (e, editor, data, index) => {
    console.log(data)
    var summaryArray = this.state.summaries;
    // summaryArray[index].learningSum = e.target.value;
    summaryArray[index].learningSum = data;

    this.setState({ summaries: summaryArray });
  }

  addObjective = () => {
    var ObjArray = this.state.learnings;
    ObjArray.push({ learningObj: "" });
    this.setState({ learnings: ObjArray });
  }

  removeObjective = (index) => {
    var ObjArray = this.state.learnings;
    ObjArray.splice(index, 1);
    this.setState({ learnings: ObjArray });
  }

  handleObjectiveChange = (e, data, index) => {
    console.log(e.target.value)
    var ObjArray = this.state.learnings;
    ObjArray[index].learningObj = e.target.value;
    this.setState({ learnings: ObjArray });
  }

  handleCheckBoxChange = (status) => {
    this.setState({ languageEnabled: status });
  }

  // videoUploadOnChange = (e) => {
  //   console.log(e.target.files);
  //   console.log(e.target.files[0].type);
  //   var files = [];
  //   var type = [];
  //   var splitArray = [];
  //   files = e.target.files;
  //   if(files.length > 0) {
  //     for(let i = 0; i < files.length; i++) {
  //       type = files[i].type;
  //       if(type != '') {
  //         splitArray = type.split('/');
  //         if(splitArray[0] != 'video'){
  //           this.setState({
  //             videoError: '* Unsupported file format',
  //           });
  //         }
  //         else{
  //           this.setState({
  //             videoFiles: e.target.files,
  //             videoError: '',
  //           });
  //         }
  //       }

  //     }
  //   }


  // }

  // handleChangeVideoCaption = (e, index) => {
  //   console.log(e.target.value)
  //   const tempArray = this.state.videos;
  //   tempArray[index].description = e.target.value;
  //   this.setState({
  //     videos: tempArray,
  //   })
  // }

  // removeVideo = (index) => {
  //   console.log("index: ",index)
  //   const tempArray = this.state.videos;
  //   tempArray.splice(index, 1);
  //   this.setState({
  //     videos: tempArray,
  //   })
  // }

  // videoUploadOnClick = async (e) => {
  //   this.setState({isVideoUploading: true})
  //   var videoList = this.state.videos;
  //   var fileinfo = document.forms.namedItem("fileinfo");
  //   console.log(fileinfo);
  //   e.preventDefault();
  //   fetch('http://161.35.207.178:5000/upload', {
  //     method: 'POST',
  //     body: new FormData(fileinfo)
  //   })
  //   .then(response => response.json())
  //   .then(result => {
  //     console.log(result);
  //     if(result.locations){
  //       if(result.locations.length > 0) {
  //         result.locations.map(video => (
  //           videoList.push({url: video.location, description: "" })
  //         ))
  //       }
  //     }
  //     this.setState({
  //       isVideoUploading: false,
  //       videos: videoList
  //     })       
  //   })
  //   .catch(error => {
  //     console.error(error);
  //     this.setState({
  //       isVideoUploading: false
  //     })
  //   });
  // }

  render() {
    const {
      loading,
      formAction,
      image,
      objectiveImage,
      bannerImage,
      isBannerImageUploading,
      objectives,
      supportingLanguages,
      formLanguage,
      learningSummary,
      summaries,
    } = this.state;
    const {
      handleSubmit,
      formErrorStatus,
      formSuccessState,
      translatingLanguages,
      languageKeys
    } = this.props;
    console.log(this.state.summaries)
    return (
      <div className="admin-content-inner">
        <div>
          <ul class="uk-breadcrumb">
            <li>
              <a onClick={() => this.breadcrumbredirect("HOME")}>Home</a>
            </li>
            <li>
              <a onClick={() => this.breadcrumbredirect("MANAGEMODULE")}>
                Manage Modules
              </a>
            </li>
            {formAction == "ADD" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("ADDMODULE")}>
                  Add Module
                </a>
              </li>
            ) : formAction == "EDIT" ? (
              <li>
                <a onClick={() => this.breadcrumbredirect("EDITMODULE")}>
                  Edit Modules
                </a>
              </li>
            ) : null}
          </ul>
        </div>

        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-th-list icon-large uk-margin-right"></i>

          {formAction == "ADD" ? (
            <h4 className="uk-margin-remove"> Add New Module </h4>
          ) : formAction == "EDIT" ? (
            <h4 className="uk-margin-remove"> Edit Module </h4>
          ) : null}
        </div>

        <div className="uk-margin-medium">
          <form className="ui form">
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="fas fa-th-list uk-margin-small-right"></i>{" "}
                    Module Details
                    {
                      formAction == "EDIT" ?
                        translatingLanguages.length > 0 ?
                          <div className="formLanguageSwitcherDiv">
                            {
                              translatingLanguages.map(languageTab => (
                                <span circular icon="download" className={formLanguage === languageTab.key ? "formLanguageSwitcherActive" : "formLanguageSwitcher"} onClick={() => this.changeFormLanguage(languageTab.key)}>
                                  {languageTab.language}
                                </span>
                              ))
                            }
                          </div>
                          : null
                        : null
                    }
                    {/* {formAction == "EDIT" ? (
                      <div className="formLanguageSwitcherDiv">
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "en"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("en")}
                        >
                          English
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "fr"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("fr")}
                        >
                          French
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "ar"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("ar")}
                        >
                          Arabic
                        </span>
                      </div>
                    ) : null} */}
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">
                    
                  {
                      formAction === "EDIT" ?
                      this.state.formLanguage === 'en' ? null
                      :
                        <div className="disableLanguageVersionDiv">
                          <input type="checkbox" id="languageEnabled" name="languageEnabled" checked={this.state.languageEnabled} onChange={() => this.handleCheckBoxChange(!this.state.languageEnabled)} />
                          <label>{languageKeys[this.state.formLanguage]} version enabled</label>
                        </div>
                      : null
                    }

                    <div className="uk-form-label adminSectionTextFieldLabel"> Module Title </div>
                    {/* <i style={{color:"#db2828"}} className="icon_asterisk" >*</i> */}
                    <input
                      value={this.state.moduleTitle || ""}
                      className="uk-input uk-form-width-huge"
                      id="moduleTitle"
                      name="moduleTitle"
                      placeholder="Title"
                      type="text"
                      onChange={this.handleChange}
                    />

                    {formAction === "ADD" ? (
                      <div>
                        <div className="uk-form-label">
                          {" "}
                          <text>Supporting Languages</text>
                          <text className="langErrorMessage">
                            {this.state.supportingLangErrorMsg}
                          </text>{" "}
                        </div>
                        {supportingLanguages
                          ? supportingLanguages.length > 0
                            ? supportingLanguages.map((lang) => (
                              <span
                                className={
                                  lang.supporting === true
                                    ? "supportingLanguageActive"
                                    : "supportingLanguage"
                                }
                                onClick={() =>
                                  this.handleLanguageClick(
                                    lang.language,
                                    !lang.supporting
                                  )
                                }
                              >
                                {lang.language}
                              </span>
                            ))
                            : null
                          : null}
                      </div>
                    ) :
                    null
                    }

                    <div className="uk-form-label"> Upload Image </div>

                    <div>
                      <div className="">
                        {/* File Component */}
                        {this.state.image !== null &&
                          this.state.image !== undefined &&
                          this.state.image !== "" ? (
                            <div className="file-upload-group uk-card uk-card-default uk-card-body">
                              <div className="uk-text-center uk-grid ">
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="download"
                                    class="uk-button uk-button-primary uk-button-small"
                                    onClick={() => {
                                      window.open(this.state.image);
                                    }}
                                  >
                                    Download
                                </span>
                                </div>
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="trash alternate outline"
                                    class="uk-button uk-button-secondary uk-button-small"
                                    onClick={() =>
                                      //   {
                                      //   this._deleteFile(this.state.image).then(
                                      //     (result) => {
                                      //       this.setState({
                                      //         image: null,
                                      //         fileRef: null,
                                      //       });
                                      //     }
                                      //   );
                                      // }
                                      this.setState({ confirmationModal: true })
                                    }
                                  >
                                    Delete
                                </span>
                                </div>
                              </div>
                              <br />
                              <Images
                                image={image}
                                removeImage={this.removeImage}
                              />
                            </div>
                          ) : (
                            <div className="uk-text-center uk-grid">
                              <div className="uk-width-auto@m">
                                <Input
                                  onChange={this.documentUploadOnChange}
                                  name="upload"
                                  id="upload"
                                  className="uk-input uk-form-width-huge"
                                  placeholder="Upload here"
                                  type="file"
                                  multiple
                                />
                              </div>
                              <div className="uk-width-auto@m">
                                <span
                                  class="uk-button uk-button-primary"
                                  onClick={this.documentUploadOnClick}
                                >
                                  {loading ? (
                                    <span
                                      data-uk-spinner=""
                                      style={{ color: "#o6a7e3" }}
                                    />
                                  ) : (
                                      "Upload Image"
                                    )}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* File Component */}
                      </div>
                      <br />
                      {this.state.isUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                              Just a moment… <br />
                            We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>

                    <div className="uk-form-label"> Module Banner Image </div>
                    <div>
                      <div className="">
                        {/* File Component */}
                        {this.state.bannerImage !== null &&
                          this.state.bannerImage !== undefined &&
                          this.state.bannerImage !== "" ? (
                            <div className="file-upload-group uk-card uk-card-default uk-card-body">
                              <div className="uk-text-center uk-grid ">
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="download"
                                    class="uk-button uk-button-primary uk-button-small"
                                    onClick={() => {
                                      window.open(this.state.bannerImage);
                                    }}
                                  >
                                    Download
                                </span>
                                </div>
                                <div className="uk-width-auto@m">
                                  <span
                                    circular
                                    icon="trash alternate outline"
                                    class="uk-button uk-button-secondary uk-button-small"
                                    onClick={() =>
                                      //   {
                                      //   this._deleteBannerFile(
                                      //     this.state.bannerImage
                                      //   ).then((result) => {
                                      //     this.setState({
                                      //       bannerImage: null,
                                      //       bannerImageFileRef: null,
                                      //     });
                                      //   });
                                      // }
                                      this.setState({ confirmationModal3: true })
                                    }
                                  >
                                    Delete
                                </span>
                                </div>
                              </div>
                              <br />
                              <Images
                                image={bannerImage}
                                removeImage={this.removeImage}
                              />
                            </div>
                          ) : (
                            <div className="uk-text-center uk-grid">
                              <div className="uk-width-auto@m">
                                <Input
                                  onChange={this.documentBannerUploadOnChange}
                                  name="upload"
                                  id="upload"
                                  className="uk-input uk-form-width-huge"
                                  placeholder="Upload here"
                                  type="file"
                                  multiple
                                />
                              </div>
                              <div className="uk-width-auto@m">
                                <span
                                  class="uk-button uk-button-primary"
                                  onClick={this.documentBannerUploadOnClick}
                                >
                                  {loading ? (
                                    <span
                                      data-uk-spinner=""
                                      style={{ color: "#o6a7e3" }}
                                    />
                                  ) : (
                                      "Upload Image"
                                    )}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* File Component */}
                      </div>
                      <br />
                      {this.state.isBannerImageUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                              Just a moment… <br />
                            We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>

                    <div className="uk-form-label"> Module Helper Text </div>
                    <textarea
                      value={this.state.moduleHelperText || ""}
                      className="uk-textarea"
                      rows="5"
                      id="moduleHelperText"
                      name="moduleHelperText"
                      onChange={this.handleChange}
                    ></textarea>
                    <div className="uk-form-label"> Module Duration </div>
                    <input
                      value={this.state.moduleOverview || ""}
                      className="uk-input uk-form-width-huge"
                      id="moduleOverview"
                      name="moduleOverview"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </div>

                  {/* {formAction == "ADD" ? ( */}
                  {/* <FieldArray
                      name="learnings"
                      component={this.renderLearnings}
                    /> */}


                  <div>
                    {
                      this.state.learnings ?
                        this.state.learnings.length > 0 ?
                          this.state.learnings.map((learningObj, index) => (
                            <div>
                              <div className="">
                                <a className="uk-button" onClick={() => this.removeObjective(index)}>
                                  <i className="fas fa-minus-circle"></i> Objective{" "}
                                </a>
                              </div>

                              <div style={{ marginLeft: "20px" }}>
                                <label> {index + 1} ) </label>
                                <input class="ckeditor" className="uk-input uk-width-xxlarge" id="learningObj" name="learningObj" placeholder="Objective" type="text" value={learningObj.learningObj} onChange={(e, data) => this.handleObjectiveChange(e, data, index)} />

                              </div>
                            </div>
                          ))
                          : null
                        : null
                    }

                    <Button
                      className="uk-button uk-button-default uk-margin adminSectionOtherButtons1"
                      type="button"
                      style={{ marginLeft: "22px" }}
                      onClick={() => this.addObjective()}
                    >
                      <i className="fas fa-plus-circle"></i>
                      <span style={{ paddingLeft: "5px" }}>Learning objective(s) </span>
                    </Button>
                  </div>

                  <div>
                    {
                      summaries ?
                        summaries.length > 0 ?
                          summaries.map((learningSum, index) => (
                            <div>
                              <div className="">
                                <a className="uk-button" onClick={() => this.removeSummary(index)}>
                                  <i className="fas fa-minus-circle"></i> Summary{" "}
                                </a>
                              </div>

                              <div style={{ marginLeft: "20px" }}>
                                <label> {index + 1} ) </label>
                                {/* class for input color-> uk-text-warning   */}
                                {/* <input className="uk-input uk-width-xxlarge" id="learningSum" name="learningSum" placeholder="Summary" type="text" value={learningSum.learningSum} onChange={(e, data) => this.handleSummaryChange(e, data, index)} /> */}
                                <CKEditor
                                  onInit={(editor) => {
                                    editor.ui
                                      .getEditableElement()
                                      .parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement(),
                                        (editor.plugins.get(
                                          "FileRepository"
                                        ).createUploadAdapter = function (loader) {
                                          return new CKUploadAdapter(loader);
                                        })
                                      );
                                  }}
                                  handleSummaryChange
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.handleSummaryChange(event, editor, data, index);
                                  }}
                                  editor={DecoupledEditor}
                                  data={learningSum.learningSum}
                                  config={{
                                    heading: {
                                      options: [
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                      ]
                                    },
                                    fontSize: {
                                      options: [
                                        'default',
                                        8,
                                        9,
                                        10,
                                        11,
                                        12,
                                        14,

                                        16,
                                        18,
                                        20,
                                        22,
                                        24,
                                        26,
                                        28,
                                        36,
                                        48,
                                        72
                                      ]
                                    },
                                    fontFamily: {
                                      options: [
                                        'default',
                                        'Arial',
                                        'courier New',
                                        'Georgia',
                                        'Lucida Sans Unicode',
                                        'Tahoma',
                                        'Times New Roman',
                                        'Trebuchet MS',
                                        'Verdana',
                                        'Calibri Light',
                                        'Calibri (Body)',
                                        'Bahnschrift Light',
                                        'Agency FB',
                                        'Algerian',
                                        'Arial Black',
                                        'Arial Narrow',
                                        'Arial Rounded MT Bold',
                                        'Bahnschrift',
                                        'Bahnschrift Condensed',
                                        'Bahnschrift Light Condensed',
                                        'Bahnschrift SemiBold SemiConden',
                                        'Bahnschrift SemiCondensed',
                                        'Sylfaen', 'Symbol', "Tahoma", 'Tempus Sans ITC', 'Times New Roman', 'Trebuchet MS', 'Tw Cen MT', 'Tw Cen MT Condensed', 'Tw cen MT Condensed Extra Bold', 'Verdana', ' Viner Hand ITC', 'Webdings ', 'Wide Latin', 'Wingdings', 'Wingdings 2', 'Wingdings 3', 'Yu Gothic ', 'Yu Gothic Light', 'Yu Gothic Medium', 'Yu Gothic UI',
                                        'Yu Gothic UI Light', 'Yu Gothic UI Semibold', 'Yu Gothic UI Semilight', 'Rockwell Extra Bold', 'Sript MT Bold', ' Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Black', 'Segoe UI Emoji', 'Segoe UI Historic', 'Segoe UI Light', ' Segoe UI Semibold', 'Segoe UI Semilight', 'Segoe UI Symbol', 'SHOWCARD GOTHIC', ' SimSun', ' SimSun-ExtB', 'Sitka Banner', ' Sitka Display', 'Sitka Heading', 'Sitka Small', 'Sitka Subheading', 'Sitka Text', 'Snap ITC', 'STENCIL ',
                                        'MV Boli', 'Myanmar Text', 'Nagara Engraved', 'Nagara Solid', 'Nirmala UI', 'Nirmala UI Semilight ', 'NSimSun', 'OCR A Extended', ' Old English text', 'Onyx', 'Palatino Linotype', 'papyrus', 'Perpetua',
                                        'PERPETUA  TITLING MT', 'Playbill', 'PMingLiU-ExtB', 'Poor Richard', 'pristina', 'Ravie', 'Rockwell', 'Rockwell Condensed ',
                                        'Microsoft JhengHei UI', 'Microsoft JhengHei UI Light', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le',
                                        'Microsoft Uighur', 'Microsoft YaHei', 'Microsoft YaHei Light', 'Microsoft YaHei UI ', ' Microsoft YaHei UI Light', 'Microsoft Yi Baiti', 'MingLiU_HKSCS-ExtB', 'MingLiU-ExtB', 'Modern No. 20', 'Mongolian Baiti', 'Monotype Corsiva', ' MS Gothic',
                                        'MS Outlook', 'MS PGothic', 'MS Reference Sans Serif ', 'MS Reference Specialty', ' MS UI Gothic', ' MT Extra',
                                        'Imprint MT Shadow', 'Informal Roman', 'Ink Free', 'Javanese Text', 'Jokerman', 'Kristen ITC', ' Leelawadee ', 'Leelawadee UI', 'Leelawadee UI Semilight', ' Lucida Bright', 'Lucida Calligraphy ', 'Lucida Console', 'Lucida Fax', 'Lucida Handwriting', 'Lucida Sans', ' Lucida Sans Typewriter', 'Lucida Sans Unicode', 'magneto', 'Maiandra GD', 'Malgun Gothic', 'Malgun Gothic Semilight', ' Marlett', 'matura MT Script Capitats ',
                                        'Cooper Black', 'COPPERPLATE GOTHIC BOLD', 'COPPERPLATE GOTHIC LIGHT', 'Corbel', 'Corbel Light', 'Courier New ', 'Curlz MT ',
                                        'Dubai', ' Dubai Light', 'Dubai Medium', 'Ebrima', 'Elephant', 'ENGRAVERS MT', 'Eras Bold ITC', ' Eras Demi ITC', 'Eras Light ITC', 'Eras Medium ITC', ' FELIX TITLING', 'Footlight MT Light ', 'Forte', 'Franklin Gothic Book', 'Franklin Gothic Demi', 'Franklin Gothic Demi Cond', ' Franklin Gothic Heavy',
                                        'Book Antigua', 'Bookman Old Style', ' Bookshelf Symbol 7', 'Bradley Hand ITC', 'Britannic Bold', 'Broadway', 'Brush script MT', 'Calibri', 'Calibri Light', 'Californian FB', 'Calisto MT', 'Cambria', 'Cambria Math', 'Candara', 'Candara Light', 'CASTELLAR', 'Centaur', 'Century', 'Century Gothic', 'Century Schoolbook', 'Chiller', 'Colonna MT', 'Comic Sans MS', 'Consolas', 'Constantia',
                                        'Franklin Gothic Heavy', 'Franklin Gothic Medium', 'Franklin Gothic Medium Cond', 'Freestyle Script', ' French Script MT', 'Gadugi', 'Garamond', 'Georgia', 'Gill Sans MT', 'Gill Sans MT Condensed',
                                        'Gloucester MT Extra Condensed ', 'Coudy Old Style', 'GOVUDY STOUT', 'Haettenschweiler', 'Harrington', 'High Tower Text', 'HoloLens MDL2 Assets', 'Impact', 'Imprint MT Shadow'

                                      ]
                                    },
                                    fontColor: {
                                      colors: [
                                        //Green
                                        {
                                          color: 'hsl(210, 9%, 92%)',

                                        },
                                        {
                                          color: 'hsl(210, 9%, 100%)',

                                        },
                                        {
                                          color: 'hsl(204, 70%, 95%)',

                                        },
                                        {
                                          color: 'hsl(6, 78%, 96%)',

                                        },

                                        {
                                          color: 'hsl(145, 61%, 95%)',

                                        },


                                        {
                                          color: 'hsl(210, 9%, 85%)',

                                        },

                                        {
                                          color: 'hsl(204, 8%, 98%)',

                                        },
                                        {
                                          color: 'hsl(204, 70%, 90%)',

                                        },
                                        {
                                          color: 'hsl(6, 78%, 91%)',

                                        },
                                        {
                                          color: 'hsl(145, 61%, 90%)',

                                        },


                                        {
                                          color: 'hsl(210, 9%, 70%)',

                                        },
                                        {
                                          color: 'hsl(204, 8%, 95%)',

                                        },
                                        {
                                          color: 'hsl(204, 70%, 81%)',

                                        },
                                        {
                                          color: 'hsl(6, 78%, 83%)',

                                        },
                                        {
                                          color: 'hsl(145, 61%, 80%)',

                                        },



                                        {
                                          color: 'hsl(210, 9%, 55%)',

                                        },
                                        {
                                          color: 'hsl(204, 8%, 90%)',

                                        },
                                        {
                                          color: 'hsl(204, 70%, 72%)',

                                        },
                                        {
                                          color: 'hsl(6, 78%, 74%)',

                                        },

                                        {
                                          color: 'hsl(145, 61%, 69%)',

                                        },


                                        {
                                          color: 'hsl(210, 14%, 39%)',

                                        },
                                        {
                                          color: 'hsl(204, 8%, 86%)',

                                        }, {
                                          color: 'hsl(204, 70%, 63%)',

                                        }, {
                                          color: 'hsl(6, 78%, 66%)',

                                        },
                                        {
                                          color: 'hsl(145, 61%, 59%)',
                                        },


                                        {
                                          color: 'hsl(210, 29%, 24%)',

                                        },
                                        {
                                          color: 'hsl(204, 8%, 81%)',

                                        },
                                        {
                                          color: 'hsl(204, 70%, 53%)',

                                        },
                                        {
                                          color: 'hsl(6, 78%, 57%)',

                                        },
                                        {
                                          color: 'hsl(145, 63%, 49%)',

                                        },


                                        {
                                          color: 'hsl(210, 29%, 18%)',

                                        }, {
                                          color: 'hsl(204, 8%, 76%)',

                                        }, {
                                          color: 'hsl(204, 62%, 47%)',

                                        },
                                        {
                                          color: 'hsl(6, 78%, 50%)',

                                        },
                                        {
                                          color: 'hsl(145, 63%, 43%)',

                                        },

                                        {
                                          color: 'hsl(210, 29%, 18%)',

                                        },
                                        {
                                          color: 'hsl(204, 5%, 67%)',

                                        },
                                        {
                                          color: 'hsl(204, 62%, 40%)',

                                        }, {
                                          color: 'hsl(6, 59%, 43%)',

                                        },
                                        {
                                          color: 'hsl(145, 63%, 37%)',

                                        },


                                        {
                                          color: 'hsl(210, 29%, 16%)',

                                        },

                                        {
                                          color: 'hsl(204, 4%, 58%)',

                                        },
                                        {
                                          color: 'hsl(204, 62%, 34%)',

                                        },
                                        {
                                          color: 'hsl(6, 59%, 37%)',

                                        }, {
                                          color: 'hsl(145, 63%, 31%)',

                                        },

                                        {
                                          color: 'hsl(210, 29%, 13%)',

                                        },
                                        {
                                          color: 'hsl(204, 3%, 49%)',

                                        },
                                        {
                                          color: 'hsl(204, 62%, 28%)',

                                        },
                                        {
                                          color: 'hsl(6, 59%, 30%)',

                                        },
                                        {
                                          color: 'hsl(145, 63%, 25%)',

                                        },
                                      ]
                                    },
                                    mediaEmbed: {
                                      semanticDataOutput: true
                                    },
                                    link: {
                                      addTargetToExternalLinks: true
                                    }
                                  }}
                                />
                              </div>
                              {
                                learningSum.summary_lists ? (
                                  learningSum.summary_lists.map((list, i) => (
                                    <div>
                                      <div className="summary_listRemoveIcon">
                                        <i className="fas fa-minus-circle summary_listRemove" onClick={() => this.removeSummary_list(index, i)}></i>

                                      </div>
                                      <div style={{ marginLeft: "40px" }}>
                                        <text className="summary_list_Index">{i + 1}.</text>
                                        <input className="uk-input uk-width-xxlarge summary_list " id="list" name="list" placeholder="list" type="text" value={list.list} onChange={(e, data) => this.handleSummary_listChange(e, data, index, i)} />
                                        <br />
                                      </div>
                                    </div>
                                  ))
                                ) : (null)
                              }

                              <Button
                                className="uk-button uk-button-default uk-margin flashQuizAnswerButton adminSectionOtherButtons2"
                                type="button"
                                onClick={() => this.addSummary_list(index)}>
                                <i className="fas fa-plus-circle"></i> Add bullet point
                              </Button>
                            </div>

                          ))
                          : null
                        : null
                    }

                    <Button
                      className="uk-button uk-button-default uk-margin adminSectionOtherButtons1"
                      type="button"
                      style={{ marginLeft: "22px", width: '14rem' }}
                      onClick={() => this.addSummary()}
                    >
                      <i className="fas fa-plus-circle"></i>
                      <span style={{ paddingLeft: "5px" }}>Learning summary</span>
                    </Button>
                  </div>


                  {/* <FieldArray
                      name="summaries"
                      component={this.renderSummary}
                    /> */}

                  {/* ) : formAction == "EDIT" ? (
                    objectives ?
                    objectives.length > 0 ? (
                      <div>
                        <FieldArray
                          name="learnings"
                          component={this.renderLearnings}
                        />
                      </div>
                    ) : null :null
                  ) : null} */}
                </div>

                <Button
                  className="uk-button uk-button-primary uk-margin"
                  style={{ height: 40 }}
                  type="button"
                  disabled={loading ? true : false}
                  onClick={handleSubmit((val) => this.handleFormSubmit(val))}
                >
                  {loading ? (
                    <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                  ) : (
                      this.state.formAction === "ADD" ?
                        "Save"
                        : "Update"
                    )}
                </Button>

                {formErrorStatus.status ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                ) : formSuccessState.status ? (
                  <div className="success_alert">
                    {formSuccessState.message}{" "}
                  </div>
                ) : (
                      ""
                    )}
              </div>
            </div>
          </form>
        </div>
        {
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => { this._deleteFile(this.state.image).then((result) => { this.setState({ image: null, fileRef: null, confirmationModal: false }); }); }} closeModal={() => this.setState({ confirmationModal: false })} />
            : null
        }

        {
          this.state.confirmationModal2 === true ?
            <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => { this._deleteObjectivesFile(this.state.objectiveImage).then((result) => { this.setState({ objectiveImage: null, objectiveFileRef: null, confirmationModal2: false }); }); }} closeModal={() => this.setState({ confirmationModal2: false })} />
            : null
        }

        {
          this.state.confirmationModal3 === true ?
            <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => { this._deleteBannerFile(this.state.bannerImage).then((result) => { this.setState({ bannerImage: null, bannerImageFileRef: null, confirmationModal3: false }); }); }} closeModal={() => this.setState({ confirmationModal3: false })} />
            : null
        }
      </div>
    );
  }
}

AddModule = connect(mapStateToProps, mapDispatchToProps)(AddModule);

export default withApollo(reduxForm({ form: "fieldArrays" })(AddModule));

// export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AddModule)));

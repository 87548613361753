import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus } from "../../actions/user-actions";
import { Form, Message } from "semantic-ui-react";
//ui-kit components

import { Button } from "react-uikit";

//Spinner
import Loader from "react-loader-spinner";
import SblDeleteModal from "./sblDeleteModal";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
  };
};

class BlurrScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: {
        form_state: "default",
        title: "",
        message: "",
      },
      email: "",
      password: "",
      loading: false,
    };
    props.setFormStatus({ status: false, title: "", message: "" });
  }

  render() {
    const { modal } = this.props;

    // console.log(formErrorStatus)
// console.log(modal)
    return (
      <div style={styles.background}>
       {
           modal === 'sblDeactivateModal' ?
           <SblDeleteModal />
           : null
       }
                  <SblDeleteModal />

      </div>
    );
  }
}
const styles = {
  background: {
      backgroundColor: '#0000005e',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
  }
};
export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(BlurrScreen))
);

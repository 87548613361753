import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import { TwitterPicker, HuePicker, AlphaPicker, CirclePicker } from 'react-color';

//queries
import { CREATE_SUB_MODULE, GET_MODULES_FOR_ADMIN, UPDATE_SUB_MODULE } from '../../../queries/admin_queries';
import { GET_SUB_MODULE } from '../../../queries/common_queries';


import { Button } from 'react-uikit';
import { Input } from "semantic-ui-react";
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
// import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';



//constants
import { UPLOAD_API_URL } from '../../../constants/index'
import CKUploadAdapter from '../../../middleware/ck-file-upload-adapter';


//Image Uploader
import Images from '../Image/Images';
import PdfIcon from '../../../images/pdfIcon.jpg';
import DeleteIcon from '../../../images/delete.png';
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

import Editor from '../../../components/ckEditor/ckEditor';
import Tooltip from 'react-uikit/lib/components/Tooltip';
import ConfirmationModal from '../../../components/adminModals/confirmationModal';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
        translatingLanguages: state.custom_reducer.translatingLanguages,
        languageKeys: state.custom_reducer.languageKeys,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddSubModule extends React.Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        if (id == null) {
            this.state = {
                data: null,
                modules: [],
                moduleId: '',
                subModuleTitle: '',
                subBannerIconUrl: '',
                subBannerColor: '',
                subModuleHelperText: '',
                subModuleOverview: '',
                isActive: true,
                loading: false,
                pagination: {
                    isActive: true,
                    lang: localStorage.LANG,
                },
                formAction: "ADD",
                loading: false,
                image: "",
                formData: "",
                file: '', imagePreviewUrl: '',
                isUploading: false,
                questions: [],
                presentations:[],
                fileRef2:[],
                isFileUploading: false,
                colSections: [],
            
                videos: [],
                videoFiles: [],
                videoError: "",
                isVideoUploading: false,
                newFiles: [],

                flashQuizAnswerIndexes: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
            };
        } else {
            this.state = {
                data: null,
                modules: [],
                moduleId: '',
                subModuleTitle: '',
                subBannerIconUrl: '',
                subBannerColor: '',
                subModuleHelperText: '',
                subModuleOverview: '',
                isActive: true,
                loading: false,
                pagination: {
                    isActive: true,
                    lang: "en",
                },
                formAction: "EDIT",
                loading: false,
                subModuleId: id,
                image: [],
                formData: "",
                file: '', imagePreviewUrl: '',
                isUploading: false,
                formLanguage: "en",
                questions: [],
                presentations:[],
                fileRef2:[],
                isFileUploading: false,
                colSections: [],

                videos: [],
                videoFiles: [],
                videoError: "",
                isVideoUploading: false,
                newFiles: [],

                flashQuizAnswerIndexes: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],

            };
        }

        // console.log(this.props)

        this.handleChange = this.handleChange.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {

        this.setState({ loading: true });
        if (this.state.formAction == "EDIT") {
            this.getSingleSubModule(this.state.formLanguage);
        } else {
            this.setState({ loading: false });
        }

        this.get_modules(this.state.pagination).then(result => {
            var module_opt = [];
            result.modules.map(module => (
                module_opt.push({ key: module.id, value: module.id, id: 'moduleId', label: module.moduleTitle })
            ));
            this.setState({ modules: module_opt });
        })
    }


    documentUploadOnClick = event => {
        this.setState({
            isUploading: true,
        });
        console.log(this.state.formData);
        this._startDocUpload(this.state.formData);
    };

    documentUploadOnChange = event => {
        this.setState({
            formData: event.target.files[0],
        });
    }

    _startDocUpload = async file => {
        var intDate = Number(new Date());

        const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
        console.log(result.url)
        if (result.status) {
            this.setState({
                isUploading: false,
                fileRef: 'uploads/' + localStorage.FIRST_NAME + intDate + '/' + file.name,
                image: result.url,
                subBannerIconUrl: result.url,
                isUploading: false,
            });
        }
    };


    openDocument = (url) => {
        console.log(url);
        if (url != "" && url != undefined) {
          window.open(url);
        }
      };

      removeResource = (index) => {
        var tempArray = this.state.presentations;
        tempArray.splice(index, 1);
        this.setState({ presentations: tempArray, fileRef2: tempArray, confirmationModal2: false });
      };
      
    filesUploadOnChange = (e) => {
        console.log(e.target.files);
        // const presentationUrls = this.state.presentations;
        const newFiles = [];
        const files = e.target.files;
        if (files.length > 0) {
            for(let i = 0; i < files.length; i ++) {
                newFiles.push({ presentation: e.target.files[i] });
            }
          this.setState({
            newFiles: newFiles,
            // presentations: presentationUrls,
            fileRef2: [],
          });
        }
      };

      filesUploadOnClick = (event) => {
        this.setState({
          isFileUploading: true,
        });
        this._startFileUpload(this.state.newFiles);
      }


      _startFileUpload = async (files) => {
          console.log(files)
         

        //   var input =  document.getElementById(this.state.moduleId);
        //     var reader = new FileReader();
        //     reader.readAsBinaryString(input.files[0]);
        //     reader.onloadend = function(){
        //         var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
        //         console.log('Number of Pages:',count );
        // }


        var intDate = Number(new Date());
        var result;
        var count;
        var tempArray = this.state.presentations;
        var temp = [];
        for (let i = 0; i < files.length; i += 1) {
            result = await uploadFile(
            files[i].presentation,
            localStorage.FIRST_NAME + intDate
          );
          if (result.status) {
            console.log(result);
            tempArray.push({ presentationUrl: result.url, filename: files[i].presentation.name });
            temp.push({ presentationUrl: result.url, filename: files[i].presentation.name });
            this.setState({
              fileRef2: tempArray,
              newFiles: [],
              presentations: tempArray
            });
          }
        } 
        if (temp.length > 0) {
            if (temp.length === files.length) {
              this.props.setSuccessStatus({
                status: true,
                title: "",
                message: "Files uploaded successfully.",
              });
            } else {
              this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                <div>
                    An error occurred while uploading your files! <br/>
                    Please try again.
                </div>
                  
                 
              });
            }
          } else {
            this.props.setSuccessStatus({
              status: false,
              title: "",
              message: "",
            });
            this.props.setFormStatus({
              status: false,
              title: "Oops!",
              message: "Add files before uploading.",
            });
          }
        this.setState({ isFileUploading: false });
      };



    _deleteFile = async (path) => {
        console.log(path)
        const deleteRef = await deleteFile(path);
        this.setState({ image: "", subBannerIconUrl: "" });
        return deleteRef;
    }

    getSingleSubModule(language) {
        const tempGetSubmodule = { subModuleId: this.state.subModuleId, lang: language }
        this.get_submodule(tempGetSubmodule).then(result => {
            const questions = result.flashQuiz != null ?  result.flashQuiz.questions ? result.flashQuiz.questions.length > 0 ? result.flashQuiz.questions : [] : [] : [];
            console.log(result);
            const subContent = this.convertTooltipToAlt(result.subContent);
            const sections = result.collapsibleSections != null ? result.collapsibleSections.collapsibleSections.length > 0 ? this.convertSectionTooltipsToAlt(result.collapsibleSections.collapsibleSections) : [] : [];
            const videoList = result.videos ? result.videos.videos ? result.videos.videos : [] : [];

            this.setState({
                moduleId: result.moduleId,
                subModuleTitle: result.subModuleTitle,
                subBannerIconUrl: result.subBannerIconUrl,
                image: result.subBannerIconUrl,
                subBannerColor: result.subBannerColor,
                subModuleHelperText: result.subModuleHelperText,
                subModuleOverview: result.subModuleOverview,
                data: subContent,
                questions: questions,
                loading: false,
                presentations: result.presentations
                ? result.presentations.presentations
                : [],
                fileRef2: result.presentations ? result.presentations.presentations : [],
                colSections: sections,
                videos: videoList,
                languageEnabled: result.languageEnabled != undefined ? result.languageEnabled === false ? false : true : true
            });
        })
    }

    onEditorChange(event, editor, data) {
        console.log(data)
        const paragraphs = data.split("</p>")
        const firstParagraph = paragraphs[0]+'</p>';
        // console.log(firstParagraph)

       
        this.setState({
            data
        });

        // console.log(data)
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleModuleChange = (event) => {
        console.log(event.target.value);
        this.setState({ "moduleId": event.target.value });
    };

    filter = id => {
        return this.state.images.filter(image => image.public_id !== id)
    }

    removeImage = id => {
        this.setState({ images: this.filter(id) })
    }

    onError = id => {
        this.setState({ images: this.filter(id) })
    }



    handleSubmit = (event) => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.setState({ loading: true });

        const { subModuleTitle, subBannerIconUrl, subModuleHelperText, subModuleOverview, moduleId, questions , presentations, fileRef2, newFiles } = this.state;
        var flashQuizErrorStatus = "";
        if (questions.length > 0) {
            flashQuizErrorStatus = this.checkFlashQuizStatus();
        }

        if (moduleId == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please select a module." });
        } else if (subModuleTitle == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please add a title for the submodule." });
        } else if (subBannerIconUrl == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please upload a banner image." });
        } else if (subModuleHelperText == '') {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: "Please add helper text." });
        } else if (flashQuizErrorStatus != "") {
            this.setState({ loading: false });
            this.props.setFormStatus({ status: true, title: "Oops!", message: flashQuizErrorStatus });
        } else if (newFiles.length > 0) {
            this.setState({ loading: false });
            console.log(presentations, fileRef2);
            this.props.setFormStatus({
              status: true,
              title: "Oops!",
              message: "Please upload the selected file(s).",
            });
        }
           else {

            if (this.state.formAction == "ADD") {
                this.createSubModule().then(result => {
                    console.log(result);
                    this.setState({
                        data: '',
                        loading: false,
                        subModuleTitle: '',
                        subBannerIconUrl: '',
                        subBannerColor: '',
                        subModuleHelperText: '',
                        subModuleOverview: '',
                        questions: [],
                        presentations:[],
                        fileRef2:[],
                        image:'',
                    });

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "Submodule created successfully!"
                    });

                }).catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                    this.props.setFormStatus({
                        status: true, title: "Oops!", message: <div>
                                                                    An error occurred while trying to create the submodule! <br/>
                                                                    Please try again.
                                                                </div>


                    });
                });

            } else if (this.state.formAction == "EDIT") {
                this.updateSubModule().then(result => {
                    console.log(result.subContent)
                    this.getSingleSubModule();

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "Submodule updated successfully!"
                    })

                }).catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                    this.props.setFormStatus({ status: true, title: "Oops!", message: <div>
                                                                                        An error occurred while trying to update the submodule!
                                                                                        <br/>
                                                                                        Please try again.
                                                                                      </div>
                    });
                })
            }

        }
        this.setState({ loading: false });

    }

    createSubModule = async () => {
        const { subModuleTitle, subBannerIconUrl, subBannerColor, subModuleHelperText, subModuleOverview, moduleId, data, isActive, questions, fileRef2, colSections, videos } = this.state;
        const subContent = this.addTooltip(data);
        var flashQuiz = {questions: questions};
        var presentations = {presentations: fileRef2};
        var sections = this.addSectionTooltips(colSections);
        var collapsibleSections = {collapsibleSections: sections}
        var videoList = {videos: videos}
        const result = await this.props.client.mutate({
            mutation: CREATE_SUB_MODULE,
            variables: { subModuleTitle, subBannerIconUrl, subBannerColor, subModuleHelperText, subModuleOverview, subContent, moduleId, isActive, flashQuiz, presentations, collapsibleSections, videos: videoList }
        });
        return result.data.createSubModule;
    };

    updateSubModule = async () => {
        const { subModuleId, subModuleTitle, subBannerIconUrl, subBannerColor, subModuleHelperText, subModuleOverview, moduleId, data, isActive, questions, fileRef2, colSections, videos, languageEnabled } = this.state;
        const subContent = this.addTooltip(data);
        var flashQuiz = {questions: questions};
        var presentations = { presentations: fileRef2};
        var sections = this.addSectionTooltips(colSections);
        var collapsibleSections = {collapsibleSections: sections};
        var videoList = {videos: videos}
        const result = await this.props.client.mutate({
            mutation: UPDATE_SUB_MODULE,
            variables: { subModuleId, subModuleTitle, subBannerIconUrl, subBannerColor, subModuleHelperText, subModuleOverview, subContent, moduleId, isActive, flashQuiz, lang: this.state.formLanguage, presentations, collapsibleSections, videos: videoList, languageEnabled }
        });

        return result.data.updateSubModule;
    };


    get_modules = async (pagination) => {
        console.log(pagination)
        const result = await this.props.client.query({
            query: GET_MODULES_FOR_ADMIN,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getModulesForAdmin;
    };

    get_submodule = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_SUB_MODULE,
            variables: pagination,
            // fetchPolicy: 'cache-first'
            fetchPolicy: 'network-only'
        });
        return result.data.getSubModule;
    };

    breadcrumbredirect = (path) => {
        if (path == "HOME") {
            this.props.history.push('/admin_home');
        } else if (path == "MANAGESUBMODULE") {
            this.props.history.push('/admin_manage_submodules');
        } else {
            return false;
        }
    }

    changeFormLanguage = (language) => {
        const id = this.state.subModuleId;
        var tempPagination = { subModuleId: id, lang: language }
        var tempGetModules = this.state.pagination;
        tempGetModules.lang = language;
        this.setState({
            formLanguage: language,
        })
        console.log('OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO')

        // this.setState({ getModuleParameter: { moduleId: id, lang: language } });
        // this.get_submodule(tempPagination).then(result => {
        //     const subContent = this.convertTooltipToAlt(result.subContent);
        //     const sections = result.collapsibleSections != null ? result.collapsibleSections.collapsibleSections.length > 0 ? this.convertSectionTooltipsToAlt(result.collapsibleSections.collapsibleSections) : [] : [];
        //     const videoList = result.videos ? result.videos.videos ? result.videos.videos : [] : [];
        
        //     console.log('RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR')
        //     console.log(result)

        //     this.setState({
        //         moduleId: result.moduleId,
        //         subModuleTitle: result.subModuleTitle,
        //         subBannerIconUrl: result.subBannerIconUrl,
        //         image: result.subBannerIconUrl,
        //         subBannerColor: result.subBannerColor,
        //         subModuleHelperText: result.subModuleHelperText,
        //         subModuleOverview: result.subModuleOverview,
        //         data: result.subContent != null ? result.subContent != undefined ? result.subContent : "" : "",
        //         loading: false,
        //         formLanguage: language,
        //         pagination: tempGetModules,
        //         presentations: result.presentations
        //         ? result.presentations.presentations
        //         : [],
        //         fileRef2: result.presentations ? result.presentations.presentations : [],
        //         colSections: sections,
        //         videos: videoList,
        //     });
        //     console.log('PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP')
        //     console.log(result.subContent)
        // })
        this.getSingleSubModule(language);
        this.get_modules(tempGetModules).then(result => {
            var module_opt = [];

            result.modules.map(module => (
                module_opt.push({ key: module.id, value: module.id, id: 'moduleId', label: module.moduleTitle })
            ));
            this.setState({ modules: module_opt });
        })
    }

    addQuestion = () => {
        var tempArray = this.state.questions;
        tempArray.push({ question: "", answers: [], correctAnswer: "" });
        this.setState({ questions: tempArray });
    }

    addAnswer = (questionIndex) => {
        var tempArray = this.state.questions;
        tempArray[questionIndex].answers.push({ answer: "", correctAnswer: "" });
        this.setState({ questions: tempArray });
    }

    removeQuestion = (qIndex) => {
        var tempArray = this.state.questions;
        tempArray.splice(qIndex, 1);
        this.setState({ questions: tempArray });
    }

    removeAnswer = (qIndex, aIndex) => {
        var tempArray = this.state.questions;
        tempArray[qIndex].answers.splice(aIndex, 1);
        this.setState({ questions: tempArray });
    }

    handleAnswerChange = (e, data, qIndex, aIndex) => {
        var tempArray = this.state.questions;
        tempArray[qIndex].answers.map(ans => (
            ans.correctAnswer = false
        ))
        tempArray[qIndex].answers[aIndex].answer = e.target.value;
        this.setState({ questions: tempArray });
    }

    handleQuestionChange = (e, data, qIndex) => {
        var tempArray = this.state.questions;
        tempArray[qIndex].question = e.target.value;
        this.setState({ questions: tempArray });
    }

    markCorrectAnswer = (qIndex, aIndex) => {
        var tempArray = this.state.questions;
        tempArray[qIndex].answers[aIndex].correctAnswer = true;
        tempArray[qIndex].answers.map((a, index) => (
            index === aIndex ?
                a.correctAnswer = true
                : a.correctAnswer = false
        ))
        tempArray[qIndex].correctAnswer = tempArray[qIndex].answers[aIndex].answer;
        this.setState({ questions: tempArray });
    }

    checkFlashQuizStatus = () => {
        var tempArray = this.state.questions;
        var errorStatus = false;
        var correctAnswer = [];
        var temp = [];

        tempArray.map((q, qIndex) => (
            q.question != "" ?
                q.answers.length > 0 ?
                    q.answers.map((a, aIndex) => (
                        a.answer != "" ?
                            errorStatus = ""
                            : errorStatus = 'There cannot be any empty answer fields'
                    ))
                    : errorStatus = 'Each question must have answers'
                : errorStatus = 'There cannot be any empty questions',
            temp = q.answers.filter(obj => obj.correctAnswer === true),
            temp.length > 0 ?
                correctAnswer.push(true)
                : correctAnswer.push(false)
        ))
        if (errorStatus === "") {
            temp = [];
            temp = correctAnswer.filter(obj => obj === false)
            if (temp.length > 0) {
                errorStatus = "Each question must be marked with a correct answer"
            }
        }
        return errorStatus;
    }


    addSection = () => {
        var temp = this.state.colSections;
        temp.push({title: "", content: "", display: false, bgColor: "#e3e3e3", fontColor: "black"});
        this.setState({ colSections: temp });
    }

    removeSection = (index) => {
        var tempArray = this.state.colSections;
        tempArray.splice(index, 1);
        this.setState({ colSections: tempArray });
        console.log(tempArray)
    }

    onChangeSectionTitle(e, index) {
        var temp = this.state.colSections;
        temp[index].title = e.target.value;
        this.setState({
            colSections: temp
        });
    }

    onSectionEditorChange(event, editor, data, index) {
        console.log(data)
        var temp = this.state.colSections;
        const paragraphs = data.split("</p>")
        const firstParagraph = paragraphs[0]+'</p>';
        // console.log(firstParagraph)
        temp[index].content = data;
       
        this.setState({
            colSections: temp
        });
    }

    handleSectionColorChange = (color, index) => {
        console.log(color, index)
        const temp = this.state.colSections;
        temp[index].bgColor = color.hex;
        this.setState({
            colSections: temp
        })
    }

    handleSectionColorChangeFont = (color, index) => {
        console.log(color, index)
        const temp = this.state.colSections;
        temp[index].fontColor = color.hex;
        this.setState({
            colSections: temp
        })
    }

    addTooltip = (data) => {
        var str = data;
        var res = '';
        if (str){
            if (str != ''){
                if (str != null){
                    if (str != undefined){
                        res = str.replace(" alt=", " data-tip=")
                    }
                }
            }
        }
        return res;
    }

    convertTooltipToAlt = (data) => {
        var str = data;
        var res = '';
        if (str){
            if (str != ''){
                if (str != null){
                    if (str != undefined){
                        res = str.replace(" data-tip=", " alt=");
                    }
                }
            }
        }
  
        return res;
    }

    addSectionTooltips = (array) => {
        var tempArray = array;
        var str = "";
        var res = "";
        var tempArray2 = [];

        if(tempArray.length > 0) {
            tempArray.map((x, index) => (
                str = x.content,
                str ?
                   str != '' ?
                       str != null ?
                           str != undefined ?
                                res = str.replace(" alt=", " data-tip=")
                            : null
                        : null
                   : null
                : null,
                tempArray2.push({bgColor: x.bgColor, fontColor: x.fontColor, title: x.title, display: x.display, content: res })
            ))
        }
        console.log(tempArray)
        return tempArray2;
    }

    convertSectionTooltipsToAlt = (array) => {
        var tempArray = array;
        var str = "";
        var res = "";
        var tempArray2 = [];

        if(tempArray.length > 0) {
            tempArray.map((x, index) => (
                str = x.content,
                str ?
                   str != '' ?
                       str != null ?
                           str != undefined ?
                                res = str.replace(" data-tip=", " alt=")
                            : null
                        : null
                   : null
                : null,
                tempArray2.push({bgColor: x.bgColor, fontColor: x.fontColor, title: x.title, display: x.display, content: res })
            ))
        }
        return tempArray2;
    }


    
  videoUploadOnChange = (e) => {
    console.log(e.target.files);
    console.log(e.target.files[0].type);
    var files = [];
    var type = [];
    var splitArray = [];
    files = e.target.files;
    if(files.length > 0) {
      for(let i = 0; i < files.length; i++) {
        type = files[i].type;
        if(type != '') {
          splitArray = type.split('/');
          if(splitArray[0] != 'video'){
            this.setState({
              videoError: '* Unsupported file format',
            });
          }
          else{
            this.setState({
              videoFiles: e.target.files,
              videoError: '',
            });
          }
        }
        
      }
    }
    

  }

  handleChangeVideoCaption = (e, index) => {
    console.log(e.target.value)
    const tempArray = this.state.videos;
    tempArray[index].description = e.target.value;
    this.setState({
      videos: tempArray,
    })
  }

  removeVideo = (index) => {
    console.log("index: ",index)
    const tempArray = this.state.videos;
    tempArray.splice(index, 1);
    this.setState({
      videos: tempArray,
      confirmationModal3: false,
    })
  }

  videoUploadOnClick = async (e) => {
    this.setState({isVideoUploading: true})
    var videoList = this.state.videos;
    var fileinfo = document.forms.namedItem("fileinfo");
    console.log(fileinfo);
    e.preventDefault();
    fetch('https://kmsvideo.skotie.com/upload', {
      method: 'POST',
      body: new FormData(fileinfo)
    })
    .then(response => response.json())
    .then(result => {
      console.log(result);
      if(result.locations){
        if(result.locations.length > 0) {
          result.locations.map(video => (
            videoList.push({url: video.location, description: "" })
          ))
        }
      }
      this.setState({
        isVideoUploading: false,
        videos: videoList
      })       
    })
    .catch(error => {
      console.error(error);
      this.setState({
        isVideoUploading: false
      })
    });
  }

  handleCheckBoxChange = (status) => {
    this.setState({ languageEnabled: status });
  }



    render() {
        const { loading, modules, image, formAction, questions, presentations, fileRef2 , Pagecount, colSections, formLanguage } = this.state;
        const { formErrorStatus, formSuccessState, translatingLanguages } = this.props;
        console.log(presentations)
        console.log(fileRef2)
        console.log(this.state.filename);
        console.log(Pagecount);
        console.log(this.state.videos)
        return (
            <div className="admin-content-inner">
                <div>
                    <ul class="uk-breadcrumb">
                        <li ><a onClick={() => this.breadcrumbredirect("HOME")}>Home</a></li>
                        <li ><a onClick={() => this.breadcrumbredirect("MANAGESUBMODULE")}>Manage Submodules</a></li>
                        {formAction == "ADD" ? (
                            <li ><a onClick={() => this.breadcrumbredirect("ADDSUBMODULE")}>Add Submodule</a></li>
                        ) : (
                                formAction == "EDIT" ? (
                                    <li ><a onClick={() => this.breadcrumbredirect("EDITSUBMODULE")}>Edit Submodules</a></li>
                                ) : (
                                        null
                                    )
                            )}

                    </ul>
                </div>
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-th icon-large uk-margin-right"></i>
                    {formAction == "ADD" ? (
                        <h4 className="uk-margin-remove"> Add New Submodule </h4>
                    ) : (
                            formAction == "EDIT" ? (
                                <h4 className="uk-margin-remove"> Edit Submodule </h4>
                            ) : (
                                    null
                                )
                        )}

                </div>
                <div className="uk-margin-medium">
                    <form className="ui form">
                        <div className="uk-grid" >
                            <div className="uk-width-1@m">
                                <div className="uk-card-small uk-card-default">
                                    <div className="uk-card-header uk-text-bold">
                                        <i className="fas fa-th uk-margin-small-right"></i> Submodule Details


                                        {
                                            formAction == "EDIT" ?
                                                translatingLanguages.length > 0 ?
                                                <div className="formLanguageSwitcherDiv">
                                                {
                                                    translatingLanguages.map(languageTab => (
                                                    <span circular icon="download" className={ formLanguage === languageTab.key ? "formLanguageSwitcherActive"  : "formLanguageSwitcher" } onClick={() => this.changeFormLanguage(languageTab.key)}>
                                                        {languageTab.language}
                                                    </span>
                                                    ))
                                                }
                                                </div>
                                                : null
                                                :null
                                            }


                                        {/* {formAction == "EDIT" ? (
                      <div className="formLanguageSwitcherDiv">
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "en"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("en")}
                        >
                          English
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "fr"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("fr")}
                        >
                          French
                        </span>
                        <span
                          circular
                          icon="download"
                          className={
                            formLanguage === "ar"
                              ? "formLanguageSwitcherActive"
                              : "formLanguageSwitcher"
                          }
                          onClick={() => this.changeFormLanguage("ar")}
                        >
                          Arabic
                        </span>
                      </div>
                    ) : null} */}
                                    </div>
                                    <div className="uk-card-body uk-padding-remove-top">
                                    {
                                            this.state.formAction === "EDIT" ?
                                                this.state.formLanguage != "en" ?
                                                    <div className="disableLanguageVersionDiv">
                                                        <input type="checkbox" id="languageEnabled" name="languageEnabled" checked={this.state.languageEnabled} onChange={() => this.handleCheckBoxChange(!this.state.languageEnabled)} />
                                                        <label>{this.props.languageKeys[this.state.formLanguage]} version enabled</label>
                                                    </div>
                                                : null
                                            :null
                                        }
                                        <div className="uk-form-label"> Module </div>
                                        <select className="uk-select uk-width-xxlarge" onChange={this.handleModuleChange} value={this.state.moduleId || ''}>
                                            <option key="userType" value="" disabled>Select Module</option>
                                            {modules.map(item => (
                                                this.state.moduleId == item.id ? (
                                                    < option selected key={item.key} value={item.value} >
                                                        {item.label}
                                                    </option>
                                                ) : (
                                                        < option key={item.key} value={item.value} >
                                                            {item.label}
                                                        </option>
                                                    )
                                            ))}
                                        </select>

                                        

                                        <div className="uk-form-label"> Submodule Title </div>
                                        <input required value={this.state.subModuleTitle || ''} className="uk-input uk-form-width-huge" id="subModuleTitle" name="subModuleTitle" placeholder="Title" type="text" onChange={this.handleChange} />
                                        
                                        

                                        <div className="uk-form-label"> Upload Submodule Image </div>

                                        <div>
                                            <div className="">

                                                {/* File Component */}
                                                {this.state.image !== null && this.state.image !== undefined && this.state.image !== "" ?
                                                    (
                                                        <div className="file-upload-group uk-card uk-card-default uk-card-body" >
                                                            <div className="uk-text-center uk-grid " >

                                                                <div className="uk-width-auto@m">
                                                                    <span circular icon='download' class="uk-button uk-button-primary uk-button-small" onClick={() => { window.open(this.state.image); }}>Download</span>
                                                                </div>
                                                                <div className="uk-width-auto@m">
                                                                    <span circular icon='trash alternate outline' class="uk-button uk-button-secondary uk-button-small" onClick={() => 
                                                                    // {
                                                                    //     this._deleteFile(this.state.image).then(result => {
                                                                    //         console.log(result);
                                                                    //         this.setState({
                                                                    //             image: null,
                                                                    //             fileRef: null,
                                                                    //         });
                                                                    //     });
                                                                    // }
                                                                    this.setState({confirmationModal: true})
                                                                    }>Delete</span>
                                                                </div>

                                                            </div><br />
                                                            <Images
                                                                image={image}
                                                            // removeImage={this.removeImage}
                                                            />
                                                        </div>
                                                    ) : (

                                                        <div className="uk-text-center uk-grid" >
                                                            <div className="uk-width-auto@m">
                                                                <Input
                                                                    onChange={this.documentUploadOnChange}
                                                                    name="upload"
                                                                    id="upload"
                                                                    className="uk-input uk-form-width-huge"
                                                                    placeholder="Upload here"
                                                                    type="file"
                                                                    multiple
                                                                />
                                                            </div>
                                                            <div className="uk-width-auto@m">
                                                                <span class="uk-button uk-button-primary" onClick={this.documentUploadOnClick}>
                                                                    {loading ? (
                                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                                    ) : ("Upload Image")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {/* File Component */}
                                            </div>

                                            <br />
                                            {this.state.isUploading ? (
                                                <div className="uk-text-center uk-grid" >
                                                    <div className="uk-width-expand@m">
                                                        <h5 className="uk-align-left">Just a moment… <br/>
                                                        We are uploading your content.
                                                        </h5>
                                                    </div>
                                                    <div className="uk-width-auto@m">
                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                    ""
                                                )}
                                        </div>

                                        <br />

                                        <div className="uk-form-label"> Submodule Helper Text </div>
                                        <textarea required value={this.state.subModuleHelperText || ''} className="uk-textarea" rows="5" id="subModuleHelperText" name="subModuleHelperText" onChange={this.handleChange}></textarea>
                                        <div className="uk-form-label"> Submodule Duration </div>
                                        <input value={this.state.subModuleOverview || ''} className="uk-input uk-form-width-huge" id="subModuleOverview" name="subModuleOverview" placeholder="Submodule Duration" type="text" onChange={this.handleChange} />
                                        {/* <textarea value={this.state.subModuleOverview || ''} className="uk-textarea" rows="5" id="subModuleOverview" name="subModuleOverview" onChange={this.handleChange}></textarea> */}
                                        
                                        

                                        <div className="uk-form-label"> Content </div>
                         
                                        <CKEditor
                                        
                                            onInit={editor => {
                                                console.log('Editor is ready to use!', editor);
                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement(),
                                                    editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                                        return new CKUploadAdapter(loader);
                                                    }
                                                );
                                            }}

                                            onEditorChange
                                            onChange={(event, editor) =>
                                            //  console.log( { event, editor } )
                                            {
                                                const data = editor.getData();
                                                this.onEditorChange(event, editor, data);
                                               
                                            }
                                            }
                                            editor={DecoupledEditor}
                                            // data="<p>Hello from CKEditor 5's DecoupledEditor!</p>"
                                            data={this.state.data}
                                            config={{
                                                //    plugins:  Table, TableToolbar, TableProperties, TableCellProperties,
                                                // numberedList: {
                                                //     options: [
                                                //         'decimal', 'decimal-leading-zero', 'lower-roman', 'upper-roman', 'lower-latin', 'upper-latin'
                                                //     ]
                                                // },
                                                
                                                heading: {
                                                    options: [
                                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                                    ]
                                                },
                                                // language: {
                                                //     textPartLanguage: [
                                                //         { title: 'Arabic', languageCode: 'ar' },
                                                //         { title: 'French', languageCode: 'fr' },
                                                //         { title: 'English', languageCode: 'en' },
                                                //         { title: 'Spanish', languageCode: 'es' }
                                                //     ]
                                                // },
                                                fontSize: {
                                                    options: [
                                                        'default',
                                                        8,
                                                        9,
                                                        10,
                                                        11,
                                                        12,
                                                        14,

                                                        16,
                                                        18,
                                                        20,
                                                        22,
                                                        24,
                                                        26,
                                                        28,
                                                        36,
                                                        48,
                                                        72
                                                    ]
                                                },
                                                fontFamily: {
                                                    options: [
                                                        'default',
                                                        'Arial',
                                                        'courier New',
                                                        'Georgia',
                                                        'Lucida Sans Unicode',
                                                        'Tahoma',
                                                        'Times New Roman',
                                                        'Trebuchet MS',
                                                        'Verdana',
                                                        'Calibri Light',
                                                        'Calibri (Body)',
                                                        'Bahnschrift Light',
                                                        'Agency FB',
                                                        'Algerian',
                                                        'Arial Black',
                                                        'Arial Narrow',
                                                        'Arial Rounded MT Bold',
                                                        'Bahnschrift',
                                                        'Bahnschrift Condensed',
                                                        'Bahnschrift Light Condensed',
                                                        'Bahnschrift SemiBold SemiConden',
                                                        'Bahnschrift SemiCondensed',
                                                        'Sylfaen', 'Symbol', "Tahoma", 'Tempus Sans ITC', 'Times New Roman', 'Trebuchet MS', 'Tw Cen MT', 'Tw Cen MT Condensed', 'Tw cen MT Condensed Extra Bold', 'Verdana', ' Viner Hand ITC', 'Webdings ', 'Wide Latin', 'Wingdings', 'Wingdings 2', 'Wingdings 3', 'Yu Gothic ', 'Yu Gothic Light', 'Yu Gothic Medium', 'Yu Gothic UI',
                                                        'Yu Gothic UI Light', 'Yu Gothic UI Semibold', 'Yu Gothic UI Semilight', 'Rockwell Extra Bold', 'Sript MT Bold', ' Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Black', 'Segoe UI Emoji', 'Segoe UI Historic', 'Segoe UI Light', ' Segoe UI Semibold', 'Segoe UI Semilight', 'Segoe UI Symbol', 'SHOWCARD GOTHIC', ' SimSun', ' SimSun-ExtB', 'Sitka Banner', ' Sitka Display', 'Sitka Heading', 'Sitka Small', 'Sitka Subheading', 'Sitka Text', 'Snap ITC', 'STENCIL ',
                                                        'MV Boli', 'Myanmar Text', 'Nagara Engraved', 'Nagara Solid', 'Nirmala UI', 'Nirmala UI Semilight ', 'NSimSun', 'OCR A Extended', ' Old English text', 'Onyx', 'Palatino Linotype', 'papyrus', 'Perpetua',
                                                        'PERPETUA  TITLING MT', 'Playbill', 'PMingLiU-ExtB', 'Poor Richard', 'pristina', 'Ravie', 'Rockwell', 'Rockwell Condensed ',
                                                        'Microsoft JhengHei UI', 'Microsoft JhengHei UI Light', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le',
                                                        'Microsoft Uighur', 'Microsoft YaHei', 'Microsoft YaHei Light', 'Microsoft YaHei UI ', ' Microsoft YaHei UI Light', 'Microsoft Yi Baiti', 'MingLiU_HKSCS-ExtB', 'MingLiU-ExtB', 'Modern No. 20', 'Mongolian Baiti', 'Monotype Corsiva', ' MS Gothic',
                                                        'MS Outlook', 'MS PGothic', 'MS Reference Sans Serif ', 'MS Reference Specialty', ' MS UI Gothic', ' MT Extra',
                                                        'Imprint MT Shadow', 'Informal Roman', 'Ink Free', 'Javanese Text', 'Jokerman', 'Kristen ITC', ' Leelawadee ', 'Leelawadee UI', 'Leelawadee UI Semilight', ' Lucida Bright', 'Lucida Calligraphy ', 'Lucida Console', 'Lucida Fax', 'Lucida Handwriting', 'Lucida Sans', ' Lucida Sans Typewriter', 'Lucida Sans Unicode', 'magneto', 'Maiandra GD', 'Malgun Gothic', 'Malgun Gothic Semilight', ' Marlett', 'matura MT Script Capitats ',
                                                        'Cooper Black', 'COPPERPLATE GOTHIC BOLD', 'COPPERPLATE GOTHIC LIGHT', 'Corbel', 'Corbel Light', 'Courier New ', 'Curlz MT ',
                                                        'Dubai', ' Dubai Light', 'Dubai Medium', 'Ebrima', 'Elephant', 'ENGRAVERS MT', 'Eras Bold ITC', ' Eras Demi ITC', 'Eras Light ITC', 'Eras Medium ITC', ' FELIX TITLING', 'Footlight MT Light ', 'Forte', 'Franklin Gothic Book', 'Franklin Gothic Demi', 'Franklin Gothic Demi Cond', ' Franklin Gothic Heavy',
                                                        'Book Antigua', 'Bookman Old Style', ' Bookshelf Symbol 7', 'Bradley Hand ITC', 'Britannic Bold', 'Broadway', 'Brush script MT', 'Calibri', 'Calibri Light', 'Californian FB', 'Calisto MT', 'Cambria', 'Cambria Math', 'Candara', 'Candara Light', 'CASTELLAR', 'Centaur', 'Century', 'Century Gothic', 'Century Schoolbook', 'Chiller', 'Colonna MT', 'Comic Sans MS', 'Consolas', 'Constantia',
                                                        'Franklin Gothic Heavy', 'Franklin Gothic Medium', 'Franklin Gothic Medium Cond', 'Freestyle Script', ' French Script MT', 'Gadugi', 'Garamond', 'Georgia', 'Gill Sans MT', 'Gill Sans MT Condensed',
                                                        'Gloucester MT Extra Condensed ', 'Coudy Old Style', 'GOVUDY STOUT', 'Haettenschweiler', 'Harrington', 'High Tower Text', 'HoloLens MDL2 Assets', 'Impact', 'Imprint MT Shadow'

                                                    ]
                                                },
                                                fontColor: {
                                                    colors: [
                                                        //Green
                                                        {
                                                            color: 'hsl(210, 9%, 92%)',

                                                        },
                                                        {
                                                            color: 'hsl(210, 9%, 100%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 95%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 96%)',

                                                        },

                                                        {
                                                            color: 'hsl(145, 61%, 95%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 9%, 85%)',

                                                        },

                                                        {
                                                            color: 'hsl(204, 8%, 98%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 90%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 91%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 90%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 9%, 70%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 95%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 81%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 83%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 80%)',

                                                        },



                                                        {
                                                            color: 'hsl(210, 9%, 55%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 90%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 72%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 74%)',

                                                        },

                                                        {
                                                            color: 'hsl(145, 61%, 69%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 14%, 39%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 86%)',

                                                        }, {
                                                            color: 'hsl(204, 70%, 63%)',

                                                        }, {
                                                            color: 'hsl(6, 78%, 66%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 59%)',
                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 24%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 81%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 53%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 57%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 49%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 18%)',

                                                        }, {
                                                            color: 'hsl(204, 8%, 76%)',

                                                        }, {
                                                            color: 'hsl(204, 62%, 47%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 50%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 43%)',

                                                        },

                                                        {
                                                            color: 'hsl(210, 29%, 18%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 5%, 67%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 40%)',

                                                        }, {
                                                            color: 'hsl(6, 59%, 43%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 37%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 16%)',

                                                        },

                                                        {
                                                            color: 'hsl(204, 4%, 58%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 34%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 59%, 37%)',

                                                        }, {
                                                            color: 'hsl(145, 63%, 31%)',

                                                        },

                                                        {
                                                            color: 'hsl(210, 29%, 13%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 3%, 49%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 28%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 59%, 30%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 25%)',

                                                        },
                                                    ]
                                                },
                                                mediaEmbed: {
                                                    semanticDataOutput: true
                                                },
                                                link: {
                                                    addTargetToExternalLinks: true
                                                  }
                                            }}
                                        />


<div style={{display:"flex"}} className="uk-form-label"> Presentations  <span> <p style={{color:"#b54141", fontSize: "small", marginBottom:"12px", marginTop:"3px", paddingLeft:"4px"}}> (Please upload PDF files only)</p>
                                            
                                            </span> </div>
                                            <div className="uk-text-center uk-grid" >
                                                <div className="uk-width-auto@m">
                                                    <Input
                                                        onChange={this.filesUploadOnChange}
                                                        name="uploadtwo"
                                                        id="uploadtwo"
                                                        className="uk-input uk-form-width-huge"
                                                        placeholder="Upload here"
                                                        type="file"
                                                        multiple
                                                    />
                                                </div>
                                                <div className="uk-width-auto@m">
                                                    <span class="uk-button uk-button-primary" onClick={this.filesUploadOnClick}>
                                                        { this.state.isFileUploading ? (
                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                            ) : ("Upload PDF(s)")}
                                                        </span>
                                                </div>
                                            </div>
                                          {presentations ? (
                                            presentations.length > 0 ? (
                                                <div className="examImageDiv uk-width-huge ">
                                                {presentations.map((res, index) => (
                                                    <div className="examDocIconDiv">
                                                    <img
                                                        src={PdfIcon}
                                                        className="examDocIcon"
                                                        uk-tooltip={
                                                        res.presentation ? res.presentation.name : res.filename
                                                        }
                                                        onClick={() =>
                                                        this.openDocument(
                                                            formAction === "ADD"
                                                            ? this.state.fileRef2[index].presentationUrl
                                                            : res.presentationUrl
                                                        )
                                                        }
                                                    />
                                                    <img
                                                        src={DeleteIcon}
                                                        className="pdfRemoveIcon"
                                                        onClick={() => 
                                                            // this.removeResource(index)
                                                            this.setState({confirmationModal2: true, clickedPresentationRemove: index})
                                                        }
                                                        uk-tooltip="Remove File"
                                                    />
                                                    </div>
                                                ))}
                                                </div>
                                            ) : null
                                            ) : null}
                                            {this.state.isFileUploading ? (
                                            <div className="uk-text-center uk-grid">
                                                <div className="uk-width-expand@m">
                                                <h5 className="uk-align-left">
                                                Just a moment… <br/>
                                                We are uploading your content.
                                                </h5>
                                                </div>
                                                <div className="uk-width-auto@m">
                                                <span
                                                    data-uk-spinner=""
                                                    style={{ color: "#o6a7e3" }}
                                                />
                                                </div>
                                            </div>
                                            ) : (
                                            ""
                                            )}



{/* Upload videos start */}

<div className="uk-form-label"> Upload Videos </div>
                        <form id="fileinfo" name="fileinfo" onSubmit={(e) => this.videoUploadOnClick(e)}>
                            <div className="uk-grid">
                                  <div className="uk-width-auto@m">
                                      <Input className="uk-input uk-form-width-huge" type="file" name="file" multiple onChange={this.videoUploadOnChange} />
                                  </div>
                                  <div className="uk-width-auto@m">
                                      <Button className="uk-button-primary" type="submit" disabled={this.state.isVideoUploading === true} >Upload Video(s)</Button>   
                                  </div>
                                  <br />
                                  {
                                    this.state.videoError != "" ?
                                      <div style={{color: 'red', width: '100%', fontSize: '0.8rem'}}>
                                        <text >{this.state.videoError}</text>
                                      </div>
                                    : null  
                                  }
                            </div>
                        </form>
                        <br />
                        {this.state.isVideoUploading ? (
                        <div className="uk-text-center uk-grid">
                          <div className="uk-width-expand@m">
                            <h5 className="uk-align-left">
                            {/* Just one second , We are uploading your video/s. */}
                            Just a moment…
                            <br/>
                            We are uploading your content.
                            </h5>
                          </div>
                          <div className="uk-width-auto@m">
                            <span
                              data-uk-spinner=""
                              style={{ color: "#o6a7e3" }}
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                        {
                          this.state.videos.length > 0 ?
                            this.state.videos.map((x, index) => (
                              <table style={{width: '100%'}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '20rem', height: '10rem', padding: '0rem 0rem', backgroundColor: ''}}>
                                        <div className="video-cover">
                                            <video key= {'https://kmsvideo.skotie.com/' + x.url} className="video" height="200" style={{height: '10rem'}} controls>
                                                <source src={'https://kmsvideo.skotie.com/' + x.url} />
                                            </video>
                                        </div>
                                  
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                    <textarea
                                      value={x.description}
                                      className="uk-textarea"
                                      rows="3"
                                      id="description"
                                      name="description"
                                      placeholder="Description"
                                      style={{fontSize: '0.8rem', marginBottom: '1rem', marginLeft: '1rem'}}
                                      onChange={(e) => this.handleChangeVideoCaption(e, index)}></textarea>
                                      <div className="fitCenterDiv">
                                      <Button className="uk-button-secondary" style={{margin: '0 auto', fontSize: '0.8rem'}} disabled={this.state.isVideoUploading === true} onClick={() =>
                                        //  this.removeVideo(index)
                                        this.setState({confirmationModal3: true, clickedVideoRemove: index})
                                         }>Delete Video</Button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            ))
                            : null
                        }
{/* Upload videos end */}




                                        <div>
                                            <div className="uk-form-label"> Collapsible Sections </div>

                                            {
                                                colSections ?
                                                colSections.length > 0 ?
                                                colSections.map((section, index) => (
                                                    <React.Fragment>
                                                        <text className="subModuleQuestionNumber">{index + 1}).</text>
                                                        <input className="uk-input uk-form-width-huge subModuleQuestionField" id="sectionTitle" name="sectionTitle" placeholder="Title" type="text" value={section.title} onChange={(e) => this.onChangeSectionTitle(e,index)} />
                                                        
                                                        <i className="fas fa-minus-circle qaRemoveIcon" onClick={() => this.removeSection(index)}></i>
                                                        <div style={{paddingLeft: '2rem', fontSize: '0.8rem', paddingTop: '1rem'}}>
                                                        <table style={{width: '100%'}}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{width: '50%'}}>
                                                                        <div>
                                                                            <text>Background Color</text>
                                                                            <div className="" style={{marginTop: '0.8rem'}}>
                                                                            <CirclePicker width="385px" onChangeComplete={ (color) => this.handleSectionColorChange(color, index) } colors={['#D9E3F0', '#F47373', '#ffbe5c', '#3ec9b5', '#2CCCE4', '#555555', '#8ad8e6', '#bd7875', '#77c750']} />
                                                                            </div>
                                                                            <div className="" style={{marginTop: '0.8rem'}}>
                                                                            <HuePicker width="365px" color={section.bgColor}  onChange={ (color) => this.handleSectionColorChange(color, index) } />
                                                                            </div>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{width: '50%'}}>
                                                                        <div>
                                                                            <text>Font Color</text>
                                                                            <div className="" style={{marginTop: '0.8rem'}}>
                                                                            <CirclePicker width="385px" onChangeComplete={ (color) => this.handleSectionColorChangeFont(color, index) } colors={['#f5f5f5', '#cccccc', '#424242', '#000000']} />
                                                                            </div>
                                                                            <div className="" style={{marginTop: '0.8rem'}}>
                                                                            <HuePicker width="365px" color={section.fontColor}  onChange={ (color) => this.handleSectionColorChangeFont(color, index) } />
                                                                            </div>
                                                                        </div>
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            
                                                            
                                                        </div>
                                                       
                                                        <div style={{paddingLeft: '2rem'}}>
                                                        <div className="uk-form-label"> Preview </div>
                                                        <div className="customAccordion2" style={{backgroundColor: section.bgColor}}>
                                                            <i class="fa fa-caret-down" aria-hidden="true" style={{color: section.fontColor, marginRight: '0.5rem'}}></i>
                                                            <text style={{color: section.fontColor, fontSize: '0.9rem'}}>{section.title}</text>
                                                        </div>
                                                        </div>
                                                                                                                <br />
                                                        {/* <Editor data={section.content} change={(e) => this.onSectionEditorChange(e.target.value, index)} index={index} /> */}
                                                        <div style={{paddingLeft: '2rem'}}>
                                                            <CKEditor
                                                                onInit={editor => {
                                                                    console.log('Editor is ready to use!', editor);

                                                                    // Insert the toolbar before the editable area.
                                                                    editor.ui.getEditableElement().parentElement.insertBefore(
                                                                        editor.ui.view.toolbar.element,
                                                                        editor.ui.getEditableElement(),
                                                                        editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                                                            return new CKUploadAdapter(loader);
                                                                        }
                                                                    );
                                                                }}

                                                                onEditorChange
                                                                onChange={(event, editor) =>
                                                                //  console.log( { event, editor } )
                                                                {
                                                                    const data = editor.getData();
                                                                    this.onSectionEditorChange(event, editor, data, index);
                                                                
                                                                }
                                                                }
                                                                editor={DecoupledEditor}
                                                                // data="<p>Hello from CKEditor 5's DecoupledEditor!</p>"
                                                                data={section.content}
                                                                config={{
                                                                    //    plugins:  Table, TableToolbar, TableProperties, TableCellProperties,
                                                                    // numberedList: {
                                                                    //     options: [
                                                                    //         'decimal', 'decimal-leading-zero', 'lower-roman', 'upper-roman', 'lower-latin', 'upper-latin'
                                                                    //     ]
                                                                    // },
                                                                
                                                                    heading: {
                                                                        options: [
                                                                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                                                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                                                        ]
                                                                    },
                                                                    fontSize: {
                                                                        options: [
                                                                            'default',
                                                                            8,
                                                                            9,
                                                                            10,
                                                                            11,
                                                                            12,
                                                                            14,

                                                                            16,
                                                                            18,
                                                                            20,
                                                                            22,
                                                                            24,
                                                                            26,
                                                                            28,
                                                                            36,
                                                                            48,
                                                                            72
                                                                        ]
                                                                    },
                                                                    fontFamily: {
                                                                        options: [
                                                                            'default',
                                                                            'Arial',
                                                                            'courier New',
                                                                            'Georgia',
                                                                            'Lucida Sans Unicode',
                                                                            'Tahoma',
                                                                            'Times New Roman',
                                                                            'Trebuchet MS',
                                                                            'Verdana',
                                                                            'Calibri Light',
                                                                            'Calibri (Body)',
                                                                            'Bahnschrift Light',
                                                                            'Agency FB',
                                                                            'Algerian',
                                                                            'Arial Black',
                                                                            'Arial Narrow',
                                                                            'Arial Rounded MT Bold',
                                                                            'Bahnschrift',
                                                                            'Bahnschrift Condensed',
                                                                            'Bahnschrift Light Condensed',
                                                                            'Bahnschrift SemiBold SemiConden',
                                                                            'Bahnschrift SemiCondensed',
                                                                            'Sylfaen', 'Symbol', "Tahoma", 'Tempus Sans ITC', 'Times New Roman', 'Trebuchet MS', 'Tw Cen MT', 'Tw Cen MT Condensed', 'Tw cen MT Condensed Extra Bold', 'Verdana', ' Viner Hand ITC', 'Webdings ', 'Wide Latin', 'Wingdings', 'Wingdings 2', 'Wingdings 3', 'Yu Gothic ', 'Yu Gothic Light', 'Yu Gothic Medium', 'Yu Gothic UI',
                                                                            'Yu Gothic UI Light', 'Yu Gothic UI Semibold', 'Yu Gothic UI Semilight', 'Rockwell Extra Bold', 'Sript MT Bold', ' Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Black', 'Segoe UI Emoji', 'Segoe UI Historic', 'Segoe UI Light', ' Segoe UI Semibold', 'Segoe UI Semilight', 'Segoe UI Symbol', 'SHOWCARD GOTHIC', ' SimSun', ' SimSun-ExtB', 'Sitka Banner', ' Sitka Display', 'Sitka Heading', 'Sitka Small', 'Sitka Subheading', 'Sitka Text', 'Snap ITC', 'STENCIL ',
                                                                            'MV Boli', 'Myanmar Text', 'Nagara Engraved', 'Nagara Solid', 'Nirmala UI', 'Nirmala UI Semilight ', 'NSimSun', 'OCR A Extended', ' Old English text', 'Onyx', 'Palatino Linotype', 'papyrus', 'Perpetua',
                                                                            'PERPETUA  TITLING MT', 'Playbill', 'PMingLiU-ExtB', 'Poor Richard', 'pristina', 'Ravie', 'Rockwell', 'Rockwell Condensed ',
                                                                            'Microsoft JhengHei UI', 'Microsoft JhengHei UI Light', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le',
                                                                            'Microsoft Uighur', 'Microsoft YaHei', 'Microsoft YaHei Light', 'Microsoft YaHei UI ', ' Microsoft YaHei UI Light', 'Microsoft Yi Baiti', 'MingLiU_HKSCS-ExtB', 'MingLiU-ExtB', 'Modern No. 20', 'Mongolian Baiti', 'Monotype Corsiva', ' MS Gothic',
                                                                            'MS Outlook', 'MS PGothic', 'MS Reference Sans Serif ', 'MS Reference Specialty', ' MS UI Gothic', ' MT Extra',
                                                                            'Imprint MT Shadow', 'Informal Roman', 'Ink Free', 'Javanese Text', 'Jokerman', 'Kristen ITC', ' Leelawadee ', 'Leelawadee UI', 'Leelawadee UI Semilight', ' Lucida Bright', 'Lucida Calligraphy ', 'Lucida Console', 'Lucida Fax', 'Lucida Handwriting', 'Lucida Sans', ' Lucida Sans Typewriter', 'Lucida Sans Unicode', 'magneto', 'Maiandra GD', 'Malgun Gothic', 'Malgun Gothic Semilight', ' Marlett', 'matura MT Script Capitats ',
                                                                            'Cooper Black', 'COPPERPLATE GOTHIC BOLD', 'COPPERPLATE GOTHIC LIGHT', 'Corbel', 'Corbel Light', 'Courier New ', 'Curlz MT ',
                                                                            'Dubai', ' Dubai Light', 'Dubai Medium', 'Ebrima', 'Elephant', 'ENGRAVERS MT', 'Eras Bold ITC', ' Eras Demi ITC', 'Eras Light ITC', 'Eras Medium ITC', ' FELIX TITLING', 'Footlight MT Light ', 'Forte', 'Franklin Gothic Book', 'Franklin Gothic Demi', 'Franklin Gothic Demi Cond', ' Franklin Gothic Heavy',
                                                                            'Book Antigua', 'Bookman Old Style', ' Bookshelf Symbol 7', 'Bradley Hand ITC', 'Britannic Bold', 'Broadway', 'Brush script MT', 'Calibri', 'Calibri Light', 'Californian FB', 'Calisto MT', 'Cambria', 'Cambria Math', 'Candara', 'Candara Light', 'CASTELLAR', 'Centaur', 'Century', 'Century Gothic', 'Century Schoolbook', 'Chiller', 'Colonna MT', 'Comic Sans MS', 'Consolas', 'Constantia',
                                                                            'Franklin Gothic Heavy', 'Franklin Gothic Medium', 'Franklin Gothic Medium Cond', 'Freestyle Script', ' French Script MT', 'Gadugi', 'Garamond', 'Georgia', 'Gill Sans MT', 'Gill Sans MT Condensed',
                                                                            'Gloucester MT Extra Condensed ', 'Coudy Old Style', 'GOVUDY STOUT', 'Haettenschweiler', 'Harrington', 'High Tower Text', 'HoloLens MDL2 Assets', 'Impact', 'Imprint MT Shadow'

                                                                        ]
                                                                    },
                                                                    fontColor: {
                                                                        colors: [
                                                                            //Green
                                                                            {
                                                                                color: 'hsl(210, 9%, 92%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(210, 9%, 100%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 70%, 95%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 78%, 96%)',

                                                                            },

                                                                            {
                                                                                color: 'hsl(145, 61%, 95%)',

                                                                            },


                                                                            {
                                                                                color: 'hsl(210, 9%, 85%)',

                                                                            },

                                                                            {
                                                                                color: 'hsl(204, 8%, 98%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 70%, 90%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 78%, 91%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(145, 61%, 90%)',

                                                                            },


                                                                            {
                                                                                color: 'hsl(210, 9%, 70%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 8%, 95%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 70%, 81%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 78%, 83%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(145, 61%, 80%)',

                                                                            },



                                                                            {
                                                                                color: 'hsl(210, 9%, 55%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 8%, 90%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 70%, 72%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 78%, 74%)',

                                                                            },

                                                                            {
                                                                                color: 'hsl(145, 61%, 69%)',

                                                                            },


                                                                            {
                                                                                color: 'hsl(210, 14%, 39%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 8%, 86%)',

                                                                            }, {
                                                                                color: 'hsl(204, 70%, 63%)',

                                                                            }, {
                                                                                color: 'hsl(6, 78%, 66%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(145, 61%, 59%)',
                                                                            },


                                                                            {
                                                                                color: 'hsl(210, 29%, 24%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 8%, 81%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 70%, 53%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 78%, 57%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(145, 63%, 49%)',

                                                                            },


                                                                            {
                                                                                color: 'hsl(210, 29%, 18%)',

                                                                            }, {
                                                                                color: 'hsl(204, 8%, 76%)',

                                                                            }, {
                                                                                color: 'hsl(204, 62%, 47%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 78%, 50%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(145, 63%, 43%)',

                                                                            },

                                                                            {
                                                                                color: 'hsl(210, 29%, 18%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 5%, 67%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 62%, 40%)',

                                                                            }, {
                                                                                color: 'hsl(6, 59%, 43%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(145, 63%, 37%)',

                                                                            },


                                                                            {
                                                                                color: 'hsl(210, 29%, 16%)',

                                                                            },

                                                                            {
                                                                                color: 'hsl(204, 4%, 58%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 62%, 34%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 59%, 37%)',

                                                                            }, {
                                                                                color: 'hsl(145, 63%, 31%)',

                                                                            },

                                                                            {
                                                                                color: 'hsl(210, 29%, 13%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 3%, 49%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(204, 62%, 28%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(6, 59%, 30%)',

                                                                            },
                                                                            {
                                                                                color: 'hsl(145, 63%, 25%)',

                                                                            },
                                                                        ]
                                                                    },
                                                                    mediaEmbed: {
                                                                        semanticDataOutput: true
                                                                    },
                                                                    link: {
                                                                        addTargetToExternalLinks: true
                                                                      }
                                                                }}
                                                            />
                                                        </div>
                                        

                                                        <hr />
                                                    </React.Fragment>
                                                   
                                                    
                                                ))
                                                :null:null
                                            }

                                            <Button
                                                className="uk-button uk-button-default uk-margin flashQuizQuestionButton adminSectionOtherButtons1"
                                                type="button"
                                                onClick={() => this.addSection()}
                                                style={{minWidth: '9rem'}}>
                                                <i className="fas fa-plus-circle"></i> Section
                                                
                                            </Button>


                                        </div>

                                
                                        <div>
                                            <div className="uk-form-label "> Flash Quiz </div>
                                            {
                                                questions ? 
                                                questions.length > 0 ?
                                                questions.map((question, index) => (
                                                    <div>
                                                        <text className="subModuleQuestionNumber">{index + 1})</text>
                                                        <input className="uk-input uk-form-width-huge subModuleQuestionField" id="question" name="question" placeholder="Question" type="text" value={question.question} onChange={(e, data) => this.handleQuestionChange(e, data, index)} />
                                                        <i className="fas fa-minus-circle qaRemoveIcon" onClick={() => this.removeQuestion(index)}></i>
                                                        <br /><br />
                                                        {question.answers.map((answer, i) => (
                                                            <div className="subModuleAnswerDiv">
                                                                <text className="subModuleAnswerNumber">{this.state.flashQuizAnswerIndexes[i]})</text>
                                                                <input className="uk-input uk-form-width-huge subModuleAnswerField" id="answer" name="answer" placeholder="Answer" type="text" value={answer.answer} onChange={(e, data) => this.handleAnswerChange(e, data, index, i)} />
                                                                <br />
                                                                <input className="correctAnswer" type="checkbox" name={i} value={answer.answer} checked={answer.correctAnswer === true} onChange={() => this.markCorrectAnswer(index, i)}></input>
                                                                <text>Mark as correct answer</text>
                                                                <i className="fas fa-minus-circle qaRemoveIcon" onClick={() => this.removeAnswer(index, i)}></i>
                                                            </div>
                                                        ))}

                                                        <Button
                                                            className="uk-button uk-button-default uk-margin flashQuizAnswerButton adminSectionOtherButtons2"
                                                            type="button"
                                                            onClick={() => this.addAnswer(index)}>
                                                            <i className="fas fa-plus-circle"></i> Answer
                                                        </Button>
                                                        <hr />
                                                    </div>
                                                ))
                                                :null
                                                :null
                                            }
                                            <Button
                                                className="uk-button uk-button-default uk-margin flashQuizQuestionButton adminSectionOtherButtons1"
                                                type="button"
                                                onClick={() => this.addQuestion()}>
                                                <i className="fas fa-plus-circle"></i> Question
                                            </Button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <Button
                            className="uk-button uk-button-primary uk-margin"
                            style={{ height: 40 }}
                            type="button"
                            disabled={loading ? (true) : (false)}
                            onClick={() => this.handleSubmit(this.event)}
                            // onClick={() => this.addTooltip()}

                        >
                            {loading ? (
                                <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                            ) : (this.state.formAction === 'ADD' ? "Save" : "Update")}
                        </Button>
                        {(formErrorStatus.status) ? (
                            <div className="danger_alert">{formErrorStatus.message}</div>
                        ) : ((formSuccessState.status) ? (
                            <div className="success_alert">{formSuccessState.message} </div>
                        ) : (''))}
                    </form>
                </div>
                {
                    this.state.confirmationModal === true ?
                        <ConfirmationModal message="Are you sure you want to delete this image?" confirmDelete={() => {
                            this._deleteFile(this.state.image).then(result => {
                                console.log(result);
                                this.setState({
                                    image: null,
                                    fileRef: null,
                                    confirmationModal:false
                                });
                            });
                        }} closeModal={() => this.setState({confirmationModal: false})} />
                        : null
                }
                {
                    this.state.confirmationModal2 === true ?
                        <ConfirmationModal message="Are you sure you want to delete this presentation?" confirmDelete={() => this.removeResource(this.state.clickedPresentationRemove)} closeModal={() => this.setState({confirmationModal2: false})} />
                        : null
                }
                {
                    this.state.confirmationModal3 === true ?
                        <ConfirmationModal message="Are you sure you want to delete this video?" confirmDelete={() => this.removeVideo(this.state.clickedVideoRemove)} closeModal={() => this.setState({confirmationModal3: false})} />
                        : null
                }
            </div>
        );
    }

}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AddSubModule))); 
import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus, setModalStatus } from "../../../actions/user-actions";

//queries
import { GET_ALL_USERS } from "../../../queries/common_queries";
import { EDIT_BASIC_PROFILE, DELETE_USER } from "../../../queries/user_queries";
import { async } from "q";
import { _generate_email } from "../../../middleware/index";

import moment from "moment";

import { formatDate } from "../../../middleware/index";

import SectionHeaderLoader from "../../../components/loaders/SectionHeaderLoader";
import TableLoader_Userlist from "../../../components/loaders/TableLoader_Userlist";

import { Dropdown, Button, Confirm, Modal } from "semantic-ui-react";
import "semantic-ui-css/components/dropdown.css";
import "semantic-ui-css/components/transition.css";
import UserdelateModal from "../../../components/user_delete_modal/userDeleteModal";
import ConfirmationModal from '../../../components/adminModals/confirmationModal';
import react from "react";

const userStatus = [
  { key: "all", value: 0, text: "All" },
  { key: "active", value: true, text: "Active" },
  { key: "pending", value: false, text: "Pending" },
];

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
    ratingModalOpen: state.custom_reducer.ratingModalOpen,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status));
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status));
    },
    setModalStatus: (status) => {
      dispatch(setModalStatus(status))
    }
  };
};

class User_list extends React.Component {
  constructor(props) {
    super(props);

    if (localStorage.USER_ID == undefined) {
      this.props.history.push("/");
    } else {
      if (localStorage.USER_TYPE == "MAUSER") {
        this.props.history.push("/");
      }
    }

    const query = new URLSearchParams(this.props.location.search);
    const status = query.get("status");
    const from = query.get("from");
    const to = query.get("to");
    console.log(status, from, to)

    this.state = {
      loading: true,
      pagination: {
        userType: "MAUSER",
        skip: 0,
        first: 6
      },
      users: [],
      previousPage: 0,
      nextPage: 1,
      currentPage: 0,
      activePage: '',
      ToDos: 6,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      isFirstBtnActive: 'disabled',
      isLastBtnActive: '',
      userStatus: "",
      activeUsers: [],
      activeList: [],
      pendingUsers: [],
      pendingList: [],
      isActive: 0,
      user_list: [],
      open: false,

      selectedType:
        query.get("status") === "active"
          ? true
          : query.get("status") === "pending"
            ? false
            : 0,

      status: query.get("status"),

      fromDate: query.get("from") || '',
      toDate: query.get("to") || '',

      confirmationModal: false,

    };
    this.handleClick = this.handleClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);


    this.loadAllUsers();
  }

  show = (id) => {
    console.log(id);
    const UserID = id;
    console.log(UserID);
    this.setState({ open: true, UserID });
  };

  openModal = (status, id) => {
    console.log(id)
    const UserID = id;

    var status = false
    if (this.props.ratingModalOpen === true) {
      status = false;
    }
    else {
      status = true;
    }
    this.props.setModalStatus(status);
    this.setState({ UserID })
  }

  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({ open: false, });
  };

  // show(id) {
  //        console.log(id);
  //     this.setState({ open: true });
  // }

  loadAllUsers = async (dropdownValue) => {
    var isActive;
    console.log(dropdownValue);
    if (dropdownValue != undefined) {
      isActive = dropdownValue;
    } else {
      isActive = this.state.selectedType;
    }
    const { pagination, fromDate, toDate } = this.state;
    const { userType, skip, first } = pagination;

    var data = isActive === 0 ? { userType, fromDate: fromDate, toDate: toDate } : { isActive: isActive, userType, fromDate: fromDate, toDate: toDate };

    await this.props.client
      .query({
        query: GET_ALL_USERS,
        variables: data,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        console.log(result.data.getAllUsers.users);
        this.setState({
          loading: false,
          user_list: result.data.getAllUsers.users,
          count: result.data.getAllUsers.count,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  handleClick(event, index) {
    this.setState({
      currentPage: Number(index),
      activePage: index
    })
  }
  _handleUserStatusChange = (e, { value }) => {
    this.state.isActive = value;
    this.state.userStatus = value;
    console.log(value);
    this.setState({
      // isActive: value,
      loading: true,
      // userStatus: value
      selectedType: value,
    });
    this.loadAllUsers(value);
  };

  handlePagination = async (pageNo) => {
    console.log(pageNo);
    this.setState({
      currentPage: pageNo
    });

    if (pageNo >= 0) {
      this.setState({ isNextBtnActive: '', isLastBtnActive: '' })
      this.state.nextPage = pageNo + 1;
    }

    if (pageNo === Math.ceil(this.state.count / this.state.ToDos) - 1) {
      this.setState({ isNextBtnActive: 'disabled', isLastBtnActive: 'disabled' });
    }

    if (pageNo > 0) {
      this.setState({ isPrevBtnActive: '', isFirstBtnActive: '' });
      this.state.previousPage = pageNo - 1;
    }

    if (pageNo === 0) {
      this.setState({ isPrevBtnActive: 'disabled', isFirstBtnActive: 'disabled' });
    }

    var paginations = {
      skip: pageNo * this.state.ToDos,
      first: 6,
      lang: "en",
      fromDate: "",
      toDate: "",
      userType: "MAUSER"

    };

    if (pageNo > 0) {

      // this.loadAllUsers().then(result => {
      //   console.log(result)

      //     // this.setState({ user_list: result.users, count: result.count });
      // });
    } else {
      this.loadAllUsers().then(result => {
        console.log(result)
        // this.setState({ user_list: result.users, count: result.count });
      });
    }
  }

  changeUserStatus(id, status, langPreference) {
    var data = "";
    if (status) {
      data = false;
      // data = { userId: id, isActive: false };
    } else {
      // data = { userId: id, isActive: true };
      data = true;
    }
    this.saveUserStatus(id, data, langPreference);

    // this.setState({ loading: true });

    // this.saveUserStatus(data).then(result => {
    //     this.setState({ loading: false });
    //     // this.loadUsers();
    //     window.location.reload();
    // }).catch(error => {
    //     console.log(error);
    // });
    this.setState({
      confirmationModal: false,
      confirmationModal2: false,
    })
  }

  get_all_users = async (pagination) => {
    const result = await this.props.client.query({
      query: GET_ALL_USERS,
      variables: pagination,
      fetchPolicy: "network-only",
    });
    return result.data.getAllUsers;
  };

  saveUserStatus = async (userId, isActive, languagePreference) => {
    this.setState({ loading: true });
    // const result = await
    this.props.client
      .mutate({
        mutation: EDIT_BASIC_PROFILE,
        variables: {
          userId: userId,
          isActive: isActive,
        },
      })
      .then((result) => {
        this.loadAllUsers();
        const USER_DATA = result.data.editBasicProfile;
        const USER_EMAIL = USER_DATA.email;
        const FULL_NAME = USER_DATA.firstName + " " + USER_DATA.lastName;
        const FIRST_NAME = USER_DATA.firstName;
        const personalizations = {
          personalizations: [
            {
              to: [
                {
                  email: USER_EMAIL,
                  name: FULL_NAME,
                },
              ],
              bcc: [
                {
                  email: "narthana@encyteus.com",
                  name: "Narthana Selvachothy",
                },
                {
                  email: "chameera.lakshitha212@gmail.com",
                  name: "Lakshitha",
                },
                {
                  email: "rishi@fpasrilanka.org",
                  name: "Rishi",
                }
              ],
              dynamic_template_data: {
                name: FIRST_NAME,
              },
              subject:
                "Social Enterprise Academy – Your request has been Approved",
            },
          ],
          
          // CHANGE 23/04/2021 

          // template_id: "d-f52720161fab4008bd340429f60fc6bc"

          template_id: languagePreference === 'en' ? "d-f52720161fab4008bd340429f60fc6bc" 
                                    : languagePreference === 'ar' ? "d-84de578f320d48eb84c860f05d60903c" 
                                        : languagePreference === 'fr' ? "d-33dcedba941c4f9d88543817552354e1" 
                                            : languagePreference === 'sp' ? "d-c5b6ef416e9543b7bad21bc48b737385" 
                                                : "d-f52720161fab4008bd340429f60fc6bc" 
        };
        
        if (isActive) {
          console.log(isActive);
          const data = _generate_email(
            "https://seippf.org/mail/mail.php",
            personalizations
          );
        }
        // window.location.reload()

        // this.loadAllUsers(this.state.pagination);
      });

    // return result.data.editBasicProfile;
  };

  redirectToRegister = () => {
    this.props.history.push("/admin_edit_profile");
  };

  deleteUser = async (userID) => {
    console.log(userID);
    console.log("hghg");

    const result = await this.props.client.mutate({
      mutation: DELETE_USER,
      variables: { userID },
    });

    this.setState({ open: false });

    return result.data.deleteUser;
  };

  render() {
    const {
      users,
      UserID,
      loading,
      userID,
      count, nextPage, previousPage, currentPage, ToDos, isPrevBtnActive, isNextBtnActive, isFirstBtnActive, isLastBtnActive,
      pendingUsers,
      activeUsers,
      user_list,
      open,
    } = this.state;

    const pages = Math.ceil(count / ToDos);
    console.log(user_list)
    console.log(count)


    if (pages === 0) return null;

    var items = [];

    for (var index = 0; index < pages; index++) {
      items[index] = index;
    }

    let renderPrevBtn = null;
    if (isPrevBtnActive === 'disabled') {
      renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev" className="button disabled" ><i className="fas fa-angle-left"></i> </span></li>
    } else {
      renderPrevBtn = <li className={isPrevBtnActive} ><a onClick={() => this.handlePagination(previousPage)} id="btnPrev" ><i className="fas fa-angle-left"></i></a></li>
    }

    let renderNextBtn = null;
    if (isNextBtnActive === 'disabled') {
      renderNextBtn = <li className={isNextBtnActive}><span id="btnNext" className="button disabled"> <i className="fas fa-angle-right"></i> </span></li>
    }
    else {
      renderNextBtn = <li className={isNextBtnActive}><a onClick={() => this.handlePagination(nextPage)} id="btnNext" > <i className="fas fa-angle-right"></i> </a></li>
    }

    let renderfirstBtn = null;
    if (isFirstBtnActive === 'disabled') {
      renderfirstBtn = <li className={isFirstBtnActive}><span id="btnfirst" className="button disabled" ><i className="fas fa-angle-double-left"></i> </span></li>
    } else {
      renderfirstBtn = <li className={isFirstBtnActive} ><a onClick={() => this.handlePagination(0)} id="btnfirst" ><i className="fas fa-angle-double-left"></i></a></li>
    }

    let renderlastBtn = null;
    if (isLastBtnActive === 'disabled') {
      renderlastBtn = <li className={isLastBtnActive}><span id="btnlast" className="button disabled"><i className="fas fa-angle-double-right"> </i> </span> </li>
    } else {
      renderlastBtn = <li className={isLastBtnActive}><a onClick={() => this.handlePagination(items.length - 1)} id="btnlast" > <i className="fas fa-angle-double-right"></i>  </a></li>
    }


    return (
      <div className="userlist-content admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-user icon-large uk-margin-right"></i>
          <h3 className="uk-margin-remove adminSectionTitles"> User Profiles </h3>

        </div>
        <div className="uk-flex-inline uk-float-right">
          {loading ? (
            <SectionHeaderLoader />
          ) : (
              <div className="uk-width-auto@m uk-text-small">
                <form>
                  <div className="uk-form-label"> User Category: </div>
                  <Dropdown
                    className="uk-select uk-width-small"
                    onChange={this._handleUserStatusChange}
                    options={userStatus}
                    placeholder="Select User Status"
                    selection
                    value={this.state.selectedType}
                  />
                </form>
              </div>
            )}
        </div>


        {this.props.ratingModalOpen === true ? <UserdelateModal UserID={UserID} /> : null}
        <br />
        <br />
        <br />
        {/* 
        {loading ? (
          <SectionHeaderLoader />
        ) : (
          <div
            style={{ backgroundColor: "#f8f8f8" }}
            className="uk-background-default uk-margin-top"
          >
            <div className="uk-grid">
              <div className="uk-width-expand@m"></div>
              <div className="uk-width-auto@m uk-text-small">
                <form>
                <div className="uk-form-label"> User Category: </div>
                  <Dropdown
                    className="uk-select uk-width-small"
                    onChange={this._handleUserStatusChange}
                    options={userStatus}
                    placeholder="Select User Status"
                    selection
                    value={this.state.selectedType}
                  />
                </form>
              </div>
            </div>
          </div>
        )} */}

        <div className=" uk-overflow-auto">
          <table
            style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)" }}
            className="fontstyle uk-table uk-table-hover uk-table-middle uk-table-divider"
          >
            <thead>
              <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                <th>PROFILE DETAILS</th>
                <th>EMAIL</th>
                <th style={{ width: '7rem' }}>REGISTERED ON </th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>

            {loading ? (
              <TableLoader_Userlist />
            ) : (
                <tbody>
                  {user_list.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <b>Name: </b>
                        <br />
                        {user.firstName} {user.lastName}
                        <br />
                        <b>
                          {user.fullName ===
                            "International Planned Parenthood Federation"
                            ? "Central Office"
                            : user.fullName === "NA"
                              ? "Regional Office"
                              : "Member Association"}
                        :
                      </b>
                        <br />
                        {user.fullName}
                        <br />
                        <b>IPPF Region: </b>
                        <br />
                        {user.region}
                        <br />
                        <b>Country: </b>
                        <br />
                        {user.country}
                        <br />
                        <b>Year of establishment: </b>
                        <br />
                        {user.year}
                      </td>
                      <td> {user.email} </td>
                      <td> {moment(user.createdAt).format("DD-MM-YYYY")} </td>
                      {/* {moment(user.createdAt).format("YYYY-MM-DD HH:mm:ss A")} */}
                      {/* formatDate(user.createdAt) 21-04-2020*/}
                      <td>
                        {user.isActive ? (
                          <button
                            onClick={() =>
                              // this.changeUserStatus(user.id, user.isActive)
                              this.setState({ confirmationModal2: true, clickedUserId: user.id, clickerUserIsActive: user.isActive, clickedUserLang: user.languagePreference  })
                            }
                            className="uk-button uk-button-danger admin-table-btn"
                            uk-tooltip="title: Edit Status ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up"
                          >
                            {" "}
                          Deactivate{" "}
                          </button>
                        ) : (
                            <button
                              onClick={() =>
                                // this.changeUserStatus(user.id, user.isActive)
                                this.setState({ confirmationModal: true, clickedUserId: user.id, clickerUserIsActive: user.isActive, clickedUserLang: user.languagePreference })
                              }
                              className="uk-button uk-button-success admin-table-btn button_Success"
                              uk-tooltip="title: Edit Status ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up"
                            >
                              {" "}
                          Activate{" "}
                            </button>
                          )}
                      </td>
                      <td>
                        <button
                          // onClick={()=> this.deleteUser(user.id)}
                          onClick={() => this.openModal(true, user.id)}

                          // onClick={() => this.show(user.id)}
                          className="uk-button uk-button-danger admin-table-btn deleteUser"
                          uk-tooltip="title: Delete User ; delay: 300 ; pos: top ;animation: uk-animation-scale-up"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          {/* Delete */}
                        </button>
                      </td>
                    </tr>

                  ))}
                </tbody>
              )}
          </table>
        </div>

        {/* <div className="paginations uk-flex uk-flex-center">
          <ul class="uk-pagination" uk-margin>
            {renderfirstBtn}
            {renderPrevBtn}

            {
              items.map((item, index) =>
                <li key={index} id={index} active={this.state.activePage === index} onClick={(e) => this.handleClick(e, item)} className={currentPage === item ? 'active' : ''} ><a onClick={() => this.handlePagination(item)} >{item + 1}</a></li>
              )
            }

            {renderNextBtn}
            {renderlastBtn}
          </ul>
        </div> */}

        {/* <button onClick={()=>this.show()}>Show</button> */}
        <Modal className="" open={open} onClose={this.onCloseModal}>
          <form>
            <div className="uk-modal-header">
              <h4> Are you sure you want to reject or delete this user? </h4>
            </div>
            <div
              className="uk-modal-footer uk-text-right"
              style={{ display: "flex", float: "right" }}
            >
              <div className="ModalButton">
                <Button
                  className="uk-button"
                  type="submit"
                  name="closeModal"
                  id="closeModal"
                  onClick={this.onCloseModal}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  className="uk-button  deleteUser"
                  type="submit"
                  onClick={() => this.deleteUser(UserID)}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </form>
        </Modal>
        {
          this.state.confirmationModal === true ?
            <ConfirmationModal message="Are you sure you want to activate this user?" confirmDelete={() => this.changeUserStatus(this.state.clickedUserId, this.state.clickerUserIsActive, this.state.clickedUserLang)} closeModal={() => this.setState({ confirmationModal: false })} />
            : null
        }
        {
          this.state.confirmationModal2 === true ?
            <ConfirmationModal message="Are you sure you want to deactivate this user?" confirmDelete={() => this.changeUserStatus(this.state.clickedUserId, this.state.clickerUserIsActive, this.state.clickedUserLang)} closeModal={() => this.setState({ confirmationModal2: false })} />
            : null
        }
      </div>
    );
  }
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(User_list))
);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

import { GET_GALLERIES } from '../../../queries/common_queries'
import { UPDATE_GALLERY } from '../../../queries/user_queries'

import { formatDate } from '../../../middleware/index'
import not_pinned from '../../../images/Programming-Pin-icon.png';
import pinned from '../../../images/pin.png';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class GalleryList extends Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        } else {
            if (localStorage.USER_TYPE == "MAUSER") {
                this.props.history.push('/');
            }
        }

        this.state = {
            galleries: [],
            loading: false,
            pagination: {
                skip: 0,
                first: 100000,
                lang: localStorage.LANG
            },
            lang: localStorage.LANG

        };
        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    componentDidMount() {
        // this.setState({
        //     loading:true
        // });
        this._getGalleries(this.state.pagination).then(result => {
            // console.log(result)
            this.setState({
                galleries: result.galleries, loading: false
            })
        })
    }

    componentDidUpdate() { window.scrollTo(0, 0) }

    _getGalleries = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_GALLERIES,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        return result.data.getGalleries;
    };

    addGallery() {
        this.props.history.push('/admin_add_gallery');
    }

    editGallery(id) {
        this.props.history.push('/admin_edit_gallery?id=' + id)
    }

    saveStatus = async (galleryId, pinned) => {
        console.log(galleryId)
        console.log(pinned)
        // this.setState({ loading: true });
        const lang = this.state.lang;
        this.props.client
            .mutate({
                mutation: UPDATE_GALLERY,
                variables: {
                    galleryId: galleryId,
                    pinned: pinned,
                    lang: lang
                },
            })
            .then((result) => {
                console.log(result)
                window.location.reload()
            });
    };

    changeStatus = (id, status) => {
        console.log(status)
        console.log(id);
        var data = "";
        if (status) {
            data = false;
        } else {
            data = true;
        }
        this.saveStatus(id, data);
    }

    updateGallery = async () => {
        const { galleryId, pinned } = this.state;
        const lang = this.state.lang;
        const result = await this.props.client.mutate({
            mutation: UPDATE_GALLERY,
            variables: { galleryId, lang, pinned },
        });
        return result.data.updateGallery;
    }

    render() {
        const { loading, galleries } = this.state;
        console.log(galleries)
        var MyData = [];

        return (
            <div className="admin-content-inner">
                <div className="uk-flex-inline uk-flex-middle">
                    <i className="fas fa-image icon-large uk-margin-right"></i>
                    <h4 className="uk-margin-remove adminSectionTitles"> Gallery </h4>

                </div>
                <div className="uk-flex-inline uk-float-right">
                    <button className="uk-button uk-button-primary admin-btn" onClick={() => this.addGallery()}>Add Album </button>
                </div>
                <br /> <br />
                <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                        <thead>
                            <tr className="uk-text-small uk-text-bold adminSectionTableTitles">
                                <th style={{ width: "35%" }}>Album </th>
                                <th style={{ width: "30%" }}>Album Title</th>
                                <th style={{ width: "15%" }}>Event Date</th>
                                <th>Edit</th>
                                <th>Pin Album</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                galleries.length > 0 ? (
                                    MyData = [].concat(this.state.galleries)
                                        .sort((a, b) => a.pinned > b.pinned ? -1 : 1)
                                        .map((gallery, i) =>
                                            <tr key={i}>
                                                <td > <img src={gallery.galleryBannerImage} /> </td>
                                                {/* style={{ maxWidth: "30%", height: "auto"}}  */}
                                                <td> {gallery.galleryTitle} </td>
                                                <td> {formatDate(gallery.eventDate)} </td>
                                                <td>
                                                    <button disabled={loading ? (true) : (false)} className="uk-button uk-button-warning admin-table-btn" uk-tooltip="Edit" onClick={() => this.editGallery(gallery.id)} ><i class='fas fa-pencil-alt'></i></button>
                                                </td>
                                                <td style={{ paddingRight: "50px" }} >
                                                    {gallery.pinned ? (
                                                        <img
                                                            src={pinned}
                                                            className="pinIcon"
                                                            uk-tooltip="title: Unpin album; delay: 500 ; pos: top ;animation:	uk-animation-scale-up"
                                                            onClick={() =>
                                                                this.changeStatus(gallery.id, gallery.pinned)
                                                                
                                                            } 
                                                            disabled={loading ? (true) : (false)}
                                                            />
                                                    ) : (
                                                            <img
                                                                src={not_pinned}
                                                                className="pinIcon"
                                                                uk-tooltip="title: Pin album ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up"
                                                                onClick={() =>
                                                                    this.changeStatus(gallery.id, gallery.pinned)
                                                                }
                                                                disabled={loading ? (true) : (false)}
                                                            />
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                ) : (null)
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GalleryList))) 
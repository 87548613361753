import * as React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import CKEditor from '@ckeditor/ckeditor5-react';
// import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import CKUploadAdapter from '../../middleware/ck-file-upload-adapter';

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

class CkEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { data, index } = this.props;
    return (
      <div>
          <CKEditor
                                            onInit={editor => {
                                                console.log('Editor is ready to use!', editor);

                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement(),
                                                    editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                                        return new CKUploadAdapter(loader);
                                                    }
                                                );
                                            }}

                                            onEditorChange
                                            onChange={(event, editor) =>
                                            {
                                                const data = editor.getData();
                                                console.log(data);
                                                this.props.change(data, index);
                                               
                                            }
                                            }
                                            editor={DecoupledEditor}
                                            // data="<p>Hello from <b>CKEditor 5's</b> DecoupledEditor!</p>"
                                            data={data}
                                            config={{
                                                heading: {
                                                    options: [
                                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                                    ]
                                                },
                                                fontSize: {
                                                    options: [
                                                        'default',
                                                        8,
                                                        9,
                                                        10,
                                                        11,
                                                        12,
                                                        14,

                                                        16,
                                                        18,
                                                        20,
                                                        22,
                                                        24,
                                                        26,
                                                        28,
                                                        36,
                                                        48,
                                                        72
                                                    ]
                                                },
                                                fontFamily: {
                                                    options: [
                                                        'default',
                                                        'Arial',
                                                        'courier New',
                                                        'Georgia',
                                                        'Lucida Sans Unicode',
                                                        'Tahoma',
                                                        'Times New Roman',
                                                        'Trebuchet MS',
                                                        'Verdana',
                                                        'Calibri Light',
                                                        'Calibri (Body)',
                                                        'Bahnschrift Light',
                                                        'Agency FB',
                                                        'Algerian',
                                                        'Arial Black',
                                                        'Arial Narrow',
                                                        'Arial Rounded MT Bold',
                                                        'Bahnschrift',
                                                        'Bahnschrift Condensed',
                                                        'Bahnschrift Light Condensed',
                                                        'Bahnschrift SemiBold SemiConden',
                                                        'Bahnschrift SemiCondensed',
                                                        'Sylfaen', 'Symbol', "Tahoma", 'Tempus Sans ITC', 'Times New Roman', 'Trebuchet MS', 'Tw Cen MT', 'Tw Cen MT Condensed', 'Tw cen MT Condensed Extra Bold', 'Verdana', ' Viner Hand ITC', 'Webdings ', 'Wide Latin', 'Wingdings', 'Wingdings 2', 'Wingdings 3', 'Yu Gothic ', 'Yu Gothic Light', 'Yu Gothic Medium', 'Yu Gothic UI',
                                                        'Yu Gothic UI Light', 'Yu Gothic UI Semibold', 'Yu Gothic UI Semilight', 'Rockwell Extra Bold', 'Sript MT Bold', ' Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Black', 'Segoe UI Emoji', 'Segoe UI Historic', 'Segoe UI Light', ' Segoe UI Semibold', 'Segoe UI Semilight', 'Segoe UI Symbol', 'SHOWCARD GOTHIC', ' SimSun', ' SimSun-ExtB', 'Sitka Banner', ' Sitka Display', 'Sitka Heading', 'Sitka Small', 'Sitka Subheading', 'Sitka Text', 'Snap ITC', 'STENCIL ',
                                                        'MV Boli', 'Myanmar Text', 'Nagara Engraved', 'Nagara Solid', 'Nirmala UI', 'Nirmala UI Semilight ', 'NSimSun', 'OCR A Extended', ' Old English text', 'Onyx', 'Palatino Linotype', 'papyrus', 'Perpetua',
                                                        'PERPETUA  TITLING MT', 'Playbill', 'PMingLiU-ExtB', 'Poor Richard', 'pristina', 'Ravie', 'Rockwell', 'Rockwell Condensed ',
                                                        'Microsoft JhengHei UI', 'Microsoft JhengHei UI Light', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le',
                                                        'Microsoft Uighur', 'Microsoft YaHei', 'Microsoft YaHei Light', 'Microsoft YaHei UI ', ' Microsoft YaHei UI Light', 'Microsoft Yi Baiti', 'MingLiU_HKSCS-ExtB', 'MingLiU-ExtB', 'Modern No. 20', 'Mongolian Baiti', 'Monotype Corsiva', ' MS Gothic',
                                                        'MS Outlook', 'MS PGothic', 'MS Reference Sans Serif ', 'MS Reference Specialty', ' MS UI Gothic', ' MT Extra',
                                                        'Imprint MT Shadow', 'Informal Roman', 'Ink Free', 'Javanese Text', 'Jokerman', 'Kristen ITC', ' Leelawadee ', 'Leelawadee UI', 'Leelawadee UI Semilight', ' Lucida Bright', 'Lucida Calligraphy ', 'Lucida Console', 'Lucida Fax', 'Lucida Handwriting', 'Lucida Sans', ' Lucida Sans Typewriter', 'Lucida Sans Unicode', 'magneto', 'Maiandra GD', 'Malgun Gothic', 'Malgun Gothic Semilight', ' Marlett', 'matura MT Script Capitats ',
                                                        'Cooper Black', 'COPPERPLATE GOTHIC BOLD', 'COPPERPLATE GOTHIC LIGHT', 'Corbel', 'Corbel Light', 'Courier New ', 'Curlz MT ',
                                                        'Dubai', ' Dubai Light', 'Dubai Medium', 'Ebrima', 'Elephant', 'ENGRAVERS MT', 'Eras Bold ITC', ' Eras Demi ITC', 'Eras Light ITC', 'Eras Medium ITC', ' FELIX TITLING', 'Footlight MT Light ', 'Forte', 'Franklin Gothic Book', 'Franklin Gothic Demi', 'Franklin Gothic Demi Cond', ' Franklin Gothic Heavy',
                                                        'Book Antigua', 'Bookman Old Style', ' Bookshelf Symbol 7', 'Bradley Hand ITC', 'Britannic Bold', 'Broadway', 'Brush script MT', 'Calibri', 'Calibri Light', 'Californian FB', 'Calisto MT', 'Cambria', 'Cambria Math', 'Candara', 'Candara Light', 'CASTELLAR', 'Centaur', 'Century', 'Century Gothic', 'Century Schoolbook', 'Chiller', 'Colonna MT', 'Comic Sans MS', 'Consolas', 'Constantia',
                                                        'Franklin Gothic Heavy', 'Franklin Gothic Medium', 'Franklin Gothic Medium Cond', 'Freestyle Script', ' French Script MT', 'Gadugi', 'Garamond', 'Georgia', 'Gill Sans MT', 'Gill Sans MT Condensed',
                                                        'Gloucester MT Extra Condensed ', 'Coudy Old Style', 'GOVUDY STOUT', 'Haettenschweiler', 'Harrington', 'High Tower Text', 'HoloLens MDL2 Assets', 'Impact', 'Imprint MT Shadow'

                                                    ]
                                                },
                                                fontColor: {
                                                    colors: [
                                                        //Green
                                                        {
                                                            color: 'hsl(210, 9%, 92%)',

                                                        },
                                                        {
                                                            color: 'hsl(210, 9%, 100%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 95%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 96%)',

                                                        },

                                                        {
                                                            color: 'hsl(145, 61%, 95%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 9%, 85%)',

                                                        },

                                                        {
                                                            color: 'hsl(204, 8%, 98%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 90%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 91%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 90%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 9%, 70%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 95%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 81%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 83%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 80%)',

                                                        },



                                                        {
                                                            color: 'hsl(210, 9%, 55%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 90%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 72%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 74%)',

                                                        },

                                                        {
                                                            color: 'hsl(145, 61%, 69%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 14%, 39%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 86%)',

                                                        }, {
                                                            color: 'hsl(204, 70%, 63%)',

                                                        }, {
                                                            color: 'hsl(6, 78%, 66%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 61%, 59%)',
                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 24%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 8%, 81%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 70%, 53%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 57%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 49%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 18%)',

                                                        }, {
                                                            color: 'hsl(204, 8%, 76%)',

                                                        }, {
                                                            color: 'hsl(204, 62%, 47%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 78%, 50%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 43%)',

                                                        },

                                                        {
                                                            color: 'hsl(210, 29%, 18%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 5%, 67%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 40%)',

                                                        }, {
                                                            color: 'hsl(6, 59%, 43%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 37%)',

                                                        },


                                                        {
                                                            color: 'hsl(210, 29%, 16%)',

                                                        },

                                                        {
                                                            color: 'hsl(204, 4%, 58%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 34%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 59%, 37%)',

                                                        }, {
                                                            color: 'hsl(145, 63%, 31%)',

                                                        },

                                                        {
                                                            color: 'hsl(210, 29%, 13%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 3%, 49%)',

                                                        },
                                                        {
                                                            color: 'hsl(204, 62%, 28%)',

                                                        },
                                                        {
                                                            color: 'hsl(6, 59%, 30%)',

                                                        },
                                                        {
                                                            color: 'hsl(145, 63%, 25%)',

                                                        },
                                                    ]
                                                },
                                                mediaEmbed: {
                                                    semanticDataOutput: true
                                                }
                                            }}
                                        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withApollo(CkEditor))
);


import gql from 'graphql-tag';

export const CREATE_MODULE = gql`
mutation CreateModule($moduleTitle: String!,$moduleHelperText:String,$bannerColor:String,$bannerIconUrl:String,$moduleOverview:String,$content:String,$isActive: Boolean, $objectivesImageUrl: String, $bannerImage: String, $objectives: JSON, $supportingLangs: JSON, $learningSummary: JSON) {
  createModule(moduleTitle:$moduleTitle,moduleHelperText:$moduleHelperText,bannerColor:$bannerColor,bannerIconUrl:$bannerIconUrl,moduleOverview:$moduleOverview,content:$content,isActive:$isActive,objectivesImageUrl:$objectivesImageUrl, bannerImage: $bannerImage, objectives:$objectives, supportingLangs: $supportingLangs, learningSummary:$learningSummary)
    {
      id
      moduleTitle
      moduleHelperText
      bannerColor
      bannerIconUrl
      moduleOverview
      content
      moduleNumber
      isActive
      objectivesImageUrl
      bannerImage
      objectives
      languageObj
      learningSummary
    }
  }
`

export const UPDATE_MODULE = gql`
mutation UpdateModule($moduleId:String!,$moduleTitle: String,$moduleHelperText:String,$bannerColor:String,$bannerIconUrl:String,$moduleOverview:String,$content:String,$isActive: Boolean,$objectivesImageUrl:String, $bannerImage: String, $formLanguage: Lang!, $objectives: JSON, $learningSummary:JSON, $languageEnabled: Boolean) {
  updateModule(moduleId:$moduleId,moduleTitle:$moduleTitle,moduleHelperText:$moduleHelperText,bannerColor:$bannerColor,bannerIconUrl:$bannerIconUrl,moduleOverview:$moduleOverview,content:$content,isActive:$isActive,objectivesImageUrl:$objectivesImageUrl, bannerImage: $bannerImage, lang:$formLanguage, objectives:$objectives, learningSummary:$learningSummary, languageEnabled:$languageEnabled)
    {
      id
      moduleTitle
      moduleHelperText
      bannerColor
      bannerIconUrl
      moduleOverview
      content
      moduleNumber
      isActive
      objectivesImageUrl
      bannerImage
      objectives
      languageObj
      learningSummary
      languageEnabled
    }
  }
`

export const CREATE_CONTENT = gql`
mutation CreateContent($content: String!,$moduleId:String,$subModuleId:String,$lessonId:String,$isActive:Boolean) {
  createContent(content:$content,moduleId:$moduleId,subModuleId:$subModuleId,lessonId:$lessonId,isActive:$isActive)
    {
      id
      content
      moduleId
      subModuleId
      lessonId
      isActive
    }
  }
`

export const UPDATE_CONTENT = gql`
mutation UpdateContent($contentId:String!,$content: String,$isActive:Boolean) {
  updateContent(contentId:$contentId,content:$content,isActive:$isActive)
    {
      id
      content
      moduleId
      subModuleId
      lessonId
      isActive
    }
  }
`

export const CREATE_SUB_MODULE = gql`
mutation CreateSubModule($subModuleTitle: String!,$subModuleHelperText:String,$subBannerColor:String,$subBannerIconUrl:String,$subModuleOverview:String,$subContent:String,$moduleId:String!,$isActive: Boolean,$flashQuiz: JSON, $presentations: JSON, $collapsibleSections:JSON, $videos: JSON ) {
  createSubModule(subModuleTitle:$subModuleTitle,subModuleHelperText:$subModuleHelperText,subBannerColor:$subBannerColor,subBannerIconUrl:$subBannerIconUrl,subModuleOverview:$subModuleOverview,subContent:$subContent,moduleId:$moduleId,isActive:$isActive,flashQuiz:$flashQuiz, presentations: $presentations, collapsibleSections: $collapsibleSections, videos: $videos)
    {
      id
      subModuleTitle
      subModuleHelperText
      subBannerColor
      subBannerIconUrl
      subModuleOverview
      subContent
      subModuleNumber
      moduleId
      isActive
      flashQuiz
      presentations
      collapsibleSections
      videos
    }
  }
`

export const UPDATE_SUB_MODULE = gql`
mutation UpdateSubModule($subModuleId:String!,$subModuleTitle: String,$subModuleHelperText:String,$subBannerColor:String,$subBannerIconUrl:String,$subModuleOverview:String,$subContent:String,$isActive: Boolean,$lang:Lang!,$flashQuiz:JSON, $presentations: JSON, $collapsibleSections: JSON, $videos: JSON, $languageEnabled: Boolean) {
  updateSubModule(subModuleId:$subModuleId,subModuleTitle:$subModuleTitle,subModuleHelperText:$subModuleHelperText,subBannerColor:$subBannerColor,subBannerIconUrl:$subBannerIconUrl,subModuleOverview:$subModuleOverview,subContent:$subContent,isActive:$isActive,lang:$lang,flashQuiz:$flashQuiz, presentations: $presentations, collapsibleSections: $collapsibleSections, videos: $videos, languageEnabled: $languageEnabled )
    {
      id
      subModuleTitle
      subModuleHelperText
      subBannerColor
      subBannerIconUrl
      subModuleOverview
      subContent
      subModuleNumber
      moduleId
      isActive
      flashQuiz
      presentations
      collapsibleSections
      videos
      languageEnabled
    }
  }
`

export const GET_MODULES_FOR_ADMIN = gql`
query GetModulesForAdmin($skip: Int,$first: Int,$isActive: Boolean,$lang: Lang) {
  getModulesForAdmin(skip:$skip,first:$first,isActive:$isActive,lang:$lang){
      modules{
        id
        moduleTitle
        moduleHelperText
        bannerColor
        bannerIconUrl
        moduleOverview
        content
        moduleNumber
        isActive
        objectivesImageUrl
        bannerImage
        objectives
        # languageObj
      }
      count
    }
  }
`;

export const GET_TOOLS = gql`
query GetTools($isActive: Boolean,$skip: Int,$first: Int,$lang: Lang!){
  getTools(isActive:$isActive,skip:$skip,first:$first, lang:$lang){
    tools{
      id
      title
      image
      url
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
    }
    count
  }
}

`
export const CREATE_TOOL = gql `
mutation CreateTool($title: String,$image: String,$url: String,$supportingLangs: JSON,$isActive: Boolean){
  createTool(title:$title,image:$image,url:$url,supportingLangs:$supportingLangs,isActive:$isActive){
    id
    title
    image
    url
    languageObj
    isActive
    createdAt
    updatedAt
    createdBy
  }

}
`
export const UPDATE_TOOL = gql`
mutation UpdateTool($toolId: String!,$title: String,$image: String,$url: String,$isActive: Boolean,$lang: Lang!,$languageEnabled: Boolean){
  updateTool(toolId:$toolId,title:$title,image:$image,url:$url,isActive:$isActive,lang:$lang,languageEnabled:$languageEnabled ){
    id
    title
    image
    url
    languageObj
    isActive
    createdAt
    updatedAt
    createdBy
    languageEnabled
  }
}
`

export const CREATE_LESSON = gql`
mutation CreateLesson($lessonTitle: String!,$lessonHelperText:String,$lessonBannerColor:String,$lessonBannerIconUrl:String,$lessonOverview:String,$lessonContent:String,$moduleId:String,$subModuleId:String,$isActive: Boolean, $lang: Lang!) {
  createLesson(lessonTitle:$lessonTitle,lessonHelperText:$lessonHelperText,lessonBannerColor:$lessonBannerColor,lessonBannerIconUrl:$lessonBannerIconUrl,lessonOverview:$lessonOverview,lessonContent:$lessonContent,moduleId:$moduleId,subModuleId:$subModuleId,isActive:$isActive, lang:$lang)
    {
      id
      lessonTitle
      lessonHelperText
      lessonBannerColor
      lessonBannerIconUrl
      lessonOverview
      lessonContent
      lessonNumber
      moduleId
      subModuleId
      isActive
    }
  }
`

export const UPDATE_LESSON = gql`
mutation UpdateLesson($lessonId:String!,$lessonTitle: String,$lessonHelperText:String,$lessonBannerColor:String,$lessonBannerIconUrl:String,$lessonOverview:String,$lessonContent:String,$isActive: Boolean, $lang: Lang!) {
  updateLesson(lessonId:$lessonId,lessonTitle:$lessonTitle,lessonHelperText:$lessonHelperText,lessonBannerColor:$lessonBannerColor,lessonBannerIconUrl:$lessonBannerIconUrl,lessonOverview:$lessonOverview,lessonContent:$lessonContent,isActive:$isActive, lang:$lang)
    {
      id
      lessonTitle
      lessonHelperText
      lessonBannerColor
      lessonBannerIconUrl
      lessonOverview
      lessonContent
      lessonNumber
      moduleId
      subModuleId
      isActive
    }
  }
`

export const GET_SUB_MODULES_FOR_ADMIN = gql`
query GetSubModulesForAdmin($skip: Int,$first: Int,$isActive: Boolean,$moduleId:String!) {
  getSubModulesForAdmin(skip:$skip,first:$first,isActive:$isActive,moduleId:$moduleId){
    subModules{
        id
        subModuleTitle
        subModuleHelperText
        subBannerColor
        subBannerIconUrl
        subModuleOverview
        subContent
        subModuleNumber
        moduleId
        isActive
        collapsibleSections
        videos
      }
      count
    }
  }
`;

export const GET_LESSONS_FOR_ADMIN = gql`
query GetLessonsForAdmin($skip: Int,$first: Int,$isActive: Boolean!,$moduleId:String,$subModuleId:String, $lang: Lang) {
  getLessonsForAdmin(skip:$skip,first:$first,isActive:$isActive,moduleId:$moduleId,subModuleId:$subModuleId, lang:$lang){
    lessons{
        id
        lessonTitle
        lessonHelperText
        lessonBannerColor
        lessonBannerIconUrl
        lessonOverview
        lessonContent
        lessonNumber
        moduleId
        subModuleId
        isActive
      }
      count
    }
  }
`;

export const CREATE_MODULE_QUIZ = gql`
mutation CreateModuleQuiz($moduleId: String!,$moduleQuiz:JSON!,$isActive:Boolean!) {
  createModuleQuiz(moduleId:$moduleId,moduleQuiz:$moduleQuiz,isActive:$isActive)
    {
      id
      moduleId
      moduleQuiz
      isActive
      languageObj
    }
  }
`;

export const UPDATE_MODULE_QUIZ = gql`
mutation UpdateModuleQuiz($moduleQuizId: String!,$moduleQuiz:JSON,$isActive:Boolean, $lang: Lang!) {
  updateModuleQuiz(moduleQuizId:$moduleQuizId,moduleQuiz:$moduleQuiz,isActive:$isActive,lang:$lang)
    {
      id
      moduleId
      moduleQuiz
      isActive
      languageObj
    }
  }
`;



export const CREATE_NEWS = gql`
mutation createNews($newsTitle: String!, $newsBannerImage: String!, $newsHelperText: String! , $newsContent: String, $isActive:Boolean!, $url: String, $newsType: NewsType!, $supportingLangs: JSON, $videos: JSON){
  createNews(newsTitle:$newsTitle, newsBannerImage:$newsBannerImage, newsHelperText:$newsHelperText, newsContent:$newsContent,isActive:$isActive, url:$url, newsType: $newsType, supportingLangs: $supportingLangs, videos: $videos){
    newsTitle
    newsContent
    newsHelperText
    newsBannerImage
    isActive
    url
    newsType
    videos
  }
}
`;

export const CREATE_RESOURCE = gql`
mutation CreateResource($resourceTitle: String!,$resources:JSON!, $description: String, $url: String, $resourceContent: String, $supportingLangs: JSON, $videos: JSON) {
  createResource(resourceTitle:$resourceTitle,resources:$resources, description:$description, url:$url, resourceContent:$resourceContent, supportingLangs:$supportingLangs, videos: $videos)
    {
      id
      resourceTitle
      resources
      createdBy
      description
      url
      resourceContent
      videos
    }
  }
`

export const UPDATE_RESOURCE = gql`
mutation UpdateResource($id:String!,$resourceTitle: String!,$resources:JSON!, $description: String, $url: String, $resourceContent: String, $lang: Lang!, $videos: JSON, $languageEnabled: Boolean) {
  updateResource(id:$id,resourceTitle:$resourceTitle,resources:$resources, description:$description, url:$url, resourceContent:$resourceContent, lang:$lang, videos: $videos, languageEnabled: $languageEnabled )
    {
      id
      resourceTitle
      resources
      createdBy
      description
      url
      resourceContent
      # languageObj
      videos
    }
  }
`
export const UPDATE_ADMIN_PASSWORD =gql`
mutation UpdateAdminPassword($userID: String!,$password: String!){
  updatePassword(userID:$userID, password:$password){
    token
    user{
      id
      firstName
      lastName
      mobile
      tel
      ext
      email
      gender
      userType
      isActive
      fullName
      country
      region
      year
    }
  }
}
`

export const CREATE_ANNOUNCEMENT = gql`
mutation CreateAnnouncement($title: String!, $description: String!,$startDate:String, $endDate: String, $showExpDate: Boolean, $date: String, $url: String, $supportingLangs: JSON, $isActive: Boolean) {
  createAnnouncement(title:$title,description:$description,startDate:$startDate, endDate:$endDate, showExpDate:$showExpDate, date:$date, url:$url,supportingLangs:$supportingLangs, isActive:$isActive)
    {
    id
    description
    startDate
    endDate
    showExpDate
    languageObj
    isActive
    createdAt
    updatedAt
    createdBy
    }
  }
`

export const UPDATE_ANNOUNCEMENT = gql`
mutation UpdateAnnouncement($title: String,$announcementId: String!,$description:String, $startDate:String, $endDate: String, $showExpDate: Boolean, $date: String, $url: String, $isActive: Boolean, $lang: Lang!, $languageEnabled: Boolean) {
  updateAnnouncement(title:$title,announcementId:$announcementId, description:$description, startDate:$startDate, endDate:$endDate, showExpDate:$showExpDate, date:$date, url:$url, isActive:$isActive, lang:$lang, languageEnabled:$languageEnabled)
    {
      id
      description
      startDate
      endDate
      showExpDate
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
      languageEnabled
    }
  }
`

export const CREATE_EXAM = gql`
mutation createExam($examName: String!,$description: String,$questions:JSON,$resourceUrls:JSON,$supportingLangs:JSON,$isActive:Boolean) {
  createExam(examName:$examName,description:$description,questions:$questions,resourceUrls:$resourceUrls,supportingLangs:$supportingLangs,isActive:$isActive)
    {
      id
      examName
      description
      questions
      resourceUrls
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
    }
  }
`

export const UPDATE_EXAM = gql`
mutation updateExam($examId:String!,$examName: String,$description: String,$questions:JSON,$resourceUrls:JSON,$isActive:Boolean,$lang:Lang!,$languageEnabled: Boolean) {
  updateExam(examId:$examId,examName:$examName,description:$description,questions:$questions,resourceUrls:$resourceUrls,isActive:$isActive,lang:$lang, languageEnabled:$languageEnabled)
    {
      id
      examName
      description
      questions
      resourceUrls
      languageObj
      isActive
      createdAt
      updatedAt
      createdBy
      languageEnabled
    }
  }
`

export const GET_USER_STATS = gql`
query getUserStats($fromDate:String, $toDate:String) {
  getUserStats(fromDate: $fromDate, toDate: $toDate)
}
`

export const GET_MODULE_STATS = gql`
query getModuleStats($fromDate:String, $toDate:String) {
  getModuleStats(fromDate: $fromDate, toDate: $toDate)
}
`

export const GET_SBL_STATS = gql`
query getSBLStats($fromDate:String, $toDate:String) {
  getSBLStats(fromDate: $fromDate, toDate: $toDate)
}
`

export const GET_FEEDBACK_STATS = gql`
query getFeedbackStats($fromDate:String, $toDate:String) {
  getFeedbackStats(fromDate: $fromDate, toDate: $toDate)
}
`

export const GET_OTHER_STATS = gql`
query getOtherStats($fromDate:String, $toDate:String) {
  getOtherStats(fromDate: $fromDate, toDate: $toDate)
}
`
export const GET_REPEATED_USER_STATS = gql`
query getRepeatedUserStats($fromDate:String, $toDate:String) {
  getRepeatedUserStats(fromDate: $fromDate, toDate: $toDate)
}
`

export const GET_ALL_EXAM_SUBMISSIONS = gql`
query getAllExamSubmissions($skip:Int, $first:Int) {
  getAllExamSubmissions(skip: $skip, first: $first)
}
`

